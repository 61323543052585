import { ColDef } from 'ag-grid-community';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AgGridBuilder from '../../../../Components/AgGridBuilder/AgGridBuilder';
import { AgGridRow } from '../../../../Components/AgGridBuilder/InterfaceAgGrid';
import DataSourceTag from '../../../../Components/DataSourceTag/DataSourceTag';
import scssVariables from '../../../../Config.module.scss';
import { DemeterFilterTimeSpan } from '../../../../Generated/Raven-Demeter';
import {
    CommodityMonthlyOriginsOrDestinationsModel,
    ListCommodityMonthlyOriginsOrDestinationsResponse,
} from '../../../Apis/Hooks/useOriginsAndDestinationsApiHook';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './OriginsAndDestinationsTable.module.scss';
import { originsAndDestinationsColumnDefinitions, originsAndDestinationsColumnOptions } from './OriginsAndDestinationsTableDefinitions';

export interface IOriginsAndDestinationsProps {
    originsOrDestinationsResponse: ListCommodityMonthlyOriginsOrDestinationsResponse;
    filterTimeSpan: DemeterFilterTimeSpan;
    testId?: string;
}

const OriginsAndDestinationsTable: React.FC<IOriginsAndDestinationsProps> = (props) => {
    const [columnDefinitions, setColumnDefinitions] = useState(originsAndDestinationsColumnDefinitions);
    const [gridRowData, setGridRowData] = useState<CommodityMonthlyOriginsOrDestinationsModel[]>([]);
    const [translations] = useLanguage();

    const tableReference = useRef<any>();

    const getRowStyle = (params: { node: { data: { id: string } } }) => {
        if (params.node?.data?.id !== 'All') {
            return {};
        }
        // Bold font for the totals row.
        return { fontWeight: scssVariables.bold };
    };

    const setHeaderNames = useCallback((asOfDate?: string) => {
        if (!asOfDate || !tableReference?.current?.api) {
            return;
        }
        const currentColumnDefinitions = [...columnDefinitions];
        currentColumnDefinitions[1].headerName = formattingService.toMonthYear(new Date(asOfDate));
        tableReference.current.api.setColumnDefs(currentColumnDefinitions);
    }, []);

    useEffect(() => {
        if (
            !props.originsOrDestinationsResponse?.rows ||
            (props.originsOrDestinationsResponse?.rows?.length && props.originsOrDestinationsResponse?.rows?.length === 0)
        ) {
            return;
        }

        props.originsOrDestinationsResponse.rows.forEach((row) => {
            (row as unknown as AgGridRow).id = row.originOrDestinationRegion as string;
        });

        const firstRow = props.originsOrDestinationsResponse?.rows[0];
        setHeaderNames(firstRow.asOfDate);
    }, [props.originsOrDestinationsResponse, tableReference?.current?.api]);

    // These are the indexes of the 2 columns that will always appear on the last 2 rows. Each of their children
    // must also be hidden for this to work.
    useEffect(() => {
        if (!tableReference?.current?.api) {
            return;
        }

        if (props.filterTimeSpan === DemeterFilterTimeSpan.YearToDate) {
            columnDefinitions[2].children.forEach((child: ColDef) => {
                child.hide = true;
            });
            columnDefinitions[3].children.forEach((child: ColDef) => {
                child.hide = false;
            });
        } else {
            columnDefinitions[2].children.forEach((child: ColDef) => {
                child.hide = false;
            });
            columnDefinitions[3].children.forEach((child: ColDef) => {
                child.hide = true;
            });
        }

        tableReference.current.api.setColumnDefs(columnDefinitions);
        tableReference.current.api.sizeColumnsToFit();
    }, [props.filterTimeSpan, gridRowData]);

    useEffect(() => {
        if (!props.originsOrDestinationsResponse?.rows || !(props.originsOrDestinationsResponse?.rows?.length > 0)) {
            return;
        }

        setGridRowData(
            props.originsOrDestinationsResponse?.rows?.slice(1).map((x, i) => ({
                ...x,
                id: i,
            })),
        );
    }, [props.originsOrDestinationsResponse]);

    return (
        <div className={styles.origins_and_destinations_table_container}>
            <AgGridBuilder
                gridRef={tableReference}
                rowData={gridRowData}
                hasSaveColumnsState
                setColumnDefinitions={setColumnDefinitions}
                columnDefinitions={columnDefinitions}
                defaultColumnDefinition={originsAndDestinationsColumnOptions}
                gridHeightFull
                domLayout={props.originsOrDestinationsResponse?.rows && props.originsOrDestinationsResponse?.rows?.length <= 9 ? 'autoHeight' : undefined}
                getRowStyle={getRowStyle}
                pinnedBottomRowData={props.originsOrDestinationsResponse?.rows?.length ? [props.originsOrDestinationsResponse.rows[0]] : []}
                testId={props.testId}
            />
            <div className={styles.data_source_container}>
                <p className={styles.origins_and_destinations_unit_of_measure}>
                    {translations.unitOfMeasure[props.originsOrDestinationsResponse?.unitOfMeasure!]}
                </p>
                <DataSourceTag value={props.originsOrDestinationsResponse?.dataSourceTag ?? ''} />
            </div>
        </div>
    );
};

export default OriginsAndDestinationsTable;
