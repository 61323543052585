import ImportExportIcon from '@mui/icons-material/ImportExport';
import React, { Children, useState } from 'react';
import { DemeterTableDefinitionType } from '../../../Generated/Raven-Demeter';
import useLanguage from '../../Services/Language/useLanguageHook';
import IconButton from '../Form/Buttons/IconButton';
import MenuSettingsModal from '../Modals/ProductsModal/MenuSettingsModal';
import BreadCrumb from './BreadCrumb';
import styles from './MenuFileSelectors.module.scss';

interface MenuSelectorWithFlipWrapperProps {
    breadCrumbs?: string;
    children: JSX.Element;
    rightSideOfBreadcrumbsComponent?: JSX.Element;
    tableDefinitionType: DemeterTableDefinitionType;
    menuSettingsKey: string;
    handleMenuFlip: () => void;
}

const MenuSelectorWithFlipWrapper: React.FC<MenuSelectorWithFlipWrapperProps> = (props: MenuSelectorWithFlipWrapperProps) => {
    const [translations] = useLanguage();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleFlip = () => {
        props.handleMenuFlip();
    };

    const handleIsModalOpen = () => {
        setIsModalOpen((previous) => !previous);
    };

    const handleMenuUserStoreUpdate = () => {
        handleIsModalOpen();
    };

    return (
        <>
            <div>
                <span className={styles.menu_flip_wrapper_icons}>
                    <IconButton tooltip={translations.actions.flipRegionsAndCommodities} handleClick={handleFlip}>
                        <ImportExportIcon />
                    </IconButton>
                    {/* TODO Modal commented out, will be enabled for work on ticket 130736 */}
                    {/* <IconButton tooltip="Settings" handleClick={handleIsModalOpen}>
                        <SettingsIcon />
                    </IconButton> */}
                </span>
                {Children.only(props.children)}
            </div>
            <div className={styles.menu_flip_wrapper_bottom_row}>
                <div className={styles.menu_flip_wrapper_left_side}>
                    <BreadCrumb breadCrumbs={props.breadCrumbs} />
                </div>
                <div className={styles.menu_flip_wrapper_right_side}>
                    {props.rightSideOfBreadcrumbsComponent && <div>{props.rightSideOfBreadcrumbsComponent}</div>}
                </div>
            </div>
            <MenuSettingsModal
                open={isModalOpen}
                tableDefinitionType={props.tableDefinitionType}
                handleClose={handleIsModalOpen}
                setMenuSettings={handleMenuUserStoreUpdate}
                menuSettingsKey={props.menuSettingsKey}
            />
        </>
    );
};

export default MenuSelectorWithFlipWrapper;
