import {
    CalculationEngineLineResultModel,
    CalculationEngineMonthlyValueOverrides,
    FarmerMarginCalculatorModel,
    RunCalculationEngineResponse,
} from '../../../../../../Generated/Raven-Demeter';
import { HideGroup, MarginCompositeModel, TabEventKeyType } from '../../../RiskDefinitions';
import MarginDataRow from '../MarginDataRow';
import MarginInputRow from '../MarginInputRow';
import styles from '../MarginInputsTable.module.scss';

interface IMarginInputsTableFuturesDataProps {
    getFarmerMarginCalculatorCompositeRowsModel: MarginCompositeModel[];
    runCalculationEngineResponse: RunCalculationEngineResponse;
    savedFarmerMarginCalculator?: FarmerMarginCalculatorModel;
    hideGroup: HideGroup;
    runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null;
    setRunMonthlyValueOverrides: (runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null) => void;
    handleGridTabNavigation: (columnIndex: number, rowIndex: number, direction: TabEventKeyType) => void;
}

const MarginInputsTableFuturesData: React.FC<IMarginInputsTableFuturesDataProps> = (props: IMarginInputsTableFuturesDataProps) => {
    const firstRowModel = props.getFarmerMarginCalculatorCompositeRowsModel[0];
    const topLevelBackground = styles.margin_inputs_table_top_level_background;
    const level1ChildBackground = styles.margin_inputs_table_level_1_background;
    const showFuturesBlendPriceChildren = props.hideGroup[firstRowModel.futuresBlendPrice.variableName] === false;

    return (
        <>
            {showFuturesBlendPriceChildren &&
                firstRowModel.futuresBlendPrice.children?.map((y) => {
                    if (y.unitOfMeasure !== 'Percent') {
                        return (
                            <MarginDataRow
                                className={showFuturesBlendPriceChildren ? level1ChildBackground : topLevelBackground}
                                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                                runCalculationEngineResponse={props.runCalculationEngineResponse}
                                fieldName={y.variableName as keyof CalculationEngineLineResultModel}
                            />
                        );
                    }

                    return (
                        <MarginInputRow
                            className={level1ChildBackground}
                            getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                            ancestors={[firstRowModel.futuresBlendPrice.variableName]}
                            hideGroup={props.hideGroup}
                            runCalculationEngineResponse={props.runCalculationEngineResponse}
                            fieldName={y.variableName}
                            handleGridTabNavigation={props.handleGridTabNavigation}
                            runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                            setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                            savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                        />
                    );
                })}
            <MarginDataRow
                className={showFuturesBlendPriceChildren ? level1ChildBackground : topLevelBackground}
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                fieldName={firstRowModel.futuresBlendPrice.variableName as keyof CalculationEngineLineResultModel}
            />
        </>
    );
};

export default MarginInputsTableFuturesData;
