import meanBy from 'lodash.meanby';
import React, { useEffect, useMemo, useState } from 'react';
import scssVariables from '../../../../../Config.module.scss';
import { Currency, MarketIndicatorFactorDataModel, UnitOfMeasure } from '../../../../../Generated/Raven-Demeter';
import {
    leadingIndicatorTypeDefinitions,
    SeasonalParameters,
} from '../../../../Pages/Administration/MarketIndicatorsManagement/MarketIndicatorsManagementDefinitions';
import formattingService from '../../../../Services/Formatting/FormattingService';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { IChartAreaRangeData, IChartAreaRangeDataSeries, IChartData, IChartDataSeries } from '../../ChartDefinitions';
import ChartWrapper from '../../ChartWrapper/ChartWrapper';
import { defaultSeasonalParameters } from '../MarketIndicatorChartDefaultParameters';
import marketIndicatorChartService from '../MarketIndicatorChartService';
import { defaultChartPalette, IMarketIndicatorChartColorPalette, IMarketIndicatorUserChartBaseProps } from '../MarketIndicatorUserChartDefinitions';
import MarketIndicatorUserChartRaw from '../MarketIndicatorUserChartRaw';
import styles from './MarketIndicatorSeasonalChart.module.scss';
import marketIndicatorSeasonalChartService from './MarketIndicatorSeasonalChartService';

export const seasonalChartPalette: IMarketIndicatorChartColorPalette = {
    dataDecileAlphaColorsRuleSetTwo: [scssVariables.ruleSetTwoAreaColor1],
    lineChartColorsRuleSetTwo: defaultChartPalette.lineChartColorsRuleSetTwo,
};

export interface IMarketIndicatorSeasonalChartProps extends IMarketIndicatorUserChartBaseProps {
    parameters?: SeasonalParameters;
}

const MarketIndicatorSeasonalChart: React.FC<IMarketIndicatorSeasonalChartProps> = (props: IMarketIndicatorSeasonalChartProps) => {
    const [translations, translate] = useLanguage();
    const [lineSeries, setLineSeries] = useState<IChartDataSeries[]>([]);
    const [areaRangeSeries, setAreaRangeSeries] = useState<IChartAreaRangeDataSeries[]>([]);

    const dataFrequency = useMemo(() => props.runTestMarketIndicatorFactorResponse?.dataFrequency, [props.runTestMarketIndicatorFactorResponse]);

    useEffect(() => {
        let finalAverageLineData: IChartData[] = [];
        const areaRangeData: IChartAreaRangeData[] = [];
        const dataOrganizedByDate: IChartData[][] = [];

        // Date helpers.
        const today = new Date();
        const maximumDate = new Date();
        const minimumDate = new Date();

        const getLastDayOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

        const startMonthIsBeforeEndMonth = +props.parameters!.startMonth < +props.parameters!.endMonth;

        // Go back to the previous year if the start month is greater than the end month.
        if (startMonthIsBeforeEndMonth) {
            minimumDate.setMonth(+props.parameters!.startMonth - 1);
            maximumDate.setMonth(+props.parameters!.endMonth - 1);
            minimumDate.setDate(0);
            maximumDate.setDate(getLastDayOfMonth(maximumDate));
        } else {
            minimumDate.setFullYear(minimumDate.getFullYear() - 1);
            minimumDate.setMonth(+props.parameters!.endMonth - 1);
            maximumDate.setMonth(+props.parameters!.startMonth - 1);
        }

        const startDateIsBeforeToday = minimumDate.getTime() < today.getTime();

        const calculationData = marketIndicatorChartService.getSeasonalAverageCalculationData(
            chartData,
            minimumDate,
            +props.parameters!.startMonth,
            +props.parameters!.endMonth,
        );

        const seasonalAverageData = marketIndicatorChartService.getSortedSeasonalAverageData(
            calculationData,
            numberOfYears,
            minimumDate,
            maximumDate,
            +props.parameters!.endMonth,
        );

        // Organize the data into an array of arrays by using the asOfDates.
        seasonalAverageData
            .filter((x) => {
                const startingDate = new Date(x.asOfDate.getFullYear(), x.asOfDate.getMonth() + 1, 0);
                const endingDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

                return startingDate.getTime() > endingDate.getTime();
            })
            .forEach((x, index) => {
                if (x.asOfDate.getTime() === seasonalAverageData[index - 1]?.asOfDate?.getTime() && index !== 0) {
                    dataOrganizedByDate[dataOrganizedByDate.length - 1].push(x);
                    return;
                }

                dataOrganizedByDate.push([x]);
            });

        // Get minimums, maxmimums and average for the lines and area.
        dataOrganizedByDate.forEach((x) => {
            const currentAverage = meanBy(x, 'value');
            finalAverageLineData.push({ ...x[0], value: currentAverage });
            const minimum = Math.min(...x.map((y) => y.value));
            const maximum = Math.max(...x.map((y) => y.value));
            areaRangeData.push({ ...x[0], minimumValue: minimum, maximumValue: maximum });
        });

        // Based on start and end month scenerios, update the minimum / maximum date and average data.
        if (startMonthIsBeforeEndMonth && !startDateIsBeforeToday) {
            minimumDate.setFullYear(minimumDate.getFullYear() - 1);
            maximumDate.setFullYear(maximumDate.getFullYear() - 1);

            finalAverageLineData.map((x) => ({ ...x, asOfDate: x.asOfDate.setFullYear(maximumDate.getFullYear()) }));
        } else if (!startMonthIsBeforeEndMonth && !startDateIsBeforeToday) {
            finalAverageLineData = [];

            dataOrganizedByDate.forEach((x) => {
                const currentAverage = meanBy(x, 'value');
                finalAverageLineData.push({ ...x[0], value: currentAverage });
            });
        } else if (!startMonthIsBeforeEndMonth && startDateIsBeforeToday) {
            finalAverageLineData = [];

            minimumDate.setMonth(+props.parameters!.startMonth - 1);
            maximumDate.setMonth(+props.parameters!.endMonth - 1);
            minimumDate.setDate(0);
            maximumDate.setDate(getLastDayOfMonth(maximumDate));

            dataOrganizedByDate.forEach((x) => {
                const currentAverage = meanBy(x, 'value');
                finalAverageLineData.push({ ...x[0], value: currentAverage });
            });
        }

        const thisYearLineData = chartData.filter(
            (x) => new Date(x.asOfDate).getTime() >= minimumDate.getTime() && new Date(x.asOfDate).getTime() <= maximumDate.getTime(),
        );

        const lastYearLineData = chartData
            .filter(
                (x) =>
                    new Date(x.asOfDate).getTime() > new Date(minimumDate.getFullYear() - 1, minimumDate.getMonth(), minimumDate.getDate()).getTime() &&
                    new Date(x.asOfDate).getTime() <= new Date(maximumDate.getFullYear() - 1, maximumDate.getMonth(), maximumDate.getDate()).getTime(),
            )
            .map((x) => ({ ...x, asOfDate: new Date(x.asOfDate.getFullYear() + 1, x.asOfDate.getMonth(), x.asOfDate.getDate()) }));

        const newLinesSeries: IChartDataSeries[] = [
            {
                label: translations.text.thisYear,
                data: thisYearLineData,
            },
            {
                label: translations.text.lastYear,
                data: lastYearLineData,
            },
            {
                label: `${numberOfYears} ${translations.text.yearlyAverage}`,
                data: finalAverageLineData,
            },
        ];

        const newAreaSeries: IChartAreaRangeDataSeries[] = [
            {
                label: `${numberOfYears} ${translations.text.yearRange}`,
                data: areaRangeData,
            },
        ];

        setLineSeries(newLinesSeries);
        setAreaRangeSeries(newAreaSeries);
    }, [props.runTestMarketIndicatorFactorResponse, props.parameters]);

    const numberOfYears = useMemo(() => {
        const parameters = props.parameters ?? defaultSeasonalParameters;
        const value = Number(parameters.yearsOfData);
        return value;
    }, [props.parameters]);

    const chartData = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.rows &&
            props.runTestMarketIndicatorFactorResponse.rows.length > 0
        ) {
            return marketIndicatorChartService.convertToChartData((props.runTestMarketIndicatorFactorResponse?.rows as MarketIndicatorFactorDataModel[]) ?? []);
        }
        return [];
    }, [props.runTestMarketIndicatorFactorResponse]);

    const isLoading = useMemo(
        () => props.isLoading || !props.runTestMarketIndicatorFactorResponse,
        [props.runTestMarketIndicatorFactorResponse, props.isLoading],
    );

    const title = useMemo(() => {
        if (props.title) {
            return props.title;
        }

        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.commodityDisplayName) {
            const region = props.runTestMarketIndicatorFactorResponse?.subRegion
                ? translate(props.runTestMarketIndicatorFactorResponse?.subRegion)
                : translate(props.runTestMarketIndicatorFactorResponse?.regionDisplayName!);
            const tableDefinitionType =
                translations.tableDefinitionType[
                    leadingIndicatorTypeDefinitions.find((x) => x.value === props.runTestMarketIndicatorFactorResponse?.leadingIndicatorType)
                        ?.tableDefinitionType!
                ];
            const commdityName = props.runTestMarketIndicatorFactorResponse.commodityDisplayName;
            return [region, commdityName, tableDefinitionType].filter((x) => x !== null && x !== '').join(' ');
        }
        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const sourceTag = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags.length > 0
        ) {
            return `${props.runTestMarketIndicatorFactorResponse.dataSourceTags[0]}, ${translations.dataSource.StoneXCalculations}`;
        }
        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const currency = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.currency) {
            return props.runTestMarketIndicatorFactorResponse.currency;
        }
        return '' as Currency;
    }, [props.runTestMarketIndicatorFactorResponse]);

    const unitOfMeasure = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.unitOfMeasure) {
            return props.runTestMarketIndicatorFactorResponse.unitOfMeasure;
        }
        return '' as UnitOfMeasure;
    }, [props.runTestMarketIndicatorFactorResponse]);

    const averageChange = useMemo(() => {
        if (chartData.length > 0) {
            const value = marketIndicatorSeasonalChartService.calculateSeasonalAverageChange(chartData, numberOfYears, dataFrequency);

            return formattingService.toNumberStringWithAutomaticDecimals(value);
        }

        return formattingService.toNumberStringWithAutomaticDecimals(0);
    }, [props.runTestMarketIndicatorFactorResponse]);

    const lastChange = useMemo(() => {
        if (chartData.length > 0) {
            const value = marketIndicatorSeasonalChartService.calculateLastChange(chartData, dataFrequency);
            return formattingService.toNumberStringWithAutomaticDecimals(value);
        }

        return formattingService.toNumberStringWithAutomaticDecimals(0);
    }, [props.runTestMarketIndicatorFactorResponse]);

    const hasAverageChangeFooter = useMemo(() => {
        if (!props.parameters?.subFactorCalculationTypes) {
            return false;
        }

        return props.parameters?.subFactorCalculationTypes.indexOf('VersesAverageChange') >= 0;
    }, [props.parameters?.subFactorCalculationTypes]);

    const footer = hasAverageChangeFooter && (
        <div className={styles.market_indicator_seasonal_chart_footer}>
            <span className={styles.market_indicator_seasonal_chart_footer_item}>
                {translations.text.averageChange}: {averageChange}
            </span>
            <span className={styles.market_indicator_seasonal_chart_footer_item}>
                {translations.text.lastChange}: {lastChange}
            </span>
        </div>
    );

    return (
        <ChartWrapper
            name="MarketIndicatorSeasonalChart"
            title={title}
            dataSourceTag={sourceTag}
            isLoading={isLoading}
            headerOptions={{
                showOnlyAsPopout: props.showOnlyAsPopout,
            }}
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
            footer={footer}
        >
            <MarketIndicatorUserChartRaw
                lineSeries={lineSeries}
                areaRangeSeries={areaRangeSeries}
                currencies={[currency]}
                unitOfMeasures={[unitOfMeasure]}
                colorPalette={seasonalChartPalette}
            />
        </ChartWrapper>
    );
};

export default MarketIndicatorSeasonalChart;
