import React from 'react';
import { DemeterFeatureType, DemeterPermissionType } from '../../../Generated/Raven-Demeter';
import PageHeader from '../../Components/Headers/PageHeader';
import Tabs from '../../Components/Navigation/Tabs/Tabs';
import useFeatureFlag from '../../Services/FeatureFlags/useFeatureFlagHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import usePermission from '../../Services/Permissions/usePermissionHook';
import Budget from './Budget/Budget';
import Margin from './Margin/Margin';
import Positions from './Positions/Positions';
import styles from './RiskPage.module.scss';

const RiskPage: React.FC = () => {
    const [translations] = useLanguage();
    const pageTitle = translations.risk.title;

    const tabOptions = [
        {
            title: translations.risk.headers.positions,
            key: 'positions',
            component: <Positions />,
            showTab: useFeatureFlag(DemeterFeatureType.PositionsCalculator) && usePermission(DemeterPermissionType.PositionsCalculator),
            testId: 'PositionsTab',
        },
        {
            title: translations.risk.headers.budget,
            key: 'budget',
            component: <Budget />,
            showTab: useFeatureFlag(DemeterFeatureType.BudgetCalculator) && usePermission(DemeterPermissionType.BudgetCalculator),
            testId: 'BudgetTab',
        },
        {
            title: translations.risk.headers.margin,
            key: 'margin',
            component: <Margin />,
            showTab: useFeatureFlag(DemeterFeatureType.FarmerMarginCalculator) && usePermission(DemeterPermissionType.FarmerMarginCalculator),
            testId: 'MarginTab',
        },
    ];

    return (
        <div className={styles.master_page_container}>
            <PageHeader title={pageTitle} testId="RiskPageHeader" />
            <Tabs tabOptions={tabOptions} />
        </div>
    );
};

export default RiskPage;
