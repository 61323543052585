import {
    AverageSeasonalParameters,
    DecileParameters,
    HistoricalParameters,
    SeasonalParameters,
    TechnicalParameters,
} from '../../../Pages/Administration/MarketIndicatorsManagement/MarketIndicatorsManagementDefinitions';

export const defaultAverageSeasonalParameters: AverageSeasonalParameters = {
    yearsOfData: '5',
    startMonth: '1',
    endMonth: '12',
};

export const defaultDecileParameters: DecileParameters = {
    yearsOfData: '5',
    oneYearWeightedPercent: '',
};

export const defaultHistoricalParameters: HistoricalParameters = {
    yearsOfData: '5',
    subFactorCalculationTypes: '',
    thresholdValue1: '',
    thresholdValue2: '',
    thresholdValue3: '',
};

export const defaultSeasonalParameters: SeasonalParameters = {
    yearsOfData: '5',
    subFactorCalculationTypes: 'Average,LastYear,VersesAverageChange',
    thresholdValue1: '',
    thresholdValue2: '',
    thresholdValue3: '',
    startMonth: '1',
    endMonth: '12',
};

export const defaultTechnicalParameters: TechnicalParameters = {
    daysToAverage1: '10',
    daysToAverage2: '40',
    daysToAverage3: '200',
    shortTermDays1: '8',
    shortTermDays2: '34',
    shortTermDays3: '13',
    longTermDays1: '16',
    longTermDays2: '68',
    longTermDays3: '26',
};
