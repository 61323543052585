import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import debounce from 'lodash.debounce';
import type { RootState } from '../ReduxStore';

export interface MarketPriceModel {
    reutersInstrumentCode: string;
    exchangeSymbol?: string;
    expirationDate?: string;
    currency?: string;
    latestPriceAsOfDate?: string;
    settlementPriceAsOfDate?: string;
    settlementPrice?: number;
    openPrice?: number;
    latestPrice?: number;
    highPrice?: number;
    lowPrice?: number;
    bidPrice?: number;
    bidSize?: number;
    askPrice?: number;
    askSize?: number;
    percentChange?: number;
    previousSettlementPrice?: number;
    netChange?: number;
    volume?: number;
    openInterest?: number;
    openInterestNetChange?: number;
    lastSessionVolume?: number;
    lastSessionHighPrice?: number;
    lastSessionLowPrice?: number;
    settlementNetChange?: number;
    tradeRegistrationPrice?: number;
    tradeRegistrationVolume?: number;
}

export type MarketPriceModelBySymbol = { [key: string]: MarketPriceModel };

export interface MarketPricesState {
    symbols: string[];
    marketPricesBySymbol: MarketPriceModelBySymbol;
    marketPricesBySymbolDebounced: MarketPriceModelBySymbol;
}

const initialState: MarketPricesState = {
    symbols: [],
    marketPricesBySymbol: {},
    marketPricesBySymbolDebounced: {},
};

export const updateMarketPrices = createAsyncThunk<MarketPriceModel[], MarketPriceModel[]>(
    'marketPrice/updateMarketPrices',
    async (marketPriceModels: MarketPriceModel[]) => marketPriceModels,
);

const debounceDelay = 2000; // 2 seconds.
export const updateMarketPricesDebounced = createAsyncThunk<void, void>(
    'marketPrice/updateMarketPricesDebounced',
    debounce(async () => {}, debounceDelay),
);

const updateMarketPrice = (marketPricesBySymbol: MarketPriceModelBySymbol, marketPrice: MarketPriceModel): void => {
    const previousSymbol = marketPricesBySymbol[marketPrice.reutersInstrumentCode];
    const updatedSymbol = {
        reutersInstrumentCode: marketPrice.reutersInstrumentCode ?? previousSymbol?.reutersInstrumentCode,
        exchangeSymbol: marketPrice.exchangeSymbol ?? previousSymbol?.exchangeSymbol,
        expirationDate: marketPrice.expirationDate ?? previousSymbol?.expirationDate,
        currency: marketPrice.currency ?? previousSymbol?.currency,
        settlementPrice: marketPrice.settlementPrice ?? previousSymbol?.settlementPrice,
        openPrice: marketPrice.openPrice ?? previousSymbol?.openPrice,
        latestPrice: marketPrice.latestPrice ?? previousSymbol?.latestPrice,
        highPrice: marketPrice.highPrice ?? previousSymbol?.highPrice,
        lowPrice: marketPrice.lowPrice ?? previousSymbol?.lowPrice,
        bidPrice: marketPrice.bidPrice ?? previousSymbol?.bidPrice,
        bidSize: marketPrice.bidSize ?? previousSymbol?.bidSize,
        askPrice: marketPrice.askPrice ?? previousSymbol?.askPrice,
        askSize: marketPrice.askSize ?? previousSymbol?.askSize,
        percentChange: marketPrice.percentChange ?? previousSymbol?.percentChange,
        previousSettlementPrice: marketPrice.previousSettlementPrice ?? previousSymbol?.previousSettlementPrice,
        netChange: marketPrice.netChange ?? previousSymbol?.netChange,
        volume: marketPrice.volume ?? previousSymbol?.volume,
        openInterest: marketPrice.openInterest ?? previousSymbol?.openInterest,
        openInterestNetChange: marketPrice.openInterestNetChange ?? previousSymbol?.openInterestNetChange,
        lastSessionVolume: marketPrice.lastSessionVolume ?? previousSymbol?.lastSessionVolume,
        lastSessionHighPrice: marketPrice.lastSessionHighPrice ?? previousSymbol?.lastSessionHighPrice,
        lastSessionLowPrice: marketPrice.lastSessionLowPrice ?? previousSymbol?.lastSessionLowPrice,
        latestPriceAsOfDate: marketPrice.latestPriceAsOfDate ?? previousSymbol?.latestPriceAsOfDate,
        settlementPriceAsOfDate: marketPrice.settlementPriceAsOfDate ?? previousSymbol?.settlementPriceAsOfDate,
        settlementNetChange: marketPrice.settlementNetChange ?? previousSymbol?.settlementNetChange,
        tradeRegistrationPrice: marketPrice.tradeRegistrationPrice ?? previousSymbol?.tradeRegistrationPrice,
        tradeRegistrationVolume: marketPrice.tradeRegistrationVolume ?? previousSymbol?.tradeRegistrationVolume,
    };

    marketPricesBySymbol[marketPrice.reutersInstrumentCode] = updatedSymbol;
};

export const marketPricesSlice = createSlice({
    name: 'marketPrice',
    initialState,
    reducers: {},
    extraReducers: (builder: ActionReducerMapBuilder<MarketPricesState>) => {
        builder.addCase(updateMarketPrices.fulfilled, (state: MarketPricesState, action: PayloadAction<MarketPriceModel[]>) => {
            action.payload.forEach((marketPrice: MarketPriceModel) => {
                updateMarketPrice(state.marketPricesBySymbol, marketPrice);
            });
        });

        builder.addCase(updateMarketPricesDebounced.fulfilled, (state: MarketPricesState) => {
            state.marketPricesBySymbolDebounced = { ...state.marketPricesBySymbol };
        });
    },
});

export const selectSymbols = (state: RootState): string[] => state.marketPrices.symbols;
export const selectMarketPricesBySymbol = (state: RootState): MarketPriceModelBySymbol => state.marketPrices.marketPricesBySymbol ?? {};
export const selectMarketPricesBySymbolDebounced = (state: RootState): MarketPriceModelBySymbol => state.marketPrices.marketPricesBySymbolDebounced ?? {};

export default marketPricesSlice.reducer;
