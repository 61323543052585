import { CalculationEngineMonthlyValueOverrides, FarmerMarginCalculatorModel, RunCalculationEngineResponse } from '../../../../../Generated/Raven-Demeter';
import { HideGroup, MarginCompositeModel } from '../../RiskDefinitions';
import styles from './MarginInputsTable.module.scss';
import MarginInputsTableStickyColumn from './MarginInputsTableStickyColumn';
import MarginInputTableData from './MarketInputsTableData/MarginInputsTableData';

interface IMarginInputsTableProps {
    getFarmerMarginCalculatorCompositeRowsModel: MarginCompositeModel[];
    runCalculationEngineResponse: RunCalculationEngineResponse;
    runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null;
    savedFarmerMarginCalculator?: FarmerMarginCalculatorModel;
    hideGroup: HideGroup;
    setHideGroup: (hideGroup: HideGroup) => void;
    quarterOptions: { label: string; value: { quarter: number; year: number } }[];
    setRunMonthlyValueOverrides: (runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null) => void;
}

const MarginInputsTable: React.FC<IMarginInputsTableProps> = (props: IMarginInputsTableProps) => (
    <div className={styles.margin_inputs_table}>
        <MarginInputsTableStickyColumn
            getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
            hideGroup={props.hideGroup}
            setHideGroup={props.setHideGroup}
        />
        <MarginInputTableData
            getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
            runCalculationEngineResponse={props.runCalculationEngineResponse}
            quarterOptions={props.quarterOptions}
            hideGroup={props.hideGroup}
            runMonthlyValueOverrides={props.runMonthlyValueOverrides}
            setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
            savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
        />
    </div>
);

export default MarginInputsTable;
