import { useState } from 'react';
import { demeterUsersApi } from '../../../../../Apis/Apis';
import { DemeterUserModel } from '../../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../../Apis/Hooks/useApiWithoutAutoExecute';
import ConfirmModal from '../../../../Components/Modals/ConfirmModal/ConfirmModal';
import ActionsCellButton from '../../../../Components/Tables/ActionsCellButton/ActionsCellButton';
import useLanguage from '../../../../Services/Language/useLanguageHook';

interface IDeactivateUserProps {
    show: boolean;
    user: DemeterUserModel;
    handleConfirmed: () => any;
}

const DeactivateUser: React.FC<IDeactivateUserProps> = (props: IDeactivateUserProps) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [removeSubscriptionChecked, setRemoveSubscriptionChecked] = useState(false);
    const [translations] = useLanguage();

    const [, deactivateDemeterUser] = useApiWithoutAutoExecute(() =>
        demeterUsersApi.deactivateDemeterUser(props.user.demeterUserTrialGuid, removeSubscriptionChecked),
    );

    if (!props.show) {
        return null;
    }

    return (
        <>
            <ActionsCellButton text={translations.users.actions.deactivate} handleClick={() => setShowConfirmModal(true)} />
            <ConfirmModal
                header={translations.users.text.confirmDeactivateHeader}
                message={translations.users.text.confirmDeactivateMessage}
                checkboxMessage={translations.users.text.removeMarketIntelligenceMessage}
                showModal={showConfirmModal}
                handleConfirm={async () => {
                    deactivateDemeterUser();
                    props.handleConfirmed();
                    setShowConfirmModal(false);
                }}
                handleCheckbox={(value: boolean) => {
                    setRemoveSubscriptionChecked(value);
                }}
                handleCancel={() => {
                    setShowConfirmModal(false);
                }}
            />
        </>
    );
};

export default DeactivateUser;
