import Dropdown from '../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { MarginQuarterOptions, MarginRequest } from '../../RiskDefinitions';

interface IEndingQuarterDropdownProps {
    marginRequest: MarginRequest;
    handleUpdateMarginRequest: (value: MarginRequest) => void;
    quarterOptions: MarginQuarterOptions[];
    disabled?: boolean;
}

const EndingQuarterDropdown = (props: IEndingQuarterDropdownProps) => {
    const [translations] = useLanguage();

    return (
        <div>
            <Dropdown
                testId="EndingQuarterDropdown"
                value={props.marginRequest.endingQuarter}
                options={props.quarterOptions}
                handleOptionChange={(value) => {
                    props.handleUpdateMarginRequest({
                        ...props.marginRequest,
                        endingQuarter: value,
                    });
                }}
                label={translations.risk.margin.fields.endingQuarter}
                disabled={props.disabled}
            />
        </div>
    );
};

export default EndingQuarterDropdown;
