import { useMemo, useState } from 'react';
import { MarketIndicatorModel } from '../../../../Generated/Raven-Demeter';
import languageService from '../../../Services/Language/LanguageService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import { ITreeNode } from '../../TreeView/TreeItem';
import ManageProducts from './ManageProducts';
import styles from './ProductsModal.module.scss';
import ProductsModalWrapper from './ProductsModalWrapper';

interface IManageMarketIndicatorsModal {
    title: string;
    selected: MarketIndicatorModel[];
    indicators: MarketIndicatorModel[];
    open: boolean;
    handleChange: (selectedOptions: MarketIndicatorModel[]) => void;
    handleClose: () => void;
}

const ManageMarketIndicatorsModal: React.FC<IManageMarketIndicatorsModal> = (props) => {
    const [translations] = useLanguage();
    const [indicatorNodesSelected, setIndicatorNodesSelected] = useState<ITreeNode[]>([]);

    const treeNodes = useMemo<ITreeNode[]>(() => {
        if (!props.indicators) {
            return [];
        }

        return props.indicators.map((indicator, index) => {
            const displayName = languageService.translate(indicator.displayName ?? '');
            const item: ITreeNode = {
                id: indicator.marketIndicatorGuid,
                value: `root_${indicator.market}_${displayName}`,
                name: displayName,
                parentId: `${indicator.market}`,
                parentValue: translations.market[indicator.market!],
                column: props.selected.some((option) => option.marketIndicatorGuid === indicator.marketIndicatorGuid) ? 'right' : 'left',
                displayOrder: props.selected.findIndex((x) => x.marketIndicatorGuid === indicator.marketIndicatorGuid),
                order: index,
                expanded: false,
                selectedProduct: false,
                children: [],
            };

            return item;
        });
    }, [props.indicators, props.selected, props.open]);

    const setSelectedIndicators = () => {
        if (!indicatorNodesSelected.length) {
            props.handleChange([]);
            props.handleClose();
            return;
        }

        const addIndicators = props.indicators.map((x) => ({
            displayName: x.displayName,
            guid: x.marketIndicatorGuid,
        }));

        const selectedIndicators = indicatorNodesSelected
            .filter((node: ITreeNode) => addIndicators.some((x) => x.guid === node.id))
            .map((node: ITreeNode) => ({ marketIndicatorGuid: addIndicators.find((x) => x.guid === node.id)!.guid } as MarketIndicatorModel));

        props.handleChange(selectedIndicators);
        props.handleClose();
    };

    return (
        <ProductsModalWrapper
            name="ManageMarketIndicatorModal"
            dataTarget={EventDataTargetsEnum.MangeModal}
            title={props.title}
            open={props.open}
            subTitle={translations.dashboard.indicators.manageModalSubHeading}
            isSetButtonDisabled={false}
            handleClose={props.handleClose}
            handleSave={setSelectedIndicators}
        >
            <div className={styles.products_modal_content}>
                <ManageProducts
                    productTreeNodes={treeNodes}
                    showSingleOrGroupOption
                    handleSelectedUpdate={setIndicatorNodesSelected}
                    searchPlaceholder={translations.dashboard.indicators.searchIndicatorPlaceholder}
                    availableSubHeader={translations.dashboard.indicators.availableIndicators}
                    selectSubHeader={translations.dashboard.indicators.selectedIndicators}
                />
            </div>
        </ProductsModalWrapper>
    );
};

export default ManageMarketIndicatorsModal;
