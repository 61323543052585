import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { demeterPageElementsApi, demeterPresentationTemplatesApi } from '../../../Apis/Apis';
import { DemeterPageElementModel, PresentationFormat } from '../../../Generated/Raven-Demeter';
import useApi from '../../Apis/Hooks/useApiHook';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import CheckboxInput from '../../Components/Form/Inputs/CheckboxInput';
import TextInput from '../../Components/Form/Inputs/TextInput';
import PageHeaderWithBackNavigation from '../../Components/Headers/PageHeaderWithBackNavigation';
import SortableList from '../../Components/Lists/SortableList/SortableList';
import useLanguage from '../../Services/Language/useLanguageHook';
import PresentationTemplateContentSelector from './PresentationTemplateContentSelector';
import PresentationTemplatePageElement from './PresentationTemplatePageElement';
import styles from './PresentationTemplatesPage.module.scss';

const PresentationTemplateAddPage: React.FC = () => {
    const [translations] = useLanguage();
    const navigate = useNavigate();
    const [name, setName] = useState<string>();
    const [format, setFormat] = useState<PresentationFormat>(PresentationFormat.Ppt);
    const [pageElement, setPageElement] = useState<DemeterPageElementModel | undefined>();
    const [pageElements, setPageElements] = useState<DemeterPageElementModel[]>([]);
    const [templatePageElements, setTemplatePageElements] = useState<DemeterPageElementModel[]>([]);

    const [, , listDemeterPageElementsResponse] = useApi(() => demeterPageElementsApi.listDemeterPageElements());

    useEffect(() => {
        if (!listDemeterPageElementsResponse) {
            return;
        }
        setPageElements(listDemeterPageElementsResponse?.rows ?? []);
    }, [listDemeterPageElementsResponse]);

    const [, addPresentationTemplate, addPresentationTemplateResponse] = useApiWithoutAutoExecute(
        () => {
            if (!isValid) {
                return null;
            }
            return demeterPresentationTemplatesApi.addPresentationTemplate({ name, format, rows: templatePageElements });
        },
        {
            successMessage: translations.presentationTemplates.success.addPresentationTemplateSuccess,
            errorMessage: translations.presentationTemplates.errors.addPresentationTemplateError,
        },
    );

    useEffect(() => {
        setName(undefined);
        setTemplatePageElements([]);
    }, [addPresentationTemplateResponse]);

    const navigateBack = () => {
        navigate(-1);
    };

    const handleSelect = (selected: boolean) => {
        if (!templatePageElements || !pageElement) {
            return;
        }
        if (selected) {
            templatePageElements?.push(pageElement);
            setTemplatePageElements([...templatePageElements]);
        } else {
            setTemplatePageElements([...templatePageElements.filter((x) => x !== pageElement)]);
        }
    };

    const isValid = !!name;

    const pageElementSelected = templatePageElements?.some((x) => x.demeterPageElementGuid === pageElement?.demeterPageElementGuid);

    return (
        <div className={styles.master_page_container}>
            <PageHeaderWithBackNavigation handleBackNavigation={navigateBack} title={translations.presentationTemplates.title.add} />
            <div className={styles.presentation_templates_main_container}>
                <div className={styles.presentation_templates_add_toolbar}>
                    <div className={styles.presentation_templates_add_toolbar_section}>
                        <div>{translations.presentationTemplates.fields.templateName}</div>
                        <TextInput value={name} handleTextChange={setName} required />
                    </div>
                    <div className={styles.presentation_templates_add_toolbar_section_separator} />
                    <div className={styles.presentation_templates_add_toolbar_section}>
                        <div>{translations.presentationTemplates.fields.templateFormat}</div>
                        <div className={styles.presentation_templates_add_toolbar_format_options}>
                            <CheckboxInput
                                isChecked={format === PresentationFormat.Pdf}
                                title="PDF"
                                handleIsChecked={() => setFormat(PresentationFormat.Pdf)}
                            />
                            <CheckboxInput
                                isChecked={format === PresentationFormat.Ppt}
                                title="PPT"
                                handleIsChecked={() => setFormat(PresentationFormat.Ppt)}
                            />
                        </div>
                    </div>
                    <div className={styles.presentation_templates_add_toolbar_section_separator} />
                    <div className={styles.presentation_templates_add_toolbar_section}>
                        <LinkButton title={translations.actions.save} type={LinkButtonType.Blue} onClick={addPresentationTemplate} disabled={!isValid} />
                    </div>
                </div>
                <div className={styles.presentation_templates_add_selection}>
                    <div className={styles.presentation_templates_add_selection_tree}>
                        <PresentationTemplateContentSelector
                            pageElements={pageElements}
                            templatePageElements={templatePageElements}
                            onSelect={setPageElement}
                        />
                    </div>
                    <div className={styles.presentation_templates_add_selection_preview}>
                        <PresentationTemplatePageElement pageElement={pageElement} selected={pageElementSelected} onSelect={handleSelect} />
                    </div>
                    <div className={styles.presentation_templates_add_selection_order}>
                        <SortableList
                            items={templatePageElements}
                            height={450}
                            renderItem={(item: DemeterPageElementModel) => (
                                <div>
                                    {item.region} {item.commodity} {item.pageType} {item.pageElementType}
                                </div>
                            )}
                            onSelect={setPageElement}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PresentationTemplateAddPage;
