/* eslint-disable class-methods-use-this */
import { MarketIndicatorFactorDataModel } from '../../../../Generated/Raven-Demeter';
import { IChartAreaRangeData, IChartData } from '../ChartDefinitions';

class MarketIndicatorChartService {
    convertToChartData(data: MarketIndicatorFactorDataModel[]): IChartData[] {
        return data
            .filter((x) => !!x.isActualValue)
            .map(
                (row) =>
                    ({
                        value: row.value ?? 0,
                        asOfDate: new Date(row.asOfDate),
                        isActualValue: row.isActualValue,
                    } as IChartData),
            );
    }

    filterByYears(data: (IChartData | IChartAreaRangeData)[], yearsBack: number): any[] {
        if (!data || data.length === 0) {
            return [];
        }

        const currentDate = data[data.length - 1].asOfDate;
        const cutoffDate = new Date();
        cutoffDate.setFullYear(currentDate.getFullYear() - yearsBack);

        return data.filter((item) => item.asOfDate >= cutoffDate);
    }

    getSeasonalAverageCalculationData = (chartData: IChartData[], minimumDate: Date, startMonth: number, endMonth: number) => {
        const today = new Date();
        const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
        const minimumMonth = Math.min(startMonth, endMonth) - 1;
        const maximumMonth = Math.max(startMonth, endMonth) - 1;
        const startMonthIsBeforeEndMonth = startMonth < endMonth;

        const startDateIsBeforeToday = minimumDate.getTime() < today.getTime();

        return chartData
            .filter((x) => new Date(x.asOfDate).getTime() < oneYearAgo.getTime())
            .filter((x) => {
                const monthOfCurrentDate = new Date(x.asOfDate).getMonth();

                if (!startMonthIsBeforeEndMonth && !startDateIsBeforeToday) {
                    return monthOfCurrentDate >= minimumMonth || monthOfCurrentDate <= maximumMonth;
                }

                if (startDateIsBeforeToday && !startMonthIsBeforeEndMonth) {
                    return monthOfCurrentDate + 1 >= startMonth || monthOfCurrentDate < endMonth;
                }

                return monthOfCurrentDate >= minimumMonth && monthOfCurrentDate <= maximumMonth;
            });
    };

    // Set all the years to be the current year.
    getSortedSeasonalAverageData = (calculationData: IChartData[], numberOfYears: number, minimumDate: Date, maximumDate: Date, endMonth: number) => {
        const dateByNumberOfYearsAgo = new Date().getFullYear() - numberOfYears - 1;

        return calculationData
            .filter((x) => new Date(x.asOfDate).getFullYear() > dateByNumberOfYearsAgo)
            .map((x) => {
                if (x.asOfDate.getTime() < minimumDate.getTime() && x.asOfDate.getMonth() < endMonth) {
                    return {
                        ...x,
                        asOfDate: new Date(maximumDate.getFullYear(), x.asOfDate.getMonth(), x.asOfDate.getDate()),
                    };
                }

                return {
                    ...x,
                    asOfDate: new Date(minimumDate.getFullYear(), x.asOfDate.getMonth(), x.asOfDate.getDate()),
                };
            })
            .sort((a, b) => b.asOfDate.getTime() - a.asOfDate.getTime());
    };
}

const marketIndicatorChartService = new MarketIndicatorChartService();
export default marketIndicatorChartService;
