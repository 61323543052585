import { useEffect, useMemo } from 'react';
import Dropdown from '../../../../Components/Form/Inputs/Dropdown';
import ComponentHeader from '../../../../Components/Headers/ComponentHeader';
import LoadingSpinner from '../../../../Components/LoadingSpinner/LoadingSpinner';
import formattingService from '../../../../Services/Formatting/FormattingService';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { MarginHeatMapTableDates, MarginRequest } from '../../RiskDefinitions';
import styles from './MarginHeatMapTable.module.scss';

interface IMarginHeatMapTableWrapperProps {
    children: JSX.Element;
    marginInputs: MarginRequest;
    marginTableDateRange: MarginHeatMapTableDates;
    setMarginTableDateRange: (marginTableDateRange: MarginHeatMapTableDates) => void;
    isLoading?: boolean;
}

const MarginHeatMapTableWrapper: React.FC<IMarginHeatMapTableWrapperProps> = (props: IMarginHeatMapTableWrapperProps) => {
    // Application hooks.
    const [translations] = useLanguage();

    const allDateOptions = useMemo(() => {
        if (!props.marginInputs) {
            return [];
        }

        const options = [];
        const start = new Date(props.marginInputs.startingQuarter.year, props.marginInputs.startingQuarter.quarter * 3 - 3, 1);
        const end = new Date(props.marginInputs.endingQuarter.year, props.marginInputs.endingQuarter.quarter * 3, 0);

        while (start.getTime() < end.getTime()) {
            const currentDate = new Date(start);
            options.push({
                label: formattingService.toMonthYear(currentDate),
                value: currentDate,
            });
            start.setMonth(start.getMonth() + 1);
        }

        return options;
    }, [props.marginInputs]);

    const coverageTableStartDateOptions = useMemo(
        () => allDateOptions.filter((x) => x.value.getTime() <= props.marginTableDateRange?.coverageTableEndDate.value.getTime()),
        [allDateOptions, props.marginTableDateRange?.coverageTableEndDate],
    );

    const coverageTableEndDateOptions = useMemo(
        () => allDateOptions.filter((x) => x.value.getTime() >= props.marginTableDateRange?.coverageTableStartDate.value.getTime()),
        [allDateOptions, props.marginTableDateRange?.coverageTableStartDate],
    );

    useEffect(() => {
        if (props.marginTableDateRange || allDateOptions.length === 0) {
            return;
        }

        props.setMarginTableDateRange({ coverageTableStartDate: allDateOptions[0], coverageTableEndDate: allDateOptions[2] });
    }, [allDateOptions]);

    return (
        <div className={styles.margin_table_wrapper}>
            <div className={styles.margin_table_wrapper_header}>
                <ComponentHeader title={translations.risk.headers.marginHeatMap} />

                <div className={styles.margin_table_wrapper_header_right_side}>
                    <div className={styles.margin_table_date_pickers}>
                        <div className={styles.margin_table_date_picker_margin}>
                            <Dropdown
                                value={props.marginTableDateRange?.coverageTableStartDate?.value}
                                options={coverageTableStartDateOptions}
                                handleOptionChange={(value) => {
                                    props.setMarginTableDateRange({
                                        ...props.marginTableDateRange,
                                        coverageTableStartDate: allDateOptions.find((x) => x.value === value)!,
                                    });
                                }}
                            />
                        </div>
                        <div className={styles.margin_table_date_picker_margin}> {translations.words.to}</div>
                        <Dropdown
                            value={props.marginTableDateRange?.coverageTableEndDate?.value}
                            options={coverageTableEndDateOptions}
                            handleOptionChange={(value) => {
                                props.setMarginTableDateRange({
                                    ...props.marginTableDateRange,
                                    coverageTableEndDate: allDateOptions.find((x) => x.value === value)!,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
            {props.isLoading ? (
                <div className={styles.margin_table_wrapper_loading_spinner}>
                    <LoadingSpinner />
                </div>
            ) : (
                <>
                    {props.children}
                    <div className={styles.margin_table_wrapper_footer_text}>
                        <div className={styles.margin_table_wrapper_gradient_div} />
                        {translations.risk.margin.text.severityOfCoverage}

                        <div className={styles.margin_table_wrapper_legend_key}>
                            <p>{translations.risk.margin.text.breakEvenShortText}</p>
                            <p>{translations.risk.margin.text.breakEven}</p>
                        </div>

                        <div className={styles.margin_table_wrapper_legend_key}>
                            <p>{translations.risk.margin.text.marketPriceShortText}</p>
                            <p>{translations.risk.margin.text.marketPrice}</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default MarginHeatMapTableWrapper;
