import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Children } from 'react';
import ErrorBoundary from '../../../Core/ErrorHandling/ErrorBoundary';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventActionsEnum, EventCategoriesEnum, EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../../Services/Logging/LoggingService';
import LinkButton, { LinkButtonType } from '../../Form/Buttons/LinkButton';
import ComponentSubHeader from '../../Headers/ComponentSubHeader';
import styles from './ProductsModal.module.scss';

interface IProductsModalWrapper {
    children: JSX.Element;
    dataTarget: EventDataTargetsEnum;
    isSetButtonDisabled: boolean;
    name: string;
    open: boolean;
    title: string;
    cancelButtonText?: string;
    leftFooter?: JSX.Element | boolean;
    setButtonText?: string;
    subTitle?: string;
    handleClose: () => void;
    handleSave: () => void;
}

const ProductsModalWrapper: React.FC<IProductsModalWrapper> = (props) => {
    const [translations] = useLanguage();

    const handleCloseWithAnalytics = () => {
        loggingService.trackEventWithAnalytics(EventActionsEnum.ModalInteraction, EventCategoriesEnum.ModalClose, props.name, props.dataTarget);
        props.handleClose();
    };

    const handleSaveWithAnalytics = () => {
        loggingService.trackEventWithAnalytics(EventActionsEnum.ModalInteraction, EventCategoriesEnum.ModalSave, props.name, props.dataTarget);
        props.handleSave();
    };

    return (
        <div>
            <Dialog disableEnforceFocus open={props.open} onClose={handleCloseWithAnalytics} sx={{ '& .MuiDialog-paper': { maxWidth: '740px' } }}>
                <DialogTitle>
                    <div className={styles.products_modal_header}>
                        <ComponentSubHeader title={props.title} />

                        <IconButton onClick={handleCloseWithAnalytics} size="small">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                    {props.subTitle && <h4 className={styles.products_modal_sub_header}>{props.subTitle}</h4>}
                </DialogTitle>
                <ErrorBoundary>
                    <DialogContent>{Children.only(props.children)}</DialogContent>
                </ErrorBoundary>
                <DialogActions>
                    <div className={styles.products_modal_selector_actions}>
                        <div>{props.leftFooter && Children.only(props.leftFooter)}</div>
                        <div className={styles.products_modal_actions_right}>
                            <LinkButton
                                title={props.cancelButtonText ? props.cancelButtonText : translations.calculators.basis.productSelector.actions.cancel}
                                type={LinkButtonType.White}
                                onClick={handleCloseWithAnalytics}
                            />
                            <LinkButton
                                title={props.setButtonText ? props.setButtonText : translations.calculators.basis.productSelector.actions.set}
                                type={LinkButtonType.Blue}
                                disabled={props.isSetButtonDisabled}
                                onClick={handleSaveWithAnalytics}
                            />
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ProductsModalWrapper;
