import { ColDef, RowClassParams, RowClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { MutableRefObject } from 'react';
import { DemeterUserStoreType } from '../../Generated/Raven-Demeter';

export interface IAgGridProps {
    gridRef?: MutableRefObject<AgGridReact | null>;
    rowData: any[];
    hasSaveColumnsState?: boolean;
    columnDefinitions: ColDef[];
    defaultColumnDefinition: any;
    onSelectionChanged?: any;
    gridHeightFull?: boolean;
    setColumnDefinitions?: Function;
    userStoreType?: DemeterUserStoreType;
    cellClickedHandler?: any;
    onGridReady?: any;
    theme?: string;
    debounce?: number;
    getRowStyle?: any;
    getRowClass?: RowClassParams;
    onRowClicked?: (event: RowClickedEvent) => void;
    domLayout?: 'autoHeight' | 'normal';
    disableExport?: boolean;
    suppressMovableColumns?: boolean;
    groupIncludeTotalFooter?: boolean;
    pinnedBottomRowData?: any;
    gridDataKey?: any;
    columnIdsToIgnoreUserStore?: string[];
    testId?: string;
}

export enum AgGridContextMenuItem {
    copy = 'copy',
    copyWithHeaders = 'copyWithHeaders',
    customExport = 'Export',
    copyWithGroupHeaders = 'copyWithGroupHeaders',
    paste = 'paste',
    separator = 'separator',
    export = 'export',
}

export interface AgGridRow {
    id?: string;
}
