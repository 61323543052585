import {
    CalculationEngineLineResultModel,
    CalculationEngineMonthlyValueOverrides,
    FarmerMarginCalculatorModel,
    RunCalculationEngineResponse,
} from '../../../../../../Generated/Raven-Demeter';
import { HideGroup, MarginCompositeModel, TabEventKeyType } from '../../../RiskDefinitions';
import MarginDataRow from '../MarginDataRow';
import MarginInputRow from '../MarginInputRow';
import styles from '../MarginInputsTable.module.scss';

interface IMarginInputsTableProductionDataProps {
    getFarmerMarginCalculatorCompositeRowsModel: MarginCompositeModel[];
    runCalculationEngineResponse: RunCalculationEngineResponse;
    savedFarmerMarginCalculator?: FarmerMarginCalculatorModel;
    runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null;
    setRunMonthlyValueOverrides: (runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null) => void;
    hideGroup: HideGroup;
    handleGridTabNavigation: (columnIndex: number, rowIndex: number, direction: TabEventKeyType) => void;
}

const MarginInputsTableProductionData: React.FC<IMarginInputsTableProductionDataProps> = (props: IMarginInputsTableProductionDataProps) => {
    const firstRowModel = props.getFarmerMarginCalculatorCompositeRowsModel[0];
    const topLevelBackground = styles.margin_inputs_table_top_level_background;

    return (
        <>
            {firstRowModel.productionLines.map((y) => (
                <MarginInputRow
                    className={topLevelBackground}
                    getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                    ancestors={[firstRowModel.futuresBlendPrice.variableName]}
                    hideGroup={props.hideGroup}
                    runCalculationEngineResponse={props.runCalculationEngineResponse}
                    fieldName={y.variableName}
                    handleGridTabNavigation={props.handleGridTabNavigation}
                    runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                    setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                    savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                />
            ))}
            {firstRowModel.productionLines?.length === 0 ? (
                <MarginInputRow
                    className={topLevelBackground}
                    getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                    hideGroup={props.hideGroup}
                    runCalculationEngineResponse={props.runCalculationEngineResponse}
                    fieldName={firstRowModel.production.variableName}
                    handleGridTabNavigation={props.handleGridTabNavigation}
                    runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                    setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                    savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                />
            ) : (
                <MarginDataRow
                    className={topLevelBackground}
                    getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                    runCalculationEngineResponse={props.runCalculationEngineResponse}
                    fieldName={firstRowModel.production.variableName as keyof CalculationEngineLineResultModel}
                />
            )}
        </>
    );
};

export default MarginInputsTableProductionData;
