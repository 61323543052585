import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { monthsOptions, SeasonalParameters, UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IMarketFactorStartMonthDropdownProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const MarketFactorStartMonthDropdown: React.FC<IMarketFactorStartMonthDropdownProps> = (props: IMarketFactorStartMonthDropdownProps) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={(props.upsertMarketIndicatorFactorRequest.parameters as SeasonalParameters).startMonth}
                handleOptionChange={(value) => {
                    props.setUpsertMarketIndicatorFactorRequest({
                        ...props.upsertMarketIndicatorFactorRequest,
                        parameters: {
                            ...props.upsertMarketIndicatorFactorRequest.parameters,
                            startMonth: value,
                        },
                    });
                }}
                options={monthsOptions}
                label={translations.marketIndicatorsManagement.fields.startMonth}
            />
        </div>
    );
};

export default MarketFactorStartMonthDropdown;
