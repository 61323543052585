/* eslint-disable class-methods-use-this */
import meanBy from 'lodash.meanby';
import moment from 'moment';
import { DemeterDataFrequency } from '../../../../../Generated/Raven-Demeter';
import formattingService from '../../../../Services/Formatting/FormattingService';
import { IChartData } from '../../ChartDefinitions';
import marketIndicatorChartDateService from '../MarketIndicatorChartDateService';

interface SeasonalWeek {
    week: number;
    average: number;
    previousWeek: number;
    previousWeekAverage: number;
}

class MarketIndicatorSeasonalChartService {
    calculateSeasonalAverageChange(data: IChartData[], yearsOfData: number, dataFrequency: DemeterDataFrequency = DemeterDataFrequency.Weekly): number {
        // This is a direct copy of the backend.
        const focusData = data[data.length - 1];
        const calculateAsOfDate = moment(focusData.asOfDate);

        if (dataFrequency === DemeterDataFrequency.Monthly) {
            const month = calculateAsOfDate.month();
            const currentDate = moment(new Date(calculateAsOfDate.year(), month, 1));
            const oldestDate = moment(currentDate).subtract(yearsOfData, 'years').toDate();
            const filteredData = data.filter((x) => {
                const asOfDate = moment(x.asOfDate);
                return asOfDate.isSameOrAfter(oldestDate) && asOfDate.isBefore(currentDate) && asOfDate.month() === month;
            });

            const previousMonthsData = filteredData.map((item) => {
                const previousData = data.filter((x) => moment(x.asOfDate).isBefore(item.asOfDate));
                const lastMonthData = previousData.reduce(
                    (maxItem, currentItem) => (moment(currentItem.asOfDate).isAfter(moment(maxItem.asOfDate)) ? currentItem : maxItem),
                    data[0] ?? null,
                );

                return lastMonthData ?? item;
            });

            const average = meanBy(filteredData, 'value');
            const previousMonthsAverage = meanBy(previousMonthsData, 'value');

            return average - previousMonthsAverage;
        }

        // This is a direct copy of the backend.
        const seasonalWeeks: SeasonalWeek[] = [];
        const week = formattingService.toWeekNumber(calculateAsOfDate.toDate());
        const previousWeek = formattingService.toWeekNumber(calculateAsOfDate.subtract(7, 'days').toDate());

        // Make sure we have all of the historical data.
        for (let i = 0; i <= yearsOfData; i += 1) {
            const focusAsOfDate = moment(focusData.asOfDate).subtract(i, 'years');
            const previousFocusAsOfDate = moment(focusAsOfDate).subtract(7, 'days');
            const filteredData = data.filter(
                (x) => Math.abs(moment(x.asOfDate).diff(focusAsOfDate, 'days')) < 14 && formattingService.toWeekNumber(x.asOfDate) === week,
            );
            const filteredDataPreviousWeek = data.filter(
                (x) => Math.abs(moment(x.asOfDate).diff(previousFocusAsOfDate, 'days')) < 14 && formattingService.toWeekNumber(x.asOfDate) === previousWeek,
            );

            const seasonalWeek: SeasonalWeek = {
                week,
                average: meanBy(filteredData, 'value'),
                previousWeek,
                previousWeekAverage: meanBy(filteredDataPreviousWeek, 'value'),
            };

            if (i !== 0) {
                seasonalWeeks.push(seasonalWeek);
            }
        }

        const average = meanBy(seasonalWeeks, 'average');
        const previousWeeksAverage = meanBy(seasonalWeeks, 'previousWeekAverage');
        return average - previousWeeksAverage;
    }

    calculateLastChange(data: IChartData[], dataFrequency: DemeterDataFrequency = DemeterDataFrequency.Weekly): number {
        if (dataFrequency === DemeterDataFrequency.Monthly) {
            return data[data.length - 1].value - data[data.length - 2].value;
        }

        const weeklyData = marketIndicatorChartDateService.toWeeklyData(data);
        return (weeklyData[weeklyData.length - 1]?.value ?? 0) - (weeklyData[weeklyData.length - 2]?.value ?? 0);
    }
}

const marketIndicatorSeasonalChartService = new MarketIndicatorSeasonalChartService();
export default marketIndicatorSeasonalChartService;
