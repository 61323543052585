import { useEffect, useState } from 'react';
import { demeterPageElementsApi } from '../../../Apis/Apis';
import { DemeterPageElementModel } from '../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import Switch from '../../Components/Form/Buttons/Switch';
import ComponentHeader from '../../Components/Headers/ComponentHeader';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './PresentationTemplatesPage.module.scss';

interface IPresentationTemplatePageElementProps {
    pageElement: DemeterPageElementModel | undefined;
    selected: boolean | undefined;
    onSelect: (selected: boolean) => void;
}

const PresentationTemplatePageElement: React.FC<IPresentationTemplatePageElementProps> = (props: IPresentationTemplatePageElementProps) => {
    const [translations] = useLanguage();
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    const [loading, getDemeterPageElementImage, imageResponse, imageResponseError] = useApiWithoutAutoExecute(
        () => {
            if (!props.pageElement) {
                return null;
            }
            return demeterPageElementsApi.getDemeterPageElementImage(props.pageElement.demeterPageElementGuid);
        },
        { errorMessage: translations.presentationTemplates.errors.getDemeterPageElementImageError },
    );

    useEffect(() => {
        getDemeterPageElementImage();
    }, [props.pageElement?.demeterPageElementGuid]);

    useEffect(() => {
        setImageSrc(null);
    }, [imageResponseError]);

    useEffect(() => {
        if (imageResponse) {
            const blob = new Blob([imageResponse], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(blob);
            setImageSrc(url);
        }

        return () => {
            if (imageSrc) {
                URL.revokeObjectURL(imageSrc);
            }
        };
    }, [imageResponse]);

    if (!props.pageElement) {
        return null;
    }

    return (
        <div className={styles.presentation_templates_page_element}>
            {loading ? (
                <div>
                    <LoadingSpinner />
                </div>
            ) : (
                <div>
                    <ComponentHeader
                        title={`${props.pageElement.region} ${props.pageElement.commodity} ${props.pageElement.pageType} ${props.pageElement.pageElementType}`}
                    />
                    {props.pageElement.description && (
                        <div className={styles.presentation_templates_page_element_description}>{props.pageElement.description}</div>
                    )}
                    <div className={styles.presentation_templates_page_element_image}>
                        {imageSrc ? (
                            <img src={imageSrc} alt="" />
                        ) : (
                            <div className={styles.presentation_templates_page_element_image_empty}>{translations.presentationTemplates.text.noImage}</div>
                        )}
                    </div>

                    <div>{translations.presentationTemplates.text.includeDescription}</div>
                    <div>
                        <Switch checked={props.selected} handleChange={props.onSelect} /> {translations.presentationTemplates.text.includeInExport}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PresentationTemplatePageElement;
