import { useEffect, useMemo, useState } from 'react';
import { demeterCalculatorsApi, demeterCompanyApi } from '../../../../Apis/Apis';
import {
    CalculationEngineMonthlyValueOverrides,
    DemeterFilterTimeSpan,
    DemeterPermissionType,
    RunCalculationEngineResponse,
    RunTestCalculationEngineRequest,
    UnitOfMeasure,
} from '../../../../Generated/Raven-Demeter';
import { useApplicationDispatch, useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectActingCompanyGuid, selectUserCurrentMarket, setActingDemeterCompanyGuid } from '../../../../Redux/Slices/UserSlice';
import useApi from '../../../Apis/Hooks/useApiHook';
import useApiWithoutAutoExecute from '../../../Apis/Hooks/useApiWithoutAutoExecute';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import chartService from '../../../Components/Charts/ChartService';
import JoinedSelectionButtons from '../../../Components/Form/Buttons/JoinedSelectionButtons/JoinedSelectionButtons';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import Dropdown from '../../../Components/Form/Inputs/Dropdown';
import { SelectInputOption } from '../../../Components/Form/Inputs/SelectInput';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import usePermission from '../../../Services/Permissions/usePermissionHook';
import { ValueOverrides } from '../../Calculators/ValorizationCalculator/ValorizationCalculatorDefinitions';
import { HideGroup, MarginCompositeModel, MarginQuarterValue, MarginRequest, UpsertCalculationEngine } from '../RiskDefinitions';
import styles from './Margin.module.scss';
import MarginChartsCarousel from './MarginCharts/MarginChartsCarousel';
import EndingQuarterDropdown from './MarginDropdowns/EndingQuarterDropdown';
import StartingQuarterDropdown from './MarginDropdowns/StartingQuarterDropdown';
import MarginHeatMapTable from './MarginHeatMapTable/MarginHeatMapTable';
import MarginInputsTable from './MarginInputsTable/MarginInputsTable';

const numberOfMonthsInQuarter = 3;
const defaultNumberOfQuarters = 6;
const defalutDisplayDecimalPlaces = 2;

const Margin: React.FC = () => {
    // Application hooks.
    const [translations, translate] = useLanguage();
    const symbols = useSymbolsApi();
    const userIsFarmerMarginAdministrator = usePermission(DemeterPermissionType.FarmerMarginAdministrator);
    const defaultMarket = useApplicationSelector(selectUserCurrentMarket);
    const currentCompanyGuid = useApplicationSelector(selectActingCompanyGuid);
    const dispatch = useApplicationDispatch();

    // Display hooks.
    const [displaySelection, setDisplaySelection] = useState('inputs');
    const [marginInputs, setMarginInputs] = useState<MarginRequest>();
    const [hideGroup, setHideGroup] = useState<HideGroup>({});
    const [selectedCalculator, setSelectedCalculator] = useState<SelectInputOption<string> & { groupName: string }>();

    // Request hook.
    const [runMonthlyValueOverrides, setRunMonthlyValueOverrides] = useState<Array<CalculationEngineMonthlyValueOverrides> | null>([]);
    const [upsertCalculationEngine, setUpsertCalculationEngine] = useState<UpsertCalculationEngine>({
        demeterCalculationEngineGuid: '',
        name: 'Farmer Margin',
        groupName: '',
        demeterCalculationEngineInputsGuid: '',
        valueOverrides: {} as { [key: string]: string },
        dataOverrides: {},
        monthlyValueOverrides: [],
    });

    // Api hooks.
    const [, refreshListFarmerMarginCalculator, listFarmerMarginCalculatorResponse] = useApi(() => demeterCalculatorsApi.listFarmerMarginCalculators());

    const [, refreshListDemeterCompaniesByPermission, listDemeterCompaniesByPermissionResponse] = useApi(() => {
        if (!userIsFarmerMarginAdministrator) {
            return null;
        }

        return demeterCompanyApi.listDemeterCompaniesByPermission(DemeterPermissionType.FarmerMarginCalculator);
    });

    const [, refreshRunCalculationEngine, runCalculationEngineResponse] = useApiWithoutAutoExecute<
        RunTestCalculationEngineRequest,
        RunCalculationEngineResponse
    >((request) => demeterCalculatorsApi.runTestCalculationEngine(upsertCalculationEngine.demeterCalculationEngineGuid!, request));

    const [, refreshAddCalculator, addCalculatorResponse] = useApiWithoutAutoExecute(
        () =>
            demeterCalculatorsApi.addCalculationEngineInputs(upsertCalculationEngine.demeterCalculationEngineGuid, {
                ...upsertCalculationEngine,
                monthlyValueOverrides: runMonthlyValueOverrides,
            }),
        {
            successMessage: translations.risk.margin.text.calculatorAddedSuccessfully,
        },
    );

    const [, refreshUpdateCalculator, updateCalculatorResponse] = useApiWithoutAutoExecute(
        () =>
            demeterCalculatorsApi.updateCalculationEngineInputs(
                upsertCalculationEngine.demeterCalculationEngineGuid!,
                upsertCalculationEngine.demeterCalculationEngineInputsGuid ?? '',
                { ...upsertCalculationEngine, monthlyValueOverrides: runMonthlyValueOverrides },
            ),
        {
            successMessage: translations.risk.margin.text.calculatorUpdatedSuccessfully,
        },
    );

    const [, refreshGetFarmerMarginCalculatorByGuid, getFarmerMarginCalculatorByGuidResponse] = useApiWithoutAutoExecute(() =>
        demeterCalculatorsApi.getFarmerMarginCalculator(selectedCalculator!.value),
    );

    const savedFarmerMarginCalculator = listFarmerMarginCalculatorResponse?.rows?.find((x) => x.displayName === selectedCalculator?.groupName);
    const stateOptions = getFarmerMarginCalculatorByGuidResponse?.farmerMarginCalculator?.stateOptions ?? [];
    const stateValueOverride = (upsertCalculationEngine.valueOverrides as ValueOverrides)[
        getFarmerMarginCalculatorByGuidResponse?.farmerMarginCalculator!.stateVariableName as keyof ValueOverrides
    ];
    const currentSelectionSavedRequestIsLoaded =
        getFarmerMarginCalculatorByGuidResponse?.farmerMarginCalculator?.demeterCalculationEngineGuid === upsertCalculationEngine.demeterCalculationEngineGuid;

    const getQuarter = (date: Date) => {
        const month = date.getMonth() + 1;

        return { quarter: Math.ceil(month / numberOfMonthsInQuarter), year: date.getFullYear() };
    };

    const getQuarterRange = (currentQuarter: MarginQuarterValue) => {
        const currentQuarterIsOutOfBounds =
            (marginInputs?.startingQuarter?.quarter! > currentQuarter.quarter && marginInputs?.startingQuarter?.year! === currentQuarter.year) ||
            (marginInputs?.endingQuarter?.quarter! < currentQuarter.quarter && marginInputs?.endingQuarter?.year! === currentQuarter.year);
        const currentYearIsOutOfBounds = marginInputs?.startingQuarter?.year! > currentQuarter.year || marginInputs?.endingQuarter?.year! < currentQuarter.year;

        if (currentQuarterIsOutOfBounds || currentYearIsOutOfBounds) {
            return false;
        }

        return true;
    };

    const getFarmerMarginCalculatorCompositeRowsModel = useMemo(() => {
        if (!getFarmerMarginCalculatorByGuidResponse) {
            return [];
        }

        return getFarmerMarginCalculatorByGuidResponse.farmerMarginCalculator?.rows
            .filter((x) => {
                const currentQuarter = getQuarter(new Date(x.asOfDate));
                return getQuarterRange(currentQuarter);
            })
            .map((x) => {
                const futuresBlendPriceAndPercentLevel2 = x.futuresPrices.lines.map((y) => [y.price, y.percent]).flat();
                const outputHedgedPriceOrVolumeLevel3 = x.outputs.map((y) =>
                    y.lines
                        .map((z) => [
                            { ...z.volume, displayName: `${z.volume.displayName} (${translations.unitOfMeasure[`Short${z.volume.unitOfMeasure!}`]})` },
                            z.price,
                        ])
                        .flat(),
                );

                const outputHedgedPriceAndVolumeLevel2 = [
                    ...x.outputs
                        .map((y, index) => [
                            {
                                ...y.hedgedVolume,
                                displayName: `${y.hedgedVolume.displayName} (${translations.unitOfMeasure[`Short${y.hedgedVolume.unitOfMeasure!}`]})`,
                                children: outputHedgedPriceOrVolumeLevel3[index],
                                combinedWith: y.hedgedPrice,
                            },
                            y.hedgedPrice,
                        ])
                        .flat(),
                ];

                const costPriceAndVolumeLevel3 = x.costs
                    .map((y) => [
                        {
                            ...y.hedgedVolume,
                            displayName: `${y.hedgedVolume.displayName} (${translations.unitOfMeasure[`Short${y.hedgedVolume.unitOfMeasure!}`]})`,
                            combinedWith: y.hedgedPrice,
                            children: [
                                y.needs,
                                y.price,
                                ...y.lines.map((z) => [
                                    { ...z.volume, displayName: `${z.volume.displayName} (${translations.unitOfMeasure[`Short${z.volume.unitOfMeasure!}`]})` },
                                    z.price,
                                ]),
                            ].flat(),
                        },
                        {
                            ...y.hedgedPrice,
                        },
                    ])
                    .flat();

                if (x.outputAdjustment) {
                    outputHedgedPriceAndVolumeLevel2.push(x.outputAdjustment);
                }

                const fields = {
                    asOfDate: x.asOfDate,
                    futuresBlendPrice: {
                        ...x.futuresPrices.futuresBlendPrice,
                        displayName: `${x.futuresPrices.futuresBlendPrice.displayName} (${chartService.getCurrencyAndUnitOfMeasureText(
                            x.futuresPrices.futuresBlendPrice.unitOfMeasure,
                            x.futuresPrices.futuresBlendPrice.currency,
                        )})`,
                        children: futuresBlendPriceAndPercentLevel2,
                    },
                    productionLines: x.productionLines.map((y) => ({
                        ...y,
                        displayName:
                            y.unitOfMeasure === UnitOfMeasure.Head
                                ? y.displayName
                                : `${y.displayName} (${translations.unitOfMeasure[`Short${y.unitOfMeasure!}`]})`,
                    })),
                    production: {
                        ...x.production,
                        displayName: `${x.production.displayName} (${translations.unitOfMeasure[`Short${x.production.unitOfMeasure!}`]})`,
                    },
                    outputHedgedVolume: {
                        ...x.outputHedgedVolume,
                        displayName: `${x.outputHedgedVolume.displayName} (${translations.unitOfMeasure[`Short${x.outputHedgedVolume.unitOfMeasure!}`]})`,
                        children: outputHedgedPriceAndVolumeLevel2,
                        combinedWith: x.outputHedgedPrice,
                    },
                    outputHedgedPrice: {
                        ...x.outputHedgedPrice,
                    },
                    costTotalPrice: {
                        ...x.costTotalPrice,
                        displayName: `${x.costTotalPrice.displayName} (${chartService.getCurrencyAndUnitOfMeasureText(
                            x.costTotalPrice.unitOfMeasure,
                            x.costTotalPrice.currency,
                        )})`,
                        hideGroup: hideGroup[x.costTotalPrice.variableName],
                        children: [
                            {
                                ...x.costLinesPrice,
                                displayName: `${x.costLinesPrice.displayName} (${translations.unitOfMeasure[`Short${x.costLinesPrice.unitOfMeasure!}`]})`,
                                children: [...costPriceAndVolumeLevel3],
                            },
                            {
                                ...x.costOtherPrice,
                                displayName: `${x.costOtherPrice.displayName} (${translations.unitOfMeasure[`Short${x.costOtherPrice.unitOfMeasure!}`]})`,
                            },
                        ],
                    },
                    hedgedMargin: {
                        ...x.marginHedged,
                        displayName: `${x.marginHedged.displayName} (${chartService.getCurrencyAndUnitOfMeasureText(
                            x.marginHedged.unitOfMeasure,
                            x.marginHedged.currency,
                        )})`,
                    },
                    outputHedgedPercent: { ...x.outputHedgedPercent },
                    unhedgedMargin: {
                        ...x.marginUnhedged,
                        displayName: `${x.marginUnhedged.displayName}  (${chartService.getCurrencyAndUnitOfMeasureText(
                            x.marginUnhedged.unitOfMeasure,
                            x.marginUnhedged.currency,
                        )})`,
                    },
                    netMargin: {
                        ...x.marginNet,
                        displayName: `${x.marginNet.displayName}  (${chartService.getCurrencyAndUnitOfMeasureText(
                            x.marginNet.unitOfMeasure,
                            x.marginNet.currency,
                        )})`,
                    },
                };

                return fields;
            });
    }, [hideGroup, marginInputs, getFarmerMarginCalculatorByGuidResponse]);

    // Dropdown options.
    const availableQuarters = useMemo(() => {
        if (!runCalculationEngineResponse?.results) {
            return [];
        }
        const options = [];

        const thisQuarter = new Date(runCalculationEngineResponse.results[2].asOfDate!);
        const firstQuarter = getQuarter(thisQuarter);
        let currentYear = thisQuarter.getFullYear();

        let origionalQuarter = firstQuarter.quarter;
        const totalQuarters = defaultNumberOfQuarters + origionalQuarter - 1;

        for (let index = origionalQuarter; index <= totalQuarters; index += 1) {
            options.push({ label: `Q${origionalQuarter} ${currentYear}`, value: { quarter: origionalQuarter, year: currentYear } });

            if (origionalQuarter === 4) {
                origionalQuarter = 1;
                currentYear += 1;
            } else {
                origionalQuarter += 1;
            }
        }

        return options;
    }, [runCalculationEngineResponse?.displayName]);

    const marginOptions = useMemo(
        () =>
            listFarmerMarginCalculatorResponse?.rows!.map((x) => ({
                label: translate(x.displayName),
                value: x.demeterCalculationEngineGuid,
                groupName: x.displayName,
            })),
        [listFarmerMarginCalculatorResponse],
    );

    const selectionGroupOptions = useMemo(
        () => [
            {
                label: translations.words.inputs,
                value: 'inputs',
            },
            {
                label: translations.words.charts,
                value: 'charts',
            },
        ],
        [],
    );

    const companiesList = useMemo(
        () =>
            listDemeterCompaniesByPermissionResponse?.rows!.map((x) => ({
                label: x.name,
                value: x.demeterCompanyGuid!,
            })),
        [listDemeterCompaniesByPermissionResponse],
    );

    useEffect(() => {
        if (!currentCompanyGuid) {
            return;
        }

        refreshListFarmerMarginCalculator();
    }, [currentCompanyGuid]);

    // If we select a different calculator, check for saved version. Reset relevant items.
    useEffect(() => {
        if (!listFarmerMarginCalculatorResponse || !selectedCalculator) {
            return;
        }

        handleResetToLastSave();
        setHideGroup({});
        refreshGetFarmerMarginCalculatorByGuid();

        if (!savedFarmerMarginCalculator && selectedCalculator) {
            setUpsertCalculationEngine({
                ...upsertCalculationEngine,
                groupName: selectedCalculator.groupName,
                demeterCalculationEngineGuid: selectedCalculator.value,
            });

            return;
        }

        const upsertRequest = {
            ...upsertCalculationEngine,
            groupName: selectedCalculator.groupName,
            demeterCalculationEngineGuid: savedFarmerMarginCalculator!.demeterCalculationEngineGuid,
            demeterCalculationEngineInputsGuid: savedFarmerMarginCalculator!.demeterCalculationEngineInputsGuid ?? '',
            valueOverrides: savedFarmerMarginCalculator!.valueOverrides ?? {},
        };

        const stateDoesNotExistForUser = !stateOptions.find(
            (x) => x.value === savedFarmerMarginCalculator?.valueOverrides[getFarmerMarginCalculatorByGuidResponse!.farmerMarginCalculator!.stateVariableName],
        );

        if (stateDoesNotExistForUser) {
            upsertRequest.valueOverrides = {};
        }

        setUpsertCalculationEngine(upsertRequest);
    }, [listFarmerMarginCalculatorResponse, selectedCalculator, getFarmerMarginCalculatorByGuidResponse?.farmerMarginCalculator?.demeterCalculationEngineGuid]);

    // When we have new criteria, re-run the calculation engine.
    useEffect(() => {
        if (!listFarmerMarginCalculatorResponse?.rows || !upsertCalculationEngine.demeterCalculationEngineGuid) {
            return;
        }

        refreshRunCalculationEngine({
            demeterCalculationEngineGuid: selectedCalculator?.value!,
            filterHistoryTimeSpan: DemeterFilterTimeSpan.OneMonth,
            showHistory: true,
            dataOverrides: {},
            valueOverrides: upsertCalculationEngine.valueOverrides,
            monthlyValueOverrides: runMonthlyValueOverrides,
        });
    }, [listFarmerMarginCalculatorResponse, runMonthlyValueOverrides, upsertCalculationEngine, hideGroup, currentCompanyGuid]);

    // Set the initial selected calculator once the options are available.
    useEffect(() => {
        if (!marginOptions || marginOptions?.length === 0) {
            return;
        }

        refreshGetFarmerMarginCalculatorByGuid();

        if (!selectedCalculator) {
            setSelectedCalculator(marginOptions[0]);
        }
    }, [listFarmerMarginCalculatorResponse, marginOptions]);

    // Set initial margin inputs.
    useEffect(() => {
        if (!symbols || availableQuarters.length === 0) {
            return;
        }

        setMarginInputs({
            market: defaultMarket,
            product: symbols?.find((x) => x.symbolCategory === defaultMarket)!,
            startingQuarter: availableQuarters[0]?.value,
            endingQuarter: availableQuarters[1]?.value,
        });
    }, [symbols, availableQuarters]);

    // After add or update is complete, refresh the saved items.
    useEffect(() => {
        if (!addCalculatorResponse && !updateCalculatorResponse) {
            return;
        }

        refreshListFarmerMarginCalculator();
    }, [addCalculatorResponse, updateCalculatorResponse]);

    const handleSave = () => {
        if (!savedFarmerMarginCalculator) {
            refreshAddCalculator();
        } else {
            refreshUpdateCalculator();
        }
    };

    const handleResetToLastSave = () => {
        const origionalMonthlyOverrides = savedFarmerMarginCalculator?.monthlyValueOverrides.map((x) => ({
            ...x,
            asOfDate: formattingService.toApiDate(x.asOfDate),
        }));

        setHideGroup({});
        setRunMonthlyValueOverrides(origionalMonthlyOverrides ?? []);
        setUpsertCalculationEngine({ ...upsertCalculationEngine, valueOverrides: savedFarmerMarginCalculator?.valueOverrides ?? {} });
    };

    const handleResetOverrides = () => {
        setRunMonthlyValueOverrides([]);
        setUpsertCalculationEngine({ ...upsertCalculationEngine, valueOverrides: {} });
    };

    const loading =
        !marginInputs ||
        !getFarmerMarginCalculatorCompositeRowsModel ||
        !getFarmerMarginCalculatorByGuidResponse ||
        getFarmerMarginCalculatorCompositeRowsModel?.length === 0;

    return loading ? (
        <PageLoadingSpinner />
    ) : (
        <div className={styles.margin}>
            <div>
                <ComponentHeader title={translations.risk.margin.title} />

                <div data-testid="MarginCalculator" className={styles.margin_dropdown_inputs_container}>
                    <div className={styles.margin_dropdown}>
                        <Dropdown
                            label={translations.risk.margin.fields.calculator}
                            value={marginOptions!.find((x) => x.value === selectedCalculator!.value)?.value}
                            options={marginOptions!}
                            handleOptionChange={(value) => {
                                setSelectedCalculator(marginOptions?.find((x) => x.value === value));
                            }}
                            disabled={displaySelection === 'charts'}
                        />
                    </div>
                    {stateOptions.length > 0 && currentSelectionSavedRequestIsLoaded && (
                        <div className={styles.margin_dropdown}>
                            <Dropdown
                                label={translations.risk.margin.fields.state}
                                value={stateValueOverride}
                                options={stateOptions}
                                handleOptionChange={(value) => {
                                    setUpsertCalculationEngine({
                                        ...upsertCalculationEngine,
                                        valueOverrides: { [getFarmerMarginCalculatorByGuidResponse.farmerMarginCalculator!.stateVariableName]: value },
                                    });
                                }}
                                disabled={displaySelection === 'charts'}
                            />
                        </div>
                    )}
                    <div className={styles.margin_dropdown}>
                        <StartingQuarterDropdown
                            marginRequest={marginInputs!}
                            handleUpdateMarginRequest={setMarginInputs}
                            quarterOptions={availableQuarters.filter(
                                (x) =>
                                    (x.value.quarter <= marginInputs!.endingQuarter.quarter && x.value.year === marginInputs!.endingQuarter.year) ||
                                    x.value.year < marginInputs!.endingQuarter.year,
                            )}
                            disabled={displaySelection === 'charts'}
                        />
                    </div>

                    <div className={styles.margin_dropdown}>
                        <EndingQuarterDropdown
                            marginRequest={marginInputs!}
                            handleUpdateMarginRequest={setMarginInputs}
                            quarterOptions={availableQuarters.filter(
                                (x) =>
                                    (x.value.year === marginInputs.endingQuarter.year && x.value.quarter >= marginInputs!.startingQuarter.quarter) ||
                                    x.value.year > marginInputs!.startingQuarter.year,
                            )}
                            disabled={displaySelection === 'charts'}
                        />
                    </div>
                    {userIsFarmerMarginAdministrator && (
                        <div className={styles.margin_dropdown}>
                            <Dropdown
                                label={translations.risk.margin.fields.company}
                                value={companiesList?.find((x) => x.value === currentCompanyGuid)?.value}
                                options={companiesList!}
                                handleOptionChange={(value) => dispatch(setActingDemeterCompanyGuid({ actingDemeterCompanyGuid: value }))}
                                disabled={displaySelection === 'charts'}
                            />
                        </div>
                    )}
                </div>

                <div className={styles.margin_actions_buttons_row}>
                    <JoinedSelectionButtons
                        name={EventDataTargetsEnum.MarginJoinedSelectionButton}
                        options={selectionGroupOptions}
                        handleSelectionChange={(selectionValue) => setDisplaySelection(selectionValue)}
                        selection={displaySelection}
                        testId="MarginSelectionButtons"
                    />

                    <div className={styles.margin_actions_save_display_area}>
                        <p>
                            {savedFarmerMarginCalculator?.modifiedAt &&
                                `${translations.words.saved} ${formattingService.toLongDateAndTimeFormat(new Date(savedFarmerMarginCalculator?.modifiedAt))}
                                    ${translations.words.by} ${savedFarmerMarginCalculator?.modifiedBy}`}
                        </p>
                        <div className={styles.margin_actions_buttons}>
                            <LinkButton
                                onClick={handleResetToLastSave}
                                title={translations.actions.loadLastSave}
                                type={LinkButtonType.White}
                                disabled={false}
                            />
                            <LinkButton onClick={handleResetOverrides} title={translations.actions.reset} type={LinkButtonType.White} disabled={false} />

                            <LinkButton onClick={handleSave} title={translations.actions.save} type={LinkButtonType.Blue} disabled={false} />
                        </div>
                    </div>
                </div>

                {displaySelection === 'inputs' && (
                    <div data-testid="MarginComparisonTable" className={styles.risk_grid_wrapper}>
                        <MarginInputsTable
                            runCalculationEngineResponse={runCalculationEngineResponse!}
                            getFarmerMarginCalculatorCompositeRowsModel={getFarmerMarginCalculatorCompositeRowsModel as MarginCompositeModel[]}
                            quarterOptions={availableQuarters.filter((x) => {
                                const currentQuarter = x.value;
                                return getQuarterRange(currentQuarter);
                            })}
                            hideGroup={hideGroup}
                            setHideGroup={setHideGroup}
                            runMonthlyValueOverrides={runMonthlyValueOverrides}
                            savedFarmerMarginCalculator={savedFarmerMarginCalculator}
                            setRunMonthlyValueOverrides={setRunMonthlyValueOverrides}
                        />
                    </div>
                )}

                {displaySelection === 'charts' && (
                    <>
                        <MarginChartsCarousel
                            getFarmerMarginCalculatorCompositeRowsModels={getFarmerMarginCalculatorCompositeRowsModel}
                            runCalculationEngineResponse={runCalculationEngineResponse!}
                            displayDecimalPlaces={defalutDisplayDecimalPlaces}
                        />
                        <MarginHeatMapTable
                            marginInputs={marginInputs}
                            upsertCalculationEngine={upsertCalculationEngine}
                            runMonthlyValueOverrides={runMonthlyValueOverrides}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default Margin;
