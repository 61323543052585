import { memo, useMemo } from 'react';
import {
    DemeterCalculationEngineLineModel,
    DemeterDataFrequency,
    DemeterDataSource,
    DemeterTableDefinitionType,
    LeadingIndicatorType,
    RunCalculationEngineResponse,
} from '../../../../../Generated/Raven-Demeter';
import useSymbolsApi from '../../../../Apis/Hooks/useSymbolsApiHook';
import useTableDefinitionsApi from '../../../../Apis/Hooks/useTableDefinitionsApiHook';
import { IChartDataSeries } from '../../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../../Components/Charts/ChartWrapper/ChartWrapper';
import PriceChartRaw from '../../../../Components/Charts/Price/PriceChartRaw';
import useCurrency from '../../../../Core/Hooks/useCurrencyHook';
import useUnitOfMeasure from '../../../../Core/Hooks/useUnitOfMeasureHook';
import formattingService from '../../../../Services/Formatting/FormattingService';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { ValorizationStreamRunRequest } from '../ValorizationCalculatorDefinitions';

interface IValorizationCommodityPricesChart {
    runCalculationEngineResponses: RunCalculationEngineResponse[];
    valorizationStreamRunRequests: ValorizationStreamRunRequest[];
}

const defaultDataFrequency = DemeterDataFrequency.Monthly;

const ValorizationCommodityPricesChart: React.FC<IValorizationCommodityPricesChart> = (props: IValorizationCommodityPricesChart) => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    // Currency and unit of measure.
    const [currency] = useCurrency();
    const [unitOfMeasure] = useUnitOfMeasure('General');

    const symbols = useSymbolsApi();
    const otcTableDefinitions = useTableDefinitionsApi(DemeterTableDefinitionType.CommodityOtcPricesTable);
    const pricesTableDefinitions = useTableDefinitionsApi(DemeterTableDefinitionType.CommodityPricesTable);

    const linesSeries = useMemo<IChartDataSeries[]>(() => {
        if (
            !props.runCalculationEngineResponses?.length ||
            props.runCalculationEngineResponses.length === 0 ||
            !symbols ||
            !otcTableDefinitions ||
            !pricesTableDefinitions
        ) {
            return [];
        }

        const filteredCommodities = props.valorizationStreamRunRequests?.map((x) => x.calculationEngineLines?.filter((y) => y.commodity)).flat();
        const distinctFilteredCommodities = filteredCommodities.reduce((distinctCommodities, current) => {
            const match = distinctCommodities.find((x) => x.commodity === current.commodity);
            if (!match) {
                return distinctCommodities.concat([current]);
            }
            return distinctCommodities;
        }, [] as DemeterCalculationEngineLineModel[]);

        const sortedAggregateResults = props.runCalculationEngineResponses
            .map((x) => x.results)
            .flat()
            .sort((a, b) => new Date(a?.asOfDate!).getTime() - new Date(b?.asOfDate!).getTime());

        return distinctFilteredCommodities.map((commodityLine) => {
            let label = '---';
            if (commodityLine.leadingIndicatorType === LeadingIndicatorType.CommodityFuturesPrice) {
                const symbol = symbols.find((x) => x.region === commodityLine.region! && x.commodity === commodityLine.commodity!);
                label = formattingService.toDisplayName(symbol);
            } else if (commodityLine.leadingIndicatorType === LeadingIndicatorType.CommodityMonthlyPrices) {
                const tableDefinitions =
                    pricesTableDefinitions
                        .find((x) => x.region === commodityLine.region)
                        ?.demeterTableDefinitionGroups.flatMap((x) => x.demeterTableDefinitions) ?? [];
                const matchingTableDefinition = tableDefinitions.find(
                    (x) =>
                        x.commodity === commodityLine.commodity &&
                        (!commodityLine.subRegion || x.subRegions.some((y) => y === commodityLine.subRegion!)) &&
                        (x.extraParameters === commodityLine.extraParameters ||
                            (!x.extraParameters && commodityLine.extraParameters === DemeterDataSource.All)),
                );

                label = translate(matchingTableDefinition?.displayName ?? '');
            }

            let forecastLabel = '---';
            if (commodityLine.secondaryLeadingIndicatorType === LeadingIndicatorType.CommodityFuturesPrice) {
                const symbol = symbols.find((x) => x.region === commodityLine.secondaryRegion! && x.commodity === commodityLine.secondaryCommodity!);
                forecastLabel = `${formattingService.toDisplayName(symbol)} ${translations.text.forwardCurve}`;
            } else if (commodityLine.secondaryLeadingIndicatorType === LeadingIndicatorType.CommodityMonthlyPrices) {
                const tableDefinitions =
                    pricesTableDefinitions
                        .find((x) => x.region === commodityLine.secondaryRegion)
                        ?.demeterTableDefinitionGroups.flatMap((x) => x.demeterTableDefinitions) ?? [];
                const matchingTableDefinition = tableDefinitions.find(
                    (x) =>
                        x.commodity === commodityLine.secondaryCommodity &&
                        (!commodityLine.secondarySubRegion || x.subRegions.some((y) => y === commodityLine.secondarySubRegion!)) &&
                        (x.extraParameters === commodityLine.secondaryExtraParameters ||
                            (!x.extraParameters && commodityLine.secondaryExtraParameters === DemeterDataSource.All)),
                );

                forecastLabel = `${translate(matchingTableDefinition?.displayName ?? '')} ${translations.words.forecast}`;
            } else if (commodityLine.secondaryLeadingIndicatorType === LeadingIndicatorType.CommodityOtcPrices) {
                forecastLabel =
                    otcTableDefinitions
                        .find((x) => x.region === commodityLine.secondaryRegion)
                        ?.demeterTableDefinitionGroups.find((x) => x.commodity === commodityLine.secondaryCommodity)?.displayName ?? '';
                forecastLabel = `${translate(forecastLabel)} ${translations.text.forwardCurve}`;
            }

            const filteredResults = sortedAggregateResults?.filter((y) => y?.lines.find((z) => z.variableName === commodityLine.variableName)?.value!);
            const data = filteredResults.map((x) => ({
                value: x!.lines.find((y) => y.variableName === commodityLine.variableName)?.value,
                asOfDate: new Date(x!.asOfDate),
                isActualValue: new Date(x!.asOfDate).getTime() < new Date().getTime(),
            }));

            return {
                label,
                forecastLabel,
                data,
                isPrimaryLine: false,
            };
        }) as IChartDataSeries[];
    }, [props.runCalculationEngineResponses, symbols, otcTableDefinitions, pricesTableDefinitions]);

    const titleString = useMemo(() => `${translations.words.commodity} ${translations.words.prices}`, [translations]);

    const dataSourceTag = useMemo(() => translations.dataSource.StoneXCalculations, [translations]);

    const loading = !props.runCalculationEngineResponses;

    return (
        <ChartWrapper name="ValorizationCommodityPricesChart" title={titleString} dataSourceTag={dataSourceTag} isLoading={loading}>
            <PriceChartRaw
                linesSeries={linesSeries}
                currency={currency}
                unitOfMeasure={unitOfMeasure}
                hidePriceNavigator
                dataFrequency={defaultDataFrequency}
            />
        </ChartWrapper>
    );
};

export default memo(ValorizationCommodityPricesChart);
