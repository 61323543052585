import { memo, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { demeterCalculatorsApi } from '../../../../Apis/Apis';
import {
    DemeterCalculationEngineInputsGroupModel,
    DemeterCalculationEngineInputsModel,
    DemeterCalculatorType,
    UpdateCalculationEngineInputsRequest,
} from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import useApi from '../../../Apis/Hooks/useApiHook';
import useCacheThenApi from '../../../Apis/Hooks/useCacheThenApiHook';
import useMultipleApis from '../../../Apis/Hooks/useMultipleApisHook';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import Dropdown from '../../../Components/Form/Inputs/Dropdown';
import LabelWithTooltip from '../../../Components/Form/Inputs/LabelWithTooltip';
import PageHeader from '../../../Components/Headers/PageHeader';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import useCurrency from '../../../Core/Hooks/useCurrencyHook';
import useUnitOfMeasure from '../../../Core/Hooks/useUnitOfMeasureHook';
import CacheKeys from '../../../Services/Cache/CacheKeys';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './ValorizationCalculator.module.scss';
import {
    BaseValorizationUpsertRequest,
    UpsertStreamRequest,
    ValorizationStreamRunRequest,
    ValorizationUpsertRequest,
} from './ValorizationCalculatorDefinitions';
import ValorizationCarousel from './ValorizationCharts/ValorizationCarousel';
import ValorizationAddStreamAction from './ValorizationModals/ValorizationRequestActions/ValorizationAddStreamAction';
import ValorizationMilkSolidsPercentAction from './ValorizationModals/ValorizationRequestActions/ValorizationMilkSolidsPercentAction';
import ValorizationSaveAsAction from './ValorizationModals/ValorizationRequestActions/ValorizationSaveAsAction';
import ValorizationStream from './ValorizationStream';

// Defaults.
const defaultDemeterCalculationEngineGuids = [
    '00000000-0000-0000-0000-000000010005',
    '00000000-0000-0000-0000-000000010015',
    '00000000-0000-0000-0000-000000010025',
];

const ValorizationCalculator: React.FC = () => {
    // Application hooks.
    const [translations, translate] = useLanguage();
    const [searchParameters, setSearchParameters] = useSearchParameters();

    // Currency and unit of measure.
    const [currency] = useCurrency();
    const [unitOfMeasure] = useUnitOfMeasure('General');

    // Request hooks. Upsert request is only for the data we send in. Run requests are what the user sees. Monthly
    // overrides are only on the run request and valueOverrides for the run request include the saved ones. Upsert
    // value overrides are only the 'new' value overrides we want to add.
    const [baseValorizationUpsertRequest, setBaseValorizationUpsertRequest] = useState<BaseValorizationUpsertRequest>();
    const [valorizationUpsertRequests, setValorizationUpsertRequests] = useState<ValorizationUpsertRequest[]>();
    const [valorizationStreamRunRequests, setValorizationStreamRunRequests] = useState<ValorizationStreamRunRequest[]>();
    const [upsertStreamRequest, setUpsertStreamRequest] = useState<UpsertStreamRequest>();

    // Api hooks.
    const [, , listCalculationEngineResponse] = useCacheThenApi(CacheKeys.ListCalculationEngineResponse, () =>
        demeterCalculatorsApi.listCalculationEngines(DemeterCalculatorType.Valorization),
    );

    const [, refreshListCalculationEngineInputsByGroup, listCalculationEngineInputsByGroupResponse] = useApi(() =>
        demeterCalculatorsApi.listCalculationEngineInputsByGroup(DemeterCalculatorType.Valorization),
    );

    const [, refreshGetCalculationEngineResponses, getCalculationEngineResponses] = useMultipleApis(() => {
        if (!listCalculationEngineInputsByGroupResponse) {
            return [];
        }

        const demeterCalculationEngineGuids = [
            ...defaultDemeterCalculationEngineGuids,
            ...(listCalculationEngineInputsByGroupResponse.rows?.map((x) => x.calculationEngineInputs.map((y) => y.demeterCalculationEngineGuid))?.flat() ??
                []),
            upsertStreamRequest?.demeterCalculationEngineGuid,
            upsertStreamRequest?.overrideDemeterCalculationEngineGuid ?? '',
        ];

        return Array.from(new Set(demeterCalculationEngineGuids))
            .filter(Boolean)
            .map((x) => demeterCalculatorsApi.getCalculationEngine(x!));
    });

    const [, refreshAddCalculationEngineInputs, addCalculationEngineInputsResponse] = useMultipleApis(
        () => {
            if (!valorizationUpsertRequests || valorizationUpsertRequests.length === 0) {
                return [];
            }

            return valorizationUpsertRequests
                .filter((x) => x.demeterCalculationEngineGuid)
                .filter((x) => !x.demeterCalculationEngineInputsGuid || x.groupName !== searchParameters.groupName)
                .filter((x) => !x.isDeleted)
                .map((x) => {
                    const calculatedOverrides = valorizationStreamRunRequests?.find((y) => x.name === y.name)?.valueOverrides ?? {};

                    return demeterCalculatorsApi.addCalculationEngineInputs(x.demeterCalculationEngineGuid!, {
                        ...x,
                        valueOverrides: { ...calculatedOverrides, ...x?.valueOverrides },
                        dataOverrides: {},
                    } as UpdateCalculationEngineInputsRequest);
                });
        },
        {
            stopAutoExecute: true,
            successMessage: translations.calculators.valorization.messages.addCalculationEnginesInputsSuccessful,
            errorMessage: translations.calculators.valorization.messages.addCalculationEnginesInputsFailed,
        },
    );

    const [, refreshUpdateCalculationEngineInputs, updateCalculationEngineInputsResponse] = useMultipleApis(
        () => {
            if (!valorizationUpsertRequests || valorizationUpsertRequests.length === 0) {
                return [];
            }

            return valorizationUpsertRequests
                ?.filter((x) => x.demeterCalculationEngineInputsGuid)
                .map((x) => {
                    const calculatedOverrides = valorizationStreamRunRequests?.find((y) => x.name === y.name)?.valueOverrides ?? {};

                    return demeterCalculatorsApi.updateCalculationEngineInputs(
                        x?.demeterCalculationEngineGuid!,
                        (x as UpdateCalculationEngineInputsRequest)?.demeterCalculationEngineInputsGuid!,
                        {
                            ...x,
                            valueOverrides: { ...calculatedOverrides, ...x?.valueOverrides },
                            dataOverrides: {},
                        } as UpdateCalculationEngineInputsRequest,
                    );
                });
        },
        {
            stopAutoExecute: true,
            successMessage: translations.calculators.valorization.messages.updateCalculationEnginesInputsSuccessful,
            errorMessage: translations.calculators.valorization.messages.updateCalculationEnginesInputsFailed,
        },
    );

    // Dropdown options.
    const streamGroupOptions = useMemo(
        () =>
            listCalculationEngineResponse?.rows!.map((x) => ({
                label: translate(x.displayName),
                value: x.demeterCalculationEngineGuid,
            })),
        [listCalculationEngineResponse],
    );

    const modelOptions = useMemo(() => {
        if (!listCalculationEngineInputsByGroupResponse) {
            return [];
        }

        const options = [
            {
                label: `${translations.words.standard} (${translations.words.default})`,
                value: 'default',
            },
        ];

        listCalculationEngineInputsByGroupResponse.rows!.forEach((x) => {
            options.push({
                label: translate(x.groupName),
                value: x.groupName,
            });
        });

        return options;
    }, [translations, listCalculationEngineInputsByGroupResponse]);

    const handleUpdateValorizationUpsertRequest = (newValorizationRequest: ValorizationUpsertRequest, isBaseRequest?: boolean) => {
        const isNewValorizationRequest = !valorizationUpsertRequests!.find((x) => x.name === newValorizationRequest?.name) && !isBaseRequest;
        const { groupName, name, isDeleted, demeterCalculationEngineInputsGuid, valueOverrides, order, overrideName, overrideDemeterCalculationEngineGuid } =
            newValorizationRequest;
        const isStreamResetRequest = !!overrideDemeterCalculationEngineGuid;
        const isRemovingUnsavedStream = (isStreamResetRequest || isDeleted) && !demeterCalculationEngineInputsGuid;

        const newValorizationRequests = valorizationUpsertRequests!
            .filter((x) => !(isRemovingUnsavedStream && x.name === name) || x.demeterCalculationEngineInputsGuid)
            .filter((x) => overrideName !== x.name || x.demeterCalculationEngineInputsGuid)
            .sort((a, b) => a.order! - b.order!)
            .map((x) => {
                if (isBaseRequest) {
                    return {
                        ...newValorizationRequest,
                        ...x,
                        valueOverrides: { ...x.valueOverrides, ...valueOverrides },
                        groupName: groupName ?? searchParameters.groupName,
                        demeterCalculationEngineInputsGuid:
                            !groupName || groupName === searchParameters.groupName ? x.demeterCalculationEngineInputsGuid : undefined,
                    };
                }

                if (x.name !== name || x.isDeleted) {
                    return x;
                }

                if (isStreamResetRequest) {
                    return { ...newValorizationRequest, isDeleted: true };
                }

                if (overrideName && x.name !== overrideName) {
                    return { ...x, name: overrideName };
                }

                return newValorizationRequest;
            });

        if (isNewValorizationRequest || isStreamResetRequest) {
            const newRequest = {
                ...newValorizationRequest,
                valueOverrides: baseValorizationUpsertRequest?.valueOverrides,
            };

            if (isStreamResetRequest) {
                newRequest.name = overrideName!;
                newRequest.demeterCalculationEngineGuid = overrideDemeterCalculationEngineGuid;
                newRequest.demeterCalculationEngineInputsGuid = '';
                newRequest.valueOverrides = { ...baseValorizationUpsertRequest?.valueOverrides, ...newValorizationRequest.valueOverrides };
                newRequest.order = order;
            } else {
                newRequest.order = Math.max(...newValorizationRequests.filter((x) => !x.isDeleted).map((x) => x.order ?? 1)) + 1;
            }

            setValorizationUpsertRequests([...newValorizationRequests, newRequest] as ValorizationUpsertRequest[]);
            return;
        }

        setValorizationUpsertRequests(newValorizationRequests as ValorizationUpsertRequest[]);
    };

    const handleUpdateValorizationStreamRunRequests = (newValorizationRunRequest: ValorizationStreamRunRequest, isBaseRequest?: boolean) => {
        const isNewRunStreamRequest = !valorizationStreamRunRequests!.find((x) => x.name === newValorizationRunRequest?.name) && !isBaseRequest;
        const { id, name, order, valueOverrides, overrideName, overrideDemeterCalculationEngineGuid } = newValorizationRunRequest;
        const isStreamResetRequest = !!overrideDemeterCalculationEngineGuid;

        const newValorizationStreamRunRequest = valorizationStreamRunRequests!
            .filter((x) => !x.isDeleted)
            .filter((x) => overrideName !== x.name && !(isStreamResetRequest && id === x.id))
            .sort((a, b) => a.order - b.order)
            .map((x) => {
                if (isBaseRequest) {
                    return { ...x, valueOverrides: { ...x.valueOverrides, ...valueOverrides } };
                }

                if (x.name !== name) {
                    return x;
                }

                if (overrideName) {
                    return { ...x, name: overrideName };
                }

                return newValorizationRunRequest;
            });

        if (isNewRunStreamRequest || isStreamResetRequest) {
            const newRequest = {
                ...newValorizationRunRequest,
                valueOverrides: { ...baseValorizationUpsertRequest!.valueOverrides, ...valueOverrides },
            };

            if (isStreamResetRequest) {
                newRequest.name = overrideName!;
                newRequest.demeterCalculationEngineGuid = overrideDemeterCalculationEngineGuid;
                newRequest.order = order;
            } else {
                newRequest.id = `${newValorizationStreamRunRequest.length}`;
                newRequest.order = Math.max(...newValorizationStreamRunRequest.filter((x) => !x.isDeleted).map((x) => x.order)) + 1;
            }

            setValorizationStreamRunRequests([newValorizationStreamRunRequest, newRequest].flat() as ValorizationStreamRunRequest[]);
            return;
        }

        setValorizationStreamRunRequests(newValorizationStreamRunRequest as ValorizationStreamRunRequest[]);
    };

    const handleResetValorizationUpsertRequest = () => {
        const defaultCalculationEngineRows = listCalculationEngineResponse!.rows!.filter((x) =>
            defaultDemeterCalculationEngineGuids.includes(x.demeterCalculationEngineGuid),
        );

        const savedRowsOfCalculationEngineInputs = listCalculationEngineInputsByGroupResponse?.rows?.find(
            (x) => x.groupName === searchParameters.groupName,
        )?.calculationEngineInputs;

        const initialValorizationUpsertCompositeModel =
            (savedRowsOfCalculationEngineInputs ?? defaultCalculationEngineRows).map((x, index) => ({
                ...getCalculationEngineResponses?.find((y) => y.calculationEngine?.demeterCalculationEngineGuid === x.demeterCalculationEngineGuid)
                    ?.calculationEngine,
                name: (x as DemeterCalculationEngineInputsModel).demeterCalculationEngineInputsGuid
                    ? x.displayName
                    : `${translations.calculators.valorization.text.stream} ${index + 1}`,
                demeterCalculationEngineInputsGuid: (x as DemeterCalculationEngineInputsModel).demeterCalculationEngineInputsGuid ?? '',
                valueOverrides: (x as DemeterCalculationEngineInputsModel).valueOverrides,
                order: (x as DemeterCalculationEngineInputsModel).order ?? index + 1,
            })) ?? [];

        const initialValorizationUpsertRequests = initialValorizationUpsertCompositeModel?.map((x) => ({
            demeterCalculationEngineGuid: x.demeterCalculationEngineGuid,
            demeterCalculationEngineInputsGuid: x.demeterCalculationEngineInputsGuid ?? '',
            groupName: searchParameters.groupName,
            name: x.name,
            order: x.order,
            currency,
            unitOfMeasure,
            valueOverrides: {},
        }));

        setValorizationUpsertRequests(initialValorizationUpsertRequests as ValorizationUpsertRequest[]);
    };

    const handleSetInitialValorizationStreamRunRequests = () => {
        const defaultCalculationEngineRows = listCalculationEngineResponse!.rows!.filter((x) =>
            defaultDemeterCalculationEngineGuids.includes(x.demeterCalculationEngineGuid),
        );

        const savedRowsOfCalculationEngineInputs = listCalculationEngineInputsByGroupResponse?.rows?.find(
            (x) => x.groupName === searchParameters.groupName,
        )?.calculationEngineInputs;

        const newCompositeStreamModels =
            (savedRowsOfCalculationEngineInputs ?? defaultCalculationEngineRows).map((x, index) => ({
                ...getCalculationEngineResponses?.find((y) => y.calculationEngine?.demeterCalculationEngineGuid === x.demeterCalculationEngineGuid)
                    ?.calculationEngine,
                id: `ValorizationStream_${index}`,
                groupName: searchParameters.groupName,
                name: (x as DemeterCalculationEngineInputsModel).demeterCalculationEngineInputsGuid
                    ? x.displayName
                    : `${translations.calculators.valorization.text.stream} ${index + 1}`,
                valueOverrides: (x as DemeterCalculationEngineInputsModel).valueOverrides,
                order: (x as DemeterCalculationEngineInputsModel).order ?? index + 1,
            })) ?? [];

        setValorizationStreamRunRequests(newCompositeStreamModels as ValorizationStreamRunRequest[]);
    };

    const handleUpsertValorizationRequests = () => {
        const valorizationRequestForAddRequests = valorizationUpsertRequests?.filter((x) => !x.demeterCalculationEngineInputsGuid);

        if (valorizationRequestForAddRequests?.length !== 0) {
            refreshAddCalculationEngineInputs();
        }

        if (valorizationRequestForAddRequests?.length !== valorizationUpsertRequests?.length) {
            refreshUpdateCalculationEngineInputs();
        }
    };

    // Initial load.
    useEffect(() => {
        const allRequestsInitialized = valorizationStreamRunRequests && valorizationUpsertRequests;

        if (!listCalculationEngineResponse || !listCalculationEngineInputsByGroupResponse || allRequestsInitialized) {
            return;
        }

        if (!getCalculationEngineResponses || getCalculationEngineResponses?.length === 0) {
            refreshGetCalculationEngineResponses();
            return;
        }

        handleResetValorizationUpsertRequest();
        handleSetInitialValorizationStreamRunRequests();
    }, [listCalculationEngineResponse, listCalculationEngineInputsByGroupResponse, getCalculationEngineResponses]);

    // Initial setting of stream group options.
    useEffect(() => {
        if (!streamGroupOptions || streamGroupOptions?.length === 0) {
            return;
        }

        setUpsertStreamRequest({ name: '', demeterCalculationEngineGuid: streamGroupOptions[0].value });
    }, [streamGroupOptions]);

    // Add or update response, get the newest saved data. Also, reset overrides for the upsert request.
    useEffect(() => {
        if (!addCalculationEngineInputsResponse && !updateCalculationEngineInputsResponse) {
            return;
        }

        const resetValueOverrides = valorizationUpsertRequests?.map((x) => ({ ...x, valueOverrides: {} }));
        setValorizationUpsertRequests(resetValueOverrides);
        refreshListCalculationEngineInputsByGroup();
    }, [addCalculationEngineInputsResponse, updateCalculationEngineInputsResponse]);

    // When we change to a different group, reset the streams to the ones of that group.
    useEffect(() => {
        if (!searchParameters.groupName || !valorizationStreamRunRequests) {
            return;
        }

        handleResetValorizationUpsertRequest();
        handleSetInitialValorizationStreamRunRequests();
    }, [searchParameters.groupName]);

    // If we add a model, we navigate to that model. If no model selected, go to default.
    useEffect(() => {
        if (modelOptions?.length === 0) {
            return;
        }

        const lastUpdatedModelGroupName = listCalculationEngineInputsByGroupResponse?.rows?.reduce(
            (accumulator, currentValue) => {
                if (!currentValue.modifiedAt || new Date(currentValue.modifiedAt).getTime() < new Date(accumulator?.modifiedAt ?? '').getTime()) {
                    return accumulator;
                }

                return currentValue;
            },
            { modifiedAt: undefined, groupName: modelOptions[0].value } as DemeterCalculationEngineInputsGroupModel,
        )?.groupName;

        if (!searchParameters.groupName) {
            setSearchParameters({ ...searchParameters, groupName: lastUpdatedModelGroupName ?? modelOptions[0].value });
            return;
        }

        // When we run 'Save As'.
        if (addCalculationEngineInputsResponse) {
            setSearchParameters({ ...searchParameters, groupName: lastUpdatedModelGroupName! });
        }
    }, [modelOptions?.length]);

    // Whenever we have a unit of measure or currency change, we set that to the upsert request.
    useEffect(() => {
        if (!valorizationUpsertRequests || !valorizationStreamRunRequests) {
            return;
        }

        handleUpdateValorizationUpsertRequest({ unitOfMeasure, currency } as ValorizationUpsertRequest, true);
        handleUpdateValorizationStreamRunRequests({ unitOfMeasure, currency } as ValorizationStreamRunRequest, true);
    }, [currency, unitOfMeasure]);

    // Run this whenever we change streams in the upsert request to make sure we always have all the streams we
    // need in the 'getCalculationEngineResponses' so we can change immediately once the user applies the change.
    useEffect(() => {
        const currentStreamModelsAreAvailable = [
            upsertStreamRequest?.overrideDemeterCalculationEngineGuid,
            upsertStreamRequest?.demeterCalculationEngineGuid,
        ].every((x) => getCalculationEngineResponses?.map((y) => y.calculationEngine?.demeterCalculationEngineGuid).includes(x));

        if (currentStreamModelsAreAvailable) {
            return;
        }

        refreshGetCalculationEngineResponses();
    }, [
        listCalculationEngineInputsByGroupResponse,
        upsertStreamRequest?.overrideDemeterCalculationEngineGuid,
        upsertStreamRequest?.demeterCalculationEngineGuid,
    ]);

    const loading = !listCalculationEngineInputsByGroupResponse || !valorizationStreamRunRequests || !valorizationUpsertRequests || !streamGroupOptions;

    return loading ? (
        <PageLoadingSpinner />
    ) : (
        <div className={styles.valorization_calculator}>
            <PageHeader
                title={translations.calculators.text.valorization}
                rightSideComponent={<Link to={NavigationRoutes.CalculatorsValorizationList}>Manage Saved Models</Link>}
            />
            <div className={styles.valorization_calculator_gray_selection_area}>
                <div className={styles.valorization_calculator_selection_area_dropdown}>
                    <Dropdown
                        value={modelOptions.find((x) => x.value === searchParameters.groupName)?.value}
                        label={translations.words.model}
                        options={modelOptions}
                        handleOptionChange={(value) => {
                            setSearchParameters({ ...searchParameters, groupName: value! });
                        }}
                    />
                </div>
                <div className={styles.valorization_calculator_gray_selection_area_separator} />
                {/* IMPORTANT: Do not remove these. They will be added back at a later date. */}
                {/* <div className={styles.valorization_calculator_selection_area_dropdown}>
                    <UnitOfMeasureGeneralDropdown disabled unitOfMeasure={unitOfMeasure} handleChange={setUnitOfMeasure} label={translations.words.unit} />
                </div>
                <div className={styles.valorization_calculator_selection_area_dropdown}>
                    <CurrencyDropdown disabled currency={currency} handleChange={setCurrency} label={translations.words.currency} />
                </div> */}
                {baseValorizationUpsertRequest && (
                    <ValorizationMilkSolidsPercentAction
                        valorizationStreamRunRequests={valorizationStreamRunRequests}
                        baseValorizationUpsertRequest={baseValorizationUpsertRequest!}
                        setBaseValorizationUpsertRequest={setBaseValorizationUpsertRequest}
                        handleUpdateValorizationStreamRunRequests={handleUpdateValorizationStreamRunRequests}
                    />
                )}
                <div className={styles.valorization_calculator_gray_selection_area_separator} />

                <div className={styles.valorization_calculator_last_saved_actions}>
                    {searchParameters.groupName !== 'default' && (
                        <div className={styles.valorization_calculator_last_saved}>
                            {translations.text.lastSavedOn}{' '}
                            {formattingService.toLongDateAndTimeFormat(
                                new Date(
                                    listCalculationEngineInputsByGroupResponse?.rows?.find((x) => x.groupName === searchParameters.groupName)?.modifiedAt!,
                                ),
                            )}
                        </div>
                    )}
                    <div className={styles.valorization_calculator_selection_area_actions}>
                        {searchParameters.groupName !== 'default' && (
                            <div className={styles.valorization_calculator_selection_area_save_button}>
                                <LinkButton title={translations.actions.save} type={LinkButtonType.Blue} onClick={handleUpsertValorizationRequests} />
                            </div>
                        )}
                        <ValorizationSaveAsAction
                            valorizationUpsertRequests={valorizationUpsertRequests}
                            baseValorizationUpsertRequest={baseValorizationUpsertRequest!}
                            setBaseValorizationUpsertRequest={setBaseValorizationUpsertRequest}
                            handleUpsertValorizationRequests={handleUpsertValorizationRequests}
                            handleUpdateValorizationUpsertRequest={handleUpdateValorizationUpsertRequest}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.valorization_calculator_stream_header_rows_area}>
                <div>
                    <div className={styles.valorization_calculator_stream_product_header}>{translations.words.product}</div>
                    <div className={styles.valorization_calculator_stream_spot_price_header}>{translations.text.spotPrice}</div>
                    <div className={styles.valorization_calculator_stream_basis_header}>{translations.words.basis}</div>
                    <div className={styles.valorization_calculator_stream_cost_of_production_header}>
                        <LabelWithTooltip
                            title={translations.calculators.valorization.fields.costOfProduction}
                            tooltip={translations.calculators.valorization.text.costOfProductionTooltip}
                            placement="top-start"
                        />
                    </div>
                    <div className={styles.valorization_calculator_stream_yield_header}>{translations.words.yield}</div>
                    <div className={styles.valorization_calculator_stream_subtotal_header}>{translations.words.subtotal}</div>
                </div>
            </div>

            <div className={styles.valorization_calculator_stream_results}>
                {valorizationStreamRunRequests
                    ?.filter((x) => !x.isDeleted)
                    .sort((a, b) => a.order - b.order)
                    ?.map((x, _, streams) => (
                        <ValorizationStream
                            key={x.id}
                            showDisableIcon={streams.length > 1}
                            listCalculationEngineInputsByGroupResponse={listCalculationEngineInputsByGroupResponse}
                            getCalculationEngineResponses={getCalculationEngineResponses!}
                            valorizationStreamRunRequest={x}
                            baseValorizationUpsertRequest={baseValorizationUpsertRequest!}
                            setBaseValorizationUpsertRequest={setBaseValorizationUpsertRequest}
                            valorizationUpsertRequest={valorizationUpsertRequests.findLast((y) => x.name === y.name)!}
                            upsertStreamOptions={streamGroupOptions}
                            upsertStreamRequest={upsertStreamRequest}
                            setUpsertStreamRequest={setUpsertStreamRequest}
                            handleUpdateValorizationUpsertRequest={handleUpdateValorizationUpsertRequest}
                            handleUpdateValorizationStreamRunRequests={handleUpdateValorizationStreamRunRequests}
                        />
                    ))}
            </div>

            {valorizationStreamRunRequests
                ?.map((x) => x.monthlyValueOverrides)
                .flat()
                .filter(Boolean).length !== 0 && (
                <div className={styles.valorization_calculator_spot_price_reset_area}>
                    <div>
                        <p>{translations.calculators.valorization.text.yourCustomValues}</p>
                    </div>
                    <LinkButton
                        title={translations.calculators.valorization.text.resetSpotPrices}
                        type={LinkButtonType.White}
                        onClick={() => {
                            const newCompositeModels = valorizationStreamRunRequests.map((x) => {
                                // By checking first, it will keep from firing a change event on all requests.
                                if (x?.monthlyValueOverrides?.length !== 0) {
                                    return {
                                        ...x,
                                        monthlyValueOverrides: [],
                                    };
                                }

                                return x;
                            });
                            setValorizationStreamRunRequests(newCompositeModels);
                        }}
                    />
                </div>
            )}

            <ValorizationAddStreamAction
                streamGroupOptions={streamGroupOptions}
                upsertStreamRequest={upsertStreamRequest!}
                setUpsertStreamRequest={setUpsertStreamRequest}
                getCalculationEngineResponses={getCalculationEngineResponses!}
                handleUpdateValorizationUpsertRequest={handleUpdateValorizationUpsertRequest}
                handleUpdateValorizationStreamRunRequests={handleUpdateValorizationStreamRunRequests}
            />
            <ValorizationCarousel valorizationStreamRunRequests={valorizationStreamRunRequests} />
        </div>
    );
};

export default memo(ValorizationCalculator);
