import minusSvg from '../../../../Assets/Icons/collapse.svg';
import plusSvg from '../../../../Assets/Icons/expandGray.svg';
import styles from './MarginInputsTable.module.scss';

interface IMarginInputsTableCollapsibleFieldProps {
    fieldDisplayName: string;
    hideGroup: boolean;
}

const MarginInputsTableCollapsibleField: React.FC<IMarginInputsTableCollapsibleFieldProps> = (props: IMarginInputsTableCollapsibleFieldProps) => (
    <div className={styles.margin_inputs_table_collapsible_field}>
        {props.fieldDisplayName}
        <img className={styles.margin_inputs_table_image} alt="#" src={props.hideGroup === false ? minusSvg : plusSvg} />
    </div>
);

export default MarginInputsTableCollapsibleField;
