import { Options, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Currency, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventActionsEnum, EventCategoriesEnum, EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../../Services/Logging/LoggingService';
import {
    chartColors,
    ChartContext,
    columnChartType,
    defaultChartAxisTickLabelStyle,
    defaultChartAxisTitleStyle,
    defaultChartOptions,
    HighchartsPlot,
    IChartBarDataSeries,
    IChartDataSeries,
    IChartProps,
    lineChartType,
} from '../ChartDefinitions';
import chartService from '../ChartService';

export interface IMultiBarLinesAreaChartRawProps extends IChartProps {
    linesSeries: IChartDataSeries[];
    barsSeries: IChartBarDataSeries[];
    areaSeries?: IChartBarDataSeries;
    yAxisLabel?: string;
    unitOfMeasure?: UnitOfMeasure;
    currency?: Currency;
}

const barLineTickAmount = 7;
const areaTickAmount = 4;
const barAndLineChartHeightPercent = 70;
const areaChartHeightPercent = 30;

const MultiBarLinesAreaChartRaw: React.FC<IMultiBarLinesAreaChartRawProps> = (props: IMultiBarLinesAreaChartRawProps) => {
    // Application hooks.
    const [translations] = useLanguage();

    // Chart hooks.
    const multiBarWithLinesChartDefaultOptions = useMemo<Options>(() => {
        const yAxis: YAxisOptions[] = [
            {
                title: {
                    text: props.yAxisLabel ?? `${translations.words.volume} (${translations.unitOfMeasure[`Short${props.unitOfMeasure}`]})`,
                    style: defaultChartAxisTitleStyle,
                },
                tickAmount: barLineTickAmount,
            },
        ];

        if (props.areaSeries) {
            yAxis[0].height = `${barAndLineChartHeightPercent}%`;
            yAxis.unshift({
                title: {
                    text: props.currency!,
                    style: defaultChartAxisTitleStyle,
                },
                opposite: true,
                top: `${barAndLineChartHeightPercent}%`,
                height: `${areaChartHeightPercent}%`,
                labels: {
                    format: '{value:{point.y: , .0f}',
                    style: defaultChartAxisTickLabelStyle,
                },
                tickAmount: areaTickAmount,
            });
        }

        return { ...defaultChartOptions, yAxis, series: [] };
    }, []);

    const [highchartOptions, setHighchartOptions] = useState<Options>(multiBarWithLinesChartDefaultOptions);

    const getDataSeriesDefinitions = useCallback(() => {
        let barsDataSeries = props.barsSeries.flatMap((barSeries, index) => {
            const currentColor = chartColors.barChartColorsRuleSetOne[index];

            return [
                {
                    name: barSeries.label,
                    type: columnChartType,
                    data: [] as HighchartsPlot[],
                    marker: {
                        symbol: 'square',
                        radius: 12,
                        fillColor: currentColor,
                        lineWidth: 1,
                        lineColor: currentColor,
                    },
                    events: {
                        legendItemClick() {
                            loggingService.trackEventWithAnalytics(
                                EventActionsEnum.ButtonClick,
                                EventCategoriesEnum.LegendItemClicked,
                                barSeries.label,
                                EventDataTargetsEnum.MultiBarLinesAreaChart,
                            );
                        },
                    },
                    color: currentColor,
                    visible: true,
                    showInLegend: true,
                },
            ];
        });

        const lineDataSeries = props.linesSeries.flatMap((lineSeries, index) => {
            const currentColor = chartColors.lineChartColorsRuleSetTwo[index];
            return [
                {
                    name: lineSeries.label,
                    yAxis: 0,
                    marker: {
                        enabled: true,
                    },
                    data: [] as HighchartsPlot[],
                    color: currentColor,
                    type: lineChartType,
                    visible: true,
                    showInLegend: true,
                },
            ];
        });

        if (!props.areaSeries) {
            return [...barsDataSeries, ...lineDataSeries];
        }

        lineDataSeries[0].yAxis = 1;
        barsDataSeries = barsDataSeries.map((x) => ({ ...x, yAxis: 1 }));

        const areaDataSeries = [
            {
                name: props.areaSeries.label,
                yAxis: 0,
                marker: {
                    enabled: false,
                },
                data: [] as HighchartsPlot[],
                color: chartColors.areaChartColorsRuleSetTwo[1],
                type: 'area',
                visible: true,
                showInLegend: true,
            },
        ];

        return [...barsDataSeries, ...lineDataSeries, ...areaDataSeries];
    }, [props.linesSeries, props.barsSeries, props.areaSeries]);

    // Main useEffect to update chart when props or data changes.
    useEffect(() => {
        const dataSeries = getDataSeriesDefinitions();
        const allSeries = [...props.barsSeries, ...props.linesSeries];
        if (props.areaSeries) {
            allSeries.push(props.areaSeries);
        }
        const downloadData = chartService.getDownloadData(allSeries as IChartDataSeries[]);

        allSeries.forEach((series, index) => {
            const endIndex = series!.data?.length;
            dataSeries[index].data = series.data?.slice(0, endIndex).map((item) => ({ x: item.asOfDate.getTime(), y: item.value }));
        });

        const newOptions = {
            ...multiBarWithLinesChartDefaultOptions,
            ...{ series: dataSeries },
            ...{
                tooltip: {
                    formatter() {
                        const context = this as unknown as ChartContext;
                        return chartService.getTooltipText(context, {
                            displayDecimalPlacesMinimum: props.displayDecimalPlacesMinimum ?? 0,
                            displayDecimalPlacesMaximum: props.displayDecimalPlacesMaximum ?? 0,
                        });
                    },
                },
            },
            downloadData,
        };

        const minimumValue = Math.min(...newOptions.series.flatMap((x) => (x.data ? x.data.map((y) => Math.abs(y.y ?? 0)) : [])).filter((x) => !!x));

        if (props.areaSeries) {
            (newOptions.yAxis as YAxisOptions[])[0].labels!.format = `{value:{point.y: , .${formattingService.getDisplayDecimalPlacesMinimumForCharts(
                minimumValue,
            )}f}`;
        }

        setHighchartOptions(newOptions as Options);
    }, [props.linesSeries, props.barsSeries, props.areaSeries]);

    return <HighchartsReact ref={props.chartReference} highcharts={HighStock} options={highchartOptions} containerProps={{ style: { height: '100%' } }} />;
};

export default memo(MultiBarLinesAreaChartRaw);
