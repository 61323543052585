import { RunCalculationEngineResponse } from '../../../../../Generated/Raven-Demeter';
import Carousel from '../../../../Components/Carousel/Carousel';
import { MarginCompositeModel } from '../../RiskDefinitions';
import styles from './MarginCharts.module.scss';
import MarginTrackerChart from './MarginTrackerChart';
import ProductionVersusHedgedVolumeChart from './ProductionVersusHedgedVolumeChart';

interface IMarginChartsCarouselProps {
    getFarmerMarginCalculatorCompositeRowsModels: MarginCompositeModel[];
    runCalculationEngineResponse: RunCalculationEngineResponse;
    displayDecimalPlaces: number;
}

const MarginChartsCarousel: React.FC<IMarginChartsCarouselProps> = (props: IMarginChartsCarouselProps) => {
    const getCalculatorFieldValue = (marginCompositeModel: MarginCompositeModel, fieldName: keyof MarginCompositeModel): number => {
        const asOfDate = Object.values(marginCompositeModel)[0];
        const currentRunResponseData = props.runCalculationEngineResponse?.results?.find(
            (y) => new Date(y.asOfDate).getTime() >= new Date(asOfDate).getTime(),
        )?.lines;

        return currentRunResponseData?.find((x) => x.variableName === fieldName)?.value ?? 0;
    };

    return (
        <div className={styles.margin_carousel_charts}>
            <Carousel>
                <div className={styles.margin_carousel_chart}>
                    <ProductionVersusHedgedVolumeChart
                        getFarmerMarginCalculatorCompositeRowsModels={props.getFarmerMarginCalculatorCompositeRowsModels}
                        getCalculatorFieldValue={getCalculatorFieldValue}
                        displayDecimalPlaces={props.displayDecimalPlaces}
                    />
                </div>
                <div className={styles.margin_carousel_chart}>
                    <MarginTrackerChart
                        getFarmerMarginCalculatorCompositeRowsModels={props.getFarmerMarginCalculatorCompositeRowsModels}
                        getCalculatorFieldValue={getCalculatorFieldValue}
                        displayDecimalPlaces={props.displayDecimalPlaces}
                    />
                </div>
            </Carousel>
        </div>
    );
};

export default MarginChartsCarousel;
