import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useMemo, useState } from 'react';
import IconButton from '../../../../../Components/Form/Buttons/IconButton';
import TextInput from '../../../../../Components/Form/Inputs/TextInput';
import ActionModal from '../../../../../Components/Modals/ActionModal/ActionModal';
import formattingService from '../../../../../Services/Formatting/FormattingService';
import useLanguage from '../../../../../Services/Language/useLanguageHook';
import { BaseValorizationUpsertRequest, ValorizationStreamRunRequest } from '../../ValorizationCalculatorDefinitions';
import styles from '../ValorizationCalculatorActions.module.scss';

interface IValorizationMilkSolidsPercentActionProps {
    valorizationStreamRunRequests: ValorizationStreamRunRequest[];
    baseValorizationUpsertRequest: BaseValorizationUpsertRequest;
    setBaseValorizationUpsertRequest: (baseValorizationUpsertRequest: BaseValorizationUpsertRequest) => void;
    handleUpdateValorizationStreamRunRequests: (newValorizationRunRequest: ValorizationStreamRunRequest, isBaseRequest?: boolean) => void;
}

const ValorizationMilkSolidsPercentAction: React.FC<IValorizationMilkSolidsPercentActionProps> = (props: IValorizationMilkSolidsPercentActionProps) => {
    // Application hooks.
    const [translations] = useLanguage();

    // Modal display hook.
    const [showSetValorizationModal, setShowSetValorizationModal] = useState(false);

    const { fatPercent, proteinPercent, valueOverrides } = props.baseValorizationUpsertRequest;

    const fatPercentValue = valueOverrides?.fatPercent ?? fatPercent?.value ?? 0 ?? 0;
    const proteinPercentValue = valueOverrides?.proteinPercent ?? proteinPercent?.value ?? 0 ?? 0;
    const hasOtherOverride = Object.keys(valueOverrides!).includes(
        props.valorizationStreamRunRequests[0].calculationEngineLines?.find((x) => x.displayName === 'Other %')?.variableName!,
    );

    // We are coding in the other equation here to allow it to be overridden or live calculated.
    const otherPercentValue = hasOtherOverride ? valueOverrides!.otherPercent : (proteinPercentValue / 100.0 / 0.374 - proteinPercentValue / 100.0) * 100.0;

    const fatPercentString = fatPercentValue.toString();
    const proteinPercentString = proteinPercentValue.toString();
    const otherPercentString = otherPercentValue.toFixed(2);

    // Values.
    const [fatPercentTextValue, setFatPercentTextValue] = useState('');
    const [proteinPercentTextValue, setProteinPercentTextValue] = useState('');
    const [otherPercentTextValue, setOtherPercentTextValue] = useState('');

    // Maximums and minimums.
    const fatValueMaximum = props.valorizationStreamRunRequests[0].calculationEngineLines?.find((x) => x.displayName === 'Fat %')?.maxmiumValue ?? 0;
    const fatValueMinimum = props.valorizationStreamRunRequests[0].calculationEngineLines?.find((x) => x.displayName === 'Fat %')?.minimumValue ?? 0;

    const proteinValueMaximum = props.valorizationStreamRunRequests[0].calculationEngineLines?.find((x) => x.displayName === 'Protein %')?.maxmiumValue ?? 0;
    const proteinValueMinimum = props.valorizationStreamRunRequests[0].calculationEngineLines?.find((x) => x.displayName === 'Protein %')?.minimumValue ?? 0;

    const otherValueMaximum = props.valorizationStreamRunRequests[0].calculationEngineLines?.find((x) => x.displayName === 'Other %')?.maxmiumValue ?? 0;
    const otherValueMinimum = props.valorizationStreamRunRequests[0].calculationEngineLines?.find((x) => x.displayName === 'Other %')?.minimumValue ?? 0;

    // Load initial values as text values.
    useEffect(() => {
        const initialProteinValueLoaded = +proteinPercentTextValue === +proteinPercentString;
        const initialFatValueLoaded = +fatPercentTextValue === +fatPercentString;

        if (initialProteinValueLoaded && initialFatValueLoaded) {
            return;
        }

        setFatPercentTextValue(fatPercentString);
        setProteinPercentTextValue(proteinPercentString);
        setOtherPercentTextValue(otherPercentString);
    }, [showSetValorizationModal, proteinPercentValue, fatPercentValue]);

    // Preserve the origional modal state values.
    const initialBaseValorizationUpsertRequest = useMemo(() => props.baseValorizationUpsertRequest, [showSetValorizationModal]);

    const modalValuesAreNotChanged = useMemo(
        () => Object.entries(props.baseValorizationUpsertRequest.valueOverrides!).length === 0,
        [props.baseValorizationUpsertRequest],
    );

    const valueIsWithinLimits = (value: number, maximum: number, minimum: number) => {
        if (value > maximum || value < minimum) {
            return false;
        }

        return true;
    };

    // TODO: Add a validation message that tells possible values.
    return (
        <>
            <div className={styles.valorization_action_selection_area_milk_solids}>
                <div className={styles.valorization_action_selection_area_milk_solids_title}>{translations.calculators.valorization.text.milkSolids}</div>
                <div className={styles.valorization_action_selection_area_milk_solids_edit}>
                    <div>
                        {`${formattingService.toPercent(+fatPercentString)}
                            ${translations.words.fat},
                            ${formattingService.toPercent(+proteinPercentString)}
                            ${translations.words.protein}`}
                    </div>
                    <IconButton handleClick={() => setShowSetValorizationModal(true)}>
                        <EditIcon />
                    </IconButton>
                </div>
            </div>
            <ActionModal
                header={translations.calculators.valorization.text.milkSolids}
                showModal={showSetValorizationModal}
                handleCancel={() => {
                    props.setBaseValorizationUpsertRequest(initialBaseValorizationUpsertRequest);
                    setShowSetValorizationModal(false);
                }}
                actionButtonName={translations.actions.apply}
                actionButtonDisabled={
                    modalValuesAreNotChanged ||
                    +fatPercentString < fatValueMinimum ||
                    +fatPercentString > fatValueMaximum ||
                    +proteinPercentString < proteinValueMinimum ||
                    +proteinPercentString > proteinValueMaximum ||
                    +otherPercentString < otherValueMinimum ||
                    +otherPercentString > otherValueMaximum
                }
                handleAction={() => {
                    let baseRequestValueOverrides = {
                        fatPercent: valueOverrides!.fatPercent ?? props.baseValorizationUpsertRequest.fatPercent!.value,
                        proteinPercent: valueOverrides!.proteinPercent ?? props.baseValorizationUpsertRequest.proteinPercent!.value,
                    };

                    if (hasOtherOverride) {
                        baseRequestValueOverrides = {
                            ...baseRequestValueOverrides,
                            otherPercent: valueOverrides!.otherPercent ?? props.baseValorizationUpsertRequest.otherPercent!.value,
                        } as typeof baseRequestValueOverrides;
                    }

                    // If we apply, we need to make sure that ALL of the baseValorization overrides get set.
                    props.setBaseValorizationUpsertRequest({
                        ...props.baseValorizationUpsertRequest!,
                        valueOverrides: baseRequestValueOverrides,
                        dataOverrides: {},
                    });

                    let newValueOverrides = {
                        fatPercent: +fatPercentString,
                        proteinPercent: +proteinPercentString,
                    };

                    if (hasOtherOverride) {
                        newValueOverrides = { ...newValueOverrides, otherPercent: +otherPercentString } as typeof newValueOverrides;
                    }

                    // TODO: handleUpdateValorizationStreamRunRequests should have 2 separate functions, one for base and one for individual.
                    props.handleUpdateValorizationStreamRunRequests({ valueOverrides: newValueOverrides } as unknown as ValorizationStreamRunRequest, true);

                    setShowSetValorizationModal(false);
                }}
            >
                <>
                    <p className={styles.valorization_action_how_to_use}>{translations.calculators.valorization.text.settingsApplyToAllStreams}</p>

                    <div className={styles.valorization_action_content_row}>
                        <p className={styles.valorization_action_label_text}>{translations.calculators.valorization.fields.fat}</p>
                        <div className={styles.valorization_action_text_input_milk_solids}>
                            <TextInput
                                type="number"
                                value={fatPercentTextValue}
                                handleTextChange={(value) => {
                                    const numberValue = +value;

                                    setFatPercentTextValue(value);

                                    if (value === fatPercentString) {
                                        return;
                                    }

                                    props.setBaseValorizationUpsertRequest({
                                        ...props.baseValorizationUpsertRequest!,
                                        valueOverrides: {
                                            ...valueOverrides,
                                            fatPercent: numberValue ?? 0,
                                        },
                                        dataOverrides: {},
                                    });
                                }}
                                validation={() => valueIsWithinLimits(+fatPercentString, fatValueMaximum, fatValueMinimum)}
                                errorMessage={`${translations.calculators.valorization.messages.pleaseEnterAValueBetween} 
                                ${fatValueMinimum} ${translations.words.and} ${fatValueMaximum}.`}
                            />
                            <p>%</p>
                        </div>
                    </div>
                    <div className={styles.valorization_action_content_row}>
                        <p className={styles.valorization_action_label_text}>{translations.calculators.valorization.fields.protein}</p>
                        <div className={styles.valorization_action_text_input_milk_solids}>
                            <TextInput
                                type="number"
                                value={proteinPercentTextValue}
                                handleTextChange={(value) => {
                                    const numberValue = +value;

                                    setProteinPercentTextValue(value);

                                    if (value === proteinPercentString) {
                                        return;
                                    }

                                    props.setBaseValorizationUpsertRequest({
                                        ...props.baseValorizationUpsertRequest!,
                                        valueOverrides: {
                                            ...valueOverrides,
                                            proteinPercent: numberValue ?? 0,
                                        },
                                        dataOverrides: {},
                                    });
                                }}
                                validation={() => valueIsWithinLimits(+proteinPercentString, proteinValueMaximum, proteinValueMinimum)}
                                errorMessage={`${translations.calculators.valorization.messages.pleaseEnterAValueBetween} 
                                ${proteinValueMinimum} ${translations.words.and} ${proteinValueMaximum}.`}
                            />
                            <p>%</p>
                        </div>
                    </div>
                    <div className={styles.valorization_action_content_row}>
                        <p className={styles.valorization_action_label_text}>{translations.words.other}</p>
                        <div className={styles.valorization_action_text_input_milk_solids}>
                            <TextInput
                                type="number"
                                value={hasOtherOverride ? otherPercentTextValue : otherPercentString}
                                handleTextChange={(value) => {
                                    const numberValue = +value;

                                    setOtherPercentTextValue(value);

                                    if (value === otherPercentString) {
                                        return;
                                    }

                                    props.setBaseValorizationUpsertRequest({
                                        ...props.baseValorizationUpsertRequest!,
                                        valueOverrides: {
                                            ...valueOverrides,
                                            otherPercent: numberValue ?? 0,
                                        },
                                        dataOverrides: {},
                                    });
                                }}
                                validation={() => valueIsWithinLimits(+otherPercentString, otherValueMaximum, otherValueMinimum)}
                                errorMessage={`${translations.calculators.valorization.messages.pleaseEnterAValueBetween} 
                                ${otherValueMinimum} ${translations.words.and} ${otherValueMaximum}.`}
                            />
                            <p>%</p>
                        </div>
                    </div>
                </>
            </ActionModal>
        </>
    );
};

export default ValorizationMilkSolidsPercentAction;
