import React, { useEffect, useRef, useState } from 'react';
import { DemeterDataFrequency, DemeterFeatureType, DemeterRegion, DemeterTableDefinitionType, DemeterUserStoreType } from '../../../Generated/Raven-Demeter';
import useTableDefinitionsApi from '../../Apis/Hooks/useTableDefinitionsApiHook';
import useTableDefinitionsByCommodityApi from '../../Apis/Hooks/useTableDefinitionsCommodityApiHook';
import useUserStoreApi from '../../Apis/Hooks/useUserStoreApiHook';
import useFeatureFlag from '../../Services/FeatureFlags/useFeatureFlagHook';
import useSearchParameters from '../Navigation/Hooks/useSearchParametersHook';
import styles from './MenuFileSelectors.module.scss';
import MenuSelectorItem, { IMenuItem, IRegionCommodityValues, MenuTypeForSearchParameter } from './MenuSelectorItem';
import MenuSelectorWithFlipWrapper from './MenuSelectorWithFlipWrapper';
import regionCommodityGroupingService from './RegionCommodityGroupingService';

const menuSettingsKey = 'topMenuType';

interface IMenuByRegionCommodityProps {
    tableDefinitionType: DemeterTableDefinitionType;
    showMainRegionsAndCommoditiesGroupsOnly?: boolean;
    rightSideOfBreadcrumbsComponent?: JSX.Element;
}

const MenuByRegionCommodity: React.FC<IMenuByRegionCommodityProps> = (props: IMenuByRegionCommodityProps) => {
    // Application hooks and constants.
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const [userStoreValue, updateUserStore] = useUserStoreApi(DemeterUserStoreType.DisplaySettings);
    // Menu Data
    const tableDefinitions = useTableDefinitionsApi(props.tableDefinitionType);
    const tableDefinitionsCommodity = useTableDefinitionsByCommodityApi(props.tableDefinitionType);
    const [topLevelMenuItems, setTopLevelMenuItems] = useState<IMenuItem[]>([]);
    const [subLevelMenuItems, setSubLevelMenuItems] = useState<IMenuItem[]>([]);
    const [breadCrumbs, setBreadCrumbs] = useState<string>();
    const enableUserData = useFeatureFlag(DemeterFeatureType.UserData);
    const regionCommoditySelectionReference = useRef<IRegionCommodityValues>({
        region: searchParameters.region ?? null,
        subRegion: searchParameters.subRegion ?? null,
        commodity: searchParameters.commodity ?? null,
        extraParameters: searchParameters.extraParameters ?? '',
        dataFrequency: searchParameters.dataFrequency ?? '',
        subCommodity: null,
        topLevelGroup: null,
        topLevelRegion: null,
        isSubSelection: false,
    });
    // Prevents race condition when userStoreValue has not loaded before table definitions on new page click
    const topMenuTypeReference = useRef<MenuTypeForSearchParameter>();

    const handleMenuAndSearchParametersUpdate = (
        topLevelItems: IMenuItem[],
        subLevelItems: IMenuItem[],
        selection: IRegionCommodityValues,
        topMenuType: MenuTypeForSearchParameter,
    ) => {
        setBreadCrumbs(
            regionCommodityGroupingService.getSelectedBreadCrumbLabel(
                [...topLevelItems, ...subLevelItems],
                topMenuType === MenuTypeForSearchParameter.commodity,
            ),
        );
        setTopLevelMenuItems(topLevelItems);
        setSubLevelMenuItems(subLevelItems);
        regionCommoditySelectionReference.current = { ...selection };

        setSearchParameters({
            region: selection.region ?? searchParameters.region ?? '',
            subRegion: selection.subRegion ?? '',
            commodity: selection.subCommodity ?? selection.commodity ?? searchParameters.commodity,
            extraParameters: selection.extraParameters ?? '',
            dataFrequency: selection.dataFrequency ?? DemeterDataFrequency.Monthly,
            topMenuType,
        });
    };

    const setMenusByRegionOrCommodity = (newSelection: IRegionCommodityValues) => {
        if (!tableDefinitions || !tableDefinitions?.length || !tableDefinitionsCommodity) {
            return;
        }

        const isCommodity = topMenuTypeReference.current === MenuTypeForSearchParameter.commodity;
        if (!isCommodity || (isCommodity && !tableDefinitionsCommodity.length)) {
            const availableRegions = tableDefinitions.filter((x) => enableUserData || x.region !== DemeterRegion.UserData);
            const selectionUpdate = regionCommodityGroupingService.getMenuSelectionByRegion(availableRegions, newSelection);

            const regionMenuItems = regionCommodityGroupingService.getMenuItemsByRegions(
                availableRegions,
                selectionUpdate,
                props.showMainRegionsAndCommoditiesGroupsOnly ?? false,
            );

            const selectedRegion = availableRegions.find((x) => x.region === selectionUpdate.topLevelRegion);
            if (!selectedRegion) {
                const regionCommoditiesMenuItems = regionCommodityGroupingService.getMenuItemsByRegionCommodities(
                    availableRegions[0].demeterTableDefinitionGroups,
                    selectionUpdate,
                    props.showMainRegionsAndCommoditiesGroupsOnly ?? false,
                );

                handleMenuAndSearchParametersUpdate(regionMenuItems, regionCommoditiesMenuItems, selectionUpdate, MenuTypeForSearchParameter.region);
                return;
            }

            const selectedSubRegion = selectedRegion.subRegions.find((x) => x === selectionUpdate.subRegion);
            const availableCommodities = selectedRegion.demeterTableDefinitionGroups.filter(
                (x) => !selectedSubRegion || x.subRegions.includes(selectedSubRegion),
            );
            const regionCommoditiesMenuItems = regionCommodityGroupingService.getMenuItemsByRegionCommodities(
                availableCommodities,
                selectionUpdate,
                props.showMainRegionsAndCommoditiesGroupsOnly ?? false,
            );

            handleMenuAndSearchParametersUpdate(regionMenuItems, regionCommoditiesMenuItems, selectionUpdate, MenuTypeForSearchParameter.region);
            return;
        }

        const selectionUpdate = regionCommodityGroupingService.getMenuSelectionByCommodity(tableDefinitionsCommodity, newSelection);
        const commodityMenuItems = regionCommodityGroupingService.getMenuItemsByCommodities(
            tableDefinitionsCommodity,
            selectionUpdate,
            props.showMainRegionsAndCommoditiesGroupsOnly ?? false,
        );
        const selectedCommodity = tableDefinitionsCommodity.find((x) => x.topLevelGroup === selectionUpdate.topLevelGroup);
        if (!selectedCommodity) {
            const commodityRegionsMenuItems = regionCommodityGroupingService.getMenuItemsForCommodityRegions(
                tableDefinitionsCommodity[0].demeterTableDefinitionRegions.filter((x) => enableUserData || x.region !== DemeterRegion.UserData),
                selectionUpdate,
                props.showMainRegionsAndCommoditiesGroupsOnly ?? false,
            );

            handleMenuAndSearchParametersUpdate(commodityMenuItems, commodityRegionsMenuItems, selectionUpdate, MenuTypeForSearchParameter.commodity);
            return;
        }

        const commodityRegionsMenuItems = regionCommodityGroupingService.getMenuItemsForCommodityRegions(
            selectedCommodity?.demeterTableDefinitionSubCommodities
                .find((x) => !!x.commodity && x.commodity === selectionUpdate.subCommodity && x.topLevelGroup === selectionUpdate.topLevelGroup)
                ?.demeterTableDefinitionRegions.filter((x) => enableUserData || x.region !== DemeterRegion.UserData) ??
                selectedCommodity.demeterTableDefinitionRegions.filter((x) => enableUserData || x.region !== DemeterRegion.UserData),
            selectionUpdate,
            props.showMainRegionsAndCommoditiesGroupsOnly ?? false,
        );

        handleMenuAndSearchParametersUpdate(commodityMenuItems, commodityRegionsMenuItems, selectionUpdate, MenuTypeForSearchParameter.commodity);
    };

    const handleMenuFlip = () => {
        const menuSettings =
            searchParameters.topMenuType === MenuTypeForSearchParameter.region ? MenuTypeForSearchParameter.commodity : MenuTypeForSearchParameter.region;

        const flipRegionCommodityValues: IRegionCommodityValues = {
            region: searchParameters.region ?? '',
            subRegion: searchParameters.subRegion ?? '',
            commodity: searchParameters.commodity ?? '',
            extraParameters: searchParameters.extraParameters ?? '',
            dataFrequency: searchParameters.dataFrequency ?? '',
            subCommodity: regionCommoditySelectionReference.current.subCommodity ?? null,
            topLevelGroup: regionCommoditySelectionReference.current.topLevelGroup ?? null,
            topLevelRegion: regionCommoditySelectionReference.current.topLevelRegion ?? null,
            isSubSelection: !!regionCommoditySelectionReference.current.subCommodity || !!searchParameters.subRegion,
        };

        updateUserStore({ [menuSettingsKey]: menuSettings });
        topMenuTypeReference.current = menuSettings;
        setMenusByRegionOrCommodity(flipRegionCommodityValues);
    };

    const handleMenuSelection = (selection: IMenuItem) => {
        const selectedSubRegion =
            selection.regionCommodityValues.subRegion !== null
                ? selection.regionCommodityValues.subRegion
                : regionCommoditySelectionReference.current.subRegion;

        const selectedSubCommodity =
            selection.regionCommodityValues.subCommodity !== null
                ? selection.regionCommodityValues.subCommodity
                : regionCommoditySelectionReference.current.subCommodity;

        const selectionRegionCommodityValues: IRegionCommodityValues = {
            region: selection.regionCommodityValues.region,
            subRegion: selectedSubRegion,
            commodity: selection.regionCommodityValues.commodity,
            extraParameters: selection.regionCommodityValues.extraParameters,
            dataFrequency: selection.regionCommodityValues.dataFrequency,
            subCommodity: selectedSubCommodity,
            topLevelGroup: selection.regionCommodityValues.topLevelGroup,
            topLevelRegion: selection.regionCommodityValues.topLevelRegion,
            isSubSelection: selection.regionCommodityValues.isSubSelection,
        };

        setMenusByRegionOrCommodity(selectionRegionCommodityValues);
    };

    // Seperates userStoreValue dependency from tableDefinitions to prevent from updating search parameters in ininite loop
    useEffect(() => {
        if (!userStoreValue?.userStore?.value && !searchParameters.topMenuType) {
            topMenuTypeReference.current = MenuTypeForSearchParameter.region;
            return;
        }

        if (!userStoreValue?.userStore?.value) {
            topMenuTypeReference.current = searchParameters.topMenuType as MenuTypeForSearchParameter;
            return;
        }

        if (
            searchParameters.topMenuType &&
            userStoreValue?.userStore?.value &&
            searchParameters.topMenuType !== userStoreValue?.userStore?.value[menuSettingsKey]
        ) {
            topMenuTypeReference.current = userStoreValue?.userStore?.value[menuSettingsKey];
            // Re run initial load due to menu type miss match
            setMenusByRegionOrCommodity(regionCommoditySelectionReference.current);
            return;
        }

        topMenuTypeReference.current = userStoreValue?.userStore?.value[menuSettingsKey];
    }, [userStoreValue]);

    useEffect(() => {
        if (!tableDefinitionsCommodity || !tableDefinitions) {
            return;
        }

        const regionCommodityInitialValues: IRegionCommodityValues = {
            region: searchParameters.region ?? null,
            subRegion: null,
            commodity: searchParameters.commodity ?? null,
            extraParameters: searchParameters.extraParameters ?? '',
            dataFrequency: searchParameters.dataFrequency ?? '',
            subCommodity: null,
            topLevelGroup: null,
            topLevelRegion: null,
            isSubSelection: false,
        };
        regionCommoditySelectionReference.current = { ...regionCommodityInitialValues };
        setMenusByRegionOrCommodity(regionCommodityInitialValues);
    }, [tableDefinitionsCommodity, tableDefinitions]);

    return (
        <MenuSelectorWithFlipWrapper
            rightSideOfBreadcrumbsComponent={props.rightSideOfBreadcrumbsComponent}
            breadCrumbs={breadCrumbs}
            tableDefinitionType={props.tableDefinitionType}
            menuSettingsKey={menuSettingsKey}
            handleMenuFlip={handleMenuFlip}
        >
            <div>
                <div className={styles.menu_with_flip_topMenu}>
                    {topLevelMenuItems.map((menuItem: IMenuItem) => (
                        <div key={menuItem.id} className={menuItem.isSelectedItem ? styles.menu_with_flip_topItem_selected : styles.menu_with_flip_topItem}>
                            <MenuSelectorItem
                                menuType="topItem"
                                menuItem={menuItem}
                                classname={menuItem.isSelectedItem ? styles.menu_with_flip_topItem_button_selected : styles.menu_with_flip_topItem_button}
                                buttonClassname={styles.menu_with_flip_topItem_button}
                                labelClassname={styles.menu_with_flip_topItem_button_label}
                                simpleClassname={styles.menu_with_flip_topItem_button_simple}
                                selectorClassname={styles.menu_with_flip_topItem_button_selector}
                                dropdownClassname={styles.menu_with_flip_topItem_button_dropdown}
                                dividerClassName={
                                    menuItem.isSelectedItem ? styles.menu_with_flip_topItem_selected_divider : styles.menu_with_flip_topItem_divider
                                }
                                isSelected={menuItem.isSelectedItem}
                                handleMenuSelection={handleMenuSelection}
                                showMainRegionsAndGroupsOnly={props.showMainRegionsAndCommoditiesGroupsOnly}
                            />
                        </div>
                    ))}
                </div>

                <div className={styles.menu_with_flip_subMenu}>
                    {subLevelMenuItems.map((subMenuItem: IMenuItem) => (
                        <div
                            key={subMenuItem.id}
                            className={subMenuItem.isSelectedItem ? styles.menu_with_flip_subItem_selected : styles.menu_with_flip_subItem}
                        >
                            <MenuSelectorItem
                                menuType="subItem"
                                menuItem={subMenuItem}
                                classname={subMenuItem.isSelectedItem ? styles.menu_with_flip_subItem_button_selected : styles.menu_with_flip_subItem_button}
                                buttonClassname={styles.menu_with_flip_subItem_button}
                                labelClassname={styles.menu_with_flip_subItem_button_label}
                                simpleClassname={styles.menu_with_flip_subItem_button_simple}
                                selectorClassname={styles.menu_with_flip_subItem_simple_selector}
                                dropdownClassname={styles.menu_with_flip_subItem_button_dropdown}
                                dividerClassName={
                                    subMenuItem.isSelectedItem ? styles.menu_with_flip_subItem_divider_selected : styles.menu_with_flip_subItem_divider
                                }
                                isSelected={subMenuItem.isSelectedItem}
                                handleMenuSelection={handleMenuSelection}
                                showMainRegionsAndGroupsOnly={props.showMainRegionsAndCommoditiesGroupsOnly}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </MenuSelectorWithFlipWrapper>
    );
};

export default MenuByRegionCommodity;
