import { useState } from 'react';
import { demeterUsersApi } from '../../../../../Apis/Apis';
import { DemeterUserModel } from '../../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../../Apis/Hooks/useApiWithoutAutoExecute';
import ConfirmModal from '../../../../Components/Modals/ConfirmModal/ConfirmModal';
import ActionsCellButton from '../../../../Components/Tables/ActionsCellButton/ActionsCellButton';
import useLanguage from '../../../../Services/Language/useLanguageHook';

interface IReactivateUserProps {
    show: boolean;
    user: DemeterUserModel;
    handleConfirmed: () => any;
}

const ReactivateUser: React.FC<IReactivateUserProps> = (props: IReactivateUserProps) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [translations] = useLanguage();

    const [, reactivateDemeterUser] = useApiWithoutAutoExecute(() => demeterUsersApi.reactivateDemeterUser(props.user.demeterUserTrialGuid));

    if (!props.show) {
        return null;
    }

    return (
        <>
            <ActionsCellButton text={translations.users.actions.reactivate} handleClick={() => setShowConfirmModal(true)} />
            <ConfirmModal
                header={translations.users.text.confirmReactivateHeader}
                message={translations.users.text.confirmReactivateMessage}
                showModal={showConfirmModal}
                handleConfirm={async () => {
                    reactivateDemeterUser();
                    props.handleConfirmed();
                    setShowConfirmModal(false);
                }}
                handleCancel={() => {
                    setShowConfirmModal(false);
                }}
            />
        </>
    );
};

export default ReactivateUser;
