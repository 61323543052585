/* eslint-disable @typescript-eslint/comma-dangle */
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton/IconButton';
import isequal from 'lodash.isequal';
import { useEffect, useState } from 'react';
import styles from './SortableList.module.scss';

interface ISortableListProps<T> {
    items: T[] | undefined;
    height?: number;
    renderItem: (item: T) => React.ReactNode;
    onChange?: (items: T[] | undefined) => void;
    onSelect?: (item: T) => void;
}

const SortableList = <T,>(props: ISortableListProps<T>) => {
    const [list, setList] = useState<any[]>();
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    useEffect(() => {
        setList(props.items);
    }, [props.items]);

    useEffect(() => {
        if (props.onChange && !isequal(list, props.items)) {
            props.onChange(list);
        }
    }, [list]);

    const moveItem = (direction: number) => {
        if (selectedIndex === null) {
            return;
        }
        const newIndex = selectedIndex + direction;
        if (list && newIndex >= 0 && newIndex < list.length) {
            const newList = [...list];
            [newList[selectedIndex], newList[newIndex]] = [newList[newIndex], newList[selectedIndex]];
            setList(newList);
            setSelectedIndex(newIndex);
        }
    };

    return (
        <div className={styles.sortable_list}>
            <div className={styles.sortable_list_items} style={props.height ? { height: `${props.height}px` } : {}}>
                {list?.map((item, index) => (
                    <div
                        key={crypto.randomUUID()}
                        className={selectedIndex === index ? styles.sortable_list_item_selected : styles.sortable_list_item}
                        onClick={() => {
                            if (props.onSelect) {
                                props.onSelect(item);
                            }
                            setSelectedIndex(index);
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                    >
                        {props.renderItem(item)}
                    </div>
                ))}
            </div>
            <div className={styles.sortable_list_actions}>
                <IconButton onClick={() => moveItem(-1)} size="small">
                    <ArrowUpward className={styles.notifications_icon_close} />
                </IconButton>
                <IconButton onClick={() => moveItem(1)} size="small">
                    <ArrowDownward className={styles.notifications_icon_close} />
                </IconButton>
            </div>
        </div>
    );
};

export default SortableList;
