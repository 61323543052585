import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Permission } from '../../../../Generated/Raven-Demeter';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import Switch from '../../../Components/Form/Buttons/Switch';
import CheckboxDropdownInput from '../../../Components/Form/Inputs/CheckboxDropdownInput';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './Permissions.module.scss';
import { PermissionDefinition, SubPermissionDefinition } from './PermissionsDefinitions';

export interface IPermissionsRawProps {
    permissionDefinitions: PermissionDefinition[];
    handlePermissionsChange: (permissions: Permission[]) => void;
}

interface ISubPermissionCheckbox {
    permission: PermissionDefinition;
    handleSubPermissionsChange: (subPermissions: SubPermissionDefinition[]) => void;
    disabled?: boolean;
}

const SubPermissionCheckbox: React.FC<ISubPermissionCheckbox> = (props: ISubPermissionCheckbox) => {
    const [values, setValues] = useState<string[]>([]);

    useEffect(() => {
        setValues(props.permission.subPermissions?.filter((x) => x.hasPermission).map((x) => x.key) ?? []);
    }, [props.permission]);

    const options = useMemo(() => (props.permission.subPermissions ?? []).map((x) => ({ label: x.title, value: x.key })), [props.permission.subPermissions]);

    return (
        <div className={styles.update_permission_input}>
            <CheckboxDropdownInput
                title={props.permission.titleGetter()}
                values={values}
                options={options}
                handleOptionSelect={(newValues: string[]) => {
                    (props.permission.subPermissions ?? []).forEach((x) => {
                        x.hasPermission = newValues.indexOf(x.key) >= 0;
                    });
                    props.handleSubPermissionsChange(props.permission.subPermissions ?? []);
                    setValues(newValues);
                }}
                disabled={props.disabled}
            />
        </div>
    );
};

const PermissionsRaw: React.FC<IPermissionsRawProps> = (props: IPermissionsRawProps) => {
    const [translations] = useLanguage();
    const [, setSearchParameters] = useSearchParameters();
    const navigate = useNavigate();

    // State hooks.
    const [savedPermissions, setSavedPermissions] = useState<PermissionDefinition[]>([]);

    useEffect(() => {
        // If we get a new permission set, we will reset everything to that permission set.
        setSavedPermissions(props.permissionDefinitions);
    }, [props.permissionDefinitions]);

    const handleSimplePermissionChange = (permission: PermissionDefinition, value: boolean) => {
        const index = savedPermissions.findIndex((x) => x.permissionType === permission.permissionType);
        const matchingPermission: PermissionDefinition = {
            ...savedPermissions[index],
            hasPermission: !!value,
        };

        const newSavedPermissions = [...savedPermissions];
        newSavedPermissions[index] = matchingPermission;
        setSavedPermissions(newSavedPermissions);
    };

    const handleCheckboxPermissionChange = (permission: PermissionDefinition, subPermissions: SubPermissionDefinition[]) => {
        const index = savedPermissions.findIndex((x) => x.permissionType === permission.permissionType);
        const matchingPermission: PermissionDefinition = {
            ...savedPermissions[index],
            hasPermission: subPermissions.some((x) => x.hasPermission),
            subPermissions,
        };

        const newSavedPermissions = [...savedPermissions];
        newSavedPermissions[index] = matchingPermission;
        setSavedPermissions(newSavedPermissions);
    };

    const handleSave = () => {
        props.handlePermissionsChange(
            savedPermissions.map((permission) => {
                const mappedPermission: Permission = {
                    permissionType: permission.permissionType,
                    hasPermission: permission.hasPermission,
                };

                if (permission.subPermissions && permission.subPermissions?.length > 0) {
                    const subPermissions: { [key: string]: boolean } = {};

                    permission.subPermissions.forEach((x) => {
                        subPermissions[x.key] = x.hasPermission;
                    });

                    mappedPermission.subPermissions = subPermissions;
                }

                return mappedPermission;
            }),
        );
    };

    const navigateBackToCompanies = () => {
        setSearchParameters({ tab: 'Companies' });
        navigate(-1);
    };

    return (
        <>
            <ComponentHeader title={translations.words.permissions} />
            {savedPermissions.length > 0 &&
                savedPermissions
                    .filter((permission) => permission.isVisible)
                    .map((permission) => (
                        <React.Fragment key={permission.permissionType}>
                            {!permission.subPermissions && (
                                <div>
                                    <Switch
                                        checked={permission.hasPermission}
                                        disabled={permission.isDisabled}
                                        handleChange={(value) => handleSimplePermissionChange(permission, value)}
                                    />
                                    {permission.titleGetter()}
                                </div>
                            )}
                            {permission.subPermissions && (
                                <SubPermissionCheckbox
                                    permission={permission}
                                    handleSubPermissionsChange={(subPermissions) => handleCheckboxPermissionChange(permission, subPermissions)}
                                    disabled={permission.isDisabled}
                                />
                            )}
                        </React.Fragment>
                    ))}
            <div className={styles.update_permission_submit_action_row}>
                <LinkButton title={translations.actions.cancel} onClick={navigateBackToCompanies} type={LinkButtonType.White} />

                <LinkButton
                    title={`${translations.actions.save} ${translations.words.permissions}`}
                    type={LinkButtonType.Blue}
                    onClick={() => {
                        handleSave();
                    }}
                />
            </div>
        </>
    );
};

export default PermissionsRaw;
