/* eslint-disable max-len */
import { FC, useEffect, useMemo, useState } from 'react';
import { Currency } from '../../../../Generated/Raven';
import { DemeterMarket, DemeterRegion, ExchangeType, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import ToggleableContainer from '../../../Components/Containers/Toggleable/ToggleableContainer';
import ButtonWithTooltip from '../../../Components/Form/Buttons/ButtonWithTooltip/ButtonWithTooltip';
import Dropdown from '../../../Components/Form/Inputs/Dropdown';
import LabelWithTooltip from '../../../Components/Form/Inputs/LabelWithTooltip';
import { SelectInputOption } from '../../../Components/Form/Inputs/SelectInput';
import SwitchWithLabel from '../../../Components/Form/Inputs/SwitchWithLabel';
import ComponentSubHeader from '../../../Components/Headers/ComponentSubHeader';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './BasisCalculator.module.scss';
import {
    BasisAdjustment,
    basisAdjustments,
    BasisCalculatorProductPair,
    basisCurrencies,
    BasisLagPeriod,
    basisLagPeriods,
    BasisMonthlyDate,
    basisMonthlyDates,
    BasisPeriod,
    basisPeriods,
    dairyUnitOfMeasures,
    energyUnitOfMeasures,
} from './BasisCalculatorDefinitions';
import { IBasisCalculatorCalculationResult } from './BasisCalculatorService';

interface IBasisCalculatorFormProps {
    productPair: BasisCalculatorProductPair;
    calculationResult?: IBasisCalculatorCalculationResult | undefined;
    onChange: (productPair: BasisCalculatorProductPair) => void;
    openProductSelector: () => void;
}

const BasisCalculatorForm: FC<IBasisCalculatorFormProps> = (props: IBasisCalculatorFormProps) => {
    const [translations, translate] = useLanguage();
    const [productPair, setProductPair] = useState<BasisCalculatorProductPair>(props.productPair);
    const [calculationResult, setCalculationResult] = useState<IBasisCalculatorCalculationResult | undefined>(props.calculationResult);
    const [basisUnitOfMeasureOptions, setBasisUnitOfMeasureOptions] = useState<SelectInputOption<UnitOfMeasure>[]>([]);

    const basisCurrencyOptions: SelectInputOption<Currency>[] = basisCurrencies.map((currency) => ({
        label: translations.currency[currency],
        value: currency,
    }));

    useEffect(() => {
        if (productPair.product1 && productPair.product1.market === DemeterMarket.Energy) {
            const unitOfMeasureOptions: SelectInputOption<UnitOfMeasure>[] = energyUnitOfMeasures.map((unit) => ({
                label: translations.unitOfMeasure[unit],
                value: unit,
            }));
            setBasisUnitOfMeasureOptions(unitOfMeasureOptions);
            return;
        }
        const unitOfMeasureOptions: SelectInputOption<UnitOfMeasure>[] = dairyUnitOfMeasures.map((unit) => ({
            label: translations.unitOfMeasure[unit],
            value: unit,
        }));
        setBasisUnitOfMeasureOptions(unitOfMeasureOptions);
    }, [productPair.product1]);

    const basisLagPeriodOptions: SelectInputOption<BasisLagPeriod>[] = basisLagPeriods.map((lag) => ({
        label: translations.calculators.basis.basisLagPeriod[lag],
        value: lag,
    }));
    const basisPeriodOptions: SelectInputOption<BasisPeriod>[] = basisPeriods.map((period) => ({
        label: translations.calculators.basis.basisPeriod[period],
        value: period,
    }));
    const basisAdjustmentOptions: SelectInputOption<BasisAdjustment>[] = basisAdjustments.map((adjustment) => ({
        label: translations.calculators.basis.basisAdjustment[adjustment],
        value: adjustment,
    }));

    useEffect(() => {
        setProductPair(props.productPair);
    }, [props.productPair]);

    useEffect(() => {
        setCalculationResult(props.calculationResult);
    }, [props.calculationResult]);

    useEffect(() => {
        props.onChange(productPair);
    }, [productPair]);

    const product1Name = useMemo(() => {
        if (productPair.product1) {
            if (productPair.product1.category === 'futures') {
                return `${translations.exchange[productPair.product1?.exchange as ExchangeType]} ${translate(productPair.product1?.name!)}`;
            }

            if (productPair.product1.category === 'physicalPrices') {
                return `${translations.region[productPair.product1?.region as DemeterRegion]} ${translate(productPair.product1?.name!)}`;
            }

            return productPair.product1?.name!;
        }

        return '';
    }, [productPair.product1]);

    const product2Name = useMemo(() => {
        if (productPair.product2) {
            if (productPair.product2.category === 'futures') {
                return `${translations.exchange[productPair.product2?.exchange as ExchangeType]} ${translate(productPair.product2?.name!)}`;
            }

            if (productPair.product2.category === 'physicalPrices') {
                return `${translations.region[productPair.product2?.region as DemeterRegion]} ${translate(productPair.product2?.name!)}`;
            }

            return productPair.product2?.name!;
        }

        return '';
    }, [productPair.product2]);

    const changeCurrency = (value: Currency) => {
        setProductPair({
            ...productPair,
            currency: value,
        });
    };

    const changeUnitOfMeasure = (value: UnitOfMeasure) => {
        setProductPair({
            ...productPair,
            unitOfMeasure: value,
        });
    };

    const changeBasisPeriod = (value: BasisPeriod) => {
        setProductPair({
            ...productPair,
            basisPeriod: value,
        });
    };

    const changeLagPeriod = (value: BasisLagPeriod) => {
        setProductPair({
            ...productPair,
            basisLagPeriod: value,
        });
    };

    const changeBasisAdjustment = (value: BasisAdjustment) => {
        setProductPair({
            ...productPair,
            basisAdjustment: value,
        });
    };

    const changeStartDate = (value: BasisMonthlyDate) => {
        setProductPair({
            ...productPair,
            startDate: value,
        });
    };

    const changeUseRegression = (value: boolean) => {
        setProductPair({
            ...productPair,
            useRegression: value,
        });
    };

    const changeShowAdvancedSettings = (value: boolean) => {
        setProductPair({
            ...productPair,
            showAdvancedSettings: value,
        });
    };

    return (
        <div className={styles.basis_calculator_form}>
            <div className={styles.basis_calculator_container}>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_half_width}>
                        <ComponentSubHeader title={translations.calculators.basis.form.product1} />
                        <p>
                            <ButtonWithTooltip text={product1Name} maximumLength={25} onClick={props.openProductSelector} />
                        </p>
                    </div>
                    <div className={styles.basis_calculator_cell_half_width}>
                        <ComponentSubHeader title={translations.calculators.basis.form.product2} />
                        <p>
                            <ButtonWithTooltip text={product2Name} maximumLength={25} onClick={props.openProductSelector} />
                        </p>
                    </div>
                </div>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_full_width}>
                        <hr />
                    </div>
                </div>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_third_width}>
                        <Dropdown
                            value={productPair.currency}
                            options={basisCurrencyOptions}
                            handleOptionChange={changeCurrency}
                            label={translations.words.currency}
                        />
                    </div>
                    <div className={styles.basis_calculator_cell_third_width}>
                        <Dropdown
                            value={productPair.unitOfMeasure}
                            options={basisUnitOfMeasureOptions}
                            handleOptionChange={changeUnitOfMeasure}
                            label={translations.words.unit}
                        />
                    </div>
                    <div className={styles.basis_calculator_cell_third_width}>
                        <Dropdown
                            value={productPair.startDate}
                            options={basisMonthlyDates}
                            handleOptionChange={changeStartDate}
                            label={translations.calculators.basis.form.historyStartDate}
                        />
                    </div>
                </div>

                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_full_width}>
                        <hr />
                    </div>
                </div>

                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_full_width}>
                        <p>
                            <strong>{translations.calculators.basis.form.correlation}</strong>
                        </p>
                        <p>{formattingService.toPercent(calculationResult?.correlation)}</p>
                    </div>
                </div>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_full_width}>
                        <hr />
                    </div>
                </div>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_full_width}>
                        <ToggleableContainer
                            open={productPair.showAdvancedSettings}
                            collapsedText={translations.calculators.basis.form.openAdvancedSettings}
                            expandedText={translations.calculators.basis.form.closeAdvancedSettings}
                            onChange={changeShowAdvancedSettings}
                        >
                            <div className={styles.basis_calculator_container}>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_full_width}>
                                        <ComponentSubHeader title={translations.calculators.basis.form.advancedCorrelation} />
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <p className={styles.basis_calculator_form_lag_period_header}>
                                            <strong>{translations.calculators.basis.form.lagPeriod}</strong>
                                        </p>
                                        <Dropdown value={productPair.basisLagPeriod} options={basisLagPeriodOptions} handleOptionChange={changeLagPeriod} />
                                    </div>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <p>
                                            <strong>{translations.calculators.basis.form.optimalLag}</strong>
                                        </p>
                                        <p>
                                            {calculationResult && (
                                                <span>
                                                    {formattingService.toPercent(calculationResult.optimalLagCorrelation)}
                                                    <span className={styles.basis_calculator_form_optimal_lag}>
                                                        {calculationResult?.optimalLagMonth! === 0 && `${translations.calculators.basis.form.noLag}`}
                                                        {(calculationResult?.optimalLagMonth! === 1 || calculationResult?.optimalLagMonth! === -1) &&
                                                            `${calculationResult.optimalLagMonth} ${translations.calculators.basis.form.month}`}
                                                        {(calculationResult?.optimalLagMonth! > 1 || calculationResult?.optimalLagMonth! < -1) &&
                                                            `${calculationResult.optimalLagMonth} ${translations.calculators.basis.form.months}`}
                                                    </span>
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <p>
                                            <strong>{translations.calculators.basis.form.lagCorrelation}</strong>
                                        </p>
                                        <p>{formattingService.toPercent(calculationResult?.lagCorrelation)}</p>
                                    </div>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <p>
                                            <strong>
                                                {translations.calculators.basis.form.lag} R<sup>2</sup>
                                            </strong>
                                        </p>
                                        <p>{formattingService.toPercent(calculationResult?.lagRSquared)}</p>
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_full_width}>
                                        <hr />
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_ful_width}>
                                        <ComponentSubHeader title={translations.calculators.basis.form.statisticalRelationship} />
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <p>
                                            <strong>{translations.calculators.basis.form.slope}</strong>
                                        </p>
                                        <p>{formattingService.toFormattedNumber(calculationResult?.slope)}</p>
                                    </div>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <p>
                                            <strong>{translations.calculators.basis.form.intercept}</strong>
                                        </p>
                                        <p>{formattingService.toFormattedNumber(calculationResult?.intercept)}</p>
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_full_width}>
                                        <SwitchWithLabel
                                            checked={productPair.useRegression!}
                                            onChange={changeUseRegression}
                                            label={translations.calculators.basis.form.useRegression}
                                        />
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_full_width}>
                                        <hr />
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_full_width}>
                                        <ComponentSubHeader title={translations.calculators.basis.form.basisSettings} />
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <Dropdown
                                            value={productPair.basisPeriod}
                                            options={basisPeriodOptions}
                                            handleOptionChange={changeBasisPeriod}
                                            label={translations.calculators.basis.form.basisPeriod}
                                        />
                                    </div>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <Dropdown
                                            value={productPair.basisAdjustment}
                                            options={basisAdjustmentOptions}
                                            handleOptionChange={changeBasisAdjustment}
                                            label={
                                                <LabelWithTooltip
                                                    title={translations.calculators.basis.form.basisAdjustment}
                                                    tooltip={translations.calculators.basis.form.basisAdjustmentInformation}
                                                    position="middle"
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_full_width}>
                                        <p>
                                            <strong>
                                                {productPair.basisAdjustment && translations.calculators.basis.basisAdjustment[productPair.basisAdjustment]}{' '}
                                                {productPair.basisPeriod && translations.calculators.basis.basisPeriod[productPair.basisPeriod]}
                                            </strong>
                                        </p>
                                        <p>{calculationResult?.metric && formattingService.toPriceString(calculationResult.metric)}</p>
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_full_width}>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </ToggleableContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasisCalculatorForm;
