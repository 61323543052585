import React from 'react';
import styles from './MenuFileSelectors.module.scss';

export interface IBreadCrumb {
    breadCrumbs?: string;
}

const BreadCrumb: React.FC<IBreadCrumb> = (props: IBreadCrumb) => {
    if (!props.breadCrumbs) {
        return <div />;
    }

    return <div className={styles.menu_breadcrumb_breadcrumb}>{props.breadCrumbs}</div>;
};
export default BreadCrumb;
