import scssVariables from '../../../../../Config.module.scss';
import { CoverageTableItemType, CoverageTableLineModel } from '../../../../../Generated/Raven-Demeter';
import formattingService from '../../../../Services/Formatting/FormattingService';
import styles from './MarginHeatMapTable.module.scss';

interface IMarginHeatMapTableRowProps {
    currentRow: CoverageTableLineModel;
    firstWeightedPriceLine: number;
    secondWeightedPriceLine: number;
    getTableFormattedNumber: (unformattedNumber: number) => string;
}

const MarginHeatMapTableRow: React.FC<IMarginHeatMapTableRowProps> = (props: IMarginHeatMapTableRowProps) => {
    const numberOfCellsBeforeFirstWeightedPriceLine = props.currentRow.coverageTableItems.filter((x) => x.price <= props.firstWeightedPriceLine).length ?? 0;
    const numberOfCellsBeforeSecondWeightedPriceLine = props.currentRow.coverageTableItems.filter((x) => x.price <= props.secondWeightedPriceLine).length ?? 0;

    const getColorPercentileGradient = (coverageTableItemType: CoverageTableItemType, colorWeight: number) => {
        if (colorWeight > 100 && coverageTableItemType === CoverageTableItemType.RiskOverHedged) {
            const colorPercentileGradient = (colorWeight - 100) * 2;
            return `${colorPercentileGradient}% 0`;
        }

        if (colorWeight > 100) {
            return '100% 0';
        }

        if (colorWeight < 0) {
            return '0% 0';
        }

        return `${colorWeight}% 0`;
    };

    const getRightBorder = (index: number) => {
        const isWeightedPriceColumn = index === numberOfCellsBeforeFirstWeightedPriceLine - 1 || index + 1 === numberOfCellsBeforeSecondWeightedPriceLine;

        if (isWeightedPriceColumn) {
            return `2px solid ${scssVariables.black}`;
        }

        return 'none';
    };

    const getBackgroundColor = (coverageTableItemType: CoverageTableItemType, colorWeight: number | null) => {
        if (colorWeight === null) {
            return scssVariables.marginColorScaleNull;
        }

        if (coverageTableItemType === CoverageTableItemType.RiskOverHedged) {
            return `linear-gradient(to right, ${scssVariables.marginColorScaleRisk7}, ${scssVariables.marginColorScaleRiskOverHedged}) 100% 0px / 10000% 100%`;
        }

        if (coverageTableItemType === CoverageTableItemType.Risk) {
            return `linear-gradient(to right, ${scssVariables.marginColorScaleRisk1}, ${scssVariables.marginColorScaleRisk2}, 
                ${scssVariables.marginColorScaleRisk3}, ${scssVariables.marginColorScaleRisk4}, ${scssVariables.marginColorScaleRisk5}, 
                ${scssVariables.marginColorScaleRisk6}, ${scssVariables.marginColorScaleRisk7}) 100% 0px / 10000% 100%`;
        }

        return `linear-gradient(to right, ${scssVariables.marginColorScaleOpportunity1},
            ${scssVariables.marginColorScaleOpportunity2}, ${scssVariables.marginColorScaleOpportunity3}, 
            ${scssVariables.marginColorScaleOpportunity4}) 100% 0px / 10000% 100%`;
    };

    return (
        <div className={styles.margin_table_row}>
            <div style={{ borderRight: getRightBorder(numberOfCellsBeforeFirstWeightedPriceLine === 0 ? -1 : 0) }} className={styles.margin_table_cell_weight}>
                {props.getTableFormattedNumber(props.currentRow.production)}
            </div>
            {props.currentRow.coverageTableItems.map((x, index) => (
                <div
                    key={`MarginTableRowCell_${x.price}`}
                    style={{
                        background: getBackgroundColor(x.coverageTableItemType, x.colorWeight!),
                        backgroundPosition: getColorPercentileGradient(x.coverageTableItemType, x.colorWeight ?? 0),
                        color: scssVariables.mainTextColor,
                        borderRight: getRightBorder(index),
                    }}
                    className={styles.margin_table_cell_percent}
                >
                    {formattingService.toPercentWithoutPlaceholder(x.percentCoverage)}
                </div>
            ))}
        </div>
    );
};

export default MarginHeatMapTableRow;
