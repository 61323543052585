import { demeterApi } from '../../../Apis/Apis';
import { DemeterMarket, DemeterTableDefinitionCommodityModel, DemeterTableDefinitionType } from '../../../Generated/Raven-Demeter/api';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserCurrentMarket } from '../../../Redux/Slices/UserSlice';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useCacheOrApi from './useCacheOrApiHook';

const useTableDefinitionsByCommodityApi = (
    tableDefinitionType: DemeterTableDefinitionType,
    overrideMarket?: DemeterMarket,
): DemeterTableDefinitionCommodityModel[] | undefined => {
    const applicationMarket = useApplicationSelector(selectUserCurrentMarket);
    const market = overrideMarket ?? applicationMarket;

    const [loading, , listDemeterTableDefinitionsResponse] = useCacheOrApi(
        `${CacheKeys.listDemeterTableDefinitionsCommodity}_${tableDefinitionType}_${market}`,
        () => demeterApi.listDemeterTableDefinitionsByCommodity(tableDefinitionType, market),
    );

    return loading ? undefined : listDemeterTableDefinitionsResponse?.rows ?? undefined;
};

export default useTableDefinitionsByCommodityApi;
