import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterPageElementsApi, demeterPresentationTemplatesApi } from '../../../Apis/Apis';
import { DemeterPageElementModel, PresentationFormat } from '../../../Generated/Raven-Demeter';
import useApi from '../../Apis/Hooks/useApiHook';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import CheckboxInput from '../../Components/Form/Inputs/CheckboxInput';
import TextInput from '../../Components/Form/Inputs/TextInput';
import PageHeaderWithBackNavigation from '../../Components/Headers/PageHeaderWithBackNavigation';
import SortableList from '../../Components/Lists/SortableList/SortableList';
import useLanguage from '../../Services/Language/useLanguageHook';
import PresentationTemplateContentSelector from './PresentationTemplateContentSelector';
import PresentationTemplatePageElement from './PresentationTemplatePageElement';
import styles from './PresentationTemplatesPage.module.scss';

const PresentationTemplateEditPage: React.FC = () => {
    const [translations] = useLanguage();
    const navigate = useNavigate();
    const { presentationTemplateGuid } = useParams();
    const [templateGuid, setTemplateGuid] = useState<string>();
    const [name, setName] = useState<string>();
    const [format, setFormat] = useState<PresentationFormat>(PresentationFormat.Ppt);
    const [pageElement, setPageElement] = useState<DemeterPageElementModel | undefined>();
    const [templatePageElements, setTemplatePageElements] = useState<DemeterPageElementModel[] | undefined>();
    const [pageElements, setPageElements] = useState<DemeterPageElementModel[]>([]);

    const [, , listDemeterPageElementsResponse] = useApi(() => demeterPageElementsApi.listDemeterPageElements());

    useEffect(() => {
        if (!presentationTemplateGuid) {
            return;
        }
        // Fix. Mono adds encoded parameter and changes Guid, so we have to remove it
        const guid = decodeURIComponent(presentationTemplateGuid).split('?')[0];
        setTemplateGuid(guid);
    }, [presentationTemplateGuid]);

    useEffect(() => {
        if (!listDemeterPageElementsResponse) {
            return;
        }
        setPageElements(listDemeterPageElementsResponse?.rows ?? []);
    }, [listDemeterPageElementsResponse]);

    const [, getPresentationTemplate, getPresentationTemplateResponse] = useApiWithoutAutoExecute(
        () => demeterPresentationTemplatesApi.getPresentationTemplate(templateGuid!),
        {
            errorMessage: translations.presentationTemplates.errors.getPresentationTemplateError,
        },
    );

    useEffect(() => {
        if (!templateGuid) {
            return;
        }
        getPresentationTemplate();
    }, [templateGuid]);

    const [, updatePresentationTemplate] = useApiWithoutAutoExecute(
        () => {
            if (!isValid) {
                return null;
            }
            return demeterPresentationTemplatesApi.updatePresentationTemplate({
                presentationTemplateGuid: templateGuid,
                name,
                format,
                rows: templatePageElements,
            });
        },
        {
            successMessage: translations.presentationTemplates.success.updatePresentationTemplateSuccess,
            errorMessage: translations.presentationTemplates.errors.updatePresentationTemplateError,
        },
    );

    useEffect(() => {
        if (!getPresentationTemplateResponse) {
            return;
        }
        setTemplatePageElements(getPresentationTemplateResponse.presentationTemplate?.rows);
    }, [getPresentationTemplateResponse]);

    useEffect(() => {
        if (!getPresentationTemplateResponse || !getPresentationTemplateResponse.presentationTemplate) {
            return;
        }
        setName(getPresentationTemplateResponse.presentationTemplate.name);
        setFormat(getPresentationTemplateResponse.presentationTemplate.format);
    }, [getPresentationTemplateResponse]);

    const navigateBack = () => {
        navigate(-1);
    };

    const handleSelect = (selected: boolean) => {
        if (!templatePageElements || !pageElement) {
            return;
        }
        if (selected) {
            templatePageElements?.push(pageElement);
            setTemplatePageElements([...templatePageElements]);
        } else {
            setTemplatePageElements([...templatePageElements.filter((x) => x !== pageElement)]);
        }
    };

    const isValid = !!templateGuid && !!name && !!format;

    const pageElementSelected = templatePageElements?.some((x) => x.demeterPageElementGuid === pageElement?.demeterPageElementGuid);

    return (
        <div className={styles.master_page_container}>
            <PageHeaderWithBackNavigation handleBackNavigation={navigateBack} title={translations.presentationTemplates.title.edit} />
            <div className={styles.presentation_templates_main_container}>
                <div className={styles.presentation_templates_add_toolbar}>
                    <div className={styles.presentation_templates_add_toolbar_section}>
                        <div>{translations.presentationTemplates.fields.templateName}</div>
                        <TextInput value={name} handleTextChange={setName} required />
                    </div>
                    <div className={styles.presentation_templates_add_toolbar_section_separator} />
                    <div className={styles.presentation_templates_add_toolbar_section}>
                        <div>{translations.presentationTemplates.fields.templateFormat}</div>
                        <div className={styles.presentation_templates_add_toolbar_format_options}>
                            <CheckboxInput
                                isChecked={format === PresentationFormat.Pdf}
                                title="PDF"
                                handleIsChecked={() => setFormat(PresentationFormat.Pdf)}
                            />
                            <CheckboxInput
                                isChecked={format === PresentationFormat.Ppt}
                                title="PPT"
                                handleIsChecked={() => setFormat(PresentationFormat.Ppt)}
                            />
                        </div>
                    </div>
                    <div className={styles.presentation_templates_add_toolbar_section_separator} />
                    <div className={styles.presentation_templates_add_toolbar_section}>
                        <LinkButton title={translations.actions.save} type={LinkButtonType.Blue} onClick={updatePresentationTemplate} disabled={!isValid} />
                    </div>
                </div>
                <div className={styles.presentation_templates_add_selection}>
                    <div className={styles.presentation_templates_add_selection_tree}>
                        <PresentationTemplateContentSelector
                            pageElements={pageElements}
                            templatePageElements={templatePageElements}
                            onSelect={setPageElement}
                        />
                    </div>
                    <div className={styles.presentation_templates_add_selection_preview}>
                        <PresentationTemplatePageElement pageElement={pageElement} selected={pageElementSelected} onSelect={handleSelect} />
                    </div>
                    <div className={styles.presentation_templates_add_selection_order}>
                        <SortableList
                            items={templatePageElements}
                            height={450}
                            renderItem={(item: DemeterPageElementModel) => (
                                <div>
                                    {item.region} {item.commodity} {item.pageType} {item.pageElementType}
                                </div>
                            )}
                            onChange={setTemplatePageElements}
                            onSelect={setPageElement}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PresentationTemplateEditPage;
