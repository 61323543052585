import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    DemeterFeatureType,
    DemeterMarket,
    DemeterPermissionType,
    DemeterRegistrationSource,
    DemeterTableDefinitionType,
    DemeterUserType,
} from '../../Generated/Raven-Demeter';
import NavigationRoutes from '../../Layouts/NavigationRoutes';
import { useApplicationSelector } from '../../Redux/ReduxStore';
import { selectRegistrationSource, selectUserCurrentMarket, selectUserType } from '../../Redux/Slices/UserSlice';
import Dropdown from '../../Refactor/Components/Form/Inputs/Dropdown';
import { SelectInputOption } from '../../Refactor/Components/Form/Inputs/SelectInput';
import useSearchParameters from '../../Refactor/Components/Navigation/Hooks/useSearchParametersHook';
import useScreenSize from '../../Refactor/Components/Responsive/Hooks/useScreenSizeHook';
import useFeatureFlag from '../../Refactor/Services/FeatureFlags/useFeatureFlagHook';
import useLanguage from '../../Refactor/Services/Language/useLanguageHook';
import { EventActionsEnum, EventDataTargetsEnum } from '../../Refactor/Services/Logging/DataLayerDefinitions';
import loggingService, { breadCrumbs } from '../../Refactor/Services/Logging/LoggingService';
import usePermission from '../../Refactor/Services/Permissions/usePermissionHook';
import styles from './Sidebar.module.scss';

type SearchParametersType = 'exchangeCommodity' | 'regionCommodity';

interface INavigationLink {
    analyticsKey?: string;
    divider?: boolean;
    isHeader?: boolean;
    isLineBreak?: boolean;
    isPageOn?: boolean;
    link?: string;
    name: string;
    recordSearchParameters?: boolean;
    searchParametersType?: SearchParametersType;
    tableDefinitionType?: DemeterTableDefinitionType;
    url?: string;
    includedMarkets?: DemeterMarket[]; // If this is undefined, link is good for all markets, otherwise, only for these markets.
    excludedMarkets?: DemeterMarket[]; // Use this to exclude these markets from this menu item.
}

interface INavigationLinkStatus {
    isCurrentLink?: boolean;
    searchString?: string;
}

interface INavigationLinkStatusMap {
    [key: string]: INavigationLinkStatus;
}

const Sidebar: React.FC = () => {
    const location = useLocation().pathname.toUpperCase();
    const market = useApplicationSelector(selectUserCurrentMarket);
    const currentUserType = useApplicationSelector(selectUserType);
    const currentRegistrationSource = useApplicationSelector(selectRegistrationSource);
    const isCancelTrialPageOn =
        currentUserType === DemeterUserType.Regular && currentRegistrationSource === DemeterRegistrationSource.SelfRegistrationWithCreditCard;
    const isAdministrationPageOn = useFeatureFlag(DemeterFeatureType.Administration);
    const isHedgeMonitorProPageOn = useFeatureFlag(DemeterFeatureType.HedgeMonitorPro) && usePermission(DemeterPermissionType.HedgeMonitorPro);
    const isMarketIndicatorPageOn = useFeatureFlag(DemeterFeatureType.MarketIndicators) && usePermission(DemeterPermissionType.MarketIndicators);
    const isFeatureFlagsPageOn = currentUserType === DemeterUserType.Administrator;
    const isPositionsCalculatorPageOn = useFeatureFlag(DemeterFeatureType.PositionsCalculator) && usePermission(DemeterPermissionType.PositionsCalculator);
    const isBudgetCalculatorPageOn = useFeatureFlag(DemeterFeatureType.BudgetCalculator) && usePermission(DemeterPermissionType.BudgetCalculator);
    const isFarmerMarginCalculatorPageOn =
        useFeatureFlag(DemeterFeatureType.FarmerMarginCalculator) && usePermission(DemeterPermissionType.FarmerMarginCalculator);
    const isRiskPageOn = isPositionsCalculatorPageOn || isBudgetCalculatorPageOn || isFarmerMarginCalculatorPageOn;
    const isPresentationTemplatesPageOn = useFeatureFlag(DemeterFeatureType.PresentationTemplates);
    const isUserDataPageOn =
        useFeatureFlag(DemeterFeatureType.UserData) && (currentUserType === DemeterUserType.Administrator || currentUserType === DemeterUserType.Premium);

    const [searchParameters] = useSearchParameters();
    const [translations] = useLanguage();
    const screenSize = useScreenSize();
    const navigate = useNavigate();

    const allLinks = useMemo<INavigationLink[]>(
        () => [
            { name: translations.words.overview, isHeader: true },
            { analyticsKey: 'Dashboard', name: translations.dashboard.title, link: NavigationRoutes.Dashboard },
            {
                analyticsKey: 'Market Indicators',
                name: translations.page.marketIndicators,
                link: NavigationRoutes.MarketIndicators,
                recordSearchParameters: true,
                isPageOn: isMarketIndicatorPageOn,
            },
            {
                analyticsKey: 'HedgeMonitor Pro',
                name: translations.page.hedgeMonitorPro,
                link: NavigationRoutes.HedgeMonitorPro,
                recordSearchParameters: true,
                isPageOn: isHedgeMonitorProPageOn,
            },
            {
                analyticsKey: 'Outlook and Reports',
                name: translations.outlookAndReports.title,
                link: NavigationRoutes.OutlookAndReports,
                divider: true,
                includedMarkets: [DemeterMarket.Dairy],
            },
            { name: 'Line Break 1', isLineBreak: true },
            { name: translations.words.prices, isHeader: true },
            {
                analyticsKey: 'Futures',
                name: market === DemeterMarket.Dairy ? translations.futures.title : translations.futures.titleWithoutOtc,
                link: NavigationRoutes.Futures,
                recordSearchParameters: true,
            },
            {
                analyticsKey: 'Physical Prices',
                name: translations.physicalPrices.title,
                link: NavigationRoutes.PhysicalPrices,
                recordSearchParameters: true,
                includedMarkets: [DemeterMarket.Dairy],
            },
            { name: 'Line Break 2', isLineBreak: true },
            { name: translations.words.fundamentals, isHeader: true },
            { analyticsKey: 'Production', name: translations.page.production, link: NavigationRoutes.Production, recordSearchParameters: true },
            { analyticsKey: 'Exports', name: translations.page.exports, link: NavigationRoutes.Exports, recordSearchParameters: true },
            { analyticsKey: 'Imports', name: translations.page.imports, link: NavigationRoutes.Imports, recordSearchParameters: true },
            { analyticsKey: 'Domestics', name: translations.page.domestics, link: NavigationRoutes.Domestics, recordSearchParameters: true },
            { analyticsKey: 'Stocks', name: translations.page.stocks, link: NavigationRoutes.Stocks, recordSearchParameters: true },
            {
                analyticsKey: 'Balance Table',
                name: translations.balanceTable.title,
                link: NavigationRoutes.BalanceTable,
                recordSearchParameters: true,
                includedMarkets: [DemeterMarket.Dairy],
            },
            { name: 'Line Break 3', isLineBreak: true },
            { name: translations.words.tools, isHeader: true },
            { analyticsKey: 'Calculators', name: translations.page.calculators, link: NavigationRoutes.Calculators, recordSearchParameters: true },
            {
                analyticsKey: 'Risk',
                name: translations.page.risk,
                link: NavigationRoutes.Risk,
                isPageOn: isRiskPageOn,
                recordSearchParameters: true,
                includedMarkets: [DemeterMarket.Dairy],
            },
            {
                analyticsKey: 'PresentationTemplates',
                name: translations.page.presentationTemplates,
                link: NavigationRoutes.PresentationTemplates,
                isPageOn: isPresentationTemplatesPageOn,
            },
            { name: 'Line Break 4', isLineBreak: true, isPageOn: isUserDataPageOn || isAdministrationPageOn || isFeatureFlagsPageOn },
            { name: translations.words.management, isHeader: true, isPageOn: isUserDataPageOn || isAdministrationPageOn || isFeatureFlagsPageOn },
            { analyticsKey: 'User Data', name: translations.page.userData, link: NavigationRoutes.UserData, isPageOn: isUserDataPageOn },
            {
                analyticsKey: 'Administration',
                name: translations.administration.title,
                link: NavigationRoutes.Administration,
                isPageOn: isAdministrationPageOn,
            },
            {
                analyticsKey: 'Feature Flags',
                name: translations.featureFlags.title,
                link: NavigationRoutes.FeatureFlags,
                isPageOn: isFeatureFlagsPageOn,
            },
            { name: 'Line Break 5', isLineBreak: true },
            { name: translations.text.helpAndPolicies, isHeader: true },
            { analyticsKey: 'Frequently Asked Questions', name: translations.page.frequentlyAskedQuestions, link: NavigationRoutes.Faqs },
            { analyticsKey: 'Disclaimer', name: translations.page.disclaimer, link: NavigationRoutes.Disclaimer },
            { analyticsKey: 'Privacy Policy', name: translations.page.privacyPolicy, link: NavigationRoutes.PrivacyPolicy },
            { analyticsKey: 'Cancel Trial', name: translations.page.cancelTrial, link: NavigationRoutes.CancelTrial, isPageOn: isCancelTrialPageOn },
        ],
        [
            market,
            isAdministrationPageOn,
            isUserDataPageOn,
            isMarketIndicatorPageOn,
            isHedgeMonitorProPageOn,
            isRiskPageOn,
            isCancelTrialPageOn,
            isPresentationTemplatesPageOn,
            translations,
        ],
    );

    const [linkStatusMap, setLinkStatusMap] = useState<INavigationLinkStatusMap>({});

    // Used to keep cache of the search string for each page.
    useEffect(() => {
        const parsedLocation = location.indexOf('?') > 0 ? location.substring(0, location.indexOf('?')) : location;
        const currentLink = allLinks.filter((link) => link.link).find((link) => parsedLocation.endsWith(link.link!.toUpperCase()));
        const newLinkStatusMap: INavigationLinkStatusMap = { ...linkStatusMap, [currentLink?.link ?? '']: {} };

        Object.keys(newLinkStatusMap).forEach((key) => {
            const linkStatus = newLinkStatusMap[key];
            linkStatus.isCurrentLink = key === currentLink?.link;

            if (linkStatus.isCurrentLink && currentLink?.recordSearchParameters && Object.values(searchParameters).filter((x) => x).length !== 0) {
                linkStatus.searchString = `?${Object.keys(searchParameters).reduce((searchString, searchStringKey) => {
                    if (searchString === '') {
                        return `${searchStringKey}=${searchParameters[searchStringKey]}`;
                    }

                    return `${searchString}&${searchStringKey}=${searchParameters[searchStringKey]}`;
                }, '')}`;
            }
        });
        setLinkStatusMap(newLinkStatusMap);
    }, [location, searchParameters]);

    const handleNavigationClick = (navigationLink: INavigationLink): void => {
        breadCrumbs.page = navigationLink.analyticsKey!;
        loggingService.trackEventWithAnalytics(
            EventActionsEnum.Navigation,
            `Navigate to ${navigationLink.analyticsKey} page`,
            '',
            EventDataTargetsEnum.Sidebar,
        );
    };

    const findSidebarSelection = (): string | undefined => {
        const selection = allLinks.find((item: INavigationLink) => linkStatusMap[item.link ?? '']?.isCurrentLink);
        return selection?.link;
    };

    const getSidebarOptions = (): (SelectInputOption<string | undefined> & { divider?: boolean })[] => {
        const sidebarOptions = allLinks.filter((item: INavigationLink) => item?.isPageOn !== false && !item?.isLineBreak);
        return sidebarOptions?.map((item: INavigationLink) => ({ label: item.name, value: item?.link, divider: item.divider }));
    };

    if (screenSize?.type === 'sm' || screenSize?.type === 'xs') {
        return (
            <div className={styles.responsive_sidebar}>
                <Dropdown
                    value={findSidebarSelection()}
                    options={getSidebarOptions()}
                    handleOptionChange={(value: string | undefined) => navigate(value ?? '')}
                />
            </div>
        );
    }

    return (
        <div className={styles.sidebar_blue}>
            <div className={styles.sidebar_content}>
                <div className={styles.sidebar_navigation}>
                    {allLinks
                        .filter((x) => !x.includedMarkets || x.includedMarkets.includes(market))
                        .map(
                            (navigationLink: INavigationLink) =>
                                navigationLink.isPageOn !== false && (
                                    <React.Fragment key={`${navigationLink.name.toString()}_link`}>
                                        {navigationLink.isLineBreak && <div className={styles.sidebar_navigation_divider} />}
                                        {navigationLink.isHeader && <div className={styles.sidebar_navigation_header}>{navigationLink.name}</div>}
                                        {!navigationLink.isLineBreak && !navigationLink.isHeader && (
                                            <div key={`${navigationLink.name.toString()}_link`} className={styles.master_flex}>
                                                <Link
                                                    onClick={() => handleNavigationClick(navigationLink)}
                                                    className={
                                                        linkStatusMap[navigationLink.link ?? '']?.isCurrentLink
                                                            ? styles.sidebar_navItem_selected
                                                            : styles.sidebar_navItem
                                                    }
                                                    to={`${navigationLink.link!}${linkStatusMap[navigationLink.link ?? '']?.searchString ?? ''}`}
                                                >
                                                    {navigationLink.name}
                                                </Link>
                                            </div>
                                        )}
                                    </React.Fragment>
                                ),
                        )}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
