import React, { memo, useMemo } from 'react';
import { IChartDataSeries } from '../../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../../Components/Charts/ChartWrapper/ChartWrapper';
import MultiBarLinesAreaChartRaw from '../../../../Components/Charts/MultiBarLinesArea/MultiBarLinesAreaChartRaw';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { MarginCompositeModel } from '../../RiskDefinitions';

export interface IMarginTrackerChartProps {
    getFarmerMarginCalculatorCompositeRowsModels: MarginCompositeModel[];
    getCalculatorFieldValue: (marginCompositeModel: MarginCompositeModel, fieldName: keyof MarginCompositeModel) => number;
    displayDecimalPlaces: number;
}

const MarginTrackerChart: React.FC<IMarginTrackerChartProps> = (props: IMarginTrackerChartProps) => {
    const [translations] = useLanguage();

    const linesSeries = useMemo<IChartDataSeries[]>(
        () => [
            {
                label: translations.risk.margin.text.costTotalPrice,
                data: props.getFarmerMarginCalculatorCompositeRowsModels.map((x) => ({
                    value: props.getCalculatorFieldValue(x, x.costTotalPrice.variableName as keyof MarginCompositeModel),
                    asOfDate: new Date(x.asOfDate),
                    isActualValue: true,
                })),
            },
            {
                label: translations.risk.margin.text.futuresBlendPrice,
                data: props.getFarmerMarginCalculatorCompositeRowsModels.map((x) => ({
                    value: props.getCalculatorFieldValue(x, x.futuresBlendPrice.variableName as keyof MarginCompositeModel),
                    asOfDate: new Date(x.asOfDate),
                    isActualValue: true,
                })),
            },
        ],
        [props.getFarmerMarginCalculatorCompositeRowsModels],
    );

    const barsSeries = useMemo<IChartDataSeries[]>(
        () => [
            {
                label: translations.risk.margin.text.unhedgedMargin,
                data: props.getFarmerMarginCalculatorCompositeRowsModels.map((x) => ({
                    value: props.getCalculatorFieldValue(x, x.unhedgedMargin.variableName as keyof MarginCompositeModel),
                    asOfDate: new Date(x.asOfDate),
                    isActualValue: true,
                })),
            },
            {
                label: translations.risk.margin.text.hedgedMargin,
                data: props.getFarmerMarginCalculatorCompositeRowsModels.map((x) => ({
                    value: props.getCalculatorFieldValue(x, x.hedgedMargin.variableName as keyof MarginCompositeModel),
                    asOfDate: new Date(x.asOfDate),
                    isActualValue: true,
                })),
            },
        ],
        [props.getFarmerMarginCalculatorCompositeRowsModels],
    );

    const dataSourceTag = useMemo(() => translations.dataSource.StoneXCalculations, [translations]);

    const currency = useMemo(
        () => props.getFarmerMarginCalculatorCompositeRowsModels[0]?.costTotalPrice.currency,
        [props.getFarmerMarginCalculatorCompositeRowsModels[0]?.costTotalPrice.currency],
    );

    const yAxisLabel = useMemo(() => {
        if (currency) {
            return `${translations.words.cost} currency(${translations.currency[`Short${currency}`]})`;
        }

        return translations.words.cost;
    }, [translations, currency]);

    return (
        <ChartWrapper
            name="MarginTrackerChart"
            title={translations.risk.headers.marginTrackerChart}
            dataSourceTag={dataSourceTag}
            isLoading={props.getFarmerMarginCalculatorCompositeRowsModels.length === 0}
        >
            <MultiBarLinesAreaChartRaw
                linesSeries={linesSeries}
                barsSeries={barsSeries}
                unitOfMeasure={props.getFarmerMarginCalculatorCompositeRowsModels[0]?.costTotalPrice.unitOfMeasure}
                currency={currency}
                yAxisLabel={yAxisLabel}
                displayDecimalPlacesMinimum={props.displayDecimalPlaces}
                displayDecimalPlacesMaximum={props.displayDecimalPlaces}
            />
        </ChartWrapper>
    );
};

export default memo(MarginTrackerChart);
