import { Tooltip } from '@mui/material';
import React from 'react';
import infoSvg from '../../Assets/Icons/info.svg';
import styles from './Headers.module.scss';

export interface IPageHeaderWithTooltip {
    title: string;
    testId?: string;
    tooltip?: string | JSX.Element;
    position?: 'bottom' | 'middle';
    placement?:
        | 'top'
        | 'top-start'
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end';
}

const PageHeaderWithTooltip: React.FC<IPageHeaderWithTooltip> = (props: IPageHeaderWithTooltip) => (
    <div className={styles.page_header_container}>
        <div className={styles.page_header_title_container}>
            <h3 data-testid={props.testId} className={styles.page_header_page_title_tooltip}>
                {props.title}
                <Tooltip
                    className={props.position === 'middle' ? styles.page_header_tooltip_middle : styles.page_header_tooltip}
                    title={props.tooltip}
                    placement={props.placement ?? 'bottom-start'}
                >
                    <img src={infoSvg} alt="#" />
                </Tooltip>
            </h3>
        </div>
    </div>
);

export default PageHeaderWithTooltip;
