import { useEffect, useRef, useState } from 'react';
import { demeterCalculatorsApi } from '../../../../../Apis/Apis';
import { CalculationEngineMonthlyValueOverrides, CoverageTableLineModel } from '../../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../../Apis/Hooks/useApiWithoutAutoExecute';
import useWindowDimensions from '../../../../Components/Responsive/Hooks/useWindowDimensionsHook';
import formattingService from '../../../../Services/Formatting/FormattingService';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { MarginHeatMapTableDates, MarginRequest, UpsertCalculationEngine } from '../../RiskDefinitions';
import styles from './MarginHeatMapTable.module.scss';
import MarginHeatMapTableHeaderRow from './MarginHeatMapTableHeaderRow';
import MarginHeatMapTableRow from './MarginHeatMapTableRow';
import MarginHeatMapTableWrapper from './MarginHeatMapTableWrapper';

interface IMarginHeatMapTableProps {
    upsertCalculationEngine: UpsertCalculationEngine;
    runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null;
    marginInputs: MarginRequest;
}

const minimumCellWidth = 80;
const defaultDisplayDecimalPlaces = 2;
const defaultDisplayDecimalPlacesForNumbersOverOneHundred = 0;
const biasMultiplier = 1;

const MarginHeatMapTable: React.FC<IMarginHeatMapTableProps> = (props: IMarginHeatMapTableProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const [cellWidth, setCellWidth] = useState<number>(minimumCellWidth);
    const windowDimensions = useWindowDimensions();

    const cellElementReference = useRef<HTMLDivElement>(null);
    const weightedPriceValueTextStyle = { left: cellWidth, width: cellWidth * 2 };

    // Request hooks.
    const [marginTableDateRange, setMarginTableDateRange] = useState<MarginHeatMapTableDates>();

    const [, refreshRunTestFarmerMarginCalculator, runTestFarmerMarginCalculatorResponse] = useApiWithoutAutoExecute(() =>
        demeterCalculatorsApi.runTestFarmerMarginCalculator(props.upsertCalculationEngine.demeterCalculationEngineGuid, {
            demeterCalculationEngineGuid: props.upsertCalculationEngine.demeterCalculationEngineGuid,
            coverageTableStartDate: formattingService.toApiDate(marginTableDateRange!.coverageTableStartDate.value),
            coverageTableEndDate: formattingService.toApiDate(marginTableDateRange!.coverageTableEndDate.value),
            biasMultiplier,
            monthlyValueOverrides: props.runMonthlyValueOverrides,
            valueOverrides: {},
            dataOverrides: {},
        }),
    );

    // Line locator helpers.
    const firstWeightedPriceLine = Math.min(
        runTestFarmerMarginCalculatorResponse?.weightedBreakEvenPrice ?? 0,
        runTestFarmerMarginCalculatorResponse?.weightedFuturesBlendPrice ?? 0,
    );

    const secondWeightedPriceLine = Math.max(
        runTestFarmerMarginCalculatorResponse?.weightedBreakEvenPrice ?? 0,
        runTestFarmerMarginCalculatorResponse?.weightedFuturesBlendPrice ?? 0,
    );

    const numberOfCellsBeforeFirstWeightedPriceLine =
        runTestFarmerMarginCalculatorResponse?.coverageTableLines && runTestFarmerMarginCalculatorResponse.coverageTableLines[0]
            ? runTestFarmerMarginCalculatorResponse.coverageTableLines[0]?.coverageTableItems.filter((x) => x.price <= firstWeightedPriceLine).length
            : 0;

    const numberOfCellsAfterSecondWeightedPriceLine =
        runTestFarmerMarginCalculatorResponse?.coverageTableLines && runTestFarmerMarginCalculatorResponse.coverageTableLines[0]
            ? runTestFarmerMarginCalculatorResponse.coverageTableLines[0]?.coverageTableItems.filter((x) => x.price > secondWeightedPriceLine).length
            : 0;

    useEffect(() => {
        if (!props.upsertCalculationEngine) {
            return;
        }

        refreshRunTestFarmerMarginCalculator();
    }, [props.upsertCalculationEngine, marginTableDateRange, props.runMonthlyValueOverrides, biasMultiplier]);

    useEffect(() => {
        const newCellWidth = cellElementReference.current?.clientWidth ?? minimumCellWidth;
        setCellWidth(newCellWidth);
    }, [windowDimensions, runTestFarmerMarginCalculatorResponse]);

    const getColumnBreakText = (weightedPriceLineNumber: number) => {
        if (weightedPriceLineNumber === runTestFarmerMarginCalculatorResponse?.weightedBreakEvenPrice) {
            return translations.risk.margin.text.breakEvenShortText;
        }

        return translations.risk.margin.text.marketPriceShortText;
    };

    const getTableFormattedNumber = (unformattedNumber: number) =>
        formattingService.toNumberStringWithTrailingZerosOrDash(
            unformattedNumber,
            unformattedNumber < 100 ? defaultDisplayDecimalPlaces : defaultDisplayDecimalPlacesForNumbersOverOneHundred,
        );

    const loading = !runTestFarmerMarginCalculatorResponse?.coverageTableLines || runTestFarmerMarginCalculatorResponse?.coverageTableLines.length === 0;

    return (
        <MarginHeatMapTableWrapper
            marginInputs={props.marginInputs}
            marginTableDateRange={marginTableDateRange!}
            setMarginTableDateRange={setMarginTableDateRange}
            isLoading={loading}
        >
            <>
                <div
                    style={{ marginLeft: cellWidth * numberOfCellsBeforeFirstWeightedPriceLine, right: cellWidth }}
                    className={styles.margin_table_first_weighted_price_line}
                >
                    <p style={weightedPriceValueTextStyle}>
                        {`${getColumnBreakText(firstWeightedPriceLine)} ${getTableFormattedNumber(firstWeightedPriceLine)}`}
                    </p>
                </div>
                <div style={{ right: cellWidth * numberOfCellsAfterSecondWeightedPriceLine }} className={styles.margin_table_second_weighted_price_line}>
                    <p style={weightedPriceValueTextStyle}>
                        {`${getColumnBreakText(secondWeightedPriceLine)} ${getTableFormattedNumber(secondWeightedPriceLine)}`}
                    </p>
                </div>

                <MarginHeatMapTableHeaderRow
                    currentRow={runTestFarmerMarginCalculatorResponse?.coverageTableLines![0] as CoverageTableLineModel}
                    firstWeightedPriceLine={firstWeightedPriceLine}
                    secondWeightedPriceLine={secondWeightedPriceLine}
                    getTableFormattedNumber={getTableFormattedNumber}
                    cellElementReference={cellElementReference}
                />

                {runTestFarmerMarginCalculatorResponse?.coverageTableLines?.map((x) => (
                    <MarginHeatMapTableRow
                        key={`MarginTableRow_${x.production}`}
                        currentRow={x}
                        firstWeightedPriceLine={firstWeightedPriceLine}
                        secondWeightedPriceLine={secondWeightedPriceLine}
                        getTableFormattedNumber={getTableFormattedNumber}
                    />
                ))}
            </>
        </MarginHeatMapTableWrapper>
    );
};

export default MarginHeatMapTable;
