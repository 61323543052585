import { useNavigate } from 'react-router-dom';
import { PresentationTemplateModel } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import ActionsCellButton from '../../../Components/Tables/ActionsCellButton/ActionsCellButton';
import useLanguage from '../../../Services/Language/useLanguageHook';

interface IEditPresentationTemplateProps {
    hide?: boolean;
    presentationTemplate?: PresentationTemplateModel | undefined;
}

const EditPresentationTemplate: React.FC<IEditPresentationTemplateProps> = (props: IEditPresentationTemplateProps) => {
    const [translations] = useLanguage();
    const navigate = useNavigate();

    const navigateToEdit = () => {
        if (!props.presentationTemplate) {
            return;
        }
        const editPath = NavigationRoutes.PresentationTemplatesEdit.replace(':presentationTemplateGuid', props.presentationTemplate.presentationTemplateGuid);
        navigate(editPath);
    };

    if (props.hide) {
        return null;
    }

    return <ActionsCellButton text={translations.presentationTemplates.actions.edit} handleClick={navigateToEdit} />;
};

export default EditPresentationTemplate;
