import NumberInput from '../../../../../../Components/Form/Inputs/NumberInput';
import RegexValidators from '../../../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IDataSelectorTransformationValueTextInputProps {
    transformationValue: number;
    handleChange: (transformationValue: number) => void;
}

const DataSelectorTransformationValueTextInput: React.FC<IDataSelectorTransformationValueTextInputProps> = (
    props: IDataSelectorTransformationValueTextInputProps,
) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.indicator_add_and_edit_input_wrapper}>
            <NumberInput
                title={translations.marketIndicatorsManagement.fields.transformationValue}
                required
                value={props.transformationValue}
                handleTextChange={props.handleChange}
                validation={RegexValidators.PositiveOrNegativeNumber}
                errorMessage={translations.marketIndicatorsManagement.messages.positiveOrNegativeNumber}
            />
        </div>
    );
};

export default DataSelectorTransformationValueTextInput;
