import { DashStyleValue, Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import scssVariables from '../../../Config.module.scss';
import { Currency, UnitOfMeasure } from '../../../Generated/Raven-Demeter';

const defaultNavigatorMargin = 10;

export const defalutLineWidth = 2;
export const featuredLineWidth = 3;

// Highcharts keys and defaults. These are keys to use on the highcharts object to avoid misspelling and improper casing.
export const defaultZoneAxis = 'x';
export const defaultForecastLineWidth = 2;
export const columnChartType = 'column';
export const lineChartType = 'line';
export const areaRangeChartType = 'arearange';
export const areaChartType = 'area';
export const solidStyle = 'Solid';
export const shortDashStyle = 'ShortDash';
export const longDashStyle = 'LongDash';
export const dotDashStyle = 'Dot';

export interface IChartData {
    value: number;
    asOfDate: Date;
    isActualValue: boolean;
}

export interface IChartDataSeries {
    allForecastDataIsForwardCurve?: boolean;
    label: string;
    data: IChartData[];
    forwardCurveData?: IChartData[];
    yAxis?: number;
    axisLabel?: string;
    forecastLabel?: string;
    isPrimaryLine?: boolean;
}

export type BarChartFormatOptions = 'percent' | 'currency' | 'value';

export interface IChartBarDataSeries extends IChartDataSeries {
    format?: BarChartFormatOptions;
    displayDecimalPlacesMinimum?: number;
    displayDecimalPlacesMaximum?: number;
    isHidden?: boolean;
}

export interface IChartAreaRangeData extends IChartData {
    minimumValue: number;
    maximumValue: number;
}

export interface IChartAreaRangeDataSeries {
    label: string;
    data: IChartAreaRangeData[];
    displayDecimalPlacesMinimum?: number;
    displayDecimalPlacesMaximum?: number;
    decileRank?: number;
}

export type LineDataType = 'average' | 'forecast' | 'range' | 'value';

export interface IChartSeasonalDataSeries extends IChartDataSeries {
    lineDataType: LineDataType;
    yearsOrNumberOfYears: number;
    data: IChartData[] | IChartAreaRangeData[];
}

export interface IChartPriceDataSeries extends IChartDataSeries {
    forwardCurveLabel?: string;
    forwardCurveData?: IChartData[];
    // This is for the case where each individual data series has its own unit of measure.
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
    forwardCurveLineStyle?: DashStyleValue;
}

export interface IDownloadData {
    label?: string;
    data: {
        value: string;
        date: number | string;
        rawDate: number | string | Date;
        isActualValue: boolean;
    }[];
}

export interface OptionsWithDownloads extends Options {
    downloadData?: IDownloadData;
}

export interface IChartProps {
    chartReference?: (newChartReference: HighchartsReact.RefObject) => void;
    displayDecimalPlacesMinimum?: number;
    displayDecimalPlacesMaximum?: number;
    testId?: string;
}

export enum ChartDisplayType {
    Area = 'Area',
    Column = 'Column',
    Line = 'Line',
    Spline = 'Spline',
}

export type ChartContextSeries = { name: string; visible: boolean; hide: () => void; show: () => void };

export type ChartContext = {
    chart: { series: ChartContextSeries[] };
    name: string;
    x: number;
    y: number;
    index: number;
    point: {
        asOfDate?: Date;
        color: string;
        high?: number;
        isActualValue?: boolean;
        low?: number;
        lineDataType?: LineDataType;
        name: string;
        options: { x: number; y: number; isActualValue: boolean };
        percentage: number;
        zone: { dashStyle: string; color: string; value: number };
    };
    series: { name: string; index: number; initialType: string; tooltipOptions: { customTooltipPerSeries: () => void } };
};

export type HighchartsPlot = {
    x: number;
    y?: number | null | undefined;
    low?: number | null | undefined;
    high?: number | null | undefined;
    lineDataType?: LineDataType;
};

export const defaultChartAxisTitleStyle = {
    color: scssVariables.chartAxisLabel,
    fontSize: '12px',
};

export const defaultChartAxisTickLabelStyle = {
    color: scssVariables.chartAxisLabel,
    fontSize: '13px',
};

export const defaultChartOptions: Options = {
    title: {
        text: '',
        style: {
            fontSize: '32px',
            color: 'white',
        },
    },
    chart: {
        style: {
            fontFamily: 'Mulish, Helvetica, Arial, sans-serif', // TODO: Get variables for this.
        },
    },
    legend: {
        symbolRadius: 0,
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            day: '%e %b',
            month: '%b %y',
            year: '%Y',
        },
        labels: {
            style: defaultChartAxisTickLabelStyle,
        },
    },
    yAxis: [],
    series: [],
    plotOptions: {
        series: {
            states: {
                inactive: {
                    opacity: 0.45,
                },
            },
        },
    },
    navigator: {
        margin: defaultNavigatorMargin,
        maskFill: scssVariables.ruleSetTwoAreaColor1,
    },
    exporting: {
        buttons: {
            contextButton: {
                enabled: false,
            },
        },
    },
    credits: { enabled: false },
    lang: {
        numericSymbols: undefined,
    },
};

export interface ChartOptionsDefinitions {
    dashStyle?: string;
    data: HighchartsPlot[] | number[] | number[][];
    showInLegend?: boolean;
    type: string;
    visible?: boolean;
    yAxis?: number;
    zones?: { value: number }[];
}

export const chartColors = {
    areaChartColorsRuleSetTwo: [scssVariables.ruleSetTwoAreaColor1, scssVariables.ruleSetTwoAreaColor2],
    areaChartColorsRuleSetTwoExport: [scssVariables.ruleSetTwoAreaColor1Export, scssVariables.ruleSetTwoAreaColor2Export],
    barChartColorsRuleSetOne: [scssVariables.ruleSetOneBarColor1, scssVariables.ruleSetOneBarColor2, scssVariables.ruleSetOneBarColor3],
    lineChartColorsRuleSetOne: [
        scssVariables.ruleSetOneLineColor1,
        scssVariables.ruleSetOneLineColor2,
        scssVariables.ruleSetOneLineColor3,
        scssVariables.ruleSetOneLineColor4,
        scssVariables.ruleSetOneLineColor5,
        scssVariables.ruleSetOneLineColor6,
        scssVariables.ruleSetOneLineColor7,
        scssVariables.ruleSetOneLineColor8,
        scssVariables.ruleSetOneLineColor9,
        scssVariables.ruleSetOneLineColor10,
        scssVariables.ruleSetOneLineColor11,
        scssVariables.ruleSetOneLineColor12,
        scssVariables.ruleSetOneLineColor13,
        scssVariables.ruleSetOneLineColor14,
        scssVariables.ruleSetOneLineColor15,
    ],
    lineChartColorsRuleSetTwo: [
        scssVariables.ruleSetTwoLineColor1,
        scssVariables.ruleSetTwoLineColor2,
        scssVariables.ruleSetTwoLineColor3,
        scssVariables.ruleSetTwoLineColor4,
        scssVariables.ruleSetTwoLineColor5,
        scssVariables.ruleSetTwoLineColor6,
        scssVariables.ruleSetTwoLineColor7,
        scssVariables.ruleSetTwoLineColor8,
        scssVariables.ruleSetTwoLineColor9,
        scssVariables.ruleSetTwoLineColor10,
    ],
    valueMatrixLineChartColors: [
        scssVariables.valueMatrixChartColor1,
        scssVariables.valueMatrixChartColor2,
        scssVariables.valueMatrixChartColor3,
        scssVariables.valueMatrixChartColor4,
    ],
    donutChartColorsRuleSetThree: [
        scssVariables.plainWhite,
        scssVariables.ruleSetThreePieChartColor1,
        scssVariables.ruleSetThreePieChartColor2,
        scssVariables.ruleSetThreePieChartColor3,
        scssVariables.ruleSetThreePieChartColor4,
        scssVariables.ruleSetThreePieChartColor5,
        scssVariables.ruleSetThreePieChartColor6,
        scssVariables.ruleSetThreePieChartColor7,
        scssVariables.ruleSetThreePieChartColor8,
        scssVariables.ruleSetThreePieChartColor9,
        scssVariables.ruleSetThreePieChartColor10,
        scssVariables.ruleSetThreePieChartColor11,
    ],
    priceDecileColorsRuleSetTwo: [
        { decileRank: 10, color: scssVariables.ruleSetTwoPriceDecileNinetyToOneHundred },
        { decileRank: 9, color: scssVariables.ruleSetTwoPriceDecileEightyToNinety },
        { decileRank: 8, color: scssVariables.ruleSetTwoPriceDecileSeventyToEighty },
        { decileRank: 7, color: scssVariables.ruleSetTwoPriceDecileSixtyToSeventy },
        { decileRank: 6, color: scssVariables.ruleSetTwoPriceDecileFiftyToSixty },
        { decileRank: 5, color: scssVariables.ruleSetTwoPriceDecileFourtyToFifty },
        { decileRank: 4, color: scssVariables.ruleSetTwoPriceDecileThirtyToFourty },
        { decileRank: 3, color: scssVariables.ruleSetTwoPriceDecileTwentyToThirty },
        { decileRank: 2, color: scssVariables.ruleSetTwoPriceDecileTenToTwenty },
        { decileRank: 1, color: scssVariables.ruleSetTwoPriceDecileZeroToTen },
    ],
    dataDecileAlphaColorsRuleSetTwo: [
        { decileRank: 10, color: scssVariables.ruleSetTwoDataDecileColorAlpha1 },
        { decileRank: 9, color: scssVariables.ruleSetTwoDataDecileColorAlpha2 },
        { decileRank: 8, color: scssVariables.ruleSetTwoDataDecileColorAlpha3 },
        { decileRank: 7, color: scssVariables.ruleSetTwoDataDecileColorAlpha4 },
        { decileRank: 6, color: scssVariables.ruleSetTwoDataDecileColorAlpha5 },
    ],
    priceDecileAlphaColorsRuleSetTwo: [
        { decileRank: 10, color: scssVariables.ruleSetTwoPriceDecileAlphaNinetyToOneHundred },
        { decileRank: 9, color: scssVariables.ruleSetTwoPriceDecileAlphaEightyToNinety },
        { decileRank: 8, color: scssVariables.ruleSetTwoPriceDecileAlphaSeventyToEighty },
        { decileRank: 7, color: scssVariables.ruleSetTwoPriceDecileAlphaSixtyToSeventy },
        { decileRank: 6, color: scssVariables.ruleSetTwoPriceDecileAlphaFiftyToSixty },
        { decileRank: 5, color: scssVariables.ruleSetTwoPriceDecileAlphaFourtyToFifty },
        { decileRank: 4, color: scssVariables.ruleSetTwoPriceDecileAlphaThirtyToFourty },
        { decileRank: 3, color: scssVariables.ruleSetTwoPriceDecileAlphaTwentyToThirty },
        { decileRank: 2, color: scssVariables.ruleSetTwoPriceDecileAlphaTenToTwenty },
        { decileRank: 1, color: scssVariables.ruleSetTwoPriceDecileAlphaZeroToTen },
    ],
};
