import { Link } from 'react-router-dom';
import { DemeterUserModel, DemeterUserStatus, DemeterUserType } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserType } from '../../../../Redux/Slices/UserSlice';
import useLanguage from '../../../Services/Language/useLanguageHook';
import ApproveUser from './UserActions/ApproveUser';
import DeactivateUser from './UserActions/DeactivateUser';
import ReactivateUser from './UserActions/ReactivateUser';
import RejectUser from './UserActions/RejectUser';
import styles from './UsersPage.module.scss';

interface IUserActionsProps {
    user: DemeterUserModel;
    handleChange: () => any;
}

const UserActions: React.FC<IUserActionsProps> = (props: IUserActionsProps) => {
    const currentUserType = useApplicationSelector(selectUserType);
    const [translations] = useLanguage();

    const canApproveUsers = currentUserType === DemeterUserType.BusinessOwner || currentUserType === DemeterUserType.Administrator;
    const isPendingApproval = props.user.userStatus === DemeterUserStatus.PendingApproval;
    const isRejected = props.user.userStatus === DemeterUserStatus.Rejected;
    const isDeactivated = props.user.userStatus === DemeterUserStatus.Deactivated;

    const showApprove = canApproveUsers && isPendingApproval;
    const showReject = canApproveUsers && isPendingApproval;
    const showDeactivate = !isDeactivated && !isPendingApproval && !isRejected;
    const showActivate = isDeactivated && !isPendingApproval && !isRejected;

    return (
        <div className={styles.users_actions_container}>
            <Link className={styles.users_actions_link_button} to={`${NavigationRoutes.Users}/${props.user.demeterUserTrialGuid}/edit`}>
                {translations.actions.edit}
            </Link>
            <ApproveUser show={showApprove} user={props.user} handleConfirmed={props.handleChange} />
            <RejectUser show={showReject} user={props.user} handleConfirmed={props.handleChange} />
            <DeactivateUser show={showDeactivate} user={props.user} handleConfirmed={props.handleChange} />
            <ReactivateUser show={showActivate} user={props.user} handleConfirmed={props.handleChange} />
        </div>
    );
};

export default UserActions;
