import React, { useEffect, useMemo, useState } from 'react';
import { Currency, MarketIndicatorFactorDataModel, UnitOfMeasure } from '../../../../../Generated/Raven-Demeter';
import { ForecastParameters } from '../../../../Pages/Administration/MarketIndicatorsManagement/MarketIndicatorsManagementDefinitions';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { IChartData, IChartDataSeries } from '../../ChartDefinitions';
import ChartWrapper from '../../ChartWrapper/ChartWrapper';
import { defaultChartPalette, IMarketIndicatorUserChartBaseProps } from '../MarketIndicatorUserChartDefinitions';
import MarketIndicatorUserChartRaw from '../MarketIndicatorUserChartRaw';

export interface IMarketIndicatorForecastChartProps extends IMarketIndicatorUserChartBaseProps {
    parameters?: ForecastParameters;
}

const MarketIndicatorForecastChart: React.FC<IMarketIndicatorForecastChartProps> = (props: IMarketIndicatorForecastChartProps) => {
    // Application hooks.
    const [translations] = useLanguage();

    // Chart hooks.
    const [lineSeries, setLineSeries] = useState<IChartDataSeries[]>([]);

    const getLineSeries = (rows: MarketIndicatorFactorDataModel[]) => {
        const currentLineValues: IChartData[] = [];

        rows.forEach((row) => {
            if (!row.value) {
                return;
            }

            currentLineValues.push({
                value: row.value,
                asOfDate: new Date(row.asOfDate),
                isActualValue: row.isActualValue,
            });
        });

        return currentLineValues;
    };

    // Constants.
    const currentForwardCurveData = props.runTestMarketIndicatorFactorResponse?.additionalData?.find((x) => x.name === 'CurrentForwardCurve');
    const currentForecastData = props.runTestMarketIndicatorFactorResponse?.additionalData?.find((x) => x.name === 'CurrentForecast');

    useEffect(() => {
        if (!currentForwardCurveData || !currentForecastData) {
            return;
        }
        const currentForwardCurveLineSeries = getLineSeries(currentForwardCurveData.rows!);
        const currentForecastLineSeries = getLineSeries(currentForecastData.rows!);

        const newLineSeries: IChartDataSeries[] = [
            {
                label: translations.marketIndicatorsManagement.text.currentForecast,
                data: currentForecastLineSeries,
            },
            {
                label: translations.marketIndicatorsManagement.text.currentForwardCurve,
                data: currentForwardCurveLineSeries,
            },
        ];

        setLineSeries(newLineSeries);
    }, [props.runTestMarketIndicatorFactorResponse, props.parameters]);

    const currencies = useMemo(() => {
        if (!currentForecastData?.currency && !currentForwardCurveData?.currency) {
            return ['' as Currency];
        }

        return [currentForecastData?.currency ?? currentForwardCurveData?.currency!];
    }, [props.runTestMarketIndicatorFactorResponse, lineSeries]);

    const unitOfMeasures = useMemo(() => {
        if (!currentForecastData?.unitOfMeasure && !currentForwardCurveData?.unitOfMeasure) {
            return ['' as UnitOfMeasure];
        }

        return [currentForecastData?.unitOfMeasure ?? currentForwardCurveData?.unitOfMeasure!];
    }, [props.runTestMarketIndicatorFactorResponse, lineSeries]);

    const sourceTag = useMemo(() => {
        const runTestResponse = props.runTestMarketIndicatorFactorResponse;
        if (!runTestResponse || !runTestResponse.dataSourceTags || runTestResponse.dataSourceTags.length === 0) {
            return '';
        }

        return `${runTestResponse.dataSourceTags[0]}, ${translations.dataSource.StoneXCalculations}`;
    }, [props.runTestMarketIndicatorFactorResponse]);

    const isLoading = useMemo(
        () => props.isLoading || !props.runTestMarketIndicatorFactorResponse,
        [props.runTestMarketIndicatorFactorResponse, props.isLoading],
    );

    return (
        <ChartWrapper
            name="MarketIndicatorForecastChart"
            title={props.title}
            dataSourceTag={sourceTag}
            isLoading={isLoading}
            headerOptions={{
                showOnlyAsPopout: props.showOnlyAsPopout,
            }}
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
        >
            <MarketIndicatorUserChartRaw lineSeries={lineSeries} currencies={currencies} unitOfMeasures={unitOfMeasures} colorPalette={defaultChartPalette} />
        </ChartWrapper>
    );
};

export default MarketIndicatorForecastChart;
