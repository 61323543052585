import SearchIcon from '@mui/icons-material/Search';
import { GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import { demeterPositionsApi } from '../../../../Apis/Apis';
import AgGridBuilder from '../../../../Components/AgGridBuilder/AgGridBuilder';
import downloadSvg from '../../../../Components/Assets/download.svg';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import { DemeterInsurancePositionModel, ListDemeterInsurancePositionsResponse, SortDirection } from '../../../../Generated/Raven-Demeter';
import { SearchParameters } from '../../../../Redux/Slices/SystemSlice';
import useApi from '../../../Apis/Hooks/useApiHook';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import useNotificationHook from '../../../Components/Notifications/useNotificationHook';
import { ApiListResponse } from '../../../Components/Tables/TableWrapper/ApiListResponse';
import TableWrapper from '../../../Components/Tables/TableWrapper/TableWrapper';
import browserService from '../../../Services/BrowserService';
import languageService from '../../../Services/Language/LanguageService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './PositionsPage.module.scss';
import { positionsColumnDefinitions, positionsColumnOptions } from './PositionsPageDefinitions';

const sortColumns = ['companyName', 'policyNumber', 'state', 'reutersInstrumentCodePrefix'];

type SortColumn = (typeof sortColumns)[number];

interface ListInsurancePositionsRequest {
    searchTerm?: string;
    sortColumn?: SortColumn;
    sortDirection?: SortDirection;
    take: number;
    skip: number;
}

const PositionsPage: React.FC = () => {
    const [translations, translate] = useLanguage();
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const [displayError, displaySuccess] = useNotificationHook();
    const [onGridReady, setOnGridReady] = useState<GridReadyEvent>();
    const gridReference = useRef<AgGridReact>(null);
    const symbols = useSymbolsApi();
    const [searchTermInput, setSearchTermInput] = useState<string>('');
    const [listInsurancePositionsRequest, setListInsurancePositionsRequest] = useState<ListInsurancePositionsRequest>(() => ({
        searchTerm: searchParameters.searchTerm ?? '',
        sortColumn: searchParameters.sortColumn ?? 'companyName',
        sortDirection: (searchParameters.sortDirection as SortDirection) ?? SortDirection.Asc,
        take: applicationConstants.ItemsPerPage,
        skip: (searchParameters.skip as unknown as number) ?? 0,
    }));
    const [listInsurancePositionsResponse, setListInsurancePositionsResponse] = useState<ListDemeterInsurancePositionsResponse>();

    const [, refreshApi, apiResponse] = useApi(() => {
        if (!listInsurancePositionsRequest) {
            return null;
        }

        return demeterPositionsApi.listInsurancePositions(
            listInsurancePositionsRequest.searchTerm,
            listInsurancePositionsRequest.sortColumn,
            listInsurancePositionsRequest.sortDirection,
            listInsurancePositionsRequest.take,
            listInsurancePositionsRequest.skip,
        );
    });

    useEffect(() => {
        if (!apiResponse?.rows || !symbols) {
            return;
        }

        apiResponse.rows = apiResponse.rows.map((row: DemeterInsurancePositionModel, index) => ({
            ...row,
            id: index,
        }));

        setListInsurancePositionsResponse(apiResponse);
    }, [apiResponse, symbols]);

    useEffect(() => {
        if (!symbols || !positionsColumnDefinitions.length) {
            return;
        }

        const commodityColumn = positionsColumnDefinitions.find((x) => x.colId === 'commodity');
        if (commodityColumn) {
            commodityColumn.valueFormatter = (parameters: ValueFormatterParams) => {
                const symbolMatch = symbols.find((x) => x.commodity === parameters.value);
                return symbolMatch ? languageService.toDisplayName(symbolMatch) : translate(parameters.value);
            };
        }
    }, [symbols, positionsColumnDefinitions]);

    useEffect(() => {
        if (!gridReference?.current || !onGridReady) {
            return;
        }

        gridReference.current.api.addEventListener('sortChanged', handleSort);
    }, [gridReference?.current, onGridReady]);

    useEffect(() => {
        setSearchTermInput(searchParameters?.searchTerm);
        setListInsurancePositionsRequest({ ...listInsurancePositionsRequest, searchTerm: searchParameters?.searchTerm, skip: 0 });
        refreshApi();
    }, [searchParameters]);

    const handleSort = () => {
        const columnState = gridReference?.current?.columnApi.getColumnState();
        const columnsWithActiveSort = columnState?.filter((x) => x.sort !== null && x.sortIndex !== null);
        if (!columnsWithActiveSort?.length) {
            return;
        }

        const newRequest = { ...listInsurancePositionsRequest };
        if (columnsWithActiveSort[0].colId === 'coveragePeriod') {
            newRequest.sortColumn = 'coverageStartDate';
        } else if (columnsWithActiveSort[0].colId === 'commodity') {
            newRequest.sortColumn = 'reutersInstrumentCodePrefix';
        } else {
            newRequest.sortColumn = columnsWithActiveSort[0].colId;
        }

        newRequest.sortDirection = columnsWithActiveSort[0].sort === SortDirection.Asc.toLowerCase() ? SortDirection.Asc : SortDirection.Desc;
        setListInsurancePositionsRequest(newRequest);
        refreshApi();
    };

    const handleTakeCountUpdate = (apiListResponse: ApiListResponse) => {
        const newRequest = { ...listInsurancePositionsRequest };
        newRequest.take = apiListResponse.take ?? applicationConstants.ItemsPerPage;
        setListInsurancePositionsRequest(newRequest);
        refreshApi();
    };

    const handlePagination = (apiListResponse: ApiListResponse) => {
        const newRequest = { ...listInsurancePositionsRequest };
        newRequest.skip = apiListResponse.skip ?? 0;
        setListInsurancePositionsRequest(newRequest);
        refreshApi();
    };

    const handleSearch = (event: KeyboardEvent) => {
        const { value } = event.target as HTMLInputElement;
        if (event.key === 'Enter') {
            const newSearchParameters: SearchParameters = {
                searchTerm: value as string,
                sortColumn: listInsurancePositionsRequest.sortColumn as string,
                sortDirection: listInsurancePositionsRequest.sortDirection as string,
                skip: listInsurancePositionsRequest.skip as unknown as string,
                tab: 'Positions',
            };

            setSearchParameters(newSearchParameters);
            setListInsurancePositionsRequest({ ...listInsurancePositionsRequest, searchTerm: value, skip: 0 });
        }
    };

    const handleExportInsurancePositions = async () => {
        try {
            const listInsurancePositionsDownload = await demeterPositionsApi.listInsurancePositions(
                listInsurancePositionsRequest.searchTerm,
                listInsurancePositionsRequest.sortColumn,
                listInsurancePositionsRequest.sortDirection,
                -1,
                0,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'text/csv',
                        accept: 'text/csv',
                    },
                },
            );
            browserService.downloadCsv(listInsurancePositionsDownload.data as string, 'Positions');
            displaySuccess(translations.positions.text.successExport);
        } catch {
            displayError(translations.positions.errors.failureExport);
        }
    };

    return !listInsurancePositionsResponse ? (
        <PageLoadingSpinner />
    ) : (
        <div className={styles.positions_container}>
            <div className={styles.positions_actions_row}>
                <div className={styles.positions_search_container}>
                    <SearchIcon className={styles.positions_search_area_icon} />
                    <input
                        onKeyDown={handleSearch as any}
                        className={styles.positions_actions_search_input}
                        placeholder={translations.actions.search}
                        value={searchTermInput}
                        onChange={(event) => setSearchTermInput((event.target as HTMLInputElement).value)}
                    />
                </div>
                <LinkButton
                    title={translations.users.actions.export}
                    type={LinkButtonType.White}
                    icon={<img style={{ marginRight: '8px' }} src={downloadSvg} alt="download" />}
                    onClick={handleExportInsurancePositions}
                />
            </div>
            <div className={styles.positions_table_container}>
                <TableWrapper
                    apiListResponse={listInsurancePositionsResponse}
                    showPagination
                    handlePagination={handlePagination}
                    handleTakeCountUpdate={handleTakeCountUpdate}
                >
                    <AgGridBuilder
                        gridRef={gridReference}
                        rowData={listInsurancePositionsResponse.rows!}
                        onGridReady={setOnGridReady}
                        columnDefinitions={positionsColumnDefinitions}
                        defaultColumnDefinition={positionsColumnOptions}
                        gridHeightFull
                        domLayout="autoHeight"
                        testId="PositionsTable"
                    />
                </TableWrapper>
            </div>
        </div>
    );
};

export default PositionsPage;
