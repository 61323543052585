import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterCompanyApi } from '../../../../Apis/Apis';
import applicationSettings from '../../../../Core/Settings/ApplicationSettings';
import {
    AddDemeterCompanyRequest,
    Currency,
    DemeterMarket,
    DemeterMarketLicenseModel,
    DemeterPaymentType,
    DemeterPermissionType,
    DemeterRegion,
    DemeterSubscriptionFrequency,
    UpdateDemeterCompanyRequest,
} from '../../../../Generated/Raven-Demeter';
import useApi from '../../../Apis/Hooks/useApiHook';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import CheckboxInput from '../../../Components/Form/Inputs/CheckboxInput';
import DatePickerInput from '../../../Components/Form/Inputs/DatePickerInput';
import SelectInput from '../../../Components/Form/Inputs/SelectInput';
import TextInput from '../../../Components/Form/Inputs/TextInput';
import PageHeaderWithBackNavigation from '../../../Components/Headers/PageHeaderWithBackNavigation';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import InlineTab from '../../../Components/Navigation/InlineTabs/InlineTab';
import InlineTabs from '../../../Components/Navigation/InlineTabs/InlineTabs';
import useNotificationHook from '../../../Components/Notifications/useNotificationHook';
import RegexValidators from '../../../Core/Validation/RegexValidators';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import usePermission from '../../../Services/Permissions/usePermissionHook';
import Permissions from '../Permissions/Permissions';
import styles from './CompaniesPage.module.scss';

type UpsertDemeterCompanyRequest = {
    name?: string | null;
    invoiceDate?: string | Date | null;
    subscriptionPrice?: number | null;
    subscriptionFrequency?: DemeterSubscriptionFrequency | string | null;
    termsAndConditionsAccepted?: boolean | null;
    termsAndConditionsAcceptedAt?: string | Date | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    postalCode?: string | null;
    phone?: string | null;
    contactPerson?: string | null;
    billingContactName?: string | null;
    billingCompanyName?: string | null;
    billingAddress1?: string | null;
    billingAddress2?: string | null;
    billingCity?: string | null;
    billingState?: string | null;
    billingCountry?: string | null;
    billingPostalCode?: string | null;
    billingPhone?: string | null;
    billingFax?: string | null;
    billingEmail?: string | null;
    billingVatNumber?: string | null;
    billingSameAsCompanyInformation?: boolean | null;
    billingCurrency?: Currency | string | null;
    billingRegion?: DemeterRegion | string | null;
    numberOfLicenses?: number | null;
    markets?: DemeterMarket[] | null;
    marketLicenses?: DemeterMarketLicenseModel[] | null;
    paymentType?: DemeterPaymentType | null;
    futuresAccountTransferConsent?: boolean | null;
    futuresAccountNumber?: string | null;
    futuresAccountCustomerName?: string | null;
    futuresAccountTitle?: string | null;
    policyNumbers?: string | null;
};

const CompanyPage: React.FC = () => {
    const { demeterCompanyGuid } = useParams();
    const navigate = useNavigate();
    const [translations] = useLanguage();
    const [displayError, displaySuccess] = useNotificationHook();
    const [searchParameters, setSearchParameters] = useSearchParameters();

    // Company hooks.
    const [company, setCompany] = useState<UpsertDemeterCompanyRequest>();
    const [, , getCompanyResponse] = useApi(() => {
        if (demeterCompanyGuid) {
            return demeterCompanyApi.getDemeterCompany(demeterCompanyGuid);
        }

        return null;
    });

    // Option hooks.
    const subscriptionFrequencyOptions = useMemo(
        () => [
            {
                value: undefined,
                label: '',
            },
            {
                value: DemeterSubscriptionFrequency.Monthly,
                label: translations.subscriptionFrequency.Monthly,
            },
            {
                value: DemeterSubscriptionFrequency.Quarterly,
                label: translations.subscriptionFrequency.Quarterly,
            },
            {
                value: DemeterSubscriptionFrequency.Yearly,
                label: translations.subscriptionFrequency.Yearly,
            },
        ],
        [translations],
    );

    const currencyOptions = useMemo(
        () => [
            {
                value: undefined,
                label: '',
            },
            {
                value: Currency.Usd,
                label: translations.currency.USD,
            },
            {
                value: Currency.Eur,
                label: translations.currency.EUR,
            },
        ],
        [translations],
    );

    const regionOptions = useMemo(
        () => [
            {
                value: undefined,
                label: '',
            },
            {
                value: DemeterRegion.America,
                label: translations.region.America,
            },
            {
                value: DemeterRegion.EuropeMiddleEastAndAfrica,
                label: translations.region.EuropeMiddleEastAndAfrica,
            },
            {
                value: DemeterRegion.AsiaPacific,
                label: translations.region.AsiaPacific,
            },
        ],
        [translations],
    );

    const paymentTypeOptions = useMemo(
        () => [
            {
                value: undefined,
                label: '',
            },
            {
                value: DemeterPaymentType.WireTransfer,
                label: translations.paymentType.WireTransfer,
            },
            {
                value: DemeterPaymentType.DeductionFromFuturesAccount,
                label: translations.paymentType.DeductionFromFuturesAccount,
            },
        ],
        [translations],
    );

    // Actions hooks.
    const [isSaving, setIsSaving] = useState(false);
    const isValid = !!company?.name;

    // Load the Company if there is one.
    useEffect(() => {
        if (getCompanyResponse) {
            const responseCompany = getCompanyResponse.demeterCompany!;

            setCompany({
                ...responseCompany,
            });
        }
    }, [demeterCompanyGuid, getCompanyResponse]);

    const navigateBackToCompanies = () => {
        setSearchParameters({ tab: 'Companies' });
        navigate(-1);
    };

    const upsertCompany = async (): Promise<void> => {
        if (isValid) {
            try {
                if (company.invoiceDate) {
                    company.invoiceDate = formattingService.toApiDate(company.invoiceDate);
                } else {
                    company.invoiceDate = null;
                }

                if (demeterCompanyGuid) {
                    await demeterCompanyApi.updateDemeterCompany(demeterCompanyGuid, company as UpdateDemeterCompanyRequest);
                    displaySuccess(translations.companies.text.companySuccessfullyUpdated);
                } else {
                    await demeterCompanyApi.addDemeterCompany(company as AddDemeterCompanyRequest);
                    displaySuccess(translations.companies.text.companySuccessfullyAdded);
                }

                navigateBackToCompanies();
            } catch (error) {
                if (demeterCompanyGuid) {
                    displayError(translations.companies.errors.updateCompanyError);
                } else {
                    displayError(translations.companies.errors.addCompanyError);
                }
                setIsSaving(false);
            }
        } else {
            setIsSaving(false);
        }
    };

    const updateMarketLicense = (market: DemeterMarket, value: string) => {
        const availableMarkets = applicationSettings.markets || [];
        const updatedMarketLicenses = availableMarkets.map((availableMarket) => {
            const existingLicense = company?.marketLicenses?.find((license) => license.market === availableMarket);

            if (availableMarket === market) {
                return {
                    market: availableMarket,
                    numberOfLicenses: +value,
                    numberOfLicensesUsed: existingLicense?.numberOfLicensesUsed || 0,
                };
            }

            return (
                existingLicense || {
                    market: availableMarket,
                    numberOfLicenses: 0,
                    numberOfLicensesUsed: 0,
                }
            );
        });

        const totalLicenses = updatedMarketLicenses.reduce((sum, license) => sum + (license.numberOfLicenses || 0), 0);

        setCompany({
            ...company,
            marketLicenses: updatedMarketLicenses,
            numberOfLicenses: totalLicenses,
        });
    };

    const showPolicyNumbers = usePermission(DemeterPermissionType.FarmerMarginAdministrator);

    return (
        <div className={styles.add_edit_company_page_container}>
            <PageHeaderWithBackNavigation
                handleBackNavigation={navigateBackToCompanies}
                title={demeterCompanyGuid ? company?.name ?? '' : translations.companies.title.add}
            />
            <InlineTabs inlineTabsKey="CompanyTab">
                <InlineTab show title={translations.companies.tabs.details} inlineTabKey="CompanyDetails" testId="CompanyDetailsTab">
                    <div className={styles.add_edit_company_input}>
                        <TextInput
                            title={translations.companies.fields.company}
                            placeholder={translations.companies.fields.company}
                            required
                            value={company?.name}
                            handleTextChange={(value) => setCompany({ ...company, name: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.companies.errors.requiredField}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <TextInput
                            title={translations.companies.fields.address1}
                            placeholder={translations.companies.fields.address1}
                            required
                            value={company?.address1}
                            handleTextChange={(value) => setCompany({ ...company, address1: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.companies.errors.requiredField}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <TextInput
                            title={translations.companies.fields.address2}
                            placeholder={translations.companies.fields.address2}
                            required
                            value={company?.address2}
                            handleTextChange={(value) => setCompany({ ...company, address2: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.companies.errors.requiredField}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <TextInput
                            title={translations.companies.fields.city}
                            placeholder={translations.companies.fields.city}
                            required
                            value={company?.city}
                            handleTextChange={(value) => setCompany({ ...company, city: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.companies.errors.requiredField}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <TextInput
                            title={translations.companies.fields.state}
                            placeholder={translations.companies.fields.state}
                            required
                            value={company?.state}
                            handleTextChange={(value) => setCompany({ ...company, state: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.companies.errors.requiredField}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <TextInput
                            title={translations.companies.fields.country}
                            placeholder={translations.companies.fields.country}
                            required
                            value={company?.country}
                            handleTextChange={(value) => setCompany({ ...company, country: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.companies.errors.requiredField}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <TextInput
                            title={translations.companies.fields.postalCode}
                            placeholder={translations.companies.fields.postalCode}
                            required
                            value={company?.postalCode}
                            handleTextChange={(value) => setCompany({ ...company, postalCode: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.companies.errors.requiredField}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <TextInput
                            title={translations.companies.fields.phone}
                            placeholder={translations.companies.fields.phone}
                            required
                            value={company?.phone}
                            handleTextChange={(value) => setCompany({ ...company, phone: value })}
                            validation={RegexValidators.Phone}
                            errorMessage={translations.companies.errors.requiredField}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <TextInput
                            title={translations.companies.fields.contactPerson}
                            placeholder={translations.companies.fields.contactPerson}
                            required
                            value={company?.contactPerson}
                            handleTextChange={(value) => setCompany({ ...company, contactPerson: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.companies.errors.requiredField}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <CheckboxInput
                            title={translations.companies.fields.termsAndConditionsAccepted}
                            isChecked={company?.termsAndConditionsAccepted ?? false}
                            disabled
                        />
                        {company?.termsAndConditionsAccepted && company?.termsAndConditionsAcceptedAt && (
                            <div>
                                {translations.companies.fields.termsAndConditionsAcceptedAt}{' '}
                                {formattingService.toShortDayMonthYear(new Date(company?.termsAndConditionsAcceptedAt))}
                            </div>
                        )}
                    </div>

                    <div className={styles.add_edit_company_input}>
                        <DatePickerInput
                            name="CompanyInvoiceDate"
                            title={translations.companies.fields.invoiceDate}
                            required
                            value={company?.invoiceDate as Date}
                            handleDateChange={(value) => setCompany({ ...company, invoiceDate: value })}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <TextInput
                            title={translations.companies.fields.subscriptionPrice}
                            placeholder={translations.companies.fields.subscriptionPrice}
                            type="number"
                            value={company?.subscriptionPrice?.toString()}
                            handleTextChange={(value) => setCompany({ ...company, subscriptionPrice: +value })}
                            validation={RegexValidators.Numeric}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <SelectInput
                            title={translations.companies.fields.subscriptionFrequency}
                            value={company?.subscriptionFrequency}
                            options={subscriptionFrequencyOptions}
                            handleOptionChange={(value) => setCompany({ ...company, subscriptionFrequency: value })}
                        />
                    </div>
                    {showPolicyNumbers && (
                        <div className={styles.add_edit_company_input}>
                            <TextInput
                                title={translations.companies.fields.policyNumbers}
                                placeholder={translations.companies.fields.policyNumbers}
                                value={company?.policyNumbers}
                                handleTextChange={(value) => setCompany({ ...company, policyNumbers: value })}
                            />
                        </div>
                    )}
                </InlineTab>
                <InlineTab show title={translations.companies.tabs.billing} inlineTabKey="CompanyBilling" testId="CompanyBillingTab">
                    {company?.billingSameAsCompanyInformation !== true && (
                        <div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.billingContactName}
                                    placeholder={translations.companies.fields.billingContactName}
                                    required
                                    value={company?.billingContactName}
                                    handleTextChange={(value) => setCompany({ ...company, billingContactName: value })}
                                    validation={RegexValidators.AlphaNumericMinimumLength2}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.billingCompanyName}
                                    placeholder={translations.companies.fields.billingCompanyName}
                                    required
                                    value={company?.billingCompanyName}
                                    handleTextChange={(value) => setCompany({ ...company, billingCompanyName: value })}
                                    validation={RegexValidators.AlphaNumericMinimumLength2}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.billingAddress1}
                                    placeholder={translations.companies.fields.billingAddress1}
                                    required
                                    value={company?.billingAddress1}
                                    handleTextChange={(value) => setCompany({ ...company, billingAddress1: value })}
                                    validation={RegexValidators.AlphaNumericMinimumLength2}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.billingAddress2}
                                    placeholder={translations.companies.fields.billingAddress2}
                                    required
                                    value={company?.billingAddress2}
                                    handleTextChange={(value) => setCompany({ ...company, billingAddress2: value })}
                                    validation={RegexValidators.AlphaNumericMinimumLength2}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.billingCity}
                                    placeholder={translations.companies.fields.billingCity}
                                    required
                                    value={company?.billingCity}
                                    handleTextChange={(value) => setCompany({ ...company, billingCity: value })}
                                    validation={RegexValidators.AlphaNumericMinimumLength2}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.billingState}
                                    placeholder={translations.companies.fields.billingState}
                                    required
                                    value={company?.billingState}
                                    handleTextChange={(value) => setCompany({ ...company, billingState: value })}
                                    validation={RegexValidators.AlphaNumericMinimumLength2}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.billingCountry}
                                    placeholder={translations.companies.fields.billingCountry}
                                    required
                                    value={company?.billingCountry}
                                    handleTextChange={(value) => setCompany({ ...company, billingCountry: value })}
                                    validation={RegexValidators.AlphaNumericMinimumLength2}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.billingPostalCode}
                                    placeholder={translations.companies.fields.billingPostalCode}
                                    required
                                    value={company?.billingPostalCode}
                                    handleTextChange={(value) => setCompany({ ...company, billingPostalCode: value })}
                                    validation={RegexValidators.AlphaNumericMinimumLength2}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.billingPhone}
                                    placeholder={translations.companies.fields.billingPhone}
                                    required
                                    value={company?.billingPhone}
                                    handleTextChange={(value) => setCompany({ ...company, billingPhone: value })}
                                    validation={RegexValidators.Phone}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.billingFax}
                                    placeholder={translations.companies.fields.billingFax}
                                    value={company?.billingFax}
                                    handleTextChange={(value) => setCompany({ ...company, billingFax: value })}
                                />
                            </div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.billingEmail}
                                    placeholder={translations.companies.fields.billingEmail}
                                    required
                                    value={company?.billingEmail}
                                    handleTextChange={(value) => setCompany({ ...company, billingEmail: value })}
                                    validation={RegexValidators.Email}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>
                        </div>
                    )}

                    <div className={styles.add_edit_company_input}>
                        <TextInput
                            title={translations.companies.fields.billingVatNumber}
                            placeholder={translations.companies.fields.billingVatNumber}
                            value={company?.billingVatNumber}
                            handleTextChange={(value) => setCompany({ ...company, billingVatNumber: value })}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <CheckboxInput
                            title={translations.companies.fields.billingSameAsCompanyInformation}
                            isChecked={company?.billingSameAsCompanyInformation ?? false}
                            handleIsChecked={(value) => setCompany({ ...company, billingSameAsCompanyInformation: value })}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <SelectInput
                            title={translations.companies.fields.paymentType}
                            value={company?.paymentType}
                            options={paymentTypeOptions}
                            handleOptionChange={(value) => setCompany({ ...company, paymentType: value })}
                        />
                    </div>
                    {company?.paymentType === DemeterPaymentType.DeductionFromFuturesAccount && (
                        <>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.futuresAccountNumber}
                                    placeholder={translations.companies.fields.futuresAccountNumber}
                                    required
                                    value={company?.futuresAccountNumber}
                                    handleTextChange={(value) => setCompany({ ...company, futuresAccountNumber: value })}
                                    validation={RegexValidators.AlphaNumericMinimumLength2}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.futuresAccountCustomerName}
                                    placeholder={translations.companies.fields.futuresAccountCustomerName}
                                    required
                                    value={company?.futuresAccountCustomerName}
                                    handleTextChange={(value) => setCompany({ ...company, futuresAccountCustomerName: value })}
                                    validation={RegexValidators.AlphaNumericMinimumLength2}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>
                            <div className={styles.add_edit_company_input}>
                                <TextInput
                                    title={translations.companies.fields.futuresAccountTitle}
                                    placeholder={translations.companies.fields.futuresAccountTitle}
                                    required
                                    value={company?.futuresAccountTitle}
                                    handleTextChange={(value) => setCompany({ ...company, futuresAccountTitle: value })}
                                    validation={RegexValidators.AlphaNumericMinimumLength2}
                                    errorMessage={translations.companies.errors.requiredField}
                                />
                            </div>

                            <div className={styles.add_edit_company_input}>
                                <CheckboxInput
                                    title={translations.companies.fields.futuresAccountTransferConsent}
                                    isChecked={company?.futuresAccountTransferConsent ?? false}
                                    handleIsChecked={(value) => setCompany({ ...company, futuresAccountTransferConsent: value })}
                                />
                            </div>
                        </>
                    )}
                    <div className={styles.add_edit_company_input}>
                        <SelectInput
                            title={translations.companies.fields.billingCurrency}
                            value={company?.billingCurrency}
                            options={currencyOptions}
                            handleOptionChange={(value) => setCompany({ ...company, billingCurrency: value })}
                        />
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <SelectInput
                            title={translations.companies.fields.billingRegion}
                            value={company?.billingRegion}
                            options={regionOptions}
                            handleOptionChange={(value) => setCompany({ ...company, billingRegion: value })}
                        />
                    </div>
                </InlineTab>
                <InlineTab show title={translations.companies.tabs.licenses} inlineTabKey="CompanyLicenses" testId="CompanyLicensesTab">
                    <div className={styles.add_edit_company_license_container}>
                        {applicationSettings.markets?.map((market) => {
                            const marketLicense = company?.marketLicenses?.find((license) => license.market === market);
                            return (
                                <div key={market} className={styles.add_edit_company_input_sm}>
                                    <TextInput
                                        title={translations.market[market]}
                                        type="number"
                                        value={`${marketLicense?.numberOfLicenses ?? 0}`}
                                        handleTextChange={(value: string) => updateMarketLicense(market, value)}
                                        validation={RegexValidators.Numeric}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.add_edit_company_input}>
                        <p>
                            <strong>{translations.companies.fields.totalLicenses}</strong>
                        </p>
                        <p>{company?.numberOfLicenses ?? '--'}</p>
                    </div>
                </InlineTab>
                <InlineTab show title={translations.companies.tabs.permissions} inlineTabKey="CompanyPermissions" testId="CompanyPermissionsTab">
                    <div>{demeterCompanyGuid && <Permissions demeterCompanyGuid={demeterCompanyGuid} />}</div>
                </InlineTab>
            </InlineTabs>
            {searchParameters.CompanyTab !== 'CompanyPermissions' && (
                <div className={styles.add_edit_company_submit_action_row}>
                    <LinkButton title={translations.actions.cancel} onClick={navigateBackToCompanies} type={LinkButtonType.White} />

                    <LinkButton
                        title={demeterCompanyGuid ? translations.actions.save : translations.actions.add}
                        type={LinkButtonType.Blue}
                        onClick={() => {
                            // Set this here and let the async upsertCompany do its job.
                            setIsSaving(true);
                            upsertCompany();
                        }}
                        disabled={!isValid || isSaving}
                    />
                </div>
            )}
        </div>
    );
};

export default CompanyPage;
