import { PresentationTemplateModel } from '../../../Generated/Raven-Demeter';
import DeletePresentationTemplate from './PresentationTemplateActions/DeletePresentationTemplate';
import DownloadPresentationTemplate from './PresentationTemplateActions/DownloadPresentationTemplate';
import DuplicatePresentationTemplate from './PresentationTemplateActions/DuplicatePresentationTemplate';
import EditPresentationTemplate from './PresentationTemplateActions/EditPresentationTemplate';

interface IPresentationTemplateActionsProps {
    presentationTemplate?: PresentationTemplateModel | undefined;
    handleAction: () => void;
}

const PresentationTemplateActions: React.FC<IPresentationTemplateActionsProps> = (props: IPresentationTemplateActionsProps) => (
    <>
        <DownloadPresentationTemplate presentationTemplate={props.presentationTemplate} />
        <EditPresentationTemplate presentationTemplate={props.presentationTemplate} />
        <DuplicatePresentationTemplate presentationTemplate={props.presentationTemplate} handleConfirmed={props.handleAction} />
        <DeletePresentationTemplate presentationTemplate={props.presentationTemplate} handleConfirmed={props.handleAction} />
    </>
);
export default PresentationTemplateActions;
