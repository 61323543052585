import { useEffect, useState } from 'react';
import { DemeterPageElementModel } from '../../../Generated/Raven-Demeter';
import { ITreeNode } from '../../Components/TreeView/TreeItem';
import TreeView from '../../Components/TreeView/TreeView';

interface IPresentationTemplateContentSelectorProps {
    pageElements: DemeterPageElementModel[];
    templatePageElements: DemeterPageElementModel[] | undefined;
    onSelect: (selected: DemeterPageElementModel) => void;
}

const PresentationTemplateContentSelector: React.FC<IPresentationTemplateContentSelectorProps> = (props: IPresentationTemplateContentSelectorProps) => {
    const [treeNodes, serTreeNodes] = useState<ITreeNode[]>([]);

    const buildTree = (pageElements: DemeterPageElementModel[], templatePageElements: DemeterPageElementModel[] | undefined): ITreeNode[] => {
        const treeMap = new Map<string, ITreeNode>();
        const rootNodes: ITreeNode[] = [];

        const getNode = (id: string, name: string, parentId: string, order: number): ITreeNode => {
            const selected = templatePageElements?.some((x) => x.demeterPageElementGuid === id) || false;
            if (!treeMap.has(id)) {
                const node: ITreeNode = {
                    id,
                    name,
                    value: id,
                    parentId,
                    parentValue: parentId,
                    order,
                    displayOrder: order,
                    selectedProduct: selected,
                    expanded: false,
                    children: [],
                };
                treeMap.set(id, node);
            }
            return treeMap.get(id)!;
        };

        pageElements.forEach((x) => {
            if (!x.market) {
                return;
            }

            const marketId = x.market;
            const marketNode = getNode(marketId, x.market, '', 0);
            if (!rootNodes.includes(marketNode)) {
                rootNodes.push(marketNode);
            }

            let parentNode: ITreeNode = marketNode;

            if (x.pageType) {
                const pageTypeId = `${marketId}-${x.pageType}`;
                parentNode = getNode(pageTypeId, x.pageType, marketId, 1);
                if (!marketNode.children.includes(parentNode)) {
                    marketNode.children.push(parentNode);
                }
            }

            if (x.region) {
                const regionId = `${parentNode.id}-${x.region}`;
                parentNode = getNode(regionId, x.region, parentNode.id, 2);
                if (!treeMap.get(parentNode.parentId)?.children.includes(parentNode)) {
                    treeMap.get(parentNode.parentId)?.children.push(parentNode);
                }
            }

            if (x.commodity) {
                const commodityId = `${parentNode.id}-${x.commodity}`;
                parentNode = getNode(commodityId, x.commodity, parentNode.id, 3);
                if (!treeMap.get(parentNode.parentId)?.children.includes(parentNode)) {
                    treeMap.get(parentNode.parentId)?.children.push(parentNode);
                }
            }

            if (x.entityGuid) {
                const entityGuid = `${parentNode.id}-${x.entityGuid}`;
                parentNode = getNode(entityGuid, x.entityGuid, parentNode.id, 4);
                if (!treeMap.get(parentNode.parentId)?.children.includes(parentNode)) {
                    treeMap.get(parentNode.parentId)?.children.push(parentNode);
                }
            }

            if (x.secondaryEntityGuid) {
                const secondaryEntityGuid = `${parentNode.id}-${x.secondaryEntityGuid}`;
                parentNode = getNode(secondaryEntityGuid, x.secondaryEntityGuid, parentNode.id, 5);
                if (!treeMap.get(parentNode.parentId)?.children.includes(parentNode)) {
                    treeMap.get(parentNode.parentId)?.children.push(parentNode);
                }
            }

            const elementId = x.demeterPageElementGuid;
            const elementName = `${x.pageType ?? ''} ${x.pageElementType ?? ''}`.trim();
            const elementNode = getNode(elementId, elementName, parentNode.id, x.order ?? 0);
            if (!parentNode.children.includes(elementNode)) {
                parentNode.children.push(elementNode);
            }
        });

        return rootNodes;
    };

    useEffect(() => {
        if (!props.pageElements) {
            return;
        }
        const tree = buildTree(props.pageElements, props.templatePageElements);
        serTreeNodes(tree);
    }, [props.pageElements, props.templatePageElements]);

    const getTreeNodeById = (id: string, nodes: ITreeNode[] = treeNodes): ITreeNode | undefined =>
        nodes.reduce<ITreeNode | undefined>((found, node) => {
            if (found) {
                return found;
            }
            if (node.id === id) {
                return node;
            }
            if (node.children) {
                return getTreeNodeById(id, node.children);
            }
            return undefined;
        }, undefined);

    const handleSelect = (event: React.MouseEvent<HTMLElement>): void => {
        const selected = getTreeNodeById(event.currentTarget.id);
        if (selected) {
            const pageElement = props.pageElements.find((x) => x.demeterPageElementGuid === selected.id);
            props.onSelect(pageElement!);
        }
    };

    return <TreeView data={treeNodes} searchTerm="" handleSelect={handleSelect} />;
};
export default PresentationTemplateContentSelector;
