import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import ErrorBoundary from '../../../Core/ErrorHandling/ErrorBoundary';
import { EventActionsEnum, EventCategoriesEnum } from '../../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../../Services/Logging/LoggingService';
import useSearchParameters from '../Hooks/useSearchParametersHook';
import InlineTab from './InlineTab';
import styles from './InlineTabs.module.scss';
import { IInlineTabOptions } from './InlineTabsDefinitions';

interface IInlineTabsProps {
    inlineTabsKey: string;
    children?: ReactNode;
}

const InlineTabs: React.FC<IInlineTabsProps> = (props: IInlineTabsProps) => {
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const [activeTab, setActiveTab] = useState(searchParameters[props.inlineTabsKey] || '');

    const tabs = useMemo(
        () =>
            React.Children.toArray(props.children)
                .filter((child) => React.isValidElement(child) && child.type === InlineTab)
                .map((child) => {
                    const element = child as React.ReactElement<IInlineTabOptions>;
                    return {
                        inlineTabKey: element.props.inlineTabKey,
                        title: element.props.title,
                        show: element.props.show !== false,
                        testId: element.props.testId,
                        component: element.props.children,
                    };
                }),
        [props.children],
    );

    useEffect(() => {
        if (!(props.inlineTabsKey in searchParameters) || searchParameters[props.inlineTabsKey] === '') {
            const firstTabKey = tabs.find((tab) => tab.show)?.inlineTabKey || '';
            setActiveTab(firstTabKey);
        }
    }, [tabs, searchParameters]);

    useEffect(() => {
        if (activeTab) {
            loggingService.trackEventWithAnalytics(EventActionsEnum.Navigation, EventCategoriesEnum.TabChange, activeTab);
            setSearchParameters({ ...searchParameters, [props.inlineTabsKey]: activeTab });
        }
    }, [activeTab]);

    return (
        <>
            <div className={styles.inline_tab_main_container}>
                {tabs
                    .filter((tab) => tab.show)
                    .map((tab) => (
                        <button
                            type="button"
                            key={`inlinetab_${tab.inlineTabKey}`}
                            className={tab.inlineTabKey === activeTab ? styles.inline_tab_button_selected : styles.inline_tab_button}
                            onClick={() => setActiveTab(tab.inlineTabKey)}
                            data-testid={tab.testId}
                        >
                            {tab.title}
                        </button>
                    ))}
                <div className={styles.inline_tab_empty_underline_space} />
            </div>
            <ErrorBoundary>
                <div>{tabs.find((tab) => tab.show && tab.inlineTabKey === activeTab)?.component}</div>
            </ErrorBoundary>
        </>
    );
};

export default InlineTabs;
