import meanBy from 'lodash.meanby';
import React, { useEffect, useMemo, useState } from 'react';
import { Currency, MarketIndicatorFactorDataModel, UnitOfMeasure } from '../../../../../Generated/Raven-Demeter';
import {
    AverageSeasonalParameters,
    leadingIndicatorTypeDefinitions,
} from '../../../../Pages/Administration/MarketIndicatorsManagement/MarketIndicatorsManagementDefinitions';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { IChartData, IChartDataSeries } from '../../ChartDefinitions';
import ChartWrapper from '../../ChartWrapper/ChartWrapper';
import { defaultAverageSeasonalParameters } from '../MarketIndicatorChartDefaultParameters';
import marketIndicatorChartService from '../MarketIndicatorChartService';
import { defaultChartPalette, IMarketIndicatorUserChartBaseProps } from '../MarketIndicatorUserChartDefinitions';
import MarketIndicatorUserChartRaw from '../MarketIndicatorUserChartRaw';

export interface IMarketIndicatorAverageSeasonalChartProps extends IMarketIndicatorUserChartBaseProps {
    parameters?: AverageSeasonalParameters;
}

const separarteAxisThresholdPercent = 0.1;

const MarketIndicatorAverageSeasonalChart: React.FC<IMarketIndicatorAverageSeasonalChartProps> = (props: IMarketIndicatorAverageSeasonalChartProps) => {
    const [translations, translate] = useLanguage();
    const [lineSeries, setLineSeries] = useState<IChartDataSeries[]>([]);

    useEffect(() => {
        let finalAverageLineData: IChartData[] = [];
        const dataOrganizedByDate: IChartData[][] = [];

        // Date helpers.
        const today = new Date();
        const maximumDate = new Date();
        const minimumDate = new Date();

        const getLastDayOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

        const startMonthIsBeforeEndMonth = +props.parameters!.startMonth < +props.parameters!.endMonth;

        // Go back to the previous year if the start month is greater than the end month.
        if (startMonthIsBeforeEndMonth) {
            minimumDate.setMonth(+props.parameters!.startMonth - 1);
            maximumDate.setMonth(+props.parameters!.endMonth - 1);
            minimumDate.setDate(0);
            maximumDate.setDate(getLastDayOfMonth(maximumDate));
        } else {
            minimumDate.setFullYear(minimumDate.getFullYear() - 1);
            minimumDate.setMonth(+props.parameters!.endMonth - 1);
            maximumDate.setMonth(+props.parameters!.startMonth - 1);
        }

        const startDateIsBeforeToday = minimumDate.getTime() < today.getTime();

        const calculationData = marketIndicatorChartService.getSeasonalAverageCalculationData(
            chartData,
            minimumDate,
            +props.parameters!.startMonth,
            +props.parameters!.endMonth,
        );

        const seasonalAverageData = marketIndicatorChartService.getSortedSeasonalAverageData(
            calculationData,
            numberOfYears,
            minimumDate,
            maximumDate,
            +props.parameters!.endMonth,
        );

        // Organize the data into an array of arrays by using the asOfDates.
        seasonalAverageData
            .filter((x) => {
                const startingDate = new Date(x.asOfDate.getFullYear(), x.asOfDate.getMonth() + 1, 0);
                const endingDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

                return startingDate.getTime() > endingDate.getTime();
            })
            .forEach((x, index) => {
                if (x.asOfDate.getTime() === seasonalAverageData[index - 1]?.asOfDate?.getTime() && index !== 0) {
                    dataOrganizedByDate[dataOrganizedByDate.length - 1].push(x);
                    return;
                }

                dataOrganizedByDate.push([x]);
            });

        dataOrganizedByDate.forEach((x) => {
            const currentAverage = meanBy(x, 'value');
            finalAverageLineData.push({ ...x[0], value: currentAverage });
        });

        // Based on start and end month scenerios, update the minimum / maximum date and average data.
        if (startMonthIsBeforeEndMonth && !startDateIsBeforeToday) {
            minimumDate.setFullYear(minimumDate.getFullYear() - 1);
            maximumDate.setFullYear(maximumDate.getFullYear() - 1);

            finalAverageLineData.map((x) => ({ ...x, asOfDate: x.asOfDate.setFullYear(maximumDate.getFullYear()) }));
        } else if (!startMonthIsBeforeEndMonth && !startDateIsBeforeToday) {
            finalAverageLineData = [];

            dataOrganizedByDate.forEach((x) => {
                const currentAverage = meanBy(x, 'value');
                finalAverageLineData.push({ ...x[0], value: currentAverage });
            });
        } else if (!startMonthIsBeforeEndMonth && startDateIsBeforeToday) {
            finalAverageLineData = [];

            minimumDate.setMonth(+props.parameters!.startMonth - 1);
            maximumDate.setMonth(+props.parameters!.endMonth - 1);
            minimumDate.setDate(0);
            maximumDate.setDate(getLastDayOfMonth(maximumDate));

            dataOrganizedByDate.forEach((x) => {
                const currentAverage = meanBy(x, 'value');
                finalAverageLineData.push({ ...x[0], value: currentAverage });
            });
        }

        const thisYearLineData = chartData.filter(
            (x) => new Date(x.asOfDate).getTime() >= minimumDate.getTime() && new Date(x.asOfDate).getTime() <= maximumDate.getTime(),
        );

        const newLineSeries: IChartDataSeries[] = [
            {
                label: translations.text.thisYear,
                data: thisYearLineData,
            },
            {
                label: `${numberOfYears} ${translations.text.yearlyAverage}`,
                data: finalAverageLineData,
            },
        ];

        // Check if the lines need to be on their own Y-axis.
        const average1 = meanBy(newLineSeries[0].data, 'value');
        const average2 = meanBy(newLineSeries[1].data, 'value');
        if (Math.abs((average1 - average2) / Math.min(Math.abs(average1), Math.abs(average2))) > separarteAxisThresholdPercent) {
            newLineSeries[0].yAxis = 0;
            newLineSeries[1].yAxis = 1;
        }

        setLineSeries(newLineSeries);
    }, [props.runTestMarketIndicatorFactorResponse]);

    const numberOfYears = useMemo(() => {
        const parameters = props.parameters ?? defaultAverageSeasonalParameters;
        const value = Number(parameters.yearsOfData);
        return value;
    }, [props.parameters]);

    const chartData = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.rows &&
            props.runTestMarketIndicatorFactorResponse.rows.length > 0
        ) {
            return marketIndicatorChartService.convertToChartData((props.runTestMarketIndicatorFactorResponse?.rows as MarketIndicatorFactorDataModel[]) ?? []);
        }

        return [];
    }, [props.runTestMarketIndicatorFactorResponse]);

    const isLoading = useMemo(
        () => props.isLoading || !props.runTestMarketIndicatorFactorResponse,
        [props.runTestMarketIndicatorFactorResponse, props.isLoading],
    );

    const title = useMemo(() => {
        if (props.title) {
            return props.title;
        }

        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.commodityDisplayName) {
            const region = props.runTestMarketIndicatorFactorResponse?.subRegion
                ? translate(props.runTestMarketIndicatorFactorResponse?.subRegion)
                : translate(props.runTestMarketIndicatorFactorResponse?.regionDisplayName!);
            const tableDefinitionType =
                translations.tableDefinitionType[
                    leadingIndicatorTypeDefinitions.find((x) => x.value === props.runTestMarketIndicatorFactorResponse?.leadingIndicatorType)
                        ?.tableDefinitionType!
                ];
            const commdityName = props.runTestMarketIndicatorFactorResponse.commodityDisplayName;
            return [region, commdityName, tableDefinitionType].filter((x) => x !== null && x !== '').join(' ');
        }

        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const sourceTag = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags.length > 0
        ) {
            return `${props.runTestMarketIndicatorFactorResponse.dataSourceTags[0]}, ${translations.dataSource.StoneXCalculations}`;
        }

        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const currencies = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.currency) {
            return lineSeries[1]?.yAxis === 1
                ? [props.runTestMarketIndicatorFactorResponse.currency, props.runTestMarketIndicatorFactorResponse.currency]
                : [props.runTestMarketIndicatorFactorResponse.currency];
        }

        return ['' as Currency];
    }, [props.runTestMarketIndicatorFactorResponse, lineSeries]);

    const unitOfMeasures = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.unitOfMeasure) {
            return lineSeries[1]?.yAxis === 1
                ? [props.runTestMarketIndicatorFactorResponse.unitOfMeasure, props.runTestMarketIndicatorFactorResponse.unitOfMeasure]
                : [props.runTestMarketIndicatorFactorResponse.unitOfMeasure];
        }

        return ['' as UnitOfMeasure];
    }, [props.runTestMarketIndicatorFactorResponse, lineSeries]);

    return (
        <ChartWrapper
            name="MarketIndicatorAverageSeasonalChart"
            title={title}
            dataSourceTag={sourceTag}
            isLoading={isLoading}
            headerOptions={{
                showOnlyAsPopout: props.showOnlyAsPopout,
            }}
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
        >
            <MarketIndicatorUserChartRaw lineSeries={lineSeries} currencies={currencies} unitOfMeasures={unitOfMeasures} colorPalette={defaultChartPalette} />
        </ChartWrapper>
    );
};

export default MarketIndicatorAverageSeasonalChart;
