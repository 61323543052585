import { RefObject } from 'react';
import scssVariables from '../../../../../Config.module.scss';
import { CoverageTableLineModel } from '../../../../../Generated/Raven-Demeter';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import styles from './MarginHeatMapTable.module.scss';

interface IMarginHeatMapTableHeaderRowProps {
    currentRow: CoverageTableLineModel;
    firstWeightedPriceLine: number;
    secondWeightedPriceLine: number;
    getTableFormattedNumber: (unformattedNumber: number) => string;
    cellElementReference: RefObject<HTMLDivElement>;
}

const MarginHeatMapTableHeaderRow: React.FC<IMarginHeatMapTableHeaderRowProps> = (props: IMarginHeatMapTableHeaderRowProps) => {
    // Application hooks.
    const [translations] = useLanguage();

    const numberOfCellsBeforeFirstWeightedPriceLine = props.currentRow.coverageTableItems.filter((x) => x.price <= props.firstWeightedPriceLine).length ?? 0;
    const numberOfCellsBeforeSecondWeightedPriceLine = props.currentRow.coverageTableItems.filter((x) => x.price <= props.secondWeightedPriceLine).length ?? 0;

    const getRightBorder = (index: number, columnDataType?: 'weightedPriceColumn' | 'base') => {
        const isWeightedPriceColumn = index === numberOfCellsBeforeFirstWeightedPriceLine - 1 || index + 1 === numberOfCellsBeforeSecondWeightedPriceLine;
        const isLastHeaderCell = index === props.currentRow.coverageTableItems.length - 1;

        if (isWeightedPriceColumn && columnDataType !== 'weightedPriceColumn') {
            return `2px solid ${scssVariables.black}`;
        }

        if (isWeightedPriceColumn || columnDataType === 'base' || isLastHeaderCell) {
            return 'none';
        }

        return `1px solid ${scssVariables.stonexLightGrayBlue}`;
    };

    return (
        <div className={styles.margin_table_row}>
            <div
                ref={props.cellElementReference}
                className={styles.margin_table_header_wrapper}
                style={{ borderRight: getRightBorder(numberOfCellsBeforeFirstWeightedPriceLine === 0 ? -1 : 0, 'base') }}
            >
                <div
                    style={{ borderRight: getRightBorder(numberOfCellsBeforeFirstWeightedPriceLine === 0 ? -1 : 0, 'weightedPriceColumn') }}
                    className={styles.margin_table_header}
                >
                    {translations.unitOfMeasure[`Short${props.currentRow.unitOfMeasure}`]}
                </div>
            </div>
            {props.currentRow.coverageTableItems.map((x, index) => (
                <div
                    key={`MarginTableRowPrice_${x.price}`}
                    className={styles.margin_table_header_wrapper}
                    style={{ borderRight: getRightBorder(index, 'base') }}
                >
                    <div className={styles.margin_table_header} style={{ borderRight: getRightBorder(index, 'weightedPriceColumn') }}>
                        {props.getTableFormattedNumber(x.price)}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MarginHeatMapTableHeaderRow;
