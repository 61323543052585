import React, { memo, useMemo } from 'react';
import { IChartDataSeries } from '../../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../../Components/Charts/ChartWrapper/ChartWrapper';
import ProjectionChartRaw from '../../../../Components/Charts/Projection/ProjectionChartRaw';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { MarginCompositeModel } from '../../RiskDefinitions';

export interface IProductionVersusHedgedVolumeChart {
    getFarmerMarginCalculatorCompositeRowsModels: MarginCompositeModel[];
    getCalculatorFieldValue: (marginCompositeModel: MarginCompositeModel, fieldName: keyof MarginCompositeModel) => number;
    displayDecimalPlaces: number;
}

const ProductionVersusHedgedVolumeChart: React.FC<IProductionVersusHedgedVolumeChart> = (props: IProductionVersusHedgedVolumeChart) => {
    const [translations] = useLanguage();

    const linesSeries = useMemo<IChartDataSeries[]>(
        () => [
            {
                label: translations.risk.margin.text.production,
                data: props.getFarmerMarginCalculatorCompositeRowsModels.map((x) => ({
                    value: props.getCalculatorFieldValue(x, x.production.variableName as keyof MarginCompositeModel),
                    asOfDate: new Date(x.asOfDate),
                    isActualValue: true,
                })),
            },
        ],
        [props.getFarmerMarginCalculatorCompositeRowsModels],
    );

    const barSeries = useMemo<IChartDataSeries>(
        () => ({
            label: translations.risk.margin.text.hedgedVolume,
            data: props.getFarmerMarginCalculatorCompositeRowsModels.map((x) => ({
                value: props.getCalculatorFieldValue(x, x.outputHedgedVolume.variableName as keyof MarginCompositeModel),
                asOfDate: new Date(x.asOfDate),
                isActualValue: true,
            })),
            yAxis: 0,
        }),
        [props.getFarmerMarginCalculatorCompositeRowsModels],
    );

    const dataSourceTag = useMemo(() => translations.dataSource.StoneXCalculations, [translations]);

    return (
        <ChartWrapper
            name="ProductionVersusHedgedVolumeChart"
            title={translations.risk.headers.productionVersusHedgedVolume}
            dataSourceTag={dataSourceTag}
            isLoading={props.getFarmerMarginCalculatorCompositeRowsModels.length === 0}
        >
            <ProjectionChartRaw
                linesSeries={linesSeries}
                barSeries={barSeries}
                unitOfMeasure={props.getFarmerMarginCalculatorCompositeRowsModels[0]?.production.unitOfMeasure}
                currency={props.getFarmerMarginCalculatorCompositeRowsModels[0]?.production.currency}
                displayDecimalPlacesMaximum={props.displayDecimalPlaces}
                displayDecimalPlacesMinimum={props.displayDecimalPlaces}
            />
        </ChartWrapper>
    );
};

export default memo(ProductionVersusHedgedVolumeChart);
