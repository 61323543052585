import { useMemo, useState } from 'react';
import { DemeterFilterTimeSpan, DemeterTableDefinitionType, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import useOriginsAndDestinationsApiHook from '../../../Apis/Hooks/useOriginsAndDestinationsApiHook';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import OriginsAndDestinationsChart from '../../Charts/OriginsAndDestinations/OriginsAndDestinationsChart';
import JoinedSelectionButtons from '../../Form/Buttons/JoinedSelectionButtons/JoinedSelectionButtons';
import ComponentHeader from '../../Headers/ComponentHeader';
import ComponentSubHeader from '../../Headers/ComponentSubHeader';
import { IRegionCommoditySelection } from '../../Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Navigation/Hooks/useTableDefinitionHook';
import OriginsAndDestinationsTable from '../../Tables/OriginsAndDestinations/OriginsAndDestinationsTable';
import styles from './OriginsAndDestinationsContainer.module.scss';

interface IOriginsAndDestinationsContainerProps {
    tableDefinitionType: DemeterTableDefinitionType;
    regionCommoditySelection: IRegionCommoditySelection;
    unitOfMeasure?: UnitOfMeasure;
    testId?: string;
}

export type DestinationsOrOrigins = 'origins' | 'destinations';
const defaultFilterTimeSpan = DemeterFilterTimeSpan.OneYear;

const OriginsAndDestinationsContainer: React.FC<IOriginsAndDestinationsContainerProps> = (props) => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    // Data hooks.
    const [tableDefinitionRegion, tableDefinitionCommodity] = useTableDefinition(props.tableDefinitionType, props.regionCommoditySelection);
    const originsAndDestinationsResponse = useOriginsAndDestinationsApiHook(props.tableDefinitionType, props.regionCommoditySelection!, props.unitOfMeasure);

    // Display hooks.
    const [timeSpan, setTimeSpan] = useState<DemeterFilterTimeSpan>(defaultFilterTimeSpan);

    const destinationsOrOrigins = props.tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyImportsTable ? 'origins' : 'destinations';

    const tableAndChartTitle = useMemo(
        () => `${translate(tableDefinitionRegion?.displayName ?? '')} ${translate(tableDefinitionCommodity?.displayName ?? '')}`,
        [tableDefinitionRegion, tableDefinitionCommodity],
    );

    const destinationsOrOriginsTitle = useMemo(
        () =>
            `${
                destinationsOrOrigins === 'origins'
                    ? translations.destinationsOrOrigins.subHeader.topOrigin
                    : translations.destinationsOrOrigins.subHeader.topDestination
            }`,
        [],
    );

    const timeSpanOptions = useMemo(
        () => [
            {
                label: translations.text.twelveMonths,
                value: DemeterFilterTimeSpan.OneYear,
            },
            {
                label: translations.text.yearToDateShort,
                value: DemeterFilterTimeSpan.YearToDate,
            },
        ],
        [translations],
    );

    const hide = !originsAndDestinationsResponse?.rows || originsAndDestinationsResponse?.rows?.length === 0;

    if (hide) {
        return null;
    }

    return (
        <>
            <div className={styles.origins_and_destinations_donut_selection_buttons}>
                <ComponentHeader title={destinationsOrOriginsTitle} containerClassName={styles.origins_and_destinations_header} />
                <JoinedSelectionButtons
                    name={EventDataTargetsEnum.OriginsAndDestinations}
                    options={timeSpanOptions}
                    handleSelectionChange={(selectionValue) => setTimeSpan(selectionValue)}
                    selection={timeSpan}
                    testId={`${props.testId}SelectionButtons`}
                />
            </div>
            <div className={`${styles.master_flex} ${styles.master_width_100} ${styles.master_spacing_between_charts}`}>
                <div className={styles.origins_and_destinations_table}>
                    <ComponentSubHeader title={tableAndChartTitle} />
                    <OriginsAndDestinationsTable
                        originsOrDestinationsResponse={originsAndDestinationsResponse}
                        filterTimeSpan={timeSpan}
                        testId={`${props.testId}Table`}
                    />
                </div>
                <div className={styles.origins_and_destinations_chart}>
                    <OriginsAndDestinationsChart
                        title={tableAndChartTitle}
                        modalAndDownloadTitle={destinationsOrOriginsTitle}
                        originsOrDestinationsResponse={originsAndDestinationsResponse}
                        filterTimeSpan={timeSpan}
                        testId={`${props.testId}Chart`}
                    />
                </div>
            </div>
        </>
    );
};

export default OriginsAndDestinationsContainer;
