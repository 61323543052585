/* eslint-disable react/no-array-index-key */
import { AxiosRequestConfig } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { demeterApi } from '../../../../Apis/Apis';
import DataSourceTag from '../../../../Components/DataSourceTag/DataSourceTag';
import { DemeterCommodity, GlobalDairyTradeWeeklyEventModel, GlobalDairyTradeWeeklyPriceSummaryModel } from '../../../../Generated/Raven-Demeter';
import useApi from '../../../Apis/Hooks/useApiHook';
import Dropdown from '../../../Components/Form/Inputs/Dropdown';
import { SelectInputOption } from '../../../Components/Form/Inputs/SelectInput';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './GlobalDairyTradeSummary.module.scss';

export interface IGlobalDairyTradeSummaryInterface {
    setTitle: (title: string | React.ReactNode) => void;
}

const numberOfContracts = 6;

const GlobalDairyTradeSummary: React.FC<IGlobalDairyTradeSummaryInterface> = (props: IGlobalDairyTradeSummaryInterface) => {
    const [, , listGlobalDairyTradeWeeklyEventsResponse] = useApi(() => demeterApi.listGlobalDairyTradeWeeklyEvents());
    const [loading, listGlobalDairyTradeWeeklyPricesSummary, globalDairyTradeData] = useApi((request?: { event?: number; options?: AxiosRequestConfig }) =>
        demeterApi.listGlobalDairyTradeWeeklyPricesSummary(request?.event, request?.options),
    );
    const [translations] = useLanguage();
    const [selectedEvent, setSelectedEvent] = useState<number | undefined>();

    const eventOptions: SelectInputOption<number>[] = useMemo(
        () =>
            listGlobalDairyTradeWeeklyEventsResponse?.rows?.map((event: GlobalDairyTradeWeeklyEventModel) => ({
                label: `${formattingService.toShortDayMonthYear(event.asOfDate)} - ${translations.dashboard.headings.event} ${event.event}`,
                value: event.event,
            })) ?? [],
        [listGlobalDairyTradeWeeklyEventsResponse],
    );

    useEffect(
        () =>
            props.setTitle(
                <div className={styles.global_dairy_trade_summary_header_content}>
                    <span className={styles.global_dairy_trade_summary_header_content_text}>
                        {translations.dashboard.headings.globalDairyTradeResultTableHeading}
                    </span>
                    {eventOptions.length > 0 && (
                        <Dropdown
                            value={selectedEvent}
                            options={eventOptions}
                            handleOptionChange={(value) => {
                                setSelectedEvent(value);
                                listGlobalDairyTradeWeeklyPricesSummary({ event: value });
                            }}
                            testId="GlobalDairyTradeEventDropdown"
                        />
                    )}
                </div>,
            ),
        [eventOptions, selectedEvent, translations],
    );

    const getHeaderName = (firstRowWithAllContracts: GlobalDairyTradeWeeklyPriceSummaryModel | undefined, contractNumber: number) => {
        if (!firstRowWithAllContracts) {
            return `${translations.dashboard.fields.contract} ${contractNumber + 1}`;
        }

        const contractRow = firstRowWithAllContracts?.globalDairyTradeWeeklyPrices[contractNumber];
        return `${translations.dashboard.fields.contract} ${contractNumber + 1} / ${formattingService.toMonth(contractRow.contractMonth!)}-${contractRow.year}`;
    };

    const getPositiveNegativeClassNames = (percentChange: number) => {
        if (percentChange > 0) {
            return styles.global_dairy_trade_summary_cell_positive;
        }

        if (percentChange < 0) {
            return styles.global_dairy_trade_summary_cell_negative;
        }

        return styles.global_dairy_trade_summary_cell;
    };

    const headerContractNames = useMemo(() => {
        if (!globalDairyTradeData?.rows) {
            return [];
        }

        setSelectedEvent(globalDairyTradeData.event);

        const headerContractNamesReturnArray = [];
        const firstRowWithAllContracts = globalDairyTradeData.rows.find((x) => x.globalDairyTradeWeeklyPrices.length >= numberOfContracts + 1);

        for (let index = 0; index < numberOfContracts; index += 1) {
            headerContractNamesReturnArray.push(getHeaderName(firstRowWithAllContracts, index));
        }

        return headerContractNamesReturnArray;
    }, [translations, globalDairyTradeData]);

    const contractRows = useMemo(() => {
        if (!globalDairyTradeData) {
            return null;
        }

        const newContractRows: { commodity: DemeterCommodity; value: number | null | undefined; percentChange: number | null | undefined }[][] = [];
        globalDairyTradeData?.rows?.forEach((row) => {
            const currentContractArray = [];

            // All of the contracts + the average price. Use a for loop instead of map so we can capture "--" regardless of whether
            // the globalDairyTradeWeeklyPrices array is fully populated.
            for (let index = 0; index <= numberOfContracts; index += 1) {
                currentContractArray.push({
                    commodity: row.commodity,
                    value: row.globalDairyTradeWeeklyPrices[index]?.value,
                    percentChange: row.globalDairyTradeWeeklyPrices[index]?.percentChange,
                });
            }

            newContractRows.push(currentContractArray);
        });

        return newContractRows;
    }, [globalDairyTradeData]);

    return (
        <div className={styles.global_dairy_trade_summary_container}>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div data-testid="GlobalDairyTradeSummary" className={styles.global_dairy_trade_summary_header_row}>
                        <div className={styles.global_dairy_trade_summary_header}>{translations.dashboard.fields.averageWinningPrice}</div>
                        {headerContractNames?.map((headerContractName) => (
                            <div key={headerContractName} className={styles.global_dairy_trade_summary_header}>
                                {headerContractName}
                            </div>
                        ))}
                        <div className={styles.global_dairy_trade_summary_header}>{translations.dashboard.fields.allContracts}</div>
                    </div>
                    {contractRows?.map((row, rowIndex) => (
                        <div key={`row_${row[rowIndex]?.commodity}`} className={styles.global_dairy_trade_summary_row_group}>
                            <div className={styles.global_dairy_trade_summary_row}>
                                {rowIndex !== numberOfContracts && (
                                    <p className={styles.global_dairy_trade_summary_value_column_header}>
                                        {`${translations.commodity[row[rowIndex]?.commodity]} (${
                                            globalDairyTradeData?.currency && translations.currency[globalDairyTradeData?.currency]
                                        })`}
                                    </p>
                                )}

                                {row.map((cell, cellIndex) => {
                                    if (rowIndex === numberOfContracts) {
                                        return null;
                                    }

                                    return (
                                        <p key={`value_${cell?.commodity}_${cellIndex}`} className={styles.global_dairy_trade_summary_cell}>
                                            {formattingService.toNumberStringWithTrailingZerosOrDash(cell.value ?? 0, 0)}
                                        </p>
                                    );
                                })}
                            </div>

                            <div className={styles.global_dairy_trade_summary_row}>
                                <p className={styles.global_dairy_trade_summary_percent_column_header}>
                                    {rowIndex === numberOfContracts ? (
                                        <strong>{translations.dashboard.fields.changeInOverallPriceIndex}</strong>
                                    ) : (
                                        translations.dashboard.fields.percentChangeFromPreviousEvent
                                    )}
                                </p>

                                {row.map((cell, cellIndex) => (
                                    <p key={`percent_${cell?.commodity}_${cellIndex}`} className={getPositiveNegativeClassNames(cell.percentChange!)}>
                                        {formattingService.toPercent(cell.percentChange)}
                                    </p>
                                ))}
                            </div>
                        </div>
                    ))}
                    <DataSourceTag value={globalDairyTradeData?.dataSourceTag} />
                </>
            )}
        </div>
    );
};

export default GlobalDairyTradeSummary;
