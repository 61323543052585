import { useEffect, useState } from 'react';
import { demeterPresentationTemplatesApi } from '../../../../Apis/Apis';
import { PresentationTemplateModel } from '../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../Apis/Hooks/useApiWithoutAutoExecute';
import ConfirmModal from '../../../Components/Modals/ConfirmModal/ConfirmModal';
import ActionsCellButton from '../../../Components/Tables/ActionsCellButton/ActionsCellButton';
import useLanguage from '../../../Services/Language/useLanguageHook';

interface IDeletePresentationTemplateProps {
    hide?: boolean;
    presentationTemplate?: PresentationTemplateModel | undefined;
    handleConfirmed?: () => void;
}

const DeletePresentationTemplate: React.FC<IDeletePresentationTemplateProps> = (props: IDeletePresentationTemplateProps) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [translations] = useLanguage();

    const [, deletePresentationTemplate, deletePresentationTemplateResponse] = useApiWithoutAutoExecute(() => {
        if (!props.presentationTemplate) {
            return null;
        }
        return demeterPresentationTemplatesApi.deletePresentationTemplate({ presentationTemplateGuid: props.presentationTemplate.presentationTemplateGuid });
    });

    useEffect(() => {
        if (!deletePresentationTemplateResponse) {
            return;
        }
        if (props.handleConfirmed) {
            props.handleConfirmed();
        }
    }, [deletePresentationTemplateResponse]);

    if (props.hide) {
        return null;
    }

    return (
        <>
            <ActionsCellButton text={translations.presentationTemplates.actions.delete} handleClick={() => setShowConfirmModal(true)} />
            <ConfirmModal
                header={translations.presentationTemplates.text.confirmDeleteHeader}
                message={translations.presentationTemplates.text.confirmDeleteMessage}
                showModal={showConfirmModal}
                handleConfirm={() => {
                    deletePresentationTemplate();
                    setShowConfirmModal(false);
                }}
                handleCancel={() => {
                    setShowConfirmModal(false);
                }}
            />
        </>
    );
};

export default DeletePresentationTemplate;
