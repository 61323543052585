import { useMemo } from 'react';
import { MarketIndicatorTransformationType } from '../../../../../../../Generated/Raven-Demeter';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IDataSelectorDataTransformationDropdownProps {
    transformationType: MarketIndicatorTransformationType;
    handleChange: (transformationType?: MarketIndicatorTransformationType) => void;
}

const DataSelectorDataTransformationDropdown: React.FC<IDataSelectorDataTransformationDropdownProps> = (
    props: IDataSelectorDataTransformationDropdownProps,
) => {
    // Application hooks.
    const [translations] = useLanguage();
    const dataTransformationOptions = useMemo(
        () =>
            [
                {
                    label: translations.words.none,
                    value: undefined,
                },
                {
                    label: translations.marketIndicatorTransformationType[MarketIndicatorTransformationType.YearOverYearChange],
                    value: MarketIndicatorTransformationType.YearOverYearChange,
                },
                {
                    label: translations.marketIndicatorTransformationType[MarketIndicatorTransformationType.MonthOverMonthChange],
                    value: MarketIndicatorTransformationType.MonthOverMonthChange,
                },
                {
                    label: translations.marketIndicatorTransformationType[MarketIndicatorTransformationType.WeekOverWeekChange],
                    value: MarketIndicatorTransformationType.WeekOverWeekChange,
                },
                {
                    label: translations.marketIndicatorTransformationType[MarketIndicatorTransformationType.DayOverDayChange],
                    value: MarketIndicatorTransformationType.DayOverDayChange,
                },
                {
                    label: translations.marketIndicatorTransformationType[MarketIndicatorTransformationType.Multiply],
                    value: MarketIndicatorTransformationType.Multiply,
                },
                {
                    label: translations.marketIndicatorTransformationType[MarketIndicatorTransformationType.RelativeStrengthIndex],
                    value: MarketIndicatorTransformationType.RelativeStrengthIndex,
                },
                {
                    label: translations.marketIndicatorTransformationType[MarketIndicatorTransformationType.RollingAverage],
                    value: MarketIndicatorTransformationType.RollingAverage,
                },
                {
                    label: translations.marketIndicatorTransformationType[MarketIndicatorTransformationType.RollingAverageForwardContracts],
                    value: MarketIndicatorTransformationType.RollingAverageForwardContracts,
                },
            ] as { label: string; value: MarketIndicatorTransformationType }[],
        [translations],
    );

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={dataTransformationOptions.find((x) => x.value === props.transformationType)?.value}
                options={dataTransformationOptions!}
                handleOptionChange={(value) => props.handleChange(value)}
                label={translations.marketIndicatorsManagement.fields.dataTransformation}
            />
        </div>
    );
};

export default DataSelectorDataTransformationDropdown;
