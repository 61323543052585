import { RunCalculationEngineResponse } from '../../../../../Generated/Raven-Demeter';
import formattingService from '../../../../Services/Formatting/FormattingService';
import { MarginCompositeModel } from '../../RiskDefinitions';
import styles from './MarginInputsTable.module.scss';

interface IMarginDataProps {
    className?: string;
    getFarmerMarginCalculatorCompositeRowsModel: MarginCompositeModel[];
    runCalculationEngineResponse: RunCalculationEngineResponse;
    fieldName: string;
}

const numberOfDecimalPlacesForPrices = 2;

const MarginDataRow: React.FC<IMarginDataProps> = (props: IMarginDataProps) => {
    const getTextValue = (marginCompositeModel: MarginCompositeModel): string => {
        if (props.fieldName === 'asOfDate') {
            return formattingService.toMonthYear(marginCompositeModel.asOfDate);
        }

        const asOfDate = Object.values(marginCompositeModel)[0];
        const currentRunResponseData = props.runCalculationEngineResponse?.results?.find(
            (y) => new Date(y.asOfDate).getTime() >= new Date(asOfDate).getTime(),
        )?.lines;
        const currentRunResponseValue = currentRunResponseData?.find((x) => x.variableName === props.fieldName)?.value;
        const minimumNumberOfDecimalPlaces = props.fieldName === 'production' ? 0 : numberOfDecimalPlacesForPrices;

        return formattingService.toNumberStringWithTrailingZerosOrDash(
            currentRunResponseValue ?? 0,
            minimumNumberOfDecimalPlaces,
            numberOfDecimalPlacesForPrices,
        );
    };

    return (
        <div className={props.className}>
            {props.getFarmerMarginCalculatorCompositeRowsModel?.map((x) => (
                <div
                    className={x.isUpdatedValue ? styles.margin_inputs_table_updated_column_text_only : styles.margin_inputs_table_base_column_text_only}
                    key={`field_${x.asOfDate}_${props.fieldName}`}
                >
                    {getTextValue(x)}
                </div>
            ))}
        </div>
    );
};

export default MarginDataRow;
