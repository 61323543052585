import { useState } from 'react';
import { demeterCompanyApi } from '../../../Apis/Apis';
import { AgreeToCompanyTermsAndConditionsRequest, DemeterPaymentType, DemeterUserModel } from '../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import TextButton from '../../Components/Form/Buttons/TextButton';
import CheckboxInput from '../../Components/Form/Inputs/CheckboxInput';
import TextInput from '../../Components/Form/Inputs/TextInput';
import ComponentSubHeader from '../../Components/Headers/ComponentSubHeader';
import PageHeader from '../../Components/Headers/PageHeader';
import ActionModal from '../../Components/Modals/ActionModal/ActionModal';
import RegexValidators from '../../Core/Validation/RegexValidators';
import useLanguage from '../../Services/Language/useLanguageHook';
import CompanyRegistrationAgreement from './CompanyRegistrationAgreement';
import styles from './SessionPage.module.scss';

export interface ICompanyRegistrationProps {
    user: DemeterUserModel | undefined;
    handleSubmit: () => void;
    handleCancel: () => void;
}

const CompanyRegistration: React.FC<ICompanyRegistrationProps> = (props: ICompanyRegistrationProps) => {
    const [translations] = useLanguage();
    const [request, setRequest] = useState<AgreeToCompanyTermsAndConditionsRequest>({
        demeterCompanyGuid: props.user?.demeterCompanyGuid!,
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        phone: '',
        contactPerson: '',
        termsAndConditionsAccepted: false,
        billingContactName: null,
        billingCompanyName: null,
        billingAddress1: null,
        billingAddress2: null,
        billingCity: null,
        billingState: null,
        billingCountry: null,
        billingPostalCode: null,
        billingPhone: null,
        billingFax: null,
        billingEmail: null,
        billingVatNumber: '',
        billingSameAsCompanyInformation: false,
        paymentType: undefined,
        futuresAccountTransferConsent: false,
        futuresAccountNumber: null,
        futuresAccountCustomerName: null,
        futuresAccountTitle: null,
    });
    const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState<boolean>(false);

    const isAddressValid =
        request &&
        !!request.address1 &&
        !!request.address2 &&
        !!request.city &&
        !!request.state &&
        !!request.country &&
        !!request.postalCode &&
        !!request.phone;

    const isBillingValid =
        request &&
        !!request.billingVatNumber &&
        (request.billingSameAsCompanyInformation ||
            (!!request.billingContactName &&
                !!request.billingCompanyName &&
                !!request.billingAddress1 &&
                !!request.billingAddress2 &&
                !!request.billingCity &&
                !!request.billingState &&
                !!request.billingCountry &&
                !!request.billingPostalCode &&
                !!request.billingPhone &&
                !!request.billingEmail));

    const isPaymentValid =
        request &&
        (request.paymentType === DemeterPaymentType.WireTransfer ||
            (request.paymentType === DemeterPaymentType.DeductionFromFuturesAccount &&
                !!request.futuresAccountTransferConsent &&
                !!request.futuresAccountNumber &&
                !!request.futuresAccountCustomerName &&
                !!request.futuresAccountTitle));

    const isValid =
        request &&
        !!request.demeterCompanyGuid &&
        !!request.contactPerson &&
        request.termsAndConditionsAccepted &&
        isAddressValid &&
        isBillingValid &&
        isPaymentValid;

    const [isSaving, handleSubmit] = useApiWithoutAutoExecute(() => {
        if (isValid) {
            props.handleSubmit();
            return demeterCompanyApi.agreeToCompanyTermsAndConditions(request?.demeterCompanyGuid!, request);
        }

        return null;
    });

    return (
        <>
            <div className={styles.master_page_container}>
                <PageHeader title={props.user?.companyName || ''} />
                <div className={styles.company_registration}>
                    <div className={styles.company_registration_description}>{translations.companyRegistration.description}</div>

                    <div className={styles.company_registration_form}>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.address1}
                                value={request.address1}
                                required
                                handleTextChange={(value) => setRequest({ ...request, address1: value })}
                                errorMessage={translations.companies.errors.requiredField}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.address2}
                                value={request.address2}
                                required
                                handleTextChange={(value) => setRequest({ ...request, address2: value })}
                                errorMessage={translations.companies.errors.requiredField}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.city}
                                value={request.city}
                                required
                                handleTextChange={(value) => setRequest({ ...request, city: value })}
                                errorMessage={translations.companies.errors.requiredField}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.state}
                                value={request.state}
                                required
                                handleTextChange={(value) => setRequest({ ...request, state: value })}
                                errorMessage={translations.companies.errors.requiredField}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.country}
                                value={request.country}
                                required
                                handleTextChange={(value) => setRequest({ ...request, country: value })}
                                errorMessage={translations.companies.errors.requiredField}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.postalCode}
                                value={request.postalCode}
                                required
                                handleTextChange={(value) => setRequest({ ...request, postalCode: value })}
                                errorMessage={translations.companies.errors.requiredField}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.phone}
                                value={request.phone}
                                required
                                handleTextChange={(value) => setRequest({ ...request, phone: value })}
                                errorMessage={translations.companies.errors.requiredField}
                                validation={RegexValidators.Phone}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.contactPerson}
                                value={request.contactPerson}
                                required
                                handleTextChange={(value) => setRequest({ ...request, contactPerson: value })}
                                errorMessage={translations.companies.errors.requiredField}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <ComponentSubHeader
                            title={translations.companyRegistration.subscriberBillingDetailsHeader}
                            containerClassName={styles.company_registration_text_subheader}
                        />
                        {request.billingSameAsCompanyInformation !== true && (
                            <>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.billingContactName}
                                        value={request.billingContactName}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, billingContactName: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.AlphaNumericMinimumLength2}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.billingCompanyName}
                                        value={request.billingCompanyName}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, billingCompanyName: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.AlphaNumericMinimumLength2}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.billingAddress1}
                                        value={request.billingAddress1}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, billingAddress1: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.AlphaNumericMinimumLength2}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.billingAddress2}
                                        value={request.billingAddress2}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, billingAddress2: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.AlphaNumericMinimumLength2}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.billingCity}
                                        value={request.billingCity}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, billingCity: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.AlphaNumericMinimumLength2}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.billingState}
                                        value={request.billingState}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, billingState: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.AlphaNumericMinimumLength2}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.billingCountry}
                                        value={request.billingCountry}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, billingCountry: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.AlphaNumericMinimumLength2}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.billingPostalCode}
                                        value={request.billingPostalCode}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, billingPostalCode: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.AlphaNumericMinimumLength2}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.billingPhone}
                                        value={request.billingPhone}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, billingPhone: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.Phone}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.billingFax}
                                        value={request.billingFax}
                                        handleTextChange={(value) => setRequest({ ...request, billingFax: value })}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.billingEmail}
                                        value={request.billingEmail}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, billingEmail: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.Email}
                                    />
                                </div>
                            </>
                        )}
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.billingVatNumber}
                                value={request.billingVatNumber}
                                handleTextChange={(value) => setRequest({ ...request, billingVatNumber: value })}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <div className={styles.company_registration_form_field_checkbox_container}>
                                <CheckboxInput
                                    isChecked={request.billingSameAsCompanyInformation ?? false}
                                    handleIsChecked={(value) => setRequest({ ...request, billingSameAsCompanyInformation: value })}
                                />
                                <span>{translations.companyRegistration.billingSameAsSubscriberInformation}</span>
                            </div>
                        </div>

                        <ComponentSubHeader
                            title={translations.companyRegistration.subscriberPaymentOptionsHeader}
                            containerClassName={styles.company_registration_text_subheader}
                        />
                        <div className={styles.company_registration_form_field}>
                            <div className={styles.company_registration_form_field_checkbox_container}>
                                <CheckboxInput
                                    isChecked={request.paymentType === DemeterPaymentType.WireTransfer}
                                    handleIsChecked={(isChecked) => {
                                        if (isChecked) {
                                            setRequest({ ...request, paymentType: DemeterPaymentType.WireTransfer });
                                        } else {
                                            setRequest({ ...request, paymentType: undefined });
                                        }
                                    }}
                                />
                                <span>{translations.companyRegistration.paymentWireTransfer}</span>
                            </div>
                        </div>
                        {request.paymentType === DemeterPaymentType.WireTransfer && (
                            <>
                                <div className={styles.company_registration_form_field}>
                                    <span className={styles.company_registration_text_preformatted}>
                                        {translations.companyRegistration.paymentWireTransferUS}
                                    </span>
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <span className={styles.company_registration_text_preformatted}>
                                        {translations.companyRegistration.paymentWireTransferEU}
                                    </span>
                                </div>
                            </>
                        )}

                        <div className={styles.company_registration_form_field}>
                            <div className={styles.company_registration_form_field_checkbox_container}>
                                <CheckboxInput
                                    isChecked={request.paymentType === DemeterPaymentType.DeductionFromFuturesAccount}
                                    handleIsChecked={(isChecked) => {
                                        if (isChecked) {
                                            setRequest({ ...request, paymentType: DemeterPaymentType.DeductionFromFuturesAccount });
                                        } else {
                                            setRequest({ ...request, paymentType: undefined });
                                        }
                                    }}
                                />
                                <span>{translations.companyRegistration.paymentAutomaticDeduction}</span>
                            </div>
                        </div>

                        {request.paymentType === DemeterPaymentType.DeductionFromFuturesAccount && (
                            <>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.futuresAccountNumber}
                                        value={request.futuresAccountNumber}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, futuresAccountNumber: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.AlphaNumericMinimumLength2}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.futuresAccountCustomerName}
                                        value={request.futuresAccountCustomerName}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, futuresAccountCustomerName: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.AlphaNumericMinimumLength2}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <TextInput
                                        title={translations.companyRegistration.futuresAccountTitle}
                                        value={request.futuresAccountTitle}
                                        required
                                        handleTextChange={(value) => setRequest({ ...request, futuresAccountTitle: value })}
                                        errorMessage={translations.companies.errors.requiredField}
                                        validation={RegexValidators.AlphaNumericMinimumLength2}
                                    />
                                </div>
                                <div className={styles.company_registration_form_field}>
                                    <div className={styles.company_registration_form_field_checkbox_container}>
                                        <CheckboxInput
                                            isChecked={request.futuresAccountTransferConsent ?? false}
                                            handleIsChecked={(value) => setRequest({ ...request, futuresAccountTransferConsent: value })}
                                        />
                                        <span>{translations.companyRegistration.futuresAccountTransferConsent}</span>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className={styles.company_registration_form_field}>
                            <div className={styles.company_registration_form_field_checkbox_container}>
                                <CheckboxInput
                                    isChecked={request.termsAndConditionsAccepted ?? false}
                                    handleIsChecked={(value) => setRequest({ ...request, termsAndConditionsAccepted: value })}
                                />
                                <span>
                                    {translations.companyRegistration.agreeTo}{' '}
                                    <TextButton title={translations.words.termsAndConditions} onClick={() => setShowTermsAndConditionsModal(true)} />
                                </span>
                            </div>
                        </div>
                        <div className={styles.company_registration_form_buttons}>
                            <LinkButton title={translations.actions.cancel} type={LinkButtonType.White} onClick={props.handleCancel} disabled={isSaving} />
                            <LinkButton title={translations.actions.submit} type={LinkButtonType.Blue} onClick={handleSubmit} disabled={isSaving || !isValid} />
                        </div>
                    </div>
                </div>
            </div>
            <ActionModal
                showModal={showTermsAndConditionsModal}
                header={translations.companyRegistration.termsAndConditionsHeader}
                handleCancel={() => setShowTermsAndConditionsModal(false)}
            >
                <CompanyRegistrationAgreement
                    handleCancel={() => setShowTermsAndConditionsModal(false)}
                    handleAccept={() => {
                        setRequest({ ...request!, termsAndConditionsAccepted: true });
                        setShowTermsAndConditionsModal(false);
                    }}
                />
            </ActionModal>
        </>
    );
};

export default CompanyRegistration;
