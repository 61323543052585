import React, { useEffect, useMemo } from 'react';
import { demeterMarketIndicatorsApi } from '../../../Apis/Apis';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import useCacheThenApi from '../../Apis/Hooks/useCacheThenApiHook';
import MarketIndicatorChart from '../../Components/Charts/MarketIndicator/MarketIndicatorChart';
import PageHeaderWithTooltip from '../../Components/Headers/PageHeaderWithTooltip';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import MenuByMarketIndicatorNavigation from '../../Components/MenuFileSelectors/MenuByMarketIndicatorNavigation';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './HedgeMonitorProPage.module.scss';
import HedgeMonitorProSummary from './HedgeMonitorProSummary';
import HedgeMonitorProTable from './HedgeMonitorProTable';

const HedgeMonitorProPage: React.FC = () => {
    // Application hooks and application constants.
    const [translations] = useLanguage();
    const [searchParameters] = useSearchParameters();

    const [, , listMarketIndicatorsResponse] = useCacheThenApi(CacheKeys.ListMarketIndicatorsCurrent, () =>
        demeterMarketIndicatorsApi.listMarketIndicatorsCurrent(),
    );

    const [, refreshMarketIndicatorByRegionCommodity, getMarketIndicatorByRegionCommodityResponse] = useApiWithoutAutoExecute(() => {
        const { region, subRegion, commodity, dataSource } = listMarketIndicatorsResponse!.rows!.find(
            (x) => x.marketIndicatorGuid === searchParameters.marketIndicatorGuid,
        )!;

        return demeterMarketIndicatorsApi.getMarketIndicatorByRegionAndCommodity(region, commodity, subRegion ?? undefined, dataSource ?? undefined);
    });

    const marketIndicator = useMemo(() => getMarketIndicatorByRegionCommodityResponse?.marketIndicator, [getMarketIndicatorByRegionCommodityResponse]);

    useEffect(() => {
        if (!listMarketIndicatorsResponse || listMarketIndicatorsResponse.rows?.length === 0 || !searchParameters.marketIndicatorGuid) {
            return;
        }

        refreshMarketIndicatorByRegionCommodity();
    }, [searchParameters.marketIndicatorGuid, listMarketIndicatorsResponse]);

    const loading = !getMarketIndicatorByRegionCommodityResponse;

    return (
        <div className={styles.master_page_container}>
            <PageHeaderWithTooltip
                title={translations.hedgeMonitorPro.title}
                testId="HedgeMonitorProPageHeader"
                tooltip={translations.hedgeMonitorPro.tooltipMessage}
            />
            {listMarketIndicatorsResponse && <MenuByMarketIndicatorNavigation isHedgeMonitorPro listMarketIndicatorsResponse={listMarketIndicatorsResponse} />}
            {loading ? (
                <PageLoadingSpinner />
            ) : (
                <div className={styles.hedge_monitor_pro_halves}>
                    <div className={styles.hedge_monitor_pro_summary}>
                        <div className={styles.hedge_monitor_pro_summary_margin}>
                            <HedgeMonitorProSummary marketIndicator={marketIndicator} />
                        </div>
                        <HedgeMonitorProTable marketIndicator={marketIndicator} />
                    </div>
                    {getMarketIndicatorByRegionCommodityResponse && (
                        <div className={styles.hedge_monitor_pro_market_chart}>
                            <MarketIndicatorChart
                                useColorPalletteInLegend
                                useHedgeMonitorProRows
                                runMarketIndicatorResponse={getMarketIndicatorByRegionCommodityResponse}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default HedgeMonitorProPage;
