import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterMarketIndicatorsApi } from '../../../../../Apis/Apis';
import { GetMarketIndicatorFactorResponse, GetMarketIndicatorResponse, MarketIndicatorTemplateType } from '../../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../../Layouts/NavigationRoutes';
import useApiWithoutAutoExecute from '../../../../Apis/Hooks/useApiWithoutAutoExecute';
import MarketIndicatorChart from '../../../../Components/Charts/MarketIndicator/MarketIndicatorChart';
// eslint-disable-next-line max-len
import MarketIndicatorAverageSeasonalChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorAverageSeasonal/MarketIndicatorAverageSeasonalChart';
import MarketIndicatorCombinationChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorCombination/MarketIndicatorCombinationChart';
import MarketIndicatorDecileChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorDecile/MarketIndicatorDecileChart';
import MarketIndicatorForecastChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorForecast/MarketIndicatorForecastChart';
import MarketIndicatorHistoricalChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorHistorical/MarketIndicatorHistoricalChart';
import MarketIndicatorSeasonalChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorSeasonal/MarketIndicatorSeasonalChart';
import MarketIndicatorTechnicalChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorTechnical/MarketIndicatorTechnicalChart';
import LinkButton, { LinkButtonType } from '../../../../Components/Form/Buttons/LinkButton';
import LoadingSpinner from '../../../../Components/LoadingSpinner/LoadingSpinner';
import useSearchParameters from '../../../../Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import {
    AverageSeasonalParameters,
    DecileParameters,
    ForecastParameters,
    HistoricalParameters,
    ManualEntryParameters,
    SeasonalParameters,
    TechnicalParameters,
    UpsertMarketIndicatorFactorRequest,
} from '../MarketIndicatorsManagementDefinitions';
import styles from './MarketIndicatorFactorPage.module.scss';

interface IMarketIndicatorFactorChartsProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    parentUpsertMarketIndicatorFactorRequest?: UpsertMarketIndicatorFactorRequest;
    handleSave: () => void;
    handleSaveAndReturn: () => void;
    savingDisabled?: boolean;
    getMarketIndicatorResponse: GetMarketIndicatorResponse | undefined;
}

const MarketIndicatorFactorCharts: React.FC<IMarketIndicatorFactorChartsProps> = (props: IMarketIndicatorFactorChartsProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const navigate = useNavigate();
    const { marketIndicatorGuid } = useParams();
    const [searchParameters] = useSearchParameters();
    const navigateBackToMarketIndicators = () => navigate(`${NavigationRoutes.AdministrationMarketIndicators}/${marketIndicatorGuid}/edit`);

    const [testChartsButtonActivated, setTestChartsButtonActivated] = useState<boolean>();

    // For combination template.
    useEffect(() => {
        setTestChartsButtonActivated(false);
    }, [searchParameters?.tab]);

    // If we change subfactors or load the page, get initial test for our main market indicator chart.
    useEffect(() => {
        if (!props.upsertMarketIndicatorFactorRequest?.marketIndicatorFactorGuid) {
            return;
        }

        refreshRunTestMarketIndicatorFactor();
    }, [
        props.upsertMarketIndicatorFactorRequest?.marketIndicatorFactorGuid,
        props.upsertMarketIndicatorFactorRequest?.subFactors,
        props.getMarketIndicatorResponse?.marketIndicator?.numberOfYears,
        searchParameters.tab,
    ]);

    const [getTestMarketIndicatorFactorLoading, refreshGetTestMarketIndicatorFactor, getTestMarketIndicatorFactorResponse] = useApiWithoutAutoExecute(() =>
        demeterMarketIndicatorsApi.getTestMarketIndicatorFactorData(props.upsertMarketIndicatorFactorRequest),
    );

    const [runTestMarketIndicatorFactorLoading, refreshRunTestMarketIndicatorFactor, runTestMarketIndicatorFactorResponse] = useApiWithoutAutoExecute(
        () =>
            demeterMarketIndicatorsApi.runTestMarketIndicatorFactor({
                leadingIndicatorType: props.upsertMarketIndicatorFactorRequest?.leadingIndicatorType!,
                region: props.upsertMarketIndicatorFactorRequest?.region,
                subRegion: props.upsertMarketIndicatorFactorRequest?.subRegion,
                commodity: props.upsertMarketIndicatorFactorRequest?.commodity,
                extraParameters: props.upsertMarketIndicatorFactorRequest?.extraParameters || undefined,
                dataFrequency: props.upsertMarketIndicatorFactorRequest?.dataFrequency,
                templateType: props.upsertMarketIndicatorFactorRequest?.templateType!,
                parameters: props.upsertMarketIndicatorFactorRequest?.parameters,
                correlation: props.upsertMarketIndicatorFactorRequest?.correlation!,
                numberOfYears: props.getMarketIndicatorResponse?.marketIndicator?.numberOfYears!,
                asOfDate: props.getMarketIndicatorResponse?.marketIndicator?.asOfDate!,
                subFactorGuids: isCombinationParent ? props.parentUpsertMarketIndicatorFactorRequest?.subFactors!.map((y) => y.marketIndicatorFactorGuid) : [],
                dataCombinationType: props.upsertMarketIndicatorFactorRequest?.dataCombinationType,
                transformationType: props.upsertMarketIndicatorFactorRequest?.transformationType,
                transformationValue: props.upsertMarketIndicatorFactorRequest?.transformationValue,
                secondaryExtraParameters: props.upsertMarketIndicatorFactorRequest?.secondaryExtraParameters,
                secondaryRegion: props.upsertMarketIndicatorFactorRequest?.secondaryRegion,
                secondarySubRegion: props.upsertMarketIndicatorFactorRequest?.secondarySubRegion,
                secondaryCommodity: props.upsertMarketIndicatorFactorRequest?.secondaryCommodity,
                secondaryDataFrequency: props.upsertMarketIndicatorFactorRequest?.secondaryDataFrequency,
                secondaryLeadingIndicatorType: props.upsertMarketIndicatorFactorRequest?.secondaryLeadingIndicatorType,
                secondaryTransformationType: props.upsertMarketIndicatorFactorRequest?.secondaryTransformationType,
                secondaryTransformationValue: props.upsertMarketIndicatorFactorRequest?.secondaryTransformationValue,
            }),
        {
            showResponseErrorMessage: true,
        },
    );

    // General constants
    const isCombinationParent =
        searchParameters.tab === 'parent' ||
        (props.upsertMarketIndicatorFactorRequest?.templateType === MarketIndicatorTemplateType.Combination &&
            !props.parentUpsertMarketIndicatorFactorRequest?.marketIndicatorFactorGuid);

    const currentMarketIndicatorSubfactors = props.parentUpsertMarketIndicatorFactorRequest?.subFactors;
    const testChartButtonActiveAndValidGetTestFactorResponse = testChartsButtonActivated && !!getTestMarketIndicatorFactorResponse;
    const testButtonDisabled =
        (isCombinationParent && !currentMarketIndicatorSubfactors) || getTestMarketIndicatorFactorLoading || runTestMarketIndicatorFactorLoading;

    // Define template types.
    const templateTypeIsAverageSeasonal = props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.AverageSeasonal;
    const templateTypeIsCombination = props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Combination;
    const templateTypeIsDecile = props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Decile;
    const templateTypeIsForecast = props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Forecast;
    const templatTypeIsHistorical = props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Historical;
    const templateTypeIsManualEntry = props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.ManualEntry;
    const templateTypeIsSeasonal = props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Seasonal;
    const templateTypeIsSlidingWindowSeasonal = props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.SlidingWindowSeasonal;
    const templateTypeIsTechnical = props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Technical;

    return (
        <div className={styles.indicator_add_and_edit_markets_chart_and_controls}>
            <div className={styles.indicator_add_and_edit_action_buttons_row}>
                <div className={styles.indicator_add_and_edit_action_buttons_test}>
                    <LinkButton
                        onClick={() => {
                            refreshRunTestMarketIndicatorFactor();
                            setTestChartsButtonActivated(true);

                            if (!isCombinationParent) {
                                refreshGetTestMarketIndicatorFactor();
                            }
                        }}
                        disabled={testButtonDisabled}
                        title={translations.actions.test}
                        type={LinkButtonType.White}
                    />
                </div>

                <div className={styles.indicator_add_and_edit_save_and_cancel}>
                    <LinkButton onClick={navigateBackToMarketIndicators} title={translations.actions.cancel} type={LinkButtonType.White} />
                    <LinkButton onClick={props.handleSave} disabled={props.savingDisabled} title={translations.actions.save} type={LinkButtonType.White} />
                    <LinkButton
                        onClick={props.handleSaveAndReturn}
                        disabled={props.savingDisabled}
                        title={translations.actions.saveAndReturn}
                        type={LinkButtonType.Blue}
                    />
                </div>
            </div>
            <div className={styles.indicator_add_and_edit_markets_chart}>
                <MarketIndicatorChart
                    runMarketIndicatorResponse={props.getMarketIndicatorResponse}
                    runTestMarketIndicatorFactorResponse={runTestMarketIndicatorFactorResponse!}
                    useColorPalletteInLegend
                />
            </div>
            {testChartButtonActiveAndValidGetTestFactorResponse && !templateTypeIsCombination && !templateTypeIsManualEntry && (
                <div className={styles.indicator_add_and_edit_markets_chart}>
                    <MarketIndicatorChart
                        title={props.upsertMarketIndicatorFactorRequest?.displayName}
                        runMarketIndicatorResponse={props.getMarketIndicatorResponse}
                        runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                        isLoading={getTestMarketIndicatorFactorLoading}
                        ignorePlotbands
                    />
                </div>
            )}
            {testChartsButtonActivated && currentMarketIndicatorSubfactors && templateTypeIsCombination && searchParameters.tab === 'parent' && (
                <div className={styles.indicator_add_and_edit_markets_chart}>
                    <MarketIndicatorCombinationChart
                        title={props.upsertMarketIndicatorFactorRequest?.displayName}
                        runMarketIndicatorResponse={props.getMarketIndicatorResponse}
                        getMarketIndicatorSubFactorResponses={currentMarketIndicatorSubfactors as GetMarketIndicatorFactorResponse[]}
                        isLoading={getTestMarketIndicatorFactorLoading}
                    />
                </div>
            )}
            {testChartButtonActiveAndValidGetTestFactorResponse && templatTypeIsHistorical && (
                <div className={styles.indicator_add_and_edit_markets_chart}>
                    <MarketIndicatorHistoricalChart
                        title={props.upsertMarketIndicatorFactorRequest?.displayName}
                        runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                        parameters={props.upsertMarketIndicatorFactorRequest.parameters as HistoricalParameters}
                        isLoading={getTestMarketIndicatorFactorLoading}
                    />
                </div>
            )}
            {testChartButtonActiveAndValidGetTestFactorResponse && (templateTypeIsSeasonal || templateTypeIsSlidingWindowSeasonal) && (
                <div className={styles.indicator_add_and_edit_markets_chart}>
                    <MarketIndicatorSeasonalChart
                        title={props.upsertMarketIndicatorFactorRequest?.displayName}
                        runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                        parameters={props.upsertMarketIndicatorFactorRequest.parameters as SeasonalParameters}
                        isLoading={getTestMarketIndicatorFactorLoading}
                    />
                </div>
            )}
            {testChartButtonActiveAndValidGetTestFactorResponse && templateTypeIsAverageSeasonal && (
                <div className={styles.indicator_add_and_edit_markets_chart}>
                    <MarketIndicatorAverageSeasonalChart
                        title={props.upsertMarketIndicatorFactorRequest?.displayName}
                        runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                        parameters={props.upsertMarketIndicatorFactorRequest.parameters as AverageSeasonalParameters}
                        isLoading={getTestMarketIndicatorFactorLoading}
                    />
                </div>
            )}
            {testChartButtonActiveAndValidGetTestFactorResponse && templateTypeIsTechnical && (
                <div className={styles.indicator_add_and_edit_markets_chart}>
                    <MarketIndicatorTechnicalChart
                        title={props.upsertMarketIndicatorFactorRequest?.displayName}
                        runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                        parameters={props.upsertMarketIndicatorFactorRequest.parameters as TechnicalParameters}
                        isLoading={getTestMarketIndicatorFactorLoading}
                    />
                </div>
            )}
            {testChartButtonActiveAndValidGetTestFactorResponse && templateTypeIsDecile && (
                <div className={styles.indicator_add_and_edit_markets_chart}>
                    <MarketIndicatorDecileChart
                        title={props.upsertMarketIndicatorFactorRequest?.displayName}
                        runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                        parameters={props.upsertMarketIndicatorFactorRequest.parameters as DecileParameters}
                        isLoading={getTestMarketIndicatorFactorLoading}
                    />
                </div>
            )}
            {testChartButtonActiveAndValidGetTestFactorResponse && templateTypeIsForecast && (
                <div className={styles.indicator_add_and_edit_markets_chart}>
                    <MarketIndicatorForecastChart
                        title={props.upsertMarketIndicatorFactorRequest?.displayName}
                        runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                        parameters={props.upsertMarketIndicatorFactorRequest.parameters as ForecastParameters}
                        isLoading={getTestMarketIndicatorFactorLoading}
                    />
                </div>
            )}

            {testChartButtonActiveAndValidGetTestFactorResponse &&
                !!(props.upsertMarketIndicatorFactorRequest.parameters as ManualEntryParameters).imageUrl &&
                (getTestMarketIndicatorFactorLoading ? (
                    <LoadingSpinner />
                ) : (
                    <img
                        className={styles.indicator_add_and_edit_image}
                        src={(props.upsertMarketIndicatorFactorRequest.parameters as ManualEntryParameters).imageUrl!}
                        alt={props.upsertMarketIndicatorFactorRequest.displayName}
                    />
                ))}
        </div>
    );
};

export default MarketIndicatorFactorCharts;
