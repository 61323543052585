import { useEffect, useState } from 'react';
import { demeterPresentationTemplatesApi } from '../../../../Apis/Apis';
import { PresentationTemplateModel } from '../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../Apis/Hooks/useApiWithoutAutoExecute';
import TextInput from '../../../Components/Form/Inputs/TextInput';
import ActionModal from '../../../Components/Modals/ActionModal/ActionModal';
import ActionsCellButton from '../../../Components/Tables/ActionsCellButton/ActionsCellButton';
import useLanguage from '../../../Services/Language/useLanguageHook';

interface IDuplicatePresentationTemplateProps {
    hide?: boolean;
    presentationTemplate?: PresentationTemplateModel | undefined;
    handleConfirmed?: () => void;
}

const DuplicatePresentationTemplate: React.FC<IDuplicatePresentationTemplateProps> = (props: IDuplicatePresentationTemplateProps) => {
    const [translations] = useLanguage();
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState<string>();

    const [, duplicatePresentationTemplate, duplicatePresentationTemplateResposne] = useApiWithoutAutoExecute(() => {
        if (!props.presentationTemplate || !name) {
            return null;
        }

        return demeterPresentationTemplatesApi.clonePresentationTemplate({
            presentationTemplateGuid: props.presentationTemplate.presentationTemplateGuid,
            name,
        });
    });

    useEffect(() => {
        if (!duplicatePresentationTemplateResposne) {
            return;
        }
        if (props.handleConfirmed) {
            props.handleConfirmed();
        }
    }, [duplicatePresentationTemplateResposne]);

    if (props.hide) {
        return null;
    }

    return (
        <>
            <ActionsCellButton text={translations.presentationTemplates.actions.duplicate} handleClick={() => setShowModal(true)} />
            <ActionModal
                header={translations.presentationTemplates.text.confirmDuplicateHeader}
                showModal={showModal}
                actionButtonName={translations.actions.save}
                handleAction={() => {
                    duplicatePresentationTemplate();
                    setShowModal(false);
                }}
                handleCancel={() => setShowModal(false)}
                actionButtonDisabled={!name}
            >
                <>
                    <div>{translations.presentationTemplates.fields.templateName}</div>
                    <TextInput value={name} handleTextChange={setName} required />
                </>
            </ActionModal>
        </>
    );
};

export default DuplicatePresentationTemplate;
