import { useEffect, useMemo, useState } from 'react';
import { DemeterTableDefinitionType, DemeterUserStoreType } from '../../../../Generated/Raven-Demeter';
import useTableDefinitionsByCommodityApi from '../../../Apis/Hooks/useTableDefinitionsCommodityApiHook';
import useUserStoreApi from '../../../Apis/Hooks/useUserStoreApiHook';
import languageService from '../../../Services/Language/LanguageService';
import { EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import { MenuTypeForSearchParameter } from '../../MenuFileSelectors/MenuSelectorItem';
import { ITreeNode } from '../../TreeView/TreeItem';
import TreeView from '../../TreeView/TreeView';
import styles from './ProductsModal.module.scss';
import productsModalService from './ProductsModalService';
import ProductsModalWrapper from './ProductsModalWrapper';

interface IMenuSettingsModalProps {
    open: boolean;
    tableDefinitionType: DemeterTableDefinitionType;
    menuSettingsKey: string;
    handleClose: () => void;
    setMenuSettings: () => void;
}

const MenuSettingsModal: React.FC<IMenuSettingsModalProps> = (props) => {
    const [userPreference, setUserPreference] = useState<MenuTypeForSearchParameter>();
    const [userStoreValue, updateUserStore] = useUserStoreApi(DemeterUserStoreType.DisplaySettings);
    const tableDefinitionsCommodity = useTableDefinitionsByCommodityApi(props.tableDefinitionType);

    const treeNodes = useMemo<ITreeNode[]>(() => {
        if (!tableDefinitionsCommodity) {
            return [];
        }

        const createFlattenedNodes = tableDefinitionsCommodity.reduce((nodes: ITreeNode[], commodity, i) => {
            const commodityName = languageService.translate(commodity.displayName);
            const marketName = languageService.translate(commodity.market || '');
            nodes.push({
                id: `${commodity.order + i}`,
                name: commodityName,
                value: `root_${marketName}_${commodityName}`,
                parentId: `${commodity.market}`,
                parentValue: marketName,
                selectedProduct: false,
                expanded: false,
                order: commodity.order,
                displayOrder: commodity.order,
                children: [],
            });

            return nodes;
        }, []);

        return createFlattenedNodes;
    }, [tableDefinitionsCommodity]);

    const handleSelected = (event: React.MouseEvent<HTMLElement>) => {};

    useEffect(() => {
        if (!userStoreValue?.userStore?.value) {
            return;
        }

        setUserPreference(userStoreValue.userStore.value[props.menuSettingsKey]);
    }, [userStoreValue]);

    // TODO all modal translations on modal ticket 130736
    return (
        <ProductsModalWrapper
            name="MenuSettingsModal"
            dataTarget={EventDataTargetsEnum.MenuSettingsModal}
            title="Settings"
            setButtonText="Apply"
            open={props.open}
            isSetButtonDisabled={false}
            handleClose={props.handleClose}
            handleSave={props.setMenuSettings}
        >
            <div className={styles.menu_setting_container}>
                <div className={styles.menu_setting_section}>
                    <div className={styles.menu_setting_section_header}>Commodity Preferences</div>
                    <div className={styles.menu_setting_section_text}>Please select the commodities for which you would like to see data.</div>
                    <div className={styles.menu_setting_tree_container}>
                        <TreeView data={productsModalService.buildTree(treeNodes, 1)?.children} searchTerm="" handleSelect={handleSelected} />
                    </div>
                </div>
                <div className={styles.menu_setting_section}>
                    <div className={styles.menu_setting_section_header}>Menu Preferences</div>
                    <div className={styles.menu_setting_section_text}>
                        To set all pages in StoneX Plus to a different hierarchy, select your preference here, This will reset all the menus in StoneX Plus. If
                        you only wish to change one particular page’s orientation, switch the menu structure on that page.
                    </div>
                    <div>
                        <label className={styles.menu_setting_radio} htmlFor="productOverRegions">
                            <input
                                id="productOverRegions"
                                type="radio"
                                onChange={() => setUserPreference(MenuTypeForSearchParameter.commodity)}
                                value={MenuTypeForSearchParameter.commodity}
                                checked={userPreference === MenuTypeForSearchParameter.commodity}
                            />
                            Display Product over Regions
                        </label>
                    </div>
                    <div>
                        <label className={styles.menu_setting_radio} htmlFor="RegionsOverProducts">
                            <input
                                id="productOverRegions"
                                type="radio"
                                onChange={() => setUserPreference(MenuTypeForSearchParameter.region)}
                                value={MenuTypeForSearchParameter.region}
                                checked={userPreference === MenuTypeForSearchParameter.region}
                            />
                            Display Regions over Products
                        </label>
                    </div>
                </div>
            </div>
        </ProductsModalWrapper>
    );
};

export default MenuSettingsModal;
