import { useEffect, useMemo, useState } from 'react';
import { demeterMarketIndicatorsApi } from '../../../Apis/Apis';
import { DemeterUserStoreType, MarketIndicatorModel } from '../../../Generated/Raven-Demeter';
import useCacheThenApi from '../../Apis/Hooks/useCacheThenApiHook';
import useUserStoreApi from '../../Apis/Hooks/useUserStoreApiHook';
import IndicatorsManagementButton from '../../Components/Form/Buttons/IndicatorsManagementButton/IndicatorsManagementButton';
import LabelWithTooltip from '../../Components/Form/Inputs/LabelWithTooltip';
import ComponentHeader from '../../Components/Headers/ComponentHeader';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import ManageMarketIndicatorsModal from '../../Components/Modals/ProductsModal/ManageCommodityIndicatorsModal';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useLanguage from '../../Services/Language/useLanguageHook';
import MarketIndicatorGuageChart from '../MarketIndicators/MarketIndicatorGuageChart';
import styles from './Dashboard.module.scss';

const defaultChartDimensions = { chartSize: 140, dialRadius: 60 };

const DashboardMarketIndicators: React.FC = () => {
    // Application hooks.
    const [translations, translate] = useLanguage();
    const [userMarketIndicatorsResponse, updateUserMarketIndicators] = useUserStoreApi(DemeterUserStoreType.UserMarketIndicators);
    const [manageCommodityIndicatorsModalOpen, setManageCommodityIndicatorsModalOpen] = useState(false);
    const [userMarketIndicators, setUserMarketIndicators] = useState<string[] | undefined>(undefined);

    // Text hooks.
    const marketIndicatorTitleString = useMemo(() => translations.dashboard.headings.marketIndicatorsHeader, [translations]);

    // Api hooks.
    const [isLoading, , listMarketIndicatorsResponse] = useCacheThenApi(CacheKeys.ListMarketIndicatorsCurrent, () =>
        demeterMarketIndicatorsApi.listMarketIndicatorsCurrent(),
    );

    const marketIndicators = useMemo(() => listMarketIndicatorsResponse?.rows ?? [], [listMarketIndicatorsResponse]);

    const selectedMarketIndicators = useMemo(() => {
        if (!marketIndicators || !userMarketIndicators) {
            return null;
        }

        const selected: MarketIndicatorModel[] = [];
        userMarketIndicators.forEach((val) => {
            const match = marketIndicators.find((x) => x.marketIndicatorGuid === val);
            if (match) {
                selected.push(match);
            }
        });

        return selected;
    }, [marketIndicators, userMarketIndicators]);

    useEffect(() => setUserMarketIndicators(userMarketIndicatorsResponse?.userStore?.value?.userMarketIndicatorGuids ?? []), [userMarketIndicatorsResponse]);

    const handleUserMarketIndicatorsUpdate = async (indicators: MarketIndicatorModel[]) => {
        const updatedUserMarketIndicators = indicators?.map((x) => x.marketIndicatorGuid);
        setUserMarketIndicators(updatedUserMarketIndicators);
        updateUserMarketIndicators({
            userMarketIndicatorGuids: updatedUserMarketIndicators,
        });
    };

    const showLoadingSpinner = isLoading || !selectedMarketIndicators;

    return showLoadingSpinner ? (
        <LoadingSpinner />
    ) : (
        <>
            <div className={styles.dashboard_gray_background}>
                <div className={styles.dashboard_header}>
                    <LabelWithTooltip
                        title={<ComponentHeader title={marketIndicatorTitleString} />}
                        tooltip={translations.marketIndicators.tooltipMessage}
                        placement="right-end"
                    />
                </div>
                <div className={styles.dashboard_guage_charts}>
                    {selectedMarketIndicators?.map((x) => (
                        <div className={styles.dashboard_guage_chart} key={x.marketIndicatorGuid}>
                            <MarketIndicatorGuageChart
                                title={translate(x.displayName ?? '')}
                                chartDimensions={defaultChartDimensions}
                                marketIndicator={x}
                                isSmallGuage
                            />
                        </div>
                    ))}
                    <IndicatorsManagementButton
                        handleClick={() => setManageCommodityIndicatorsModalOpen(true)}
                        tooltipText={translations.dashboard.indicators.addOrRemoveCommodityIndicators}
                    />
                </div>
            </div>
            <ManageMarketIndicatorsModal
                open={manageCommodityIndicatorsModalOpen}
                title={marketIndicatorTitleString}
                selected={selectedMarketIndicators}
                indicators={marketIndicators}
                handleClose={() => setManageCommodityIndicatorsModalOpen(false)}
                handleChange={handleUserMarketIndicatorsUpdate}
            />
        </>
    );
};

export default DashboardMarketIndicators;
