/* tslint:disable */
/* eslint-disable */
/**
 * Raven Demeter Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddCalculationEngineInputsRequest
 */
export interface AddCalculationEngineInputsRequest {
    /**
     * 
     * @type {string}
     * @memberof AddCalculationEngineInputsRequest
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCalculationEngineInputsRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCalculationEngineInputsRequest
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddCalculationEngineInputsRequest
     */
    'order'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddCalculationEngineInputsRequest
     */
    'demeterCalculationEngineGuid': string;
    /**
     * 
     * @type {string}
     * @memberof AddCalculationEngineInputsRequest
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddCalculationEngineInputsRequest
     */
    'showHistory'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddCalculationEngineInputsRequest
     */
    'showFutures'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddCalculationEngineInputsRequest
     */
    'endingDate'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof AddCalculationEngineInputsRequest
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof AddCalculationEngineInputsRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof AddCalculationEngineInputsRequest
     */
    'showHiddenLines'?: boolean;
    /**
     * 
     * @type {{ [key: string]: CalculationEngineDataOverride; }}
     * @memberof AddCalculationEngineInputsRequest
     */
    'dataOverrides'?: { [key: string]: CalculationEngineDataOverride; } | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AddCalculationEngineInputsRequest
     */
    'valueOverrides'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {Array<CalculationEngineMonthlyValueOverrides>}
     * @memberof AddCalculationEngineInputsRequest
     */
    'monthlyValueOverrides'?: Array<CalculationEngineMonthlyValueOverrides> | null;
}
/**
 * 
 * @export
 * @interface AddCalculationEngineInputsResponse
 */
export interface AddCalculationEngineInputsResponse {
    /**
     * 
     * @type {string}
     * @memberof AddCalculationEngineInputsResponse
     */
    'demeterCalculationEngineInputsGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddDemeterCompanyRequest
 */
export interface AddDemeterCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'invoiceDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddDemeterCompanyRequest
     */
    'subscriptionPrice'?: number | null;
    /**
     * 
     * @type {DemeterSubscriptionFrequency}
     * @memberof AddDemeterCompanyRequest
     */
    'subscriptionFrequency'?: DemeterSubscriptionFrequency;
    /**
     * 
     * @type {Currency}
     * @memberof AddDemeterCompanyRequest
     */
    'billingCurrency'?: Currency;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof AddDemeterCompanyRequest
     */
    'billingRegion'?: DemeterRegion;
    /**
     * 
     * @type {number}
     * @memberof AddDemeterCompanyRequest
     */
    'numberOfLicenses'?: number | null;
    /**
     * 
     * @type {Array<DemeterMarket>}
     * @memberof AddDemeterCompanyRequest
     */
    'markets'?: Array<DemeterMarket> | null;
    /**
     * 
     * @type {Array<DemeterMarketLicenseModel>}
     * @memberof AddDemeterCompanyRequest
     */
    'marketLicenses'?: Array<DemeterMarketLicenseModel> | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'contactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'billingContactName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'billingCompanyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'billingAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'billingAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'billingCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'billingState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'billingCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'billingPostalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'billingPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'billingFax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'billingEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'billingVatNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddDemeterCompanyRequest
     */
    'billingSameAsCompanyInformation'?: boolean | null;
    /**
     * 
     * @type {DemeterPaymentType}
     * @memberof AddDemeterCompanyRequest
     */
    'paymentType'?: DemeterPaymentType;
    /**
     * 
     * @type {boolean}
     * @memberof AddDemeterCompanyRequest
     */
    'futuresAccountTransferConsent'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'futuresAccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'futuresAccountCustomerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'futuresAccountTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyRequest
     */
    'policyNumbers'?: string | null;
}
/**
 * 
 * @export
 * @interface AddDemeterCompanyResponse
 */
export interface AddDemeterCompanyResponse {
    /**
     * 
     * @type {string}
     * @memberof AddDemeterCompanyResponse
     */
    'demeterCompanyGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddDemeterUserRequest
 */
export interface AddDemeterUserRequest {
    /**
     * 
     * @type {string}
     * @memberof AddDemeterUserRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterUserRequest
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterUserRequest
     */
    'demeterCompanyGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterUserRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterUserRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {DemeterUserType}
     * @memberof AddDemeterUserRequest
     */
    'userType'?: DemeterUserType;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterUserRequest
     */
    'trialStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterUserRequest
     */
    'trialExpirationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterUserRequest
     */
    'subscribedDate'?: string | null;
    /**
     * 
     * @type {MarketPrecision}
     * @memberof AddDemeterUserRequest
     */
    'marketPrecision'?: MarketPrecision;
    /**
     * 
     * @type {string}
     * @memberof AddDemeterUserRequest
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddDemeterUserRequest
     */
    'isCompanyAdministrator'?: boolean | null;
    /**
     * 
     * @type {Array<DemeterMarket>}
     * @memberof AddDemeterUserRequest
     */
    'markets'?: Array<DemeterMarket> | null;
}
/**
 * 
 * @export
 * @interface AddDemeterUserResponse
 */
export interface AddDemeterUserResponse {
    /**
     * 
     * @type {string}
     * @memberof AddDemeterUserResponse
     */
    'demeterTrialUserGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddMarketIndicatorFactorRequest
 */
export interface AddMarketIndicatorFactorRequest {
    /**
     * 
     * @type {string}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'marketIndicatorGuid': string;
    /**
     * 
     * @type {string}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'parentMarketIndicatorFactorGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'displayGroupName': string;
    /**
     * 
     * @type {string}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'displayName': string;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'market'?: DemeterMarket;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'leadingIndicatorType': LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'dataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'transformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'transformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorDataCombinationType}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'dataCombinationType'?: MarketIndicatorDataCombinationType;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'secondaryMarket'?: DemeterMarket;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'secondaryLeadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'secondaryRegion'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'secondarySubRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'secondaryCommodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'secondaryExtraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'secondaryDataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'secondaryTransformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'secondaryTransformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorTemplateType}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'templateType': MarketIndicatorTemplateType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'parameters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Correlation}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'correlation': Correlation;
    /**
     * 
     * @type {number}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'multiplier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddMarketIndicatorFactorRequest
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface AddMarketIndicatorFactorResponse
 */
export interface AddMarketIndicatorFactorResponse {
    /**
     * 
     * @type {string}
     * @memberof AddMarketIndicatorFactorResponse
     */
    'marketIndicatorFactorGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddMarketIndicatorRequest
 */
export interface AddMarketIndicatorRequest {
    /**
     * 
     * @type {DemeterRegion}
     * @memberof AddMarketIndicatorRequest
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof AddMarketIndicatorRequest
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof AddMarketIndicatorRequest
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof AddMarketIndicatorRequest
     */
    'dataSource'?: DemeterDataSource;
    /**
     * 
     * @type {boolean}
     * @memberof AddMarketIndicatorRequest
     */
    'usePrices'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AddMarketIndicatorRequest
     */
    'contractNumber': number;
    /**
     * 
     * @type {string}
     * @memberof AddMarketIndicatorRequest
     */
    'displayName': string;
    /**
     * 
     * @type {number}
     * @memberof AddMarketIndicatorRequest
     */
    'numberOfYears': number;
}
/**
 * 
 * @export
 * @interface AddMarketIndicatorResponse
 */
export interface AddMarketIndicatorResponse {
    /**
     * 
     * @type {string}
     * @memberof AddMarketIndicatorResponse
     */
    'marketIndicatorGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddMarketIndicatorReviewRequest
 */
export interface AddMarketIndicatorReviewRequest {
    /**
     * 
     * @type {string}
     * @memberof AddMarketIndicatorReviewRequest
     */
    'marketIndicatorGuid': string;
    /**
     * 
     * @type {MarketIndicatorReviewStatus}
     * @memberof AddMarketIndicatorReviewRequest
     */
    'status': MarketIndicatorReviewStatus;
    /**
     * 
     * @type {string}
     * @memberof AddMarketIndicatorReviewRequest
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface AddPresentationTemplateRequest
 */
export interface AddPresentationTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof AddPresentationTemplateRequest
     */
    'name': string;
    /**
     * 
     * @type {PresentationFormat}
     * @memberof AddPresentationTemplateRequest
     */
    'format': PresentationFormat;
    /**
     * 
     * @type {Array<DemeterPageElementModel>}
     * @memberof AddPresentationTemplateRequest
     */
    'rows'?: Array<DemeterPageElementModel> | null;
}
/**
 * 
 * @export
 * @interface AddPresentationTemplateResponse
 */
export interface AddPresentationTemplateResponse {
    /**
     * 
     * @type {string}
     * @memberof AddPresentationTemplateResponse
     */
    'presentationTemplateGuid'?: string;
}
/**
 * 
 * @export
 * @interface AgreeToCompanyTermsAndConditionsRequest
 */
export interface AgreeToCompanyTermsAndConditionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'demeterCompanyGuid': string;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'address1': string;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'address2': string;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'contactPerson': string;
    /**
     * 
     * @type {boolean}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'termsAndConditionsAccepted': boolean;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingContactName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingCompanyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingPostalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingFax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingVatNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'billingSameAsCompanyInformation'?: boolean | null;
    /**
     * 
     * @type {DemeterPaymentType}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'paymentType'?: DemeterPaymentType;
    /**
     * 
     * @type {boolean}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'futuresAccountTransferConsent'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'futuresAccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'futuresAccountCustomerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgreeToCompanyTermsAndConditionsRequest
     */
    'futuresAccountTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface AgreeToDemeterTermsAndConditionsRequest
 */
export interface AgreeToDemeterTermsAndConditionsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AgreeToDemeterTermsAndConditionsRequest
     */
    'agree': boolean;
    /**
     * 
     * @type {number}
     * @memberof AgreeToDemeterTermsAndConditionsRequest
     */
    'version'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Application = {
    Demeter: 'Demeter',
    FarmAdvantage: 'FarmAdvantage',
    MyStoneXMobile: 'MyStoneXMobile',
    CommodityManagement: 'CommodityManagement'
} as const;

export type Application = typeof Application[keyof typeof Application];


/**
 * 
 * @export
 * @interface BatchImportDemeterUsersResponse
 */
export interface BatchImportDemeterUsersResponse {
    /**
     * 
     * @type {Array<ImportDemeterUserResult>}
     * @memberof BatchImportDemeterUsersResponse
     */
    'results'?: Array<ImportDemeterUserResult> | null;
}
/**
 * 
 * @export
 * @interface BatchUpdateDemeterLeadingIndicatorsRequest
 */
export interface BatchUpdateDemeterLeadingIndicatorsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchUpdateDemeterLeadingIndicatorsRequest
     */
    'demeterLeadingIndicatorGuids'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface BudgetCalculatorModel
 */
export interface BudgetCalculatorModel {
    /**
     * 
     * @type {Array<MonthlyBudgetModel>}
     * @memberof BudgetCalculatorModel
     */
    'budgets': Array<MonthlyBudgetModel>;
    /**
     * 
     * @type {string}
     * @memberof BudgetCalculatorModel
     */
    'demeterUserCalculatorGuid'?: string | null;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof BudgetCalculatorModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof BudgetCalculatorModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {FuturesDirection}
     * @memberof BudgetCalculatorModel
     */
    'direction': FuturesDirection;
    /**
     * 
     * @type {number}
     * @memberof BudgetCalculatorModel
     */
    'probability': number;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof BudgetCalculatorModel
     */
    'volatilityTimeSpan': DemeterFilterTimeSpan;
    /**
     * 
     * @type {number}
     * @memberof BudgetCalculatorModel
     */
    'basis': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetCalculatorModel
     */
    'monthLag'?: number | null;
    /**
     * 
     * @type {Currency}
     * @memberof BudgetCalculatorModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof BudgetCalculatorModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {Array<MonthlyBudgetModel>}
     * @memberof BudgetCalculatorModel
     */
    'rows': Array<MonthlyBudgetModel>;
    /**
     * 
     * @type {string}
     * @memberof BudgetCalculatorModel
     */
    'modifiedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetCalculatorModel
     */
    'modifiedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface CalculationEngineDataOverride
 */
export interface CalculationEngineDataOverride {
    /**
     * 
     * @type {DemeterMarket}
     * @memberof CalculationEngineDataOverride
     */
    'market'?: DemeterMarket;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof CalculationEngineDataOverride
     */
    'leadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CalculationEngineDataOverride
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CalculationEngineDataOverride
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CalculationEngineDataOverride
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof CalculationEngineDataOverride
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof CalculationEngineDataOverride
     */
    'dataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof CalculationEngineDataOverride
     */
    'secondaryMarket'?: DemeterMarket;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof CalculationEngineDataOverride
     */
    'secondaryLeadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CalculationEngineDataOverride
     */
    'secondaryRegion'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CalculationEngineDataOverride
     */
    'secondarySubRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CalculationEngineDataOverride
     */
    'secondaryCommodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof CalculationEngineDataOverride
     */
    'secondaryExtraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof CalculationEngineDataOverride
     */
    'secondaryDataFrequency'?: DemeterDataFrequency;
}
/**
 * 
 * @export
 * @interface CalculationEngineLineResultModel
 */
export interface CalculationEngineLineResultModel {
    /**
     * 
     * @type {string}
     * @memberof CalculationEngineLineResultModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationEngineLineResultModel
     */
    'groupDisplayName': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationEngineLineResultModel
     */
    'subGroupDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalculationEngineLineResultModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationEngineLineResultModel
     */
    'variableName': string;
    /**
     * 
     * @type {number}
     * @memberof CalculationEngineLineResultModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CalculationEngineLineResultModel
     */
    'originalDataValue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalculationEngineLineResultModel
     */
    'isHidden': boolean;
    /**
     * 
     * @type {Currency}
     * @memberof CalculationEngineLineResultModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CalculationEngineLineResultModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface CalculationEngineMonthlyValueOverrides
 */
export interface CalculationEngineMonthlyValueOverrides {
    /**
     * 
     * @type {string}
     * @memberof CalculationEngineMonthlyValueOverrides
     */
    'asOfDate': string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof CalculationEngineMonthlyValueOverrides
     */
    'valueOverrides': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface CalculationEngineOption
 */
export interface CalculationEngineOption {
    /**
     * 
     * @type {string}
     * @memberof CalculationEngineOption
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof CalculationEngineOption
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface CalculationEngineResultModel
 */
export interface CalculationEngineResultModel {
    /**
     * 
     * @type {string}
     * @memberof CalculationEngineResultModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationEngineResultModel
     */
    'contractAsOfDate': string;
    /**
     * 
     * @type {Array<CalculationEngineLineResultModel>}
     * @memberof CalculationEngineResultModel
     */
    'lines': Array<CalculationEngineLineResultModel>;
}
/**
 * 
 * @export
 * @interface CloneMarketIndicatorRequest
 */
export interface CloneMarketIndicatorRequest {
    /**
     * 
     * @type {string}
     * @memberof CloneMarketIndicatorRequest
     */
    'marketIndicatorGuid': string;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CloneMarketIndicatorRequest
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CloneMarketIndicatorRequest
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CloneMarketIndicatorRequest
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof CloneMarketIndicatorRequest
     */
    'dataSource'?: DemeterDataSource;
    /**
     * 
     * @type {boolean}
     * @memberof CloneMarketIndicatorRequest
     */
    'usePrices'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof CloneMarketIndicatorRequest
     */
    'contractNumber'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CloneMarketIndicatorRequest
     */
    'displayName': string;
    /**
     * 
     * @type {number}
     * @memberof CloneMarketIndicatorRequest
     */
    'numberOfYears'?: number | null;
}
/**
 * 
 * @export
 * @interface ClonePresentationTemplateRequest
 */
export interface ClonePresentationTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof ClonePresentationTemplateRequest
     */
    'presentationTemplateGuid': string;
    /**
     * 
     * @type {string}
     * @memberof ClonePresentationTemplateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CommodityDailyDomesticModel
 */
export interface CommodityDailyDomesticModel {
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityDailyDomesticModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityDailyDomesticModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityDailyDomesticModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityDailyDomesticModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityDailyDomesticModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityDailyDomesticModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityDailyDomesticModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityDailyExportModel
 */
export interface CommodityDailyExportModel {
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityDailyExportModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityDailyExportModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityDailyExportModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityDailyExportModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityDailyExportModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityDailyExportModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityDailyExportModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityDailyImportModel
 */
export interface CommodityDailyImportModel {
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityDailyImportModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityDailyImportModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityDailyImportModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityDailyImportModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityDailyImportModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityDailyImportModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityDailyImportModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityDailyProductionModel
 */
export interface CommodityDailyProductionModel {
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityDailyProductionModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityDailyProductionModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityDailyProductionModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityDailyProductionModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityDailyProductionModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityDailyProductionModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityDailyProductionModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyDomesticModel
 */
export interface CommodityMonthlyDomesticModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyDomesticModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyDomesticModel
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyDomesticModel
     */
    'normalizedValue': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyDomesticModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyDomesticModel
     */
    'monthOverMonthAbsoluteChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyDomesticModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyDomesticModel
     */
    'rollingYearValue'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityMonthlyDomesticModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyDomesticModel
     */
    'alwaysShowActualValue': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyDomesticModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyDomesticModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityMonthlyDomesticModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyDomesticModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyDomesticModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyDomesticModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyDomesticModelSeasonalYearSummaryModel
 */
export interface CommodityMonthlyDomesticModelSeasonalYearSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyDomesticModelSeasonalYearSummaryModel
     */
    'year': number;
    /**
     * 
     * @type {SeasonalYearModel}
     * @memberof CommodityMonthlyDomesticModelSeasonalYearSummaryModel
     */
    'yearlyValues': SeasonalYearModel;
    /**
     * 
     * @type {Array<SeasonalQuarterModel>}
     * @memberof CommodityMonthlyDomesticModelSeasonalYearSummaryModel
     */
    'quarterlyValues': Array<SeasonalQuarterModel>;
    /**
     * 
     * @type {Array<CommodityMonthlyDomesticModel>}
     * @memberof CommodityMonthlyDomesticModelSeasonalYearSummaryModel
     */
    'monthlyValues': Array<CommodityMonthlyDomesticModel>;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyExportDestinationModel
 */
export interface CommodityMonthlyExportDestinationModel {
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'destinationRegion': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'destinationSubRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'yearToDateValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'yearToDateChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'order': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'normalizedValue': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'monthOverMonthAbsoluteChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'rollingYearValue'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'alwaysShowActualValue': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyExportDestinationModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyExportModel
 */
export interface CommodityMonthlyExportModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportModel
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportModel
     */
    'normalizedValue': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportModel
     */
    'monthOverMonthAbsoluteChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportModel
     */
    'rollingYearValue'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityMonthlyExportModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyExportModel
     */
    'alwaysShowActualValue': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyExportModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyExportModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityMonthlyExportModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyExportModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyExportModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyExportModelSeasonalYearSummaryModel
 */
export interface CommodityMonthlyExportModelSeasonalYearSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyExportModelSeasonalYearSummaryModel
     */
    'year': number;
    /**
     * 
     * @type {SeasonalYearModel}
     * @memberof CommodityMonthlyExportModelSeasonalYearSummaryModel
     */
    'yearlyValues': SeasonalYearModel;
    /**
     * 
     * @type {Array<SeasonalQuarterModel>}
     * @memberof CommodityMonthlyExportModelSeasonalYearSummaryModel
     */
    'quarterlyValues': Array<SeasonalQuarterModel>;
    /**
     * 
     * @type {Array<CommodityMonthlyExportModel>}
     * @memberof CommodityMonthlyExportModelSeasonalYearSummaryModel
     */
    'monthlyValues': Array<CommodityMonthlyExportModel>;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyImportModel
 */
export interface CommodityMonthlyImportModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportModel
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportModel
     */
    'normalizedValue': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportModel
     */
    'monthOverMonthAbsoluteChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportModel
     */
    'rollingYearValue'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityMonthlyImportModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyImportModel
     */
    'alwaysShowActualValue': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyImportModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyImportModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityMonthlyImportModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyImportModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyImportModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyImportModelSeasonalYearSummaryModel
 */
export interface CommodityMonthlyImportModelSeasonalYearSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportModelSeasonalYearSummaryModel
     */
    'year': number;
    /**
     * 
     * @type {SeasonalYearModel}
     * @memberof CommodityMonthlyImportModelSeasonalYearSummaryModel
     */
    'yearlyValues': SeasonalYearModel;
    /**
     * 
     * @type {Array<SeasonalQuarterModel>}
     * @memberof CommodityMonthlyImportModelSeasonalYearSummaryModel
     */
    'quarterlyValues': Array<SeasonalQuarterModel>;
    /**
     * 
     * @type {Array<CommodityMonthlyImportModel>}
     * @memberof CommodityMonthlyImportModelSeasonalYearSummaryModel
     */
    'monthlyValues': Array<CommodityMonthlyImportModel>;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyImportOriginModel
 */
export interface CommodityMonthlyImportOriginModel {
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'originRegion': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'originSubRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'yearToDateValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'yearToDateChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'order': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'normalizedValue': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'monthOverMonthAbsoluteChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'rollingYearValue'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'alwaysShowActualValue': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyImportOriginModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyMarginModel
 */
export interface CommodityMonthlyMarginModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyMarginModel
     */
    'contraction'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyMarginModel
     */
    'expansion'?: number | null;
    /**
     * 
     * @type {Currency}
     * @memberof CommodityMonthlyMarginModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyMarginModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyMarginModel
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyMarginModel
     */
    'normalizedValue': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyMarginModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyMarginModel
     */
    'monthOverMonthAbsoluteChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyMarginModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyMarginModel
     */
    'rollingYearValue'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityMonthlyMarginModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyMarginModel
     */
    'alwaysShowActualValue': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyMarginModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyMarginModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityMonthlyMarginModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyMarginModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyMarginModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyMarginModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyOtherModel
 */
export interface CommodityMonthlyOtherModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyOtherModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyOtherModel
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyOtherModel
     */
    'normalizedValue': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyOtherModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyOtherModel
     */
    'monthOverMonthAbsoluteChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyOtherModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyOtherModel
     */
    'rollingYearValue'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityMonthlyOtherModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyOtherModel
     */
    'alwaysShowActualValue': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyOtherModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyOtherModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityMonthlyOtherModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyOtherModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyOtherModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyOtherModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyPriceModel
 */
export interface CommodityMonthlyPriceModel {
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof CommodityMonthlyPriceModel
     */
    'dataFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceModel
     */
    'month': number;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyPriceModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyPriceModel
     */
    'groupDisplayName'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof CommodityMonthlyPriceModel
     */
    'priceFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof CommodityMonthlyPriceModel
     */
    'dataSource': DemeterDataSource;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyPriceModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof CommodityMonthlyPriceModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityMonthlyPriceModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceModel
     */
    'previousValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceModel
     */
    'percentChange'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceModel
     */
    'netChange'?: number | null;
    /**
     * 
     * @type {Array<PriceFrequencyDefinition>}
     * @memberof CommodityMonthlyPriceModel
     */
    'priceFrequencyDefinitions': Array<PriceFrequencyDefinition>;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyPriceModel
     */
    'hasPricingData': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyPriceModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyPriceModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityMonthlyPriceModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyPriceModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyPriceModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyPriceSeasonalModel
 */
export interface CommodityMonthlyPriceSeasonalModel {
    /**
     * 
     * @type {Currency}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'dataSource'?: DemeterDataSource;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'normalizedValue': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'monthOverMonthAbsoluteChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'rollingYearValue'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'alwaysShowActualValue': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyPriceSeasonalModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyPriceSeasonalModelSeasonalYearSummaryModel
 */
export interface CommodityMonthlyPriceSeasonalModelSeasonalYearSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyPriceSeasonalModelSeasonalYearSummaryModel
     */
    'year': number;
    /**
     * 
     * @type {SeasonalYearModel}
     * @memberof CommodityMonthlyPriceSeasonalModelSeasonalYearSummaryModel
     */
    'yearlyValues': SeasonalYearModel;
    /**
     * 
     * @type {Array<SeasonalQuarterModel>}
     * @memberof CommodityMonthlyPriceSeasonalModelSeasonalYearSummaryModel
     */
    'quarterlyValues': Array<SeasonalQuarterModel>;
    /**
     * 
     * @type {Array<CommodityMonthlyPriceSeasonalModel>}
     * @memberof CommodityMonthlyPriceSeasonalModelSeasonalYearSummaryModel
     */
    'monthlyValues': Array<CommodityMonthlyPriceSeasonalModel>;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyProductionModel
 */
export interface CommodityMonthlyProductionModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyProductionModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyProductionModel
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyProductionModel
     */
    'normalizedValue': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyProductionModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyProductionModel
     */
    'monthOverMonthAbsoluteChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyProductionModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyProductionModel
     */
    'rollingYearValue'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityMonthlyProductionModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyProductionModel
     */
    'alwaysShowActualValue': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyProductionModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyProductionModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityMonthlyProductionModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyProductionModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyProductionModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyProductionModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyProductionModelSeasonalYearSummaryModel
 */
export interface CommodityMonthlyProductionModelSeasonalYearSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyProductionModelSeasonalYearSummaryModel
     */
    'year': number;
    /**
     * 
     * @type {SeasonalYearModel}
     * @memberof CommodityMonthlyProductionModelSeasonalYearSummaryModel
     */
    'yearlyValues': SeasonalYearModel;
    /**
     * 
     * @type {Array<SeasonalQuarterModel>}
     * @memberof CommodityMonthlyProductionModelSeasonalYearSummaryModel
     */
    'quarterlyValues': Array<SeasonalQuarterModel>;
    /**
     * 
     * @type {Array<CommodityMonthlyProductionModel>}
     * @memberof CommodityMonthlyProductionModelSeasonalYearSummaryModel
     */
    'monthlyValues': Array<CommodityMonthlyProductionModel>;
}
/**
 * 
 * @export
 * @interface CommodityMonthlySalesModel
 */
export interface CommodityMonthlySalesModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlySalesModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlySalesModel
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlySalesModel
     */
    'normalizedValue': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlySalesModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlySalesModel
     */
    'monthOverMonthAbsoluteChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlySalesModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlySalesModel
     */
    'rollingYearValue'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityMonthlySalesModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlySalesModel
     */
    'alwaysShowActualValue': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlySalesModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlySalesModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityMonthlySalesModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlySalesModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlySalesModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlySalesModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyStockModel
 */
export interface CommodityMonthlyStockModel {
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof CommodityMonthlyStockModel
     */
    'dataSource': DemeterDataSource;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyStockModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyStockModel
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyStockModel
     */
    'normalizedValue': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyStockModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyStockModel
     */
    'monthOverMonthAbsoluteChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyStockModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyStockModel
     */
    'rollingYearValue'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityMonthlyStockModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyStockModel
     */
    'alwaysShowActualValue': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityMonthlyStockModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityMonthlyStockModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityMonthlyStockModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyStockModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityMonthlyStockModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityMonthlyStockModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityMonthlyStockModelSeasonalYearSummaryModel
 */
export interface CommodityMonthlyStockModelSeasonalYearSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityMonthlyStockModelSeasonalYearSummaryModel
     */
    'year': number;
    /**
     * 
     * @type {SeasonalYearModel}
     * @memberof CommodityMonthlyStockModelSeasonalYearSummaryModel
     */
    'yearlyValues': SeasonalYearModel;
    /**
     * 
     * @type {Array<SeasonalQuarterModel>}
     * @memberof CommodityMonthlyStockModelSeasonalYearSummaryModel
     */
    'quarterlyValues': Array<SeasonalQuarterModel>;
    /**
     * 
     * @type {Array<CommodityMonthlyStockModel>}
     * @memberof CommodityMonthlyStockModelSeasonalYearSummaryModel
     */
    'monthlyValues': Array<CommodityMonthlyStockModel>;
}
/**
 * 
 * @export
 * @interface CommodityOtcPriceModel
 */
export interface CommodityOtcPriceModel {
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityOtcPriceModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityOtcPriceModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityOtcPriceModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {number}
     * @memberof CommodityOtcPriceModel
     */
    'contractYear': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityOtcPriceModel
     */
    'contractMonth': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityOtcPriceModel
     */
    'bidPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityOtcPriceModel
     */
    'askPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityOtcPriceModel
     */
    'bidAskAveragePrice': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityOtcPriceModel
     */
    'previousBidAskAveragePrice': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityOtcPriceModel
     */
    'netChange': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityOtcPriceModel
     */
    'percentChange': number;
    /**
     * 
     * @type {string}
     * @memberof CommodityOtcPriceModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {string}
     * @memberof CommodityOtcPriceModel
     */
    'releaseTime': string;
    /**
     * 
     * @type {Currency}
     * @memberof CommodityOtcPriceModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityOtcPriceModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface CommodityPriceModel
 */
export interface CommodityPriceModel {
    /**
     * 
     * @type {string}
     * @memberof CommodityPriceModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CommodityPriceModel
     */
    'groupDisplayName'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof CommodityPriceModel
     */
    'priceFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof CommodityPriceModel
     */
    'dataFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof CommodityPriceModel
     */
    'dataSource': DemeterDataSource;
    /**
     * 
     * @type {string}
     * @memberof CommodityPriceModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof CommodityPriceModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityPriceModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {number}
     * @memberof CommodityPriceModel
     */
    'previousValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityPriceModel
     */
    'percentChange'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityPriceModel
     */
    'netChange'?: number | null;
    /**
     * 
     * @type {Array<PriceFrequencyDefinition>}
     * @memberof CommodityPriceModel
     */
    'priceFrequencyDefinitions': Array<PriceFrequencyDefinition>;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityPriceModel
     */
    'hasPricingData': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityPriceModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityPriceModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityPriceModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityPriceModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityPriceModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityPriceModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommoditySelection
 */
export interface CommoditySelection {
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommoditySelection
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommoditySelection
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommoditySelection
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof CommoditySelection
     */
    'dataSource'?: DemeterDataSource;
}
/**
 * 
 * @export
 * @interface CommoditySpotPriceHistoricalSummaryModel
 */
export interface CommoditySpotPriceHistoricalSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceHistoricalSummaryModel
     */
    'priceCurrent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceHistoricalSummaryModel
     */
    'priceOneDayAgo'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceHistoricalSummaryModel
     */
    'priceTwoDaysAgo'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceHistoricalSummaryModel
     */
    'priceOneWeekAgo'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceHistoricalSummaryModel
     */
    'priceOneMonthAgo'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceHistoricalSummaryModel
     */
    'priceOneYearAgo'?: number | null;
}
/**
 * 
 * @export
 * @interface CommoditySpotPriceModel
 */
export interface CommoditySpotPriceModel {
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof CommoditySpotPriceModel
     */
    'dataFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceModel
     */
    'sales'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceModel
     */
    'bids'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceModel
     */
    'offers'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CommoditySpotPriceModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CommoditySpotPriceModel
     */
    'groupDisplayName'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof CommoditySpotPriceModel
     */
    'priceFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof CommoditySpotPriceModel
     */
    'dataSource': DemeterDataSource;
    /**
     * 
     * @type {string}
     * @memberof CommoditySpotPriceModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof CommoditySpotPriceModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommoditySpotPriceModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceModel
     */
    'previousValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceModel
     */
    'percentChange'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceModel
     */
    'netChange'?: number | null;
    /**
     * 
     * @type {Array<PriceFrequencyDefinition>}
     * @memberof CommoditySpotPriceModel
     */
    'priceFrequencyDefinitions': Array<PriceFrequencyDefinition>;
    /**
     * 
     * @type {boolean}
     * @memberof CommoditySpotPriceModel
     */
    'hasPricingData': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommoditySpotPriceModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommoditySpotPriceModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommoditySpotPriceModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommoditySpotPriceModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommoditySpotPriceModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommoditySpotPriceSummaryModel
 */
export interface CommoditySpotPriceSummaryModel {
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommoditySpotPriceSummaryModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommoditySpotPriceSummaryModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof CommoditySpotPriceSummaryModel
     */
    'dataSource': DemeterDataSource;
    /**
     * 
     * @type {Currency}
     * @memberof CommoditySpotPriceSummaryModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommoditySpotPriceSummaryModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof CommoditySpotPriceSummaryModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {CommoditySpotPriceModel}
     * @memberof CommoditySpotPriceSummaryModel
     */
    'currentPrice': CommoditySpotPriceModel;
    /**
     * 
     * @type {CommoditySpotPriceWeeklySummaryModel}
     * @memberof CommoditySpotPriceSummaryModel
     */
    'weeklySummary': CommoditySpotPriceWeeklySummaryModel;
    /**
     * 
     * @type {CommoditySpotPriceHistoricalSummaryModel}
     * @memberof CommoditySpotPriceSummaryModel
     */
    'historicalSummary': CommoditySpotPriceHistoricalSummaryModel;
    /**
     * 
     * @type {Array<number>}
     * @memberof CommoditySpotPriceSummaryModel
     */
    'dailyValues': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CommoditySpotPriceSummaryModel
     */
    'weeklyValues': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CommoditySpotPriceSummaryModel
     */
    'monthlyValues': Array<number>;
}
/**
 * 
 * @export
 * @interface CommoditySpotPriceWeeklySummaryModel
 */
export interface CommoditySpotPriceWeeklySummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceWeeklySummaryModel
     */
    'priceMonday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceWeeklySummaryModel
     */
    'priceTuesday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceWeeklySummaryModel
     */
    'priceWednesday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceWeeklySummaryModel
     */
    'priceThursday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceWeeklySummaryModel
     */
    'priceFriday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceWeeklySummaryModel
     */
    'weeklyAverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceWeeklySummaryModel
     */
    'previousWeeklyAverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommoditySpotPriceWeeklySummaryModel
     */
    'sales'?: number | null;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyDomesticModel
 */
export interface CommodityWeeklyDomesticModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyDomesticModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyDomesticModel
     */
    'week': number;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityWeeklyDomesticModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityWeeklyDomesticModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityWeeklyDomesticModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityWeeklyDomesticModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyDomesticModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityWeeklyDomesticModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityWeeklyDomesticModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyDomesticModelSeasonalYearByWeekSummaryModel
 */
export interface CommodityWeeklyDomesticModelSeasonalYearByWeekSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyDomesticModelSeasonalYearByWeekSummaryModel
     */
    'year': number;
    /**
     * 
     * @type {Array<CommodityWeeklyDomesticModel>}
     * @memberof CommodityWeeklyDomesticModelSeasonalYearByWeekSummaryModel
     */
    'weeklyValues': Array<CommodityWeeklyDomesticModel>;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyExportModel
 */
export interface CommodityWeeklyExportModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyExportModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyExportModel
     */
    'week': number;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityWeeklyExportModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityWeeklyExportModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityWeeklyExportModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityWeeklyExportModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyExportModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityWeeklyExportModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityWeeklyExportModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyExportModelSeasonalYearByWeekSummaryModel
 */
export interface CommodityWeeklyExportModelSeasonalYearByWeekSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyExportModelSeasonalYearByWeekSummaryModel
     */
    'year': number;
    /**
     * 
     * @type {Array<CommodityWeeklyExportModel>}
     * @memberof CommodityWeeklyExportModelSeasonalYearByWeekSummaryModel
     */
    'weeklyValues': Array<CommodityWeeklyExportModel>;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyImportModel
 */
export interface CommodityWeeklyImportModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyImportModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyImportModel
     */
    'week': number;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityWeeklyImportModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityWeeklyImportModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityWeeklyImportModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityWeeklyImportModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyImportModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityWeeklyImportModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityWeeklyImportModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyImportModelSeasonalYearByWeekSummaryModel
 */
export interface CommodityWeeklyImportModelSeasonalYearByWeekSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyImportModelSeasonalYearByWeekSummaryModel
     */
    'year': number;
    /**
     * 
     * @type {Array<CommodityWeeklyImportModel>}
     * @memberof CommodityWeeklyImportModelSeasonalYearByWeekSummaryModel
     */
    'weeklyValues': Array<CommodityWeeklyImportModel>;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyPriceHistoricalSummaryModel
 */
export interface CommodityWeeklyPriceHistoricalSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyPriceHistoricalSummaryModel
     */
    'priceCurrentWeek'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyPriceHistoricalSummaryModel
     */
    'priceOneWeekAgo'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyPriceHistoricalSummaryModel
     */
    'priceTwoWeekAgo'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyPriceHistoricalSummaryModel
     */
    'priceOneMonthAgo'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyPriceHistoricalSummaryModel
     */
    'priceTwoMonthAgo'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyPriceHistoricalSummaryModel
     */
    'priceOneYearAgo'?: number | null;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyPriceModel
 */
export interface CommodityWeeklyPriceModel {
    /**
     * 
     * @type {string}
     * @memberof CommodityWeeklyPriceModel
     */
    'commodityWeeklyPriceGuid': string;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof CommodityWeeklyPriceModel
     */
    'dataFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyPriceModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyPriceModel
     */
    'week': number;
    /**
     * 
     * @type {string}
     * @memberof CommodityWeeklyPriceModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CommodityWeeklyPriceModel
     */
    'groupDisplayName'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof CommodityWeeklyPriceModel
     */
    'priceFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof CommodityWeeklyPriceModel
     */
    'dataSource': DemeterDataSource;
    /**
     * 
     * @type {string}
     * @memberof CommodityWeeklyPriceModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof CommodityWeeklyPriceModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityWeeklyPriceModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyPriceModel
     */
    'previousValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyPriceModel
     */
    'percentChange'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyPriceModel
     */
    'netChange'?: number | null;
    /**
     * 
     * @type {Array<PriceFrequencyDefinition>}
     * @memberof CommodityWeeklyPriceModel
     */
    'priceFrequencyDefinitions': Array<PriceFrequencyDefinition>;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityWeeklyPriceModel
     */
    'hasPricingData': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityWeeklyPriceModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityWeeklyPriceModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityWeeklyPriceModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyPriceModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityWeeklyPriceModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityWeeklyPriceModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyPriceSummaryModel
 */
export interface CommodityWeeklyPriceSummaryModel {
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityWeeklyPriceSummaryModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityWeeklyPriceSummaryModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityWeeklyPriceSummaryModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof CommodityWeeklyPriceSummaryModel
     */
    'dataSource': DemeterDataSource;
    /**
     * 
     * @type {Currency}
     * @memberof CommodityWeeklyPriceSummaryModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityWeeklyPriceSummaryModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof CommodityWeeklyPriceSummaryModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {CommodityWeeklyPriceModel}
     * @memberof CommodityWeeklyPriceSummaryModel
     */
    'currentWeekPrice': CommodityWeeklyPriceModel;
    /**
     * 
     * @type {CommodityWeeklyPriceModel}
     * @memberof CommodityWeeklyPriceSummaryModel
     */
    'previousWeekPrice': CommodityWeeklyPriceModel;
    /**
     * 
     * @type {CommodityWeeklyPriceHistoricalSummaryModel}
     * @memberof CommodityWeeklyPriceSummaryModel
     */
    'historicalSummary': CommodityWeeklyPriceHistoricalSummaryModel;
    /**
     * 
     * @type {Array<number>}
     * @memberof CommodityWeeklyPriceSummaryModel
     */
    'weeklyValues': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CommodityWeeklyPriceSummaryModel
     */
    'monthlyValues': Array<number>;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyProductionModel
 */
export interface CommodityWeeklyProductionModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyProductionModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyProductionModel
     */
    'week': number;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityWeeklyProductionModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityWeeklyProductionModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityWeeklyProductionModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityWeeklyProductionModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyProductionModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityWeeklyProductionModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityWeeklyProductionModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyProductionModelSeasonalYearByWeekSummaryModel
 */
export interface CommodityWeeklyProductionModelSeasonalYearByWeekSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyProductionModelSeasonalYearByWeekSummaryModel
     */
    'year': number;
    /**
     * 
     * @type {Array<CommodityWeeklyProductionModel>}
     * @memberof CommodityWeeklyProductionModelSeasonalYearByWeekSummaryModel
     */
    'weeklyValues': Array<CommodityWeeklyProductionModel>;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyStockModel
 */
export interface CommodityWeeklyStockModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyStockModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyStockModel
     */
    'week': number;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityWeeklyStockModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityWeeklyStockModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityWeeklyStockModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityWeeklyStockModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyStockModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityWeeklyStockModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityWeeklyStockModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityWeeklyStockModelSeasonalYearByWeekSummaryModel
 */
export interface CommodityWeeklyStockModelSeasonalYearByWeekSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityWeeklyStockModelSeasonalYearByWeekSummaryModel
     */
    'year': number;
    /**
     * 
     * @type {Array<CommodityWeeklyStockModel>}
     * @memberof CommodityWeeklyStockModelSeasonalYearByWeekSummaryModel
     */
    'weeklyValues': Array<CommodityWeeklyStockModel>;
}
/**
 * 
 * @export
 * @interface CommodityYearlyBalanceByYearModel
 */
export interface CommodityYearlyBalanceByYearModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityYearlyBalanceByYearModel
     */
    'year': number;
    /**
     * 
     * @type {CommodityYearlyBalanceModel}
     * @memberof CommodityYearlyBalanceByYearModel
     */
    'domestic': CommodityYearlyBalanceModel;
    /**
     * 
     * @type {CommodityYearlyBalanceModel}
     * @memberof CommodityYearlyBalanceByYearModel
     */
    'exports': CommodityYearlyBalanceModel;
    /**
     * 
     * @type {CommodityYearlyBalanceModel}
     * @memberof CommodityYearlyBalanceByYearModel
     */
    'stocks': CommodityYearlyBalanceModel;
    /**
     * 
     * @type {CommodityYearlyBalanceModel}
     * @memberof CommodityYearlyBalanceByYearModel
     */
    'stocksCommercial': CommodityYearlyBalanceModel;
    /**
     * 
     * @type {CommodityYearlyBalanceModel}
     * @memberof CommodityYearlyBalanceByYearModel
     */
    'stocksGovernment': CommodityYearlyBalanceModel;
    /**
     * 
     * @type {CommodityYearlyBalanceModel}
     * @memberof CommodityYearlyBalanceByYearModel
     */
    'imports': CommodityYearlyBalanceModel;
    /**
     * 
     * @type {CommodityYearlyBalanceModel}
     * @memberof CommodityYearlyBalanceByYearModel
     */
    'production': CommodityYearlyBalanceModel;
}
/**
 * 
 * @export
 * @interface CommodityYearlyBalanceModel
 */
export interface CommodityYearlyBalanceModel {
    /**
     * 
     * @type {DemeterBalanceType}
     * @memberof CommodityYearlyBalanceModel
     */
    'balanceType': DemeterBalanceType;
    /**
     * 
     * @type {number}
     * @memberof CommodityYearlyBalanceModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof CommodityYearlyBalanceModel
     */
    'yearOverYearChangePercent'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityYearlyBalanceModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityYearlyBalanceModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityYearlyBalanceModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityYearlyBalanceModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityYearlyBalanceModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityYearlyBalanceModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityYearlyBalanceModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface CommodityYearlyPriceModel
 */
export interface CommodityYearlyPriceModel {
    /**
     * 
     * @type {string}
     * @memberof CommodityYearlyPriceModel
     */
    'commodityYearlyPriceGuid': string;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof CommodityYearlyPriceModel
     */
    'dataFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {number}
     * @memberof CommodityYearlyPriceModel
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof CommodityYearlyPriceModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CommodityYearlyPriceModel
     */
    'groupDisplayName'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof CommodityYearlyPriceModel
     */
    'priceFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof CommodityYearlyPriceModel
     */
    'dataSource': DemeterDataSource;
    /**
     * 
     * @type {string}
     * @memberof CommodityYearlyPriceModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof CommodityYearlyPriceModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CommodityYearlyPriceModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {number}
     * @memberof CommodityYearlyPriceModel
     */
    'previousValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityYearlyPriceModel
     */
    'percentChange'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommodityYearlyPriceModel
     */
    'netChange'?: number | null;
    /**
     * 
     * @type {Array<PriceFrequencyDefinition>}
     * @memberof CommodityYearlyPriceModel
     */
    'priceFrequencyDefinitions': Array<PriceFrequencyDefinition>;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityYearlyPriceModel
     */
    'hasPricingData': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof CommodityYearlyPriceModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof CommodityYearlyPriceModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof CommodityYearlyPriceModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof CommodityYearlyPriceModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityYearlyPriceModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityYearlyPriceModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Correlation = {
    None: 'None',
    Negative: 'Negative',
    Positive: 'Positive'
} as const;

export type Correlation = typeof Correlation[keyof typeof Correlation];


/**
 * 
 * @export
 * @interface CoverageTableItemModel
 */
export interface CoverageTableItemModel {
    /**
     * 
     * @type {number}
     * @memberof CoverageTableItemModel
     */
    'price': number;
    /**
     * 
     * @type {Currency}
     * @memberof CoverageTableItemModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CoverageTableItemModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {CoverageTableItemType}
     * @memberof CoverageTableItemModel
     */
    'coverageTableItemType': CoverageTableItemType;
    /**
     * 
     * @type {number}
     * @memberof CoverageTableItemModel
     */
    'percentCoverage': number;
    /**
     * 
     * @type {number}
     * @memberof CoverageTableItemModel
     */
    'colorWeight'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CoverageTableItemType = {
    Neutral: 'Neutral',
    Opportunity: 'Opportunity',
    Risk: 'Risk',
    RiskOverHedged: 'RiskOverHedged'
} as const;

export type CoverageTableItemType = typeof CoverageTableItemType[keyof typeof CoverageTableItemType];


/**
 * 
 * @export
 * @interface CoverageTableLineModel
 */
export interface CoverageTableLineModel {
    /**
     * 
     * @type {number}
     * @memberof CoverageTableLineModel
     */
    'production': number;
    /**
     * 
     * @type {Currency}
     * @memberof CoverageTableLineModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CoverageTableLineModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {Array<CoverageTableItemModel>}
     * @memberof CoverageTableLineModel
     */
    'coverageTableItems': Array<CoverageTableItemModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Currency = {
    Aud: 'AUD',
    Brl: 'BRL',
    Cad: 'CAD',
    Cny: 'CNY',
    Eur: 'EUR',
    Gbp: 'GBP',
    Jpy: 'JPY',
    Krw: 'KRW',
    Mxn: 'MXN',
    Myr: 'MYR',
    Nzd: 'NZD',
    Thb: 'THB',
    Usc: 'USC',
    Usd: 'USD'
} as const;

export type Currency = typeof Currency[keyof typeof Currency];


/**
 * 
 * @export
 * @interface CurrencyConversionRate
 */
export interface CurrencyConversionRate {
    /**
     * 
     * @type {number}
     * @memberof CurrencyConversionRate
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyConversionRate
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyConversionRate
     */
    'conversionRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyConversionRate
     */
    'reverseConversionRate'?: number;
}
/**
 * 
 * @export
 * @interface Data
 */
export interface Data {
    /**
     * 
     * @type {string}
     * @memberof Data
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Data
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Data
     */
    'dateRaw'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Data
     */
    'isActualValue'?: boolean;
}
/**
 * 
 * @export
 * @interface DataSeries
 */
export interface DataSeries {
    /**
     * 
     * @type {string}
     * @memberof DataSeries
     */
    'label'?: string | null;
    /**
     * 
     * @type {Array<Data>}
     * @memberof DataSeries
     */
    'data'?: Array<Data> | null;
}
/**
 * 
 * @export
 * @interface DeleteCalculationEngineInputsRequest
 */
export interface DeleteCalculationEngineInputsRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteCalculationEngineInputsRequest
     */
    'demeterCalculationEngineGuid': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteCalculationEngineInputsRequest
     */
    'demeterCalculationEngineInputsGuid': string;
}
/**
 * 
 * @export
 * @interface DeleteDemeterUserDataRequest
 */
export interface DeleteDemeterUserDataRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteDemeterUserDataRequest
     */
    'demeterUserDataGuid': string;
}
/**
 * 
 * @export
 * @interface DeleteMarketIndicatorFactorRequest
 */
export interface DeleteMarketIndicatorFactorRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteMarketIndicatorFactorRequest
     */
    'marketIndicatorGuid': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteMarketIndicatorFactorRequest
     */
    'marketIndicatorFactorGuid': string;
}
/**
 * 
 * @export
 * @interface DeletePresentationTemplateRequest
 */
export interface DeletePresentationTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof DeletePresentationTemplateRequest
     */
    'presentationTemplateGuid': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterBalanceType = {
    Domestic: 'Domestic',
    Exports: 'Exports',
    Stocks: 'Stocks',
    StocksCommercial: 'StocksCommercial',
    StocksGovernment: 'StocksGovernment',
    Imports: 'Imports',
    Production: 'Production'
} as const;

export type DemeterBalanceType = typeof DemeterBalanceType[keyof typeof DemeterBalanceType];


/**
 * 
 * @export
 * @interface DemeterCalculationEngineInputsGroupModel
 */
export interface DemeterCalculationEngineInputsGroupModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineInputsGroupModel
     */
    'groupName': string;
    /**
     * 
     * @type {Array<DemeterCalculationEngineInputsModel>}
     * @memberof DemeterCalculationEngineInputsGroupModel
     */
    'calculationEngineInputs': Array<DemeterCalculationEngineInputsModel>;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineInputsGroupModel
     */
    'modifiedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineInputsGroupModel
     */
    'modifiedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface DemeterCalculationEngineInputsModel
 */
export interface DemeterCalculationEngineInputsModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'demeterCalculationEngineInputsGuid': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'demeterCalculationEngineGuid': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'showHistory': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'showFutures': boolean;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'endingDate'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {{ [key: string]: CalculationEngineDataOverride; }}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'dataOverrides': { [key: string]: CalculationEngineDataOverride; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'valueOverrides': { [key: string]: number; };
    /**
     * 
     * @type {Array<CalculationEngineMonthlyValueOverrides>}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'monthlyValueOverrides': Array<CalculationEngineMonthlyValueOverrides>;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'modifiedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterCalculationEngineInputsModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface DemeterCalculationEngineLineModel
 */
export interface DemeterCalculationEngineLineModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineLineModel
     */
    'demeterCalculationEngineLineGuid': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineLineModel
     */
    'groupDisplayName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineLineModel
     */
    'subGroupDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineLineModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineLineModel
     */
    'variableName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineLineModel
     */
    'inputGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineLineModel
     */
    'outputGroup'?: string | null;
    /**
     * 
     * @type {DemeterCalculationEngineLineType}
     * @memberof DemeterCalculationEngineLineModel
     */
    'calculationEngineLineType': DemeterCalculationEngineLineType;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineLineModel
     */
    'isGlobal': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineLineModel
     */
    'isEditable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineLineModel
     */
    'isRequired': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineLineModel
     */
    'isOptions': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineLineModel
     */
    'isHidden': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineLineModel
     */
    'isConversion': boolean;
    /**
     * 
     * @type {number}
     * @memberof DemeterCalculationEngineLineModel
     */
    'minimumValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterCalculationEngineLineModel
     */
    'maxmiumValue'?: number | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DemeterCalculationEngineLineModel
     */
    'parameters': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineLineModel
     */
    'lineCalculation': string;
    /**
     * 
     * @type {Currency}
     * @memberof DemeterCalculationEngineLineModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof DemeterCalculationEngineLineModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {number}
     * @memberof DemeterCalculationEngineLineModel
     */
    'order': number;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof DemeterCalculationEngineLineModel
     */
    'market'?: DemeterMarket;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof DemeterCalculationEngineLineModel
     */
    'leadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterCalculationEngineLineModel
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof DemeterCalculationEngineLineModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterCalculationEngineLineModel
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineLineModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof DemeterCalculationEngineLineModel
     */
    'dataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof DemeterCalculationEngineLineModel
     */
    'secondaryMarket'?: DemeterMarket;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof DemeterCalculationEngineLineModel
     */
    'secondaryLeadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterCalculationEngineLineModel
     */
    'secondaryRegion'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof DemeterCalculationEngineLineModel
     */
    'secondarySubRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterCalculationEngineLineModel
     */
    'secondaryCommodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineLineModel
     */
    'secondaryExtraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof DemeterCalculationEngineLineModel
     */
    'secondaryDataFrequency'?: DemeterDataFrequency;
}
/**
 * 
 * @export
 * @interface DemeterCalculationEngineLineShortModel
 */
export interface DemeterCalculationEngineLineShortModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineLineShortModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineLineShortModel
     */
    'variableName': string;
    /**
     * 
     * @type {number}
     * @memberof DemeterCalculationEngineLineShortModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {Currency}
     * @memberof DemeterCalculationEngineLineShortModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof DemeterCalculationEngineLineShortModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterCalculationEngineLineType = {
    Value: 'Value',
    Equation: 'Equation',
    Data: 'Data',
    Custom: 'Custom'
} as const;

export type DemeterCalculationEngineLineType = typeof DemeterCalculationEngineLineType[keyof typeof DemeterCalculationEngineLineType];


/**
 * 
 * @export
 * @interface DemeterCalculationEngineModel
 */
export interface DemeterCalculationEngineModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineModel
     */
    'demeterCalculationEngineGuid': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterCalculationEngineModel
     */
    'displayName': string;
    /**
     * 
     * @type {DemeterCalculatorType}
     * @memberof DemeterCalculationEngineModel
     */
    'calculatorType': DemeterCalculatorType;
    /**
     * 
     * @type {Array<DemeterCalculationEngineLineModel>}
     * @memberof DemeterCalculationEngineModel
     */
    'calculationEngineLines': Array<DemeterCalculationEngineLineModel>;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineModel
     */
    'hasCurrencyConversion': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineModel
     */
    'hasUnitOfMeasureConversion': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineModel
     */
    'isPublished': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineModel
     */
    'hasFutures': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCalculationEngineModel
     */
    'hasHistory': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterCalculatorType = {
    Basis: 'Basis',
    Budget: 'Budget',
    FarmerMargin: 'FarmerMargin',
    FederalMilkMarketingOrder: 'FederalMilkMarketingOrder',
    Positions: 'Positions',
    Valorization: 'Valorization',
    ValueMatrix: 'ValueMatrix'
} as const;

export type DemeterCalculatorType = typeof DemeterCalculatorType[keyof typeof DemeterCalculatorType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterChartType = {
    Futures: 'Futures',
    Price: 'Price',
    Summary: 'Summary',
    Seasonal: 'Seasonal',
    Value: 'Value'
} as const;

export type DemeterChartType = typeof DemeterChartType[keyof typeof DemeterChartType];


/**
 * 
 * @export
 * @interface DemeterCommentaryModel
 */
export interface DemeterCommentaryModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterCommentaryModel
     */
    'demeterCommentaryGuid': string;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterCommentaryModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterCommentaryModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof DemeterCommentaryModel
     */
    'content': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterCommodity = {
    All: 'All',
    AlfalfaHay: 'AlfalfaHay',
    Aluminum: 'Aluminum',
    AnhydrousMilkFat: 'AnhydrousMilkFat',
    AnhydrousMilkFatPremium210Kilograms: 'AnhydrousMilkFatPremium210Kilograms',
    AnhydrousMilkFatRegular210Kilograms: 'AnhydrousMilkFatRegular210Kilograms',
    AnhydrousMilkFatPremium1000Kilograms: 'AnhydrousMilkFatPremium1000Kilograms',
    Aggregated: 'Aggregated',
    CheeseBlue: 'CheeseBlue',
    BalticDryIndex: 'BalticDryIndex',
    Beef: 'Beef',
    BeefChoice: 'BeefChoice',
    BeefSelect: 'BeefSelect',
    Butter: 'Butter',
    ButterAndButterBlends: 'ButterAndButterBlends',
    ButterAndAnhydrousMilkFat: 'ButterAndAnhydrousMilkFat',
    ButterBlends: 'ButterBlends',
    Butterfat: 'Butterfat',
    ButterfatClass2: 'ButterfatClass2',
    ButterfatClass3: 'ButterfatClass3',
    ButterMilk: 'ButterMilk',
    ButterMilkPowder: 'ButterMilkPowder',
    ButterMilkPowderUltraHeatTreated: 'ButterMilkPowderUltraHeatTreated',
    ButterOil: 'ButterOil',
    ButterSalted: 'ButterSalted',
    ButterSkimMilkPowderMilk: 'ButterSkimMilkPowderMilk',
    ButterUnsalted: 'ButterUnsalted',
    Canola: 'Canola',
    Casein: 'Casein',
    CaseinAcid: 'CaseinAcid',
    Caseinates: 'Caseinates',
    Cattle: 'Cattle',
    CattleFeeder: 'CattleFeeder',
    CattleHeiferAll: 'CattleHeiferAll',
    CattleHeiferOver80Percent: 'CattleHeiferOver80Percent',
    CattleLive: 'CattleLive',
    CattleSteerAll: 'CattleSteerAll',
    CattleSteerOver80Percent: 'CattleSteerOver80Percent',
    CattleWeight: 'CattleWeight',
    Cheddar: 'Cheddar',
    CheddarCurd: 'CheddarCurd',
    CheddarMild: 'CheddarMild',
    CheddarFrozen: 'CheddarFrozen',
    Cheese: 'Cheese',
    CheeseAmerican: 'CheeseAmerican',
    CheeseBarrel: 'CheeseBarrel',
    CheeseBlock: 'CheeseBlock',
    CheeseBlockBarrelSpread: 'CheeseBlockBarrelSpread',
    CheeseCream: 'CheeseCream',
    CheeseFresh: 'CheeseFresh',
    CheeseGratedOrPowdered: 'CheeseGratedOrPowdered',
    CheeseItalian: 'CheeseItalian',
    CheeseItalianOther: 'CheeseItalianOther',
    CheeseHispanic: 'CheeseHispanic',
    CheeseMilk: 'CheeseMilk',
    CheeseMuenster: 'CheeseMuenster',
    CheeseOther: 'CheeseOther',
    CheeseProcessed: 'CheeseProcessed',
    ChicagoWheat: 'ChicagoWheat',
    Coal: 'Coal',
    Cocoa: 'Cocoa',
    CocoaLondon: 'CocoaLondon',
    CoffeeArabica: 'CoffeeArabica',
    CoffeeRobusta: 'CoffeeRobusta',
    ColbyJack: 'ColbyJack',
    CondensedMilkUnsweetened: 'CondensedMilkUnsweetened',
    CondensedMilkSweetened: 'CondensedMilkSweetened',
    CondensedSkimUnsweetened: 'CondensedSkimUnsweetened',
    Corn: 'Corn',
    Cotton: 'Cotton',
    CottageCheese: 'CottageCheese',
    CottageCreamed: 'CottageCreamed',
    CottageLowFat: 'CottageLowFat',
    CowDairy: 'CowDairy',
    CowProduction: 'CowProduction',
    CowSlaughtered: 'CowSlaughtered',
    Cream: 'Cream',
    CreamClass2: 'CreamClass2',
    CrudeOil: 'CrudeOil',
    CrudeOilBrent: 'CrudeOilBrent',
    CrudeOilWti: 'CrudeOilWti',
    CurrencyAudToUsd: 'CurrencyAudToUsd',
    CurrencyCadToUsd: 'CurrencyCadToUsd',
    CurrencyBrlToUsd: 'CurrencyBrlToUsd',
    CurrencyCnyToUsd: 'CurrencyCnyToUsd',
    CurrencyEurToGbp: 'CurrencyEurToGbp',
    CurrencyEurToUsd: 'CurrencyEurToUsd',
    CurrencyGbpToUsd: 'CurrencyGbpToUsd',
    CurrencyJpyToUsd: 'CurrencyJpyToUsd',
    CurrencyKrwToUsd: 'CurrencyKrwToUsd',
    CurrencyMxnToUsd: 'CurrencyMxnToUsd',
    CurrencyMyrToUsd: 'CurrencyMyrToUsd',
    CurrencyNzdToUsd: 'CurrencyNzdToUsd',
    CurrencyThbToUsd: 'CurrencyThbToUsd',
    CurrencyUsdToCny: 'CurrencyUsdToCny',
    CurrencyYenToUsd: 'CurrencyYenToUsd',
    Diesel: 'Diesel',
    DieselTax: 'DieselTax',
    DollarIndex: 'DollarIndex',
    DowJonesAsianTitansIndex: 'DowJonesAsianTitansIndex',
    DowJonesIndustrialIndex: 'DowJonesIndustrialIndex',
    DryButtermilk: 'DryButtermilk',
    DryWhey: 'DryWhey',
    DryWheyAndWheyProteinConcentrate: 'DryWheyAndWheyProteinConcentrate',
    DryWheyAnimal: 'DryWheyAnimal',
    DryWheyHuman: 'DryWheyHuman',
    Electricity: 'Electricity',
    EmergingMarketsIndex: 'EmergingMarketsIndex',
    Ethanol: 'Ethanol',
    CarbonCredit: 'CarbonCredit',
    Fat: 'Fat',
    FatFilledMilkPowder: 'FatFilledMilkPowder',
    Feed: 'Feed',
    FeedCost: 'FeedCost',
    FeedMargin: 'FeedMargin',
    Feta: 'Feta',
    FluidMilk: 'FluidMilk',
    FlavoredFatReduced: 'FlavoredFatReduced',
    Fmmo: 'Fmmo',
    FrozenYogurt: 'FrozenYogurt',
    FrozenYogurtLowFat: 'FrozenYogurtLowFat',
    FrozenYogurtMix: 'FrozenYogurtMix',
    FrozenYogurtNonfat: 'FrozenYogurtNonfat',
    FuelOil: 'FuelOil',
    Gasoline: 'Gasoline',
    GasolineAndDieselOil: 'GasolineAndDieselOil',
    GasolineMotorAndAviation: 'GasolineMotorAndAviation',
    Gasoil: 'Gasoil',
    GlobalEquitiesIndex: 'GlobalEquitiesIndex',
    Gouda: 'Gouda',
    HeadlineProduction: 'HeadlineProduction',
    Hog: 'Hog',
    HogCarcass: 'HogCarcass',
    HogWeight: 'HogWeight',
    HogSlaughtered: 'HogSlaughtered',
    IceCream: 'IceCream',
    IceCreamNonfat: 'IceCreamNonfat',
    IceCreamLowFatHard: 'IceCreamLowFatHard',
    IceCreamLowFatSoft: 'IceCreamLowFatSoft',
    IceCreamMix: 'IceCreamMix',
    IceCreamMixLowFat: 'IceCreamMixLowFat',
    IceCreamMixNonfat: 'IceCreamMixNonfat',
    Index: 'Index',
    InfantFormula: 'InfantFormula',
    Kerosene: 'Kerosene',
    KeroseneJetFuel: 'KeroseneJetFuel',
    KcWheat: 'KcWheat',
    Lactose: 'Lactose',
    LactoseM100: 'LactoseM100',
    LactoseM200: 'LactoseM200',
    LeanHog: 'LeanHog',
    LiquefiedPetroleumGases: 'LiquefiedPetroleumGases',
    Maize: 'Maize',
    Milk: 'Milk',
    MilkClass1: 'MilkClass1',
    MilkClass2: 'MilkClass2',
    MilkClass3: 'MilkClass3',
    MilkClass4: 'MilkClass4',
    MilkComponents: 'MilkComponents',
    MilkConventional: 'MilkConventional',
    MilkConventionalOther: 'MilkConventionalOther',
    MilkEquivalent: 'MilkEquivalent',
    MilkFatLessThanOnePercent: 'MilkFatLessThanOnePercent',
    MilkFatOneToSixPercent: 'MilkFatOneToSixPercent',
    MilkFatSixToTenPercent: 'MilkFatSixToTenPercent',
    MilkFatGreaterThanTenPercent: 'MilkFatGreaterThanTenPercent',
    MilkOnePercent: 'MilkOnePercent',
    MilkOrganic: 'MilkOrganic',
    MilkOrganicFlavored: 'MilkOrganicFlavored',
    MilkOrganicOnePercent: 'MilkOrganicOnePercent',
    MilkOrganicOther: 'MilkOrganicOther',
    MilkOrganicSkim: 'MilkOrganicSkim',
    MilkOrganicTwoPercent: 'MilkOrganicTwoPercent',
    MilkOrganicWhole: 'MilkOrganicWhole',
    MilkProduction: 'MilkProduction',
    MilkProteinConcentrate: 'MilkProteinConcentrate',
    MilkProteinConcentrate40: 'MilkProteinConcentrate40',
    MilkProteinConcentrate70: 'MilkProteinConcentrate70',
    MilkProteinConcentrate90: 'MilkProteinConcentrate90',
    MilkReplacer: 'MilkReplacer',
    MilkSkim: 'MilkSkim',
    MilkSolids: 'MilkSolids',
    MilkTwoPercent: 'MilkTwoPercent',
    MilkWhole: 'MilkWhole',
    MilkWholeFlavored: 'MilkWholeFlavored',
    MinneapolisWheat: 'MinneapolisWheat',
    Mozzarella: 'Mozzarella',
    Muenster: 'Muenster',
    Naphtha: 'Naphtha',
    NasdaqCompositeIndex: 'NasdaqCompositeIndex',
    NaturalGas: 'NaturalGas',
    NaturalGasLiquids: 'NaturalGasLiquids',
    NonFatDryMilk: 'NonFatDryMilk',
    NonFatDryMilkAndSkimMilkPowder: 'NonFatDryMilkAndSkimMilkPowder',
    NonFatDryMilkHighHeat: 'NonFatDryMilkHighHeat',
    NonFatSolids: 'NonFatSolids',
    NonFatSolidsClass2: 'NonFatSolidsClass2',
    Oat: 'Oat',
    Other: 'Other',
    OtherEnergy: 'OtherEnergy',
    OtherFrozenProducts: 'OtherFrozenProducts',
    OtherOil: 'OtherOil',
    OtherSolids: 'OtherSolids',
    PalmKernelOil: 'PalmKernelOil',
    PalmOil: 'PalmOil',
    Parmesan: 'Parmesan',
    Pork: 'Pork',
    PorkCutout: 'PorkCutout',
    PorkProducerPriceIndex: 'PorkProducerPriceIndex',
    PorkWeightedCuts: 'PorkWeightedCuts',
    ProducerPriceIndex: 'ProducerPriceIndex',
    Protein: 'Protein',
    Provolone: 'Provolone',
    Rapeseed: 'Rapeseed',
    RapeseedOil: 'RapeseedOil',
    Rice: 'Rice',
    Ricotta: 'Ricotta',
    Romano: 'Romano',
    StandardAndPoors500Index: 'StandardAndPoors500Index',
    StandardAndPoorsEurope350Index: 'StandardAndPoorsEurope350Index',
    Sherbert: 'Sherbert',
    SherbertMix: 'SherbertMix',
    SkimMilkClass1: 'SkimMilkClass1',
    SkimMilkClass2: 'SkimMilkClass2',
    SkimMilkClass3: 'SkimMilkClass3',
    SkimMilkClass4: 'SkimMilkClass4',
    SkimMilkPowder: 'SkimMilkPowder',
    SkimMilkPowderLow: 'SkimMilkPowderLow',
    SkimMilkPowderMedium: 'SkimMilkPowderMedium',
    SkimMilkPowderUltraHeatTreated: 'SkimMilkPowderUltraHeatTreated',
    SourCream: 'SourCream',
    Soybean: 'Soybean',
    SoybeanCrushMargin: 'SoybeanCrushMargin',
    SoybeanMeal: 'SoybeanMeal',
    SoybeanOil: 'SoybeanOil',
    Sugar: 'Sugar',
    Sugar11: 'Sugar11',
    Sugar16: 'Sugar16',
    Sugar5: 'Sugar5',
    SugarRaw: 'SugarRaw',
    SunflowerOil: 'SunflowerOil',
    Swiss: 'Swiss',
    VegetableOil: 'VegetableOil',
    UserData: 'UserData',
    WaterIces: 'WaterIces',
    WheatDurum: 'WheatDurum',
    WheatLiffe: 'WheatLiffe',
    WheatMilling: 'WheatMilling',
    Weather: 'Weather',
    Wheat: 'Wheat',
    WheatSpring: 'WheatSpring',
    WheatWinter: 'WheatWinter',
    Whey: 'Whey',
    WheyProteinConcentrate: 'WheyProteinConcentrate',
    WheyProteinConcentrateHighProtein: 'WheyProteinConcentrateHighProtein',
    WheyProteinConcentrateLowProtein: 'WheyProteinConcentrateLowProtein',
    WheyProteinIsolate: 'WheyProteinIsolate',
    WheyReducedMineral: 'WheyReducedMineral',
    WheyReducedMineralAnimal: 'WheyReducedMineralAnimal',
    WheyReducedMineralHuman: 'WheyReducedMineralHuman',
    WheySweetPowder: 'WheySweetPowder',
    WholeMilkPowder: 'WholeMilkPowder',
    WholeMilkPowderInstant: 'WholeMilkPowderInstant',
    WholeMilkPowderSweetened: 'WholeMilkPowderSweetened',
    WholeMilkPowderUnsweetened: 'WholeMilkPowderUnsweetened',
    WholeMilkPowderUltraHeatTreated: 'WholeMilkPowderUltraHeatTreated',
    Wind: 'Wind',
    Yogurt: 'Yogurt'
} as const;

export type DemeterCommodity = typeof DemeterCommodity[keyof typeof DemeterCommodity];


/**
 * 
 * @export
 * @interface DemeterCompanyAdministratorModel
 */
export interface DemeterCompanyAdministratorModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyAdministratorModel
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyAdministratorModel
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyAdministratorModel
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface DemeterCompanyModel
 */
export interface DemeterCompanyModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'demeterCompanyGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'invoiceDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'createdDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterCompanyModel
     */
    'subscriptionPrice'?: number | null;
    /**
     * 
     * @type {DemeterSubscriptionFrequency}
     * @memberof DemeterCompanyModel
     */
    'subscriptionFrequency'?: DemeterSubscriptionFrequency;
    /**
     * 
     * @type {Currency}
     * @memberof DemeterCompanyModel
     */
    'billingCurrency'?: Currency;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterCompanyModel
     */
    'billingRegion'?: DemeterRegion;
    /**
     * 
     * @type {number}
     * @memberof DemeterCompanyModel
     */
    'numberOfLicensesUsed': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterCompanyModel
     */
    'numberOfLicenses': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterCompanyModel
     */
    'numberOfUsers': number;
    /**
     * 
     * @type {Array<DemeterCompanyAdministratorModel>}
     * @memberof DemeterCompanyModel
     */
    'companyAdministrators': Array<DemeterCompanyAdministratorModel>;
    /**
     * 
     * @type {Array<DemeterMarket>}
     * @memberof DemeterCompanyModel
     */
    'markets': Array<DemeterMarket>;
    /**
     * 
     * @type {Array<DemeterMarketLicenseModel>}
     * @memberof DemeterCompanyModel
     */
    'marketLicenses': Array<DemeterMarketLicenseModel>;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'contactPerson'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCompanyModel
     */
    'termsAndConditionsAccepted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'termsAndConditionsAcceptedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'billingContactName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'billingCompanyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'billingAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'billingAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'billingCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'billingState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'billingCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'billingPostalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'billingPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'billingFax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'billingEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'billingVatNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCompanyModel
     */
    'billingSameAsCompanyInformation'?: boolean | null;
    /**
     * 
     * @type {DemeterPaymentType}
     * @memberof DemeterCompanyModel
     */
    'paymentType'?: DemeterPaymentType;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterCompanyModel
     */
    'futuresAccountTransferConsent'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'futuresAccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'futuresAccountCustomerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'futuresAccountTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterCompanyModel
     */
    'policyNumbers'?: string | null;
}
/**
 * 
 * @export
 * @interface DemeterDashboardPresetChartModel
 */
export interface DemeterDashboardPresetChartModel {
    /**
     * 
     * @type {DemeterTableDefinitionType}
     * @memberof DemeterDashboardPresetChartModel
     */
    'tableDefinitionType': DemeterTableDefinitionType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterDashboardPresetChartModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {string}
     * @memberof DemeterDashboardPresetChartModel
     */
    'regionDisplayName'?: string | null;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof DemeterDashboardPresetChartModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterDashboardPresetChartModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof DemeterDashboardPresetChartModel
     */
    'commodityDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterDashboardPresetChartModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {DemeterChartType}
     * @memberof DemeterDashboardPresetChartModel
     */
    'chartType': DemeterChartType;
    /**
     * 
     * @type {string}
     * @memberof DemeterDashboardPresetChartModel
     */
    'reutersInstrumentCodePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterDashboardPresetChartModel
     */
    'reutersInstrumentCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterDashboardPresetChartModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface DemeterDashboardPresetModel
 */
export interface DemeterDashboardPresetModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof DemeterDashboardPresetModel
     */
    'demeterLeadingIndicatorGuids': Array<string>;
    /**
     * 
     * @type {Array<DemeterDashboardPresetChartModel>}
     * @memberof DemeterDashboardPresetModel
     */
    'dashboardPresetsCharts': Array<DemeterDashboardPresetChartModel>;
    /**
     * 
     * @type {Currency}
     * @memberof DemeterDashboardPresetModel
     */
    'currency': Currency;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterDataFrequency = {
    Streaming: 'Streaming',
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
} as const;

export type DemeterDataFrequency = typeof DemeterDataFrequency[keyof typeof DemeterDataFrequency];


/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterDataSource = {
    All: 'All',
    AllForecast: 'AllForecast',
    Advanced: 'Advanced',
    Aggregated: 'Aggregated',
    AnnualProjection: 'AnnualProjection',
    AnnualProjectionWithExports: 'AnnualProjectionWithExports',
    Arla: 'Arla',
    Basis: 'Basis',
    Ble: 'BLE',
    Chicago: 'Chicago',
    Cme: 'CME',
    ColdStorage: 'ColdStorage',
    Commercial: 'Commercial',
    CropCondition: 'CropCondition',
    CropPlanted: 'CropPlanted',
    CropHarvested: 'CropHarvested',
    DaysOfSupply: 'DaysOfSupply',
    Dce: 'DCE',
    Eex: 'EEX',
    EexForecast: 'EEXForecast',
    Euronext: 'Euronext',
    Farm: 'Farm',
    Feed: 'Feed',
    FeedLot: 'FeedLot',
    Fmmo: 'FMMO',
    Fonterra: 'Fonterra',
    FonterraContract1: 'FonterraContract1',
    FonterraContract2: 'FonterraContract2',
    FonterraContract3: 'FonterraContract3',
    FonterraContract4: 'FonterraContract4',
    FonterraContract5: 'FonterraContract5',
    FonterraContract6: 'FonterraContract6',
    FreeOnBoard: 'FreeOnBoard',
    GlobalDairyTrade: 'GlobalDairyTrade',
    Government: 'Government',
    HenryHub: 'HenryHub',
    Ice: 'ICE',
    IncludingExports: 'IncludingExports',
    Index: 'Index',
    InspectionsNet: 'InspectionsNet',
    InspectionsCumulative: 'InspectionsCumulative',
    InspectionsPaceNeeded: 'InspectionsPaceNeeded',
    Ndpsr: 'NDPSR',
    NdpsrForecast: 'NDPSRForecast',
    NorthernVentura: 'NorthernVentura',
    PacificGasAndElectric: 'PacificGasAndElectric',
    PowerBurn: 'PowerBurn',
    Refinery: 'Refinery',
    SalesNetCurrentMarketingYear: 'SalesNetCurrentMarketingYear',
    SalesNetNextMarketingYear: 'SalesNetNextMarketingYear',
    SalesProgressCumulative: 'SalesProgressCumulative',
    SalesProgressPaceNeeded: 'SalesProgressPaceNeeded',
    Sgx: 'SGX',
    SoCal: 'SoCal',
    StoneX: 'StoneX',
    StoneXCalculations: 'StoneXCalculations',
    TotalUse: 'TotalUse',
    TranscoZone6: 'TranscoZone6',
    Usda: 'USDA'
} as const;

export type DemeterDataSource = typeof DemeterDataSource[keyof typeof DemeterDataSource];


/**
 * 
 * @export
 * @interface DemeterDataValue
 */
export interface DemeterDataValue {
    /**
     * 
     * @type {string}
     * @memberof DemeterDataValue
     */
    'asOfDate': string;
    /**
     * 
     * @type {number}
     * @memberof DemeterDataValue
     */
    'value': number;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterDataValue
     */
    'isActualValue': boolean;
}
/**
 * 
 * @export
 * @interface DemeterDataValueWithContractModel
 */
export interface DemeterDataValueWithContractModel {
    /**
     * 
     * @type {number}
     * @memberof DemeterDataValueWithContractModel
     */
    'contractMonth': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterDataValueWithContractModel
     */
    'contractYear': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterDataValueWithContractModel
     */
    'latestProducerPriceIndex': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterDataValueWithContractModel
     */
    'currentProducerPriceIndex': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterDataValueWithContractModel
     */
    'adjustedValueWithProducerPriceIndex': number;
    /**
     * 
     * @type {string}
     * @memberof DemeterDataValueWithContractModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {number}
     * @memberof DemeterDataValueWithContractModel
     */
    'value': number;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterDataValueWithContractModel
     */
    'isActualValue': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterFeatureType = {
    Administration: 'Administration',
    BasisCalculator: 'BasisCalculator',
    BudgetCalculator: 'BudgetCalculator',
    Companies: 'Companies',
    DownloadsExports: 'DownloadsExports',
    DownloadsGeneral: 'DownloadsGeneral',
    DownloadsImports: 'DownloadsImports',
    FarmerMarginCalculator: 'FarmerMarginCalculator',
    HedgeMonitorPro: 'HedgeMonitorPro',
    Markets: 'Markets',
    MarketIndicators: 'MarketIndicators',
    PositionsCalculator: 'PositionsCalculator',
    PresentationTemplates: 'PresentationTemplates',
    Refactors: 'Refactors',
    UserData: 'UserData',
    Users: 'Users',
    Valorization: 'Valorization',
    ValueMatrix: 'ValueMatrix'
} as const;

export type DemeterFeatureType = typeof DemeterFeatureType[keyof typeof DemeterFeatureType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterFilterTimeSpan = {
    LastTwoActuals: 'LastTwoActuals',
    OneDay: 'OneDay',
    ThirtyDays: 'ThirtyDays',
    OneMonth: 'OneMonth',
    ThreeMonths: 'ThreeMonths',
    SixMonths: 'SixMonths',
    NineMonths: 'NineMonths',
    OneYear: 'OneYear',
    TwoYears: 'TwoYears',
    ThreeYears: 'ThreeYears',
    FourYears: 'FourYears',
    FiveYears: 'FiveYears',
    TenYears: 'TenYears',
    FifteenYears: 'FifteenYears',
    TwentyYears: 'TwentyYears',
    All: 'All',
    YearToDate: 'YearToDate'
} as const;

export type DemeterFilterTimeSpan = typeof DemeterFilterTimeSpan[keyof typeof DemeterFilterTimeSpan];


/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterFundamental = {
    Balances: 'Balances',
    Domestics: 'Domestics',
    Exports: 'Exports',
    Imports: 'Imports',
    Indexes: 'Indexes',
    Prices: 'Prices',
    Production: 'Production',
    Progress: 'Progress',
    Other: 'Other',
    Stocks: 'Stocks'
} as const;

export type DemeterFundamental = typeof DemeterFundamental[keyof typeof DemeterFundamental];


/**
 * 
 * @export
 * @interface DemeterFundamentalDataModel
 */
export interface DemeterFundamentalDataModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterFundamentalDataModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {number}
     * @memberof DemeterFundamentalDataModel
     */
    'value': number;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterFundamentalDataModel
     */
    'isActualValue': boolean;
}
/**
 * 
 * @export
 * @interface DemeterInsurancePositionModel
 */
export interface DemeterInsurancePositionModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterInsurancePositionModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterInsurancePositionModel
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterInsurancePositionModel
     */
    'policyNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterInsurancePositionModel
     */
    'state': string;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterInsurancePositionModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof DemeterInsurancePositionModel
     */
    'weightClass'?: number | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof DemeterInsurancePositionModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {Currency}
     * @memberof DemeterInsurancePositionModel
     */
    'currency': Currency;
    /**
     * 
     * @type {number}
     * @memberof DemeterInsurancePositionModel
     */
    'triggerPrice': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterInsurancePositionModel
     */
    'premiumPrice': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterInsurancePositionModel
     */
    'netCoveragePrice': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterInsurancePositionModel
     */
    'netCoveredVolume': number;
    /**
     * 
     * @type {string}
     * @memberof DemeterInsurancePositionModel
     */
    'endorsementDate': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterInsurancePositionModel
     */
    'coveragePeriodDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterInsurancePositionModel
     */
    'coverageQuarter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterInsurancePositionModel
     */
    'coverageStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterInsurancePositionModel
     */
    'coverageEndDate': string;
}
/**
 * 
 * @export
 * @interface DemeterLeadingIndicatorModel
 */
export interface DemeterLeadingIndicatorModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterLeadingIndicatorModel
     */
    'demeterLeadingIndicatorGuid': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterLeadingIndicatorModel
     */
    'displayName': string;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof DemeterLeadingIndicatorModel
     */
    'leadingIndicatorType': LeadingIndicatorType;
    /**
     * 
     * @type {string}
     * @memberof DemeterLeadingIndicatorModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterLeadingIndicatorModel
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof DemeterLeadingIndicatorModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterLeadingIndicatorModel
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof DemeterLeadingIndicatorModel
     */
    'dataSource'?: DemeterDataSource;
    /**
     * 
     * @type {string}
     * @memberof DemeterLeadingIndicatorModel
     */
    'reutersInstrumentCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterLeadingIndicatorModel
     */
    'priceMultiplierFutures'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterLeadingIndicatorModel
     */
    'currentValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterLeadingIndicatorModel
     */
    'previousValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterLeadingIndicatorModel
     */
    'percentChange'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterLeadingIndicatorModel
     */
    'order': number;
    /**
     * 
     * @type {Currency}
     * @memberof DemeterLeadingIndicatorModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof DemeterLeadingIndicatorModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {number}
     * @memberof DemeterLeadingIndicatorModel
     */
    'displayDecimalPlacesMinimum': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterLeadingIndicatorModel
     */
    'displayDecimalPlacesMaximum': number;
    /**
     * 
     * @type {DemeterTableDefinitionType}
     * @memberof DemeterLeadingIndicatorModel
     */
    'tableDefinitionType': DemeterTableDefinitionType;
    /**
     * 
     * @type {DemeterChartType}
     * @memberof DemeterLeadingIndicatorModel
     */
    'chartType': DemeterChartType;
    /**
     * 
     * @type {string}
     * @memberof DemeterLeadingIndicatorModel
     */
    'dataSourceTag': string;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof DemeterLeadingIndicatorModel
     */
    'priceFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof DemeterLeadingIndicatorModel
     */
    'dataFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {string}
     * @memberof DemeterLeadingIndicatorModel
     */
    'asOfDate'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterMarket = {
    All: 'All',
    Currency: 'Currency',
    Dairy: 'Dairy',
    Energy: 'Energy',
    Grains: 'Grains',
    Livestock: 'Livestock',
    Other: 'Other',
    Softs: 'Softs'
} as const;

export type DemeterMarket = typeof DemeterMarket[keyof typeof DemeterMarket];


/**
 * 
 * @export
 * @interface DemeterMarketLicenseModel
 */
export interface DemeterMarketLicenseModel {
    /**
     * 
     * @type {DemeterMarket}
     * @memberof DemeterMarketLicenseModel
     */
    'market': DemeterMarket;
    /**
     * 
     * @type {number}
     * @memberof DemeterMarketLicenseModel
     */
    'numberOfLicenses': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterMarketLicenseModel
     */
    'numberOfLicensesUsed': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterMarketNewsType = {
    All: 'All',
    Headline: 'Headline',
    Reports: 'Reports'
} as const;

export type DemeterMarketNewsType = typeof DemeterMarketNewsType[keyof typeof DemeterMarketNewsType];


/**
 * 
 * @export
 * @interface DemeterNewsModel
 */
export interface DemeterNewsModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterNewsModel
     */
    'articleId': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterNewsModel
     */
    'authorName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterNewsModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterNewsModel
     */
    'abstract': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterNewsModel
     */
    'publishedDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterNewsModel
     */
    'isShortForm': boolean;
    /**
     * 
     * @type {string}
     * @memberof DemeterNewsModel
     */
    'heroImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterNewsModel
     */
    'languageCode'?: string | null;
}
/**
 * 
 * @export
 * @interface DemeterPageElementModel
 */
export interface DemeterPageElementModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterPageElementModel
     */
    'demeterPageElementGuid': string;
    /**
     * 
     * @type {DemeterPageType}
     * @memberof DemeterPageElementModel
     */
    'pageType'?: DemeterPageType;
    /**
     * 
     * @type {string}
     * @memberof DemeterPageElementModel
     */
    'pageUrl'?: string | null;
    /**
     * 
     * @type {DemeterPageElementType}
     * @memberof DemeterPageElementModel
     */
    'pageElementType'?: DemeterPageElementType;
    /**
     * 
     * @type {number}
     * @memberof DemeterPageElementModel
     */
    'order'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterPageElementModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof DemeterPageElementModel
     */
    'market'?: DemeterMarket;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterPageElementModel
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof DemeterPageElementModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterPageElementModel
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {Currency}
     * @memberof DemeterPageElementModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof DemeterPageElementModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof DemeterPageElementModel
     */
    'dataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DemeterPageElementModel
     */
    'extraParameters': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof DemeterPageElementModel
     */
    'entityGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterPageElementModel
     */
    'secondaryEntityGuid'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DemeterPageElementModel
     */
    'options': { [key: string]: string; };
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterPageElementType = {
    MarginChart: 'MarginChart',
    ProjectionChart: 'ProjectionChart',
    SeasonalChart: 'SeasonalChart'
} as const;

export type DemeterPageElementType = typeof DemeterPageElementType[keyof typeof DemeterPageElementType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterPageType = {
    Balance: 'Balance',
    Domestics: 'Domestics',
    Exports: 'Exports',
    Futures: 'Futures',
    Imports: 'Imports',
    Production: 'Production',
    Stocks: 'Stocks'
} as const;

export type DemeterPageType = typeof DemeterPageType[keyof typeof DemeterPageType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterPaymentType = {
    WireTransfer: 'WireTransfer',
    DeductionFromFuturesAccount: 'DeductionFromFuturesAccount'
} as const;

export type DemeterPaymentType = typeof DemeterPaymentType[keyof typeof DemeterPaymentType];


/**
 * 
 * @export
 * @interface DemeterPermissionModel
 */
export interface DemeterPermissionModel {
    /**
     * 
     * @type {DemeterPermissionType}
     * @memberof DemeterPermissionModel
     */
    'permissionType': DemeterPermissionType;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterPermissionModel
     */
    'hasPermission': boolean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DemeterPermissionModel
     */
    'subPermissions': { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterPermissionType = {
    BudgetCalculator: 'BudgetCalculator',
    HedgeMonitorPro: 'HedgeMonitorPro',
    HedgeMonitorProAdministrator: 'HedgeMonitorProAdministrator',
    FarmerMarginAdministrator: 'FarmerMarginAdministrator',
    FarmerMarginCalculator: 'FarmerMarginCalculator',
    MarketIndicators: 'MarketIndicators',
    MarketIndicatorAdministrator: 'MarketIndicatorAdministrator',
    MarketIndicatorCompliance: 'MarketIndicatorCompliance',
    MarketIndicatorDeveloper: 'MarketIndicatorDeveloper',
    MarketIndicatorSubjectMatterExpert: 'MarketIndicatorSubjectMatterExpert',
    PositionsCalculator: 'PositionsCalculator',
    Users: 'Users',
    UsersExport: 'UsersExport',
    UsersManagement: 'UsersManagement',
    Valorization: 'Valorization',
    ValueMatrixAdvanced: 'ValueMatrixAdvanced'
} as const;

export type DemeterPermissionType = typeof DemeterPermissionType[keyof typeof DemeterPermissionType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterRegion = {
    All: 'All',
    Afghanistan: 'Afghanistan',
    Africa: 'Africa',
    AfricaOther: 'AfricaOther',
    Albania: 'Albania',
    Algeria: 'Algeria',
    Aggregate: 'Aggregate',
    Aggregated: 'Aggregated',
    America: 'America',
    AmericanSamoa: 'AmericanSamoa',
    Andorra: 'Andorra',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antarctica',
    AntiguaAndBarbuda: 'AntiguaAndBarbuda',
    Argentina: 'Argentina',
    Armenia: 'Armenia',
    Aruba: 'Aruba',
    Asia: 'Asia',
    AsiaPacific: 'AsiaPacific',
    AsiaOther: 'AsiaOther',
    Australia: 'Australia',
    Austria: 'Austria',
    Azerbaijan: 'Azerbaijan',
    Bahamas: 'Bahamas',
    Barbados: 'Barbados',
    Bangladesh: 'Bangladesh',
    Bahrain: 'Bahrain',
    Belarus: 'Belarus',
    Belize: 'Belize',
    Belgium: 'Belgium',
    Benin: 'Benin',
    Bermuda: 'Bermuda',
    Bhutan: 'Bhutan',
    Bolivia: 'Bolivia',
    Bonaire: 'Bonaire',
    Bosnia: 'Bosnia',
    Botswana: 'Botswana',
    BouvetIsland: 'BouvetIsland',
    Brazil: 'Brazil',
    BritishIndianOceanTerritory: 'BritishIndianOceanTerritory',
    Brunei: 'Brunei',
    Bulgaria: 'Bulgaria',
    BurkinaFaso: 'BurkinaFaso',
    Burundi: 'Burundi',
    Cambodia: 'Cambodia',
    Cameroon: 'Cameroon',
    Canada: 'Canada',
    CapeVerde: 'CapeVerde',
    CaymanIslands: 'CaymanIslands',
    CentralAfricanRepublic: 'CentralAfricanRepublic',
    Ceuta: 'Ceuta',
    Chad: 'Chad',
    Chile: 'Chile',
    China: 'China',
    CocosIslands: 'CocosIslands',
    Colombia: 'Colombia',
    Congo: 'Congo',
    CongoDemocraticRepublicOf: 'CongoDemocraticRepublicOf',
    Comoros: 'Comoros',
    CookIslands: 'CookIslands',
    CostaRica: 'CostaRica',
    ChristmasIslands: 'ChristmasIslands',
    Croatia: 'Croatia',
    Cuba: 'Cuba',
    Curacao: 'Curacao',
    Cyprus: 'Cyprus',
    CzechRepublic: 'CzechRepublic',
    Denmark: 'Denmark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominica',
    DominicanRepublic: 'DominicanRepublic',
    Ecuador: 'Ecuador',
    ElSalvador: 'ElSalvador',
    Egypt: 'Egypt',
    Eritrea: 'Eritrea',
    Estonia: 'Estonia',
    Ethiopia: 'Ethiopia',
    Europe: 'Europe',
    EuropeMiddleEastAndAfrica: 'EuropeMiddleEastAndAfrica',
    EuropeOther: 'EuropeOther',
    EuropeanUnion: 'EuropeanUnion',
    EuropeanUnion27: 'EuropeanUnion27',
    FalklandIslands: 'FalklandIslands',
    FaroeIslands: 'FaroeIslands',
    Fiji: 'Fiji',
    Finland: 'Finland',
    France: 'France',
    FreeZone: 'FreeZone',
    FrenchPolynesia: 'FrenchPolynesia',
    Gambia: 'Gambia',
    Georgia: 'Georgia',
    Germany: 'Germany',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    GlobalDairyTrade: 'GlobalDairyTrade',
    Gabon: 'Gabon',
    Greece: 'Greece',
    Greenland: 'Greenland',
    Grenada: 'Grenada',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guinea: 'Guinea',
    GuineaBissau: 'GuineaBissau',
    GuineaEquatorial: 'GuineaEquatorial',
    Guyana: 'Guyana',
    Haiti: 'Haiti',
    Honduras: 'Honduras',
    HongKong: 'HongKong',
    Hungary: 'Hungary',
    Iceland: 'Iceland',
    India: 'India',
    Indonesia: 'Indonesia',
    Iran: 'Iran',
    Iraq: 'Iraq',
    Ireland: 'Ireland',
    Israel: 'Israel',
    Italy: 'Italy',
    IvoryCoast: 'IvoryCoast',
    Jamaica: 'Jamaica',
    Japan: 'Japan',
    Jordan: 'Jordan',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    Kosovo: 'Kosovo',
    Kuwait: 'Kuwait',
    Kyrgyzstan: 'Kyrgyzstan',
    Laos: 'Laos',
    Latvia: 'Latvia',
    Lebanon: 'Lebanon',
    Lesotho: 'Lesotho',
    Liberia: 'Liberia',
    Libya: 'Libya',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lithuania',
    Luxembourg: 'Luxembourg',
    Macao: 'Macao',
    Macedonia: 'Macedonia',
    Madagascar: 'Madagascar',
    MajorExporters: 'MajorExporters',
    Malawi: 'Malawi',
    Malaysia: 'Malaysia',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malta',
    MarshallIslands: 'MarshallIslands',
    Mauritania: 'Mauritania',
    Mauritius: 'Mauritius',
    Mayotte: 'Mayotte',
    Melilla: 'Melilla',
    Mexico: 'Mexico',
    Moldova: 'Moldova',
    Mongolia: 'Mongolia',
    Montenegro: 'Montenegro',
    Morocco: 'Morocco',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibia',
    Nauru: 'Nauru',
    Nepal: 'Nepal',
    Netherlands: 'Netherlands',
    NetherlandsAntilles: 'NetherlandsAntilles',
    NewCaledonia: 'NewCaledonia',
    NewZealand: 'NewZealand',
    NewZealandOceania: 'NewZealandOceania',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    NorfolkIsland: 'NorfolkIsland',
    NorthAmericaOther: 'NorthAmericaOther',
    NorthernMarianaIslands: 'NorthernMarianaIslands',
    NorthKorea: 'NorthKorea',
    Norway: 'Norway',
    Oceania: 'Oceania',
    OceaniaOther: 'OceaniaOther',
    Oman: 'Oman',
    Opec: 'Opec',
    OpecPlus: 'OpecPlus',
    Other: 'Other',
    Pakistan: 'Pakistan',
    Palau: 'Palau',
    Palestine: 'Palestine',
    Panama: 'Panama',
    PapuaNewGuinea: 'PapuaNewGuinea',
    Paraguay: 'Paraguay',
    Peru: 'Peru',
    Philippines: 'Philippines',
    Pitcairn: 'Pitcairn',
    Poland: 'Poland',
    Portugal: 'Portugal',
    PuertoRico: 'PuertoRico',
    Qatar: 'Qatar',
    Romania: 'Romania',
    Russia: 'Russia',
    Rwanda: 'Rwanda',
    SaintBarthelemy: 'SaintBarthelemy',
    SaintHelena: 'SaintHelena',
    SaintKittsAndNevis: 'SaintKittsAndNevis',
    SaintLucia: 'SaintLucia',
    SaintPierreAndMiquelon: 'SaintPierreAndMiquelon',
    SaintVincentAndTheGrenadines: 'SaintVincentAndTheGrenadines',
    Samoa: 'Samoa',
    SanMarino: 'SanMarino',
    SaoTomeAndPrincipe: 'SaoTomeAndPrincipe',
    SaudiArabia: 'SaudiArabia',
    Senegal: 'Senegal',
    Serbia: 'Serbia',
    SerbiaAndMontenegro: 'SerbiaAndMontenegro',
    Seychelles: 'Seychelles',
    SierraLeone: 'SierraLeone',
    Singapore: 'Singapore',
    SintMaarten: 'SintMaarten',
    Slovakia: 'Slovakia',
    Slovenia: 'Slovenia',
    SolomonIslands: 'SolomonIslands',
    Somalia: 'Somalia',
    SouthAfrica: 'SouthAfrica',
    SouthAmerica: 'SouthAmerica',
    SouthAmericaOther: 'SouthAmericaOther',
    SouthKorea: 'SouthKorea',
    SouthSudan: 'SouthSudan',
    Spain: 'Spain',
    SriLanka: 'SriLanka',
    StoresAndProvisions: 'StoresAndProvisions',
    StoresAndProvisions2: 'StoresAndProvisions2',
    StoresAndProvisions3: 'StoresAndProvisions3',
    Sudan: 'Sudan',
    Suriname: 'Suriname',
    Swaziland: 'Swaziland',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    Syria: 'Syria',
    Taiwan: 'Taiwan',
    Tajikistan: 'Tajikistan',
    Tanzania: 'Tanzania',
    Thailand: 'Thailand',
    TimorLeste: 'TimorLeste',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    TopFive: 'TopFive',
    TopThirty: 'TopThirty',
    TrinidadAndTobago: 'TrinidadAndTobago',
    Tunisia: 'Tunisia',
    Turkey: 'Turkey',
    Turkmenistan: 'Turkmenistan',
    TurksAndCaicos: 'TurksAndCaicos',
    Tuvalu: 'Tuvalu',
    Uganda: 'Uganda',
    Ukraine: 'Ukraine',
    UnitedArabEmirates: 'UnitedArabEmirates',
    UnitedKingdom: 'UnitedKingdom',
    UserData: 'UserData',
    UnitedStates: 'UnitedStates',
    UnitedStatesMinorOutlyingIslands: 'UnitedStatesMinorOutlyingIslands',
    Unspecified: 'Unspecified',
    Unspecified2: 'Unspecified2',
    Unspecified3: 'Unspecified3',
    Unspecified4: 'Unspecified4',
    Unspecified5: 'Unspecified5',
    Unspecified6: 'Unspecified6',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Uzbekistan',
    Vanuatu: 'Vanuatu',
    VaticanCity: 'VaticanCity',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnam',
    VirginIslandsBritish: 'VirginIslandsBritish',
    VirginIslandsUnitedStates: 'VirginIslandsUnitedStates',
    WallisAndFutuna: 'WallisAndFutuna',
    Yemen: 'Yemen',
    Yugoslavia: 'Yugoslavia',
    Zambia: 'Zambia',
    Zimbabwe: 'Zimbabwe'
} as const;

export type DemeterRegion = typeof DemeterRegion[keyof typeof DemeterRegion];


/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterRegistrationSource = {
    Broker: 'Broker',
    SelfRegistrationWithCreditCard: 'SelfRegistrationWithCreditCard'
} as const;

export type DemeterRegistrationSource = typeof DemeterRegistrationSource[keyof typeof DemeterRegistrationSource];


/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterSubFundamental = {
    All: 'All',
    Basis: 'Basis',
    ForecastHistory: 'ForecastHistory'
} as const;

export type DemeterSubFundamental = typeof DemeterSubFundamental[keyof typeof DemeterSubFundamental];


/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterSubRegion = {
    All: 'All',
    Aggregated: 'Aggregated',
    Central: 'Central',
    East: 'East',
    EastCentral: 'EastCentral',
    Midwest: 'Midwest',
    Mountain: 'Mountain',
    North: 'North',
    Northeast: 'Northeast',
    Pacific: 'Pacific',
    South: 'South',
    SouthCentral: 'SouthCentral',
    Southeast: 'Southeast',
    UpperMidwest: 'UpperMidwest',
    West: 'West',
    WestCentral: 'WestCentral',
    NewSouthWales: 'NewSouthWales',
    Queensland: 'Queensland',
    Tasmania: 'Tasmania',
    Victoria: 'Victoria',
    Austria: 'Austria',
    Britian: 'Britian',
    Belgium: 'Belgium',
    Bulgaria: 'Bulgaria',
    Croatia: 'Croatia',
    Cyprus: 'Cyprus',
    CzechRepublic: 'CzechRepublic',
    Denmark: 'Denmark',
    Dutch: 'Dutch',
    Estonia: 'Estonia',
    Europe: 'Europe',
    Finland: 'Finland',
    France: 'France',
    Germany: 'Germany',
    Gouda: 'Gouda',
    Greece: 'Greece',
    Hungary: 'Hungary',
    Ireland: 'Ireland',
    Italy: 'Italy',
    Latvia: 'Latvia',
    Lithuania: 'Lithuania',
    Luxembourg: 'Luxembourg',
    Malta: 'Malta',
    Netherlands: 'Netherlands',
    Poland: 'Poland',
    Portugal: 'Portugal',
    Romania: 'Romania',
    Slovakia: 'Slovakia',
    Slovenia: 'Slovenia',
    Spain: 'Spain',
    Sweden: 'Sweden',
    UnitedKingdom: 'UnitedKingdom',
    Malaysia: 'Malaysia',
    Japan: 'Japan',
    Shanghai: 'Shanghai',
    SouthKorea: 'SouthKorea',
    Thailand: 'Thailand',
    Brazil: 'Brazil',
    Alabama: 'Alabama',
    Alaska: 'Alaska',
    Arizona: 'Arizona',
    Arkansas: 'Arkansas',
    California: 'California',
    Colorado: 'Colorado',
    Connecticut: 'Connecticut',
    Delaware: 'Delaware',
    Florida: 'Florida',
    Georgia: 'Georgia',
    Hawaii: 'Hawaii',
    Idaho: 'Idaho',
    Illinois: 'Illinois',
    Indiana: 'Indiana',
    Iowa: 'Iowa',
    Kansas: 'Kansas',
    Kentucky: 'Kentucky',
    Louisiana: 'Louisiana',
    Maine: 'Maine',
    Maryland: 'Maryland',
    Massachusetts: 'Massachusetts',
    Michigan: 'Michigan',
    Minnesota: 'Minnesota',
    Mississippi: 'Mississippi',
    Missouri: 'Missouri',
    Montana: 'Montana',
    Nebraska: 'Nebraska',
    Nevada: 'Nevada',
    NewHampshire: 'NewHampshire',
    NewJersey: 'NewJersey',
    NewMexico: 'NewMexico',
    NewYork: 'NewYork',
    NorthCarolina: 'NorthCarolina',
    NorthDakota: 'NorthDakota',
    Ohio: 'Ohio',
    Oklahoma: 'Oklahoma',
    Oregon: 'Oregon',
    Pennsylvania: 'Pennsylvania',
    RhodeIsland: 'RhodeIsland',
    SouthCarolina: 'SouthCarolina',
    SouthDakota: 'SouthDakota',
    Tennessee: 'Tennessee',
    Texas: 'Texas',
    Utah: 'Utah',
    Vermont: 'Vermont',
    Virginia: 'Virginia',
    Washington: 'Washington',
    WestVirginia: 'WestVirginia',
    Wisconsin: 'Wisconsin',
    Wyoming: 'Wyoming',
    CornBelt: 'CornBelt',
    GulfCoast: 'GulfCoast'
} as const;

export type DemeterSubRegion = typeof DemeterSubRegion[keyof typeof DemeterSubRegion];


/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterSubscriptionFrequency = {
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    Yearly: 'Yearly'
} as const;

export type DemeterSubscriptionFrequency = typeof DemeterSubscriptionFrequency[keyof typeof DemeterSubscriptionFrequency];


/**
 * 
 * @export
 * @interface DemeterSymbolContractModel
 */
export interface DemeterSymbolContractModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterSymbolContractModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterSymbolContractModel
     */
    'monthYearName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterSymbolContractModel
     */
    'reutersInstrumentCode': string;
    /**
     * 
     * @type {number}
     * @memberof DemeterSymbolContractModel
     */
    'priceMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterSymbolContractModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterSymbolContractModel
     */
    'month': number;
}
/**
 * 
 * @export
 * @interface DemeterSymbolModel
 */
export interface DemeterSymbolModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof DemeterSymbolModel
     */
    'contractMonths'?: Array<number> | null;
    /**
     * 
     * @type {SymbolCategory}
     * @memberof DemeterSymbolModel
     */
    'symbolCategory': SymbolCategory;
    /**
     * 
     * @type {ExchangeType}
     * @memberof DemeterSymbolModel
     */
    'exchange': ExchangeType;
    /**
     * 
     * @type {SubExchangeType}
     * @memberof DemeterSymbolModel
     */
    'subExchange'?: SubExchangeType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterSymbolModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterSymbolModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof DemeterSymbolModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterSymbolModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterSymbolModel
     */
    'reutersInstrumentCodePrefix': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterSymbolModel
     */
    'reutersInstrumentCodeFrontMonth': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterSymbolModel
     */
    'reutersInstrumentCodeSpotPrice'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterSymbolModel
     */
    'priceMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterSymbolModel
     */
    'priceMultiplierFutures': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterSymbolModel
     */
    'visibleContracts': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterSymbolModel
     */
    'startYearForTwoDigitFutureYearSuffix': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterSymbolModel
     */
    'startYearForTwoDigitOptionYearSuffix': number;
    /**
     * 
     * @type {number}
     * @memberof DemeterSymbolModel
     */
    'consecutiveContractsAfterFrontMonth'?: number | null;
    /**
     * 
     * @type {Array<DemeterSymbolContractModel>}
     * @memberof DemeterSymbolModel
     */
    'symbolContracts': Array<DemeterSymbolContractModel>;
    /**
     * 
     * @type {Array<DemeterSymbolContractModel>}
     * @memberof DemeterSymbolModel
     */
    'expiredSymbolContracts': Array<DemeterSymbolContractModel>;
    /**
     * 
     * @type {Currency}
     * @memberof DemeterSymbolModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof DemeterSymbolModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof DemeterSymbolModel
     */
    'dataSourceTag': string;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterSymbolModel
     */
    'pricesRegion'?: DemeterRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterSymbolModel
     */
    'pricesCommodity'?: DemeterCommodity;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof DemeterSymbolModel
     */
    'pricesDataSource'?: DemeterDataSource;
    /**
     * 
     * @type {number}
     * @memberof DemeterSymbolModel
     */
    'rollingContractNumber'?: number | null;
}
/**
 * 
 * @export
 * @interface DemeterTableDefinitionCommodityModel
 */
export interface DemeterTableDefinitionCommodityModel {
    /**
     * 
     * @type {DemeterTableDefinitionType}
     * @memberof DemeterTableDefinitionCommodityModel
     */
    'tableDefinitionType': DemeterTableDefinitionType;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionCommodityModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionCommodityModel
     */
    'topLevelGroup': string;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof DemeterTableDefinitionCommodityModel
     */
    'market'?: DemeterMarket;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterTableDefinitionCommodityModel
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {Array<DemeterTableDefinitionSubCommodityModel>}
     * @memberof DemeterTableDefinitionCommodityModel
     */
    'demeterTableDefinitionSubCommodities': Array<DemeterTableDefinitionSubCommodityModel>;
    /**
     * 
     * @type {Array<DemeterTableDefinitionCommodityRegionModel>}
     * @memberof DemeterTableDefinitionCommodityModel
     */
    'demeterTableDefinitionRegions': Array<DemeterTableDefinitionCommodityRegionModel>;
    /**
     * 
     * @type {number}
     * @memberof DemeterTableDefinitionCommodityModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface DemeterTableDefinitionCommodityRegionModel
 */
export interface DemeterTableDefinitionCommodityRegionModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionCommodityRegionModel
     */
    'displayName': string;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterTableDefinitionCommodityRegionModel
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {Array<DemeterSubRegion>}
     * @memberof DemeterTableDefinitionCommodityRegionModel
     */
    'subRegions': Array<DemeterSubRegion>;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterTableDefinitionCommodityRegionModel
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionCommodityRegionModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterTableDefinitionCommodityRegionModel
     */
    'order': number;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterTableDefinitionCommodityRegionModel
     */
    'showInOtherList': boolean;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionCommodityRegionModel
     */
    'demeterCommentaryGuid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterTableDefinitionCommodityRegionModel
     */
    'displayDecimalPlacesMinimum'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterTableDefinitionCommodityRegionModel
     */
    'displayDecimalPlacesMaximum'?: number | null;
    /**
     * 
     * @type {Array<DemeterDataFrequency>}
     * @memberof DemeterTableDefinitionCommodityRegionModel
     */
    'dataFrequencies': Array<DemeterDataFrequency>;
}
/**
 * 
 * @export
 * @interface DemeterTableDefinitionGroupModel
 */
export interface DemeterTableDefinitionGroupModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'topLevelGroup'?: string | null;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'market'?: DemeterMarket;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'hasNullCommodityForSubRegions': boolean;
    /**
     * 
     * @type {Array<DemeterSubRegion>}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'subRegions': Array<DemeterSubRegion>;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'hasSubGroupHeaders': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'hasSubGroupBreaks': boolean;
    /**
     * 
     * @type {Array<DemeterTableDefinitionModel>}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'demeterTableDefinitions': Array<DemeterTableDefinitionModel>;
    /**
     * 
     * @type {number}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'demeterCommentaryGuid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'displayDecimalPlacesMinimum'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'displayDecimalPlacesMaximum'?: number | null;
    /**
     * 
     * @type {Array<DemeterDataFrequency>}
     * @memberof DemeterTableDefinitionGroupModel
     */
    'dataFrequencies': Array<DemeterDataFrequency>;
}
/**
 * 
 * @export
 * @interface DemeterTableDefinitionModel
 */
export interface DemeterTableDefinitionModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionModel
     */
    'groupDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionModel
     */
    'topLevelGroup'?: string | null;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterTableDefinitionModel
     */
    'queryRegion'?: DemeterRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterTableDefinitionModel
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {Array<DemeterSubRegion>}
     * @memberof DemeterTableDefinitionModel
     */
    'subRegions': Array<DemeterSubRegion>;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterTableDefinitionModel
     */
    'isSubGroupHeader': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterTableDefinitionModel
     */
    'hasSubGroupBreakBefore': boolean;
    /**
     * 
     * @type {number}
     * @memberof DemeterTableDefinitionModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionModel
     */
    'demeterCommentaryGuid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterTableDefinitionModel
     */
    'displayDecimalPlacesMinimum'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemeterTableDefinitionModel
     */
    'displayDecimalPlacesMaximum'?: number | null;
    /**
     * 
     * @type {Array<DemeterDataFrequency>}
     * @memberof DemeterTableDefinitionModel
     */
    'dataFrequencies': Array<DemeterDataFrequency>;
}
/**
 * 
 * @export
 * @interface DemeterTableDefinitionRegionModel
 */
export interface DemeterTableDefinitionRegionModel {
    /**
     * 
     * @type {DemeterTableDefinitionType}
     * @memberof DemeterTableDefinitionRegionModel
     */
    'tableDefinitionType': DemeterTableDefinitionType;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionRegionModel
     */
    'displayName': string;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof DemeterTableDefinitionRegionModel
     */
    'market'?: DemeterMarket;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterTableDefinitionRegionModel
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterTableDefinitionRegionModel
     */
    'parentRegion'?: DemeterRegion;
    /**
     * 
     * @type {Array<DemeterSubRegion>}
     * @memberof DemeterTableDefinitionRegionModel
     */
    'subRegions': Array<DemeterSubRegion>;
    /**
     * 
     * @type {Array<DemeterTableDefinitionGroupModel>}
     * @memberof DemeterTableDefinitionRegionModel
     */
    'demeterTableDefinitionGroups': Array<DemeterTableDefinitionGroupModel>;
    /**
     * 
     * @type {number}
     * @memberof DemeterTableDefinitionRegionModel
     */
    'order': number;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterTableDefinitionRegionModel
     */
    'showInOtherList': boolean;
}
/**
 * 
 * @export
 * @interface DemeterTableDefinitionSubCommodityModel
 */
export interface DemeterTableDefinitionSubCommodityModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionSubCommodityModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterTableDefinitionSubCommodityModel
     */
    'topLevelGroup'?: string | null;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DemeterTableDefinitionSubCommodityModel
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterTableDefinitionSubCommodityModel
     */
    'isSubGroupHeader': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterTableDefinitionSubCommodityModel
     */
    'hasSubGroupBreakBefore': boolean;
    /**
     * 
     * @type {Array<DemeterTableDefinitionCommodityRegionModel>}
     * @memberof DemeterTableDefinitionSubCommodityModel
     */
    'demeterTableDefinitionRegions': Array<DemeterTableDefinitionCommodityRegionModel>;
    /**
     * 
     * @type {number}
     * @memberof DemeterTableDefinitionSubCommodityModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterTableDefinitionType = {
    CommodityFuturesTable: 'CommodityFuturesTable',
    CommodityFuturesIndexTable: 'CommodityFuturesIndexTable',
    CommodityGlobalDairyTradeWeeklyPricesTable: 'CommodityGlobalDairyTradeWeeklyPricesTable',
    CommodityMonthlyDomesticTable: 'CommodityMonthlyDomesticTable',
    CommodityMonthlyExportsTable: 'CommodityMonthlyExportsTable',
    CommodityMonthlyImportsTable: 'CommodityMonthlyImportsTable',
    CommodityMonthlyMarginTable: 'CommodityMonthlyMarginTable',
    CommodityMonthlyPricesTable: 'CommodityMonthlyPricesTable',
    CommodityMonthlyProductionTable: 'CommodityMonthlyProductionTable',
    CommodityMonthlySalesTable: 'CommodityMonthlySalesTable',
    CommodityMonthlyStockTable: 'CommodityMonthlyStockTable',
    CommodityOtcPricesTable: 'CommodityOtcPricesTable',
    CommodityOtherTable: 'CommodityOtherTable',
    CommodityPricesTable: 'CommodityPricesTable',
    CommodityProgressTable: 'CommodityProgressTable',
    CommoditySpotPricesTable: 'CommoditySpotPricesTable',
    CommoditySummaryTable: 'CommoditySummaryTable',
    CommodityWeeklyPricesTable: 'CommodityWeeklyPricesTable',
    CommodityYearlyBalanceTable: 'CommodityYearlyBalanceTable',
    MarketNews: 'MarketNews',
    UsersTable: 'UsersTable'
} as const;

export type DemeterTableDefinitionType = typeof DemeterTableDefinitionType[keyof typeof DemeterTableDefinitionType];


/**
 * 
 * @export
 * @interface DemeterUserDataModel
 */
export interface DemeterUserDataModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterUserDataModel
     */
    'demeterUserDataGuid': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserDataModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserDataModel
     */
    'fileName': string;
    /**
     * 
     * @type {Currency}
     * @memberof DemeterUserDataModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof DemeterUserDataModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {Array<DemeterDataValue>}
     * @memberof DemeterUserDataModel
     */
    'rows': Array<DemeterDataValue>;
}
/**
 * 
 * @export
 * @interface DemeterUserDataValue
 */
export interface DemeterUserDataValue {
    /**
     * 
     * @type {string}
     * @memberof DemeterUserDataValue
     */
    'asOfDate': string;
    /**
     * 
     * @type {number}
     * @memberof DemeterUserDataValue
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface DemeterUserModel
 */
export interface DemeterUserModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'demeterUserGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'demeterUserTrialGuid': string;
    /**
     * 
     * @type {DemeterUserType}
     * @memberof DemeterUserModel
     */
    'userType': DemeterUserType;
    /**
     * 
     * @type {DemeterUserStatus}
     * @memberof DemeterUserModel
     */
    'userStatus': DemeterUserStatus;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'demeterCompanyGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'trialStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'trialExpirationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'firstUseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'lastUseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'subscribedDate'?: string | null;
    /**
     * 
     * @type {MarketPrecision}
     * @memberof DemeterUserModel
     */
    'marketPrecision'?: MarketPrecision;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemeterUserModel
     */
    'isCompanyAdministrator': boolean;
    /**
     * 
     * @type {Array<DemeterMarket>}
     * @memberof DemeterUserModel
     */
    'markets': Array<DemeterMarket>;
    /**
     * 
     * @type {DemeterRegistrationSource}
     * @memberof DemeterUserModel
     */
    'registrationSource': DemeterRegistrationSource;
    /**
     * 
     * @type {string}
     * @memberof DemeterUserModel
     */
    'termsAndConditionsAcceptedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface DemeterUserPreferenceModel
 */
export interface DemeterUserPreferenceModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterUserPreferenceModel
     */
    'demeterUserPreferenceGuid'?: string | null;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DemeterUserPreferenceModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof DemeterUserPreferenceModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterWorkerType}
     * @memberof DemeterUserPreferenceModel
     */
    'workerType': DemeterWorkerType;
    /**
     * 
     * @type {Currency}
     * @memberof DemeterUserPreferenceModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof DemeterUserPreferenceModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterUserStatus = {
    Active: 'Active',
    Deactivated: 'Deactivated',
    Rejected: 'Rejected',
    Expired: 'Expired',
    Expiring: 'Expiring',
    Locked: 'Locked',
    Pending: 'Pending',
    PendingApproval: 'PendingApproval',
    Reactivated: 'Reactivated',
    Synced: 'Synced'
} as const;

export type DemeterUserStatus = typeof DemeterUserStatus[keyof typeof DemeterUserStatus];


/**
 * 
 * @export
 * @interface DemeterUserStoreModel
 */
export interface DemeterUserStoreModel {
    /**
     * 
     * @type {DemeterUserStoreType}
     * @memberof DemeterUserStoreModel
     */
    'userStoreType': DemeterUserStoreType;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof DemeterUserStoreModel
     */
    'value': { [key: string]: any; };
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterUserStoreType = {
    BasisCalculator: 'BasisCalculator',
    CommodityFuturesChart: 'CommodityFuturesChart',
    CommodityFuturesTable: 'CommodityFuturesTable',
    CommodityPricesChart: 'CommodityPricesChart',
    CommodityPricesTable: 'CommodityPricesTable',
    DisplaySettings: 'DisplaySettings',
    LocationData: 'LocationData',
    LanguageSelected: 'LanguageSelected',
    UserData: 'UserData',
    UserMarketIndicators: 'UserMarketIndicators'
} as const;

export type DemeterUserStoreType = typeof DemeterUserStoreType[keyof typeof DemeterUserStoreType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterUserType = {
    User: 'User',
    Regular: 'Regular',
    Premium: 'Premium',
    BusinessOwner: 'BusinessOwner',
    Administrator: 'Administrator'
} as const;

export type DemeterUserType = typeof DemeterUserType[keyof typeof DemeterUserType];


/**
 * 
 * @export
 * @interface DemeterWorkerSyncStatusModel
 */
export interface DemeterWorkerSyncStatusModel {
    /**
     * 
     * @type {string}
     * @memberof DemeterWorkerSyncStatusModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterWorkerSyncStatusModel
     */
    'lastRunAt'?: string | null;
    /**
     * 
     * @type {WorkerLogType}
     * @memberof DemeterWorkerSyncStatusModel
     */
    'lastStatus'?: WorkerLogType;
    /**
     * 
     * @type {string}
     * @memberof DemeterWorkerSyncStatusModel
     */
    'lastSuccessfulRunAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterWorkerSyncStatusModel
     */
    'lastFailedRunAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemeterWorkerSyncStatusModel
     */
    'lastErrorMessage'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DemeterWorkerType = {
    Any: 'Any',
    Buyer: 'Buyer',
    Default: 'Default',
    Farmer: 'Farmer',
    Processor: 'Processor',
    Trader: 'Trader'
} as const;

export type DemeterWorkerType = typeof DemeterWorkerType[keyof typeof DemeterWorkerType];


/**
 * 
 * @export
 * @interface DownloadDataRequest
 */
export interface DownloadDataRequest {
    /**
     * 
     * @type {Array<DataSeries>}
     * @memberof DownloadDataRequest
     */
    'dataSeries'?: Array<DataSeries> | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadDataRequest
     */
    'dateLabel'?: string | null;
}
/**
 * 
 * @export
 * @interface DownloadDataResponse
 */
export interface DownloadDataResponse {
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof DownloadDataResponse
     */
    'rows'?: Array<{ [key: string]: string; }> | null;
    /**
     * 
     * @type {number}
     * @memberof DownloadDataResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DownloadDataResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof DownloadDataResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof DownloadDataResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DownloadDataResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface DownloadPresentationTemplateResponse
 */
export interface DownloadPresentationTemplateResponse {
    /**
     * 
     * @type {string}
     * @memberof DownloadPresentationTemplateResponse
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface DownloadValueMatrixRequest
 */
export interface DownloadValueMatrixRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DownloadValueMatrixRequest
     */
    'termClassifications'?: Array<string> | null;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof DownloadValueMatrixRequest
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof DownloadValueMatrixRequest
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof DownloadValueMatrixRequest
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof DownloadValueMatrixRequest
     */
    'dataSource'?: DemeterDataSource;
    /**
     * 
     * @type {PriceType}
     * @memberof DownloadValueMatrixRequest
     */
    'priceType'?: PriceType;
    /**
     * 
     * @type {string}
     * @memberof DownloadValueMatrixRequest
     */
    'stripType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DownloadValueMatrixRequest
     */
    'contractNumberStart'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DownloadValueMatrixRequest
     */
    'contractNumberEnd'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadValueMatrixRequest
     */
    'useProducerPriceIndex'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadValueMatrixRequest
     */
    'useWeightedCalculation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DownloadValueMatrixRequest
     */
    'innerYears'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DownloadValueMatrixRequest
     */
    'outerYears': number;
    /**
     * 
     * @type {number}
     * @memberof DownloadValueMatrixRequest
     */
    'innerWeightPercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DownloadValueMatrixRequest
     */
    'outerWeightPercent'?: number | null;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof DownloadValueMatrixRequest
     */
    'monthSets': Array<Array<number>>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof DownloadValueMatrixRequest
     */
    'currentValuesPerMonthSet'?: Array<Array<number>> | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadValueMatrixRequest
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadValueMatrixRequest
     */
    'showProducerPriceIndex'?: boolean;
}
/**
 * 
 * @export
 * @interface DownloadValueMatrixResponse
 */
export interface DownloadValueMatrixResponse {
    /**
     * 
     * @type {string}
     * @memberof DownloadValueMatrixResponse
     */
    'fileContent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadValueMatrixResponse
     */
    'contentType'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ExchangeType = {
    Cme: 'CME',
    B3: 'B3',
    Dce: 'DCE',
    Eex: 'EEX',
    Euronext: 'Euronext',
    Ice: 'ICE',
    Mdx: 'MDX',
    Mwe: 'MWE',
    Otc: 'OTC',
    Safex: 'SAFEX',
    Sgx: 'SGX'
} as const;

export type ExchangeType = typeof ExchangeType[keyof typeof ExchangeType];


/**
 * 
 * @export
 * @interface FarmerMarginCalculatorCostModel
 */
export interface FarmerMarginCalculatorCostModel {
    /**
     * 
     * @type {string}
     * @memberof FarmerMarginCalculatorCostModel
     */
    'displayName': string;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorCostModel
     */
    'price': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorCostModel
     */
    'needs': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {Array<FarmerMarginCalculatorVolumePriceLineModel>}
     * @memberof FarmerMarginCalculatorCostModel
     */
    'lines': Array<FarmerMarginCalculatorVolumePriceLineModel>;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorCostModel
     */
    'hedgedVolume': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorCostModel
     */
    'hedgedPrice': DemeterCalculationEngineLineShortModel;
}
/**
 * 
 * @export
 * @interface FarmerMarginCalculatorFuturesModel
 */
export interface FarmerMarginCalculatorFuturesModel {
    /**
     * 
     * @type {Array<FarmerMarginCalculatorPricePercentLineModel>}
     * @memberof FarmerMarginCalculatorFuturesModel
     */
    'lines': Array<FarmerMarginCalculatorPricePercentLineModel>;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorFuturesModel
     */
    'futuresBlendPrice': DemeterCalculationEngineLineShortModel;
}
/**
 * 
 * @export
 * @interface FarmerMarginCalculatorModel
 */
export interface FarmerMarginCalculatorModel {
    /**
     * 
     * @type {string}
     * @memberof FarmerMarginCalculatorModel
     */
    'demeterCalculationEngineGuid': string;
    /**
     * 
     * @type {string}
     * @memberof FarmerMarginCalculatorModel
     */
    'demeterCalculationEngineInputsGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FarmerMarginCalculatorModel
     */
    'displayName': string;
    /**
     * 
     * @type {{ [key: string]: CalculationEngineDataOverride; }}
     * @memberof FarmerMarginCalculatorModel
     */
    'dataOverrides': { [key: string]: CalculationEngineDataOverride; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof FarmerMarginCalculatorModel
     */
    'valueOverrides': { [key: string]: number; };
    /**
     * 
     * @type {Array<CalculationEngineMonthlyValueOverrides>}
     * @memberof FarmerMarginCalculatorModel
     */
    'monthlyValueOverrides': Array<CalculationEngineMonthlyValueOverrides>;
    /**
     * 
     * @type {Array<FarmerMarginCalculatorMonthlyModel>}
     * @memberof FarmerMarginCalculatorModel
     */
    'rows': Array<FarmerMarginCalculatorMonthlyModel>;
    /**
     * 
     * @type {string}
     * @memberof FarmerMarginCalculatorModel
     */
    'stateVariableName': string;
    /**
     * 
     * @type {Array<CalculationEngineOption>}
     * @memberof FarmerMarginCalculatorModel
     */
    'stateOptions': Array<CalculationEngineOption>;
    /**
     * 
     * @type {string}
     * @memberof FarmerMarginCalculatorModel
     */
    'modifiedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FarmerMarginCalculatorModel
     */
    'modifiedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FarmerMarginCalculatorMonthlyModel
 */
export interface FarmerMarginCalculatorMonthlyModel {
    /**
     * 
     * @type {string}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {FarmerMarginCalculatorFuturesModel}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'futuresPrices': FarmerMarginCalculatorFuturesModel;
    /**
     * 
     * @type {Array<DemeterCalculationEngineLineShortModel>}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'productionLines': Array<DemeterCalculationEngineLineShortModel>;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'production': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {Array<FarmerMarginCalculatorOutputModel>}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'outputs': Array<FarmerMarginCalculatorOutputModel>;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'outputAdjustment': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'outputHedgedPercent': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'outputHedgedVolume': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'outputHedgedPrice': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {Array<FarmerMarginCalculatorCostModel>}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'costs': Array<FarmerMarginCalculatorCostModel>;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'costLinesPrice': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'costOtherPrice': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'costTotalPrice': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'marginHedged': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'marginUnhedged': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorMonthlyModel
     */
    'marginNet': DemeterCalculationEngineLineShortModel;
}
/**
 * 
 * @export
 * @interface FarmerMarginCalculatorOutputModel
 */
export interface FarmerMarginCalculatorOutputModel {
    /**
     * 
     * @type {string}
     * @memberof FarmerMarginCalculatorOutputModel
     */
    'displayName': string;
    /**
     * 
     * @type {Array<FarmerMarginCalculatorVolumePriceLineModel>}
     * @memberof FarmerMarginCalculatorOutputModel
     */
    'lines': Array<FarmerMarginCalculatorVolumePriceLineModel>;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorOutputModel
     */
    'hedgedVolume': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorOutputModel
     */
    'hedgedPrice': DemeterCalculationEngineLineShortModel;
}
/**
 * 
 * @export
 * @interface FarmerMarginCalculatorPricePercentLineModel
 */
export interface FarmerMarginCalculatorPricePercentLineModel {
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorPricePercentLineModel
     */
    'price': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorPricePercentLineModel
     */
    'percent': DemeterCalculationEngineLineShortModel;
}
/**
 * 
 * @export
 * @interface FarmerMarginCalculatorVolumePriceLineModel
 */
export interface FarmerMarginCalculatorVolumePriceLineModel {
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorVolumePriceLineModel
     */
    'volume': DemeterCalculationEngineLineShortModel;
    /**
     * 
     * @type {DemeterCalculationEngineLineShortModel}
     * @memberof FarmerMarginCalculatorVolumePriceLineModel
     */
    'price': DemeterCalculationEngineLineShortModel;
}
/**
 * 
 * @export
 * @interface ForeignExchangeRateModel
 */
export interface ForeignExchangeRateModel {
    /**
     * 
     * @type {number}
     * @memberof ForeignExchangeRateModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof ForeignExchangeRateModel
     */
    'month': number;
    /**
     * 
     * @type {string}
     * @memberof ForeignExchangeRateModel
     */
    'foreignExchangeRateMonthlyGuid': string;
    /**
     * 
     * @type {Currency}
     * @memberof ForeignExchangeRateModel
     */
    'fromCurrency': Currency;
    /**
     * 
     * @type {Currency}
     * @memberof ForeignExchangeRateModel
     */
    'toCurrency': Currency;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof ForeignExchangeRateModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof ForeignExchangeRateModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof ForeignExchangeRateModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof ForeignExchangeRateModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ForeignExchangeRateModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof ForeignExchangeRateModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FuturesDirection = {
    Last: 'Last',
    Bid: 'Bid',
    Ask: 'Ask',
    Settle: 'Settle'
} as const;

export type FuturesDirection = typeof FuturesDirection[keyof typeof FuturesDirection];


/**
 * 
 * @export
 * @interface GetBudgetCalculatorResponse
 */
export interface GetBudgetCalculatorResponse {
    /**
     * 
     * @type {BudgetCalculatorModel}
     * @memberof GetBudgetCalculatorResponse
     */
    'budgetCalculator'?: BudgetCalculatorModel;
}
/**
 * 
 * @export
 * @interface GetCalculationEngineInputsResponse
 */
export interface GetCalculationEngineInputsResponse {
    /**
     * 
     * @type {DemeterCalculationEngineInputsModel}
     * @memberof GetCalculationEngineInputsResponse
     */
    'calculationEngineInputs'?: DemeterCalculationEngineInputsModel;
}
/**
 * 
 * @export
 * @interface GetCalculationEngineResponse
 */
export interface GetCalculationEngineResponse {
    /**
     * 
     * @type {DemeterCalculationEngineModel}
     * @memberof GetCalculationEngineResponse
     */
    'calculationEngine'?: DemeterCalculationEngineModel;
}
/**
 * 
 * @export
 * @interface GetCommodityMonthlyDomesticsSeasonalAggregatedRequest
 */
export interface GetCommodityMonthlyDomesticsSeasonalAggregatedRequest {
    /**
     * 
     * @type {Array<CommoditySelection>}
     * @memberof GetCommodityMonthlyDomesticsSeasonalAggregatedRequest
     */
    'commoditySelections': Array<CommoditySelection>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetCommodityMonthlyDomesticsSeasonalAggregatedRequest
     */
    'years'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCommodityMonthlyDomesticsSeasonalAggregatedRequest
     */
    'showForecast'?: boolean;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityMonthlyDomesticsSeasonalAggregatedRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface GetCommodityMonthlyDomesticsSeasonalResponse
 */
export interface GetCommodityMonthlyDomesticsSeasonalResponse {
    /**
     * 
     * @type {Array<SeasonalYearAverageModel>}
     * @memberof GetCommodityMonthlyDomesticsSeasonalResponse
     */
    'averages'?: Array<SeasonalYearAverageModel> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyDomesticsSeasonalResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityMonthlyDomesticsSeasonalResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyDomesticsSeasonalResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityMonthlyDomesticModelSeasonalYearSummaryModel>}
     * @memberof GetCommodityMonthlyDomesticsSeasonalResponse
     */
    'rows'?: Array<CommodityMonthlyDomesticModelSeasonalYearSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyDomesticsSeasonalResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyDomesticsSeasonalResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetCommodityMonthlyDomesticsSeasonalResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyDomesticsSeasonalResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyDomesticsSeasonalResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCommodityMonthlyExportsSeasonalAggregatedRequest
 */
export interface GetCommodityMonthlyExportsSeasonalAggregatedRequest {
    /**
     * 
     * @type {Array<CommoditySelection>}
     * @memberof GetCommodityMonthlyExportsSeasonalAggregatedRequest
     */
    'commoditySelections': Array<CommoditySelection>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetCommodityMonthlyExportsSeasonalAggregatedRequest
     */
    'years'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCommodityMonthlyExportsSeasonalAggregatedRequest
     */
    'showForecast'?: boolean;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityMonthlyExportsSeasonalAggregatedRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface GetCommodityMonthlyExportsSeasonalResponse
 */
export interface GetCommodityMonthlyExportsSeasonalResponse {
    /**
     * 
     * @type {Array<SeasonalYearAverageModel>}
     * @memberof GetCommodityMonthlyExportsSeasonalResponse
     */
    'averages'?: Array<SeasonalYearAverageModel> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyExportsSeasonalResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityMonthlyExportsSeasonalResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyExportsSeasonalResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityMonthlyExportModelSeasonalYearSummaryModel>}
     * @memberof GetCommodityMonthlyExportsSeasonalResponse
     */
    'rows'?: Array<CommodityMonthlyExportModelSeasonalYearSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyExportsSeasonalResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyExportsSeasonalResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetCommodityMonthlyExportsSeasonalResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyExportsSeasonalResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyExportsSeasonalResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCommodityMonthlyImportsSeasonalAggregatedRequest
 */
export interface GetCommodityMonthlyImportsSeasonalAggregatedRequest {
    /**
     * 
     * @type {Array<CommoditySelection>}
     * @memberof GetCommodityMonthlyImportsSeasonalAggregatedRequest
     */
    'commoditySelections': Array<CommoditySelection>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetCommodityMonthlyImportsSeasonalAggregatedRequest
     */
    'years'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCommodityMonthlyImportsSeasonalAggregatedRequest
     */
    'showForecast'?: boolean;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityMonthlyImportsSeasonalAggregatedRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface GetCommodityMonthlyImportsSeasonalResponse
 */
export interface GetCommodityMonthlyImportsSeasonalResponse {
    /**
     * 
     * @type {Array<SeasonalYearAverageModel>}
     * @memberof GetCommodityMonthlyImportsSeasonalResponse
     */
    'averages'?: Array<SeasonalYearAverageModel> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyImportsSeasonalResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityMonthlyImportsSeasonalResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyImportsSeasonalResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityMonthlyImportModelSeasonalYearSummaryModel>}
     * @memberof GetCommodityMonthlyImportsSeasonalResponse
     */
    'rows'?: Array<CommodityMonthlyImportModelSeasonalYearSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyImportsSeasonalResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyImportsSeasonalResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetCommodityMonthlyImportsSeasonalResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyImportsSeasonalResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyImportsSeasonalResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCommodityMonthlyPricesSeasonalResponse
 */
export interface GetCommodityMonthlyPricesSeasonalResponse {
    /**
     * 
     * @type {Currency}
     * @memberof GetCommodityMonthlyPricesSeasonalResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {Array<SeasonalYearAverageModel>}
     * @memberof GetCommodityMonthlyPricesSeasonalResponse
     */
    'averages'?: Array<SeasonalYearAverageModel> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyPricesSeasonalResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityMonthlyPricesSeasonalResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyPricesSeasonalResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityMonthlyPriceSeasonalModelSeasonalYearSummaryModel>}
     * @memberof GetCommodityMonthlyPricesSeasonalResponse
     */
    'rows'?: Array<CommodityMonthlyPriceSeasonalModelSeasonalYearSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyPricesSeasonalResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyPricesSeasonalResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetCommodityMonthlyPricesSeasonalResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyPricesSeasonalResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyPricesSeasonalResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCommodityMonthlyProductionSeasonalAggregatedRequest
 */
export interface GetCommodityMonthlyProductionSeasonalAggregatedRequest {
    /**
     * 
     * @type {Array<CommoditySelection>}
     * @memberof GetCommodityMonthlyProductionSeasonalAggregatedRequest
     */
    'commoditySelections': Array<CommoditySelection>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetCommodityMonthlyProductionSeasonalAggregatedRequest
     */
    'years'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCommodityMonthlyProductionSeasonalAggregatedRequest
     */
    'showForecast'?: boolean;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityMonthlyProductionSeasonalAggregatedRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface GetCommodityMonthlyProductionSeasonalResponse
 */
export interface GetCommodityMonthlyProductionSeasonalResponse {
    /**
     * 
     * @type {Array<SeasonalYearAverageModel>}
     * @memberof GetCommodityMonthlyProductionSeasonalResponse
     */
    'averages'?: Array<SeasonalYearAverageModel> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyProductionSeasonalResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityMonthlyProductionSeasonalResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyProductionSeasonalResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityMonthlyProductionModelSeasonalYearSummaryModel>}
     * @memberof GetCommodityMonthlyProductionSeasonalResponse
     */
    'rows'?: Array<CommodityMonthlyProductionModelSeasonalYearSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyProductionSeasonalResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyProductionSeasonalResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetCommodityMonthlyProductionSeasonalResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyProductionSeasonalResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyProductionSeasonalResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCommodityMonthlyStocksSeasonalAggregatedRequest
 */
export interface GetCommodityMonthlyStocksSeasonalAggregatedRequest {
    /**
     * 
     * @type {Array<CommoditySelection>}
     * @memberof GetCommodityMonthlyStocksSeasonalAggregatedRequest
     */
    'commoditySelections': Array<CommoditySelection>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetCommodityMonthlyStocksSeasonalAggregatedRequest
     */
    'years'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCommodityMonthlyStocksSeasonalAggregatedRequest
     */
    'showForecast'?: boolean;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityMonthlyStocksSeasonalAggregatedRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface GetCommodityMonthlyStocksSeasonalResponse
 */
export interface GetCommodityMonthlyStocksSeasonalResponse {
    /**
     * 
     * @type {Array<SeasonalYearAverageModel>}
     * @memberof GetCommodityMonthlyStocksSeasonalResponse
     */
    'averages'?: Array<SeasonalYearAverageModel> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyStocksSeasonalResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityMonthlyStocksSeasonalResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyStocksSeasonalResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityMonthlyStockModelSeasonalYearSummaryModel>}
     * @memberof GetCommodityMonthlyStocksSeasonalResponse
     */
    'rows'?: Array<CommodityMonthlyStockModelSeasonalYearSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyStocksSeasonalResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityMonthlyStocksSeasonalResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetCommodityMonthlyStocksSeasonalResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyStocksSeasonalResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityMonthlyStocksSeasonalResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCommodityWeeklyDomesticsSeasonalResponse
 */
export interface GetCommodityWeeklyDomesticsSeasonalResponse {
    /**
     * 
     * @type {Array<SeasonalYearByWeekAverageModel>}
     * @memberof GetCommodityWeeklyDomesticsSeasonalResponse
     */
    'averages'?: Array<SeasonalYearByWeekAverageModel> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyDomesticsSeasonalResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityWeeklyDomesticsSeasonalResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyDomesticsSeasonalResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityWeeklyDomesticModelSeasonalYearByWeekSummaryModel>}
     * @memberof GetCommodityWeeklyDomesticsSeasonalResponse
     */
    'rows'?: Array<CommodityWeeklyDomesticModelSeasonalYearByWeekSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyDomesticsSeasonalResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyDomesticsSeasonalResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetCommodityWeeklyDomesticsSeasonalResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyDomesticsSeasonalResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyDomesticsSeasonalResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCommodityWeeklyExportsSeasonalResponse
 */
export interface GetCommodityWeeklyExportsSeasonalResponse {
    /**
     * 
     * @type {Array<SeasonalYearByWeekAverageModel>}
     * @memberof GetCommodityWeeklyExportsSeasonalResponse
     */
    'averages'?: Array<SeasonalYearByWeekAverageModel> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyExportsSeasonalResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityWeeklyExportsSeasonalResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyExportsSeasonalResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityWeeklyExportModelSeasonalYearByWeekSummaryModel>}
     * @memberof GetCommodityWeeklyExportsSeasonalResponse
     */
    'rows'?: Array<CommodityWeeklyExportModelSeasonalYearByWeekSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyExportsSeasonalResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyExportsSeasonalResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetCommodityWeeklyExportsSeasonalResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyExportsSeasonalResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyExportsSeasonalResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCommodityWeeklyImportsSeasonalResponse
 */
export interface GetCommodityWeeklyImportsSeasonalResponse {
    /**
     * 
     * @type {Array<SeasonalYearByWeekAverageModel>}
     * @memberof GetCommodityWeeklyImportsSeasonalResponse
     */
    'averages'?: Array<SeasonalYearByWeekAverageModel> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyImportsSeasonalResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityWeeklyImportsSeasonalResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyImportsSeasonalResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityWeeklyImportModelSeasonalYearByWeekSummaryModel>}
     * @memberof GetCommodityWeeklyImportsSeasonalResponse
     */
    'rows'?: Array<CommodityWeeklyImportModelSeasonalYearByWeekSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyImportsSeasonalResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyImportsSeasonalResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetCommodityWeeklyImportsSeasonalResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyImportsSeasonalResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyImportsSeasonalResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCommodityWeeklyProductionSeasonalResponse
 */
export interface GetCommodityWeeklyProductionSeasonalResponse {
    /**
     * 
     * @type {Array<SeasonalYearByWeekAverageModel>}
     * @memberof GetCommodityWeeklyProductionSeasonalResponse
     */
    'averages'?: Array<SeasonalYearByWeekAverageModel> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyProductionSeasonalResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityWeeklyProductionSeasonalResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyProductionSeasonalResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityWeeklyProductionModelSeasonalYearByWeekSummaryModel>}
     * @memberof GetCommodityWeeklyProductionSeasonalResponse
     */
    'rows'?: Array<CommodityWeeklyProductionModelSeasonalYearByWeekSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyProductionSeasonalResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyProductionSeasonalResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetCommodityWeeklyProductionSeasonalResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyProductionSeasonalResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyProductionSeasonalResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCommodityWeeklyStocksSeasonalResponse
 */
export interface GetCommodityWeeklyStocksSeasonalResponse {
    /**
     * 
     * @type {Array<SeasonalYearByWeekAverageModel>}
     * @memberof GetCommodityWeeklyStocksSeasonalResponse
     */
    'averages'?: Array<SeasonalYearByWeekAverageModel> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyStocksSeasonalResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetCommodityWeeklyStocksSeasonalResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyStocksSeasonalResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityWeeklyStockModelSeasonalYearByWeekSummaryModel>}
     * @memberof GetCommodityWeeklyStocksSeasonalResponse
     */
    'rows'?: Array<CommodityWeeklyStockModelSeasonalYearByWeekSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyStocksSeasonalResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommodityWeeklyStocksSeasonalResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetCommodityWeeklyStocksSeasonalResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyStocksSeasonalResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommodityWeeklyStocksSeasonalResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCurrencyConversionChartResponse
 */
export interface GetCurrencyConversionChartResponse {
    /**
     * 
     * @type {Currency}
     * @memberof GetCurrencyConversionChartResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {string}
     * @memberof GetCurrencyConversionChartResponse
     */
    'reutersInstrumentCodePrefix'?: string | null;
    /**
     * 
     * @type {GetCurrencyConversionChartResponseCurrencyConversionMap}
     * @memberof GetCurrencyConversionChartResponse
     */
    'currencyConversionMap'?: GetCurrencyConversionChartResponseCurrencyConversionMap | null;
}
/**
 * 
 * @export
 * @interface GetCurrencyConversionChartResponseCurrencyConversionMap
 */
export interface GetCurrencyConversionChartResponseCurrencyConversionMap {
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'AUD'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'BRL'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'CAD'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'CNY'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'EUR'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'GBP'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'JPY'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'KRW'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'MXN'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'MYR'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'NZD'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'THB'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'USC'?: Array<CurrencyConversionRate>;
    /**
     * 
     * @type {Array<CurrencyConversionRate>}
     * @memberof GetCurrencyConversionChartResponseCurrencyConversionMap
     */
    'USD'?: Array<CurrencyConversionRate>;
}
/**
 * 
 * @export
 * @interface GetDemeterCommentaryResponse
 */
export interface GetDemeterCommentaryResponse {
    /**
     * 
     * @type {DemeterCommentaryModel}
     * @memberof GetDemeterCommentaryResponse
     */
    'commentary'?: DemeterCommentaryModel;
}
/**
 * 
 * @export
 * @interface GetDemeterCompanyResponse
 */
export interface GetDemeterCompanyResponse {
    /**
     * 
     * @type {DemeterCompanyModel}
     * @memberof GetDemeterCompanyResponse
     */
    'demeterCompany'?: DemeterCompanyModel;
}
/**
 * 
 * @export
 * @interface GetDemeterDashboardPresetsResponse
 */
export interface GetDemeterDashboardPresetsResponse {
    /**
     * 
     * @type {DemeterDashboardPresetModel}
     * @memberof GetDemeterDashboardPresetsResponse
     */
    'dashboardPreset'?: DemeterDashboardPresetModel;
}
/**
 * 
 * @export
 * @interface GetDemeterNewsResponse
 */
export interface GetDemeterNewsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetDemeterNewsResponse
     */
    'articleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDemeterNewsResponse
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDemeterPermissionResponse
 */
export interface GetDemeterPermissionResponse {
    /**
     * 
     * @type {string}
     * @memberof GetDemeterPermissionResponse
     */
    'demeterPermissionGuid'?: string | null;
    /**
     * 
     * @type {Array<DemeterPermissionModel>}
     * @memberof GetDemeterPermissionResponse
     */
    'permissions'?: Array<DemeterPermissionModel> | null;
}
/**
 * 
 * @export
 * @interface GetDemeterUserDataFileResponse
 */
export interface GetDemeterUserDataFileResponse {
    /**
     * 
     * @type {string}
     * @memberof GetDemeterUserDataFileResponse
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDemeterUserDataFileResponse
     */
    'fileContent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDemeterUserDataFileResponse
     */
    'contentType'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDemeterUserDataResponse
 */
export interface GetDemeterUserDataResponse {
    /**
     * 
     * @type {DemeterUserDataModel}
     * @memberof GetDemeterUserDataResponse
     */
    'demeterUserData'?: DemeterUserDataModel;
}
/**
 * 
 * @export
 * @interface GetDemeterUserFeatureResponse
 */
export interface GetDemeterUserFeatureResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetDemeterUserFeatureResponse
     */
    'isOn'?: boolean;
}
/**
 * 
 * @export
 * @interface GetDemeterUserPreferenceResponse
 */
export interface GetDemeterUserPreferenceResponse {
    /**
     * 
     * @type {DemeterUserPreferenceModel}
     * @memberof GetDemeterUserPreferenceResponse
     */
    'userPreference'?: DemeterUserPreferenceModel;
}
/**
 * 
 * @export
 * @interface GetDemeterUserResponse
 */
export interface GetDemeterUserResponse {
    /**
     * 
     * @type {DemeterUserModel}
     * @memberof GetDemeterUserResponse
     */
    'demeterUser'?: DemeterUserModel;
}
/**
 * 
 * @export
 * @interface GetDemeterUserSessionResponse
 */
export interface GetDemeterUserSessionResponse {
    /**
     * 
     * @type {GetTermsAndConditionsResponse}
     * @memberof GetDemeterUserSessionResponse
     */
    'getTermsAndConditionsResponse'?: GetTermsAndConditionsResponse;
    /**
     * 
     * @type {GetDemeterPermissionResponse}
     * @memberof GetDemeterUserSessionResponse
     */
    'getDemeterPermissionResponse'?: GetDemeterPermissionResponse;
    /**
     * 
     * @type {Array<DemeterUserStoreModel>}
     * @memberof GetDemeterUserSessionResponse
     */
    'userStores'?: Array<DemeterUserStoreModel> | null;
    /**
     * 
     * @type {string}
     * @memberof GetDemeterUserSessionResponse
     */
    'sessionToken'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetDemeterUserSessionResponse
     */
    'companyTermsAndConditionsNeedsAccepting'?: boolean | null;
    /**
     * 
     * @type {DemeterUserModel}
     * @memberof GetDemeterUserSessionResponse
     */
    'demeterUser'?: DemeterUserModel;
}
/**
 * 
 * @export
 * @interface GetDemeterUserStoreResponse
 */
export interface GetDemeterUserStoreResponse {
    /**
     * 
     * @type {DemeterUserStoreModel}
     * @memberof GetDemeterUserStoreResponse
     */
    'userStore'?: DemeterUserStoreModel;
}
/**
 * 
 * @export
 * @interface GetFarmerMarginCalculatorResponse
 */
export interface GetFarmerMarginCalculatorResponse {
    /**
     * 
     * @type {FarmerMarginCalculatorModel}
     * @memberof GetFarmerMarginCalculatorResponse
     */
    'farmerMarginCalculator'?: FarmerMarginCalculatorModel;
}
/**
 * 
 * @export
 * @interface GetFederalMilkMarketingOrderCalculatorResponse
 */
export interface GetFederalMilkMarketingOrderCalculatorResponse {
    /**
     * 
     * @type {number}
     * @memberof GetFederalMilkMarketingOrderCalculatorResponse
     */
    'cheeseBlockPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFederalMilkMarketingOrderCalculatorResponse
     */
    'cheeseBarrelPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFederalMilkMarketingOrderCalculatorResponse
     */
    'dryWheyPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFederalMilkMarketingOrderCalculatorResponse
     */
    'butterPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFederalMilkMarketingOrderCalculatorResponse
     */
    'nonFatDryMilkPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFederalMilkMarketingOrderCalculatorResponse
     */
    'cheeseBlockPercent'?: number;
}
/**
 * 
 * @export
 * @interface GetMarketIndicatorFactorDataResponse
 */
export interface GetMarketIndicatorFactorDataResponse {
    /**
     * 
     * @type {string}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'factorDisplayName'?: string | null;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'leadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {string}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'regionDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'commodityDisplayName'?: string | null;
    /**
     * 
     * @type {DemeterTableDefinitionType}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'tableDefinitionType'?: DemeterTableDefinitionType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'dataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'transformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'transformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorDataCombinationType}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'dataCombinationType'?: MarketIndicatorDataCombinationType;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'secondaryLeadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {string}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'secondaryRegionDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'secondaryCommodityDisplayName'?: string | null;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'secondaryRegion'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'secondarySubRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'secondaryCommodity'?: DemeterCommodity;
    /**
     * 
     * @type {DemeterTableDefinitionType}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'secondaryTableDefinitionType'?: DemeterTableDefinitionType;
    /**
     * 
     * @type {string}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'secondaryExtraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'secondaryDataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'secondaryTransformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'secondaryTransformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorTemplateType}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'templateType'?: MarketIndicatorTemplateType;
    /**
     * 
     * @type {Currency}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'dataSourceTags'?: Array<string> | null;
    /**
     * 
     * @type {Array<MarketIndicatorFactorAdditionalData>}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'additionalData'?: Array<MarketIndicatorFactorAdditionalData> | null;
    /**
     * 
     * @type {Array<MarketIndicatorFactorDataModel>}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'rows'?: Array<MarketIndicatorFactorDataModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetMarketIndicatorFactorDataResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetMarketIndicatorFactorResponse
 */
export interface GetMarketIndicatorFactorResponse {
    /**
     * 
     * @type {MarketIndicatorFactorModel}
     * @memberof GetMarketIndicatorFactorResponse
     */
    'marketIndicatorFactor'?: MarketIndicatorFactorModel;
}
/**
 * 
 * @export
 * @interface GetMarketIndicatorResponse
 */
export interface GetMarketIndicatorResponse {
    /**
     * 
     * @type {MarketIndicatorModel}
     * @memberof GetMarketIndicatorResponse
     */
    'marketIndicator'?: MarketIndicatorModel;
    /**
     * 
     * @type {Array<MarketIndicatorOutlookResultModel>}
     * @memberof GetMarketIndicatorResponse
     */
    'rows'?: Array<MarketIndicatorOutlookResultModel> | null;
    /**
     * 
     * @type {Array<MarketIndicatorOutlookResultModel>}
     * @memberof GetMarketIndicatorResponse
     */
    'hedgeMonitorProRows'?: Array<MarketIndicatorOutlookResultModel> | null;
}
/**
 * 
 * @export
 * @interface GetMarketIndicatorReviewCurrentResponse
 */
export interface GetMarketIndicatorReviewCurrentResponse {
    /**
     * 
     * @type {MarketIndicatorReviewModel}
     * @memberof GetMarketIndicatorReviewCurrentResponse
     */
    'marketIndicatorReview'?: MarketIndicatorReviewModel;
}
/**
 * 
 * @export
 * @interface GetPositionsCalculatorResponse
 */
export interface GetPositionsCalculatorResponse {
    /**
     * 
     * @type {PositionsCalculatorModel}
     * @memberof GetPositionsCalculatorResponse
     */
    'positionCalculator'?: PositionsCalculatorModel;
}
/**
 * 
 * @export
 * @interface GetPresentationTemplateResponse
 */
export interface GetPresentationTemplateResponse {
    /**
     * 
     * @type {PresentationTemplateModel}
     * @memberof GetPresentationTemplateResponse
     */
    'presentationTemplate'?: PresentationTemplateModel;
}
/**
 * 
 * @export
 * @interface GetTermsAndConditionsResponse
 */
export interface GetTermsAndConditionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetTermsAndConditionsResponse
     */
    'agreed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTermsAndConditionsResponse
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetTermsAndConditionsResponse
     */
    'version'?: number | null;
}
/**
 * 
 * @export
 * @interface GetTestMarketIndicatorFactorDataRequest
 */
export interface GetTestMarketIndicatorFactorDataRequest {
    /**
     * 
     * @type {string}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'leadingIndicatorType': LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'dataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'transformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'transformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorDataCombinationType}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'dataCombinationType'?: MarketIndicatorDataCombinationType;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'secondaryLeadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'secondaryRegion'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'secondarySubRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'secondaryCommodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'secondaryExtraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'secondaryDataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'secondaryTransformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'secondaryTransformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorTemplateType}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'templateType': MarketIndicatorTemplateType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'parameters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof GetTestMarketIndicatorFactorDataRequest
     */
    'market'?: DemeterMarket;
}
/**
 * 
 * @export
 * @interface GetUnitOfMeasureConversionChartResponse
 */
export interface GetUnitOfMeasureConversionChartResponse {
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GetUnitOfMeasureConversionChartResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap}
     * @memberof GetUnitOfMeasureConversionChartResponse
     */
    'unitOfMeasureConversionMap'?: GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap | null;
}
/**
 * 
 * @export
 * @interface GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
 */
export interface GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap {
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Bag'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Barrel'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'BillionCubicFeet'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Bushel'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Contracts'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Currency'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Days'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'DegreesFahrenheit'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'EuropeanUnionAllowance'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Gallon'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Head'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'HundredKilogram'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'HundredWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Imperial'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Index'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Kilogram'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'KilogramMilkSolids'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Liter'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'MegaWatt'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'MegaWattHour'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Metric'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'MetricTon'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'MillionBritishThermalUnits'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'MillionBushels'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'MillionCubicFeet'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'MillionGallons'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'MillionLiters'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'MillionMetricTons'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'MillionPounds'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Pound'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'PoundSingle'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'ShortTon'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'ThousandBarrels'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'ThousandBarrelsPerDay'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'ThousandHeads'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'ThousandMetricTons'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'ThousandPounds'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'ThousandShortTons'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap
     */
    'Unknown'?: number;
}
/**
 * 
 * @export
 * @interface GetValueMatrixCalculatorResponse
 */
export interface GetValueMatrixCalculatorResponse {
    /**
     * 
     * @type {ValueMatrixCalculatorModel}
     * @memberof GetValueMatrixCalculatorResponse
     */
    'valueMatrixCalculator'?: ValueMatrixCalculatorModel;
}
/**
 * 
 * @export
 * @interface GlobalDairyTradeWeeklyEventModel
 */
export interface GlobalDairyTradeWeeklyEventModel {
    /**
     * 
     * @type {number}
     * @memberof GlobalDairyTradeWeeklyEventModel
     */
    'event': number;
    /**
     * 
     * @type {string}
     * @memberof GlobalDairyTradeWeeklyEventModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface GlobalDairyTradeWeeklyPriceModel
 */
export interface GlobalDairyTradeWeeklyPriceModel {
    /**
     * 
     * @type {string}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'globalDairyTradeWeeklyPriceGuid': string;
    /**
     * 
     * @type {number}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'event': number;
    /**
     * 
     * @type {number}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'week': number;
    /**
     * 
     * @type {number}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'contractYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'contractMonth'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'groupDisplayName'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'priceFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'dataFrequency': DemeterDataFrequency;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'dataSource': DemeterDataSource;
    /**
     * 
     * @type {string}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {number}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'previousValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'percentChange'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'netChange'?: number | null;
    /**
     * 
     * @type {Array<PriceFrequencyDefinition>}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'priceFrequencyDefinitions': Array<PriceFrequencyDefinition>;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'hasPricingData': boolean;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {number}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'isActualValue': boolean;
    /**
     * 
     * @type {string}
     * @memberof GlobalDairyTradeWeeklyPriceModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface GlobalDairyTradeWeeklyPriceSummaryModel
 */
export interface GlobalDairyTradeWeeklyPriceSummaryModel {
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof GlobalDairyTradeWeeklyPriceSummaryModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof GlobalDairyTradeWeeklyPriceSummaryModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {Array<GlobalDairyTradeWeeklyPriceModel>}
     * @memberof GlobalDairyTradeWeeklyPriceSummaryModel
     */
    'globalDairyTradeWeeklyPrices': Array<GlobalDairyTradeWeeklyPriceModel>;
}
/**
 * 
 * @export
 * @interface ImportDemeterUserModel
 */
export interface ImportDemeterUserModel {
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserModel
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserModel
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserModel
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserModel
     */
    'trialExpirationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserModel
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface ImportDemeterUserResult
 */
export interface ImportDemeterUserResult {
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserResult
     */
    'demeterTrialUserGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserResult
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserResult
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserResult
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserResult
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserResult
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserResult
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserResult
     */
    'trialExpirationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserResult
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImportDemeterUserResult
     */
    'row': number;
    /**
     * 
     * @type {boolean}
     * @memberof ImportDemeterUserResult
     */
    'isSuccess': boolean;
    /**
     * 
     * @type {string}
     * @memberof ImportDemeterUserResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LeadingIndicatorType = {
    CommodityDailyOther: 'CommodityDailyOther',
    CommodityFuturesIndex: 'CommodityFuturesIndex',
    CommodityFuturesPrice: 'CommodityFuturesPrice',
    CommodityMonthlyDomestics: 'CommodityMonthlyDomestics',
    CommodityMonthlyExports: 'CommodityMonthlyExports',
    CommodityMonthlyImports: 'CommodityMonthlyImports',
    CommodityMonthlyPrices: 'CommodityMonthlyPrices',
    CommodityMonthlyProduction: 'CommodityMonthlyProduction',
    CommodityMonthlyStocks: 'CommodityMonthlyStocks',
    CommodityOtcPrices: 'CommodityOtcPrices',
    CommodityProgress: 'CommodityProgress',
    CommoditySpotPrices: 'CommoditySpotPrices',
    CommodityWeeklyPrices: 'CommodityWeeklyPrices',
    GlobalDairyTradeWeeklyPrices: 'GlobalDairyTradeWeeklyPrices',
    UserData: 'UserData'
} as const;

export type LeadingIndicatorType = typeof LeadingIndicatorType[keyof typeof LeadingIndicatorType];


/**
 * 
 * @export
 * @interface ListBudgetCalculatorsResponse
 */
export interface ListBudgetCalculatorsResponse {
    /**
     * 
     * @type {Array<BudgetCalculatorModel>}
     * @memberof ListBudgetCalculatorsResponse
     */
    'rows'?: Array<BudgetCalculatorModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListBudgetCalculatorsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListBudgetCalculatorsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListBudgetCalculatorsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListBudgetCalculatorsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListBudgetCalculatorsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCalculationEngineInputsByGroupResponse
 */
export interface ListCalculationEngineInputsByGroupResponse {
    /**
     * 
     * @type {Array<DemeterCalculationEngineInputsGroupModel>}
     * @memberof ListCalculationEngineInputsByGroupResponse
     */
    'rows'?: Array<DemeterCalculationEngineInputsGroupModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCalculationEngineInputsByGroupResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCalculationEngineInputsByGroupResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCalculationEngineInputsByGroupResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCalculationEngineInputsByGroupResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCalculationEngineInputsByGroupResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCalculationEngineInputsResponse
 */
export interface ListCalculationEngineInputsResponse {
    /**
     * 
     * @type {Array<DemeterCalculationEngineInputsModel>}
     * @memberof ListCalculationEngineInputsResponse
     */
    'rows'?: Array<DemeterCalculationEngineInputsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCalculationEngineInputsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCalculationEngineInputsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCalculationEngineInputsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCalculationEngineInputsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCalculationEngineInputsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCalculationEnginesResponse
 */
export interface ListCalculationEnginesResponse {
    /**
     * 
     * @type {Array<DemeterCalculationEngineModel>}
     * @memberof ListCalculationEnginesResponse
     */
    'rows'?: Array<DemeterCalculationEngineModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCalculationEnginesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCalculationEnginesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCalculationEnginesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCalculationEnginesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCalculationEnginesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityBasisPricesResponse
 */
export interface ListCommodityBasisPricesResponse {
    /**
     * 
     * @type {string}
     * @memberof ListCommodityBasisPricesResponse
     */
    'reutersInstrumentCodePrefix'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityBasisPricesResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityBasisPricesResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityBasisPricesResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityBasisPricesResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityBasisPricesResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommoditySpotPriceModel>}
     * @memberof ListCommodityBasisPricesResponse
     */
    'rows'?: Array<CommoditySpotPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityBasisPricesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityBasisPricesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityBasisPricesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityBasisPricesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityBasisPricesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityDailyDomesticsResponse
 */
export interface ListCommodityDailyDomesticsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityDailyDomesticsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityDailyDomesticsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityDailyDomesticsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityDailyDomesticsResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityDailyDomesticsResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityDailyDomesticModel>}
     * @memberof ListCommodityDailyDomesticsResponse
     */
    'rows'?: Array<CommodityDailyDomesticModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityDailyDomesticsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityDailyDomesticsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityDailyDomesticsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityDailyDomesticsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityDailyDomesticsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityDailyExportsResponse
 */
export interface ListCommodityDailyExportsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityDailyExportsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityDailyExportsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityDailyExportsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityDailyExportsResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityDailyExportsResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityDailyExportModel>}
     * @memberof ListCommodityDailyExportsResponse
     */
    'rows'?: Array<CommodityDailyExportModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityDailyExportsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityDailyExportsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityDailyExportsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityDailyExportsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityDailyExportsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityDailyImportsResponse
 */
export interface ListCommodityDailyImportsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityDailyImportsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityDailyImportsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityDailyImportsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityDailyImportsResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityDailyImportsResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityDailyImportModel>}
     * @memberof ListCommodityDailyImportsResponse
     */
    'rows'?: Array<CommodityDailyImportModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityDailyImportsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityDailyImportsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityDailyImportsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityDailyImportsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityDailyImportsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityDailyProductionResponse
 */
export interface ListCommodityDailyProductionResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityDailyProductionResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityDailyProductionResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityDailyProductionResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityDailyProductionResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityDailyProductionResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityDailyProductionModel>}
     * @memberof ListCommodityDailyProductionResponse
     */
    'rows'?: Array<CommodityDailyProductionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityDailyProductionResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityDailyProductionResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityDailyProductionResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityDailyProductionResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityDailyProductionResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyDomesticsAggregatedRequest
 */
export interface ListCommodityMonthlyDomesticsAggregatedRequest {
    /**
     * 
     * @type {Array<CommoditySelection>}
     * @memberof ListCommodityMonthlyDomesticsAggregatedRequest
     */
    'commoditySelections': Array<CommoditySelection>;
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyDomesticsAggregatedRequest
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyDomesticsAggregatedRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyDomesticsAggregatedRequest
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyDomesticsResponse
 */
export interface ListCommodityMonthlyDomesticsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyDomesticsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyDomesticsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyDomesticsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyDomesticsResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyDomesticsResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityMonthlyDomesticModel>}
     * @memberof ListCommodityMonthlyDomesticsResponse
     */
    'rows'?: Array<CommodityMonthlyDomesticModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyDomesticsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyDomesticsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityMonthlyDomesticsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyDomesticsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyDomesticsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyExportDestinationsResponse
 */
export interface ListCommodityMonthlyExportDestinationsResponse {
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyExportDestinationsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyExportDestinationsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityMonthlyExportDestinationModel>}
     * @memberof ListCommodityMonthlyExportDestinationsResponse
     */
    'rows'?: Array<CommodityMonthlyExportDestinationModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyExportDestinationsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyExportDestinationsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityMonthlyExportDestinationsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyExportDestinationsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyExportDestinationsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyExportsAggregatedRequest
 */
export interface ListCommodityMonthlyExportsAggregatedRequest {
    /**
     * 
     * @type {Array<CommoditySelection>}
     * @memberof ListCommodityMonthlyExportsAggregatedRequest
     */
    'commoditySelections': Array<CommoditySelection>;
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyExportsAggregatedRequest
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyExportsAggregatedRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyExportsAggregatedRequest
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyExportsResponse
 */
export interface ListCommodityMonthlyExportsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyExportsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyExportsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyExportsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyExportsResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyExportsResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityMonthlyExportModel>}
     * @memberof ListCommodityMonthlyExportsResponse
     */
    'rows'?: Array<CommodityMonthlyExportModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyExportsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyExportsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityMonthlyExportsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyExportsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyExportsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyImportOriginsResponse
 */
export interface ListCommodityMonthlyImportOriginsResponse {
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyImportOriginsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyImportOriginsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityMonthlyImportOriginModel>}
     * @memberof ListCommodityMonthlyImportOriginsResponse
     */
    'rows'?: Array<CommodityMonthlyImportOriginModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyImportOriginsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyImportOriginsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityMonthlyImportOriginsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyImportOriginsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyImportOriginsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyImportsAggregatedRequest
 */
export interface ListCommodityMonthlyImportsAggregatedRequest {
    /**
     * 
     * @type {Array<CommoditySelection>}
     * @memberof ListCommodityMonthlyImportsAggregatedRequest
     */
    'commoditySelections': Array<CommoditySelection>;
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyImportsAggregatedRequest
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyImportsAggregatedRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyImportsAggregatedRequest
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyImportsResponse
 */
export interface ListCommodityMonthlyImportsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyImportsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyImportsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyImportsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyImportsResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyImportsResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityMonthlyImportModel>}
     * @memberof ListCommodityMonthlyImportsResponse
     */
    'rows'?: Array<CommodityMonthlyImportModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyImportsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyImportsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityMonthlyImportsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyImportsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyImportsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyMarginsResponse
 */
export interface ListCommodityMonthlyMarginsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyMarginsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyMarginsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyMarginsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyMarginsResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyMarginsResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityMonthlyMarginModel>}
     * @memberof ListCommodityMonthlyMarginsResponse
     */
    'rows'?: Array<CommodityMonthlyMarginModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyMarginsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyMarginsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityMonthlyMarginsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyMarginsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyMarginsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyOtherResponse
 */
export interface ListCommodityMonthlyOtherResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyOtherResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyOtherResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyOtherResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyOtherResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyOtherResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityMonthlyOtherModel>}
     * @memberof ListCommodityMonthlyOtherResponse
     */
    'rows'?: Array<CommodityMonthlyOtherModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyOtherResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyOtherResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityMonthlyOtherResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyOtherResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyOtherResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyPricesResponse
 */
export interface ListCommodityMonthlyPricesResponse {
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'reutersInstrumentCodePrefix'?: string | null;
    /**
     * 
     * @type {Array<MarketPriceModel>}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'futuresMarketPricesForwardCurve'?: Array<MarketPriceModel> | null;
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityMonthlyPriceModel>}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'rows'?: Array<CommodityMonthlyPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyPricesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyProductionAggregatedRequest
 */
export interface ListCommodityMonthlyProductionAggregatedRequest {
    /**
     * 
     * @type {Array<CommoditySelection>}
     * @memberof ListCommodityMonthlyProductionAggregatedRequest
     */
    'commoditySelections': Array<CommoditySelection>;
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyProductionAggregatedRequest
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyProductionAggregatedRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyProductionAggregatedRequest
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyProductionResponse
 */
export interface ListCommodityMonthlyProductionResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyProductionResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyProductionResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyProductionResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyProductionResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyProductionResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityMonthlyProductionModel>}
     * @memberof ListCommodityMonthlyProductionResponse
     */
    'rows'?: Array<CommodityMonthlyProductionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyProductionResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyProductionResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityMonthlyProductionResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyProductionResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyProductionResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlySalesResponse
 */
export interface ListCommodityMonthlySalesResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlySalesResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlySalesResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlySalesResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlySalesResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlySalesResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityMonthlySalesModel>}
     * @memberof ListCommodityMonthlySalesResponse
     */
    'rows'?: Array<CommodityMonthlySalesModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlySalesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlySalesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityMonthlySalesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlySalesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlySalesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyStocksAggregatedRequest
 */
export interface ListCommodityMonthlyStocksAggregatedRequest {
    /**
     * 
     * @type {Array<CommoditySelection>}
     * @memberof ListCommodityMonthlyStocksAggregatedRequest
     */
    'commoditySelections': Array<CommoditySelection>;
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyStocksAggregatedRequest
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyStocksAggregatedRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyStocksAggregatedRequest
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
}
/**
 * 
 * @export
 * @interface ListCommodityMonthlyStocksResponse
 */
export interface ListCommodityMonthlyStocksResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityMonthlyStocksResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityMonthlyStocksResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyStocksResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyStocksResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityMonthlyStocksResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityMonthlyStockModel>}
     * @memberof ListCommodityMonthlyStocksResponse
     */
    'rows'?: Array<CommodityMonthlyStockModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyStocksResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityMonthlyStocksResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityMonthlyStocksResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyStocksResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityMonthlyStocksResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityOtcPricesForwardCurveResponse
 */
export interface ListCommodityOtcPricesForwardCurveResponse {
    /**
     * 
     * @type {Array<CommodityOtcPriceModel>}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'currentOtcPrices'?: Array<CommodityOtcPriceModel> | null;
    /**
     * 
     * @type {Array<CommodityOtcPriceModel>}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'asOfDateOtcPrices'?: Array<CommodityOtcPriceModel> | null;
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityOtcPriceModel>}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'rows'?: Array<CommodityOtcPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityOtcPricesForwardCurveResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityOtcPricesResponse
 */
export interface ListCommodityOtcPricesResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityOtcPricesResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityOtcPricesResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityOtcPricesResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityOtcPricesResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityOtcPricesResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityOtcPriceModel>}
     * @memberof ListCommodityOtcPricesResponse
     */
    'rows'?: Array<CommodityOtcPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityOtcPricesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityOtcPricesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityOtcPricesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityOtcPricesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityOtcPricesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityPricesByFrequencyResponse
 */
export interface ListCommodityPricesByFrequencyResponse {
    /**
     * 
     * @type {Array<CommodityPriceModel>}
     * @memberof ListCommodityPricesByFrequencyResponse
     */
    'rows'?: Array<CommodityPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityPricesByFrequencyResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityPricesByFrequencyResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityPricesByFrequencyResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityPricesByFrequencyResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityPricesByFrequencyResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommoditySpotPricesResponse
 */
export interface ListCommoditySpotPricesResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommoditySpotPricesResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommoditySpotPricesResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommoditySpotPricesResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommoditySpotPricesResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommoditySpotPricesResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommoditySpotPriceModel>}
     * @memberof ListCommoditySpotPricesResponse
     */
    'rows'?: Array<CommoditySpotPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommoditySpotPricesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommoditySpotPricesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommoditySpotPricesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommoditySpotPricesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommoditySpotPricesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommoditySpotPricesSummaryResponse
 */
export interface ListCommoditySpotPricesSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof ListCommoditySpotPricesSummaryResponse
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof ListCommoditySpotPricesSummaryResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommoditySpotPricesSummaryResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommoditySpotPricesSummaryResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommoditySpotPricesSummaryResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommoditySpotPricesSummaryResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommoditySpotPriceSummaryModel>}
     * @memberof ListCommoditySpotPricesSummaryResponse
     */
    'rows'?: Array<CommoditySpotPriceSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommoditySpotPricesSummaryResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommoditySpotPricesSummaryResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommoditySpotPricesSummaryResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommoditySpotPricesSummaryResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommoditySpotPricesSummaryResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityWeeklyDomesticsResponse
 */
export interface ListCommodityWeeklyDomesticsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityWeeklyDomesticsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityWeeklyDomesticsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyDomesticsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyDomesticsResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityWeeklyDomesticsResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityWeeklyDomesticModel>}
     * @memberof ListCommodityWeeklyDomesticsResponse
     */
    'rows'?: Array<CommodityWeeklyDomesticModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyDomesticsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyDomesticsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityWeeklyDomesticsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyDomesticsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyDomesticsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityWeeklyExportsResponse
 */
export interface ListCommodityWeeklyExportsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityWeeklyExportsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityWeeklyExportsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyExportsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyExportsResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityWeeklyExportsResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityWeeklyExportModel>}
     * @memberof ListCommodityWeeklyExportsResponse
     */
    'rows'?: Array<CommodityWeeklyExportModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyExportsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyExportsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityWeeklyExportsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyExportsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyExportsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityWeeklyImportsResponse
 */
export interface ListCommodityWeeklyImportsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityWeeklyImportsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityWeeklyImportsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyImportsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyImportsResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityWeeklyImportsResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityWeeklyImportModel>}
     * @memberof ListCommodityWeeklyImportsResponse
     */
    'rows'?: Array<CommodityWeeklyImportModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyImportsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyImportsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityWeeklyImportsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyImportsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyImportsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityWeeklyPricesResponse
 */
export interface ListCommodityWeeklyPricesResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityWeeklyPricesResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityWeeklyPricesResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyPricesResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyPricesResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityWeeklyPricesResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityWeeklyPriceModel>}
     * @memberof ListCommodityWeeklyPricesResponse
     */
    'rows'?: Array<CommodityWeeklyPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyPricesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyPricesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityWeeklyPricesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyPricesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyPricesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityWeeklyPricesSummaryResponse
 */
export interface ListCommodityWeeklyPricesSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyPricesSummaryResponse
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityWeeklyPricesSummaryResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityWeeklyPricesSummaryResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyPricesSummaryResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyPricesSummaryResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityWeeklyPricesSummaryResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityWeeklyPriceSummaryModel>}
     * @memberof ListCommodityWeeklyPricesSummaryResponse
     */
    'rows'?: Array<CommodityWeeklyPriceSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyPricesSummaryResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyPricesSummaryResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityWeeklyPricesSummaryResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyPricesSummaryResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyPricesSummaryResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityWeeklyProductionResponse
 */
export interface ListCommodityWeeklyProductionResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityWeeklyProductionResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityWeeklyProductionResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyProductionResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyProductionResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityWeeklyProductionResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityWeeklyProductionModel>}
     * @memberof ListCommodityWeeklyProductionResponse
     */
    'rows'?: Array<CommodityWeeklyProductionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyProductionResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyProductionResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityWeeklyProductionResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyProductionResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyProductionResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityWeeklyStocksResponse
 */
export interface ListCommodityWeeklyStocksResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityWeeklyStocksResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityWeeklyStocksResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyStocksResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyStocksResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityWeeklyStocksResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityWeeklyStockModel>}
     * @memberof ListCommodityWeeklyStocksResponse
     */
    'rows'?: Array<CommodityWeeklyStockModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyStocksResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityWeeklyStocksResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityWeeklyStocksResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyStocksResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityWeeklyStocksResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityYearlyBalancesResponse
 */
export interface ListCommodityYearlyBalancesResponse {
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityYearlyBalancesResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityYearlyBalancesResponse
     */
    'tagMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityYearlyBalancesResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<CommodityYearlyBalanceByYearModel>}
     * @memberof ListCommodityYearlyBalancesResponse
     */
    'rows'?: Array<CommodityYearlyBalanceByYearModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityYearlyBalancesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityYearlyBalancesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityYearlyBalancesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityYearlyBalancesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityYearlyBalancesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityYearlyPricesResponse
 */
export interface ListCommodityYearlyPricesResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListCommodityYearlyPricesResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListCommodityYearlyPricesResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityYearlyPricesResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityYearlyPricesResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListCommodityYearlyPricesResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<CommodityYearlyPriceModel>}
     * @memberof ListCommodityYearlyPricesResponse
     */
    'rows'?: Array<CommodityYearlyPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityYearlyPricesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityYearlyPricesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityYearlyPricesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityYearlyPricesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityYearlyPricesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterCompaniesResponse
 */
export interface ListDemeterCompaniesResponse {
    /**
     * 
     * @type {Array<DemeterCompanyModel>}
     * @memberof ListDemeterCompaniesResponse
     */
    'rows'?: Array<DemeterCompanyModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterCompaniesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterCompaniesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterCompaniesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterCompaniesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterCompaniesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterFundamentalDataResponse
 */
export interface ListDemeterFundamentalDataResponse {
    /**
     * 
     * @type {DemeterFundamental}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'fundamental'?: DemeterFundamental;
    /**
     * 
     * @type {DemeterSubFundamental}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'subFundamental'?: DemeterSubFundamental;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'dataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'dataSource'?: DemeterDataSource;
    /**
     * 
     * @type {Currency}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'alwaysShowActualValue'?: boolean;
    /**
     * 
     * @type {Array<DemeterFundamentalDataModel>}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'rows'?: Array<DemeterFundamentalDataModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterFundamentalDataResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterInsurancePositionsResponse
 */
export interface ListDemeterInsurancePositionsResponse {
    /**
     * 
     * @type {Array<DemeterInsurancePositionModel>}
     * @memberof ListDemeterInsurancePositionsResponse
     */
    'rows'?: Array<DemeterInsurancePositionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterInsurancePositionsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterInsurancePositionsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterInsurancePositionsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterInsurancePositionsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterInsurancePositionsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterLeadingIndicatorsResponse
 */
export interface ListDemeterLeadingIndicatorsResponse {
    /**
     * 
     * @type {Array<DemeterLeadingIndicatorModel>}
     * @memberof ListDemeterLeadingIndicatorsResponse
     */
    'rows'?: Array<DemeterLeadingIndicatorModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterLeadingIndicatorsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterLeadingIndicatorsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterLeadingIndicatorsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterLeadingIndicatorsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterLeadingIndicatorsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterNewsResponse
 */
export interface ListDemeterNewsResponse {
    /**
     * 
     * @type {Array<DemeterNewsModel>}
     * @memberof ListDemeterNewsResponse
     */
    'rows'?: Array<DemeterNewsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterNewsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterNewsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterNewsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterNewsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterNewsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterPageElementsResponse
 */
export interface ListDemeterPageElementsResponse {
    /**
     * 
     * @type {Array<DemeterPageElementModel>}
     * @memberof ListDemeterPageElementsResponse
     */
    'rows'?: Array<DemeterPageElementModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterPageElementsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterPageElementsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterPageElementsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterPageElementsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterPageElementsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterSymbolsResponse
 */
export interface ListDemeterSymbolsResponse {
    /**
     * 
     * @type {Array<DemeterSymbolModel>}
     * @memberof ListDemeterSymbolsResponse
     */
    'rows'?: Array<DemeterSymbolModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterSymbolsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterSymbolsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterSymbolsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterSymbolsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterSymbolsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterTableDefinitionsByCommodityResponse
 */
export interface ListDemeterTableDefinitionsByCommodityResponse {
    /**
     * 
     * @type {Array<DemeterTableDefinitionCommodityModel>}
     * @memberof ListDemeterTableDefinitionsByCommodityResponse
     */
    'rows'?: Array<DemeterTableDefinitionCommodityModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterTableDefinitionsByCommodityResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterTableDefinitionsByCommodityResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterTableDefinitionsByCommodityResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterTableDefinitionsByCommodityResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterTableDefinitionsByCommodityResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterTableDefinitionsResponse
 */
export interface ListDemeterTableDefinitionsResponse {
    /**
     * 
     * @type {Array<DemeterTableDefinitionRegionModel>}
     * @memberof ListDemeterTableDefinitionsResponse
     */
    'rows'?: Array<DemeterTableDefinitionRegionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterTableDefinitionsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterTableDefinitionsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterTableDefinitionsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterTableDefinitionsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterTableDefinitionsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterUserDataResponse
 */
export interface ListDemeterUserDataResponse {
    /**
     * 
     * @type {Array<DemeterUserDataModel>}
     * @memberof ListDemeterUserDataResponse
     */
    'rows'?: Array<DemeterUserDataModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterUserDataResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterUserDataResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterUserDataResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterUserDataResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterUserDataResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterUserFeaturesResponse
 */
export interface ListDemeterUserFeaturesResponse {
    /**
     * 
     * @type {ListDemeterUserFeaturesResponseFeatures}
     * @memberof ListDemeterUserFeaturesResponse
     */
    'features'?: ListDemeterUserFeaturesResponseFeatures | null;
}
/**
 * 
 * @export
 * @interface ListDemeterUserFeaturesResponseFeatures
 */
export interface ListDemeterUserFeaturesResponseFeatures {
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'Administration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'BasisCalculator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'BudgetCalculator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'Companies'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'DownloadsExports'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'DownloadsGeneral'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'DownloadsImports'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'FarmerMarginCalculator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'HedgeMonitorPro'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'Markets'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'MarketIndicators'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'PositionsCalculator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'PresentationTemplates'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'Refactors'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'UserData'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'Users'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'Valorization'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDemeterUserFeaturesResponseFeatures
     */
    'ValueMatrix'?: boolean;
}
/**
 * 
 * @export
 * @interface ListDemeterUserStoresResponse
 */
export interface ListDemeterUserStoresResponse {
    /**
     * 
     * @type {Array<DemeterUserStoreModel>}
     * @memberof ListDemeterUserStoresResponse
     */
    'rows'?: Array<DemeterUserStoreModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterUserStoresResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterUserStoresResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterUserStoresResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterUserStoresResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterUserStoresResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterUsersResponse
 */
export interface ListDemeterUsersResponse {
    /**
     * 
     * @type {Array<DemeterUserModel>}
     * @memberof ListDemeterUsersResponse
     */
    'rows'?: Array<DemeterUserModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterUsersResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterUsersResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterUsersResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterUsersResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterUsersResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDemeterWorkerSyncStatusesResponse
 */
export interface ListDemeterWorkerSyncStatusesResponse {
    /**
     * 
     * @type {Array<DemeterWorkerSyncStatusModel>}
     * @memberof ListDemeterWorkerSyncStatusesResponse
     */
    'rows'?: Array<DemeterWorkerSyncStatusModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterWorkerSyncStatusesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDemeterWorkerSyncStatusesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDemeterWorkerSyncStatusesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterWorkerSyncStatusesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDemeterWorkerSyncStatusesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListFarmerMarginCalculatorsResponse
 */
export interface ListFarmerMarginCalculatorsResponse {
    /**
     * 
     * @type {Array<FarmerMarginCalculatorModel>}
     * @memberof ListFarmerMarginCalculatorsResponse
     */
    'rows'?: Array<FarmerMarginCalculatorModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListFarmerMarginCalculatorsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListFarmerMarginCalculatorsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListFarmerMarginCalculatorsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListFarmerMarginCalculatorsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListFarmerMarginCalculatorsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListForeignExchangeRatesResponse
 */
export interface ListForeignExchangeRatesResponse {
    /**
     * 
     * @type {Array<ForeignExchangeRateModel>}
     * @memberof ListForeignExchangeRatesResponse
     */
    'rows'?: Array<ForeignExchangeRateModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListForeignExchangeRatesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListForeignExchangeRatesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListForeignExchangeRatesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListForeignExchangeRatesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListForeignExchangeRatesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListGlobalDairyTradeWeeklyEventsResponse
 */
export interface ListGlobalDairyTradeWeeklyEventsResponse {
    /**
     * 
     * @type {Array<GlobalDairyTradeWeeklyEventModel>}
     * @memberof ListGlobalDairyTradeWeeklyEventsResponse
     */
    'rows'?: Array<GlobalDairyTradeWeeklyEventModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListGlobalDairyTradeWeeklyEventsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListGlobalDairyTradeWeeklyEventsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListGlobalDairyTradeWeeklyEventsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListGlobalDairyTradeWeeklyEventsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListGlobalDairyTradeWeeklyEventsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListGlobalDairyTradeWeeklyPricesResponse
 */
export interface ListGlobalDairyTradeWeeklyPricesResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListGlobalDairyTradeWeeklyPricesResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListGlobalDairyTradeWeeklyPricesResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListGlobalDairyTradeWeeklyPricesResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListGlobalDairyTradeWeeklyPricesResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListGlobalDairyTradeWeeklyPricesResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<GlobalDairyTradeWeeklyPriceModel>}
     * @memberof ListGlobalDairyTradeWeeklyPricesResponse
     */
    'rows'?: Array<GlobalDairyTradeWeeklyPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListGlobalDairyTradeWeeklyPricesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListGlobalDairyTradeWeeklyPricesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListGlobalDairyTradeWeeklyPricesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListGlobalDairyTradeWeeklyPricesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListGlobalDairyTradeWeeklyPricesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListGlobalDairyTradeWeeklyPricesSummaryResponse
 */
export interface ListGlobalDairyTradeWeeklyPricesSummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'event'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'eventDate'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'oldestAsOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'filterTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {Array<GlobalDairyTradeWeeklyPriceSummaryModel>}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'rows'?: Array<GlobalDairyTradeWeeklyPriceSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListGlobalDairyTradeWeeklyPricesSummaryResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketIndicatorReviewsResponse
 */
export interface ListMarketIndicatorReviewsResponse {
    /**
     * 
     * @type {Array<MarketIndicatorReviewerModel>}
     * @memberof ListMarketIndicatorReviewsResponse
     */
    'reviewers'?: Array<MarketIndicatorReviewerModel> | null;
    /**
     * 
     * @type {Array<MarketIndicatorReviewModel>}
     * @memberof ListMarketIndicatorReviewsResponse
     */
    'rows'?: Array<MarketIndicatorReviewModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketIndicatorReviewsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketIndicatorReviewsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketIndicatorReviewsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketIndicatorReviewsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketIndicatorReviewsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketIndicatorSummariesResponse
 */
export interface ListMarketIndicatorSummariesResponse {
    /**
     * 
     * @type {Array<MarketIndicatorSummaryModel>}
     * @memberof ListMarketIndicatorSummariesResponse
     */
    'rows'?: Array<MarketIndicatorSummaryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketIndicatorSummariesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketIndicatorSummariesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketIndicatorSummariesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketIndicatorSummariesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketIndicatorSummariesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketIndicatorsResponse
 */
export interface ListMarketIndicatorsResponse {
    /**
     * 
     * @type {Array<MarketIndicatorModel>}
     * @memberof ListMarketIndicatorsResponse
     */
    'rows'?: Array<MarketIndicatorModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketIndicatorsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketIndicatorsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketIndicatorsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketIndicatorsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketIndicatorsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketPricesComparisonWithConversionsResponse
 */
export interface ListMarketPricesComparisonWithConversionsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListMarketPricesComparisonWithConversionsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListMarketPricesComparisonWithConversionsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesComparisonWithConversionsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesComparisonWithConversionsResponse
     */
    'dataSourceTag1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesComparisonWithConversionsResponse
     */
    'dataSourceTag2'?: string | null;
    /**
     * 
     * @type {Array<MarketPriceComparisonModel>}
     * @memberof ListMarketPricesComparisonWithConversionsResponse
     */
    'rows'?: Array<MarketPriceComparisonModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesComparisonWithConversionsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesComparisonWithConversionsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketPricesComparisonWithConversionsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesComparisonWithConversionsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesComparisonWithConversionsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketPricesForwardCurveWithConversionsResponse
 */
export interface ListMarketPricesForwardCurveWithConversionsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListMarketPricesForwardCurveWithConversionsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListMarketPricesForwardCurveWithConversionsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesForwardCurveWithConversionsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<MarketPriceModel>}
     * @memberof ListMarketPricesForwardCurveWithConversionsResponse
     */
    'currentMarketPrices'?: Array<MarketPriceModel> | null;
    /**
     * 
     * @type {Array<MarketPriceModel>}
     * @memberof ListMarketPricesForwardCurveWithConversionsResponse
     */
    'asOfDateMarketPrices'?: Array<MarketPriceModel> | null;
    /**
     * 
     * @type {Array<MarketPriceModel>}
     * @memberof ListMarketPricesForwardCurveWithConversionsResponse
     */
    'rows'?: Array<MarketPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesForwardCurveWithConversionsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesForwardCurveWithConversionsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketPricesForwardCurveWithConversionsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesForwardCurveWithConversionsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesForwardCurveWithConversionsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketPricesRollingRangeResponse
 */
export interface ListMarketPricesRollingRangeResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListMarketPricesRollingRangeResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListMarketPricesRollingRangeResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesRollingRangeResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<MarketPricesModel>}
     * @memberof ListMarketPricesRollingRangeResponse
     */
    'rows'?: Array<MarketPricesModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesRollingRangeResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesRollingRangeResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketPricesRollingRangeResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesRollingRangeResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesRollingRangeResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketPricesWithConversionsResponse
 */
export interface ListMarketPricesWithConversionsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof ListMarketPricesWithConversionsResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ListMarketPricesWithConversionsResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesWithConversionsResponse
     */
    'dataSourceTag'?: string | null;
    /**
     * 
     * @type {Array<MarketPriceModel>}
     * @memberof ListMarketPricesWithConversionsResponse
     */
    'rows'?: Array<MarketPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesWithConversionsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesWithConversionsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketPricesWithConversionsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesWithConversionsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesWithConversionsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListPositionsCalculatorsResponse
 */
export interface ListPositionsCalculatorsResponse {
    /**
     * 
     * @type {Array<PositionsCalculatorModel>}
     * @memberof ListPositionsCalculatorsResponse
     */
    'rows'?: Array<PositionsCalculatorModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListPositionsCalculatorsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListPositionsCalculatorsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListPositionsCalculatorsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListPositionsCalculatorsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListPositionsCalculatorsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListPresentationTemplatesResponse
 */
export interface ListPresentationTemplatesResponse {
    /**
     * 
     * @type {Array<PresentationTemplateModel>}
     * @memberof ListPresentationTemplatesResponse
     */
    'rows'?: Array<PresentationTemplateModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListPresentationTemplatesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListPresentationTemplatesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListPresentationTemplatesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListPresentationTemplatesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListPresentationTemplatesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListValueMatrixCalculatorsResponse
 */
export interface ListValueMatrixCalculatorsResponse {
    /**
     * 
     * @type {Array<ValueMatrixCalculatorModel>}
     * @memberof ListValueMatrixCalculatorsResponse
     */
    'rows'?: Array<ValueMatrixCalculatorModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListValueMatrixCalculatorsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListValueMatrixCalculatorsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListValueMatrixCalculatorsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListValueMatrixCalculatorsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListValueMatrixCalculatorsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarketIndicatorDataCombinationType = {
    Aggregate: 'Aggregate',
    Average: 'Average',
    Index: 'Index',
    Multiply: 'Multiply',
    Ratio: 'Ratio',
    Spread: 'Spread'
} as const;

export type MarketIndicatorDataCombinationType = typeof MarketIndicatorDataCombinationType[keyof typeof MarketIndicatorDataCombinationType];


/**
 * 
 * @export
 * @interface MarketIndicatorFactorAdditionalData
 */
export interface MarketIndicatorFactorAdditionalData {
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorAdditionalData
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorAdditionalData
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof MarketIndicatorFactorAdditionalData
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof MarketIndicatorFactorAdditionalData
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {Array<MarketIndicatorFactorDataModel>}
     * @memberof MarketIndicatorFactorAdditionalData
     */
    'rows'?: Array<MarketIndicatorFactorDataModel> | null;
}
/**
 * 
 * @export
 * @interface MarketIndicatorFactorDataModel
 */
export interface MarketIndicatorFactorDataModel {
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorFactorDataModel
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorDataModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof MarketIndicatorFactorDataModel
     */
    'isActualValue': boolean;
}
/**
 * 
 * @export
 * @interface MarketIndicatorFactorGroupModel
 */
export interface MarketIndicatorFactorGroupModel {
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorGroupModel
     */
    'displayGroupName': string;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorFactorGroupModel
     */
    'order': number;
    /**
     * 
     * @type {Array<MarketIndicatorFactorSummaryModel>}
     * @memberof MarketIndicatorFactorGroupModel
     */
    'factors': Array<MarketIndicatorFactorSummaryModel>;
}
/**
 * 
 * @export
 * @interface MarketIndicatorFactorModel
 */
export interface MarketIndicatorFactorModel {
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorModel
     */
    'marketIndicatorFactorGuid': string;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorModel
     */
    'parentMarketIndicatorFactorGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorModel
     */
    'marketIndicatorGuid': string;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorModel
     */
    'displayGroupName': string;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorModel
     */
    'displayName': string;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof MarketIndicatorFactorModel
     */
    'market'?: DemeterMarket;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof MarketIndicatorFactorModel
     */
    'leadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof MarketIndicatorFactorModel
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof MarketIndicatorFactorModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof MarketIndicatorFactorModel
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorModel
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof MarketIndicatorFactorModel
     */
    'dataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof MarketIndicatorFactorModel
     */
    'transformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorFactorModel
     */
    'transformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorDataCombinationType}
     * @memberof MarketIndicatorFactorModel
     */
    'dataCombinationType'?: MarketIndicatorDataCombinationType;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof MarketIndicatorFactorModel
     */
    'secondaryMarket'?: DemeterMarket;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof MarketIndicatorFactorModel
     */
    'secondaryLeadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof MarketIndicatorFactorModel
     */
    'secondaryRegion'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof MarketIndicatorFactorModel
     */
    'secondarySubRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof MarketIndicatorFactorModel
     */
    'secondaryCommodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorModel
     */
    'secondaryExtraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof MarketIndicatorFactorModel
     */
    'secondaryDataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof MarketIndicatorFactorModel
     */
    'secondaryTransformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorFactorModel
     */
    'secondaryTransformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorTemplateType}
     * @memberof MarketIndicatorFactorModel
     */
    'templateType': MarketIndicatorTemplateType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MarketIndicatorFactorModel
     */
    'parameters': { [key: string]: string; };
    /**
     * 
     * @type {Correlation}
     * @memberof MarketIndicatorFactorModel
     */
    'correlation': Correlation;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorFactorModel
     */
    'multiplier': number;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorFactorModel
     */
    'order': number;
    /**
     * 
     * @type {MarketIndicatorOutlook}
     * @memberof MarketIndicatorFactorModel
     */
    'outlook': MarketIndicatorOutlook;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {Array<MarketIndicatorFactorModel>}
     * @memberof MarketIndicatorFactorModel
     */
    'subFactors': Array<MarketIndicatorFactorModel>;
}
/**
 * 
 * @export
 * @interface MarketIndicatorFactorSummaryModel
 */
export interface MarketIndicatorFactorSummaryModel {
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'marketIndicatorFactorGuid': string;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'displayName': string;
    /**
     * 
     * @type {MarketIndicatorTemplateType}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'templateType': MarketIndicatorTemplateType;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'multiplier': number;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'yearsOfData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'oneYearWeightedPercent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'daysToAverage1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'daysToAverage2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'daysToAverage3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'startMonth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'endMonth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'imageUrl'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'order': number;
    /**
     * 
     * @type {MarketIndicatorOutlook}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'outlook': MarketIndicatorOutlook;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'subFactorGuids': Array<string>;
    /**
     * 
     * @type {MarketIndicatorFactorSummaryModel}
     * @memberof MarketIndicatorFactorSummaryModel
     */
    'activeSubFactor': MarketIndicatorFactorSummaryModel;
}
/**
 * 
 * @export
 * @interface MarketIndicatorModel
 */
export interface MarketIndicatorModel {
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorModel
     */
    'marketIndicatorGuid': string;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof MarketIndicatorModel
     */
    'market'?: DemeterMarket;
    /**
     * 
     * @type {ExchangeType}
     * @memberof MarketIndicatorModel
     */
    'exchange'?: ExchangeType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof MarketIndicatorModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof MarketIndicatorModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof MarketIndicatorModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof MarketIndicatorModel
     */
    'dataSource'?: DemeterDataSource;
    /**
     * 
     * @type {boolean}
     * @memberof MarketIndicatorModel
     */
    'usePrices': boolean;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'contractNumber': number;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorModel
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'numberOfYears': number;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'currentPrice': number;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorModel
     */
    'currentPriceAsOfDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorModel
     */
    'currentPriceReutersInstrumentCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'currentPriceContractMonth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'currentPriceContractYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'priceDecile'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'spotPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'spotPriceDecile'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorModel
     */
    'spotPriceAsOfDate'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof MarketIndicatorModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof MarketIndicatorModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {MarketIndicatorOutlook}
     * @memberof MarketIndicatorModel
     */
    'outlook': MarketIndicatorOutlook;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'outlookValue': number;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'outlookDecile': number;
    /**
     * 
     * @type {MarketIndicatorOutlook}
     * @memberof MarketIndicatorModel
     */
    'precipiceOutlook'?: MarketIndicatorOutlook;
    /**
     * 
     * @type {MarketIndicatorOutlook}
     * @memberof MarketIndicatorModel
     */
    'hedgeMonitorProOutlook'?: MarketIndicatorOutlook;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof MarketIndicatorModel
     */
    'isPublished': boolean;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorModel
     */
    'version': number;
    /**
     * 
     * @type {MarketIndicatorReviewStatus}
     * @memberof MarketIndicatorModel
     */
    'reviewStatus'?: MarketIndicatorReviewStatus;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorModel
     */
    'createdAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorModel
     */
    'modifiedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorModel
     */
    'reviewedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorModel
     */
    'reviewedBy'?: string | null;
    /**
     * 
     * @type {Array<MarketIndicatorFactorGroupModel>}
     * @memberof MarketIndicatorModel
     */
    'factorGroups': Array<MarketIndicatorFactorGroupModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarketIndicatorOutlook = {
    Flat: 'Flat',
    Bearish: 'Bearish',
    VeryBearish: 'VeryBearish',
    Unavailable: 'Unavailable',
    VeryBullish: 'VeryBullish',
    Bullish: 'Bullish'
} as const;

export type MarketIndicatorOutlook = typeof MarketIndicatorOutlook[keyof typeof MarketIndicatorOutlook];


/**
 * 
 * @export
 * @interface MarketIndicatorOutlookResultModel
 */
export interface MarketIndicatorOutlookResultModel {
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorOutlookResultModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorOutlookResultModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {MarketIndicatorOutlook}
     * @memberof MarketIndicatorOutlookResultModel
     */
    'outlook': MarketIndicatorOutlook;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorOutlookResultModel
     */
    'outlookDecile': number;
}
/**
 * 
 * @export
 * @interface MarketIndicatorQuarterDecileModel
 */
export interface MarketIndicatorQuarterDecileModel {
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorQuarterDecileModel
     */
    'quarter': number;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorQuarterDecileModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorQuarterDecileModel
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorQuarterDecileModel
     */
    'decile': number;
}
/**
 * 
 * @export
 * @interface MarketIndicatorReviewModel
 */
export interface MarketIndicatorReviewModel {
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorReviewModel
     */
    'reviewedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorReviewModel
     */
    'reviewedBy': string;
    /**
     * 
     * @type {MarketIndicatorReviewStatus}
     * @memberof MarketIndicatorReviewModel
     */
    'status': MarketIndicatorReviewStatus;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorReviewModel
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarketIndicatorReviewStatus = {
    Approved: 'Approved',
    Rejected: 'Rejected'
} as const;

export type MarketIndicatorReviewStatus = typeof MarketIndicatorReviewStatus[keyof typeof MarketIndicatorReviewStatus];


/**
 * 
 * @export
 * @interface MarketIndicatorReviewerModel
 */
export interface MarketIndicatorReviewerModel {
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorReviewerModel
     */
    'demeterUserGuid': string;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorReviewerModel
     */
    'demeterUserTrialGuid': string;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorReviewerModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorReviewerModel
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface MarketIndicatorSummaryModel
 */
export interface MarketIndicatorSummaryModel {
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorSummaryModel
     */
    'marketIndicatorGuid': string;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof MarketIndicatorSummaryModel
     */
    'market'?: DemeterMarket;
    /**
     * 
     * @type {ExchangeType}
     * @memberof MarketIndicatorSummaryModel
     */
    'exchange'?: ExchangeType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof MarketIndicatorSummaryModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof MarketIndicatorSummaryModel
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof MarketIndicatorSummaryModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof MarketIndicatorSummaryModel
     */
    'dataSource'?: DemeterDataSource;
    /**
     * 
     * @type {boolean}
     * @memberof MarketIndicatorSummaryModel
     */
    'usePrices'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorSummaryModel
     */
    'contractNumber'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorSummaryModel
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorSummaryModel
     */
    'currentPrice': number;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorSummaryModel
     */
    'currentPriceAsOfDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndicatorSummaryModel
     */
    'currentPriceReutersInstrumentCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorSummaryModel
     */
    'currentPriceContractMonth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorSummaryModel
     */
    'currentPriceContractYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorSummaryModel
     */
    'monthOverMonthChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorSummaryModel
     */
    'rollingYearChangePercent'?: number | null;
    /**
     * 
     * @type {Currency}
     * @memberof MarketIndicatorSummaryModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof MarketIndicatorSummaryModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {MarketIndicatorOutlook}
     * @memberof MarketIndicatorSummaryModel
     */
    'outlook': MarketIndicatorOutlook;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorSummaryModel
     */
    'outlookValue': number;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorSummaryModel
     */
    'outlookDecile': number;
    /**
     * 
     * @type {MarketIndicatorOutlook}
     * @memberof MarketIndicatorSummaryModel
     */
    'precipiceOutlook'?: MarketIndicatorOutlook;
    /**
     * 
     * @type {MarketIndicatorOutlook}
     * @memberof MarketIndicatorSummaryModel
     */
    'hedgeMonitorProOutlook'?: MarketIndicatorOutlook;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorSummaryModel
     */
    'mean': number;
    /**
     * 
     * @type {number}
     * @memberof MarketIndicatorSummaryModel
     */
    'median': number;
    /**
     * 
     * @type {Array<ValueMatrixDecileModel>}
     * @memberof MarketIndicatorSummaryModel
     */
    'deciles': Array<ValueMatrixDecileModel>;
    /**
     * 
     * @type {ValueMatrixDecileModel}
     * @memberof MarketIndicatorSummaryModel
     */
    'spotDecile': ValueMatrixDecileModel;
    /**
     * 
     * @type {Array<MarketIndicatorQuarterDecileModel>}
     * @memberof MarketIndicatorSummaryModel
     */
    'quarterDeciles': Array<MarketIndicatorQuarterDecileModel>;
    /**
     * 
     * @type {RunValueMatrixRequest}
     * @memberof MarketIndicatorSummaryModel
     */
    'runValueMatrixRequest': RunValueMatrixRequest;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarketIndicatorTemplateType = {
    AverageSeasonal: 'AverageSeasonal',
    Combination: 'Combination',
    Decile: 'Decile',
    Forecast: 'Forecast',
    ForwardCurve: 'ForwardCurve',
    Historical: 'Historical',
    ManualEntry: 'ManualEntry',
    Seasonal: 'Seasonal',
    SlidingWindowSeasonal: 'SlidingWindowSeasonal',
    Speculative: 'Speculative',
    Technical: 'Technical'
} as const;

export type MarketIndicatorTemplateType = typeof MarketIndicatorTemplateType[keyof typeof MarketIndicatorTemplateType];


/**
 * 
 * @export
 * @enum {string}
 */

export const MarketIndicatorTransformationType = {
    DayOverDayChange: 'DayOverDayChange',
    MonthOverMonthChange: 'MonthOverMonthChange',
    Multiply: 'Multiply',
    RelativeStrengthIndex: 'RelativeStrengthIndex',
    RollingAverage: 'RollingAverage',
    RollingAverageForwardContracts: 'RollingAverageForwardContracts',
    WeekOverWeekChange: 'WeekOverWeekChange',
    YearOverYearChange: 'YearOverYearChange'
} as const;

export type MarketIndicatorTransformationType = typeof MarketIndicatorTransformationType[keyof typeof MarketIndicatorTransformationType];


/**
 * 
 * @export
 * @enum {string}
 */

export const MarketPrecision = {
    Realtime: 'Realtime',
    Delayed: 'Delayed',
    EndOfDay: 'EndOfDay'
} as const;

export type MarketPrecision = typeof MarketPrecision[keyof typeof MarketPrecision];


/**
 * 
 * @export
 * @interface MarketPriceComparisonModel
 */
export interface MarketPriceComparisonModel {
    /**
     * 
     * @type {string}
     * @memberof MarketPriceComparisonModel
     */
    'reutersInstrumentCode1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketPriceComparisonModel
     */
    'reutersInstrumentCode2'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceComparisonModel
     */
    'contractMonth': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceComparisonModel
     */
    'contractYear': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceComparisonModel
     */
    'settlementPrice1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceComparisonModel
     */
    'settlementPrice2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceComparisonModel
     */
    'difference'?: number | null;
}
/**
 * 
 * @export
 * @interface MarketPriceModel
 */
export interface MarketPriceModel {
    /**
     * 
     * @type {string}
     * @memberof MarketPriceModel
     */
    'reutersInstrumentCode': string;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'openPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'closePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'settlementPrice': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'highPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'lowPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'volume'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'contractMonth': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'contractYear': number;
    /**
     * 
     * @type {string}
     * @memberof MarketPriceModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface MarketPricesModel
 */
export interface MarketPricesModel {
    /**
     * 
     * @type {number}
     * @memberof MarketPricesModel
     */
    'contractNumber': number;
    /**
     * 
     * @type {Array<MarketPriceModel>}
     * @memberof MarketPricesModel
     */
    'prices': Array<MarketPriceModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarketPricesTimeSpan = {
    OneDay: 'OneDay',
    FiveDays: 'FiveDays',
    OneMonth: 'OneMonth',
    ThirtyDays: 'ThirtyDays',
    ThreeMonths: 'ThreeMonths',
    SixMonths: 'SixMonths',
    NineMonths: 'NineMonths',
    OneYear: 'OneYear',
    TwoYears: 'TwoYears',
    ThreeYears: 'ThreeYears',
    FourYears: 'FourYears',
    FiveYears: 'FiveYears',
    EightYears: 'EightYears',
    TenYears: 'TenYears',
    FifteenYears: 'FifteenYears',
    TwentyYears: 'TwentyYears',
    All: 'All'
} as const;

export type MarketPricesTimeSpan = typeof MarketPricesTimeSpan[keyof typeof MarketPricesTimeSpan];


/**
 * 
 * @export
 * @interface MonthlyBudgetModel
 */
export interface MonthlyBudgetModel {
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'budgetPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'budgetPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'dataMonth': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'dataYear': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'contractMonth': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'contractYear': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'basis'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'stocksPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'stocksPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'stocksUnpricedDrawdown'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'stocksUnpricedIncrease'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'purchasesPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'purchasesPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'salesPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'salesPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'physicalPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'physicalPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'futuresPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyBudgetModel
     */
    'futuresPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface MonthlyPositionModel
 */
export interface MonthlyPositionModel {
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'dataMonth': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'dataYear': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'contractMonth': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'contractYear': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'basis'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'stocksPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'stocksPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'stocksUnpricedDrawdown'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'stocksUnpricedIncrease'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'purchasesPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'purchasesPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'salesPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'salesPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'physicalPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'physicalPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'futuresPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPositionModel
     */
    'futuresPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {DemeterPermissionType}
     * @memberof Permission
     */
    'permissionType': DemeterPermissionType;
    /**
     * 
     * @type {boolean}
     * @memberof Permission
     */
    'hasPermission'?: boolean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof Permission
     */
    'subPermissions'?: { [key: string]: boolean; } | null;
}
/**
 * 
 * @export
 * @interface PositionsCalculatorModel
 */
export interface PositionsCalculatorModel {
    /**
     * 
     * @type {Array<MonthlyPositionModel>}
     * @memberof PositionsCalculatorModel
     */
    'positions': Array<MonthlyPositionModel>;
    /**
     * 
     * @type {string}
     * @memberof PositionsCalculatorModel
     */
    'demeterUserCalculatorGuid'?: string | null;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof PositionsCalculatorModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof PositionsCalculatorModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {FuturesDirection}
     * @memberof PositionsCalculatorModel
     */
    'direction': FuturesDirection;
    /**
     * 
     * @type {number}
     * @memberof PositionsCalculatorModel
     */
    'probability': number;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof PositionsCalculatorModel
     */
    'volatilityTimeSpan': DemeterFilterTimeSpan;
    /**
     * 
     * @type {number}
     * @memberof PositionsCalculatorModel
     */
    'basis': number;
    /**
     * 
     * @type {number}
     * @memberof PositionsCalculatorModel
     */
    'monthLag'?: number | null;
    /**
     * 
     * @type {Currency}
     * @memberof PositionsCalculatorModel
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof PositionsCalculatorModel
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {Array<MonthlyPositionModel>}
     * @memberof PositionsCalculatorModel
     */
    'rows': Array<MonthlyPositionModel>;
    /**
     * 
     * @type {string}
     * @memberof PositionsCalculatorModel
     */
    'modifiedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionsCalculatorModel
     */
    'modifiedBy'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PresentationFormat = {
    Ppt: 'Ppt',
    Pdf: 'Pdf'
} as const;

export type PresentationFormat = typeof PresentationFormat[keyof typeof PresentationFormat];


/**
 * 
 * @export
 * @interface PresentationTemplateModel
 */
export interface PresentationTemplateModel {
    /**
     * 
     * @type {string}
     * @memberof PresentationTemplateModel
     */
    'presentationTemplateGuid': string;
    /**
     * 
     * @type {string}
     * @memberof PresentationTemplateModel
     */
    'userGuid': string;
    /**
     * 
     * @type {string}
     * @memberof PresentationTemplateModel
     */
    'companyGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PresentationTemplateModel
     */
    'name': string;
    /**
     * 
     * @type {PresentationFormat}
     * @memberof PresentationTemplateModel
     */
    'format': PresentationFormat;
    /**
     * 
     * @type {Array<DemeterPageElementModel>}
     * @memberof PresentationTemplateModel
     */
    'rows': Array<DemeterPageElementModel>;
}
/**
 * 
 * @export
 * @interface PriceFrequencyDefinition
 */
export interface PriceFrequencyDefinition {
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof PriceFrequencyDefinition
     */
    'priceFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof PriceFrequencyDefinition
     */
    'dataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {boolean}
     * @memberof PriceFrequencyDefinition
     */
    'hasForecastData'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PriceType = {
    Basis: 'Basis',
    Futures: 'Futures',
    Physical: 'Physical'
} as const;

export type PriceType = typeof PriceType[keyof typeof PriceType];


/**
 * 
 * @export
 * @interface ReactivateDemeterUserRequest
 */
export interface ReactivateDemeterUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ReactivateDemeterUserRequest
     */
    'demeterUserTrialGuid'?: string | null;
}
/**
 * 
 * @export
 * @interface RegisterDemeterUserForZohoRequest
 */
export interface RegisterDemeterUserForZohoRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterDemeterUserForZohoRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDemeterUserForZohoRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDemeterUserForZohoRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDemeterUserForZohoRequest
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDemeterUserForZohoRequest
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDemeterUserForZohoRequest
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface RegisterUserForZohoResponse
 */
export interface RegisterUserForZohoResponse {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserForZohoResponse
     */
    'redirectUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface RejectDemeterUserRequest
 */
export interface RejectDemeterUserRequest {
    /**
     * 
     * @type {string}
     * @memberof RejectDemeterUserRequest
     */
    'demeterUserTrialGuid': string;
}
/**
 * 
 * @export
 * @interface RunCalculationEngineRequest
 */
export interface RunCalculationEngineRequest {
    /**
     * 
     * @type {string}
     * @memberof RunCalculationEngineRequest
     */
    'demeterCalculationEngineGuid': string;
    /**
     * 
     * @type {string}
     * @memberof RunCalculationEngineRequest
     */
    'demeterCalculationEngineInputsGuid': string;
    /**
     * 
     * @type {string}
     * @memberof RunCalculationEngineRequest
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof RunCalculationEngineRequest
     */
    'filterHistoryTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof RunCalculationEngineRequest
     */
    'showHiddenLines'?: boolean;
}
/**
 * 
 * @export
 * @interface RunCalculationEngineResponse
 */
export interface RunCalculationEngineResponse {
    /**
     * 
     * @type {string}
     * @memberof RunCalculationEngineResponse
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {Array<CalculationEngineResultModel>}
     * @memberof RunCalculationEngineResponse
     */
    'results'?: Array<CalculationEngineResultModel> | null;
    /**
     * 
     * @type {{ [key: string]: Array<CalculationEngineOption>; }}
     * @memberof RunCalculationEngineResponse
     */
    'optionsMap'?: { [key: string]: Array<CalculationEngineOption>; } | null;
    /**
     * 
     * @type {Currency}
     * @memberof RunCalculationEngineResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof RunCalculationEngineResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {number}
     * @memberof RunCalculationEngineResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface RunFarmerMarginCalculatorResponse
 */
export interface RunFarmerMarginCalculatorResponse {
    /**
     * 
     * @type {number}
     * @memberof RunFarmerMarginCalculatorResponse
     */
    'weightedFuturesBlendPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof RunFarmerMarginCalculatorResponse
     */
    'weightedBreakEvenPrice'?: number;
    /**
     * 
     * @type {Array<CoverageTableLineModel>}
     * @memberof RunFarmerMarginCalculatorResponse
     */
    'coverageTableLines'?: Array<CoverageTableLineModel> | null;
    /**
     * 
     * @type {string}
     * @memberof RunFarmerMarginCalculatorResponse
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {Array<CalculationEngineResultModel>}
     * @memberof RunFarmerMarginCalculatorResponse
     */
    'results'?: Array<CalculationEngineResultModel> | null;
    /**
     * 
     * @type {{ [key: string]: Array<CalculationEngineOption>; }}
     * @memberof RunFarmerMarginCalculatorResponse
     */
    'optionsMap'?: { [key: string]: Array<CalculationEngineOption>; } | null;
    /**
     * 
     * @type {Currency}
     * @memberof RunFarmerMarginCalculatorResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof RunFarmerMarginCalculatorResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {number}
     * @memberof RunFarmerMarginCalculatorResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface RunMarketIndicatorFactorResponse
 */
export interface RunMarketIndicatorFactorResponse {
    /**
     * 
     * @type {MarketIndicatorOutlookResultModel}
     * @memberof RunMarketIndicatorFactorResponse
     */
    'currentResult'?: MarketIndicatorOutlookResultModel;
    /**
     * 
     * @type {Array<MarketIndicatorOutlookResultModel>}
     * @memberof RunMarketIndicatorFactorResponse
     */
    'rows'?: Array<MarketIndicatorOutlookResultModel> | null;
    /**
     * 
     * @type {number}
     * @memberof RunMarketIndicatorFactorResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof RunMarketIndicatorFactorResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof RunMarketIndicatorFactorResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof RunMarketIndicatorFactorResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RunMarketIndicatorFactorResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface RunMarketIndicatorResponse
 */
export interface RunMarketIndicatorResponse {
    /**
     * 
     * @type {MarketIndicatorModel}
     * @memberof RunMarketIndicatorResponse
     */
    'marketIndicator'?: MarketIndicatorModel;
    /**
     * 
     * @type {Array<MarketIndicatorOutlookResultModel>}
     * @memberof RunMarketIndicatorResponse
     */
    'rows'?: Array<MarketIndicatorOutlookResultModel> | null;
    /**
     * 
     * @type {number}
     * @memberof RunMarketIndicatorResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof RunMarketIndicatorResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof RunMarketIndicatorResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof RunMarketIndicatorResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RunMarketIndicatorResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface RunMarketIndicatorWithFactorReplacementRequest
 */
export interface RunMarketIndicatorWithFactorReplacementRequest {
    /**
     * 
     * @type {string}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'marketIndicatorGuid': string;
    /**
     * 
     * @type {string}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'marketIndicatorFactorGuid': string;
    /**
     * 
     * @type {Correlation}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'correlation': Correlation;
    /**
     * 
     * @type {number}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'numberOfYears': number;
    /**
     * 
     * @type {string}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'asOfDate': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'subFactorGuids'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'leadingIndicatorType': LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'dataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'transformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'transformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorDataCombinationType}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'dataCombinationType'?: MarketIndicatorDataCombinationType;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'secondaryLeadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'secondaryRegion'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'secondarySubRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'secondaryCommodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'secondaryExtraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'secondaryDataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'secondaryTransformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'secondaryTransformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorTemplateType}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'templateType': MarketIndicatorTemplateType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'parameters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof RunMarketIndicatorWithFactorReplacementRequest
     */
    'market'?: DemeterMarket;
}
/**
 * 
 * @export
 * @interface RunTestCalculationEngineRequest
 */
export interface RunTestCalculationEngineRequest {
    /**
     * 
     * @type {string}
     * @memberof RunTestCalculationEngineRequest
     */
    'demeterCalculationEngineGuid': string;
    /**
     * 
     * @type {string}
     * @memberof RunTestCalculationEngineRequest
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RunTestCalculationEngineRequest
     */
    'showHistory'?: boolean;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof RunTestCalculationEngineRequest
     */
    'filterHistoryTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof RunTestCalculationEngineRequest
     */
    'showFutures'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RunTestCalculationEngineRequest
     */
    'endingDate'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof RunTestCalculationEngineRequest
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof RunTestCalculationEngineRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof RunTestCalculationEngineRequest
     */
    'showHiddenLines'?: boolean;
    /**
     * 
     * @type {{ [key: string]: CalculationEngineDataOverride; }}
     * @memberof RunTestCalculationEngineRequest
     */
    'dataOverrides'?: { [key: string]: CalculationEngineDataOverride; } | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof RunTestCalculationEngineRequest
     */
    'valueOverrides'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {Array<CalculationEngineMonthlyValueOverrides>}
     * @memberof RunTestCalculationEngineRequest
     */
    'monthlyValueOverrides'?: Array<CalculationEngineMonthlyValueOverrides> | null;
}
/**
 * 
 * @export
 * @interface RunTestFarmerMarginCalculatorRequest
 */
export interface RunTestFarmerMarginCalculatorRequest {
    /**
     * 
     * @type {string}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'coverageTableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'coverageTableEndDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'biasMultiplier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'demeterCalculationEngineGuid': string;
    /**
     * 
     * @type {string}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'showHistory'?: boolean;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'filterHistoryTimeSpan'?: DemeterFilterTimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'showFutures'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'endingDate'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'showHiddenLines'?: boolean;
    /**
     * 
     * @type {{ [key: string]: CalculationEngineDataOverride; }}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'dataOverrides'?: { [key: string]: CalculationEngineDataOverride; } | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'valueOverrides'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {Array<CalculationEngineMonthlyValueOverrides>}
     * @memberof RunTestFarmerMarginCalculatorRequest
     */
    'monthlyValueOverrides'?: Array<CalculationEngineMonthlyValueOverrides> | null;
}
/**
 * 
 * @export
 * @interface RunTestMarketIndicatorFactorRequest
 */
export interface RunTestMarketIndicatorFactorRequest {
    /**
     * 
     * @type {Correlation}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'correlation': Correlation;
    /**
     * 
     * @type {number}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'numberOfYears': number;
    /**
     * 
     * @type {string}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'asOfDate': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'subFactorGuids'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'leadingIndicatorType': LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'dataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'transformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'transformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorDataCombinationType}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'dataCombinationType'?: MarketIndicatorDataCombinationType;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'secondaryLeadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'secondaryRegion'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'secondarySubRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'secondaryCommodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'secondaryExtraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'secondaryDataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'secondaryTransformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'secondaryTransformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorTemplateType}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'templateType': MarketIndicatorTemplateType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'parameters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof RunTestMarketIndicatorFactorRequest
     */
    'market'?: DemeterMarket;
}
/**
 * 
 * @export
 * @interface RunValueMatrixRequest
 */
export interface RunValueMatrixRequest {
    /**
     * 
     * @type {DemeterRegion}
     * @memberof RunValueMatrixRequest
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof RunValueMatrixRequest
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof RunValueMatrixRequest
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {DemeterDataSource}
     * @memberof RunValueMatrixRequest
     */
    'dataSource'?: DemeterDataSource;
    /**
     * 
     * @type {PriceType}
     * @memberof RunValueMatrixRequest
     */
    'priceType'?: PriceType;
    /**
     * 
     * @type {string}
     * @memberof RunValueMatrixRequest
     */
    'stripType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RunValueMatrixRequest
     */
    'contractNumberStart'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RunValueMatrixRequest
     */
    'contractNumberEnd'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RunValueMatrixRequest
     */
    'useProducerPriceIndex'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RunValueMatrixRequest
     */
    'useWeightedCalculation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RunValueMatrixRequest
     */
    'innerYears'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RunValueMatrixRequest
     */
    'outerYears': number;
    /**
     * 
     * @type {number}
     * @memberof RunValueMatrixRequest
     */
    'innerWeightPercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RunValueMatrixRequest
     */
    'outerWeightPercent'?: number | null;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof RunValueMatrixRequest
     */
    'monthSets': Array<Array<number>>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof RunValueMatrixRequest
     */
    'currentValuesPerMonthSet'?: Array<Array<number>> | null;
    /**
     * 
     * @type {string}
     * @memberof RunValueMatrixRequest
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RunValueMatrixRequest
     */
    'showProducerPriceIndex'?: boolean;
}
/**
 * 
 * @export
 * @interface RunValueMatrixResponse
 */
export interface RunValueMatrixResponse {
    /**
     * 
     * @type {Currency}
     * @memberof RunValueMatrixResponse
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof RunValueMatrixResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {Array<ValueMatrixModel>}
     * @memberof RunValueMatrixResponse
     */
    'rows'?: Array<ValueMatrixModel> | null;
}
/**
 * 
 * @export
 * @interface SeasonalAverageValueModel
 */
export interface SeasonalAverageValueModel {
    /**
     * 
     * @type {number}
     * @memberof SeasonalAverageValueModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalAverageValueModel
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalAverageValueModel
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface SeasonalByWeekAverageValueModel
 */
export interface SeasonalByWeekAverageValueModel {
    /**
     * 
     * @type {number}
     * @memberof SeasonalByWeekAverageValueModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalByWeekAverageValueModel
     */
    'week': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalByWeekAverageValueModel
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface SeasonalQuarterModel
 */
export interface SeasonalQuarterModel {
    /**
     * 
     * @type {number}
     * @memberof SeasonalQuarterModel
     */
    'quarter': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalQuarterModel
     */
    'quarterOverQuarterChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SeasonalQuarterModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalQuarterModel
     */
    'monthCount': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalQuarterModel
     */
    'valueAverage': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalQuarterModel
     */
    'valueTotal': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalQuarterModel
     */
    'normalizedValueAverage': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalQuarterModel
     */
    'normalizedValueTotal': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalQuarterModel
     */
    'monthOverMonthChangePercentAverage'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SeasonalQuarterModel
     */
    'isActualValue': boolean;
}
/**
 * 
 * @export
 * @interface SeasonalYearAverageModel
 */
export interface SeasonalYearAverageModel {
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearAverageModel
     */
    'numberOfYears': number;
    /**
     * 
     * @type {Array<SeasonalYearAverageMonthlyValueMonthly>}
     * @memberof SeasonalYearAverageModel
     */
    'monthlyValues': Array<SeasonalYearAverageMonthlyValueMonthly>;
}
/**
 * 
 * @export
 * @interface SeasonalYearAverageMonthlyValueMonthly
 */
export interface SeasonalYearAverageMonthlyValueMonthly {
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearAverageMonthlyValueMonthly
     */
    'month': number;
    /**
     * 
     * @type {Array<SeasonalAverageValueModel>}
     * @memberof SeasonalYearAverageMonthlyValueMonthly
     */
    'values': Array<SeasonalAverageValueModel>;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearAverageMonthlyValueMonthly
     */
    'averageValue': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearAverageMonthlyValueMonthly
     */
    'minimumValue': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearAverageMonthlyValueMonthly
     */
    'maximumValue': number;
}
/**
 * 
 * @export
 * @interface SeasonalYearByWeekAverageModel
 */
export interface SeasonalYearByWeekAverageModel {
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearByWeekAverageModel
     */
    'numberOfYears': number;
    /**
     * 
     * @type {Array<SeasonalYearByWeekAverageMonthlyValueMonthly>}
     * @memberof SeasonalYearByWeekAverageModel
     */
    'weeklyValues': Array<SeasonalYearByWeekAverageMonthlyValueMonthly>;
}
/**
 * 
 * @export
 * @interface SeasonalYearByWeekAverageMonthlyValueMonthly
 */
export interface SeasonalYearByWeekAverageMonthlyValueMonthly {
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearByWeekAverageMonthlyValueMonthly
     */
    'week': number;
    /**
     * 
     * @type {Array<SeasonalByWeekAverageValueModel>}
     * @memberof SeasonalYearByWeekAverageMonthlyValueMonthly
     */
    'values': Array<SeasonalByWeekAverageValueModel>;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearByWeekAverageMonthlyValueMonthly
     */
    'averageValue': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearByWeekAverageMonthlyValueMonthly
     */
    'minimumValue': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearByWeekAverageMonthlyValueMonthly
     */
    'maximumValue': number;
}
/**
 * 
 * @export
 * @interface SeasonalYearModel
 */
export interface SeasonalYearModel {
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearModel
     */
    'yearOverYearChangePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearModel
     */
    'monthCount': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearModel
     */
    'valueAverage': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearModel
     */
    'valueTotal': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearModel
     */
    'normalizedValueAverage': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearModel
     */
    'normalizedValueTotal': number;
    /**
     * 
     * @type {number}
     * @memberof SeasonalYearModel
     */
    'monthOverMonthChangePercentAverage'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SeasonalYearModel
     */
    'isActualValue': boolean;
}
/**
 * 
 * @export
 * @interface SendDemeterUserTrialEmailRequest
 */
export interface SendDemeterUserTrialEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof SendDemeterUserTrialEmailRequest
     */
    'demeterUserTrialGuid': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SortDirection = {
    Asc: 'Asc',
    Desc: 'Desc',
    None: 'None'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];


/**
 * 
 * @export
 * @enum {string}
 */

export const SubExchangeType = {
    Cbot: 'CBOT',
    Comex: 'COMEX',
    Mgex: 'MGEX',
    Nymex: 'NYMEX',
    Ifeu: 'IFEU',
    Ifsg: 'IFSG',
    Ifus: 'IFUS',
    Ndex: 'NDEX'
} as const;

export type SubExchangeType = typeof SubExchangeType[keyof typeof SubExchangeType];


/**
 * 
 * @export
 * @enum {string}
 */

export const SymbolCategory = {
    Currency: 'Currency',
    Dairy: 'Dairy',
    Energy: 'Energy',
    Fertilizer: 'Fertilizer',
    Grains: 'Grains',
    Indices: 'Indices',
    Livestock: 'Livestock',
    Metals: 'Metals',
    Softs: 'Softs'
} as const;

export type SymbolCategory = typeof SymbolCategory[keyof typeof SymbolCategory];


/**
 * 
 * @export
 * @enum {string}
 */

export const UnitOfMeasure = {
    Bag: 'Bag',
    Barrel: 'Barrel',
    BillionCubicFeet: 'BillionCubicFeet',
    Bushel: 'Bushel',
    Contracts: 'Contracts',
    Currency: 'Currency',
    Days: 'Days',
    DegreesFahrenheit: 'DegreesFahrenheit',
    EuropeanUnionAllowance: 'EuropeanUnionAllowance',
    Gallon: 'Gallon',
    Head: 'Head',
    HundredKilogram: 'HundredKilogram',
    HundredWeight: 'HundredWeight',
    Imperial: 'Imperial',
    Index: 'Index',
    Kilogram: 'Kilogram',
    KilogramMilkSolids: 'KilogramMilkSolids',
    Liter: 'Liter',
    MegaWatt: 'MegaWatt',
    MegaWattHour: 'MegaWattHour',
    Metric: 'Metric',
    MetricTon: 'MetricTon',
    MillionBritishThermalUnits: 'MillionBritishThermalUnits',
    MillionBushels: 'MillionBushels',
    MillionCubicFeet: 'MillionCubicFeet',
    MillionGallons: 'MillionGallons',
    MillionLiters: 'MillionLiters',
    MillionMetricTons: 'MillionMetricTons',
    MillionPounds: 'MillionPounds',
    Percent: 'Percent',
    Pound: 'Pound',
    PoundSingle: 'PoundSingle',
    ShortTon: 'ShortTon',
    ThousandBarrels: 'ThousandBarrels',
    ThousandBarrelsPerDay: 'ThousandBarrelsPerDay',
    ThousandHeads: 'ThousandHeads',
    ThousandMetricTons: 'ThousandMetricTons',
    ThousandPounds: 'ThousandPounds',
    ThousandShortTons: 'ThousandShortTons',
    Unknown: 'Unknown'
} as const;

export type UnitOfMeasure = typeof UnitOfMeasure[keyof typeof UnitOfMeasure];


/**
 * 
 * @export
 * @interface UpdateBudgetCalculatorRequest
 */
export interface UpdateBudgetCalculatorRequest {
    /**
     * 
     * @type {DemeterRegion}
     * @memberof UpdateBudgetCalculatorRequest
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof UpdateBudgetCalculatorRequest
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {FuturesDirection}
     * @memberof UpdateBudgetCalculatorRequest
     */
    'direction': FuturesDirection;
    /**
     * 
     * @type {number}
     * @memberof UpdateBudgetCalculatorRequest
     */
    'probability': number;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof UpdateBudgetCalculatorRequest
     */
    'volatilityTimeSpan': DemeterFilterTimeSpan;
    /**
     * 
     * @type {number}
     * @memberof UpdateBudgetCalculatorRequest
     */
    'basis': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBudgetCalculatorRequest
     */
    'monthLag'?: number | null;
    /**
     * 
     * @type {Currency}
     * @memberof UpdateBudgetCalculatorRequest
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof UpdateBudgetCalculatorRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {Array<MonthlyBudgetModel>}
     * @memberof UpdateBudgetCalculatorRequest
     */
    'budgets': Array<MonthlyBudgetModel>;
}
/**
 * 
 * @export
 * @interface UpdateCalculationEngineInputsRequest
 */
export interface UpdateCalculationEngineInputsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'demeterCalculationEngineInputsGuid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'order'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'demeterCalculationEngineGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'showHistory'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'showFutures'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'endingDate'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'showHiddenLines'?: boolean;
    /**
     * 
     * @type {{ [key: string]: CalculationEngineDataOverride; }}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'dataOverrides'?: { [key: string]: CalculationEngineDataOverride; } | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'valueOverrides'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {Array<CalculationEngineMonthlyValueOverrides>}
     * @memberof UpdateCalculationEngineInputsRequest
     */
    'monthlyValueOverrides'?: Array<CalculationEngineMonthlyValueOverrides> | null;
}
/**
 * 
 * @export
 * @interface UpdateCalculationEngineInputsResponse
 */
export interface UpdateCalculationEngineInputsResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculationEngineInputsResponse
     */
    'demeterCalculationEngineInputsGuid'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDemeterCompanyLicensesRequest
 */
export interface UpdateDemeterCompanyLicensesRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyLicensesRequest
     */
    'demeterCompanyGuid'?: string;
    /**
     * 
     * @type {Array<UserLicense>}
     * @memberof UpdateDemeterCompanyLicensesRequest
     */
    'userLicenses': Array<UserLicense>;
}
/**
 * 
 * @export
 * @interface UpdateDemeterCompanyPermissionRequest
 */
export interface UpdateDemeterCompanyPermissionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyPermissionRequest
     */
    'demeterCompanyGuid': string;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof UpdateDemeterCompanyPermissionRequest
     */
    'permissions': Array<Permission>;
}
/**
 * 
 * @export
 * @interface UpdateDemeterCompanyRequest
 */
export interface UpdateDemeterCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'demeterCompanyGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'invoiceDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateDemeterCompanyRequest
     */
    'subscriptionPrice'?: number | null;
    /**
     * 
     * @type {DemeterSubscriptionFrequency}
     * @memberof UpdateDemeterCompanyRequest
     */
    'subscriptionFrequency'?: DemeterSubscriptionFrequency;
    /**
     * 
     * @type {Currency}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingCurrency'?: Currency;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingRegion'?: DemeterRegion;
    /**
     * 
     * @type {number}
     * @memberof UpdateDemeterCompanyRequest
     */
    'numberOfLicenses'?: number | null;
    /**
     * 
     * @type {Array<DemeterMarket>}
     * @memberof UpdateDemeterCompanyRequest
     */
    'markets'?: Array<DemeterMarket> | null;
    /**
     * 
     * @type {Array<DemeterMarketLicenseModel>}
     * @memberof UpdateDemeterCompanyRequest
     */
    'marketLicenses'?: Array<DemeterMarketLicenseModel> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'contactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingContactName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingCompanyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingPostalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingFax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingVatNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterCompanyRequest
     */
    'billingSameAsCompanyInformation'?: boolean | null;
    /**
     * 
     * @type {DemeterPaymentType}
     * @memberof UpdateDemeterCompanyRequest
     */
    'paymentType'?: DemeterPaymentType;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterCompanyRequest
     */
    'futuresAccountTransferConsent'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'futuresAccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'futuresAccountCustomerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'futuresAccountTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterCompanyRequest
     */
    'policyNumbers'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateDemeterUserCurrencyPreferenceRequest
 */
export interface UpdateDemeterUserCurrencyPreferenceRequest {
    /**
     * 
     * @type {Currency}
     * @memberof UpdateDemeterUserCurrencyPreferenceRequest
     */
    'currency'?: Currency;
}
/**
 * 
 * @export
 * @interface UpdateDemeterUserDataRequest
 */
export interface UpdateDemeterUserDataRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterUserDataRequest
     */
    'demeterUserDataGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterUserDataRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterUserDataRequest
     */
    'fileName': string;
    /**
     * 
     * @type {Currency}
     * @memberof UpdateDemeterUserDataRequest
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof UpdateDemeterUserDataRequest
     */
    'unitOfMeasure': UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface UpdateDemeterUserFeaturesRequest
 */
export interface UpdateDemeterUserFeaturesRequest {
    /**
     * 
     * @type {DemeterUserType}
     * @memberof UpdateDemeterUserFeaturesRequest
     */
    'userType'?: DemeterUserType;
    /**
     * 
     * @type {UpdateDemeterUserFeaturesRequestFeatures}
     * @memberof UpdateDemeterUserFeaturesRequest
     */
    'features': UpdateDemeterUserFeaturesRequestFeatures;
}
/**
 * 
 * @export
 * @interface UpdateDemeterUserFeaturesRequestFeatures
 */
export interface UpdateDemeterUserFeaturesRequestFeatures {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'Administration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'BasisCalculator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'BudgetCalculator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'Companies'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'DownloadsExports'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'DownloadsGeneral'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'DownloadsImports'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'FarmerMarginCalculator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'HedgeMonitorPro'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'Markets'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'MarketIndicators'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'PositionsCalculator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'PresentationTemplates'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'Refactors'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'UserData'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'Users'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'Valorization'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserFeaturesRequestFeatures
     */
    'ValueMatrix'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateDemeterUserPermissionRequest
 */
export interface UpdateDemeterUserPermissionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterUserPermissionRequest
     */
    'demeterUserTrialGuid': string;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof UpdateDemeterUserPermissionRequest
     */
    'permissions': Array<Permission>;
}
/**
 * 
 * @export
 * @interface UpdateDemeterUserPreferenceRequest
 */
export interface UpdateDemeterUserPreferenceRequest {
    /**
     * 
     * @type {DemeterRegion}
     * @memberof UpdateDemeterUserPreferenceRequest
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof UpdateDemeterUserPreferenceRequest
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterWorkerType}
     * @memberof UpdateDemeterUserPreferenceRequest
     */
    'workerType'?: DemeterWorkerType;
    /**
     * 
     * @type {Currency}
     * @memberof UpdateDemeterUserPreferenceRequest
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof UpdateDemeterUserPreferenceRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface UpdateDemeterUserRequest
 */
export interface UpdateDemeterUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterUserRequest
     */
    'demeterUserTrialGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterUserRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterUserRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterUserRequest
     */
    'demeterCompanyGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterUserRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {DemeterUserType}
     * @memberof UpdateDemeterUserRequest
     */
    'userType': DemeterUserType;
    /**
     * 
     * @type {DemeterUserStatus}
     * @memberof UpdateDemeterUserRequest
     */
    'userStatus'?: DemeterUserStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterUserRequest
     */
    'trialExpirationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterUserRequest
     */
    'subscribedDate'?: string | null;
    /**
     * 
     * @type {MarketPrecision}
     * @memberof UpdateDemeterUserRequest
     */
    'marketPrecision'?: MarketPrecision;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemeterUserRequest
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDemeterUserRequest
     */
    'isCompanyAdministrator'?: boolean | null;
    /**
     * 
     * @type {Array<DemeterMarket>}
     * @memberof UpdateDemeterUserRequest
     */
    'markets'?: Array<DemeterMarket> | null;
}
/**
 * 
 * @export
 * @interface UpdateDemeterUserStoreRequest
 */
export interface UpdateDemeterUserStoreRequest {
    /**
     * 
     * @type {DemeterUserStoreType}
     * @memberof UpdateDemeterUserStoreRequest
     */
    'userStoreType': DemeterUserStoreType;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UpdateDemeterUserStoreRequest
     */
    'value': { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface UpdateDemeterUserUnitOfMeasurePreferenceRequest
 */
export interface UpdateDemeterUserUnitOfMeasurePreferenceRequest {
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof UpdateDemeterUserUnitOfMeasurePreferenceRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface UpdateFederalMilkMarketingOrderCalculatorRequest
 */
export interface UpdateFederalMilkMarketingOrderCalculatorRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateFederalMilkMarketingOrderCalculatorRequest
     */
    'cheeseBlockPrice': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateFederalMilkMarketingOrderCalculatorRequest
     */
    'cheeseBarrelPrice': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateFederalMilkMarketingOrderCalculatorRequest
     */
    'dryWheyPrice': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateFederalMilkMarketingOrderCalculatorRequest
     */
    'butterPrice': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateFederalMilkMarketingOrderCalculatorRequest
     */
    'nonFatDryMilkPrice': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateFederalMilkMarketingOrderCalculatorRequest
     */
    'cheeseBlockPercent': number;
}
/**
 * 
 * @export
 * @interface UpdateMarketIndicatorFactorGroupRequest
 */
export interface UpdateMarketIndicatorFactorGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMarketIndicatorFactorGroupRequest
     */
    'marketIndicatorGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMarketIndicatorFactorGroupRequest
     */
    'displayGroupName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMarketIndicatorFactorGroupRequest
     */
    'newDisplayGroupName': string;
}
/**
 * 
 * @export
 * @interface UpdateMarketIndicatorFactorOrderRequest
 */
export interface UpdateMarketIndicatorFactorOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMarketIndicatorFactorOrderRequest
     */
    'marketIndicatorGuid': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateMarketIndicatorFactorOrderRequest
     */
    'marketIndicatorFactorGuids': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateMarketIndicatorFactorRequest
 */
export interface UpdateMarketIndicatorFactorRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'marketIndicatorFactorGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'marketIndicatorGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'displayGroupName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'displayName': string;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'market'?: DemeterMarket;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'leadingIndicatorType': LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'region'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'subRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'commodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'extraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'dataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'transformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'transformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorDataCombinationType}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'dataCombinationType'?: MarketIndicatorDataCombinationType;
    /**
     * 
     * @type {DemeterMarket}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'secondaryMarket'?: DemeterMarket;
    /**
     * 
     * @type {LeadingIndicatorType}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'secondaryLeadingIndicatorType'?: LeadingIndicatorType;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'secondaryRegion'?: DemeterRegion;
    /**
     * 
     * @type {DemeterSubRegion}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'secondarySubRegion'?: DemeterSubRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'secondaryCommodity'?: DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'secondaryExtraParameters'?: string | null;
    /**
     * 
     * @type {DemeterDataFrequency}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'secondaryDataFrequency'?: DemeterDataFrequency;
    /**
     * 
     * @type {MarketIndicatorTransformationType}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'secondaryTransformationType'?: MarketIndicatorTransformationType;
    /**
     * 
     * @type {number}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'secondaryTransformationValue'?: number | null;
    /**
     * 
     * @type {MarketIndicatorTemplateType}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'templateType': MarketIndicatorTemplateType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'parameters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Correlation}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'correlation': Correlation;
    /**
     * 
     * @type {number}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'multiplier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateMarketIndicatorFactorRequest
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface UpdateMarketIndicatorRequest
 */
export interface UpdateMarketIndicatorRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMarketIndicatorRequest
     */
    'marketIndicatorGuid': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMarketIndicatorRequest
     */
    'contractNumber': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateMarketIndicatorRequest
     */
    'displayName': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMarketIndicatorRequest
     */
    'isPublished': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateMarketIndicatorRequest
     */
    'numberOfYears': number;
}
/**
 * 
 * @export
 * @interface UpdatePositionsCalculatorRequest
 */
export interface UpdatePositionsCalculatorRequest {
    /**
     * 
     * @type {DemeterRegion}
     * @memberof UpdatePositionsCalculatorRequest
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof UpdatePositionsCalculatorRequest
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {FuturesDirection}
     * @memberof UpdatePositionsCalculatorRequest
     */
    'direction': FuturesDirection;
    /**
     * 
     * @type {number}
     * @memberof UpdatePositionsCalculatorRequest
     */
    'probability': number;
    /**
     * 
     * @type {DemeterFilterTimeSpan}
     * @memberof UpdatePositionsCalculatorRequest
     */
    'volatilityTimeSpan': DemeterFilterTimeSpan;
    /**
     * 
     * @type {number}
     * @memberof UpdatePositionsCalculatorRequest
     */
    'basis': number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePositionsCalculatorRequest
     */
    'monthLag'?: number | null;
    /**
     * 
     * @type {Currency}
     * @memberof UpdatePositionsCalculatorRequest
     */
    'currency'?: Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof UpdatePositionsCalculatorRequest
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {Array<MonthlyPositionModel>}
     * @memberof UpdatePositionsCalculatorRequest
     */
    'positions': Array<MonthlyPositionModel>;
}
/**
 * 
 * @export
 * @interface UpdatePresentationTemplateRequest
 */
export interface UpdatePresentationTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePresentationTemplateRequest
     */
    'presentationTemplateGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePresentationTemplateRequest
     */
    'name': string;
    /**
     * 
     * @type {PresentationFormat}
     * @memberof UpdatePresentationTemplateRequest
     */
    'format': PresentationFormat;
    /**
     * 
     * @type {Array<DemeterPageElementModel>}
     * @memberof UpdatePresentationTemplateRequest
     */
    'rows'?: Array<DemeterPageElementModel> | null;
}
/**
 * 
 * @export
 * @interface UpdateValueMatrixCalculatorRequest
 */
export interface UpdateValueMatrixCalculatorRequest {
    /**
     * 
     * @type {DemeterRegion}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'applyProducerPriceIndex': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'weighted': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'numberOfYearsInner'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'weightPercentInner'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'numberOfYearsOuter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'weightPercentOuter'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'stripType': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'termClassifications': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'showChart': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'chartStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'chartEndDate'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'chartDeciles'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateValueMatrixCalculatorRequest
     */
    'chartTerms'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpsertDemeterUserDataExcelRequest
 */
export interface UpsertDemeterUserDataExcelRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertDemeterUserDataExcelRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertDemeterUserDataExcelRequest
     */
    'fileName': string;
    /**
     * 
     * @type {Currency}
     * @memberof UpsertDemeterUserDataExcelRequest
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof UpsertDemeterUserDataExcelRequest
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {any}
     * @memberof UpsertDemeterUserDataExcelRequest
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface UpsertDemeterUserDataRequest
 */
export interface UpsertDemeterUserDataRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertDemeterUserDataRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertDemeterUserDataRequest
     */
    'fileName': string;
    /**
     * 
     * @type {Currency}
     * @memberof UpsertDemeterUserDataRequest
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof UpsertDemeterUserDataRequest
     */
    'unitOfMeasure': UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface UserLicense
 */
export interface UserLicense {
    /**
     * 
     * @type {string}
     * @memberof UserLicense
     */
    'demeterUserTrialGuid': string;
    /**
     * 
     * @type {Array<DemeterMarket>}
     * @memberof UserLicense
     */
    'markets': Array<DemeterMarket>;
}
/**
 * 
 * @export
 * @interface ValueMatrixCalculatorModel
 */
export interface ValueMatrixCalculatorModel {
    /**
     * 
     * @type {string}
     * @memberof ValueMatrixCalculatorModel
     */
    'demeterUserCalculatorGuid'?: string | null;
    /**
     * 
     * @type {DemeterRegion}
     * @memberof ValueMatrixCalculatorModel
     */
    'region': DemeterRegion;
    /**
     * 
     * @type {DemeterCommodity}
     * @memberof ValueMatrixCalculatorModel
     */
    'commodity': DemeterCommodity;
    /**
     * 
     * @type {string}
     * @memberof ValueMatrixCalculatorModel
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ValueMatrixCalculatorModel
     */
    'applyProducerPriceIndex': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ValueMatrixCalculatorModel
     */
    'weighted': boolean;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixCalculatorModel
     */
    'numberOfYearsInner'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixCalculatorModel
     */
    'weightPercentInner'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixCalculatorModel
     */
    'numberOfYearsOuter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixCalculatorModel
     */
    'weightPercentOuter'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValueMatrixCalculatorModel
     */
    'stripType': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ValueMatrixCalculatorModel
     */
    'termClassifications': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ValueMatrixCalculatorModel
     */
    'showChart': boolean;
    /**
     * 
     * @type {string}
     * @memberof ValueMatrixCalculatorModel
     */
    'chartStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValueMatrixCalculatorModel
     */
    'chartEndDate'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ValueMatrixCalculatorModel
     */
    'chartDeciles': Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ValueMatrixCalculatorModel
     */
    'chartTerms': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValueMatrixCalculatorModel
     */
    'modifiedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ValueMatrixDecileModel
 */
export interface ValueMatrixDecileModel {
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixDecileModel
     */
    'decile': number;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixDecileModel
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixDecileModel
     */
    'innerValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixDecileModel
     */
    'outerValue'?: number | null;
}
/**
 * 
 * @export
 * @interface ValueMatrixModel
 */
export interface ValueMatrixModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof ValueMatrixModel
     */
    'months': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixModel
     */
    'mean': number;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixModel
     */
    'median': number;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixModel
     */
    'innerMean'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixModel
     */
    'innerMedian'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixModel
     */
    'outerMean'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixModel
     */
    'outerMedian'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixModel
     */
    'weightedMean'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ValueMatrixModel
     */
    'weightedMedian'?: number | null;
    /**
     * 
     * @type {Array<ValueMatrixDecileModel>}
     * @memberof ValueMatrixModel
     */
    'deciles': Array<ValueMatrixDecileModel>;
    /**
     * 
     * @type {Array<ValueMatrixDecileModel>}
     * @memberof ValueMatrixModel
     */
    'currentValueDeciles': Array<ValueMatrixDecileModel>;
    /**
     * 
     * @type {Array<DemeterDataValueWithContractModel>}
     * @memberof ValueMatrixModel
     */
    'outerDataValues': Array<DemeterDataValueWithContractModel>;
    /**
     * 
     * @type {Array<DemeterDataValueWithContractModel>}
     * @memberof ValueMatrixModel
     */
    'innerDataValues': Array<DemeterDataValueWithContractModel>;
    /**
     * 
     * @type {Array<CommodityMonthlyOtherModel>}
     * @memberof ValueMatrixModel
     */
    'producerPriceIndexRows': Array<CommodityMonthlyOtherModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WorkerLogType = {
    Complete: 'Complete',
    Data: 'Data',
    Error: 'Error',
    Log: 'Log',
    Setup: 'Setup',
    Start: 'Start'
} as const;

export type WorkerLogType = typeof WorkerLogType[keyof typeof WorkerLogType];


/**
 * 
 * @export
 * @interface ZohoBillingAddress
 */
export interface ZohoBillingAddress {
    /**
     * 
     * @type {string}
     * @memberof ZohoBillingAddress
     */
    'country'?: string | null;
}
/**
 * 
 * @export
 * @interface ZohoPlan
 */
export interface ZohoPlan {
    /**
     * 
     * @type {string}
     * @memberof ZohoPlan
     */
    'plan_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoPlan
     */
    'plan_description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ZohoPlan
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ZohoPlan
     */
    'price'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ZohoPlan
     */
    'trial_days'?: number;
}
/**
 * 
 * @export
 * @interface ZohoSubscription
 */
export interface ZohoSubscription {
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'subscription_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'product_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'product_name'?: string | null;
    /**
     * 
     * @type {ZohoPlan}
     * @memberof ZohoSubscription
     */
    'plan'?: ZohoPlan;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'customer_id'?: string | null;
    /**
     * 
     * @type {ZohoUser}
     * @memberof ZohoSubscription
     */
    'customer'?: ZohoUser;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'trial_starts_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'trial_ends_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'start_date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ZohoSubscription
     */
    'trial_remaining_days'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'expires_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'currency_symbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'current_term_starts_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'current_term_ends_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'updated_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'reference_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'next_billing_at'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ZohoSubscription
     */
    'end_of_term'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ZohoSubscription
     */
    'exchange_rate'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ZohoSubscription
     */
    'is_inclusive_tax'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'activated_at'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ZohoSubscription
     */
    'interval'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'billing_mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'currency_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'interval_unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ZohoSubscription
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'subscription_number'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ZohoSubscription
     */
    'auto_collect'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscription
     */
    'created_time'?: string | null;
}
/**
 * 
 * @export
 * @interface ZohoSubscriptionContainer
 */
export interface ZohoSubscriptionContainer {
    /**
     * 
     * @type {ZohoSubscription}
     * @memberof ZohoSubscriptionContainer
     */
    'subscription'?: ZohoSubscription;
}
/**
 * 
 * @export
 * @interface ZohoUser
 */
export interface ZohoUser {
    /**
     * 
     * @type {string}
     * @memberof ZohoUser
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoUser
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoUser
     */
    'display_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoUser
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoUser
     */
    'company_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoUser
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoUser
     */
    'country'?: string | null;
    /**
     * 
     * @type {ZohoBillingAddress}
     * @memberof ZohoUser
     */
    'billing_address'?: ZohoBillingAddress;
}
/**
 * 
 * @export
 * @interface ZohoWebhookRequest
 */
export interface ZohoWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof ZohoWebhookRequest
     */
    'event_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoWebhookRequest
     */
    'event_type'?: string | null;
    /**
     * 
     * @type {ZohoSubscriptionContainer}
     * @memberof ZohoWebhookRequest
     */
    'data'?: ZohoSubscriptionContainer;
    /**
     * 
     * @type {string}
     * @memberof ZohoWebhookRequest
     */
    'created_time'?: string;
}

/**
 * DemeterApi - axios parameter creator
 * @export
 */
export const DemeterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BatchUpdateDemeterLeadingIndicatorsRequest} [batchUpdateDemeterLeadingIndicatorsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateDemeterLeadingIndicators: async (batchUpdateDemeterLeadingIndicatorsRequest?: BatchUpdateDemeterLeadingIndicatorsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/leading-indicators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchUpdateDemeterLeadingIndicatorsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BatchUpdateDemeterLeadingIndicatorsRequest} [batchUpdateDemeterLeadingIndicatorsRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        batchUpdateDemeterLeadingIndicatorsObsolete: async (batchUpdateDemeterLeadingIndicatorsRequest?: BatchUpdateDemeterLeadingIndicatorsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/leading-indicators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchUpdateDemeterLeadingIndicatorsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyPricesSeasonal: async (region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityMonthlyPricesSeasonal', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityMonthlyPricesSeasonal', 'commodity', commodity)
            const localVarPath = `/demeter/{region}/{commodity}/prices/monthly/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyFuturesConversionChart: async (reutersInstrumentCodePrefix: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('getCurrencyFuturesConversionChart', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            const localVarPath = `/demeter/features/symbols/{reutersInstrumentCodePrefix}/currencies/futures`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyHistoricalConversionChart: async (reutersInstrumentCodePrefix: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('getCurrencyHistoricalConversionChart', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            const localVarPath = `/demeter/features/symbols/{reutersInstrumentCodePrefix}/currencies/historical`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DemeterMarket} [market] 
         * @param {DemeterRegion} [region] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {DemeterWorkerType} [workerType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardPresets: async (market?: DemeterMarket, region?: DemeterRegion, subRegion?: DemeterSubRegion, workerType?: DemeterWorkerType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/features/dashboard/presets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (market !== undefined) {
                localVarQueryParameter['Market'] = market;
            }

            if (region !== undefined) {
                localVarQueryParameter['Region'] = region;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (workerType !== undefined) {
                localVarQueryParameter['WorkerType'] = workerType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCommentaryGuid 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterCommentary: async (demeterCommentaryGuid: string, languageCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCommentaryGuid' is not null or undefined
            assertParamExists('getDemeterCommentary', 'demeterCommentaryGuid', demeterCommentaryGuid)
            const localVarPath = `/demeter/news/commentaries/{demeterCommentaryGuid}`
                .replace(`{${"demeterCommentaryGuid"}}`, encodeURIComponent(String(demeterCommentaryGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} articleId 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterHeadlineNews: async (articleId: string, languageCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getDemeterHeadlineNews', 'articleId', articleId)
            const localVarPath = `/demeter/news/headline/{articleId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} featureType 
         * @param {DemeterUserType} [userType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUserFeature: async (featureType: string, userType?: DemeterUserType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureType' is not null or undefined
            assertParamExists('getDemeterUserFeature', 'featureType', featureType)
            const localVarPath = `/demeter/features/{featureType}`
                .replace(`{${"featureType"}}`, encodeURIComponent(String(featureType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userType !== undefined) {
                localVarQueryParameter['UserType'] = userType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitOfMeasureConversionChart: async (reutersInstrumentCodePrefix: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('getUnitOfMeasureConversionChart', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            const localVarPath = `/demeter/features/symbols/{reutersInstrumentCodePrefix}/unit-of-measures`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tableDefinitionType 
         * @param {DemeterMarket} [market] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllDemeterTableDefinitions: async (tableDefinitionType: string, market?: DemeterMarket, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableDefinitionType' is not null or undefined
            assertParamExists('listAllDemeterTableDefinitions', 'tableDefinitionType', tableDefinitionType)
            const localVarPath = `/demeter/features/table-definitions/{tableDefinitionType}/all`
                .replace(`{${"tableDefinitionType"}}`, encodeURIComponent(String(tableDefinitionType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (market !== undefined) {
                localVarQueryParameter['Market'] = market;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityBasisPrices: async (region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityBasisPrices', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityBasisPrices', 'commodity', commodity)
            const localVarPath = `/demeter/{region}/{commodity}/prices/basis`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyMargins: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityMonthlyMargins', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityMonthlyMargins', 'commodity', commodity)
            const localVarPath = `/demeter/{region}/{commodity}/margin`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyPrices: async (region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityMonthlyPrices', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityMonthlyPrices', 'commodity', commodity)
            const localVarPath = `/demeter/{region}/{commodity}/prices/monthly`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlySales: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityMonthlySales', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityMonthlySales', 'commodity', commodity)
            const localVarPath = `/demeter/{region}/{commodity}/sales`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {number} contractYear 
         * @param {number} contractMonth 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityOtcPrices: async (region: string, commodity: string, contractYear: number, contractMonth: number, currency?: Currency, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityOtcPrices', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityOtcPrices', 'commodity', commodity)
            // verify required parameter 'contractYear' is not null or undefined
            assertParamExists('listCommodityOtcPrices', 'contractYear', contractYear)
            // verify required parameter 'contractMonth' is not null or undefined
            assertParamExists('listCommodityOtcPrices', 'contractMonth', contractMonth)
            const localVarPath = `/demeter/futures/{region}/{commodity}/otc`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractYear !== undefined) {
                localVarQueryParameter['ContractYear'] = contractYear;
            }

            if (contractMonth !== undefined) {
                localVarQueryParameter['ContractMonth'] = contractMonth;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityOtcPricesCurrent: async (region: string, commodity: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, asOfDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityOtcPricesCurrent', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityOtcPricesCurrent', 'commodity', commodity)
            const localVarPath = `/demeter/futures/{region}/{commodity}/otc/current`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (asOfDate !== undefined) {
                localVarQueryParameter['AsOfDate'] = (asOfDate as any instanceof Date) ?
                    (asOfDate as any).toISOString() :
                    asOfDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} asOfDate 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {Currency} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityOtcPricesForwardCurve: async (region: string, commodity: string, asOfDate: string, filterTimeSpan?: DemeterFilterTimeSpan, unitOfMeasure?: UnitOfMeasure, currency?: Currency, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityOtcPricesForwardCurve', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityOtcPricesForwardCurve', 'commodity', commodity)
            // verify required parameter 'asOfDate' is not null or undefined
            assertParamExists('listCommodityOtcPricesForwardCurve', 'asOfDate', asOfDate)
            const localVarPath = `/demeter/futures/{region}/{commodity}/otc/forward-curve/{asOfDate}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)))
                .replace(`{${"asOfDate"}}`, encodeURIComponent(String(asOfDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterMarket} [market] 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityPricesByFrequency: async (region: string, commodity: string, dataFrequency: string, market?: DemeterMarket, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityPricesByFrequency', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityPricesByFrequency', 'commodity', commodity)
            // verify required parameter 'dataFrequency' is not null or undefined
            assertParamExists('listCommodityPricesByFrequency', 'dataFrequency', dataFrequency)
            const localVarPath = `/demeter/{region}/{commodity}/prices/frequency/{dataFrequency}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)))
                .replace(`{${"dataFrequency"}}`, encodeURIComponent(String(dataFrequency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (market !== undefined) {
                localVarQueryParameter['Market'] = market;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommoditySpotPrices: async (region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommoditySpotPrices', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommoditySpotPrices', 'commodity', commodity)
            const localVarPath = `/demeter/{region}/{commodity}/prices/spot`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommoditySpotPricesSummary: async (region: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommoditySpotPricesSummary', 'region', region)
            const localVarPath = `/demeter/{region}/prices/spot/summary`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyPrices: async (region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityWeeklyPrices', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityWeeklyPrices', 'commodity', commodity)
            const localVarPath = `/demeter/{region}/{commodity}/prices/weekly`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyPricesSummary: async (region: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityWeeklyPricesSummary', 'region', region)
            const localVarPath = `/demeter/{region}/prices/weekly/summary`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityYearlyBalances: async (region: string, commodity: string, unitOfMeasure?: UnitOfMeasure, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityYearlyBalances', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityYearlyBalances', 'commodity', commodity)
            const localVarPath = `/demeter/{region}/{commodity}/balances`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityYearlyPrices: async (region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityYearlyPrices', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityYearlyPrices', 'commodity', commodity)
            const localVarPath = `/demeter/{region}/{commodity}/prices/yearly`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DemeterMarketNewsType} [marketNewsType] 
         * @param {string} [languageCode] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterHeadlineNews: async (marketNewsType?: DemeterMarketNewsType, languageCode?: string, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/news/headline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (marketNewsType !== undefined) {
                localVarQueryParameter['MarketNewsType'] = marketNewsType;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DemeterMarket} [market] 
         * @param {boolean} [showAll] 
         * @param {boolean} [showWithData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterLeadingIndicators: async (market?: DemeterMarket, showAll?: boolean, showWithData?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/leading-indicators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (market !== undefined) {
                localVarQueryParameter['Market'] = market;
            }

            if (showAll !== undefined) {
                localVarQueryParameter['ShowAll'] = showAll;
            }

            if (showWithData !== undefined) {
                localVarQueryParameter['ShowWithData'] = showWithData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [showExpiredContracts] 
         * @param {boolean} [showUsingExpirationDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterSymbols: async (showExpiredContracts?: boolean, showUsingExpirationDate?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/features/symbols`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (showExpiredContracts !== undefined) {
                localVarQueryParameter['ShowExpiredContracts'] = showExpiredContracts;
            }

            if (showUsingExpirationDate !== undefined) {
                localVarQueryParameter['ShowUsingExpirationDate'] = showUsingExpirationDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tableDefinitionType 
         * @param {DemeterMarket} [market] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterTableDefinitions: async (tableDefinitionType: string, market?: DemeterMarket, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableDefinitionType' is not null or undefined
            assertParamExists('listDemeterTableDefinitions', 'tableDefinitionType', tableDefinitionType)
            const localVarPath = `/demeter/features/table-definitions/{tableDefinitionType}`
                .replace(`{${"tableDefinitionType"}}`, encodeURIComponent(String(tableDefinitionType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (market !== undefined) {
                localVarQueryParameter['Market'] = market;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tableDefinitionType 
         * @param {DemeterMarket} [market] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterTableDefinitionsByCommodity: async (tableDefinitionType: string, market?: DemeterMarket, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableDefinitionType' is not null or undefined
            assertParamExists('listDemeterTableDefinitionsByCommodity', 'tableDefinitionType', tableDefinitionType)
            const localVarPath = `/demeter/features/table-definitions/{tableDefinitionType}/commodity`
                .replace(`{${"tableDefinitionType"}}`, encodeURIComponent(String(tableDefinitionType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (market !== undefined) {
                localVarQueryParameter['Market'] = market;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DemeterUserType} [userType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterUserFeatures: async (userType?: DemeterUserType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userType !== undefined) {
                localVarQueryParameter['UserType'] = userType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fromCurrency 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listForeignExchangeRatesMonthly: async (fromCurrency: string, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromCurrency' is not null or undefined
            assertParamExists('listForeignExchangeRatesMonthly', 'fromCurrency', fromCurrency)
            const localVarPath = `/demeter/foreign-exchange-rates/{fromCurrency}`
                .replace(`{${"fromCurrency"}}`, encodeURIComponent(String(fromCurrency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalDairyTradeWeeklyEvents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/global-dairy-trade/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} commodity 
         * @param {DemeterRegion} region 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalDairyTradeWeeklyPrices: async (commodity: string, region: DemeterRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listGlobalDairyTradeWeeklyPrices', 'commodity', commodity)
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listGlobalDairyTradeWeeklyPrices', 'region', region)
            const localVarPath = `/demeter/global-dairy-trade/{commodity}/prices/weekly`
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['Region'] = region;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [event] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalDairyTradeWeeklyPricesSummary: async (event?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/global-dairy-trade/prices/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (event !== undefined) {
                localVarQueryParameter['Event'] = event;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} contractYear 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesByContractYearWithConversions: async (reutersInstrumentCodePrefix: string, contractYear: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricesByContractYearWithConversions', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'contractYear' is not null or undefined
            assertParamExists('listMarketPricesByContractYearWithConversions', 'contractYear', contractYear)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('listMarketPricesByContractYearWithConversions', 'timeSpan', timeSpan)
            const localVarPath = `/demeter/futures/{reutersInstrumentCodePrefix}/contract-year/{contractYear}`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)))
                .replace(`{${"contractYear"}}`, encodeURIComponent(String(contractYear)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix1 
         * @param {string} reutersInstrumentCodePrefix2 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {string} [asOfDate] 
         * @param {MarketPricesTimeSpan} [timeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesComparisonWithConversions: async (reutersInstrumentCodePrefix1: string, reutersInstrumentCodePrefix2: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, asOfDate?: string, timeSpan?: MarketPricesTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix1' is not null or undefined
            assertParamExists('listMarketPricesComparisonWithConversions', 'reutersInstrumentCodePrefix1', reutersInstrumentCodePrefix1)
            // verify required parameter 'reutersInstrumentCodePrefix2' is not null or undefined
            assertParamExists('listMarketPricesComparisonWithConversions', 'reutersInstrumentCodePrefix2', reutersInstrumentCodePrefix2)
            const localVarPath = `/demeter/futures/{reutersInstrumentCodePrefix1}/comparison/{reutersInstrumentCodePrefix2}`
                .replace(`{${"reutersInstrumentCodePrefix1"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix1)))
                .replace(`{${"reutersInstrumentCodePrefix2"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix2)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (asOfDate !== undefined) {
                localVarQueryParameter['AsOfDate'] = (asOfDate as any instanceof Date) ?
                    (asOfDate as any).toISOString() :
                    asOfDate;
            }

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} asOfDate 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {MarketPricesTimeSpan} [timeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesForwardCurveWithConversions: async (reutersInstrumentCodePrefix: string, asOfDate: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, timeSpan?: MarketPricesTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricesForwardCurveWithConversions', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'asOfDate' is not null or undefined
            assertParamExists('listMarketPricesForwardCurveWithConversions', 'asOfDate', asOfDate)
            const localVarPath = `/demeter/futures/{reutersInstrumentCodePrefix}/forward-curve/{asOfDate}`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)))
                .replace(`{${"asOfDate"}}`, encodeURIComponent(String(asOfDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} contractNumberStart 
         * @param {string} contractNumberEnd 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesRollingRange: async (reutersInstrumentCodePrefix: string, contractNumberStart: string, contractNumberEnd: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricesRollingRange', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'contractNumberStart' is not null or undefined
            assertParamExists('listMarketPricesRollingRange', 'contractNumberStart', contractNumberStart)
            // verify required parameter 'contractNumberEnd' is not null or undefined
            assertParamExists('listMarketPricesRollingRange', 'contractNumberEnd', contractNumberEnd)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('listMarketPricesRollingRange', 'timeSpan', timeSpan)
            const localVarPath = `/demeter/futures/{reutersInstrumentCodePrefix}/rolling/{contractNumberStart}/{contractNumberEnd}`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)))
                .replace(`{${"contractNumberStart"}}`, encodeURIComponent(String(contractNumberStart)))
                .replace(`{${"contractNumberEnd"}}`, encodeURIComponent(String(contractNumberEnd)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {number} contractNumber 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesRollingWithConversions: async (reutersInstrumentCodePrefix: string, contractNumber: number, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricesRollingWithConversions', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'contractNumber' is not null or undefined
            assertParamExists('listMarketPricesRollingWithConversions', 'contractNumber', contractNumber)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('listMarketPricesRollingWithConversions', 'timeSpan', timeSpan)
            const localVarPath = `/demeter/futures/{reutersInstrumentCodePrefix}/rolling`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (contractNumber !== undefined) {
                localVarQueryParameter['ContractNumber'] = contractNumber;
            }

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {number} [startingContractMonth] 
         * @param {Array<number>} [contractMonths] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesRollingYearWithConversions: async (reutersInstrumentCodePrefix: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, startingContractMonth?: number, contractMonths?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricesRollingYearWithConversions', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('listMarketPricesRollingYearWithConversions', 'timeSpan', timeSpan)
            const localVarPath = `/demeter/futures/{reutersInstrumentCodePrefix}/rolling/year`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (startingContractMonth !== undefined) {
                localVarQueryParameter['StartingContractMonth'] = startingContractMonth;
            }

            if (contractMonths) {
                localVarQueryParameter['ContractMonths'] = contractMonths;
            }

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesWithConversions: async (reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCode' is not null or undefined
            assertParamExists('listMarketPricesWithConversions', 'reutersInstrumentCode', reutersInstrumentCode)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('listMarketPricesWithConversions', 'timeSpan', timeSpan)
            const localVarPath = `/demeter/futures/{reutersInstrumentCode}`
                .replace(`{${"reutersInstrumentCode"}}`, encodeURIComponent(String(reutersInstrumentCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {DemeterDataFrequency} dataFrequency 
         * @param {DemeterMarket} [market] 
         * @param {DemeterCommodity} [commodity] 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegionPricesByFrequency: async (region: string, dataFrequency: DemeterDataFrequency, market?: DemeterMarket, commodity?: DemeterCommodity, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listRegionPricesByFrequency', 'region', region)
            // verify required parameter 'dataFrequency' is not null or undefined
            assertParamExists('listRegionPricesByFrequency', 'dataFrequency', dataFrequency)
            const localVarPath = `/demeter/{region}/prices/frequency`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (market !== undefined) {
                localVarQueryParameter['Market'] = market;
            }

            if (commodity !== undefined) {
                localVarQueryParameter['Commodity'] = commodity;
            }

            if (dataFrequency !== undefined) {
                localVarQueryParameter['DataFrequency'] = dataFrequency;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDemeterUserFeaturesRequest} [updateDemeterUserFeaturesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUserFeatures: async (updateDemeterUserFeaturesRequest?: UpdateDemeterUserFeaturesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterUserFeaturesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterApi - functional programming interface
 * @export
 */
export const DemeterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BatchUpdateDemeterLeadingIndicatorsRequest} [batchUpdateDemeterLeadingIndicatorsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateDemeterLeadingIndicators(batchUpdateDemeterLeadingIndicatorsRequest?: BatchUpdateDemeterLeadingIndicatorsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateDemeterLeadingIndicators(batchUpdateDemeterLeadingIndicatorsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BatchUpdateDemeterLeadingIndicatorsRequest} [batchUpdateDemeterLeadingIndicatorsRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async batchUpdateDemeterLeadingIndicatorsObsolete(batchUpdateDemeterLeadingIndicatorsRequest?: BatchUpdateDemeterLeadingIndicatorsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateDemeterLeadingIndicatorsObsolete(batchUpdateDemeterLeadingIndicatorsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityMonthlyPricesSeasonal(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyPricesSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyPricesSeasonal(region, commodity, dataSource, currency, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrencyFuturesConversionChart(reutersInstrumentCodePrefix: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCurrencyConversionChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrencyFuturesConversionChart(reutersInstrumentCodePrefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrencyHistoricalConversionChart(reutersInstrumentCodePrefix: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCurrencyConversionChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrencyHistoricalConversionChart(reutersInstrumentCodePrefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DemeterMarket} [market] 
         * @param {DemeterRegion} [region] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {DemeterWorkerType} [workerType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardPresets(market?: DemeterMarket, region?: DemeterRegion, subRegion?: DemeterSubRegion, workerType?: DemeterWorkerType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterDashboardPresetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardPresets(market, region, subRegion, workerType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCommentaryGuid 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemeterCommentary(demeterCommentaryGuid: string, languageCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterCommentaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemeterCommentary(demeterCommentaryGuid, languageCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} articleId 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemeterHeadlineNews(articleId: string, languageCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterNewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemeterHeadlineNews(articleId, languageCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} featureType 
         * @param {DemeterUserType} [userType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemeterUserFeature(featureType: string, userType?: DemeterUserType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterUserFeatureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemeterUserFeature(featureType, userType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnitOfMeasureConversionChart(reutersInstrumentCodePrefix: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUnitOfMeasureConversionChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnitOfMeasureConversionChart(reutersInstrumentCodePrefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tableDefinitionType 
         * @param {DemeterMarket} [market] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllDemeterTableDefinitions(tableDefinitionType: string, market?: DemeterMarket, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterTableDefinitionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllDemeterTableDefinitions(tableDefinitionType, market, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityBasisPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityBasisPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityBasisPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyMargins(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyMarginsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyMargins(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlySales(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlySalesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlySales(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {number} contractYear 
         * @param {number} contractMonth 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityOtcPrices(region: string, commodity: string, contractYear: number, contractMonth: number, currency?: Currency, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityOtcPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityOtcPrices(region, commodity, contractYear, contractMonth, currency, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityOtcPricesCurrent(region: string, commodity: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, asOfDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityOtcPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityOtcPricesCurrent(region, commodity, currency, unitOfMeasure, asOfDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} asOfDate 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {Currency} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityOtcPricesForwardCurve(region: string, commodity: string, asOfDate: string, filterTimeSpan?: DemeterFilterTimeSpan, unitOfMeasure?: UnitOfMeasure, currency?: Currency, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityOtcPricesForwardCurveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityOtcPricesForwardCurve(region, commodity, asOfDate, filterTimeSpan, unitOfMeasure, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterMarket} [market] 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityPricesByFrequency(region: string, commodity: string, dataFrequency: string, market?: DemeterMarket, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityPricesByFrequencyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityPricesByFrequency(region, commodity, dataFrequency, market, currency, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommoditySpotPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommoditySpotPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommoditySpotPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommoditySpotPricesSummary(region: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommoditySpotPricesSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommoditySpotPricesSummary(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityWeeklyPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityWeeklyPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityWeeklyPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityWeeklyPricesSummary(region: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityWeeklyPricesSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityWeeklyPricesSummary(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityYearlyBalances(region: string, commodity: string, unitOfMeasure?: UnitOfMeasure, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityYearlyBalancesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityYearlyBalances(region, commodity, unitOfMeasure, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityYearlyPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityYearlyPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityYearlyPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DemeterMarketNewsType} [marketNewsType] 
         * @param {string} [languageCode] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterHeadlineNews(marketNewsType?: DemeterMarketNewsType, languageCode?: string, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterNewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterHeadlineNews(marketNewsType, languageCode, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DemeterMarket} [market] 
         * @param {boolean} [showAll] 
         * @param {boolean} [showWithData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterLeadingIndicators(market?: DemeterMarket, showAll?: boolean, showWithData?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterLeadingIndicatorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterLeadingIndicators(market, showAll, showWithData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [showExpiredContracts] 
         * @param {boolean} [showUsingExpirationDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterSymbols(showExpiredContracts?: boolean, showUsingExpirationDate?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterSymbolsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterSymbols(showExpiredContracts, showUsingExpirationDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tableDefinitionType 
         * @param {DemeterMarket} [market] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterTableDefinitions(tableDefinitionType: string, market?: DemeterMarket, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterTableDefinitionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterTableDefinitions(tableDefinitionType, market, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tableDefinitionType 
         * @param {DemeterMarket} [market] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterTableDefinitionsByCommodity(tableDefinitionType: string, market?: DemeterMarket, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterTableDefinitionsByCommodityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterTableDefinitionsByCommodity(tableDefinitionType, market, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DemeterUserType} [userType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterUserFeatures(userType?: DemeterUserType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterUserFeaturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterUserFeatures(userType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fromCurrency 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listForeignExchangeRatesMonthly(fromCurrency: string, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListForeignExchangeRatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listForeignExchangeRatesMonthly(fromCurrency, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalDairyTradeWeeklyEvents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListGlobalDairyTradeWeeklyEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalDairyTradeWeeklyEvents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} commodity 
         * @param {DemeterRegion} region 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalDairyTradeWeeklyPrices(commodity: string, region: DemeterRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListGlobalDairyTradeWeeklyPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalDairyTradeWeeklyPrices(commodity, region, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [event] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalDairyTradeWeeklyPricesSummary(event?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListGlobalDairyTradeWeeklyPricesSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalDairyTradeWeeklyPricesSummary(event, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} contractYear 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesByContractYearWithConversions(reutersInstrumentCodePrefix: string, contractYear: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesWithConversionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesByContractYearWithConversions(reutersInstrumentCodePrefix, contractYear, timeSpan, currency, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix1 
         * @param {string} reutersInstrumentCodePrefix2 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {string} [asOfDate] 
         * @param {MarketPricesTimeSpan} [timeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesComparisonWithConversions(reutersInstrumentCodePrefix1: string, reutersInstrumentCodePrefix2: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, asOfDate?: string, timeSpan?: MarketPricesTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesComparisonWithConversionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesComparisonWithConversions(reutersInstrumentCodePrefix1, reutersInstrumentCodePrefix2, currency, unitOfMeasure, asOfDate, timeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} asOfDate 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {MarketPricesTimeSpan} [timeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesForwardCurveWithConversions(reutersInstrumentCodePrefix: string, asOfDate: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, timeSpan?: MarketPricesTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesForwardCurveWithConversionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesForwardCurveWithConversions(reutersInstrumentCodePrefix, asOfDate, currency, unitOfMeasure, timeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} contractNumberStart 
         * @param {string} contractNumberEnd 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesRollingRange(reutersInstrumentCodePrefix: string, contractNumberStart: string, contractNumberEnd: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesRollingRangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesRollingRange(reutersInstrumentCodePrefix, contractNumberStart, contractNumberEnd, timeSpan, currency, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {number} contractNumber 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesRollingWithConversions(reutersInstrumentCodePrefix: string, contractNumber: number, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesWithConversionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesRollingWithConversions(reutersInstrumentCodePrefix, contractNumber, timeSpan, currency, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {number} [startingContractMonth] 
         * @param {Array<number>} [contractMonths] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesRollingYearWithConversions(reutersInstrumentCodePrefix: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, startingContractMonth?: number, contractMonths?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesWithConversionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesRollingYearWithConversions(reutersInstrumentCodePrefix, timeSpan, currency, unitOfMeasure, startingContractMonth, contractMonths, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesWithConversions(reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesWithConversionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesWithConversions(reutersInstrumentCode, timeSpan, currency, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {DemeterDataFrequency} dataFrequency 
         * @param {DemeterMarket} [market] 
         * @param {DemeterCommodity} [commodity] 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRegionPricesByFrequency(region: string, dataFrequency: DemeterDataFrequency, market?: DemeterMarket, commodity?: DemeterCommodity, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityPricesByFrequencyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRegionPricesByFrequency(region, dataFrequency, market, commodity, currency, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDemeterUserFeaturesRequest} [updateDemeterUserFeaturesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDemeterUserFeatures(updateDemeterUserFeaturesRequest?: UpdateDemeterUserFeaturesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterUserFeaturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterUserFeatures(updateDemeterUserFeaturesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterApi - factory interface
 * @export
 */
export const DemeterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterApiFp(configuration)
    return {
        /**
         * 
         * @param {BatchUpdateDemeterLeadingIndicatorsRequest} [batchUpdateDemeterLeadingIndicatorsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateDemeterLeadingIndicators(batchUpdateDemeterLeadingIndicatorsRequest?: BatchUpdateDemeterLeadingIndicatorsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.batchUpdateDemeterLeadingIndicators(batchUpdateDemeterLeadingIndicatorsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchUpdateDemeterLeadingIndicatorsRequest} [batchUpdateDemeterLeadingIndicatorsRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        batchUpdateDemeterLeadingIndicatorsObsolete(batchUpdateDemeterLeadingIndicatorsRequest?: BatchUpdateDemeterLeadingIndicatorsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.batchUpdateDemeterLeadingIndicatorsObsolete(batchUpdateDemeterLeadingIndicatorsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyPricesSeasonal(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityMonthlyPricesSeasonalResponse> {
            return localVarFp.getCommodityMonthlyPricesSeasonal(region, commodity, dataSource, currency, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyFuturesConversionChart(reutersInstrumentCodePrefix: string, options?: any): AxiosPromise<GetCurrencyConversionChartResponse> {
            return localVarFp.getCurrencyFuturesConversionChart(reutersInstrumentCodePrefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyHistoricalConversionChart(reutersInstrumentCodePrefix: string, options?: any): AxiosPromise<GetCurrencyConversionChartResponse> {
            return localVarFp.getCurrencyHistoricalConversionChart(reutersInstrumentCodePrefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DemeterMarket} [market] 
         * @param {DemeterRegion} [region] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {DemeterWorkerType} [workerType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardPresets(market?: DemeterMarket, region?: DemeterRegion, subRegion?: DemeterSubRegion, workerType?: DemeterWorkerType, options?: any): AxiosPromise<GetDemeterDashboardPresetsResponse> {
            return localVarFp.getDashboardPresets(market, region, subRegion, workerType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCommentaryGuid 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterCommentary(demeterCommentaryGuid: string, languageCode?: string, options?: any): AxiosPromise<GetDemeterCommentaryResponse> {
            return localVarFp.getDemeterCommentary(demeterCommentaryGuid, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} articleId 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterHeadlineNews(articleId: string, languageCode?: string, options?: any): AxiosPromise<GetDemeterNewsResponse> {
            return localVarFp.getDemeterHeadlineNews(articleId, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} featureType 
         * @param {DemeterUserType} [userType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUserFeature(featureType: string, userType?: DemeterUserType, options?: any): AxiosPromise<GetDemeterUserFeatureResponse> {
            return localVarFp.getDemeterUserFeature(featureType, userType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitOfMeasureConversionChart(reutersInstrumentCodePrefix: string, options?: any): AxiosPromise<GetUnitOfMeasureConversionChartResponse> {
            return localVarFp.getUnitOfMeasureConversionChart(reutersInstrumentCodePrefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tableDefinitionType 
         * @param {DemeterMarket} [market] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllDemeterTableDefinitions(tableDefinitionType: string, market?: DemeterMarket, options?: any): AxiosPromise<ListDemeterTableDefinitionsResponse> {
            return localVarFp.listAllDemeterTableDefinitions(tableDefinitionType, market, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityBasisPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityBasisPricesResponse> {
            return localVarFp.listCommodityBasisPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyMargins(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityMonthlyMarginsResponse> {
            return localVarFp.listCommodityMonthlyMargins(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityMonthlyPricesResponse> {
            return localVarFp.listCommodityMonthlyPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlySales(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityMonthlySalesResponse> {
            return localVarFp.listCommodityMonthlySales(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {number} contractYear 
         * @param {number} contractMonth 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityOtcPrices(region: string, commodity: string, contractYear: number, contractMonth: number, currency?: Currency, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityOtcPricesResponse> {
            return localVarFp.listCommodityOtcPrices(region, commodity, contractYear, contractMonth, currency, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityOtcPricesCurrent(region: string, commodity: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, asOfDate?: string, options?: any): AxiosPromise<ListCommodityOtcPricesResponse> {
            return localVarFp.listCommodityOtcPricesCurrent(region, commodity, currency, unitOfMeasure, asOfDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} asOfDate 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {Currency} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityOtcPricesForwardCurve(region: string, commodity: string, asOfDate: string, filterTimeSpan?: DemeterFilterTimeSpan, unitOfMeasure?: UnitOfMeasure, currency?: Currency, options?: any): AxiosPromise<ListCommodityOtcPricesForwardCurveResponse> {
            return localVarFp.listCommodityOtcPricesForwardCurve(region, commodity, asOfDate, filterTimeSpan, unitOfMeasure, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterMarket} [market] 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityPricesByFrequency(region: string, commodity: string, dataFrequency: string, market?: DemeterMarket, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<ListCommodityPricesByFrequencyResponse> {
            return localVarFp.listCommodityPricesByFrequency(region, commodity, dataFrequency, market, currency, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommoditySpotPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommoditySpotPricesResponse> {
            return localVarFp.listCommoditySpotPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommoditySpotPricesSummary(region: string, options?: any): AxiosPromise<ListCommoditySpotPricesSummaryResponse> {
            return localVarFp.listCommoditySpotPricesSummary(region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityWeeklyPricesResponse> {
            return localVarFp.listCommodityWeeklyPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyPricesSummary(region: string, options?: any): AxiosPromise<ListCommodityWeeklyPricesSummaryResponse> {
            return localVarFp.listCommodityWeeklyPricesSummary(region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityYearlyBalances(region: string, commodity: string, unitOfMeasure?: UnitOfMeasure, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListCommodityYearlyBalancesResponse> {
            return localVarFp.listCommodityYearlyBalances(region, commodity, unitOfMeasure, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {Currency} [currency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityYearlyPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityYearlyPricesResponse> {
            return localVarFp.listCommodityYearlyPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DemeterMarketNewsType} [marketNewsType] 
         * @param {string} [languageCode] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterHeadlineNews(marketNewsType?: DemeterMarketNewsType, languageCode?: string, take?: number, options?: any): AxiosPromise<ListDemeterNewsResponse> {
            return localVarFp.listDemeterHeadlineNews(marketNewsType, languageCode, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DemeterMarket} [market] 
         * @param {boolean} [showAll] 
         * @param {boolean} [showWithData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterLeadingIndicators(market?: DemeterMarket, showAll?: boolean, showWithData?: boolean, options?: any): AxiosPromise<ListDemeterLeadingIndicatorsResponse> {
            return localVarFp.listDemeterLeadingIndicators(market, showAll, showWithData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [showExpiredContracts] 
         * @param {boolean} [showUsingExpirationDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterSymbols(showExpiredContracts?: boolean, showUsingExpirationDate?: boolean, options?: any): AxiosPromise<ListDemeterSymbolsResponse> {
            return localVarFp.listDemeterSymbols(showExpiredContracts, showUsingExpirationDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tableDefinitionType 
         * @param {DemeterMarket} [market] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterTableDefinitions(tableDefinitionType: string, market?: DemeterMarket, options?: any): AxiosPromise<ListDemeterTableDefinitionsResponse> {
            return localVarFp.listDemeterTableDefinitions(tableDefinitionType, market, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tableDefinitionType 
         * @param {DemeterMarket} [market] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterTableDefinitionsByCommodity(tableDefinitionType: string, market?: DemeterMarket, options?: any): AxiosPromise<ListDemeterTableDefinitionsByCommodityResponse> {
            return localVarFp.listDemeterTableDefinitionsByCommodity(tableDefinitionType, market, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DemeterUserType} [userType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterUserFeatures(userType?: DemeterUserType, options?: any): AxiosPromise<ListDemeterUserFeaturesResponse> {
            return localVarFp.listDemeterUserFeatures(userType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fromCurrency 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listForeignExchangeRatesMonthly(fromCurrency: string, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListForeignExchangeRatesResponse> {
            return localVarFp.listForeignExchangeRatesMonthly(fromCurrency, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalDairyTradeWeeklyEvents(options?: any): AxiosPromise<ListGlobalDairyTradeWeeklyEventsResponse> {
            return localVarFp.listGlobalDairyTradeWeeklyEvents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} commodity 
         * @param {DemeterRegion} region 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalDairyTradeWeeklyPrices(commodity: string, region: DemeterRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListGlobalDairyTradeWeeklyPricesResponse> {
            return localVarFp.listGlobalDairyTradeWeeklyPrices(commodity, region, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [event] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalDairyTradeWeeklyPricesSummary(event?: number, options?: any): AxiosPromise<ListGlobalDairyTradeWeeklyPricesSummaryResponse> {
            return localVarFp.listGlobalDairyTradeWeeklyPricesSummary(event, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} contractYear 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesByContractYearWithConversions(reutersInstrumentCodePrefix: string, contractYear: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<ListMarketPricesWithConversionsResponse> {
            return localVarFp.listMarketPricesByContractYearWithConversions(reutersInstrumentCodePrefix, contractYear, timeSpan, currency, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix1 
         * @param {string} reutersInstrumentCodePrefix2 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {string} [asOfDate] 
         * @param {MarketPricesTimeSpan} [timeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesComparisonWithConversions(reutersInstrumentCodePrefix1: string, reutersInstrumentCodePrefix2: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, asOfDate?: string, timeSpan?: MarketPricesTimeSpan, options?: any): AxiosPromise<ListMarketPricesComparisonWithConversionsResponse> {
            return localVarFp.listMarketPricesComparisonWithConversions(reutersInstrumentCodePrefix1, reutersInstrumentCodePrefix2, currency, unitOfMeasure, asOfDate, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} asOfDate 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {MarketPricesTimeSpan} [timeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesForwardCurveWithConversions(reutersInstrumentCodePrefix: string, asOfDate: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, timeSpan?: MarketPricesTimeSpan, options?: any): AxiosPromise<ListMarketPricesForwardCurveWithConversionsResponse> {
            return localVarFp.listMarketPricesForwardCurveWithConversions(reutersInstrumentCodePrefix, asOfDate, currency, unitOfMeasure, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} contractNumberStart 
         * @param {string} contractNumberEnd 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesRollingRange(reutersInstrumentCodePrefix: string, contractNumberStart: string, contractNumberEnd: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<ListMarketPricesRollingRangeResponse> {
            return localVarFp.listMarketPricesRollingRange(reutersInstrumentCodePrefix, contractNumberStart, contractNumberEnd, timeSpan, currency, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {number} contractNumber 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesRollingWithConversions(reutersInstrumentCodePrefix: string, contractNumber: number, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<ListMarketPricesWithConversionsResponse> {
            return localVarFp.listMarketPricesRollingWithConversions(reutersInstrumentCodePrefix, contractNumber, timeSpan, currency, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {number} [startingContractMonth] 
         * @param {Array<number>} [contractMonths] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesRollingYearWithConversions(reutersInstrumentCodePrefix: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, startingContractMonth?: number, contractMonths?: Array<number>, options?: any): AxiosPromise<ListMarketPricesWithConversionsResponse> {
            return localVarFp.listMarketPricesRollingYearWithConversions(reutersInstrumentCodePrefix, timeSpan, currency, unitOfMeasure, startingContractMonth, contractMonths, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesWithConversions(reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<ListMarketPricesWithConversionsResponse> {
            return localVarFp.listMarketPricesWithConversions(reutersInstrumentCode, timeSpan, currency, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {DemeterDataFrequency} dataFrequency 
         * @param {DemeterMarket} [market] 
         * @param {DemeterCommodity} [commodity] 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegionPricesByFrequency(region: string, dataFrequency: DemeterDataFrequency, market?: DemeterMarket, commodity?: DemeterCommodity, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<ListCommodityPricesByFrequencyResponse> {
            return localVarFp.listRegionPricesByFrequency(region, dataFrequency, market, commodity, currency, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDemeterUserFeaturesRequest} [updateDemeterUserFeaturesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUserFeatures(updateDemeterUserFeaturesRequest?: UpdateDemeterUserFeaturesRequest, options?: any): AxiosPromise<ListDemeterUserFeaturesResponse> {
            return localVarFp.updateDemeterUserFeatures(updateDemeterUserFeaturesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterApi - object-oriented interface
 * @export
 * @class DemeterApi
 * @extends {BaseAPI}
 */
export class DemeterApi extends BaseAPI {
    /**
     * 
     * @param {BatchUpdateDemeterLeadingIndicatorsRequest} [batchUpdateDemeterLeadingIndicatorsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public batchUpdateDemeterLeadingIndicators(batchUpdateDemeterLeadingIndicatorsRequest?: BatchUpdateDemeterLeadingIndicatorsRequest, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).batchUpdateDemeterLeadingIndicators(batchUpdateDemeterLeadingIndicatorsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchUpdateDemeterLeadingIndicatorsRequest} [batchUpdateDemeterLeadingIndicatorsRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public batchUpdateDemeterLeadingIndicatorsObsolete(batchUpdateDemeterLeadingIndicatorsRequest?: BatchUpdateDemeterLeadingIndicatorsRequest, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).batchUpdateDemeterLeadingIndicatorsObsolete(batchUpdateDemeterLeadingIndicatorsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterDataSource} [dataSource] 
     * @param {Currency} [currency] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public getCommodityMonthlyPricesSeasonal(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).getCommodityMonthlyPricesSeasonal(region, commodity, dataSource, currency, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public getCurrencyFuturesConversionChart(reutersInstrumentCodePrefix: string, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).getCurrencyFuturesConversionChart(reutersInstrumentCodePrefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public getCurrencyHistoricalConversionChart(reutersInstrumentCodePrefix: string, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).getCurrencyHistoricalConversionChart(reutersInstrumentCodePrefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DemeterMarket} [market] 
     * @param {DemeterRegion} [region] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {DemeterWorkerType} [workerType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public getDashboardPresets(market?: DemeterMarket, region?: DemeterRegion, subRegion?: DemeterSubRegion, workerType?: DemeterWorkerType, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).getDashboardPresets(market, region, subRegion, workerType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCommentaryGuid 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public getDemeterCommentary(demeterCommentaryGuid: string, languageCode?: string, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).getDemeterCommentary(demeterCommentaryGuid, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} articleId 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public getDemeterHeadlineNews(articleId: string, languageCode?: string, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).getDemeterHeadlineNews(articleId, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} featureType 
     * @param {DemeterUserType} [userType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public getDemeterUserFeature(featureType: string, userType?: DemeterUserType, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).getDemeterUserFeature(featureType, userType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public getUnitOfMeasureConversionChart(reutersInstrumentCodePrefix: string, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).getUnitOfMeasureConversionChart(reutersInstrumentCodePrefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tableDefinitionType 
     * @param {DemeterMarket} [market] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listAllDemeterTableDefinitions(tableDefinitionType: string, market?: DemeterMarket, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listAllDemeterTableDefinitions(tableDefinitionType, market, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterDataSource} [dataSource] 
     * @param {Currency} [currency] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommodityBasisPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommodityBasisPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommodityMonthlyMargins(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommodityMonthlyMargins(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterDataSource} [dataSource] 
     * @param {Currency} [currency] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommodityMonthlyPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommodityMonthlyPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommodityMonthlySales(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommodityMonthlySales(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {number} contractYear 
     * @param {number} contractMonth 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommodityOtcPrices(region: string, commodity: string, contractYear: number, contractMonth: number, currency?: Currency, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommodityOtcPrices(region, commodity, contractYear, contractMonth, currency, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {string} [asOfDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommodityOtcPricesCurrent(region: string, commodity: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, asOfDate?: string, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommodityOtcPricesCurrent(region, commodity, currency, unitOfMeasure, asOfDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {string} asOfDate 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {Currency} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommodityOtcPricesForwardCurve(region: string, commodity: string, asOfDate: string, filterTimeSpan?: DemeterFilterTimeSpan, unitOfMeasure?: UnitOfMeasure, currency?: Currency, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommodityOtcPricesForwardCurve(region, commodity, asOfDate, filterTimeSpan, unitOfMeasure, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {string} dataFrequency 
     * @param {DemeterMarket} [market] 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommodityPricesByFrequency(region: string, commodity: string, dataFrequency: string, market?: DemeterMarket, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommodityPricesByFrequency(region, commodity, dataFrequency, market, currency, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterDataSource} [dataSource] 
     * @param {Currency} [currency] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommoditySpotPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommoditySpotPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommoditySpotPricesSummary(region: string, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommoditySpotPricesSummary(region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterDataSource} [dataSource] 
     * @param {Currency} [currency] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommodityWeeklyPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommodityWeeklyPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommodityWeeklyPricesSummary(region: string, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommodityWeeklyPricesSummary(region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommodityYearlyBalances(region: string, commodity: string, unitOfMeasure?: UnitOfMeasure, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommodityYearlyBalances(region, commodity, unitOfMeasure, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterDataSource} [dataSource] 
     * @param {Currency} [currency] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listCommodityYearlyPrices(region: string, commodity: string, dataSource?: DemeterDataSource, currency?: Currency, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listCommodityYearlyPrices(region, commodity, dataSource, currency, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DemeterMarketNewsType} [marketNewsType] 
     * @param {string} [languageCode] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listDemeterHeadlineNews(marketNewsType?: DemeterMarketNewsType, languageCode?: string, take?: number, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listDemeterHeadlineNews(marketNewsType, languageCode, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DemeterMarket} [market] 
     * @param {boolean} [showAll] 
     * @param {boolean} [showWithData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listDemeterLeadingIndicators(market?: DemeterMarket, showAll?: boolean, showWithData?: boolean, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listDemeterLeadingIndicators(market, showAll, showWithData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [showExpiredContracts] 
     * @param {boolean} [showUsingExpirationDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listDemeterSymbols(showExpiredContracts?: boolean, showUsingExpirationDate?: boolean, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listDemeterSymbols(showExpiredContracts, showUsingExpirationDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tableDefinitionType 
     * @param {DemeterMarket} [market] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listDemeterTableDefinitions(tableDefinitionType: string, market?: DemeterMarket, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listDemeterTableDefinitions(tableDefinitionType, market, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tableDefinitionType 
     * @param {DemeterMarket} [market] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listDemeterTableDefinitionsByCommodity(tableDefinitionType: string, market?: DemeterMarket, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listDemeterTableDefinitionsByCommodity(tableDefinitionType, market, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DemeterUserType} [userType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listDemeterUserFeatures(userType?: DemeterUserType, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listDemeterUserFeatures(userType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fromCurrency 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listForeignExchangeRatesMonthly(fromCurrency: string, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listForeignExchangeRatesMonthly(fromCurrency, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listGlobalDairyTradeWeeklyEvents(options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listGlobalDairyTradeWeeklyEvents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} commodity 
     * @param {DemeterRegion} region 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listGlobalDairyTradeWeeklyPrices(commodity: string, region: DemeterRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listGlobalDairyTradeWeeklyPrices(commodity, region, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [event] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listGlobalDairyTradeWeeklyPricesSummary(event?: number, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listGlobalDairyTradeWeeklyPricesSummary(event, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {string} contractYear 
     * @param {MarketPricesTimeSpan} timeSpan 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listMarketPricesByContractYearWithConversions(reutersInstrumentCodePrefix: string, contractYear: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listMarketPricesByContractYearWithConversions(reutersInstrumentCodePrefix, contractYear, timeSpan, currency, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix1 
     * @param {string} reutersInstrumentCodePrefix2 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {string} [asOfDate] 
     * @param {MarketPricesTimeSpan} [timeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listMarketPricesComparisonWithConversions(reutersInstrumentCodePrefix1: string, reutersInstrumentCodePrefix2: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, asOfDate?: string, timeSpan?: MarketPricesTimeSpan, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listMarketPricesComparisonWithConversions(reutersInstrumentCodePrefix1, reutersInstrumentCodePrefix2, currency, unitOfMeasure, asOfDate, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {string} asOfDate 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {MarketPricesTimeSpan} [timeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listMarketPricesForwardCurveWithConversions(reutersInstrumentCodePrefix: string, asOfDate: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, timeSpan?: MarketPricesTimeSpan, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listMarketPricesForwardCurveWithConversions(reutersInstrumentCodePrefix, asOfDate, currency, unitOfMeasure, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {string} contractNumberStart 
     * @param {string} contractNumberEnd 
     * @param {MarketPricesTimeSpan} timeSpan 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listMarketPricesRollingRange(reutersInstrumentCodePrefix: string, contractNumberStart: string, contractNumberEnd: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listMarketPricesRollingRange(reutersInstrumentCodePrefix, contractNumberStart, contractNumberEnd, timeSpan, currency, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {number} contractNumber 
     * @param {MarketPricesTimeSpan} timeSpan 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listMarketPricesRollingWithConversions(reutersInstrumentCodePrefix: string, contractNumber: number, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listMarketPricesRollingWithConversions(reutersInstrumentCodePrefix, contractNumber, timeSpan, currency, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {MarketPricesTimeSpan} timeSpan 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {number} [startingContractMonth] 
     * @param {Array<number>} [contractMonths] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listMarketPricesRollingYearWithConversions(reutersInstrumentCodePrefix: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, startingContractMonth?: number, contractMonths?: Array<number>, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listMarketPricesRollingYearWithConversions(reutersInstrumentCodePrefix, timeSpan, currency, unitOfMeasure, startingContractMonth, contractMonths, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCode 
     * @param {MarketPricesTimeSpan} timeSpan 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listMarketPricesWithConversions(reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listMarketPricesWithConversions(reutersInstrumentCode, timeSpan, currency, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {DemeterDataFrequency} dataFrequency 
     * @param {DemeterMarket} [market] 
     * @param {DemeterCommodity} [commodity] 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public listRegionPricesByFrequency(region: string, dataFrequency: DemeterDataFrequency, market?: DemeterMarket, commodity?: DemeterCommodity, currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).listRegionPricesByFrequency(region, dataFrequency, market, commodity, currency, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDemeterUserFeaturesRequest} [updateDemeterUserFeaturesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterApi
     */
    public updateDemeterUserFeatures(updateDemeterUserFeaturesRequest?: UpdateDemeterUserFeaturesRequest, options?: AxiosRequestConfig) {
        return DemeterApiFp(this.configuration).updateDemeterUserFeatures(updateDemeterUserFeaturesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterCalculatorsApi - axios parameter creator
 * @export
 */
export const DemeterCalculatorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {AddCalculationEngineInputsRequest} [addCalculationEngineInputsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCalculationEngineInputs: async (demeterCalculationEngineGuid: string, addCalculationEngineInputsRequest?: AddCalculationEngineInputsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCalculationEngineGuid' is not null or undefined
            assertParamExists('addCalculationEngineInputs', 'demeterCalculationEngineGuid', demeterCalculationEngineGuid)
            const localVarPath = `/demeter/calculators/calculation-engines/{demeterCalculationEngineGuid}/inputs`
                .replace(`{${"demeterCalculationEngineGuid"}}`, encodeURIComponent(String(demeterCalculationEngineGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCalculationEngineInputsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} demeterCalculationEngineInputsGuid 
         * @param {DeleteCalculationEngineInputsRequest} [deleteCalculationEngineInputsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalculationEngineInputs: async (demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, deleteCalculationEngineInputsRequest?: DeleteCalculationEngineInputsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCalculationEngineGuid' is not null or undefined
            assertParamExists('deleteCalculationEngineInputs', 'demeterCalculationEngineGuid', demeterCalculationEngineGuid)
            // verify required parameter 'demeterCalculationEngineInputsGuid' is not null or undefined
            assertParamExists('deleteCalculationEngineInputs', 'demeterCalculationEngineInputsGuid', demeterCalculationEngineInputsGuid)
            const localVarPath = `/demeter/calculators/calculation-engines/{demeterCalculationEngineGuid}/inputs/{demeterCalculationEngineInputsGuid}`
                .replace(`{${"demeterCalculationEngineGuid"}}`, encodeURIComponent(String(demeterCalculationEngineGuid)))
                .replace(`{${"demeterCalculationEngineInputsGuid"}}`, encodeURIComponent(String(demeterCalculationEngineInputsGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCalculationEngineInputsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DownloadValueMatrixRequest} [downloadValueMatrixRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadValueMatrixCalculator: async (region: string, commodity: string, downloadValueMatrixRequest?: DownloadValueMatrixRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('downloadValueMatrixCalculator', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('downloadValueMatrixCalculator', 'commodity', commodity)
            const localVarPath = `/demeter/calculators/value-matrix/{region}/{commodity}/download`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadValueMatrixRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} [demeterUserCalculatorGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBudgetCalculator: async (region: string, commodity: string, demeterUserCalculatorGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getBudgetCalculator', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getBudgetCalculator', 'commodity', commodity)
            const localVarPath = `/demeter/calculators/budget/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (demeterUserCalculatorGuid !== undefined) {
                localVarQueryParameter['DemeterUserCalculatorGuid'] = demeterUserCalculatorGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserCalculatorGuid 
         * @param {DemeterRegion} [region] 
         * @param {DemeterCommodity} [commodity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBudgetCalculatorByGuid: async (demeterUserCalculatorGuid: string, region?: DemeterRegion, commodity?: DemeterCommodity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserCalculatorGuid' is not null or undefined
            assertParamExists('getBudgetCalculatorByGuid', 'demeterUserCalculatorGuid', demeterUserCalculatorGuid)
            const localVarPath = `/demeter/calculators/budget/{demeterUserCalculatorGuid}`
                .replace(`{${"demeterUserCalculatorGuid"}}`, encodeURIComponent(String(demeterUserCalculatorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['Region'] = region;
            }

            if (commodity !== undefined) {
                localVarQueryParameter['Commodity'] = commodity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculationEngine: async (demeterCalculationEngineGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCalculationEngineGuid' is not null or undefined
            assertParamExists('getCalculationEngine', 'demeterCalculationEngineGuid', demeterCalculationEngineGuid)
            const localVarPath = `/demeter/calculators/calculation-engines/{demeterCalculationEngineGuid}`
                .replace(`{${"demeterCalculationEngineGuid"}}`, encodeURIComponent(String(demeterCalculationEngineGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} demeterCalculationEngineInputsGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculationEngineInputs: async (demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCalculationEngineGuid' is not null or undefined
            assertParamExists('getCalculationEngineInputs', 'demeterCalculationEngineGuid', demeterCalculationEngineGuid)
            // verify required parameter 'demeterCalculationEngineInputsGuid' is not null or undefined
            assertParamExists('getCalculationEngineInputs', 'demeterCalculationEngineInputsGuid', demeterCalculationEngineInputsGuid)
            const localVarPath = `/demeter/calculators/calculation-engines/{demeterCalculationEngineGuid}/inputs/{demeterCalculationEngineInputsGuid}`
                .replace(`{${"demeterCalculationEngineGuid"}}`, encodeURIComponent(String(demeterCalculationEngineGuid)))
                .replace(`{${"demeterCalculationEngineInputsGuid"}}`, encodeURIComponent(String(demeterCalculationEngineInputsGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} [demeterCalculationEngineInputsGuid] 
         * @param {boolean} [refreshCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFarmerMarginCalculator: async (demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid?: string, refreshCache?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCalculationEngineGuid' is not null or undefined
            assertParamExists('getFarmerMarginCalculator', 'demeterCalculationEngineGuid', demeterCalculationEngineGuid)
            const localVarPath = `/demeter/calculators/farmer-margin/{demeterCalculationEngineGuid}`
                .replace(`{${"demeterCalculationEngineGuid"}}`, encodeURIComponent(String(demeterCalculationEngineGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (demeterCalculationEngineInputsGuid !== undefined) {
                localVarQueryParameter['DemeterCalculationEngineInputsGuid'] = demeterCalculationEngineInputsGuid;
            }

            if (refreshCache !== undefined) {
                localVarQueryParameter['RefreshCache'] = refreshCache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFederalMilkMarketingOrderCalculator: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/calculators/federal-milk-marketing-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} [demeterUserCalculatorGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionsCalculator: async (region: string, commodity: string, demeterUserCalculatorGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getPositionsCalculator', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getPositionsCalculator', 'commodity', commodity)
            const localVarPath = `/demeter/calculators/positions/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (demeterUserCalculatorGuid !== undefined) {
                localVarQueryParameter['DemeterUserCalculatorGuid'] = demeterUserCalculatorGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserCalculatorGuid 
         * @param {DemeterRegion} [region] 
         * @param {DemeterCommodity} [commodity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionsCalculatorByGuid: async (demeterUserCalculatorGuid: string, region?: DemeterRegion, commodity?: DemeterCommodity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserCalculatorGuid' is not null or undefined
            assertParamExists('getPositionsCalculatorByGuid', 'demeterUserCalculatorGuid', demeterUserCalculatorGuid)
            const localVarPath = `/demeter/calculators/positions/{demeterUserCalculatorGuid}`
                .replace(`{${"demeterUserCalculatorGuid"}}`, encodeURIComponent(String(demeterUserCalculatorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['Region'] = region;
            }

            if (commodity !== undefined) {
                localVarQueryParameter['Commodity'] = commodity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValueMatrixCalculator: async (region: string, commodity: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getValueMatrixCalculator', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getValueMatrixCalculator', 'commodity', commodity)
            const localVarPath = `/demeter/calculators/value-matrix/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBudgetCalculators: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/calculators/budget`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [demeterCalculationEngineGuid] 
         * @param {DemeterCalculatorType} [calculatorType] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalculationEngineInputs: async (demeterCalculationEngineGuid?: string, calculatorType?: DemeterCalculatorType, groupName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/calculators/calculation-engines/inputs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (demeterCalculationEngineGuid !== undefined) {
                localVarQueryParameter['DemeterCalculationEngineGuid'] = demeterCalculationEngineGuid;
            }

            if (calculatorType !== undefined) {
                localVarQueryParameter['CalculatorType'] = calculatorType;
            }

            if (groupName !== undefined) {
                localVarQueryParameter['GroupName'] = groupName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} calculatorType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalculationEngineInputsByGroup: async (calculatorType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calculatorType' is not null or undefined
            assertParamExists('listCalculationEngineInputsByGroup', 'calculatorType', calculatorType)
            const localVarPath = `/demeter/calculators/calculation-engines/inputs/groups/{calculatorType}`
                .replace(`{${"calculatorType"}}`, encodeURIComponent(String(calculatorType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DemeterCalculatorType} [calculatorType] 
         * @param {boolean} [showAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalculationEngines: async (calculatorType?: DemeterCalculatorType, showAll?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/calculators/calculation-engines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (calculatorType !== undefined) {
                localVarQueryParameter['CalculatorType'] = calculatorType;
            }

            if (showAll !== undefined) {
                localVarQueryParameter['ShowAll'] = showAll;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFarmerMarginCalculators: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/calculators/farmer-margin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPositionsCalculators: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/calculators/positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listValueMatrixCalculators: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/calculators/value-matrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} demeterCalculationEngineInputsGuid 
         * @param {RunCalculationEngineRequest} [runCalculationEngineRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCalculationEngine: async (demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, runCalculationEngineRequest?: RunCalculationEngineRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCalculationEngineGuid' is not null or undefined
            assertParamExists('runCalculationEngine', 'demeterCalculationEngineGuid', demeterCalculationEngineGuid)
            // verify required parameter 'demeterCalculationEngineInputsGuid' is not null or undefined
            assertParamExists('runCalculationEngine', 'demeterCalculationEngineInputsGuid', demeterCalculationEngineInputsGuid)
            const localVarPath = `/demeter/calculators/calculation-engines/{demeterCalculationEngineGuid}/inputs/{demeterCalculationEngineInputsGuid}/run`
                .replace(`{${"demeterCalculationEngineGuid"}}`, encodeURIComponent(String(demeterCalculationEngineGuid)))
                .replace(`{${"demeterCalculationEngineInputsGuid"}}`, encodeURIComponent(String(demeterCalculationEngineInputsGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(runCalculationEngineRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {RunTestCalculationEngineRequest} [runTestCalculationEngineRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestCalculationEngine: async (demeterCalculationEngineGuid: string, runTestCalculationEngineRequest?: RunTestCalculationEngineRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCalculationEngineGuid' is not null or undefined
            assertParamExists('runTestCalculationEngine', 'demeterCalculationEngineGuid', demeterCalculationEngineGuid)
            const localVarPath = `/demeter/calculators/calculation-engines/{demeterCalculationEngineGuid}/run/test`
                .replace(`{${"demeterCalculationEngineGuid"}}`, encodeURIComponent(String(demeterCalculationEngineGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(runTestCalculationEngineRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {RunTestFarmerMarginCalculatorRequest} [runTestFarmerMarginCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestFarmerMarginCalculator: async (demeterCalculationEngineGuid: string, runTestFarmerMarginCalculatorRequest?: RunTestFarmerMarginCalculatorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCalculationEngineGuid' is not null or undefined
            assertParamExists('runTestFarmerMarginCalculator', 'demeterCalculationEngineGuid', demeterCalculationEngineGuid)
            const localVarPath = `/demeter/calculators/farmer-margin/{demeterCalculationEngineGuid}/run/test`
                .replace(`{${"demeterCalculationEngineGuid"}}`, encodeURIComponent(String(demeterCalculationEngineGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(runTestFarmerMarginCalculatorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RunValueMatrixRequest} [runValueMatrixRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runValueMatrix: async (runValueMatrixRequest?: RunValueMatrixRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/calculators/value-matrix/run`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(runValueMatrixRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {UpdateBudgetCalculatorRequest} [updateBudgetCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBudgetCalculator: async (region: string, commodity: string, updateBudgetCalculatorRequest?: UpdateBudgetCalculatorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('updateBudgetCalculator', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('updateBudgetCalculator', 'commodity', commodity)
            const localVarPath = `/demeter/calculators/budget/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBudgetCalculatorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} demeterCalculationEngineInputsGuid 
         * @param {UpdateCalculationEngineInputsRequest} [updateCalculationEngineInputsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalculationEngineInputs: async (demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, updateCalculationEngineInputsRequest?: UpdateCalculationEngineInputsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCalculationEngineGuid' is not null or undefined
            assertParamExists('updateCalculationEngineInputs', 'demeterCalculationEngineGuid', demeterCalculationEngineGuid)
            // verify required parameter 'demeterCalculationEngineInputsGuid' is not null or undefined
            assertParamExists('updateCalculationEngineInputs', 'demeterCalculationEngineInputsGuid', demeterCalculationEngineInputsGuid)
            const localVarPath = `/demeter/calculators/calculation-engines/{demeterCalculationEngineGuid}/inputs/{demeterCalculationEngineInputsGuid}`
                .replace(`{${"demeterCalculationEngineGuid"}}`, encodeURIComponent(String(demeterCalculationEngineGuid)))
                .replace(`{${"demeterCalculationEngineInputsGuid"}}`, encodeURIComponent(String(demeterCalculationEngineInputsGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCalculationEngineInputsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateFederalMilkMarketingOrderCalculatorRequest} [updateFederalMilkMarketingOrderCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFederalMilkMarketingOrderCalculator: async (updateFederalMilkMarketingOrderCalculatorRequest?: UpdateFederalMilkMarketingOrderCalculatorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/calculators/federal-milk-marketing-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFederalMilkMarketingOrderCalculatorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {UpdatePositionsCalculatorRequest} [updatePositionsCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePositionsCalculator: async (region: string, commodity: string, updatePositionsCalculatorRequest?: UpdatePositionsCalculatorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('updatePositionsCalculator', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('updatePositionsCalculator', 'commodity', commodity)
            const localVarPath = `/demeter/calculators/positions/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePositionsCalculatorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {UpdateValueMatrixCalculatorRequest} [updateValueMatrixCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValueMatrixCalculator: async (region: string, commodity: string, updateValueMatrixCalculatorRequest?: UpdateValueMatrixCalculatorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('updateValueMatrixCalculator', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('updateValueMatrixCalculator', 'commodity', commodity)
            const localVarPath = `/demeter/calculators/value-matrix/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateValueMatrixCalculatorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {UpdateCalculationEngineInputsRequest} [updateCalculationEngineInputsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertCalculationEngineInputs: async (demeterCalculationEngineGuid: string, updateCalculationEngineInputsRequest?: UpdateCalculationEngineInputsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCalculationEngineGuid' is not null or undefined
            assertParamExists('upsertCalculationEngineInputs', 'demeterCalculationEngineGuid', demeterCalculationEngineGuid)
            const localVarPath = `/demeter/calculators/calculation-engines/{demeterCalculationEngineGuid}/inputs`
                .replace(`{${"demeterCalculationEngineGuid"}}`, encodeURIComponent(String(demeterCalculationEngineGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCalculationEngineInputsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterCalculatorsApi - functional programming interface
 * @export
 */
export const DemeterCalculatorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterCalculatorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {AddCalculationEngineInputsRequest} [addCalculationEngineInputsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCalculationEngineInputs(demeterCalculationEngineGuid: string, addCalculationEngineInputsRequest?: AddCalculationEngineInputsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCalculationEngineInputsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCalculationEngineInputs(demeterCalculationEngineGuid, addCalculationEngineInputsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} demeterCalculationEngineInputsGuid 
         * @param {DeleteCalculationEngineInputsRequest} [deleteCalculationEngineInputsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalculationEngineInputs(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, deleteCalculationEngineInputsRequest?: DeleteCalculationEngineInputsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalculationEngineInputs(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, deleteCalculationEngineInputsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DownloadValueMatrixRequest} [downloadValueMatrixRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadValueMatrixCalculator(region: string, commodity: string, downloadValueMatrixRequest?: DownloadValueMatrixRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadValueMatrixResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadValueMatrixCalculator(region, commodity, downloadValueMatrixRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} [demeterUserCalculatorGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBudgetCalculator(region: string, commodity: string, demeterUserCalculatorGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBudgetCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBudgetCalculator(region, commodity, demeterUserCalculatorGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserCalculatorGuid 
         * @param {DemeterRegion} [region] 
         * @param {DemeterCommodity} [commodity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBudgetCalculatorByGuid(demeterUserCalculatorGuid: string, region?: DemeterRegion, commodity?: DemeterCommodity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBudgetCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBudgetCalculatorByGuid(demeterUserCalculatorGuid, region, commodity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalculationEngine(demeterCalculationEngineGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCalculationEngineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalculationEngine(demeterCalculationEngineGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} demeterCalculationEngineInputsGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalculationEngineInputs(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCalculationEngineInputsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalculationEngineInputs(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} [demeterCalculationEngineInputsGuid] 
         * @param {boolean} [refreshCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFarmerMarginCalculator(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid?: string, refreshCache?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFarmerMarginCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFarmerMarginCalculator(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, refreshCache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFederalMilkMarketingOrderCalculator(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFederalMilkMarketingOrderCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFederalMilkMarketingOrderCalculator(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} [demeterUserCalculatorGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPositionsCalculator(region: string, commodity: string, demeterUserCalculatorGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPositionsCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPositionsCalculator(region, commodity, demeterUserCalculatorGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserCalculatorGuid 
         * @param {DemeterRegion} [region] 
         * @param {DemeterCommodity} [commodity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPositionsCalculatorByGuid(demeterUserCalculatorGuid: string, region?: DemeterRegion, commodity?: DemeterCommodity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPositionsCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPositionsCalculatorByGuid(demeterUserCalculatorGuid, region, commodity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getValueMatrixCalculator(region: string, commodity: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetValueMatrixCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getValueMatrixCalculator(region, commodity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBudgetCalculators(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBudgetCalculatorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBudgetCalculators(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [demeterCalculationEngineGuid] 
         * @param {DemeterCalculatorType} [calculatorType] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCalculationEngineInputs(demeterCalculationEngineGuid?: string, calculatorType?: DemeterCalculatorType, groupName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCalculationEngineInputsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCalculationEngineInputs(demeterCalculationEngineGuid, calculatorType, groupName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} calculatorType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCalculationEngineInputsByGroup(calculatorType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCalculationEngineInputsByGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCalculationEngineInputsByGroup(calculatorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DemeterCalculatorType} [calculatorType] 
         * @param {boolean} [showAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCalculationEngines(calculatorType?: DemeterCalculatorType, showAll?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCalculationEnginesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCalculationEngines(calculatorType, showAll, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFarmerMarginCalculators(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFarmerMarginCalculatorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFarmerMarginCalculators(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPositionsCalculators(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPositionsCalculatorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPositionsCalculators(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listValueMatrixCalculators(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListValueMatrixCalculatorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listValueMatrixCalculators(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} demeterCalculationEngineInputsGuid 
         * @param {RunCalculationEngineRequest} [runCalculationEngineRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runCalculationEngine(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, runCalculationEngineRequest?: RunCalculationEngineRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunCalculationEngineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runCalculationEngine(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, runCalculationEngineRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {RunTestCalculationEngineRequest} [runTestCalculationEngineRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runTestCalculationEngine(demeterCalculationEngineGuid: string, runTestCalculationEngineRequest?: RunTestCalculationEngineRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunCalculationEngineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runTestCalculationEngine(demeterCalculationEngineGuid, runTestCalculationEngineRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {RunTestFarmerMarginCalculatorRequest} [runTestFarmerMarginCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runTestFarmerMarginCalculator(demeterCalculationEngineGuid: string, runTestFarmerMarginCalculatorRequest?: RunTestFarmerMarginCalculatorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunFarmerMarginCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runTestFarmerMarginCalculator(demeterCalculationEngineGuid, runTestFarmerMarginCalculatorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RunValueMatrixRequest} [runValueMatrixRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runValueMatrix(runValueMatrixRequest?: RunValueMatrixRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunValueMatrixResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runValueMatrix(runValueMatrixRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {UpdateBudgetCalculatorRequest} [updateBudgetCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBudgetCalculator(region: string, commodity: string, updateBudgetCalculatorRequest?: UpdateBudgetCalculatorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBudgetCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBudgetCalculator(region, commodity, updateBudgetCalculatorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} demeterCalculationEngineInputsGuid 
         * @param {UpdateCalculationEngineInputsRequest} [updateCalculationEngineInputsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalculationEngineInputs(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, updateCalculationEngineInputsRequest?: UpdateCalculationEngineInputsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCalculationEngineInputsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalculationEngineInputs(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, updateCalculationEngineInputsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateFederalMilkMarketingOrderCalculatorRequest} [updateFederalMilkMarketingOrderCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFederalMilkMarketingOrderCalculator(updateFederalMilkMarketingOrderCalculatorRequest?: UpdateFederalMilkMarketingOrderCalculatorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFederalMilkMarketingOrderCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFederalMilkMarketingOrderCalculator(updateFederalMilkMarketingOrderCalculatorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {UpdatePositionsCalculatorRequest} [updatePositionsCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePositionsCalculator(region: string, commodity: string, updatePositionsCalculatorRequest?: UpdatePositionsCalculatorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPositionsCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePositionsCalculator(region, commodity, updatePositionsCalculatorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {UpdateValueMatrixCalculatorRequest} [updateValueMatrixCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateValueMatrixCalculator(region: string, commodity: string, updateValueMatrixCalculatorRequest?: UpdateValueMatrixCalculatorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetValueMatrixCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateValueMatrixCalculator(region, commodity, updateValueMatrixCalculatorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {UpdateCalculationEngineInputsRequest} [updateCalculationEngineInputsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertCalculationEngineInputs(demeterCalculationEngineGuid: string, updateCalculationEngineInputsRequest?: UpdateCalculationEngineInputsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCalculationEngineInputsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertCalculationEngineInputs(demeterCalculationEngineGuid, updateCalculationEngineInputsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterCalculatorsApi - factory interface
 * @export
 */
export const DemeterCalculatorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterCalculatorsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {AddCalculationEngineInputsRequest} [addCalculationEngineInputsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCalculationEngineInputs(demeterCalculationEngineGuid: string, addCalculationEngineInputsRequest?: AddCalculationEngineInputsRequest, options?: any): AxiosPromise<AddCalculationEngineInputsResponse> {
            return localVarFp.addCalculationEngineInputs(demeterCalculationEngineGuid, addCalculationEngineInputsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} demeterCalculationEngineInputsGuid 
         * @param {DeleteCalculationEngineInputsRequest} [deleteCalculationEngineInputsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalculationEngineInputs(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, deleteCalculationEngineInputsRequest?: DeleteCalculationEngineInputsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCalculationEngineInputs(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, deleteCalculationEngineInputsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DownloadValueMatrixRequest} [downloadValueMatrixRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadValueMatrixCalculator(region: string, commodity: string, downloadValueMatrixRequest?: DownloadValueMatrixRequest, options?: any): AxiosPromise<DownloadValueMatrixResponse> {
            return localVarFp.downloadValueMatrixCalculator(region, commodity, downloadValueMatrixRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} [demeterUserCalculatorGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBudgetCalculator(region: string, commodity: string, demeterUserCalculatorGuid?: string, options?: any): AxiosPromise<GetBudgetCalculatorResponse> {
            return localVarFp.getBudgetCalculator(region, commodity, demeterUserCalculatorGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserCalculatorGuid 
         * @param {DemeterRegion} [region] 
         * @param {DemeterCommodity} [commodity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBudgetCalculatorByGuid(demeterUserCalculatorGuid: string, region?: DemeterRegion, commodity?: DemeterCommodity, options?: any): AxiosPromise<GetBudgetCalculatorResponse> {
            return localVarFp.getBudgetCalculatorByGuid(demeterUserCalculatorGuid, region, commodity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculationEngine(demeterCalculationEngineGuid: string, options?: any): AxiosPromise<GetCalculationEngineResponse> {
            return localVarFp.getCalculationEngine(demeterCalculationEngineGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} demeterCalculationEngineInputsGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculationEngineInputs(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, options?: any): AxiosPromise<GetCalculationEngineInputsResponse> {
            return localVarFp.getCalculationEngineInputs(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} [demeterCalculationEngineInputsGuid] 
         * @param {boolean} [refreshCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFarmerMarginCalculator(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid?: string, refreshCache?: boolean, options?: any): AxiosPromise<GetFarmerMarginCalculatorResponse> {
            return localVarFp.getFarmerMarginCalculator(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, refreshCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFederalMilkMarketingOrderCalculator(options?: any): AxiosPromise<GetFederalMilkMarketingOrderCalculatorResponse> {
            return localVarFp.getFederalMilkMarketingOrderCalculator(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} [demeterUserCalculatorGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionsCalculator(region: string, commodity: string, demeterUserCalculatorGuid?: string, options?: any): AxiosPromise<GetPositionsCalculatorResponse> {
            return localVarFp.getPositionsCalculator(region, commodity, demeterUserCalculatorGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserCalculatorGuid 
         * @param {DemeterRegion} [region] 
         * @param {DemeterCommodity} [commodity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionsCalculatorByGuid(demeterUserCalculatorGuid: string, region?: DemeterRegion, commodity?: DemeterCommodity, options?: any): AxiosPromise<GetPositionsCalculatorResponse> {
            return localVarFp.getPositionsCalculatorByGuid(demeterUserCalculatorGuid, region, commodity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValueMatrixCalculator(region: string, commodity: string, options?: any): AxiosPromise<GetValueMatrixCalculatorResponse> {
            return localVarFp.getValueMatrixCalculator(region, commodity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBudgetCalculators(options?: any): AxiosPromise<ListBudgetCalculatorsResponse> {
            return localVarFp.listBudgetCalculators(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [demeterCalculationEngineGuid] 
         * @param {DemeterCalculatorType} [calculatorType] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalculationEngineInputs(demeterCalculationEngineGuid?: string, calculatorType?: DemeterCalculatorType, groupName?: string, options?: any): AxiosPromise<ListCalculationEngineInputsResponse> {
            return localVarFp.listCalculationEngineInputs(demeterCalculationEngineGuid, calculatorType, groupName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} calculatorType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalculationEngineInputsByGroup(calculatorType: string, options?: any): AxiosPromise<ListCalculationEngineInputsByGroupResponse> {
            return localVarFp.listCalculationEngineInputsByGroup(calculatorType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DemeterCalculatorType} [calculatorType] 
         * @param {boolean} [showAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalculationEngines(calculatorType?: DemeterCalculatorType, showAll?: boolean, options?: any): AxiosPromise<ListCalculationEnginesResponse> {
            return localVarFp.listCalculationEngines(calculatorType, showAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFarmerMarginCalculators(options?: any): AxiosPromise<ListFarmerMarginCalculatorsResponse> {
            return localVarFp.listFarmerMarginCalculators(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPositionsCalculators(options?: any): AxiosPromise<ListPositionsCalculatorsResponse> {
            return localVarFp.listPositionsCalculators(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listValueMatrixCalculators(options?: any): AxiosPromise<ListValueMatrixCalculatorsResponse> {
            return localVarFp.listValueMatrixCalculators(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} demeterCalculationEngineInputsGuid 
         * @param {RunCalculationEngineRequest} [runCalculationEngineRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCalculationEngine(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, runCalculationEngineRequest?: RunCalculationEngineRequest, options?: any): AxiosPromise<RunCalculationEngineResponse> {
            return localVarFp.runCalculationEngine(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, runCalculationEngineRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {RunTestCalculationEngineRequest} [runTestCalculationEngineRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestCalculationEngine(demeterCalculationEngineGuid: string, runTestCalculationEngineRequest?: RunTestCalculationEngineRequest, options?: any): AxiosPromise<RunCalculationEngineResponse> {
            return localVarFp.runTestCalculationEngine(demeterCalculationEngineGuid, runTestCalculationEngineRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {RunTestFarmerMarginCalculatorRequest} [runTestFarmerMarginCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestFarmerMarginCalculator(demeterCalculationEngineGuid: string, runTestFarmerMarginCalculatorRequest?: RunTestFarmerMarginCalculatorRequest, options?: any): AxiosPromise<RunFarmerMarginCalculatorResponse> {
            return localVarFp.runTestFarmerMarginCalculator(demeterCalculationEngineGuid, runTestFarmerMarginCalculatorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RunValueMatrixRequest} [runValueMatrixRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runValueMatrix(runValueMatrixRequest?: RunValueMatrixRequest, options?: any): AxiosPromise<RunValueMatrixResponse> {
            return localVarFp.runValueMatrix(runValueMatrixRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {UpdateBudgetCalculatorRequest} [updateBudgetCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBudgetCalculator(region: string, commodity: string, updateBudgetCalculatorRequest?: UpdateBudgetCalculatorRequest, options?: any): AxiosPromise<GetBudgetCalculatorResponse> {
            return localVarFp.updateBudgetCalculator(region, commodity, updateBudgetCalculatorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {string} demeterCalculationEngineInputsGuid 
         * @param {UpdateCalculationEngineInputsRequest} [updateCalculationEngineInputsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalculationEngineInputs(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, updateCalculationEngineInputsRequest?: UpdateCalculationEngineInputsRequest, options?: any): AxiosPromise<UpdateCalculationEngineInputsResponse> {
            return localVarFp.updateCalculationEngineInputs(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, updateCalculationEngineInputsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateFederalMilkMarketingOrderCalculatorRequest} [updateFederalMilkMarketingOrderCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFederalMilkMarketingOrderCalculator(updateFederalMilkMarketingOrderCalculatorRequest?: UpdateFederalMilkMarketingOrderCalculatorRequest, options?: any): AxiosPromise<GetFederalMilkMarketingOrderCalculatorResponse> {
            return localVarFp.updateFederalMilkMarketingOrderCalculator(updateFederalMilkMarketingOrderCalculatorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {UpdatePositionsCalculatorRequest} [updatePositionsCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePositionsCalculator(region: string, commodity: string, updatePositionsCalculatorRequest?: UpdatePositionsCalculatorRequest, options?: any): AxiosPromise<GetPositionsCalculatorResponse> {
            return localVarFp.updatePositionsCalculator(region, commodity, updatePositionsCalculatorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {UpdateValueMatrixCalculatorRequest} [updateValueMatrixCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValueMatrixCalculator(region: string, commodity: string, updateValueMatrixCalculatorRequest?: UpdateValueMatrixCalculatorRequest, options?: any): AxiosPromise<GetValueMatrixCalculatorResponse> {
            return localVarFp.updateValueMatrixCalculator(region, commodity, updateValueMatrixCalculatorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCalculationEngineGuid 
         * @param {UpdateCalculationEngineInputsRequest} [updateCalculationEngineInputsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertCalculationEngineInputs(demeterCalculationEngineGuid: string, updateCalculationEngineInputsRequest?: UpdateCalculationEngineInputsRequest, options?: any): AxiosPromise<UpdateCalculationEngineInputsResponse> {
            return localVarFp.upsertCalculationEngineInputs(demeterCalculationEngineGuid, updateCalculationEngineInputsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterCalculatorsApi - object-oriented interface
 * @export
 * @class DemeterCalculatorsApi
 * @extends {BaseAPI}
 */
export class DemeterCalculatorsApi extends BaseAPI {
    /**
     * 
     * @param {string} demeterCalculationEngineGuid 
     * @param {AddCalculationEngineInputsRequest} [addCalculationEngineInputsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public addCalculationEngineInputs(demeterCalculationEngineGuid: string, addCalculationEngineInputsRequest?: AddCalculationEngineInputsRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).addCalculationEngineInputs(demeterCalculationEngineGuid, addCalculationEngineInputsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCalculationEngineGuid 
     * @param {string} demeterCalculationEngineInputsGuid 
     * @param {DeleteCalculationEngineInputsRequest} [deleteCalculationEngineInputsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public deleteCalculationEngineInputs(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, deleteCalculationEngineInputsRequest?: DeleteCalculationEngineInputsRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).deleteCalculationEngineInputs(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, deleteCalculationEngineInputsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DownloadValueMatrixRequest} [downloadValueMatrixRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public downloadValueMatrixCalculator(region: string, commodity: string, downloadValueMatrixRequest?: DownloadValueMatrixRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).downloadValueMatrixCalculator(region, commodity, downloadValueMatrixRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {string} [demeterUserCalculatorGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public getBudgetCalculator(region: string, commodity: string, demeterUserCalculatorGuid?: string, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).getBudgetCalculator(region, commodity, demeterUserCalculatorGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserCalculatorGuid 
     * @param {DemeterRegion} [region] 
     * @param {DemeterCommodity} [commodity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public getBudgetCalculatorByGuid(demeterUserCalculatorGuid: string, region?: DemeterRegion, commodity?: DemeterCommodity, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).getBudgetCalculatorByGuid(demeterUserCalculatorGuid, region, commodity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCalculationEngineGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public getCalculationEngine(demeterCalculationEngineGuid: string, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).getCalculationEngine(demeterCalculationEngineGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCalculationEngineGuid 
     * @param {string} demeterCalculationEngineInputsGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public getCalculationEngineInputs(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).getCalculationEngineInputs(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCalculationEngineGuid 
     * @param {string} [demeterCalculationEngineInputsGuid] 
     * @param {boolean} [refreshCache] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public getFarmerMarginCalculator(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid?: string, refreshCache?: boolean, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).getFarmerMarginCalculator(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, refreshCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public getFederalMilkMarketingOrderCalculator(options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).getFederalMilkMarketingOrderCalculator(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {string} [demeterUserCalculatorGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public getPositionsCalculator(region: string, commodity: string, demeterUserCalculatorGuid?: string, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).getPositionsCalculator(region, commodity, demeterUserCalculatorGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserCalculatorGuid 
     * @param {DemeterRegion} [region] 
     * @param {DemeterCommodity} [commodity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public getPositionsCalculatorByGuid(demeterUserCalculatorGuid: string, region?: DemeterRegion, commodity?: DemeterCommodity, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).getPositionsCalculatorByGuid(demeterUserCalculatorGuid, region, commodity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public getValueMatrixCalculator(region: string, commodity: string, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).getValueMatrixCalculator(region, commodity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public listBudgetCalculators(options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).listBudgetCalculators(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [demeterCalculationEngineGuid] 
     * @param {DemeterCalculatorType} [calculatorType] 
     * @param {string} [groupName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public listCalculationEngineInputs(demeterCalculationEngineGuid?: string, calculatorType?: DemeterCalculatorType, groupName?: string, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).listCalculationEngineInputs(demeterCalculationEngineGuid, calculatorType, groupName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} calculatorType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public listCalculationEngineInputsByGroup(calculatorType: string, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).listCalculationEngineInputsByGroup(calculatorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DemeterCalculatorType} [calculatorType] 
     * @param {boolean} [showAll] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public listCalculationEngines(calculatorType?: DemeterCalculatorType, showAll?: boolean, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).listCalculationEngines(calculatorType, showAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public listFarmerMarginCalculators(options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).listFarmerMarginCalculators(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public listPositionsCalculators(options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).listPositionsCalculators(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public listValueMatrixCalculators(options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).listValueMatrixCalculators(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCalculationEngineGuid 
     * @param {string} demeterCalculationEngineInputsGuid 
     * @param {RunCalculationEngineRequest} [runCalculationEngineRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public runCalculationEngine(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, runCalculationEngineRequest?: RunCalculationEngineRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).runCalculationEngine(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, runCalculationEngineRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCalculationEngineGuid 
     * @param {RunTestCalculationEngineRequest} [runTestCalculationEngineRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public runTestCalculationEngine(demeterCalculationEngineGuid: string, runTestCalculationEngineRequest?: RunTestCalculationEngineRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).runTestCalculationEngine(demeterCalculationEngineGuid, runTestCalculationEngineRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCalculationEngineGuid 
     * @param {RunTestFarmerMarginCalculatorRequest} [runTestFarmerMarginCalculatorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public runTestFarmerMarginCalculator(demeterCalculationEngineGuid: string, runTestFarmerMarginCalculatorRequest?: RunTestFarmerMarginCalculatorRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).runTestFarmerMarginCalculator(demeterCalculationEngineGuid, runTestFarmerMarginCalculatorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RunValueMatrixRequest} [runValueMatrixRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public runValueMatrix(runValueMatrixRequest?: RunValueMatrixRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).runValueMatrix(runValueMatrixRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {UpdateBudgetCalculatorRequest} [updateBudgetCalculatorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public updateBudgetCalculator(region: string, commodity: string, updateBudgetCalculatorRequest?: UpdateBudgetCalculatorRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).updateBudgetCalculator(region, commodity, updateBudgetCalculatorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCalculationEngineGuid 
     * @param {string} demeterCalculationEngineInputsGuid 
     * @param {UpdateCalculationEngineInputsRequest} [updateCalculationEngineInputsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public updateCalculationEngineInputs(demeterCalculationEngineGuid: string, demeterCalculationEngineInputsGuid: string, updateCalculationEngineInputsRequest?: UpdateCalculationEngineInputsRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).updateCalculationEngineInputs(demeterCalculationEngineGuid, demeterCalculationEngineInputsGuid, updateCalculationEngineInputsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateFederalMilkMarketingOrderCalculatorRequest} [updateFederalMilkMarketingOrderCalculatorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public updateFederalMilkMarketingOrderCalculator(updateFederalMilkMarketingOrderCalculatorRequest?: UpdateFederalMilkMarketingOrderCalculatorRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).updateFederalMilkMarketingOrderCalculator(updateFederalMilkMarketingOrderCalculatorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {UpdatePositionsCalculatorRequest} [updatePositionsCalculatorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public updatePositionsCalculator(region: string, commodity: string, updatePositionsCalculatorRequest?: UpdatePositionsCalculatorRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).updatePositionsCalculator(region, commodity, updatePositionsCalculatorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {UpdateValueMatrixCalculatorRequest} [updateValueMatrixCalculatorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public updateValueMatrixCalculator(region: string, commodity: string, updateValueMatrixCalculatorRequest?: UpdateValueMatrixCalculatorRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).updateValueMatrixCalculator(region, commodity, updateValueMatrixCalculatorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCalculationEngineGuid 
     * @param {UpdateCalculationEngineInputsRequest} [updateCalculationEngineInputsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCalculatorsApi
     */
    public upsertCalculationEngineInputs(demeterCalculationEngineGuid: string, updateCalculationEngineInputsRequest?: UpdateCalculationEngineInputsRequest, options?: AxiosRequestConfig) {
        return DemeterCalculatorsApiFp(this.configuration).upsertCalculationEngineInputs(demeterCalculationEngineGuid, updateCalculationEngineInputsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterCompaniesApi - axios parameter creator
 * @export
 */
export const DemeterCompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddDemeterCompanyRequest} [addDemeterCompanyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDemeterCompany: async (addDemeterCompanyRequest?: AddDemeterCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDemeterCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {AgreeToCompanyTermsAndConditionsRequest} [agreeToCompanyTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreeToCompanyTermsAndConditions: async (demeterCompanyGuid: string, agreeToCompanyTermsAndConditionsRequest?: AgreeToCompanyTermsAndConditionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCompanyGuid' is not null or undefined
            assertParamExists('agreeToCompanyTermsAndConditions', 'demeterCompanyGuid', demeterCompanyGuid)
            const localVarPath = `/demeter/companies/{demeterCompanyGuid}/terms-and-conditions/agree`
                .replace(`{${"demeterCompanyGuid"}}`, encodeURIComponent(String(demeterCompanyGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agreeToCompanyTermsAndConditionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentDemeterCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/companies/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterCompany: async (demeterCompanyGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCompanyGuid' is not null or undefined
            assertParamExists('getDemeterCompany', 'demeterCompanyGuid', demeterCompanyGuid)
            const localVarPath = `/demeter/companies/{demeterCompanyGuid}`
                .replace(`{${"demeterCompanyGuid"}}`, encodeURIComponent(String(demeterCompanyGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {boolean} [refreshCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterCompanyPermissions: async (demeterCompanyGuid: string, refreshCache?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCompanyGuid' is not null or undefined
            assertParamExists('getDemeterCompanyPermissions', 'demeterCompanyGuid', demeterCompanyGuid)
            const localVarPath = `/demeter/companies/{demeterCompanyGuid}/permissions`
                .replace(`{${"demeterCompanyGuid"}}`, encodeURIComponent(String(demeterCompanyGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (refreshCache !== undefined) {
                localVarQueryParameter['RefreshCache'] = refreshCache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterCompanies: async (searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['SearchTerm'] = searchTerm;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} permissionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterCompaniesByPermission: async (permissionType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'permissionType' is not null or undefined
            assertParamExists('listDemeterCompaniesByPermission', 'permissionType', permissionType)
            const localVarPath = `/demeter/companies/permissions/{permissionType}`
                .replace(`{${"permissionType"}}`, encodeURIComponent(String(permissionType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {UpdateDemeterCompanyRequest} [updateDemeterCompanyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterCompany: async (demeterCompanyGuid: string, updateDemeterCompanyRequest?: UpdateDemeterCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCompanyGuid' is not null or undefined
            assertParamExists('updateDemeterCompany', 'demeterCompanyGuid', demeterCompanyGuid)
            const localVarPath = `/demeter/companies/{demeterCompanyGuid}`
                .replace(`{${"demeterCompanyGuid"}}`, encodeURIComponent(String(demeterCompanyGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {UpdateDemeterCompanyLicensesRequest} [updateDemeterCompanyLicensesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterCompanyLicenses: async (demeterCompanyGuid: string, updateDemeterCompanyLicensesRequest?: UpdateDemeterCompanyLicensesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCompanyGuid' is not null or undefined
            assertParamExists('updateDemeterCompanyLicenses', 'demeterCompanyGuid', demeterCompanyGuid)
            const localVarPath = `/demeter/companies/{demeterCompanyGuid}/licenses`
                .replace(`{${"demeterCompanyGuid"}}`, encodeURIComponent(String(demeterCompanyGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterCompanyLicensesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {UpdateDemeterCompanyPermissionRequest} [updateDemeterCompanyPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterCompanyPermissions: async (demeterCompanyGuid: string, updateDemeterCompanyPermissionRequest?: UpdateDemeterCompanyPermissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCompanyGuid' is not null or undefined
            assertParamExists('updateDemeterCompanyPermissions', 'demeterCompanyGuid', demeterCompanyGuid)
            const localVarPath = `/demeter/companies/{demeterCompanyGuid}/permissions`
                .replace(`{${"demeterCompanyGuid"}}`, encodeURIComponent(String(demeterCompanyGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterCompanyPermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {UpdateDemeterCompanyPermissionRequest} [updateDemeterCompanyPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDemeterCompanyPermissionsObsolete: async (demeterCompanyGuid: string, updateDemeterCompanyPermissionRequest?: UpdateDemeterCompanyPermissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterCompanyGuid' is not null or undefined
            assertParamExists('updateDemeterCompanyPermissionsObsolete', 'demeterCompanyGuid', demeterCompanyGuid)
            const localVarPath = `/demeter/companies/{demeterCompanyGuid}/permissions`
                .replace(`{${"demeterCompanyGuid"}}`, encodeURIComponent(String(demeterCompanyGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterCompanyPermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterCompaniesApi - functional programming interface
 * @export
 */
export const DemeterCompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterCompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddDemeterCompanyRequest} [addDemeterCompanyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDemeterCompany(addDemeterCompanyRequest?: AddDemeterCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddDemeterCompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDemeterCompany(addDemeterCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {AgreeToCompanyTermsAndConditionsRequest} [agreeToCompanyTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agreeToCompanyTermsAndConditions(demeterCompanyGuid: string, agreeToCompanyTermsAndConditionsRequest?: AgreeToCompanyTermsAndConditionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agreeToCompanyTermsAndConditions(demeterCompanyGuid, agreeToCompanyTermsAndConditionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentDemeterCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterCompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentDemeterCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemeterCompany(demeterCompanyGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterCompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemeterCompany(demeterCompanyGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {boolean} [refreshCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemeterCompanyPermissions(demeterCompanyGuid: string, refreshCache?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterPermissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemeterCompanyPermissions(demeterCompanyGuid, refreshCache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterCompanies(searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterCompaniesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterCompanies(searchTerm, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} permissionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterCompaniesByPermission(permissionType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterCompaniesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterCompaniesByPermission(permissionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {UpdateDemeterCompanyRequest} [updateDemeterCompanyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDemeterCompany(demeterCompanyGuid: string, updateDemeterCompanyRequest?: UpdateDemeterCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterCompany(demeterCompanyGuid, updateDemeterCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {UpdateDemeterCompanyLicensesRequest} [updateDemeterCompanyLicensesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDemeterCompanyLicenses(demeterCompanyGuid: string, updateDemeterCompanyLicensesRequest?: UpdateDemeterCompanyLicensesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterCompanyLicenses(demeterCompanyGuid, updateDemeterCompanyLicensesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {UpdateDemeterCompanyPermissionRequest} [updateDemeterCompanyPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDemeterCompanyPermissions(demeterCompanyGuid: string, updateDemeterCompanyPermissionRequest?: UpdateDemeterCompanyPermissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterCompanyPermissions(demeterCompanyGuid, updateDemeterCompanyPermissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {UpdateDemeterCompanyPermissionRequest} [updateDemeterCompanyPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateDemeterCompanyPermissionsObsolete(demeterCompanyGuid: string, updateDemeterCompanyPermissionRequest?: UpdateDemeterCompanyPermissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterCompanyPermissionsObsolete(demeterCompanyGuid, updateDemeterCompanyPermissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterCompaniesApi - factory interface
 * @export
 */
export const DemeterCompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterCompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {AddDemeterCompanyRequest} [addDemeterCompanyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDemeterCompany(addDemeterCompanyRequest?: AddDemeterCompanyRequest, options?: any): AxiosPromise<AddDemeterCompanyResponse> {
            return localVarFp.addDemeterCompany(addDemeterCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {AgreeToCompanyTermsAndConditionsRequest} [agreeToCompanyTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreeToCompanyTermsAndConditions(demeterCompanyGuid: string, agreeToCompanyTermsAndConditionsRequest?: AgreeToCompanyTermsAndConditionsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.agreeToCompanyTermsAndConditions(demeterCompanyGuid, agreeToCompanyTermsAndConditionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentDemeterCompany(options?: any): AxiosPromise<GetDemeterCompanyResponse> {
            return localVarFp.getCurrentDemeterCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterCompany(demeterCompanyGuid: string, options?: any): AxiosPromise<GetDemeterCompanyResponse> {
            return localVarFp.getDemeterCompany(demeterCompanyGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {boolean} [refreshCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterCompanyPermissions(demeterCompanyGuid: string, refreshCache?: boolean, options?: any): AxiosPromise<GetDemeterPermissionResponse> {
            return localVarFp.getDemeterCompanyPermissions(demeterCompanyGuid, refreshCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterCompanies(searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListDemeterCompaniesResponse> {
            return localVarFp.listDemeterCompanies(searchTerm, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} permissionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterCompaniesByPermission(permissionType: string, options?: any): AxiosPromise<ListDemeterCompaniesResponse> {
            return localVarFp.listDemeterCompaniesByPermission(permissionType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {UpdateDemeterCompanyRequest} [updateDemeterCompanyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterCompany(demeterCompanyGuid: string, updateDemeterCompanyRequest?: UpdateDemeterCompanyRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterCompany(demeterCompanyGuid, updateDemeterCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {UpdateDemeterCompanyLicensesRequest} [updateDemeterCompanyLicensesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterCompanyLicenses(demeterCompanyGuid: string, updateDemeterCompanyLicensesRequest?: UpdateDemeterCompanyLicensesRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterCompanyLicenses(demeterCompanyGuid, updateDemeterCompanyLicensesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {UpdateDemeterCompanyPermissionRequest} [updateDemeterCompanyPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterCompanyPermissions(demeterCompanyGuid: string, updateDemeterCompanyPermissionRequest?: UpdateDemeterCompanyPermissionRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterCompanyPermissions(demeterCompanyGuid, updateDemeterCompanyPermissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterCompanyGuid 
         * @param {UpdateDemeterCompanyPermissionRequest} [updateDemeterCompanyPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDemeterCompanyPermissionsObsolete(demeterCompanyGuid: string, updateDemeterCompanyPermissionRequest?: UpdateDemeterCompanyPermissionRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterCompanyPermissionsObsolete(demeterCompanyGuid, updateDemeterCompanyPermissionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterCompaniesApi - object-oriented interface
 * @export
 * @class DemeterCompaniesApi
 * @extends {BaseAPI}
 */
export class DemeterCompaniesApi extends BaseAPI {
    /**
     * 
     * @param {AddDemeterCompanyRequest} [addDemeterCompanyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCompaniesApi
     */
    public addDemeterCompany(addDemeterCompanyRequest?: AddDemeterCompanyRequest, options?: AxiosRequestConfig) {
        return DemeterCompaniesApiFp(this.configuration).addDemeterCompany(addDemeterCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCompanyGuid 
     * @param {AgreeToCompanyTermsAndConditionsRequest} [agreeToCompanyTermsAndConditionsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCompaniesApi
     */
    public agreeToCompanyTermsAndConditions(demeterCompanyGuid: string, agreeToCompanyTermsAndConditionsRequest?: AgreeToCompanyTermsAndConditionsRequest, options?: AxiosRequestConfig) {
        return DemeterCompaniesApiFp(this.configuration).agreeToCompanyTermsAndConditions(demeterCompanyGuid, agreeToCompanyTermsAndConditionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCompaniesApi
     */
    public getCurrentDemeterCompany(options?: AxiosRequestConfig) {
        return DemeterCompaniesApiFp(this.configuration).getCurrentDemeterCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCompanyGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCompaniesApi
     */
    public getDemeterCompany(demeterCompanyGuid: string, options?: AxiosRequestConfig) {
        return DemeterCompaniesApiFp(this.configuration).getDemeterCompany(demeterCompanyGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCompanyGuid 
     * @param {boolean} [refreshCache] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCompaniesApi
     */
    public getDemeterCompanyPermissions(demeterCompanyGuid: string, refreshCache?: boolean, options?: AxiosRequestConfig) {
        return DemeterCompaniesApiFp(this.configuration).getDemeterCompanyPermissions(demeterCompanyGuid, refreshCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchTerm] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCompaniesApi
     */
    public listDemeterCompanies(searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DemeterCompaniesApiFp(this.configuration).listDemeterCompanies(searchTerm, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} permissionType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCompaniesApi
     */
    public listDemeterCompaniesByPermission(permissionType: string, options?: AxiosRequestConfig) {
        return DemeterCompaniesApiFp(this.configuration).listDemeterCompaniesByPermission(permissionType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCompanyGuid 
     * @param {UpdateDemeterCompanyRequest} [updateDemeterCompanyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCompaniesApi
     */
    public updateDemeterCompany(demeterCompanyGuid: string, updateDemeterCompanyRequest?: UpdateDemeterCompanyRequest, options?: AxiosRequestConfig) {
        return DemeterCompaniesApiFp(this.configuration).updateDemeterCompany(demeterCompanyGuid, updateDemeterCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCompanyGuid 
     * @param {UpdateDemeterCompanyLicensesRequest} [updateDemeterCompanyLicensesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCompaniesApi
     */
    public updateDemeterCompanyLicenses(demeterCompanyGuid: string, updateDemeterCompanyLicensesRequest?: UpdateDemeterCompanyLicensesRequest, options?: AxiosRequestConfig) {
        return DemeterCompaniesApiFp(this.configuration).updateDemeterCompanyLicenses(demeterCompanyGuid, updateDemeterCompanyLicensesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCompanyGuid 
     * @param {UpdateDemeterCompanyPermissionRequest} [updateDemeterCompanyPermissionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterCompaniesApi
     */
    public updateDemeterCompanyPermissions(demeterCompanyGuid: string, updateDemeterCompanyPermissionRequest?: UpdateDemeterCompanyPermissionRequest, options?: AxiosRequestConfig) {
        return DemeterCompaniesApiFp(this.configuration).updateDemeterCompanyPermissions(demeterCompanyGuid, updateDemeterCompanyPermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterCompanyGuid 
     * @param {UpdateDemeterCompanyPermissionRequest} [updateDemeterCompanyPermissionRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DemeterCompaniesApi
     */
    public updateDemeterCompanyPermissionsObsolete(demeterCompanyGuid: string, updateDemeterCompanyPermissionRequest?: UpdateDemeterCompanyPermissionRequest, options?: AxiosRequestConfig) {
        return DemeterCompaniesApiFp(this.configuration).updateDemeterCompanyPermissionsObsolete(demeterCompanyGuid, updateDemeterCompanyPermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterDomesticsApi - axios parameter creator
 * @export
 */
export const DemeterDomesticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityDomesticsSeasonal: async (region: string, commodity: string, dataFrequency: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityDomesticsSeasonal', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityDomesticsSeasonal', 'commodity', commodity)
            // verify required parameter 'dataFrequency' is not null or undefined
            assertParamExists('getCommodityDomesticsSeasonal', 'dataFrequency', dataFrequency)
            const localVarPath = `/demeter/domestics/{region}/{commodity}/{dataFrequency}/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)))
                .replace(`{${"dataFrequency"}}`, encodeURIComponent(String(dataFrequency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyDomesticsSeasonal: async (region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityMonthlyDomesticsSeasonal', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityMonthlyDomesticsSeasonal', 'commodity', commodity)
            const localVarPath = `/demeter/domestics/{region}/{commodity}/monthly/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCommodityMonthlyDomesticsSeasonalAggregatedRequest} [getCommodityMonthlyDomesticsSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyDomesticsSeasonalAggregated: async (getCommodityMonthlyDomesticsSeasonalAggregatedRequest?: GetCommodityMonthlyDomesticsSeasonalAggregatedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/domestics/aggregated/seasonal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCommodityMonthlyDomesticsSeasonalAggregatedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCommodityMonthlyDomesticsSeasonalObsolete: async (region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityMonthlyDomesticsSeasonalObsolete', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityMonthlyDomesticsSeasonalObsolete', 'commodity', commodity)
            const localVarPath = `/demeter/domestics/{region}/{commodity}/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityDailyDomestics: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityDailyDomestics', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityDailyDomestics', 'commodity', commodity)
            const localVarPath = `/demeter/domestics/{region}/{commodity}/daily`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyDomestics: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityMonthlyDomestics', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityMonthlyDomestics', 'commodity', commodity)
            const localVarPath = `/demeter/domestics/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListCommodityMonthlyDomesticsAggregatedRequest} [listCommodityMonthlyDomesticsAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyDomesticsAggregated: async (listCommodityMonthlyDomesticsAggregatedRequest?: ListCommodityMonthlyDomesticsAggregatedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/domestics/aggregated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listCommodityMonthlyDomesticsAggregatedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyDomestics: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityWeeklyDomestics', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityWeeklyDomestics', 'commodity', commodity)
            const localVarPath = `/demeter/domestics/{region}/{commodity}/weekly`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterDomesticsApi - functional programming interface
 * @export
 */
export const DemeterDomesticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterDomesticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityDomesticsSeasonal(region: string, commodity: string, dataFrequency: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityWeeklyDomesticsSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityDomesticsSeasonal(region, commodity, dataFrequency, dataSource, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityMonthlyDomesticsSeasonal(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyDomesticsSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyDomesticsSeasonal(region, commodity, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetCommodityMonthlyDomesticsSeasonalAggregatedRequest} [getCommodityMonthlyDomesticsSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityMonthlyDomesticsSeasonalAggregated(getCommodityMonthlyDomesticsSeasonalAggregatedRequest?: GetCommodityMonthlyDomesticsSeasonalAggregatedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyDomesticsSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyDomesticsSeasonalAggregated(getCommodityMonthlyDomesticsSeasonalAggregatedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCommodityMonthlyDomesticsSeasonalObsolete(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyDomesticsSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyDomesticsSeasonalObsolete(region, commodity, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityDailyDomestics(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityDailyDomesticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityDailyDomestics(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyDomestics(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyDomesticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyDomestics(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ListCommodityMonthlyDomesticsAggregatedRequest} [listCommodityMonthlyDomesticsAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyDomesticsAggregated(listCommodityMonthlyDomesticsAggregatedRequest?: ListCommodityMonthlyDomesticsAggregatedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyDomesticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyDomesticsAggregated(listCommodityMonthlyDomesticsAggregatedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityWeeklyDomestics(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityWeeklyDomesticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityWeeklyDomestics(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterDomesticsApi - factory interface
 * @export
 */
export const DemeterDomesticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterDomesticsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityDomesticsSeasonal(region: string, commodity: string, dataFrequency: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityWeeklyDomesticsSeasonalResponse> {
            return localVarFp.getCommodityDomesticsSeasonal(region, commodity, dataFrequency, dataSource, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyDomesticsSeasonal(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityMonthlyDomesticsSeasonalResponse> {
            return localVarFp.getCommodityMonthlyDomesticsSeasonal(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCommodityMonthlyDomesticsSeasonalAggregatedRequest} [getCommodityMonthlyDomesticsSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyDomesticsSeasonalAggregated(getCommodityMonthlyDomesticsSeasonalAggregatedRequest?: GetCommodityMonthlyDomesticsSeasonalAggregatedRequest, options?: any): AxiosPromise<GetCommodityMonthlyDomesticsSeasonalResponse> {
            return localVarFp.getCommodityMonthlyDomesticsSeasonalAggregated(getCommodityMonthlyDomesticsSeasonalAggregatedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCommodityMonthlyDomesticsSeasonalObsolete(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityMonthlyDomesticsSeasonalResponse> {
            return localVarFp.getCommodityMonthlyDomesticsSeasonalObsolete(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityDailyDomestics(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityDailyDomesticsResponse> {
            return localVarFp.listCommodityDailyDomestics(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyDomestics(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityMonthlyDomesticsResponse> {
            return localVarFp.listCommodityMonthlyDomestics(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListCommodityMonthlyDomesticsAggregatedRequest} [listCommodityMonthlyDomesticsAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyDomesticsAggregated(listCommodityMonthlyDomesticsAggregatedRequest?: ListCommodityMonthlyDomesticsAggregatedRequest, options?: any): AxiosPromise<ListCommodityMonthlyDomesticsResponse> {
            return localVarFp.listCommodityMonthlyDomesticsAggregated(listCommodityMonthlyDomesticsAggregatedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyDomestics(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityWeeklyDomesticsResponse> {
            return localVarFp.listCommodityWeeklyDomestics(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterDomesticsApi - object-oriented interface
 * @export
 * @class DemeterDomesticsApi
 * @extends {BaseAPI}
 */
export class DemeterDomesticsApi extends BaseAPI {
    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {string} dataFrequency 
     * @param {DemeterDataSource} [dataSource] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterDomesticsApi
     */
    public getCommodityDomesticsSeasonal(region: string, commodity: string, dataFrequency: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterDomesticsApiFp(this.configuration).getCommodityDomesticsSeasonal(region, commodity, dataFrequency, dataSource, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterDomesticsApi
     */
    public getCommodityMonthlyDomesticsSeasonal(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterDomesticsApiFp(this.configuration).getCommodityMonthlyDomesticsSeasonal(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCommodityMonthlyDomesticsSeasonalAggregatedRequest} [getCommodityMonthlyDomesticsSeasonalAggregatedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterDomesticsApi
     */
    public getCommodityMonthlyDomesticsSeasonalAggregated(getCommodityMonthlyDomesticsSeasonalAggregatedRequest?: GetCommodityMonthlyDomesticsSeasonalAggregatedRequest, options?: AxiosRequestConfig) {
        return DemeterDomesticsApiFp(this.configuration).getCommodityMonthlyDomesticsSeasonalAggregated(getCommodityMonthlyDomesticsSeasonalAggregatedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DemeterDomesticsApi
     */
    public getCommodityMonthlyDomesticsSeasonalObsolete(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterDomesticsApiFp(this.configuration).getCommodityMonthlyDomesticsSeasonalObsolete(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterDomesticsApi
     */
    public listCommodityDailyDomestics(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterDomesticsApiFp(this.configuration).listCommodityDailyDomestics(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterDomesticsApi
     */
    public listCommodityMonthlyDomestics(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterDomesticsApiFp(this.configuration).listCommodityMonthlyDomestics(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListCommodityMonthlyDomesticsAggregatedRequest} [listCommodityMonthlyDomesticsAggregatedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterDomesticsApi
     */
    public listCommodityMonthlyDomesticsAggregated(listCommodityMonthlyDomesticsAggregatedRequest?: ListCommodityMonthlyDomesticsAggregatedRequest, options?: AxiosRequestConfig) {
        return DemeterDomesticsApiFp(this.configuration).listCommodityMonthlyDomesticsAggregated(listCommodityMonthlyDomesticsAggregatedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterDomesticsApi
     */
    public listCommodityWeeklyDomestics(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterDomesticsApiFp(this.configuration).listCommodityWeeklyDomestics(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterDownloadsApi - axios parameter creator
 * @export
 */
export const DemeterDownloadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DownloadDataRequest} [downloadDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadData: async (downloadDataRequest?: DownloadDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/downloads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterDownloadsApi - functional programming interface
 * @export
 */
export const DemeterDownloadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterDownloadsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DownloadDataRequest} [downloadDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadData(downloadDataRequest?: DownloadDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadData(downloadDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterDownloadsApi - factory interface
 * @export
 */
export const DemeterDownloadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterDownloadsApiFp(configuration)
    return {
        /**
         * 
         * @param {DownloadDataRequest} [downloadDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadData(downloadDataRequest?: DownloadDataRequest, options?: any): AxiosPromise<DownloadDataResponse> {
            return localVarFp.downloadData(downloadDataRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterDownloadsApi - object-oriented interface
 * @export
 * @class DemeterDownloadsApi
 * @extends {BaseAPI}
 */
export class DemeterDownloadsApi extends BaseAPI {
    /**
     * 
     * @param {DownloadDataRequest} [downloadDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterDownloadsApi
     */
    public downloadData(downloadDataRequest?: DownloadDataRequest, options?: AxiosRequestConfig) {
        return DemeterDownloadsApiFp(this.configuration).downloadData(downloadDataRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterExportsApi - axios parameter creator
 * @export
 */
export const DemeterExportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityExportsSeasonal: async (region: string, commodity: string, dataFrequency: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityExportsSeasonal', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityExportsSeasonal', 'commodity', commodity)
            // verify required parameter 'dataFrequency' is not null or undefined
            assertParamExists('getCommodityExportsSeasonal', 'dataFrequency', dataFrequency)
            const localVarPath = `/demeter/exports/{region}/{commodity}/{dataFrequency}/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)))
                .replace(`{${"dataFrequency"}}`, encodeURIComponent(String(dataFrequency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyExportsSeasonal: async (region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityMonthlyExportsSeasonal', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityMonthlyExportsSeasonal', 'commodity', commodity)
            const localVarPath = `/demeter/exports/{region}/{commodity}/monthly/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCommodityMonthlyExportsSeasonalAggregatedRequest} [getCommodityMonthlyExportsSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyExportsSeasonalAggregated: async (getCommodityMonthlyExportsSeasonalAggregatedRequest?: GetCommodityMonthlyExportsSeasonalAggregatedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/exports/aggregated/seasonal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCommodityMonthlyExportsSeasonalAggregatedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCommodityMonthlyExportsSeasonalObsolete: async (region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityMonthlyExportsSeasonalObsolete', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityMonthlyExportsSeasonalObsolete', 'commodity', commodity)
            const localVarPath = `/demeter/exports/{region}/{commodity}/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityDailyExports: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityDailyExports', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityDailyExports', 'commodity', commodity)
            const localVarPath = `/demeter/exports/{region}/{commodity}/daily`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyExportDestinations: async (region: string, commodity: string, subRegion?: DemeterSubRegion, month?: number, year?: number, unitOfMeasure?: UnitOfMeasure, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityMonthlyExportDestinations', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityMonthlyExportDestinations', 'commodity', commodity)
            const localVarPath = `/demeter/exports/{region}/{commodity}/destinations`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyExports: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityMonthlyExports', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityMonthlyExports', 'commodity', commodity)
            const localVarPath = `/demeter/exports/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListCommodityMonthlyExportsAggregatedRequest} [listCommodityMonthlyExportsAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyExportsAggregated: async (listCommodityMonthlyExportsAggregatedRequest?: ListCommodityMonthlyExportsAggregatedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/exports/aggregated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listCommodityMonthlyExportsAggregatedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DemeterRegion} region 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyExportsSummary: async (region: DemeterRegion, asOfDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityMonthlyExportsSummary', 'region', region)
            const localVarPath = `/demeter/exports/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['Region'] = region;
            }

            if (asOfDate !== undefined) {
                localVarQueryParameter['AsOfDate'] = (asOfDate as any instanceof Date) ?
                    (asOfDate as any).toISOString() :
                    asOfDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyExports: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityWeeklyExports', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityWeeklyExports', 'commodity', commodity)
            const localVarPath = `/demeter/exports/{region}/{commodity}/weekly`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterExportsApi - functional programming interface
 * @export
 */
export const DemeterExportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterExportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityExportsSeasonal(region: string, commodity: string, dataFrequency: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityWeeklyExportsSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityExportsSeasonal(region, commodity, dataFrequency, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityMonthlyExportsSeasonal(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyExportsSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyExportsSeasonal(region, commodity, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetCommodityMonthlyExportsSeasonalAggregatedRequest} [getCommodityMonthlyExportsSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityMonthlyExportsSeasonalAggregated(getCommodityMonthlyExportsSeasonalAggregatedRequest?: GetCommodityMonthlyExportsSeasonalAggregatedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyExportsSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyExportsSeasonalAggregated(getCommodityMonthlyExportsSeasonalAggregatedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCommodityMonthlyExportsSeasonalObsolete(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyExportsSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyExportsSeasonalObsolete(region, commodity, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityDailyExports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityDailyExportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityDailyExports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyExportDestinations(region: string, commodity: string, subRegion?: DemeterSubRegion, month?: number, year?: number, unitOfMeasure?: UnitOfMeasure, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyExportDestinationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyExportDestinations(region, commodity, subRegion, month, year, unitOfMeasure, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyExports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyExportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyExports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ListCommodityMonthlyExportsAggregatedRequest} [listCommodityMonthlyExportsAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyExportsAggregated(listCommodityMonthlyExportsAggregatedRequest?: ListCommodityMonthlyExportsAggregatedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyExportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyExportsAggregated(listCommodityMonthlyExportsAggregatedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DemeterRegion} region 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyExportsSummary(region: DemeterRegion, asOfDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyExportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyExportsSummary(region, asOfDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityWeeklyExports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityWeeklyExportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityWeeklyExports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterExportsApi - factory interface
 * @export
 */
export const DemeterExportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterExportsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityExportsSeasonal(region: string, commodity: string, dataFrequency: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityWeeklyExportsSeasonalResponse> {
            return localVarFp.getCommodityExportsSeasonal(region, commodity, dataFrequency, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyExportsSeasonal(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityMonthlyExportsSeasonalResponse> {
            return localVarFp.getCommodityMonthlyExportsSeasonal(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCommodityMonthlyExportsSeasonalAggregatedRequest} [getCommodityMonthlyExportsSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyExportsSeasonalAggregated(getCommodityMonthlyExportsSeasonalAggregatedRequest?: GetCommodityMonthlyExportsSeasonalAggregatedRequest, options?: any): AxiosPromise<GetCommodityMonthlyExportsSeasonalResponse> {
            return localVarFp.getCommodityMonthlyExportsSeasonalAggregated(getCommodityMonthlyExportsSeasonalAggregatedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCommodityMonthlyExportsSeasonalObsolete(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityMonthlyExportsSeasonalResponse> {
            return localVarFp.getCommodityMonthlyExportsSeasonalObsolete(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityDailyExports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityDailyExportsResponse> {
            return localVarFp.listCommodityDailyExports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyExportDestinations(region: string, commodity: string, subRegion?: DemeterSubRegion, month?: number, year?: number, unitOfMeasure?: UnitOfMeasure, take?: number, options?: any): AxiosPromise<ListCommodityMonthlyExportDestinationsResponse> {
            return localVarFp.listCommodityMonthlyExportDestinations(region, commodity, subRegion, month, year, unitOfMeasure, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyExports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityMonthlyExportsResponse> {
            return localVarFp.listCommodityMonthlyExports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListCommodityMonthlyExportsAggregatedRequest} [listCommodityMonthlyExportsAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyExportsAggregated(listCommodityMonthlyExportsAggregatedRequest?: ListCommodityMonthlyExportsAggregatedRequest, options?: any): AxiosPromise<ListCommodityMonthlyExportsResponse> {
            return localVarFp.listCommodityMonthlyExportsAggregated(listCommodityMonthlyExportsAggregatedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DemeterRegion} region 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyExportsSummary(region: DemeterRegion, asOfDate?: string, options?: any): AxiosPromise<ListCommodityMonthlyExportsResponse> {
            return localVarFp.listCommodityMonthlyExportsSummary(region, asOfDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyExports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityWeeklyExportsResponse> {
            return localVarFp.listCommodityWeeklyExports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterExportsApi - object-oriented interface
 * @export
 * @class DemeterExportsApi
 * @extends {BaseAPI}
 */
export class DemeterExportsApi extends BaseAPI {
    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {string} dataFrequency 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterExportsApi
     */
    public getCommodityExportsSeasonal(region: string, commodity: string, dataFrequency: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterExportsApiFp(this.configuration).getCommodityExportsSeasonal(region, commodity, dataFrequency, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterExportsApi
     */
    public getCommodityMonthlyExportsSeasonal(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterExportsApiFp(this.configuration).getCommodityMonthlyExportsSeasonal(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCommodityMonthlyExportsSeasonalAggregatedRequest} [getCommodityMonthlyExportsSeasonalAggregatedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterExportsApi
     */
    public getCommodityMonthlyExportsSeasonalAggregated(getCommodityMonthlyExportsSeasonalAggregatedRequest?: GetCommodityMonthlyExportsSeasonalAggregatedRequest, options?: AxiosRequestConfig) {
        return DemeterExportsApiFp(this.configuration).getCommodityMonthlyExportsSeasonalAggregated(getCommodityMonthlyExportsSeasonalAggregatedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DemeterExportsApi
     */
    public getCommodityMonthlyExportsSeasonalObsolete(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterExportsApiFp(this.configuration).getCommodityMonthlyExportsSeasonalObsolete(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterExportsApi
     */
    public listCommodityDailyExports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterExportsApiFp(this.configuration).listCommodityDailyExports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterExportsApi
     */
    public listCommodityMonthlyExportDestinations(region: string, commodity: string, subRegion?: DemeterSubRegion, month?: number, year?: number, unitOfMeasure?: UnitOfMeasure, take?: number, options?: AxiosRequestConfig) {
        return DemeterExportsApiFp(this.configuration).listCommodityMonthlyExportDestinations(region, commodity, subRegion, month, year, unitOfMeasure, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterExportsApi
     */
    public listCommodityMonthlyExports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterExportsApiFp(this.configuration).listCommodityMonthlyExports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListCommodityMonthlyExportsAggregatedRequest} [listCommodityMonthlyExportsAggregatedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterExportsApi
     */
    public listCommodityMonthlyExportsAggregated(listCommodityMonthlyExportsAggregatedRequest?: ListCommodityMonthlyExportsAggregatedRequest, options?: AxiosRequestConfig) {
        return DemeterExportsApiFp(this.configuration).listCommodityMonthlyExportsAggregated(listCommodityMonthlyExportsAggregatedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DemeterRegion} region 
     * @param {string} [asOfDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterExportsApi
     */
    public listCommodityMonthlyExportsSummary(region: DemeterRegion, asOfDate?: string, options?: AxiosRequestConfig) {
        return DemeterExportsApiFp(this.configuration).listCommodityMonthlyExportsSummary(region, asOfDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterExportsApi
     */
    public listCommodityWeeklyExports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterExportsApiFp(this.configuration).listCommodityWeeklyExports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterFundamentalsApi - axios parameter creator
 * @export
 */
export const DemeterFundamentalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} fundamental 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubFundamental} [subFundamental] 
         * @param {DemeterDataFrequency} [dataFrequency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterFundamentalData: async (fundamental: string, region: string, commodity: string, subFundamental?: DemeterSubFundamental, dataFrequency?: DemeterDataFrequency, subRegion?: DemeterSubRegion, dataSource?: DemeterDataSource, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundamental' is not null or undefined
            assertParamExists('listDemeterFundamentalData', 'fundamental', fundamental)
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listDemeterFundamentalData', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listDemeterFundamentalData', 'commodity', commodity)
            const localVarPath = `/demeter/fundamentals/{fundamental}/{region}/{commodity}`
                .replace(`{${"fundamental"}}`, encodeURIComponent(String(fundamental)))
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subFundamental !== undefined) {
                localVarQueryParameter['SubFundamental'] = subFundamental;
            }

            if (dataFrequency !== undefined) {
                localVarQueryParameter['DataFrequency'] = dataFrequency;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterFundamentalsApi - functional programming interface
 * @export
 */
export const DemeterFundamentalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterFundamentalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} fundamental 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubFundamental} [subFundamental] 
         * @param {DemeterDataFrequency} [dataFrequency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterFundamentalData(fundamental: string, region: string, commodity: string, subFundamental?: DemeterSubFundamental, dataFrequency?: DemeterDataFrequency, subRegion?: DemeterSubRegion, dataSource?: DemeterDataSource, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterFundamentalDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterFundamentalData(fundamental, region, commodity, subFundamental, dataFrequency, subRegion, dataSource, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterFundamentalsApi - factory interface
 * @export
 */
export const DemeterFundamentalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterFundamentalsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} fundamental 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubFundamental} [subFundamental] 
         * @param {DemeterDataFrequency} [dataFrequency] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterFundamentalData(fundamental: string, region: string, commodity: string, subFundamental?: DemeterSubFundamental, dataFrequency?: DemeterDataFrequency, subRegion?: DemeterSubRegion, dataSource?: DemeterDataSource, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListDemeterFundamentalDataResponse> {
            return localVarFp.listDemeterFundamentalData(fundamental, region, commodity, subFundamental, dataFrequency, subRegion, dataSource, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterFundamentalsApi - object-oriented interface
 * @export
 * @class DemeterFundamentalsApi
 * @extends {BaseAPI}
 */
export class DemeterFundamentalsApi extends BaseAPI {
    /**
     * 
     * @param {string} fundamental 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubFundamental} [subFundamental] 
     * @param {DemeterDataFrequency} [dataFrequency] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {DemeterDataSource} [dataSource] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterFundamentalsApi
     */
    public listDemeterFundamentalData(fundamental: string, region: string, commodity: string, subFundamental?: DemeterSubFundamental, dataFrequency?: DemeterDataFrequency, subRegion?: DemeterSubRegion, dataSource?: DemeterDataSource, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterFundamentalsApiFp(this.configuration).listDemeterFundamentalData(fundamental, region, commodity, subFundamental, dataFrequency, subRegion, dataSource, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterImportsApi - axios parameter creator
 * @export
 */
export const DemeterImportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityImportsSeasonal: async (region: string, commodity: string, dataFrequency: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityImportsSeasonal', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityImportsSeasonal', 'commodity', commodity)
            // verify required parameter 'dataFrequency' is not null or undefined
            assertParamExists('getCommodityImportsSeasonal', 'dataFrequency', dataFrequency)
            const localVarPath = `/demeter/imports/{region}/{commodity}/{dataFrequency}/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)))
                .replace(`{${"dataFrequency"}}`, encodeURIComponent(String(dataFrequency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyImportsSeasonal: async (region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityMonthlyImportsSeasonal', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityMonthlyImportsSeasonal', 'commodity', commodity)
            const localVarPath = `/demeter/imports/{region}/{commodity}/monthly/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCommodityMonthlyImportsSeasonalAggregatedRequest} [getCommodityMonthlyImportsSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyImportsSeasonalAggregated: async (getCommodityMonthlyImportsSeasonalAggregatedRequest?: GetCommodityMonthlyImportsSeasonalAggregatedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/imports/aggregated/seasonal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCommodityMonthlyImportsSeasonalAggregatedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCommodityMonthlyImportsSeasonalObsolete: async (region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityMonthlyImportsSeasonalObsolete', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityMonthlyImportsSeasonalObsolete', 'commodity', commodity)
            const localVarPath = `/demeter/imports/{region}/{commodity}/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityDailyImports: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityDailyImports', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityDailyImports', 'commodity', commodity)
            const localVarPath = `/demeter/imports/{region}/{commodity}/daily`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyImportOrigins: async (region: string, commodity: string, subRegion?: DemeterSubRegion, month?: number, year?: number, unitOfMeasure?: UnitOfMeasure, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityMonthlyImportOrigins', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityMonthlyImportOrigins', 'commodity', commodity)
            const localVarPath = `/demeter/imports/{region}/{commodity}/origins`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyImports: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityMonthlyImports', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityMonthlyImports', 'commodity', commodity)
            const localVarPath = `/demeter/imports/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListCommodityMonthlyImportsAggregatedRequest} [listCommodityMonthlyImportsAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyImportsAggregated: async (listCommodityMonthlyImportsAggregatedRequest?: ListCommodityMonthlyImportsAggregatedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/imports/aggregated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listCommodityMonthlyImportsAggregatedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyImports: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityWeeklyImports', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityWeeklyImports', 'commodity', commodity)
            const localVarPath = `/demeter/imports/{region}/{commodity}/weekly`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterImportsApi - functional programming interface
 * @export
 */
export const DemeterImportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterImportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityImportsSeasonal(region: string, commodity: string, dataFrequency: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityWeeklyImportsSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityImportsSeasonal(region, commodity, dataFrequency, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityMonthlyImportsSeasonal(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyImportsSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyImportsSeasonal(region, commodity, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetCommodityMonthlyImportsSeasonalAggregatedRequest} [getCommodityMonthlyImportsSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityMonthlyImportsSeasonalAggregated(getCommodityMonthlyImportsSeasonalAggregatedRequest?: GetCommodityMonthlyImportsSeasonalAggregatedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyImportsSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyImportsSeasonalAggregated(getCommodityMonthlyImportsSeasonalAggregatedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCommodityMonthlyImportsSeasonalObsolete(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyImportsSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyImportsSeasonalObsolete(region, commodity, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityDailyImports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityDailyImportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityDailyImports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyImportOrigins(region: string, commodity: string, subRegion?: DemeterSubRegion, month?: number, year?: number, unitOfMeasure?: UnitOfMeasure, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyImportOriginsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyImportOrigins(region, commodity, subRegion, month, year, unitOfMeasure, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyImports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyImportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyImports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ListCommodityMonthlyImportsAggregatedRequest} [listCommodityMonthlyImportsAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyImportsAggregated(listCommodityMonthlyImportsAggregatedRequest?: ListCommodityMonthlyImportsAggregatedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyImportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyImportsAggregated(listCommodityMonthlyImportsAggregatedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityWeeklyImports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityWeeklyImportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityWeeklyImports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterImportsApi - factory interface
 * @export
 */
export const DemeterImportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterImportsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityImportsSeasonal(region: string, commodity: string, dataFrequency: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityWeeklyImportsSeasonalResponse> {
            return localVarFp.getCommodityImportsSeasonal(region, commodity, dataFrequency, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyImportsSeasonal(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityMonthlyImportsSeasonalResponse> {
            return localVarFp.getCommodityMonthlyImportsSeasonal(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCommodityMonthlyImportsSeasonalAggregatedRequest} [getCommodityMonthlyImportsSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyImportsSeasonalAggregated(getCommodityMonthlyImportsSeasonalAggregatedRequest?: GetCommodityMonthlyImportsSeasonalAggregatedRequest, options?: any): AxiosPromise<GetCommodityMonthlyImportsSeasonalResponse> {
            return localVarFp.getCommodityMonthlyImportsSeasonalAggregated(getCommodityMonthlyImportsSeasonalAggregatedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCommodityMonthlyImportsSeasonalObsolete(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityMonthlyImportsSeasonalResponse> {
            return localVarFp.getCommodityMonthlyImportsSeasonalObsolete(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityDailyImports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityDailyImportsResponse> {
            return localVarFp.listCommodityDailyImports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyImportOrigins(region: string, commodity: string, subRegion?: DemeterSubRegion, month?: number, year?: number, unitOfMeasure?: UnitOfMeasure, take?: number, options?: any): AxiosPromise<ListCommodityMonthlyImportOriginsResponse> {
            return localVarFp.listCommodityMonthlyImportOrigins(region, commodity, subRegion, month, year, unitOfMeasure, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyImports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityMonthlyImportsResponse> {
            return localVarFp.listCommodityMonthlyImports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListCommodityMonthlyImportsAggregatedRequest} [listCommodityMonthlyImportsAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyImportsAggregated(listCommodityMonthlyImportsAggregatedRequest?: ListCommodityMonthlyImportsAggregatedRequest, options?: any): AxiosPromise<ListCommodityMonthlyImportsResponse> {
            return localVarFp.listCommodityMonthlyImportsAggregated(listCommodityMonthlyImportsAggregatedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyImports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityWeeklyImportsResponse> {
            return localVarFp.listCommodityWeeklyImports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterImportsApi - object-oriented interface
 * @export
 * @class DemeterImportsApi
 * @extends {BaseAPI}
 */
export class DemeterImportsApi extends BaseAPI {
    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {string} dataFrequency 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterImportsApi
     */
    public getCommodityImportsSeasonal(region: string, commodity: string, dataFrequency: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterImportsApiFp(this.configuration).getCommodityImportsSeasonal(region, commodity, dataFrequency, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterImportsApi
     */
    public getCommodityMonthlyImportsSeasonal(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterImportsApiFp(this.configuration).getCommodityMonthlyImportsSeasonal(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCommodityMonthlyImportsSeasonalAggregatedRequest} [getCommodityMonthlyImportsSeasonalAggregatedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterImportsApi
     */
    public getCommodityMonthlyImportsSeasonalAggregated(getCommodityMonthlyImportsSeasonalAggregatedRequest?: GetCommodityMonthlyImportsSeasonalAggregatedRequest, options?: AxiosRequestConfig) {
        return DemeterImportsApiFp(this.configuration).getCommodityMonthlyImportsSeasonalAggregated(getCommodityMonthlyImportsSeasonalAggregatedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DemeterImportsApi
     */
    public getCommodityMonthlyImportsSeasonalObsolete(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterImportsApiFp(this.configuration).getCommodityMonthlyImportsSeasonalObsolete(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterImportsApi
     */
    public listCommodityDailyImports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterImportsApiFp(this.configuration).listCommodityDailyImports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterImportsApi
     */
    public listCommodityMonthlyImportOrigins(region: string, commodity: string, subRegion?: DemeterSubRegion, month?: number, year?: number, unitOfMeasure?: UnitOfMeasure, take?: number, options?: AxiosRequestConfig) {
        return DemeterImportsApiFp(this.configuration).listCommodityMonthlyImportOrigins(region, commodity, subRegion, month, year, unitOfMeasure, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterImportsApi
     */
    public listCommodityMonthlyImports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterImportsApiFp(this.configuration).listCommodityMonthlyImports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListCommodityMonthlyImportsAggregatedRequest} [listCommodityMonthlyImportsAggregatedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterImportsApi
     */
    public listCommodityMonthlyImportsAggregated(listCommodityMonthlyImportsAggregatedRequest?: ListCommodityMonthlyImportsAggregatedRequest, options?: AxiosRequestConfig) {
        return DemeterImportsApiFp(this.configuration).listCommodityMonthlyImportsAggregated(listCommodityMonthlyImportsAggregatedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterImportsApi
     */
    public listCommodityWeeklyImports(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterImportsApiFp(this.configuration).listCommodityWeeklyImports(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterMarketIndicatorsApi - axios parameter creator
 * @export
 */
export const DemeterMarketIndicatorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddMarketIndicatorRequest} [addMarketIndicatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketIndicator: async (addMarketIndicatorRequest?: AddMarketIndicatorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/market-indicators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMarketIndicatorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {AddMarketIndicatorFactorRequest} [addMarketIndicatorFactorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketIndicatorFactor: async (marketIndicatorGuid: string, addMarketIndicatorFactorRequest?: AddMarketIndicatorFactorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('addMarketIndicatorFactor', 'marketIndicatorGuid', marketIndicatorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/factors`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMarketIndicatorFactorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {AddMarketIndicatorReviewRequest} [addMarketIndicatorReviewRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketIndicatorReview: async (marketIndicatorGuid: string, addMarketIndicatorReviewRequest?: AddMarketIndicatorReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('addMarketIndicatorReview', 'marketIndicatorGuid', marketIndicatorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/reviews`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMarketIndicatorReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {CloneMarketIndicatorRequest} [cloneMarketIndicatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneMarketIndicator: async (marketIndicatorGuid: string, cloneMarketIndicatorRequest?: CloneMarketIndicatorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('cloneMarketIndicator', 'marketIndicatorGuid', marketIndicatorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/clone`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cloneMarketIndicatorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {DeleteMarketIndicatorFactorRequest} [deleteMarketIndicatorFactorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarketIndicatorFactor: async (marketIndicatorGuid: string, marketIndicatorFactorGuid: string, deleteMarketIndicatorFactorRequest?: DeleteMarketIndicatorFactorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('deleteMarketIndicatorFactor', 'marketIndicatorGuid', marketIndicatorGuid)
            // verify required parameter 'marketIndicatorFactorGuid' is not null or undefined
            assertParamExists('deleteMarketIndicatorFactor', 'marketIndicatorFactorGuid', marketIndicatorFactorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/factors/{marketIndicatorFactorGuid}`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)))
                .replace(`{${"marketIndicatorFactorGuid"}}`, encodeURIComponent(String(marketIndicatorFactorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteMarketIndicatorFactorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {boolean} [showWithData] 
         * @param {boolean} [showLatestData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketIndicator: async (marketIndicatorGuid: string, showWithData?: boolean, showLatestData?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('getMarketIndicator', 'marketIndicatorGuid', marketIndicatorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (showWithData !== undefined) {
                localVarQueryParameter['ShowWithData'] = showWithData;
            }

            if (showLatestData !== undefined) {
                localVarQueryParameter['ShowLatestData'] = showLatestData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {DemeterDataSource} [dataSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketIndicatorByRegionAndCommodity: async (region: string, commodity: string, subRegion?: DemeterSubRegion, dataSource?: DemeterDataSource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getMarketIndicatorByRegionAndCommodity', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getMarketIndicatorByRegionAndCommodity', 'commodity', commodity)
            const localVarPath = `/demeter/market-indicators/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketIndicatorFactor: async (marketIndicatorGuid: string, marketIndicatorFactorGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('getMarketIndicatorFactor', 'marketIndicatorGuid', marketIndicatorGuid)
            // verify required parameter 'marketIndicatorFactorGuid' is not null or undefined
            assertParamExists('getMarketIndicatorFactor', 'marketIndicatorFactorGuid', marketIndicatorFactorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/factors/{marketIndicatorFactorGuid}`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)))
                .replace(`{${"marketIndicatorFactorGuid"}}`, encodeURIComponent(String(marketIndicatorFactorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketIndicatorFactorData: async (marketIndicatorGuid: string, marketIndicatorFactorGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('getMarketIndicatorFactorData', 'marketIndicatorGuid', marketIndicatorGuid)
            // verify required parameter 'marketIndicatorFactorGuid' is not null or undefined
            assertParamExists('getMarketIndicatorFactorData', 'marketIndicatorFactorGuid', marketIndicatorFactorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/factors/{marketIndicatorFactorGuid}/data`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)))
                .replace(`{${"marketIndicatorFactorGuid"}}`, encodeURIComponent(String(marketIndicatorFactorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketIndicatorReviewCurrent: async (marketIndicatorGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('getMarketIndicatorReviewCurrent', 'marketIndicatorGuid', marketIndicatorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/reviews/current`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetTestMarketIndicatorFactorDataRequest} [getTestMarketIndicatorFactorDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestMarketIndicatorFactorData: async (getTestMarketIndicatorFactorDataRequest?: GetTestMarketIndicatorFactorDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/market-indicators/factors/data/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTestMarketIndicatorFactorDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketIndicatorReviews: async (marketIndicatorGuid: string, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('listMarketIndicatorReviews', 'marketIndicatorGuid', marketIndicatorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/reviews`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketIndicatorSummaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/market-indicators/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [showWithData] 
         * @param {boolean} [showAll] 
         * @param {boolean} [showReviewStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketIndicators: async (showWithData?: boolean, showAll?: boolean, showReviewStatus?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/market-indicators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (showWithData !== undefined) {
                localVarQueryParameter['ShowWithData'] = showWithData;
            }

            if (showAll !== undefined) {
                localVarQueryParameter['ShowAll'] = showAll;
            }

            if (showReviewStatus !== undefined) {
                localVarQueryParameter['ShowReviewStatus'] = showReviewStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [showWithData] 
         * @param {boolean} [showAll] 
         * @param {boolean} [showReviewStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketIndicatorsCurrent: async (showWithData?: boolean, showAll?: boolean, showReviewStatus?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/market-indicators/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (showWithData !== undefined) {
                localVarQueryParameter['ShowWithData'] = showWithData;
            }

            if (showAll !== undefined) {
                localVarQueryParameter['ShowAll'] = showAll;
            }

            if (showReviewStatus !== undefined) {
                localVarQueryParameter['ShowReviewStatus'] = showReviewStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runMarketIndicator: async (marketIndicatorGuid: string, asOfDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('runMarketIndicator', 'marketIndicatorGuid', marketIndicatorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/run`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (asOfDate !== undefined) {
                localVarQueryParameter['AsOfDate'] = (asOfDate as any instanceof Date) ?
                    (asOfDate as any).toISOString() :
                    asOfDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {number} numberOfYears 
         * @param {string} asOfDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runMarketIndicatorFactor: async (marketIndicatorGuid: string, marketIndicatorFactorGuid: string, numberOfYears: number, asOfDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('runMarketIndicatorFactor', 'marketIndicatorGuid', marketIndicatorGuid)
            // verify required parameter 'marketIndicatorFactorGuid' is not null or undefined
            assertParamExists('runMarketIndicatorFactor', 'marketIndicatorFactorGuid', marketIndicatorFactorGuid)
            // verify required parameter 'numberOfYears' is not null or undefined
            assertParamExists('runMarketIndicatorFactor', 'numberOfYears', numberOfYears)
            // verify required parameter 'asOfDate' is not null or undefined
            assertParamExists('runMarketIndicatorFactor', 'asOfDate', asOfDate)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/factors/{marketIndicatorFactorGuid}/run`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)))
                .replace(`{${"marketIndicatorFactorGuid"}}`, encodeURIComponent(String(marketIndicatorFactorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (numberOfYears !== undefined) {
                localVarQueryParameter['NumberOfYears'] = numberOfYears;
            }

            if (asOfDate !== undefined) {
                localVarQueryParameter['AsOfDate'] = (asOfDate as any instanceof Date) ?
                    (asOfDate as any).toISOString() :
                    asOfDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {RunMarketIndicatorWithFactorReplacementRequest} [runMarketIndicatorWithFactorReplacementRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runMarketIndicatorWithFactorReplacement: async (marketIndicatorGuid: string, marketIndicatorFactorGuid: string, runMarketIndicatorWithFactorReplacementRequest?: RunMarketIndicatorWithFactorReplacementRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('runMarketIndicatorWithFactorReplacement', 'marketIndicatorGuid', marketIndicatorGuid)
            // verify required parameter 'marketIndicatorFactorGuid' is not null or undefined
            assertParamExists('runMarketIndicatorWithFactorReplacement', 'marketIndicatorFactorGuid', marketIndicatorFactorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/factors/{marketIndicatorFactorGuid}/run/replacement`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)))
                .replace(`{${"marketIndicatorFactorGuid"}}`, encodeURIComponent(String(marketIndicatorFactorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(runMarketIndicatorWithFactorReplacementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RunTestMarketIndicatorFactorRequest} [runTestMarketIndicatorFactorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestMarketIndicatorFactor: async (runTestMarketIndicatorFactorRequest?: RunTestMarketIndicatorFactorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/market-indicators/factors/run/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(runTestMarketIndicatorFactorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {UpdateMarketIndicatorRequest} [updateMarketIndicatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketIndicator: async (marketIndicatorGuid: string, updateMarketIndicatorRequest?: UpdateMarketIndicatorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('updateMarketIndicator', 'marketIndicatorGuid', marketIndicatorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMarketIndicatorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {UpdateMarketIndicatorFactorRequest} [updateMarketIndicatorFactorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketIndicatorFactor: async (marketIndicatorGuid: string, marketIndicatorFactorGuid: string, updateMarketIndicatorFactorRequest?: UpdateMarketIndicatorFactorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('updateMarketIndicatorFactor', 'marketIndicatorGuid', marketIndicatorGuid)
            // verify required parameter 'marketIndicatorFactorGuid' is not null or undefined
            assertParamExists('updateMarketIndicatorFactor', 'marketIndicatorFactorGuid', marketIndicatorFactorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/factors/{marketIndicatorFactorGuid}`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)))
                .replace(`{${"marketIndicatorFactorGuid"}}`, encodeURIComponent(String(marketIndicatorFactorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMarketIndicatorFactorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} displayGroupName 
         * @param {UpdateMarketIndicatorFactorGroupRequest} [updateMarketIndicatorFactorGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketIndicatorFactorGroup: async (marketIndicatorGuid: string, displayGroupName: string, updateMarketIndicatorFactorGroupRequest?: UpdateMarketIndicatorFactorGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('updateMarketIndicatorFactorGroup', 'marketIndicatorGuid', marketIndicatorGuid)
            // verify required parameter 'displayGroupName' is not null or undefined
            assertParamExists('updateMarketIndicatorFactorGroup', 'displayGroupName', displayGroupName)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/factors/group/{displayGroupName}`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)))
                .replace(`{${"displayGroupName"}}`, encodeURIComponent(String(displayGroupName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMarketIndicatorFactorGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {UpdateMarketIndicatorFactorOrderRequest} [updateMarketIndicatorFactorOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketIndicatorFactorOrder: async (marketIndicatorGuid: string, updateMarketIndicatorFactorOrderRequest?: UpdateMarketIndicatorFactorOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketIndicatorGuid' is not null or undefined
            assertParamExists('updateMarketIndicatorFactorOrder', 'marketIndicatorGuid', marketIndicatorGuid)
            const localVarPath = `/demeter/market-indicators/{marketIndicatorGuid}/factors/order`
                .replace(`{${"marketIndicatorGuid"}}`, encodeURIComponent(String(marketIndicatorGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMarketIndicatorFactorOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterMarketIndicatorsApi - functional programming interface
 * @export
 */
export const DemeterMarketIndicatorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterMarketIndicatorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddMarketIndicatorRequest} [addMarketIndicatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMarketIndicator(addMarketIndicatorRequest?: AddMarketIndicatorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddMarketIndicatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMarketIndicator(addMarketIndicatorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {AddMarketIndicatorFactorRequest} [addMarketIndicatorFactorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMarketIndicatorFactor(marketIndicatorGuid: string, addMarketIndicatorFactorRequest?: AddMarketIndicatorFactorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddMarketIndicatorFactorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMarketIndicatorFactor(marketIndicatorGuid, addMarketIndicatorFactorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {AddMarketIndicatorReviewRequest} [addMarketIndicatorReviewRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMarketIndicatorReview(marketIndicatorGuid: string, addMarketIndicatorReviewRequest?: AddMarketIndicatorReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMarketIndicatorReview(marketIndicatorGuid, addMarketIndicatorReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {CloneMarketIndicatorRequest} [cloneMarketIndicatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneMarketIndicator(marketIndicatorGuid: string, cloneMarketIndicatorRequest?: CloneMarketIndicatorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddMarketIndicatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneMarketIndicator(marketIndicatorGuid, cloneMarketIndicatorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {DeleteMarketIndicatorFactorRequest} [deleteMarketIndicatorFactorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMarketIndicatorFactor(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, deleteMarketIndicatorFactorRequest?: DeleteMarketIndicatorFactorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMarketIndicatorFactor(marketIndicatorGuid, marketIndicatorFactorGuid, deleteMarketIndicatorFactorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {boolean} [showWithData] 
         * @param {boolean} [showLatestData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketIndicator(marketIndicatorGuid: string, showWithData?: boolean, showLatestData?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketIndicatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketIndicator(marketIndicatorGuid, showWithData, showLatestData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {DemeterDataSource} [dataSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketIndicatorByRegionAndCommodity(region: string, commodity: string, subRegion?: DemeterSubRegion, dataSource?: DemeterDataSource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketIndicatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketIndicatorByRegionAndCommodity(region, commodity, subRegion, dataSource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketIndicatorFactor(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketIndicatorFactorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketIndicatorFactor(marketIndicatorGuid, marketIndicatorFactorGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketIndicatorFactorData(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketIndicatorFactorDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketIndicatorFactorData(marketIndicatorGuid, marketIndicatorFactorGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketIndicatorReviewCurrent(marketIndicatorGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketIndicatorReviewCurrentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketIndicatorReviewCurrent(marketIndicatorGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetTestMarketIndicatorFactorDataRequest} [getTestMarketIndicatorFactorDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestMarketIndicatorFactorData(getTestMarketIndicatorFactorDataRequest?: GetTestMarketIndicatorFactorDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketIndicatorFactorDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestMarketIndicatorFactorData(getTestMarketIndicatorFactorDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketIndicatorReviews(marketIndicatorGuid: string, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketIndicatorReviewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketIndicatorReviews(marketIndicatorGuid, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketIndicatorSummaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketIndicatorSummariesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketIndicatorSummaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [showWithData] 
         * @param {boolean} [showAll] 
         * @param {boolean} [showReviewStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketIndicators(showWithData?: boolean, showAll?: boolean, showReviewStatus?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketIndicatorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketIndicators(showWithData, showAll, showReviewStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [showWithData] 
         * @param {boolean} [showAll] 
         * @param {boolean} [showReviewStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketIndicatorsCurrent(showWithData?: boolean, showAll?: boolean, showReviewStatus?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketIndicatorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketIndicatorsCurrent(showWithData, showAll, showReviewStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runMarketIndicator(marketIndicatorGuid: string, asOfDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunMarketIndicatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runMarketIndicator(marketIndicatorGuid, asOfDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {number} numberOfYears 
         * @param {string} asOfDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runMarketIndicatorFactor(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, numberOfYears: number, asOfDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunMarketIndicatorFactorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runMarketIndicatorFactor(marketIndicatorGuid, marketIndicatorFactorGuid, numberOfYears, asOfDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {RunMarketIndicatorWithFactorReplacementRequest} [runMarketIndicatorWithFactorReplacementRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runMarketIndicatorWithFactorReplacement(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, runMarketIndicatorWithFactorReplacementRequest?: RunMarketIndicatorWithFactorReplacementRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunMarketIndicatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runMarketIndicatorWithFactorReplacement(marketIndicatorGuid, marketIndicatorFactorGuid, runMarketIndicatorWithFactorReplacementRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RunTestMarketIndicatorFactorRequest} [runTestMarketIndicatorFactorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runTestMarketIndicatorFactor(runTestMarketIndicatorFactorRequest?: RunTestMarketIndicatorFactorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunMarketIndicatorFactorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runTestMarketIndicatorFactor(runTestMarketIndicatorFactorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {UpdateMarketIndicatorRequest} [updateMarketIndicatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMarketIndicator(marketIndicatorGuid: string, updateMarketIndicatorRequest?: UpdateMarketIndicatorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMarketIndicator(marketIndicatorGuid, updateMarketIndicatorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {UpdateMarketIndicatorFactorRequest} [updateMarketIndicatorFactorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMarketIndicatorFactor(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, updateMarketIndicatorFactorRequest?: UpdateMarketIndicatorFactorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMarketIndicatorFactor(marketIndicatorGuid, marketIndicatorFactorGuid, updateMarketIndicatorFactorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} displayGroupName 
         * @param {UpdateMarketIndicatorFactorGroupRequest} [updateMarketIndicatorFactorGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMarketIndicatorFactorGroup(marketIndicatorGuid: string, displayGroupName: string, updateMarketIndicatorFactorGroupRequest?: UpdateMarketIndicatorFactorGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMarketIndicatorFactorGroup(marketIndicatorGuid, displayGroupName, updateMarketIndicatorFactorGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {UpdateMarketIndicatorFactorOrderRequest} [updateMarketIndicatorFactorOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMarketIndicatorFactorOrder(marketIndicatorGuid: string, updateMarketIndicatorFactorOrderRequest?: UpdateMarketIndicatorFactorOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMarketIndicatorFactorOrder(marketIndicatorGuid, updateMarketIndicatorFactorOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterMarketIndicatorsApi - factory interface
 * @export
 */
export const DemeterMarketIndicatorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterMarketIndicatorsApiFp(configuration)
    return {
        /**
         * 
         * @param {AddMarketIndicatorRequest} [addMarketIndicatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketIndicator(addMarketIndicatorRequest?: AddMarketIndicatorRequest, options?: any): AxiosPromise<AddMarketIndicatorResponse> {
            return localVarFp.addMarketIndicator(addMarketIndicatorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {AddMarketIndicatorFactorRequest} [addMarketIndicatorFactorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketIndicatorFactor(marketIndicatorGuid: string, addMarketIndicatorFactorRequest?: AddMarketIndicatorFactorRequest, options?: any): AxiosPromise<AddMarketIndicatorFactorResponse> {
            return localVarFp.addMarketIndicatorFactor(marketIndicatorGuid, addMarketIndicatorFactorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {AddMarketIndicatorReviewRequest} [addMarketIndicatorReviewRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketIndicatorReview(marketIndicatorGuid: string, addMarketIndicatorReviewRequest?: AddMarketIndicatorReviewRequest, options?: any): AxiosPromise<object> {
            return localVarFp.addMarketIndicatorReview(marketIndicatorGuid, addMarketIndicatorReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {CloneMarketIndicatorRequest} [cloneMarketIndicatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneMarketIndicator(marketIndicatorGuid: string, cloneMarketIndicatorRequest?: CloneMarketIndicatorRequest, options?: any): AxiosPromise<AddMarketIndicatorResponse> {
            return localVarFp.cloneMarketIndicator(marketIndicatorGuid, cloneMarketIndicatorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {DeleteMarketIndicatorFactorRequest} [deleteMarketIndicatorFactorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarketIndicatorFactor(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, deleteMarketIndicatorFactorRequest?: DeleteMarketIndicatorFactorRequest, options?: any): AxiosPromise<object> {
            return localVarFp.deleteMarketIndicatorFactor(marketIndicatorGuid, marketIndicatorFactorGuid, deleteMarketIndicatorFactorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {boolean} [showWithData] 
         * @param {boolean} [showLatestData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketIndicator(marketIndicatorGuid: string, showWithData?: boolean, showLatestData?: boolean, options?: any): AxiosPromise<GetMarketIndicatorResponse> {
            return localVarFp.getMarketIndicator(marketIndicatorGuid, showWithData, showLatestData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {DemeterDataSource} [dataSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketIndicatorByRegionAndCommodity(region: string, commodity: string, subRegion?: DemeterSubRegion, dataSource?: DemeterDataSource, options?: any): AxiosPromise<GetMarketIndicatorResponse> {
            return localVarFp.getMarketIndicatorByRegionAndCommodity(region, commodity, subRegion, dataSource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketIndicatorFactor(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, options?: any): AxiosPromise<GetMarketIndicatorFactorResponse> {
            return localVarFp.getMarketIndicatorFactor(marketIndicatorGuid, marketIndicatorFactorGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketIndicatorFactorData(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, options?: any): AxiosPromise<GetMarketIndicatorFactorDataResponse> {
            return localVarFp.getMarketIndicatorFactorData(marketIndicatorGuid, marketIndicatorFactorGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketIndicatorReviewCurrent(marketIndicatorGuid: string, options?: any): AxiosPromise<GetMarketIndicatorReviewCurrentResponse> {
            return localVarFp.getMarketIndicatorReviewCurrent(marketIndicatorGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetTestMarketIndicatorFactorDataRequest} [getTestMarketIndicatorFactorDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestMarketIndicatorFactorData(getTestMarketIndicatorFactorDataRequest?: GetTestMarketIndicatorFactorDataRequest, options?: any): AxiosPromise<GetMarketIndicatorFactorDataResponse> {
            return localVarFp.getTestMarketIndicatorFactorData(getTestMarketIndicatorFactorDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketIndicatorReviews(marketIndicatorGuid: string, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListMarketIndicatorReviewsResponse> {
            return localVarFp.listMarketIndicatorReviews(marketIndicatorGuid, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketIndicatorSummaries(options?: any): AxiosPromise<ListMarketIndicatorSummariesResponse> {
            return localVarFp.listMarketIndicatorSummaries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [showWithData] 
         * @param {boolean} [showAll] 
         * @param {boolean} [showReviewStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketIndicators(showWithData?: boolean, showAll?: boolean, showReviewStatus?: boolean, options?: any): AxiosPromise<ListMarketIndicatorsResponse> {
            return localVarFp.listMarketIndicators(showWithData, showAll, showReviewStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [showWithData] 
         * @param {boolean} [showAll] 
         * @param {boolean} [showReviewStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketIndicatorsCurrent(showWithData?: boolean, showAll?: boolean, showReviewStatus?: boolean, options?: any): AxiosPromise<ListMarketIndicatorsResponse> {
            return localVarFp.listMarketIndicatorsCurrent(showWithData, showAll, showReviewStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runMarketIndicator(marketIndicatorGuid: string, asOfDate?: string, options?: any): AxiosPromise<RunMarketIndicatorResponse> {
            return localVarFp.runMarketIndicator(marketIndicatorGuid, asOfDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {number} numberOfYears 
         * @param {string} asOfDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runMarketIndicatorFactor(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, numberOfYears: number, asOfDate: string, options?: any): AxiosPromise<RunMarketIndicatorFactorResponse> {
            return localVarFp.runMarketIndicatorFactor(marketIndicatorGuid, marketIndicatorFactorGuid, numberOfYears, asOfDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {RunMarketIndicatorWithFactorReplacementRequest} [runMarketIndicatorWithFactorReplacementRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runMarketIndicatorWithFactorReplacement(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, runMarketIndicatorWithFactorReplacementRequest?: RunMarketIndicatorWithFactorReplacementRequest, options?: any): AxiosPromise<RunMarketIndicatorResponse> {
            return localVarFp.runMarketIndicatorWithFactorReplacement(marketIndicatorGuid, marketIndicatorFactorGuid, runMarketIndicatorWithFactorReplacementRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RunTestMarketIndicatorFactorRequest} [runTestMarketIndicatorFactorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestMarketIndicatorFactor(runTestMarketIndicatorFactorRequest?: RunTestMarketIndicatorFactorRequest, options?: any): AxiosPromise<RunMarketIndicatorFactorResponse> {
            return localVarFp.runTestMarketIndicatorFactor(runTestMarketIndicatorFactorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {UpdateMarketIndicatorRequest} [updateMarketIndicatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketIndicator(marketIndicatorGuid: string, updateMarketIndicatorRequest?: UpdateMarketIndicatorRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateMarketIndicator(marketIndicatorGuid, updateMarketIndicatorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} marketIndicatorFactorGuid 
         * @param {UpdateMarketIndicatorFactorRequest} [updateMarketIndicatorFactorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketIndicatorFactor(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, updateMarketIndicatorFactorRequest?: UpdateMarketIndicatorFactorRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateMarketIndicatorFactor(marketIndicatorGuid, marketIndicatorFactorGuid, updateMarketIndicatorFactorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {string} displayGroupName 
         * @param {UpdateMarketIndicatorFactorGroupRequest} [updateMarketIndicatorFactorGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketIndicatorFactorGroup(marketIndicatorGuid: string, displayGroupName: string, updateMarketIndicatorFactorGroupRequest?: UpdateMarketIndicatorFactorGroupRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateMarketIndicatorFactorGroup(marketIndicatorGuid, displayGroupName, updateMarketIndicatorFactorGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketIndicatorGuid 
         * @param {UpdateMarketIndicatorFactorOrderRequest} [updateMarketIndicatorFactorOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketIndicatorFactorOrder(marketIndicatorGuid: string, updateMarketIndicatorFactorOrderRequest?: UpdateMarketIndicatorFactorOrderRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateMarketIndicatorFactorOrder(marketIndicatorGuid, updateMarketIndicatorFactorOrderRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterMarketIndicatorsApi - object-oriented interface
 * @export
 * @class DemeterMarketIndicatorsApi
 * @extends {BaseAPI}
 */
export class DemeterMarketIndicatorsApi extends BaseAPI {
    /**
     * 
     * @param {AddMarketIndicatorRequest} [addMarketIndicatorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public addMarketIndicator(addMarketIndicatorRequest?: AddMarketIndicatorRequest, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).addMarketIndicator(addMarketIndicatorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {AddMarketIndicatorFactorRequest} [addMarketIndicatorFactorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public addMarketIndicatorFactor(marketIndicatorGuid: string, addMarketIndicatorFactorRequest?: AddMarketIndicatorFactorRequest, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).addMarketIndicatorFactor(marketIndicatorGuid, addMarketIndicatorFactorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {AddMarketIndicatorReviewRequest} [addMarketIndicatorReviewRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public addMarketIndicatorReview(marketIndicatorGuid: string, addMarketIndicatorReviewRequest?: AddMarketIndicatorReviewRequest, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).addMarketIndicatorReview(marketIndicatorGuid, addMarketIndicatorReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {CloneMarketIndicatorRequest} [cloneMarketIndicatorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public cloneMarketIndicator(marketIndicatorGuid: string, cloneMarketIndicatorRequest?: CloneMarketIndicatorRequest, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).cloneMarketIndicator(marketIndicatorGuid, cloneMarketIndicatorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {string} marketIndicatorFactorGuid 
     * @param {DeleteMarketIndicatorFactorRequest} [deleteMarketIndicatorFactorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public deleteMarketIndicatorFactor(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, deleteMarketIndicatorFactorRequest?: DeleteMarketIndicatorFactorRequest, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).deleteMarketIndicatorFactor(marketIndicatorGuid, marketIndicatorFactorGuid, deleteMarketIndicatorFactorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {boolean} [showWithData] 
     * @param {boolean} [showLatestData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public getMarketIndicator(marketIndicatorGuid: string, showWithData?: boolean, showLatestData?: boolean, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).getMarketIndicator(marketIndicatorGuid, showWithData, showLatestData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {DemeterDataSource} [dataSource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public getMarketIndicatorByRegionAndCommodity(region: string, commodity: string, subRegion?: DemeterSubRegion, dataSource?: DemeterDataSource, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).getMarketIndicatorByRegionAndCommodity(region, commodity, subRegion, dataSource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {string} marketIndicatorFactorGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public getMarketIndicatorFactor(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).getMarketIndicatorFactor(marketIndicatorGuid, marketIndicatorFactorGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {string} marketIndicatorFactorGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public getMarketIndicatorFactorData(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).getMarketIndicatorFactorData(marketIndicatorGuid, marketIndicatorFactorGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public getMarketIndicatorReviewCurrent(marketIndicatorGuid: string, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).getMarketIndicatorReviewCurrent(marketIndicatorGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetTestMarketIndicatorFactorDataRequest} [getTestMarketIndicatorFactorDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public getTestMarketIndicatorFactorData(getTestMarketIndicatorFactorDataRequest?: GetTestMarketIndicatorFactorDataRequest, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).getTestMarketIndicatorFactorData(getTestMarketIndicatorFactorDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public listMarketIndicatorReviews(marketIndicatorGuid: string, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).listMarketIndicatorReviews(marketIndicatorGuid, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public listMarketIndicatorSummaries(options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).listMarketIndicatorSummaries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [showWithData] 
     * @param {boolean} [showAll] 
     * @param {boolean} [showReviewStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public listMarketIndicators(showWithData?: boolean, showAll?: boolean, showReviewStatus?: boolean, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).listMarketIndicators(showWithData, showAll, showReviewStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [showWithData] 
     * @param {boolean} [showAll] 
     * @param {boolean} [showReviewStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public listMarketIndicatorsCurrent(showWithData?: boolean, showAll?: boolean, showReviewStatus?: boolean, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).listMarketIndicatorsCurrent(showWithData, showAll, showReviewStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {string} [asOfDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public runMarketIndicator(marketIndicatorGuid: string, asOfDate?: string, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).runMarketIndicator(marketIndicatorGuid, asOfDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {string} marketIndicatorFactorGuid 
     * @param {number} numberOfYears 
     * @param {string} asOfDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public runMarketIndicatorFactor(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, numberOfYears: number, asOfDate: string, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).runMarketIndicatorFactor(marketIndicatorGuid, marketIndicatorFactorGuid, numberOfYears, asOfDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {string} marketIndicatorFactorGuid 
     * @param {RunMarketIndicatorWithFactorReplacementRequest} [runMarketIndicatorWithFactorReplacementRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public runMarketIndicatorWithFactorReplacement(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, runMarketIndicatorWithFactorReplacementRequest?: RunMarketIndicatorWithFactorReplacementRequest, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).runMarketIndicatorWithFactorReplacement(marketIndicatorGuid, marketIndicatorFactorGuid, runMarketIndicatorWithFactorReplacementRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RunTestMarketIndicatorFactorRequest} [runTestMarketIndicatorFactorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public runTestMarketIndicatorFactor(runTestMarketIndicatorFactorRequest?: RunTestMarketIndicatorFactorRequest, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).runTestMarketIndicatorFactor(runTestMarketIndicatorFactorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {UpdateMarketIndicatorRequest} [updateMarketIndicatorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public updateMarketIndicator(marketIndicatorGuid: string, updateMarketIndicatorRequest?: UpdateMarketIndicatorRequest, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).updateMarketIndicator(marketIndicatorGuid, updateMarketIndicatorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {string} marketIndicatorFactorGuid 
     * @param {UpdateMarketIndicatorFactorRequest} [updateMarketIndicatorFactorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public updateMarketIndicatorFactor(marketIndicatorGuid: string, marketIndicatorFactorGuid: string, updateMarketIndicatorFactorRequest?: UpdateMarketIndicatorFactorRequest, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).updateMarketIndicatorFactor(marketIndicatorGuid, marketIndicatorFactorGuid, updateMarketIndicatorFactorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {string} displayGroupName 
     * @param {UpdateMarketIndicatorFactorGroupRequest} [updateMarketIndicatorFactorGroupRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public updateMarketIndicatorFactorGroup(marketIndicatorGuid: string, displayGroupName: string, updateMarketIndicatorFactorGroupRequest?: UpdateMarketIndicatorFactorGroupRequest, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).updateMarketIndicatorFactorGroup(marketIndicatorGuid, displayGroupName, updateMarketIndicatorFactorGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketIndicatorGuid 
     * @param {UpdateMarketIndicatorFactorOrderRequest} [updateMarketIndicatorFactorOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterMarketIndicatorsApi
     */
    public updateMarketIndicatorFactorOrder(marketIndicatorGuid: string, updateMarketIndicatorFactorOrderRequest?: UpdateMarketIndicatorFactorOrderRequest, options?: AxiosRequestConfig) {
        return DemeterMarketIndicatorsApiFp(this.configuration).updateMarketIndicatorFactorOrder(marketIndicatorGuid, updateMarketIndicatorFactorOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterOtherApi - axios parameter creator
 * @export
 */
export const DemeterOtherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyOther: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityMonthlyOther', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityMonthlyOther', 'commodity', commodity)
            const localVarPath = `/demeter/other/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterOtherApi - functional programming interface
 * @export
 */
export const DemeterOtherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterOtherApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyOther(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyOtherResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyOther(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterOtherApi - factory interface
 * @export
 */
export const DemeterOtherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterOtherApiFp(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyOther(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityMonthlyOtherResponse> {
            return localVarFp.listCommodityMonthlyOther(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterOtherApi - object-oriented interface
 * @export
 * @class DemeterOtherApi
 * @extends {BaseAPI}
 */
export class DemeterOtherApi extends BaseAPI {
    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterOtherApi
     */
    public listCommodityMonthlyOther(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterOtherApiFp(this.configuration).listCommodityMonthlyOther(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterPageElementsApi - axios parameter creator
 * @export
 */
export const DemeterPageElementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} demeterPageElementGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterPageElementImage: async (demeterPageElementGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterPageElementGuid' is not null or undefined
            assertParamExists('getDemeterPageElementImage', 'demeterPageElementGuid', demeterPageElementGuid)
            const localVarPath = `/demeter/page-elements/{demeterPageElementGuid}/image`
                .replace(`{${"demeterPageElementGuid"}}`, encodeURIComponent(String(demeterPageElementGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DemeterPageType} [pageType] 
         * @param {DemeterMarket} [market] 
         * @param {DemeterRegion} [region] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {DemeterCommodity} [commodity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterPageElements: async (pageType?: DemeterPageType, market?: DemeterMarket, region?: DemeterRegion, subRegion?: DemeterSubRegion, commodity?: DemeterCommodity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/page-elements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageType !== undefined) {
                localVarQueryParameter['PageType'] = pageType;
            }

            if (market !== undefined) {
                localVarQueryParameter['Market'] = market;
            }

            if (region !== undefined) {
                localVarQueryParameter['Region'] = region;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (commodity !== undefined) {
                localVarQueryParameter['Commodity'] = commodity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterPageElementsApi - functional programming interface
 * @export
 */
export const DemeterPageElementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterPageElementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} demeterPageElementGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemeterPageElementImage(demeterPageElementGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemeterPageElementImage(demeterPageElementGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DemeterPageType} [pageType] 
         * @param {DemeterMarket} [market] 
         * @param {DemeterRegion} [region] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {DemeterCommodity} [commodity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterPageElements(pageType?: DemeterPageType, market?: DemeterMarket, region?: DemeterRegion, subRegion?: DemeterSubRegion, commodity?: DemeterCommodity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterPageElementsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterPageElements(pageType, market, region, subRegion, commodity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterPageElementsApi - factory interface
 * @export
 */
export const DemeterPageElementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterPageElementsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} demeterPageElementGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterPageElementImage(demeterPageElementGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.getDemeterPageElementImage(demeterPageElementGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DemeterPageType} [pageType] 
         * @param {DemeterMarket} [market] 
         * @param {DemeterRegion} [region] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {DemeterCommodity} [commodity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterPageElements(pageType?: DemeterPageType, market?: DemeterMarket, region?: DemeterRegion, subRegion?: DemeterSubRegion, commodity?: DemeterCommodity, options?: any): AxiosPromise<ListDemeterPageElementsResponse> {
            return localVarFp.listDemeterPageElements(pageType, market, region, subRegion, commodity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterPageElementsApi - object-oriented interface
 * @export
 * @class DemeterPageElementsApi
 * @extends {BaseAPI}
 */
export class DemeterPageElementsApi extends BaseAPI {
    /**
     * 
     * @param {string} demeterPageElementGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterPageElementsApi
     */
    public getDemeterPageElementImage(demeterPageElementGuid: string, options?: AxiosRequestConfig) {
        return DemeterPageElementsApiFp(this.configuration).getDemeterPageElementImage(demeterPageElementGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DemeterPageType} [pageType] 
     * @param {DemeterMarket} [market] 
     * @param {DemeterRegion} [region] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {DemeterCommodity} [commodity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterPageElementsApi
     */
    public listDemeterPageElements(pageType?: DemeterPageType, market?: DemeterMarket, region?: DemeterRegion, subRegion?: DemeterSubRegion, commodity?: DemeterCommodity, options?: AxiosRequestConfig) {
        return DemeterPageElementsApiFp(this.configuration).listDemeterPageElements(pageType, market, region, subRegion, commodity, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterPositionsApi - axios parameter creator
 * @export
 */
export const DemeterPositionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInsurancePositions: async (searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/positions/insurance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['SearchTerm'] = searchTerm;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterPositionsApi - functional programming interface
 * @export
 */
export const DemeterPositionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterPositionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInsurancePositions(searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterInsurancePositionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInsurancePositions(searchTerm, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterPositionsApi - factory interface
 * @export
 */
export const DemeterPositionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterPositionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInsurancePositions(searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListDemeterInsurancePositionsResponse> {
            return localVarFp.listInsurancePositions(searchTerm, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterPositionsApi - object-oriented interface
 * @export
 * @class DemeterPositionsApi
 * @extends {BaseAPI}
 */
export class DemeterPositionsApi extends BaseAPI {
    /**
     * 
     * @param {string} [searchTerm] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterPositionsApi
     */
    public listInsurancePositions(searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DemeterPositionsApiFp(this.configuration).listInsurancePositions(searchTerm, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterPresentationTemplatesApi - axios parameter creator
 * @export
 */
export const DemeterPresentationTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddPresentationTemplateRequest} [addPresentationTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPresentationTemplate: async (addPresentationTemplateRequest?: AddPresentationTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/presentation-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPresentationTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClonePresentationTemplateRequest} [clonePresentationTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clonePresentationTemplate: async (clonePresentationTemplateRequest?: ClonePresentationTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/presentation-templates/clone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clonePresentationTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePresentationTemplateRequest} [deletePresentationTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePresentationTemplate: async (deletePresentationTemplateRequest?: DeletePresentationTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/presentation-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePresentationTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} presentationTemplateGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPresentationTemplate: async (presentationTemplateGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'presentationTemplateGuid' is not null or undefined
            assertParamExists('downloadPresentationTemplate', 'presentationTemplateGuid', presentationTemplateGuid)
            const localVarPath = `/demeter/presentation-templates/{presentationTemplateGuid}/download`
                .replace(`{${"presentationTemplateGuid"}}`, encodeURIComponent(String(presentationTemplateGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} presentationTemplateGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresentationTemplate: async (presentationTemplateGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'presentationTemplateGuid' is not null or undefined
            assertParamExists('getPresentationTemplate', 'presentationTemplateGuid', presentationTemplateGuid)
            const localVarPath = `/demeter/presentation-templates/{presentationTemplateGuid}`
                .replace(`{${"presentationTemplateGuid"}}`, encodeURIComponent(String(presentationTemplateGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPresentationTemplates: async (searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/presentation-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['SearchTerm'] = searchTerm;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePresentationTemplateRequest} [updatePresentationTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePresentationTemplate: async (updatePresentationTemplateRequest?: UpdatePresentationTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/presentation-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePresentationTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterPresentationTemplatesApi - functional programming interface
 * @export
 */
export const DemeterPresentationTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterPresentationTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddPresentationTemplateRequest} [addPresentationTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPresentationTemplate(addPresentationTemplateRequest?: AddPresentationTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddPresentationTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPresentationTemplate(addPresentationTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ClonePresentationTemplateRequest} [clonePresentationTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clonePresentationTemplate(clonePresentationTemplateRequest?: ClonePresentationTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddPresentationTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clonePresentationTemplate(clonePresentationTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePresentationTemplateRequest} [deletePresentationTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePresentationTemplate(deletePresentationTemplateRequest?: DeletePresentationTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePresentationTemplate(deletePresentationTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} presentationTemplateGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPresentationTemplate(presentationTemplateGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadPresentationTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPresentationTemplate(presentationTemplateGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} presentationTemplateGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresentationTemplate(presentationTemplateGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPresentationTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresentationTemplate(presentationTemplateGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPresentationTemplates(searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPresentationTemplatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPresentationTemplates(searchTerm, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdatePresentationTemplateRequest} [updatePresentationTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePresentationTemplate(updatePresentationTemplateRequest?: UpdatePresentationTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePresentationTemplate(updatePresentationTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterPresentationTemplatesApi - factory interface
 * @export
 */
export const DemeterPresentationTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterPresentationTemplatesApiFp(configuration)
    return {
        /**
         * 
         * @param {AddPresentationTemplateRequest} [addPresentationTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPresentationTemplate(addPresentationTemplateRequest?: AddPresentationTemplateRequest, options?: any): AxiosPromise<AddPresentationTemplateResponse> {
            return localVarFp.addPresentationTemplate(addPresentationTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClonePresentationTemplateRequest} [clonePresentationTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clonePresentationTemplate(clonePresentationTemplateRequest?: ClonePresentationTemplateRequest, options?: any): AxiosPromise<AddPresentationTemplateResponse> {
            return localVarFp.clonePresentationTemplate(clonePresentationTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePresentationTemplateRequest} [deletePresentationTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePresentationTemplate(deletePresentationTemplateRequest?: DeletePresentationTemplateRequest, options?: any): AxiosPromise<object> {
            return localVarFp.deletePresentationTemplate(deletePresentationTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} presentationTemplateGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPresentationTemplate(presentationTemplateGuid: string, options?: any): AxiosPromise<DownloadPresentationTemplateResponse> {
            return localVarFp.downloadPresentationTemplate(presentationTemplateGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} presentationTemplateGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresentationTemplate(presentationTemplateGuid: string, options?: any): AxiosPromise<GetPresentationTemplateResponse> {
            return localVarFp.getPresentationTemplate(presentationTemplateGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPresentationTemplates(searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListPresentationTemplatesResponse> {
            return localVarFp.listPresentationTemplates(searchTerm, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePresentationTemplateRequest} [updatePresentationTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePresentationTemplate(updatePresentationTemplateRequest?: UpdatePresentationTemplateRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updatePresentationTemplate(updatePresentationTemplateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterPresentationTemplatesApi - object-oriented interface
 * @export
 * @class DemeterPresentationTemplatesApi
 * @extends {BaseAPI}
 */
export class DemeterPresentationTemplatesApi extends BaseAPI {
    /**
     * 
     * @param {AddPresentationTemplateRequest} [addPresentationTemplateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterPresentationTemplatesApi
     */
    public addPresentationTemplate(addPresentationTemplateRequest?: AddPresentationTemplateRequest, options?: AxiosRequestConfig) {
        return DemeterPresentationTemplatesApiFp(this.configuration).addPresentationTemplate(addPresentationTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClonePresentationTemplateRequest} [clonePresentationTemplateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterPresentationTemplatesApi
     */
    public clonePresentationTemplate(clonePresentationTemplateRequest?: ClonePresentationTemplateRequest, options?: AxiosRequestConfig) {
        return DemeterPresentationTemplatesApiFp(this.configuration).clonePresentationTemplate(clonePresentationTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePresentationTemplateRequest} [deletePresentationTemplateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterPresentationTemplatesApi
     */
    public deletePresentationTemplate(deletePresentationTemplateRequest?: DeletePresentationTemplateRequest, options?: AxiosRequestConfig) {
        return DemeterPresentationTemplatesApiFp(this.configuration).deletePresentationTemplate(deletePresentationTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} presentationTemplateGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterPresentationTemplatesApi
     */
    public downloadPresentationTemplate(presentationTemplateGuid: string, options?: AxiosRequestConfig) {
        return DemeterPresentationTemplatesApiFp(this.configuration).downloadPresentationTemplate(presentationTemplateGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} presentationTemplateGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterPresentationTemplatesApi
     */
    public getPresentationTemplate(presentationTemplateGuid: string, options?: AxiosRequestConfig) {
        return DemeterPresentationTemplatesApiFp(this.configuration).getPresentationTemplate(presentationTemplateGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchTerm] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterPresentationTemplatesApi
     */
    public listPresentationTemplates(searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DemeterPresentationTemplatesApiFp(this.configuration).listPresentationTemplates(searchTerm, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePresentationTemplateRequest} [updatePresentationTemplateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterPresentationTemplatesApi
     */
    public updatePresentationTemplate(updatePresentationTemplateRequest?: UpdatePresentationTemplateRequest, options?: AxiosRequestConfig) {
        return DemeterPresentationTemplatesApiFp(this.configuration).updatePresentationTemplate(updatePresentationTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterProductionApi - axios parameter creator
 * @export
 */
export const DemeterProductionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyProductionSeasonal: async (region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityMonthlyProductionSeasonal', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityMonthlyProductionSeasonal', 'commodity', commodity)
            const localVarPath = `/demeter/production/{region}/{commodity}/monthly/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCommodityMonthlyProductionSeasonalAggregatedRequest} [getCommodityMonthlyProductionSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyProductionSeasonalAggregated: async (getCommodityMonthlyProductionSeasonalAggregatedRequest?: GetCommodityMonthlyProductionSeasonalAggregatedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/production/aggregated/seasonal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCommodityMonthlyProductionSeasonalAggregatedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCommodityMonthlyProductionSeasonalObsolete: async (region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityMonthlyProductionSeasonalObsolete', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityMonthlyProductionSeasonalObsolete', 'commodity', commodity)
            const localVarPath = `/demeter/production/{region}/{commodity}/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityProductionSeasonal: async (region: string, commodity: string, dataFrequency: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityProductionSeasonal', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityProductionSeasonal', 'commodity', commodity)
            // verify required parameter 'dataFrequency' is not null or undefined
            assertParamExists('getCommodityProductionSeasonal', 'dataFrequency', dataFrequency)
            const localVarPath = `/demeter/production/{region}/{commodity}/{dataFrequency}/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)))
                .replace(`{${"dataFrequency"}}`, encodeURIComponent(String(dataFrequency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityDailyProduction: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityDailyProduction', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityDailyProduction', 'commodity', commodity)
            const localVarPath = `/demeter/production/{region}/{commodity}/daily`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyProduction: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityMonthlyProduction', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityMonthlyProduction', 'commodity', commodity)
            const localVarPath = `/demeter/production/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListCommodityMonthlyProductionAggregatedRequest} [listCommodityMonthlyProductionAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyProductionAggregated: async (listCommodityMonthlyProductionAggregatedRequest?: ListCommodityMonthlyProductionAggregatedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/production/aggregated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listCommodityMonthlyProductionAggregatedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyProduction: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityWeeklyProduction', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityWeeklyProduction', 'commodity', commodity)
            const localVarPath = `/demeter/production/{region}/{commodity}/weekly`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterProductionApi - functional programming interface
 * @export
 */
export const DemeterProductionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterProductionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityMonthlyProductionSeasonal(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyProductionSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyProductionSeasonal(region, commodity, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetCommodityMonthlyProductionSeasonalAggregatedRequest} [getCommodityMonthlyProductionSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityMonthlyProductionSeasonalAggregated(getCommodityMonthlyProductionSeasonalAggregatedRequest?: GetCommodityMonthlyProductionSeasonalAggregatedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyProductionSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyProductionSeasonalAggregated(getCommodityMonthlyProductionSeasonalAggregatedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCommodityMonthlyProductionSeasonalObsolete(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyProductionSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyProductionSeasonalObsolete(region, commodity, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityProductionSeasonal(region: string, commodity: string, dataFrequency: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityWeeklyProductionSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityProductionSeasonal(region, commodity, dataFrequency, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityDailyProduction(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityDailyProductionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityDailyProduction(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyProduction(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyProductionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyProduction(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ListCommodityMonthlyProductionAggregatedRequest} [listCommodityMonthlyProductionAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyProductionAggregated(listCommodityMonthlyProductionAggregatedRequest?: ListCommodityMonthlyProductionAggregatedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyProductionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyProductionAggregated(listCommodityMonthlyProductionAggregatedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityWeeklyProduction(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityWeeklyProductionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityWeeklyProduction(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterProductionApi - factory interface
 * @export
 */
export const DemeterProductionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterProductionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyProductionSeasonal(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityMonthlyProductionSeasonalResponse> {
            return localVarFp.getCommodityMonthlyProductionSeasonal(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCommodityMonthlyProductionSeasonalAggregatedRequest} [getCommodityMonthlyProductionSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyProductionSeasonalAggregated(getCommodityMonthlyProductionSeasonalAggregatedRequest?: GetCommodityMonthlyProductionSeasonalAggregatedRequest, options?: any): AxiosPromise<GetCommodityMonthlyProductionSeasonalResponse> {
            return localVarFp.getCommodityMonthlyProductionSeasonalAggregated(getCommodityMonthlyProductionSeasonalAggregatedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCommodityMonthlyProductionSeasonalObsolete(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityMonthlyProductionSeasonalResponse> {
            return localVarFp.getCommodityMonthlyProductionSeasonalObsolete(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityProductionSeasonal(region: string, commodity: string, dataFrequency: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityWeeklyProductionSeasonalResponse> {
            return localVarFp.getCommodityProductionSeasonal(region, commodity, dataFrequency, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityDailyProduction(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityDailyProductionResponse> {
            return localVarFp.listCommodityDailyProduction(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyProduction(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityMonthlyProductionResponse> {
            return localVarFp.listCommodityMonthlyProduction(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListCommodityMonthlyProductionAggregatedRequest} [listCommodityMonthlyProductionAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyProductionAggregated(listCommodityMonthlyProductionAggregatedRequest?: ListCommodityMonthlyProductionAggregatedRequest, options?: any): AxiosPromise<ListCommodityMonthlyProductionResponse> {
            return localVarFp.listCommodityMonthlyProductionAggregated(listCommodityMonthlyProductionAggregatedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyProduction(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityWeeklyProductionResponse> {
            return localVarFp.listCommodityWeeklyProduction(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterProductionApi - object-oriented interface
 * @export
 * @class DemeterProductionApi
 * @extends {BaseAPI}
 */
export class DemeterProductionApi extends BaseAPI {
    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterProductionApi
     */
    public getCommodityMonthlyProductionSeasonal(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterProductionApiFp(this.configuration).getCommodityMonthlyProductionSeasonal(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCommodityMonthlyProductionSeasonalAggregatedRequest} [getCommodityMonthlyProductionSeasonalAggregatedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterProductionApi
     */
    public getCommodityMonthlyProductionSeasonalAggregated(getCommodityMonthlyProductionSeasonalAggregatedRequest?: GetCommodityMonthlyProductionSeasonalAggregatedRequest, options?: AxiosRequestConfig) {
        return DemeterProductionApiFp(this.configuration).getCommodityMonthlyProductionSeasonalAggregated(getCommodityMonthlyProductionSeasonalAggregatedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DemeterProductionApi
     */
    public getCommodityMonthlyProductionSeasonalObsolete(region: string, commodity: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterProductionApiFp(this.configuration).getCommodityMonthlyProductionSeasonalObsolete(region, commodity, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {string} dataFrequency 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterProductionApi
     */
    public getCommodityProductionSeasonal(region: string, commodity: string, dataFrequency: string, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterProductionApiFp(this.configuration).getCommodityProductionSeasonal(region, commodity, dataFrequency, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterProductionApi
     */
    public listCommodityDailyProduction(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterProductionApiFp(this.configuration).listCommodityDailyProduction(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterProductionApi
     */
    public listCommodityMonthlyProduction(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterProductionApiFp(this.configuration).listCommodityMonthlyProduction(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListCommodityMonthlyProductionAggregatedRequest} [listCommodityMonthlyProductionAggregatedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterProductionApi
     */
    public listCommodityMonthlyProductionAggregated(listCommodityMonthlyProductionAggregatedRequest?: ListCommodityMonthlyProductionAggregatedRequest, options?: AxiosRequestConfig) {
        return DemeterProductionApiFp(this.configuration).listCommodityMonthlyProductionAggregated(listCommodityMonthlyProductionAggregatedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterProductionApi
     */
    public listCommodityWeeklyProduction(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterProductionApiFp(this.configuration).listCommodityWeeklyProduction(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterRegistrationApi - axios parameter creator
 * @export
 */
export const DemeterRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RegisterDemeterUserForZohoRequest} [registerDemeterUserForZohoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDemeterUserForZoho: async (registerDemeterUserForZohoRequest?: RegisterDemeterUserForZohoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/registration/zoho`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerDemeterUserForZohoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZohoWebhookRequest} [zohoWebhookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zohoSubscribeWebhook: async (zohoWebhookRequest?: ZohoWebhookRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/registration/zoho/webhook/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zohoWebhookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZohoWebhookRequest} [zohoWebhookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zohoUnsubscribeWebhook: async (zohoWebhookRequest?: ZohoWebhookRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/registration/zoho/webhook/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zohoWebhookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterRegistrationApi - functional programming interface
 * @export
 */
export const DemeterRegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterRegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RegisterDemeterUserForZohoRequest} [registerDemeterUserForZohoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerDemeterUserForZoho(registerDemeterUserForZohoRequest?: RegisterDemeterUserForZohoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterUserForZohoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerDemeterUserForZoho(registerDemeterUserForZohoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ZohoWebhookRequest} [zohoWebhookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zohoSubscribeWebhook(zohoWebhookRequest?: ZohoWebhookRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zohoSubscribeWebhook(zohoWebhookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ZohoWebhookRequest} [zohoWebhookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zohoUnsubscribeWebhook(zohoWebhookRequest?: ZohoWebhookRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zohoUnsubscribeWebhook(zohoWebhookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterRegistrationApi - factory interface
 * @export
 */
export const DemeterRegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterRegistrationApiFp(configuration)
    return {
        /**
         * 
         * @param {RegisterDemeterUserForZohoRequest} [registerDemeterUserForZohoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDemeterUserForZoho(registerDemeterUserForZohoRequest?: RegisterDemeterUserForZohoRequest, options?: any): AxiosPromise<RegisterUserForZohoResponse> {
            return localVarFp.registerDemeterUserForZoho(registerDemeterUserForZohoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZohoWebhookRequest} [zohoWebhookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zohoSubscribeWebhook(zohoWebhookRequest?: ZohoWebhookRequest, options?: any): AxiosPromise<object> {
            return localVarFp.zohoSubscribeWebhook(zohoWebhookRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZohoWebhookRequest} [zohoWebhookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zohoUnsubscribeWebhook(zohoWebhookRequest?: ZohoWebhookRequest, options?: any): AxiosPromise<object> {
            return localVarFp.zohoUnsubscribeWebhook(zohoWebhookRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterRegistrationApi - object-oriented interface
 * @export
 * @class DemeterRegistrationApi
 * @extends {BaseAPI}
 */
export class DemeterRegistrationApi extends BaseAPI {
    /**
     * 
     * @param {RegisterDemeterUserForZohoRequest} [registerDemeterUserForZohoRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterRegistrationApi
     */
    public registerDemeterUserForZoho(registerDemeterUserForZohoRequest?: RegisterDemeterUserForZohoRequest, options?: AxiosRequestConfig) {
        return DemeterRegistrationApiFp(this.configuration).registerDemeterUserForZoho(registerDemeterUserForZohoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZohoWebhookRequest} [zohoWebhookRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterRegistrationApi
     */
    public zohoSubscribeWebhook(zohoWebhookRequest?: ZohoWebhookRequest, options?: AxiosRequestConfig) {
        return DemeterRegistrationApiFp(this.configuration).zohoSubscribeWebhook(zohoWebhookRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZohoWebhookRequest} [zohoWebhookRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterRegistrationApi
     */
    public zohoUnsubscribeWebhook(zohoWebhookRequest?: ZohoWebhookRequest, options?: AxiosRequestConfig) {
        return DemeterRegistrationApiFp(this.configuration).zohoUnsubscribeWebhook(zohoWebhookRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterStocksApi - axios parameter creator
 * @export
 */
export const DemeterStocksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyStocksSeasonal: async (region: string, commodity: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityMonthlyStocksSeasonal', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityMonthlyStocksSeasonal', 'commodity', commodity)
            const localVarPath = `/demeter/stocks/{region}/{commodity}/monthly/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCommodityMonthlyStocksSeasonalAggregatedRequest} [getCommodityMonthlyStocksSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyStocksSeasonalAggregated: async (getCommodityMonthlyStocksSeasonalAggregatedRequest?: GetCommodityMonthlyStocksSeasonalAggregatedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/stocks/aggregated/seasonal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCommodityMonthlyStocksSeasonalAggregatedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCommodityMonthlyStocksSeasonalObsolete: async (region: string, commodity: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityMonthlyStocksSeasonalObsolete', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityMonthlyStocksSeasonalObsolete', 'commodity', commodity)
            const localVarPath = `/demeter/stocks/{region}/{commodity}/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityStocksSeasonal: async (region: string, commodity: string, dataFrequency: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('getCommodityStocksSeasonal', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getCommodityStocksSeasonal', 'commodity', commodity)
            // verify required parameter 'dataFrequency' is not null or undefined
            assertParamExists('getCommodityStocksSeasonal', 'dataFrequency', dataFrequency)
            const localVarPath = `/demeter/stocks/{region}/{commodity}/{dataFrequency}/seasonal`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)))
                .replace(`{${"dataFrequency"}}`, encodeURIComponent(String(dataFrequency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (showForecast !== undefined) {
                localVarQueryParameter['ShowForecast'] = showForecast;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyStocks: async (region: string, commodity: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityMonthlyStocks', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityMonthlyStocks', 'commodity', commodity)
            const localVarPath = `/demeter/stocks/{region}/{commodity}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataSource !== undefined) {
                localVarQueryParameter['DataSource'] = dataSource;
            }

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListCommodityMonthlyStocksAggregatedRequest} [listCommodityMonthlyStocksAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyStocksAggregated: async (listCommodityMonthlyStocksAggregatedRequest?: ListCommodityMonthlyStocksAggregatedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/stocks/aggregated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listCommodityMonthlyStocksAggregatedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyStocks: async (region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('listCommodityWeeklyStocks', 'region', region)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('listCommodityWeeklyStocks', 'commodity', commodity)
            const localVarPath = `/demeter/stocks/{region}/{commodity}/weekly`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subRegion !== undefined) {
                localVarQueryParameter['SubRegion'] = subRegion;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterStocksApi - functional programming interface
 * @export
 */
export const DemeterStocksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterStocksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityMonthlyStocksSeasonal(region: string, commodity: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyStocksSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyStocksSeasonal(region, commodity, dataSource, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetCommodityMonthlyStocksSeasonalAggregatedRequest} [getCommodityMonthlyStocksSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityMonthlyStocksSeasonalAggregated(getCommodityMonthlyStocksSeasonalAggregatedRequest?: GetCommodityMonthlyStocksSeasonalAggregatedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyStocksSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyStocksSeasonalAggregated(getCommodityMonthlyStocksSeasonalAggregatedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCommodityMonthlyStocksSeasonalObsolete(region: string, commodity: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityMonthlyStocksSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityMonthlyStocksSeasonalObsolete(region, commodity, dataSource, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommodityStocksSeasonal(region: string, commodity: string, dataFrequency: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommodityWeeklyStocksSeasonalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommodityStocksSeasonal(region, commodity, dataFrequency, dataSource, subRegion, years, showForecast, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyStocks(region: string, commodity: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyStocksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyStocks(region, commodity, dataSource, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ListCommodityMonthlyStocksAggregatedRequest} [listCommodityMonthlyStocksAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityMonthlyStocksAggregated(listCommodityMonthlyStocksAggregatedRequest?: ListCommodityMonthlyStocksAggregatedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyStocksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityMonthlyStocksAggregated(listCommodityMonthlyStocksAggregatedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityWeeklyStocks(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityWeeklyStocksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityWeeklyStocks(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterStocksApi - factory interface
 * @export
 */
export const DemeterStocksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterStocksApiFp(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyStocksSeasonal(region: string, commodity: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityMonthlyStocksSeasonalResponse> {
            return localVarFp.getCommodityMonthlyStocksSeasonal(region, commodity, dataSource, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCommodityMonthlyStocksSeasonalAggregatedRequest} [getCommodityMonthlyStocksSeasonalAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityMonthlyStocksSeasonalAggregated(getCommodityMonthlyStocksSeasonalAggregatedRequest?: GetCommodityMonthlyStocksSeasonalAggregatedRequest, options?: any): AxiosPromise<GetCommodityMonthlyStocksSeasonalResponse> {
            return localVarFp.getCommodityMonthlyStocksSeasonalAggregated(getCommodityMonthlyStocksSeasonalAggregatedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCommodityMonthlyStocksSeasonalObsolete(region: string, commodity: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityMonthlyStocksSeasonalResponse> {
            return localVarFp.getCommodityMonthlyStocksSeasonalObsolete(region, commodity, dataSource, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {string} dataFrequency 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {Array<number>} [years] 
         * @param {boolean} [showForecast] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommodityStocksSeasonal(region: string, commodity: string, dataFrequency: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<GetCommodityWeeklyStocksSeasonalResponse> {
            return localVarFp.getCommodityStocksSeasonal(region, commodity, dataFrequency, dataSource, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterDataSource} [dataSource] 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyStocks(region: string, commodity: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityMonthlyStocksResponse> {
            return localVarFp.listCommodityMonthlyStocks(region, commodity, dataSource, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListCommodityMonthlyStocksAggregatedRequest} [listCommodityMonthlyStocksAggregatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityMonthlyStocksAggregated(listCommodityMonthlyStocksAggregatedRequest?: ListCommodityMonthlyStocksAggregatedRequest, options?: any): AxiosPromise<ListCommodityMonthlyStocksResponse> {
            return localVarFp.listCommodityMonthlyStocksAggregated(listCommodityMonthlyStocksAggregatedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} commodity 
         * @param {DemeterSubRegion} [subRegion] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityWeeklyStocks(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityWeeklyStocksResponse> {
            return localVarFp.listCommodityWeeklyStocks(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterStocksApi - object-oriented interface
 * @export
 * @class DemeterStocksApi
 * @extends {BaseAPI}
 */
export class DemeterStocksApi extends BaseAPI {
    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterDataSource} [dataSource] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterStocksApi
     */
    public getCommodityMonthlyStocksSeasonal(region: string, commodity: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterStocksApiFp(this.configuration).getCommodityMonthlyStocksSeasonal(region, commodity, dataSource, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCommodityMonthlyStocksSeasonalAggregatedRequest} [getCommodityMonthlyStocksSeasonalAggregatedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterStocksApi
     */
    public getCommodityMonthlyStocksSeasonalAggregated(getCommodityMonthlyStocksSeasonalAggregatedRequest?: GetCommodityMonthlyStocksSeasonalAggregatedRequest, options?: AxiosRequestConfig) {
        return DemeterStocksApiFp(this.configuration).getCommodityMonthlyStocksSeasonalAggregated(getCommodityMonthlyStocksSeasonalAggregatedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterDataSource} [dataSource] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DemeterStocksApi
     */
    public getCommodityMonthlyStocksSeasonalObsolete(region: string, commodity: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterStocksApiFp(this.configuration).getCommodityMonthlyStocksSeasonalObsolete(region, commodity, dataSource, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {string} dataFrequency 
     * @param {DemeterDataSource} [dataSource] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {Array<number>} [years] 
     * @param {boolean} [showForecast] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterStocksApi
     */
    public getCommodityStocksSeasonal(region: string, commodity: string, dataFrequency: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, years?: Array<number>, showForecast?: boolean, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterStocksApiFp(this.configuration).getCommodityStocksSeasonal(region, commodity, dataFrequency, dataSource, subRegion, years, showForecast, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterDataSource} [dataSource] 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterStocksApi
     */
    public listCommodityMonthlyStocks(region: string, commodity: string, dataSource?: DemeterDataSource, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterStocksApiFp(this.configuration).listCommodityMonthlyStocks(region, commodity, dataSource, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListCommodityMonthlyStocksAggregatedRequest} [listCommodityMonthlyStocksAggregatedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterStocksApi
     */
    public listCommodityMonthlyStocksAggregated(listCommodityMonthlyStocksAggregatedRequest?: ListCommodityMonthlyStocksAggregatedRequest, options?: AxiosRequestConfig) {
        return DemeterStocksApiFp(this.configuration).listCommodityMonthlyStocksAggregated(listCommodityMonthlyStocksAggregatedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} commodity 
     * @param {DemeterSubRegion} [subRegion] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterStocksApi
     */
    public listCommodityWeeklyStocks(region: string, commodity: string, subRegion?: DemeterSubRegion, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterStocksApiFp(this.configuration).listCommodityWeeklyStocks(region, commodity, subRegion, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterUserDataApi - axios parameter creator
 * @export
 */
export const DemeterUserDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} demeterUserDataGuid 
         * @param {DeleteDemeterUserDataRequest} [deleteDemeterUserDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDemeterUserData: async (demeterUserDataGuid: string, deleteDemeterUserDataRequest?: DeleteDemeterUserDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserDataGuid' is not null or undefined
            assertParamExists('deleteDemeterUserData', 'demeterUserDataGuid', demeterUserDataGuid)
            const localVarPath = `/demeter/user-data/{demeterUserDataGuid}`
                .replace(`{${"demeterUserDataGuid"}}`, encodeURIComponent(String(demeterUserDataGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteDemeterUserDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserDataGuid 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUserData: async (demeterUserDataGuid: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserDataGuid' is not null or undefined
            assertParamExists('getDemeterUserData', 'demeterUserDataGuid', demeterUserDataGuid)
            const localVarPath = `/demeter/user-data/{demeterUserDataGuid}`
                .replace(`{${"demeterUserDataGuid"}}`, encodeURIComponent(String(demeterUserDataGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserDataGuid 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUserDataFile: async (demeterUserDataGuid: string, contentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserDataGuid' is not null or undefined
            assertParamExists('getDemeterUserDataFile', 'demeterUserDataGuid', demeterUserDataGuid)
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('getDemeterUserDataFile', 'contentType', contentType)
            const localVarPath = `/demeter/user-data/file/{demeterUserDataGuid}`
                .replace(`{${"demeterUserDataGuid"}}`, encodeURIComponent(String(demeterUserDataGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['ContentType'] = contentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterUserData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/user-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [demeterUserDataGuid] 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserDataMonthlyPrices: async (demeterUserDataGuid?: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/user-data/prices/monthly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (demeterUserDataGuid !== undefined) {
                localVarQueryParameter['DemeterUserDataGuid'] = demeterUserDataGuid;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }

            if (filterTimeSpan !== undefined) {
                localVarQueryParameter['FilterTimeSpan'] = filterTimeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserDataPricesByFrequency: async (currency?: Currency, unitOfMeasure?: UnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/user-data/prices/frequency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (unitOfMeasure !== undefined) {
                localVarQueryParameter['UnitOfMeasure'] = unitOfMeasure;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserDataGuid 
         * @param {UpdateDemeterUserDataRequest} [updateDemeterUserDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUserData: async (demeterUserDataGuid: string, updateDemeterUserDataRequest?: UpdateDemeterUserDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserDataGuid' is not null or undefined
            assertParamExists('updateDemeterUserData', 'demeterUserDataGuid', demeterUserDataGuid)
            const localVarPath = `/demeter/user-data/{demeterUserDataGuid}`
                .replace(`{${"demeterUserDataGuid"}}`, encodeURIComponent(String(demeterUserDataGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterUserDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertDemeterUserDataRequest} [upsertDemeterUserDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertDemeterUserData: async (upsertDemeterUserDataRequest?: UpsertDemeterUserDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/user-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertDemeterUserDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertDemeterUserDataExcelRequest} [upsertDemeterUserDataExcelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertDemeterUserDataExcel: async (upsertDemeterUserDataExcelRequest?: UpsertDemeterUserDataExcelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/user-data/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertDemeterUserDataExcelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterUserDataApi - functional programming interface
 * @export
 */
export const DemeterUserDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterUserDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} demeterUserDataGuid 
         * @param {DeleteDemeterUserDataRequest} [deleteDemeterUserDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDemeterUserData(demeterUserDataGuid: string, deleteDemeterUserDataRequest?: DeleteDemeterUserDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDemeterUserData(demeterUserDataGuid, deleteDemeterUserDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserDataGuid 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemeterUserData(demeterUserDataGuid: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterUserDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemeterUserData(demeterUserDataGuid, currency, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserDataGuid 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemeterUserDataFile(demeterUserDataGuid: string, contentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterUserDataFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemeterUserDataFile(demeterUserDataGuid, contentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterUserData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterUserDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterUserData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [demeterUserDataGuid] 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserDataMonthlyPrices(demeterUserDataGuid?: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityMonthlyPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserDataMonthlyPrices(demeterUserDataGuid, currency, unitOfMeasure, filterTimeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserDataPricesByFrequency(currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityPricesByFrequencyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserDataPricesByFrequency(currency, unitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserDataGuid 
         * @param {UpdateDemeterUserDataRequest} [updateDemeterUserDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDemeterUserData(demeterUserDataGuid: string, updateDemeterUserDataRequest?: UpdateDemeterUserDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterUserData(demeterUserDataGuid, updateDemeterUserDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertDemeterUserDataRequest} [upsertDemeterUserDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertDemeterUserData(upsertDemeterUserDataRequest?: UpsertDemeterUserDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertDemeterUserData(upsertDemeterUserDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertDemeterUserDataExcelRequest} [upsertDemeterUserDataExcelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertDemeterUserDataExcel(upsertDemeterUserDataExcelRequest?: UpsertDemeterUserDataExcelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertDemeterUserDataExcel(upsertDemeterUserDataExcelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterUserDataApi - factory interface
 * @export
 */
export const DemeterUserDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterUserDataApiFp(configuration)
    return {
        /**
         * 
         * @param {string} demeterUserDataGuid 
         * @param {DeleteDemeterUserDataRequest} [deleteDemeterUserDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDemeterUserData(demeterUserDataGuid: string, deleteDemeterUserDataRequest?: DeleteDemeterUserDataRequest, options?: any): AxiosPromise<object> {
            return localVarFp.deleteDemeterUserData(demeterUserDataGuid, deleteDemeterUserDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserDataGuid 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUserData(demeterUserDataGuid: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<GetDemeterUserDataResponse> {
            return localVarFp.getDemeterUserData(demeterUserDataGuid, currency, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserDataGuid 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUserDataFile(demeterUserDataGuid: string, contentType: string, options?: any): AxiosPromise<GetDemeterUserDataFileResponse> {
            return localVarFp.getDemeterUserDataFile(demeterUserDataGuid, contentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterUserData(options?: any): AxiosPromise<ListDemeterUserDataResponse> {
            return localVarFp.listDemeterUserData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [demeterUserDataGuid] 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserDataMonthlyPrices(demeterUserDataGuid?: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: any): AxiosPromise<ListCommodityMonthlyPricesResponse> {
            return localVarFp.listUserDataMonthlyPrices(demeterUserDataGuid, currency, unitOfMeasure, filterTimeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Currency} [currency] 
         * @param {UnitOfMeasure} [unitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserDataPricesByFrequency(currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: any): AxiosPromise<ListCommodityPricesByFrequencyResponse> {
            return localVarFp.listUserDataPricesByFrequency(currency, unitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserDataGuid 
         * @param {UpdateDemeterUserDataRequest} [updateDemeterUserDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUserData(demeterUserDataGuid: string, updateDemeterUserDataRequest?: UpdateDemeterUserDataRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterUserData(demeterUserDataGuid, updateDemeterUserDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertDemeterUserDataRequest} [upsertDemeterUserDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertDemeterUserData(upsertDemeterUserDataRequest?: UpsertDemeterUserDataRequest, options?: any): AxiosPromise<object> {
            return localVarFp.upsertDemeterUserData(upsertDemeterUserDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertDemeterUserDataExcelRequest} [upsertDemeterUserDataExcelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertDemeterUserDataExcel(upsertDemeterUserDataExcelRequest?: UpsertDemeterUserDataExcelRequest, options?: any): AxiosPromise<object> {
            return localVarFp.upsertDemeterUserDataExcel(upsertDemeterUserDataExcelRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterUserDataApi - object-oriented interface
 * @export
 * @class DemeterUserDataApi
 * @extends {BaseAPI}
 */
export class DemeterUserDataApi extends BaseAPI {
    /**
     * 
     * @param {string} demeterUserDataGuid 
     * @param {DeleteDemeterUserDataRequest} [deleteDemeterUserDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUserDataApi
     */
    public deleteDemeterUserData(demeterUserDataGuid: string, deleteDemeterUserDataRequest?: DeleteDemeterUserDataRequest, options?: AxiosRequestConfig) {
        return DemeterUserDataApiFp(this.configuration).deleteDemeterUserData(demeterUserDataGuid, deleteDemeterUserDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserDataGuid 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUserDataApi
     */
    public getDemeterUserData(demeterUserDataGuid: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterUserDataApiFp(this.configuration).getDemeterUserData(demeterUserDataGuid, currency, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserDataGuid 
     * @param {string} contentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUserDataApi
     */
    public getDemeterUserDataFile(demeterUserDataGuid: string, contentType: string, options?: AxiosRequestConfig) {
        return DemeterUserDataApiFp(this.configuration).getDemeterUserDataFile(demeterUserDataGuid, contentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUserDataApi
     */
    public listDemeterUserData(options?: AxiosRequestConfig) {
        return DemeterUserDataApiFp(this.configuration).listDemeterUserData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [demeterUserDataGuid] 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {DemeterFilterTimeSpan} [filterTimeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUserDataApi
     */
    public listUserDataMonthlyPrices(demeterUserDataGuid?: string, currency?: Currency, unitOfMeasure?: UnitOfMeasure, filterTimeSpan?: DemeterFilterTimeSpan, options?: AxiosRequestConfig) {
        return DemeterUserDataApiFp(this.configuration).listUserDataMonthlyPrices(demeterUserDataGuid, currency, unitOfMeasure, filterTimeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Currency} [currency] 
     * @param {UnitOfMeasure} [unitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUserDataApi
     */
    public listUserDataPricesByFrequency(currency?: Currency, unitOfMeasure?: UnitOfMeasure, options?: AxiosRequestConfig) {
        return DemeterUserDataApiFp(this.configuration).listUserDataPricesByFrequency(currency, unitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserDataGuid 
     * @param {UpdateDemeterUserDataRequest} [updateDemeterUserDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUserDataApi
     */
    public updateDemeterUserData(demeterUserDataGuid: string, updateDemeterUserDataRequest?: UpdateDemeterUserDataRequest, options?: AxiosRequestConfig) {
        return DemeterUserDataApiFp(this.configuration).updateDemeterUserData(demeterUserDataGuid, updateDemeterUserDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertDemeterUserDataRequest} [upsertDemeterUserDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUserDataApi
     */
    public upsertDemeterUserData(upsertDemeterUserDataRequest?: UpsertDemeterUserDataRequest, options?: AxiosRequestConfig) {
        return DemeterUserDataApiFp(this.configuration).upsertDemeterUserData(upsertDemeterUserDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertDemeterUserDataExcelRequest} [upsertDemeterUserDataExcelRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUserDataApi
     */
    public upsertDemeterUserDataExcel(upsertDemeterUserDataExcelRequest?: UpsertDemeterUserDataExcelRequest, options?: AxiosRequestConfig) {
        return DemeterUserDataApiFp(this.configuration).upsertDemeterUserDataExcel(upsertDemeterUserDataExcelRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterUsersApi - axios parameter creator
 * @export
 */
export const DemeterUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddDemeterUserRequest} [addDemeterUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDemeterUser: async (addDemeterUserRequest?: AddDemeterUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDemeterUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AgreeToDemeterTermsAndConditionsRequest} [agreeToDemeterTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreeToDemeterTermsAndConditions: async (agreeToDemeterTermsAndConditionsRequest?: AgreeToDemeterTermsAndConditionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/terms-and-conditions/agree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agreeToDemeterTermsAndConditionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDemeterUser: async (demeterUserTrialGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserTrialGuid' is not null or undefined
            assertParamExists('approveDemeterUser', 'demeterUserTrialGuid', demeterUserTrialGuid)
            const localVarPath = `/demeter/users/{demeterUserTrialGuid}/approve`
                .replace(`{${"demeterUserTrialGuid"}}`, encodeURIComponent(String(demeterUserTrialGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchImportDemeterUsers: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDemeterSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {boolean} [deactivateMarketIntelligence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateDemeterUser: async (demeterUserTrialGuid: string, deactivateMarketIntelligence?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserTrialGuid' is not null or undefined
            assertParamExists('deactivateDemeterUser', 'demeterUserTrialGuid', demeterUserTrialGuid)
            const localVarPath = `/demeter/users/{demeterUserTrialGuid}`
                .replace(`{${"demeterUserTrialGuid"}}`, encodeURIComponent(String(demeterUserTrialGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deactivateMarketIntelligence !== undefined) {
                localVarQueryParameter['DeactivateMarketIntelligence'] = deactivateMarketIntelligence;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentDemeterUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentDemeterUserPermissions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/current/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUser: async (demeterUserTrialGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserTrialGuid' is not null or undefined
            assertParamExists('getDemeterUser', 'demeterUserTrialGuid', demeterUserTrialGuid)
            const localVarPath = `/demeter/users/{demeterUserTrialGuid}`
                .replace(`{${"demeterUserTrialGuid"}}`, encodeURIComponent(String(demeterUserTrialGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {boolean} [refreshCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUserPermissions: async (demeterUserTrialGuid: string, refreshCache?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserTrialGuid' is not null or undefined
            assertParamExists('getDemeterUserPermissions', 'demeterUserTrialGuid', demeterUserTrialGuid)
            const localVarPath = `/demeter/users/{demeterUserTrialGuid}/permissions`
                .replace(`{${"demeterUserTrialGuid"}}`, encodeURIComponent(String(demeterUserTrialGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (refreshCache !== undefined) {
                localVarQueryParameter['RefreshCache'] = refreshCache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUserPreference: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DemeterUserStoreType>} [userStoreTypes] 
         * @param {boolean} [refreshSession] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUserSession: async (userStoreTypes?: Array<DemeterUserStoreType>, refreshSession?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userStoreTypes) {
                localVarQueryParameter['UserStoreTypes'] = userStoreTypes;
            }

            if (refreshSession !== undefined) {
                localVarQueryParameter['RefreshSession'] = refreshSession;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userStoreType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStore: async (userStoreType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userStoreType' is not null or undefined
            assertParamExists('getUserStore', 'userStoreType', userStoreType)
            const localVarPath = `/demeter/users/stores/{userStoreType}`
                .replace(`{${"userStoreType"}}`, encodeURIComponent(String(userStoreType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterUserStores: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {DemeterUserType} [userType] 
         * @param {DemeterUserStatus} [userStatus] 
         * @param {string} [demeterCompanyGuid] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterUsers: async (searchTerm?: string, userType?: DemeterUserType, userStatus?: DemeterUserStatus, demeterCompanyGuid?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['SearchTerm'] = searchTerm;
            }

            if (userType !== undefined) {
                localVarQueryParameter['UserType'] = userType;
            }

            if (userStatus !== undefined) {
                localVarQueryParameter['UserStatus'] = userStatus;
            }

            if (demeterCompanyGuid !== undefined) {
                localVarQueryParameter['DemeterCompanyGuid'] = demeterCompanyGuid;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingDemeterUserSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/session/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {ReactivateDemeterUserRequest} [reactivateDemeterUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateDemeterUser: async (demeterUserTrialGuid: string, reactivateDemeterUserRequest?: ReactivateDemeterUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserTrialGuid' is not null or undefined
            assertParamExists('reactivateDemeterUser', 'demeterUserTrialGuid', demeterUserTrialGuid)
            const localVarPath = `/demeter/users/{demeterUserTrialGuid}/reactivate`
                .replace(`{${"demeterUserTrialGuid"}}`, encodeURIComponent(String(demeterUserTrialGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reactivateDemeterUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {RejectDemeterUserRequest} [rejectDemeterUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectDemeterUser: async (demeterUserTrialGuid: string, rejectDemeterUserRequest?: RejectDemeterUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserTrialGuid' is not null or undefined
            assertParamExists('rejectDemeterUser', 'demeterUserTrialGuid', demeterUserTrialGuid)
            const localVarPath = `/demeter/users/{demeterUserTrialGuid}/reject`
                .replace(`{${"demeterUserTrialGuid"}}`, encodeURIComponent(String(demeterUserTrialGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectDemeterUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {SendDemeterUserTrialEmailRequest} [sendDemeterUserTrialEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDemeterUserTrialEmail: async (demeterUserTrialGuid: string, sendDemeterUserTrialEmailRequest?: SendDemeterUserTrialEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserTrialGuid' is not null or undefined
            assertParamExists('sendDemeterUserTrialEmail', 'demeterUserTrialGuid', demeterUserTrialGuid)
            const localVarPath = `/demeter/users/{demeterUserTrialGuid}/email/trial`
                .replace(`{${"demeterUserTrialGuid"}}`, encodeURIComponent(String(demeterUserTrialGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendDemeterUserTrialEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {UpdateDemeterUserRequest} [updateDemeterUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUser: async (demeterUserTrialGuid: string, updateDemeterUserRequest?: UpdateDemeterUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserTrialGuid' is not null or undefined
            assertParamExists('updateDemeterUser', 'demeterUserTrialGuid', demeterUserTrialGuid)
            const localVarPath = `/demeter/users/{demeterUserTrialGuid}`
                .replace(`{${"demeterUserTrialGuid"}}`, encodeURIComponent(String(demeterUserTrialGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDemeterUserCurrencyPreferenceRequest} [updateDemeterUserCurrencyPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUserCurrencyPreference: async (updateDemeterUserCurrencyPreferenceRequest?: UpdateDemeterUserCurrencyPreferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/preferences/currency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterUserCurrencyPreferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDemeterUserCurrencyPreferenceRequest} [updateDemeterUserCurrencyPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDemeterUserCurrencyPreferenceObsolete: async (updateDemeterUserCurrencyPreferenceRequest?: UpdateDemeterUserCurrencyPreferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/preferences/currency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterUserCurrencyPreferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {UpdateDemeterUserPermissionRequest} [updateDemeterUserPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUserPermissions: async (demeterUserTrialGuid: string, updateDemeterUserPermissionRequest?: UpdateDemeterUserPermissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserTrialGuid' is not null or undefined
            assertParamExists('updateDemeterUserPermissions', 'demeterUserTrialGuid', demeterUserTrialGuid)
            const localVarPath = `/demeter/users/{demeterUserTrialGuid}/permissions`
                .replace(`{${"demeterUserTrialGuid"}}`, encodeURIComponent(String(demeterUserTrialGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterUserPermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {UpdateDemeterUserPermissionRequest} [updateDemeterUserPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDemeterUserPermissionsObsolete: async (demeterUserTrialGuid: string, updateDemeterUserPermissionRequest?: UpdateDemeterUserPermissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demeterUserTrialGuid' is not null or undefined
            assertParamExists('updateDemeterUserPermissionsObsolete', 'demeterUserTrialGuid', demeterUserTrialGuid)
            const localVarPath = `/demeter/users/{demeterUserTrialGuid}/permissions`
                .replace(`{${"demeterUserTrialGuid"}}`, encodeURIComponent(String(demeterUserTrialGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterUserPermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDemeterUserPreferenceRequest} [updateDemeterUserPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUserPreference: async (updateDemeterUserPreferenceRequest?: UpdateDemeterUserPreferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterUserPreferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDemeterUserPreferenceRequest} [updateDemeterUserPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDemeterUserPreferenceObsolete: async (updateDemeterUserPreferenceRequest?: UpdateDemeterUserPreferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterUserPreferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDemeterUserUnitOfMeasurePreferenceRequest} [updateDemeterUserUnitOfMeasurePreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUserUnitOfMeasurePreference: async (updateDemeterUserUnitOfMeasurePreferenceRequest?: UpdateDemeterUserUnitOfMeasurePreferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/preferences/unit-of-measure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterUserUnitOfMeasurePreferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDemeterUserUnitOfMeasurePreferenceRequest} [updateDemeterUserUnitOfMeasurePreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDemeterUserUnitOfMeasurePreferenceObsolete: async (updateDemeterUserUnitOfMeasurePreferenceRequest?: UpdateDemeterUserUnitOfMeasurePreferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/users/preferences/unit-of-measure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterUserUnitOfMeasurePreferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userStoreType 
         * @param {UpdateDemeterUserStoreRequest} [updateDemeterUserStoreRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStore: async (userStoreType: string, updateDemeterUserStoreRequest?: UpdateDemeterUserStoreRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userStoreType' is not null or undefined
            assertParamExists('updateUserStore', 'userStoreType', userStoreType)
            const localVarPath = `/demeter/users/stores/{userStoreType}`
                .replace(`{${"userStoreType"}}`, encodeURIComponent(String(userStoreType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDemeterUserStoreRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterUsersApi - functional programming interface
 * @export
 */
export const DemeterUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddDemeterUserRequest} [addDemeterUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDemeterUser(addDemeterUserRequest?: AddDemeterUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddDemeterUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDemeterUser(addDemeterUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AgreeToDemeterTermsAndConditionsRequest} [agreeToDemeterTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agreeToDemeterTermsAndConditions(agreeToDemeterTermsAndConditionsRequest?: AgreeToDemeterTermsAndConditionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agreeToDemeterTermsAndConditions(agreeToDemeterTermsAndConditionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveDemeterUser(demeterUserTrialGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveDemeterUser(demeterUserTrialGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchImportDemeterUsers(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchImportDemeterUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchImportDemeterUsers(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelDemeterSubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelDemeterSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {boolean} [deactivateMarketIntelligence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateDemeterUser(demeterUserTrialGuid: string, deactivateMarketIntelligence?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateDemeterUser(demeterUserTrialGuid, deactivateMarketIntelligence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentDemeterUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentDemeterUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentDemeterUserPermissions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterPermissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentDemeterUserPermissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemeterUser(demeterUserTrialGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemeterUser(demeterUserTrialGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {boolean} [refreshCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemeterUserPermissions(demeterUserTrialGuid: string, refreshCache?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterPermissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemeterUserPermissions(demeterUserTrialGuid, refreshCache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemeterUserPreference(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterUserPreferenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemeterUserPreference(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<DemeterUserStoreType>} [userStoreTypes] 
         * @param {boolean} [refreshSession] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemeterUserSession(userStoreTypes?: Array<DemeterUserStoreType>, refreshSession?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterUserSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemeterUserSession(userStoreTypes, refreshSession, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userStoreType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserStore(userStoreType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemeterUserStoreResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserStore(userStoreType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterUserStores(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterUserStoresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterUserStores(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {DemeterUserType} [userType] 
         * @param {DemeterUserStatus} [userStatus] 
         * @param {string} [demeterCompanyGuid] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterUsers(searchTerm?: string, userType?: DemeterUserType, userStatus?: DemeterUserStatus, demeterCompanyGuid?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterUsers(searchTerm, userType, userStatus, demeterCompanyGuid, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingDemeterUserSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingDemeterUserSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {ReactivateDemeterUserRequest} [reactivateDemeterUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactivateDemeterUser(demeterUserTrialGuid: string, reactivateDemeterUserRequest?: ReactivateDemeterUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactivateDemeterUser(demeterUserTrialGuid, reactivateDemeterUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {RejectDemeterUserRequest} [rejectDemeterUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectDemeterUser(demeterUserTrialGuid: string, rejectDemeterUserRequest?: RejectDemeterUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectDemeterUser(demeterUserTrialGuid, rejectDemeterUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {SendDemeterUserTrialEmailRequest} [sendDemeterUserTrialEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendDemeterUserTrialEmail(demeterUserTrialGuid: string, sendDemeterUserTrialEmailRequest?: SendDemeterUserTrialEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendDemeterUserTrialEmail(demeterUserTrialGuid, sendDemeterUserTrialEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {UpdateDemeterUserRequest} [updateDemeterUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDemeterUser(demeterUserTrialGuid: string, updateDemeterUserRequest?: UpdateDemeterUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterUser(demeterUserTrialGuid, updateDemeterUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDemeterUserCurrencyPreferenceRequest} [updateDemeterUserCurrencyPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDemeterUserCurrencyPreference(updateDemeterUserCurrencyPreferenceRequest?: UpdateDemeterUserCurrencyPreferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterUserCurrencyPreference(updateDemeterUserCurrencyPreferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDemeterUserCurrencyPreferenceRequest} [updateDemeterUserCurrencyPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateDemeterUserCurrencyPreferenceObsolete(updateDemeterUserCurrencyPreferenceRequest?: UpdateDemeterUserCurrencyPreferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterUserCurrencyPreferenceObsolete(updateDemeterUserCurrencyPreferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {UpdateDemeterUserPermissionRequest} [updateDemeterUserPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDemeterUserPermissions(demeterUserTrialGuid: string, updateDemeterUserPermissionRequest?: UpdateDemeterUserPermissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterUserPermissions(demeterUserTrialGuid, updateDemeterUserPermissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {UpdateDemeterUserPermissionRequest} [updateDemeterUserPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateDemeterUserPermissionsObsolete(demeterUserTrialGuid: string, updateDemeterUserPermissionRequest?: UpdateDemeterUserPermissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterUserPermissionsObsolete(demeterUserTrialGuid, updateDemeterUserPermissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDemeterUserPreferenceRequest} [updateDemeterUserPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDemeterUserPreference(updateDemeterUserPreferenceRequest?: UpdateDemeterUserPreferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterUserPreference(updateDemeterUserPreferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDemeterUserPreferenceRequest} [updateDemeterUserPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateDemeterUserPreferenceObsolete(updateDemeterUserPreferenceRequest?: UpdateDemeterUserPreferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterUserPreferenceObsolete(updateDemeterUserPreferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDemeterUserUnitOfMeasurePreferenceRequest} [updateDemeterUserUnitOfMeasurePreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDemeterUserUnitOfMeasurePreference(updateDemeterUserUnitOfMeasurePreferenceRequest?: UpdateDemeterUserUnitOfMeasurePreferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterUserUnitOfMeasurePreference(updateDemeterUserUnitOfMeasurePreferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDemeterUserUnitOfMeasurePreferenceRequest} [updateDemeterUserUnitOfMeasurePreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateDemeterUserUnitOfMeasurePreferenceObsolete(updateDemeterUserUnitOfMeasurePreferenceRequest?: UpdateDemeterUserUnitOfMeasurePreferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDemeterUserUnitOfMeasurePreferenceObsolete(updateDemeterUserUnitOfMeasurePreferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userStoreType 
         * @param {UpdateDemeterUserStoreRequest} [updateDemeterUserStoreRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserStore(userStoreType: string, updateDemeterUserStoreRequest?: UpdateDemeterUserStoreRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserStore(userStoreType, updateDemeterUserStoreRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterUsersApi - factory interface
 * @export
 */
export const DemeterUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterUsersApiFp(configuration)
    return {
        /**
         * 
         * @param {AddDemeterUserRequest} [addDemeterUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDemeterUser(addDemeterUserRequest?: AddDemeterUserRequest, options?: any): AxiosPromise<AddDemeterUserResponse> {
            return localVarFp.addDemeterUser(addDemeterUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AgreeToDemeterTermsAndConditionsRequest} [agreeToDemeterTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreeToDemeterTermsAndConditions(agreeToDemeterTermsAndConditionsRequest?: AgreeToDemeterTermsAndConditionsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.agreeToDemeterTermsAndConditions(agreeToDemeterTermsAndConditionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDemeterUser(demeterUserTrialGuid: string, options?: any): AxiosPromise<object> {
            return localVarFp.approveDemeterUser(demeterUserTrialGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchImportDemeterUsers(body?: object, options?: any): AxiosPromise<BatchImportDemeterUsersResponse> {
            return localVarFp.batchImportDemeterUsers(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDemeterSubscription(options?: any): AxiosPromise<object> {
            return localVarFp.cancelDemeterSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {boolean} [deactivateMarketIntelligence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateDemeterUser(demeterUserTrialGuid: string, deactivateMarketIntelligence?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.deactivateDemeterUser(demeterUserTrialGuid, deactivateMarketIntelligence, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentDemeterUser(options?: any): AxiosPromise<GetDemeterUserResponse> {
            return localVarFp.getCurrentDemeterUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentDemeterUserPermissions(options?: any): AxiosPromise<GetDemeterPermissionResponse> {
            return localVarFp.getCurrentDemeterUserPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUser(demeterUserTrialGuid: string, options?: any): AxiosPromise<GetDemeterUserResponse> {
            return localVarFp.getDemeterUser(demeterUserTrialGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {boolean} [refreshCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUserPermissions(demeterUserTrialGuid: string, refreshCache?: boolean, options?: any): AxiosPromise<GetDemeterPermissionResponse> {
            return localVarFp.getDemeterUserPermissions(demeterUserTrialGuid, refreshCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUserPreference(options?: any): AxiosPromise<GetDemeterUserPreferenceResponse> {
            return localVarFp.getDemeterUserPreference(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<DemeterUserStoreType>} [userStoreTypes] 
         * @param {boolean} [refreshSession] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemeterUserSession(userStoreTypes?: Array<DemeterUserStoreType>, refreshSession?: boolean, options?: any): AxiosPromise<GetDemeterUserSessionResponse> {
            return localVarFp.getDemeterUserSession(userStoreTypes, refreshSession, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userStoreType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStore(userStoreType: string, options?: any): AxiosPromise<GetDemeterUserStoreResponse> {
            return localVarFp.getUserStore(userStoreType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterUserStores(options?: any): AxiosPromise<ListDemeterUserStoresResponse> {
            return localVarFp.listDemeterUserStores(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {DemeterUserType} [userType] 
         * @param {DemeterUserStatus} [userStatus] 
         * @param {string} [demeterCompanyGuid] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterUsers(searchTerm?: string, userType?: DemeterUserType, userStatus?: DemeterUserStatus, demeterCompanyGuid?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListDemeterUsersResponse> {
            return localVarFp.listDemeterUsers(searchTerm, userType, userStatus, demeterCompanyGuid, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingDemeterUserSession(options?: any): AxiosPromise<object> {
            return localVarFp.pingDemeterUserSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {ReactivateDemeterUserRequest} [reactivateDemeterUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateDemeterUser(demeterUserTrialGuid: string, reactivateDemeterUserRequest?: ReactivateDemeterUserRequest, options?: any): AxiosPromise<object> {
            return localVarFp.reactivateDemeterUser(demeterUserTrialGuid, reactivateDemeterUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {RejectDemeterUserRequest} [rejectDemeterUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectDemeterUser(demeterUserTrialGuid: string, rejectDemeterUserRequest?: RejectDemeterUserRequest, options?: any): AxiosPromise<object> {
            return localVarFp.rejectDemeterUser(demeterUserTrialGuid, rejectDemeterUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {SendDemeterUserTrialEmailRequest} [sendDemeterUserTrialEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDemeterUserTrialEmail(demeterUserTrialGuid: string, sendDemeterUserTrialEmailRequest?: SendDemeterUserTrialEmailRequest, options?: any): AxiosPromise<object> {
            return localVarFp.sendDemeterUserTrialEmail(demeterUserTrialGuid, sendDemeterUserTrialEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {UpdateDemeterUserRequest} [updateDemeterUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUser(demeterUserTrialGuid: string, updateDemeterUserRequest?: UpdateDemeterUserRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterUser(demeterUserTrialGuid, updateDemeterUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDemeterUserCurrencyPreferenceRequest} [updateDemeterUserCurrencyPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUserCurrencyPreference(updateDemeterUserCurrencyPreferenceRequest?: UpdateDemeterUserCurrencyPreferenceRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterUserCurrencyPreference(updateDemeterUserCurrencyPreferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDemeterUserCurrencyPreferenceRequest} [updateDemeterUserCurrencyPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDemeterUserCurrencyPreferenceObsolete(updateDemeterUserCurrencyPreferenceRequest?: UpdateDemeterUserCurrencyPreferenceRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterUserCurrencyPreferenceObsolete(updateDemeterUserCurrencyPreferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {UpdateDemeterUserPermissionRequest} [updateDemeterUserPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUserPermissions(demeterUserTrialGuid: string, updateDemeterUserPermissionRequest?: UpdateDemeterUserPermissionRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterUserPermissions(demeterUserTrialGuid, updateDemeterUserPermissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} demeterUserTrialGuid 
         * @param {UpdateDemeterUserPermissionRequest} [updateDemeterUserPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDemeterUserPermissionsObsolete(demeterUserTrialGuid: string, updateDemeterUserPermissionRequest?: UpdateDemeterUserPermissionRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterUserPermissionsObsolete(demeterUserTrialGuid, updateDemeterUserPermissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDemeterUserPreferenceRequest} [updateDemeterUserPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUserPreference(updateDemeterUserPreferenceRequest?: UpdateDemeterUserPreferenceRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterUserPreference(updateDemeterUserPreferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDemeterUserPreferenceRequest} [updateDemeterUserPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDemeterUserPreferenceObsolete(updateDemeterUserPreferenceRequest?: UpdateDemeterUserPreferenceRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterUserPreferenceObsolete(updateDemeterUserPreferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDemeterUserUnitOfMeasurePreferenceRequest} [updateDemeterUserUnitOfMeasurePreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemeterUserUnitOfMeasurePreference(updateDemeterUserUnitOfMeasurePreferenceRequest?: UpdateDemeterUserUnitOfMeasurePreferenceRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterUserUnitOfMeasurePreference(updateDemeterUserUnitOfMeasurePreferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDemeterUserUnitOfMeasurePreferenceRequest} [updateDemeterUserUnitOfMeasurePreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDemeterUserUnitOfMeasurePreferenceObsolete(updateDemeterUserUnitOfMeasurePreferenceRequest?: UpdateDemeterUserUnitOfMeasurePreferenceRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateDemeterUserUnitOfMeasurePreferenceObsolete(updateDemeterUserUnitOfMeasurePreferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userStoreType 
         * @param {UpdateDemeterUserStoreRequest} [updateDemeterUserStoreRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStore(userStoreType: string, updateDemeterUserStoreRequest?: UpdateDemeterUserStoreRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateUserStore(userStoreType, updateDemeterUserStoreRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterUsersApi - object-oriented interface
 * @export
 * @class DemeterUsersApi
 * @extends {BaseAPI}
 */
export class DemeterUsersApi extends BaseAPI {
    /**
     * 
     * @param {AddDemeterUserRequest} [addDemeterUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public addDemeterUser(addDemeterUserRequest?: AddDemeterUserRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).addDemeterUser(addDemeterUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AgreeToDemeterTermsAndConditionsRequest} [agreeToDemeterTermsAndConditionsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public agreeToDemeterTermsAndConditions(agreeToDemeterTermsAndConditionsRequest?: AgreeToDemeterTermsAndConditionsRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).agreeToDemeterTermsAndConditions(agreeToDemeterTermsAndConditionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserTrialGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public approveDemeterUser(demeterUserTrialGuid: string, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).approveDemeterUser(demeterUserTrialGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public batchImportDemeterUsers(body?: object, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).batchImportDemeterUsers(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public cancelDemeterSubscription(options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).cancelDemeterSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserTrialGuid 
     * @param {boolean} [deactivateMarketIntelligence] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public deactivateDemeterUser(demeterUserTrialGuid: string, deactivateMarketIntelligence?: boolean, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).deactivateDemeterUser(demeterUserTrialGuid, deactivateMarketIntelligence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public getCurrentDemeterUser(options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).getCurrentDemeterUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public getCurrentDemeterUserPermissions(options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).getCurrentDemeterUserPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserTrialGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public getDemeterUser(demeterUserTrialGuid: string, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).getDemeterUser(demeterUserTrialGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserTrialGuid 
     * @param {boolean} [refreshCache] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public getDemeterUserPermissions(demeterUserTrialGuid: string, refreshCache?: boolean, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).getDemeterUserPermissions(demeterUserTrialGuid, refreshCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public getDemeterUserPreference(options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).getDemeterUserPreference(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<DemeterUserStoreType>} [userStoreTypes] 
     * @param {boolean} [refreshSession] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public getDemeterUserSession(userStoreTypes?: Array<DemeterUserStoreType>, refreshSession?: boolean, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).getDemeterUserSession(userStoreTypes, refreshSession, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userStoreType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public getUserStore(userStoreType: string, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).getUserStore(userStoreType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public listDemeterUserStores(options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).listDemeterUserStores(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchTerm] 
     * @param {DemeterUserType} [userType] 
     * @param {DemeterUserStatus} [userStatus] 
     * @param {string} [demeterCompanyGuid] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public listDemeterUsers(searchTerm?: string, userType?: DemeterUserType, userStatus?: DemeterUserStatus, demeterCompanyGuid?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).listDemeterUsers(searchTerm, userType, userStatus, demeterCompanyGuid, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public pingDemeterUserSession(options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).pingDemeterUserSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserTrialGuid 
     * @param {ReactivateDemeterUserRequest} [reactivateDemeterUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public reactivateDemeterUser(demeterUserTrialGuid: string, reactivateDemeterUserRequest?: ReactivateDemeterUserRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).reactivateDemeterUser(demeterUserTrialGuid, reactivateDemeterUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserTrialGuid 
     * @param {RejectDemeterUserRequest} [rejectDemeterUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public rejectDemeterUser(demeterUserTrialGuid: string, rejectDemeterUserRequest?: RejectDemeterUserRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).rejectDemeterUser(demeterUserTrialGuid, rejectDemeterUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserTrialGuid 
     * @param {SendDemeterUserTrialEmailRequest} [sendDemeterUserTrialEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public sendDemeterUserTrialEmail(demeterUserTrialGuid: string, sendDemeterUserTrialEmailRequest?: SendDemeterUserTrialEmailRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).sendDemeterUserTrialEmail(demeterUserTrialGuid, sendDemeterUserTrialEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserTrialGuid 
     * @param {UpdateDemeterUserRequest} [updateDemeterUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public updateDemeterUser(demeterUserTrialGuid: string, updateDemeterUserRequest?: UpdateDemeterUserRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).updateDemeterUser(demeterUserTrialGuid, updateDemeterUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDemeterUserCurrencyPreferenceRequest} [updateDemeterUserCurrencyPreferenceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public updateDemeterUserCurrencyPreference(updateDemeterUserCurrencyPreferenceRequest?: UpdateDemeterUserCurrencyPreferenceRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).updateDemeterUserCurrencyPreference(updateDemeterUserCurrencyPreferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDemeterUserCurrencyPreferenceRequest} [updateDemeterUserCurrencyPreferenceRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public updateDemeterUserCurrencyPreferenceObsolete(updateDemeterUserCurrencyPreferenceRequest?: UpdateDemeterUserCurrencyPreferenceRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).updateDemeterUserCurrencyPreferenceObsolete(updateDemeterUserCurrencyPreferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserTrialGuid 
     * @param {UpdateDemeterUserPermissionRequest} [updateDemeterUserPermissionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public updateDemeterUserPermissions(demeterUserTrialGuid: string, updateDemeterUserPermissionRequest?: UpdateDemeterUserPermissionRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).updateDemeterUserPermissions(demeterUserTrialGuid, updateDemeterUserPermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} demeterUserTrialGuid 
     * @param {UpdateDemeterUserPermissionRequest} [updateDemeterUserPermissionRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public updateDemeterUserPermissionsObsolete(demeterUserTrialGuid: string, updateDemeterUserPermissionRequest?: UpdateDemeterUserPermissionRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).updateDemeterUserPermissionsObsolete(demeterUserTrialGuid, updateDemeterUserPermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDemeterUserPreferenceRequest} [updateDemeterUserPreferenceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public updateDemeterUserPreference(updateDemeterUserPreferenceRequest?: UpdateDemeterUserPreferenceRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).updateDemeterUserPreference(updateDemeterUserPreferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDemeterUserPreferenceRequest} [updateDemeterUserPreferenceRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public updateDemeterUserPreferenceObsolete(updateDemeterUserPreferenceRequest?: UpdateDemeterUserPreferenceRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).updateDemeterUserPreferenceObsolete(updateDemeterUserPreferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDemeterUserUnitOfMeasurePreferenceRequest} [updateDemeterUserUnitOfMeasurePreferenceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public updateDemeterUserUnitOfMeasurePreference(updateDemeterUserUnitOfMeasurePreferenceRequest?: UpdateDemeterUserUnitOfMeasurePreferenceRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).updateDemeterUserUnitOfMeasurePreference(updateDemeterUserUnitOfMeasurePreferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDemeterUserUnitOfMeasurePreferenceRequest} [updateDemeterUserUnitOfMeasurePreferenceRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public updateDemeterUserUnitOfMeasurePreferenceObsolete(updateDemeterUserUnitOfMeasurePreferenceRequest?: UpdateDemeterUserUnitOfMeasurePreferenceRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).updateDemeterUserUnitOfMeasurePreferenceObsolete(updateDemeterUserUnitOfMeasurePreferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userStoreType 
     * @param {UpdateDemeterUserStoreRequest} [updateDemeterUserStoreRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterUsersApi
     */
    public updateUserStore(userStoreType: string, updateDemeterUserStoreRequest?: UpdateDemeterUserStoreRequest, options?: AxiosRequestConfig) {
        return DemeterUsersApiFp(this.configuration).updateUserStore(userStoreType, updateDemeterUserStoreRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemeterWorkerSyncStatusesApi - axios parameter creator
 * @export
 */
export const DemeterWorkerSyncStatusesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterWorkerSyncStatuses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demeter/worker-sync-statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemeterWorkerSyncStatusesApi - functional programming interface
 * @export
 */
export const DemeterWorkerSyncStatusesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemeterWorkerSyncStatusesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemeterWorkerSyncStatuses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDemeterWorkerSyncStatusesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemeterWorkerSyncStatuses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemeterWorkerSyncStatusesApi - factory interface
 * @export
 */
export const DemeterWorkerSyncStatusesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemeterWorkerSyncStatusesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemeterWorkerSyncStatuses(options?: any): AxiosPromise<ListDemeterWorkerSyncStatusesResponse> {
            return localVarFp.listDemeterWorkerSyncStatuses(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemeterWorkerSyncStatusesApi - object-oriented interface
 * @export
 * @class DemeterWorkerSyncStatusesApi
 * @extends {BaseAPI}
 */
export class DemeterWorkerSyncStatusesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemeterWorkerSyncStatusesApi
     */
    public listDemeterWorkerSyncStatuses(options?: AxiosRequestConfig) {
        return DemeterWorkerSyncStatusesApiFp(this.configuration).listDemeterWorkerSyncStatuses(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationHealth(options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.getApplicationHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public getApplicationHealth(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).getApplicationHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


