import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import formattingService from '../../../Services/Formatting/FormattingService';
import languageService from '../../../Services/Language/LanguageService';
import { translationKeys } from '../../../Services/Language/TranslationDefinitions';

export const positionsColumnDefinitions: ColDef[] = [
    {
        headerValueGetter: () => languageService.translate(translationKeys.positions.fields.companyName),
        field: 'companyName',
        colId: 'companyName',
    },
    {
        headerValueGetter: () => languageService.translate(translationKeys.positions.fields.policyNumber),
        field: 'policyNumber',
        colId: 'policyNumber',
    },
    {
        headerValueGetter: () => languageService.translate(translationKeys.positions.fields.state),
        field: 'state',
        colId: 'state',
    },
    {
        headerValueGetter: () => languageService.translate(translationKeys.positions.fields.commodity),
        field: 'commodity',
        colId: 'commodity',
    },
    {
        headerValueGetter: () => languageService.translate(translationKeys.positions.fields.triggerPrice),
        field: 'triggerPrice',
        colId: 'triggerPrice',
    },
    {
        headerValueGetter: () => languageService.translate(translationKeys.positions.fields.premiumPrice),
        field: 'premiumPrice',
        colId: 'premiumPrice',
    },
    {
        headerValueGetter: () => languageService.translate(translationKeys.positions.fields.netCoveredVolume),
        field: 'netCoveredVolume',
        colId: 'netCoveredVolume',
    },
    {
        headerValueGetter: () => languageService.translate(translationKeys.positions.fields.endorsementDate),
        field: 'endorsementDate',
        colId: 'endorsementDate',
        valueFormatter: (parameters: ValueFormatterParams) => formattingService.toLongDayMonthYear(parameters.value),
    },
    {
        headerValueGetter: () => languageService.translate(translationKeys.positions.fields.coveragePeriod),
        field: 'coveragePeriod',
        colId: 'coveragePeriod',
        valueGetter: (parameters: ValueGetterParams) =>
            formattingService.toLongDayMonthYear(parameters.data.coveragePeriodDate) || parameters.data.coverageQuarter,
    },
];

export const positionsColumnOptions = {
    resizable: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    flex: 1,
    rowDragManaged: true,
    animateRows: true,
    wrapText: true,
    enableCellChangeFlash: true,
    headerClass: 'ag-header-cell-flex-start',
    comparator: () => 0,
    valueFormatter: (parameters: ValueFormatterParams) => {
        if (!parameters.value) {
            return applicationConstants.TablePlaceholderZeroOrEmpty;
        }

        return parameters.value;
    },
    sortable: true,
};
