import { DemeterMarket } from '../../Generated/Raven-Demeter';

type Environment = 'local' | 'development' | 'staging' | 'qa' | 'production';

interface ApplicationSettings {
    applicationName: string;
    applicationVersion: number;
    trialAccessAgreementVersion: number;
    displayName: string;
    environment: Environment;
    emails: {
        contactEmail: string;
        supportEmail: string;
    };
    telephoneNumbers: {
        chicago: string;
        dublin: string;
        singapore: string;
    };
    isDevelopmentLike: boolean;
    isMono: boolean;
    monoApplicationId: string;
    monoWidgetId: string;
    ravenEnvironment: string;
    // General settings, please alphabetize this.
    api?: {
        marketPricesUri: string;
        ravenBackendUri: string;
        ravenBackendDemterUri: string;
        ravenLightstreamerUri: string;
        ravenHighchartsUri: string;
    };
    applicationInsightsInstrumentKey?: string;
    geolocationApiKey: string;
    links: {
        stoneXHomePageUri?: string;
        monoUri?: string;
        marketIntelligenceUri?: string;
        precipiceUri?: string;
        privacyPolicyUri?: string;
    };
    locize: {
        projectId: string;
        apiKey: string;
        version: string;
        namespace: string;
    };
    markets: DemeterMarket[];
    okta: {
        issuer: string;
        clientId: string;
        redirectUri: string;
        postLogoutRedirectUri: string;
    };
}

const environment = (process.env.REACT_APP_ENVIRONMENT_NAME as Environment) ?? 'local';

const applicationSettings: ApplicationSettings = {
    applicationName: 'Demeter',
    applicationVersion: 1,
    trialAccessAgreementVersion: 2,
    displayName: 'StoneX Plus',
    environment,
    emails: {
        contactEmail: 'Plus_Dairy@StoneX.com',
        supportEmail: 'PlusBrokerSupport@StoneX.com',
    },
    telephoneNumbers: {
        chicago: '+1 (312) 456-3609',
        dublin: '+65 6309 3994',
        singapore: '+354 1 634-9140',
    },
    // eslint-disable-next-line no-nested-ternary
    ravenEnvironment: environment === 'development' || environment === 'local' ? 'development' : environment === 'production' ? 'production' : 'staging',
    isDevelopmentLike: environment !== 'production',
    isMono: true,
    monoApplicationId: 'DemeterApp',
    monoWidgetId: 'Demeter',
    // General settings, please alphabetize this.
    api: {
        marketPricesUri: process.env.REACT_APP_MARKET_PRICES_URI as string,
        ravenBackendUri: process.env.REACT_APP_RAVEN_BACKEND_URI as string,
        ravenBackendDemterUri: process.env.REACT_APP_RAVEN_DEMETER_BACKEND_URI as string,
        ravenLightstreamerUri: process.env.REACT_APP_RAVEN_LIGHTSTREAMER_URI as string,
        ravenHighchartsUri: process.env.REACT_APP_RAVEN_HIGHCHARTS_URI as string,
    },
    applicationInsightsInstrumentKey: process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENT_KEY,
    links: {
        stoneXHomePageUri: process.env.REACT_APP_STONEX_HOME_PAGE_URI,
        monoUri: process.env.REACT_APP_MONO_URI,
        marketIntelligenceUri: process.env.REACT_APP_MARKET_INTELLIGENCE_URI,
        precipiceUri: process.env.REACT_APP_PRECIPICE_URI,
        privacyPolicyUri: process.env.REACT_APP_PRIVACY_POLICY_URI,
    },
    geolocationApiKey: 'f2e84010-e1e9-11ed-b2f8-6b70106be3c8',
    locize: {
        projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID as string,
        apiKey: process.env.REACT_APP_LOCIZE_API_KEY as string,
        version: 'latest',
        namespace: 'translation',
    },
    markets: [DemeterMarket.Dairy, DemeterMarket.Energy, DemeterMarket.Grains],
    okta: {
        issuer: process.env.REACT_APP_OKTA_ISSUER ?? '',
        clientId: process.env.REACT_APP_OKTA_CLIENT_ID ?? '',
        redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI ?? '',
        postLogoutRedirectUri: process.env.REACT_APP_OKTA_POST_LOGOUT_URI ?? '',
    },
};

// eslint-disable-next-line no-console
console.log(`RUNNING in ${applicationSettings.environment} Environment.`);

export default applicationSettings;
