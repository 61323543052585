/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-redeclare */
import moment from 'moment';
import { Currency, DemeterSymbolModel, DemeterTableDefinitionModel, ExchangeType, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import formattingService from '../../../Services/Formatting/FormattingService';
import languageService from '../../../Services/Language/LanguageService';

moment.locale(languageService.getLanguage());

export type BasisCalculatorProduct = {
    id: string;
    name: string;
    category: string;
    group?: string | null | undefined;
    subGroup?: string | null | undefined;
    market?: string | null | undefined;
    reutersInstrumentCodePrefix?: string | null | undefined;
    exchange?: ExchangeType | null | undefined;
    region?: string | null | undefined;
    commodity?: string | null | undefined;
    subRegion?: string | null | undefined;
    contractNumber?: number | null | undefined;
    extraParameters?: string | null | undefined;
    currency?: Currency | undefined;
    unitOfMeasure?: UnitOfMeasure | undefined;
    dataSourceTag?: string[] | null | undefined;
    futuresSymbol?: DemeterSymbolModel | undefined;
    commodityTableDefinition?: DemeterTableDefinitionModel | undefined;
};

export type BasisCalculatorProductPair = {
    product1: BasisCalculatorProduct | undefined;
    product2: BasisCalculatorProduct | undefined;
    saved?: Date | undefined;
    currency?: Currency | undefined;
    unitOfMeasure?: UnitOfMeasure | undefined;
    showAdvancedSettings?: boolean | undefined;
    basisLagPeriod?: BasisLagPeriod | undefined;
    basisPeriod?: BasisPeriod | undefined;
    basisAdjustment?: BasisAdjustment | undefined;
    useOptimalLag?: boolean | undefined;
    useRegression?: boolean | undefined;
    startDate?: BasisMonthlyDate | undefined;
};

export type BasisValueModel = {
    asOfDate: Date;
    value: number;
    isActualValue: boolean;
    isForwardCurve?: boolean;
};

export type GroupedProducts = {
    [category: string]: {
        [market: string]: {
            [group: string]: {
                subGroups?: {
                    [subGroup: string]: BasisCalculatorProduct[];
                };
                products?: BasisCalculatorProduct[];
            };
        };
    };
};

export type BasisCalculatorChartText = {
    productName: string;
    productNameForwardCurve?: string;
    dataSourceTags: string[];
};

export const BasisPeriod = {
    Basis: 'Basis',
    ThreeMonthBasis: 'ThreeMonthBasis',
    SixMonthBasis: 'SixMonthBasis',
    NineMonthBasis: 'NineMonthBasis',
    TwelveMonthBasis: 'TwelveMonthBasis',
} as const;

export type BasisPeriod = (typeof BasisPeriod)[keyof typeof BasisPeriod];

export const BasisLagPeriod = {
    MinusSixMonthLag: 'MinusSixMonthLag',
    MinusFiveMonthLag: 'MinusFiveMonthLag',
    MinusFourMonthLag: 'MinusFourMonthLag',
    MinusThreeMonthLag: 'MinusThreeMonthLag',
    MinusTwoMonthLag: 'MinusTwoMonthLag',
    MinusOneMonthLag: 'MinusOneMonthLag',
    NoLag: 'NoLag',
    OneMonthLag: 'OneMonthLag',
    TwoMonthLag: 'TwoMonthLag',
    ThreeMonthLag: 'ThreeMonthLag',
    FourMonthLag: 'FourMonthLag',
    FiveMonthLag: 'FiveMonthLag',
    SixMonthLag: 'SixMonthLag',
} as const;

export type BasisLagPeriod = (typeof BasisLagPeriod)[keyof typeof BasisLagPeriod];

export const basisLagPeriods = [
    BasisLagPeriod.MinusSixMonthLag,
    BasisLagPeriod.MinusFiveMonthLag,
    BasisLagPeriod.MinusFourMonthLag,
    BasisLagPeriod.MinusThreeMonthLag,
    BasisLagPeriod.MinusTwoMonthLag,
    BasisLagPeriod.MinusOneMonthLag,
    BasisLagPeriod.NoLag,
    BasisLagPeriod.OneMonthLag,
    BasisLagPeriod.TwoMonthLag,
    BasisLagPeriod.ThreeMonthLag,
    BasisLagPeriod.FourMonthLag,
    BasisLagPeriod.FiveMonthLag,
    BasisLagPeriod.SixMonthLag,
];

export const BasisAdjustment = {
    Average: 'Average',
    Maximum: 'Maximum',
    Minimum: 'Minimum',
    Top10Percent: 'Top10Percent',
    Top20Percent: 'Top20Percent',
    Top30Percent: 'Top30Percent',
    Top40Percent: 'Top40Percent',
    Top50Percent: 'Top50Percent',
    Bottom40Percent: 'Bottom40Percent',
    Bottom30Percent: 'Bottom30Percent',
    Bottom20Percent: 'Bottom20Percent',
    Bottom10Percent: 'Bottom10Percent',
} as const;

export type BasisAdjustment = (typeof BasisAdjustment)[keyof typeof BasisAdjustment];

export const basisCurrencies = [Currency.Usd, Currency.Eur, Currency.Gbp];

export const dairyUnitOfMeasures: UnitOfMeasure[] = [
    UnitOfMeasure.HundredWeight,
    UnitOfMeasure.Kilogram,
    UnitOfMeasure.MetricTon,
    UnitOfMeasure.Pound,
    UnitOfMeasure.ShortTon,
];
export const energyUnitOfMeasures: UnitOfMeasure[] = [
    UnitOfMeasure.Gallon,
    UnitOfMeasure.Barrel,
    UnitOfMeasure.MegaWattHour,
    UnitOfMeasure.MillionBritishThermalUnits,
];

export const basisPeriods = [
    BasisPeriod.Basis,
    BasisPeriod.ThreeMonthBasis,
    BasisPeriod.SixMonthBasis,
    BasisPeriod.NineMonthBasis,
    BasisPeriod.TwelveMonthBasis,
];

export const basisAdjustments = [
    BasisAdjustment.Average,
    BasisAdjustment.Minimum,
    BasisAdjustment.Maximum,
    BasisAdjustment.Top10Percent,
    BasisAdjustment.Top20Percent,
    BasisAdjustment.Top30Percent,
    BasisAdjustment.Top40Percent,
    BasisAdjustment.Top50Percent,
    BasisAdjustment.Bottom40Percent,
    BasisAdjustment.Bottom30Percent,
    BasisAdjustment.Bottom20Percent,
    BasisAdjustment.Bottom10Percent,
];

const generateMonthlyDates = (startDate: Date, endDate: Date): { [key: string]: string } => {
    const currentDate = new Date(endDate);
    const result: { [key: string]: string } = {};
    while (currentDate >= startDate) {
        const key = formattingService.toMonthYear(currentDate);
        result[key] = key;
        currentDate.setMonth(currentDate.getMonth() - 1);
    }
    return result;
};

const getDateYearsAgo = (yearsAgo: number): Date => {
    const pastDate = new Date();
    pastDate.setFullYear(pastDate.getFullYear() - yearsAgo);
    return pastDate;
};

const getDateMonthsAgo = (monthsAgo: number): Date => {
    const pastDate = new Date();
    pastDate.setMonth(pastDate.getMonth() - monthsAgo);
    return pastDate;
};

const numberOfYearsBackwardsForStartDate = 7;
const numberOfMonthsBackwardsForEndDate = 6;

export const BasisMonthlyDate = {
    ...generateMonthlyDates(getDateYearsAgo(numberOfYearsBackwardsForStartDate), getDateMonthsAgo(numberOfMonthsBackwardsForEndDate)),
} as const;

export type BasisMonthlyDate = (typeof BasisMonthlyDate)[keyof typeof BasisMonthlyDate];

const startDateYearsBack = 5;

export const defaultStartDate = formattingService.toMonthYear(getDateYearsAgo(startDateYearsBack));

export const basisMonthlyDates = Object.keys(BasisMonthlyDate).map((key) => ({
    label: key,
    value: BasisMonthlyDate[key],
}));
