import { useMemo } from 'react';
import scssVariables from '../../../../../Config.module.scss';
import LabelWithTooltip from '../../../../Components/Form/Inputs/LabelWithTooltip';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { DemeterCalculationEngineLineShortModelWithChildren, HideGroup, MarginCompositeModel } from '../../RiskDefinitions';
import StickyColumnGroup from '../../StickyColumnGroup';
import styles from './MarginInputsTable.module.scss';
import MarginInputsTableCollapsibleField from './MarginInputsTableCollapsibleField';

interface IMarginInputsTableStickyColumnProps {
    getFarmerMarginCalculatorCompositeRowsModel: MarginCompositeModel[];
    hideGroup: HideGroup;
    setHideGroup: (hideGroup: HideGroup) => void;
}

const MarginInputsTableStickyColumn: React.FC<IMarginInputsTableStickyColumnProps> = (props: IMarginInputsTableStickyColumnProps) => {
    const [translations] = useLanguage();

    const stickyColumns = useMemo(() => {
        const columnHeadersRow = props.getFarmerMarginCalculatorCompositeRowsModel[0];
        const levelColors = [
            scssVariables.tableRowDark,
            scssVariables.alternativeNeutralColor,
            scssVariables.alternativeNeutralColorLevel2,
            scssVariables.alternativeNeutralColorLevel3,
        ];

        const getBaseNode = (displayName: string, currentLevel: number, hideGroupKey?: keyof HideGroup, informationText?: string) => ({
            sectionTitle: informationText ? <LabelWithTooltip title={displayName} tooltip={informationText} placement="top-start" /> : displayName,
            key: `${displayName}`,
            backgroundColor: props.hideGroup[hideGroupKey!] === false ? levelColors[currentLevel] : levelColors[currentLevel - 1],
        });

        const getNodeWithChildren = (displayName: string, currentLevel: number, hideGroupKey: keyof HideGroup, secondaryHideGroupKey?: keyof HideGroup) => {
            const showGroupChildren = props.hideGroup[hideGroupKey] === false;

            const newNode = {
                sectionTitle: <MarginInputsTableCollapsibleField fieldDisplayName={displayName} hideGroup={props.hideGroup[hideGroupKey]} />,
                key: `${displayName}`,
                handleGroupShowHide: () =>
                    props.setHideGroup({
                        ...props.hideGroup,
                        [hideGroupKey]: showGroupChildren,
                    }),
                backgroundColor: showGroupChildren ? levelColors[currentLevel] : levelColors[currentLevel - 1],
                secondaryHideGroupKey,
                isBorderless: false,
            };

            if (secondaryHideGroupKey) {
                newNode.handleGroupShowHide = () =>
                    props.setHideGroup({
                        ...props.hideGroup,
                        [hideGroupKey]: showGroupChildren,
                        [secondaryHideGroupKey]: props.hideGroup[secondaryHideGroupKey] === false,
                    });

                newNode.isBorderless = true;
            }

            return newNode;
        };

        const columnHeaders = [
            { sectionTitle: '' },
            ...Object.values(columnHeadersRow)
                .flat() // For production lines.
                .filter((x) => x.displayName)
                .map((x) => {
                    const firstLevelChildren = x?.children ?? [];
                    const finalNodes = [];

                    if (columnHeadersRow.productionLines.length > 0 && x.variableName === 'production') {
                        const informationText = translations.risk.margin.text.productionInformation;
                        return getBaseNode(x.displayName, 1, x.variableName, informationText);
                    }

                    // Childless top level nodes.
                    if (firstLevelChildren.length === 0) {
                        return getBaseNode(x.displayName, 1, x.variableName);
                    }

                    if (props.hideGroup[x.variableName] === false) {
                        (firstLevelChildren as DemeterCalculationEngineLineShortModelWithChildren[]).forEach((firstLevelChild) => {
                            const firstLevelGrandchildren = firstLevelChild.children;
                            const firstLevelDisplayName = firstLevelChild.displayName;

                            firstLevelGrandchildren?.forEach((secondLevelChildModel) => {
                                const secondLevelGrandChildren = secondLevelChildModel.children;
                                const secondLevelDisplayName = secondLevelChildModel.displayName;

                                if (props.hideGroup[firstLevelChild.variableName] !== false) {
                                    return;
                                }

                                // Childless grandchildren.
                                if (!secondLevelGrandChildren || secondLevelGrandChildren?.length === 0) {
                                    const thirdLevelNode = getBaseNode(secondLevelDisplayName, 3, secondLevelChildModel.variableName);
                                    finalNodes.push(thirdLevelNode);
                                    return;
                                }

                                // Childless great grandchildren.
                                if (secondLevelGrandChildren && props.hideGroup[secondLevelChildModel.variableName] === false) {
                                    secondLevelGrandChildren?.forEach((thirdLevelChild) => {
                                        const childlessFourthLevelNode = getBaseNode(thirdLevelChild.displayName, 3, secondLevelChildModel.variableName);
                                        finalNodes.push(childlessFourthLevelNode);
                                    });
                                }

                                // Grandchildren with children.
                                const thirdLevelNodeWithChildren = getNodeWithChildren(
                                    secondLevelDisplayName,
                                    3,
                                    secondLevelChildModel.variableName,
                                    secondLevelChildModel.combinedWith?.variableName,
                                );
                                finalNodes.push(thirdLevelNodeWithChildren);
                            });

                            // Childless children.
                            if (!firstLevelGrandchildren) {
                                const childlessFirstLevelNode = getBaseNode(firstLevelDisplayName, 2, firstLevelChild.variableName);
                                finalNodes.push(childlessFirstLevelNode);
                                return;
                            }

                            // Children with children.
                            const secondLevelNodeWithChildren = getNodeWithChildren(
                                firstLevelDisplayName,
                                2,
                                firstLevelChild.variableName,
                                firstLevelChild.combinedWith?.variableName,
                            );
                            finalNodes.push(secondLevelNodeWithChildren);
                        });
                    }

                    const firstLevelNodeWithChildren = getNodeWithChildren(x.displayName, 1, x.variableName, x.combinedWith?.variableName);
                    finalNodes.push(firstLevelNodeWithChildren);

                    return finalNodes;
                }),
        ].flat();

        return (
            <div className={styles.margin_inputs_table_sticky_column}>
                <StickyColumnGroup columnHeaders={columnHeaders} />
            </div>
        );
    }, [props.hideGroup, props.getFarmerMarginCalculatorCompositeRowsModel]);

    return stickyColumns;
};

export default MarginInputsTableStickyColumn;
