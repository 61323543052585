import React from 'react';
import styles from './Headers.module.scss';

interface IComponentHeaderProps {
    title?: string | React.ReactNode;
    subtitle?: string;
    containerClassName?: string;
}

const ComponentHeader: React.FC<IComponentHeaderProps> = (props: IComponentHeaderProps) => (
    <div className={props.containerClassName ?? styles.component_header_container}>
        {props.title && <h3 className={styles.component_header_main}>{props.title}</h3>}
        {props.subtitle && <h2 className={styles.component_header_sub}>{props.subtitle}</h2>}
    </div>
);

export default ComponentHeader;
