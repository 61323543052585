import { CalculationEngineMonthlyValueOverrides, FarmerMarginCalculatorModel, RunCalculationEngineResponse } from '../../../../../../Generated/Raven-Demeter';
import { HideGroup, MarginCompositeModel, TabEventKeyType } from '../../../RiskDefinitions';
import MarginInputRow from '../MarginInputRow';
import styles from '../MarginInputsTable.module.scss';

interface IMarginInputsTableCostDataProps {
    getFarmerMarginCalculatorCompositeRowsModel: MarginCompositeModel[];
    runCalculationEngineResponse: RunCalculationEngineResponse;
    savedFarmerMarginCalculator?: FarmerMarginCalculatorModel;
    hideGroup: HideGroup;
    runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null;
    setRunMonthlyValueOverrides: (runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null) => void;
    handleGridTabNavigation: (columnIndex: number, rowIndex: number, direction: TabEventKeyType) => void;
}

const MarginInputsTableCostData: React.FC<IMarginInputsTableCostDataProps> = (props: IMarginInputsTableCostDataProps) => {
    const firstRowModel = props.getFarmerMarginCalculatorCompositeRowsModel[0];
    const showCostTotalPriceChildren = props.hideGroup[firstRowModel.costTotalPrice.variableName] === false;
    const topLevelBackground = styles.margin_inputs_table_top_level_background;
    const level1ChildBackground = styles.margin_inputs_table_level_1_background;
    const level2ChildBackground = styles.margin_inputs_table_level_2_background;
    const level3ChildBackground = styles.margin_inputs_table_level_3_background;

    return (
        <>
            {showCostTotalPriceChildren &&
                firstRowModel.costTotalPrice.children!.map((child) => {
                    const showGroup = props.hideGroup[child.variableName] === false;
                    const level1Parent = [firstRowModel.costTotalPrice.variableName];

                    const finalRow = (
                        <MarginInputRow
                            className={!showGroup ? level1ChildBackground : level2ChildBackground}
                            getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                            ancestors={level1Parent}
                            hideGroup={props.hideGroup}
                            runCalculationEngineResponse={props.runCalculationEngineResponse}
                            disabled={showGroup}
                            fieldName={child.variableName}
                            handleGridTabNavigation={props.handleGridTabNavigation}
                            runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                            setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                            savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                        />
                    );

                    if (showGroup) {
                        return (
                            <>
                                {child.children?.map((secondLevelChild) => {
                                    const combinationVariableName =
                                        child.children?.find((x) => x.combinedWith?.variableName === secondLevelChild.variableName)?.variableName ?? '';
                                    const showSecondaryGroup =
                                        props.hideGroup[secondLevelChild.variableName] === false || props.hideGroup[combinationVariableName] === false;

                                    const level2Parents = [child.variableName, child.combinedWith?.variableName ?? ''];
                                    const secondaryRow = (
                                        <MarginInputRow
                                            className={
                                                props.hideGroup[secondLevelChild.variableName] !== false && props.hideGroup[combinationVariableName] !== false
                                                    ? level2ChildBackground
                                                    : level3ChildBackground
                                            }
                                            getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                                            ancestors={[...level1Parent, ...level2Parents]}
                                            hideGroup={props.hideGroup}
                                            runCalculationEngineResponse={props.runCalculationEngineResponse}
                                            disabled={showSecondaryGroup}
                                            fieldName={secondLevelChild.variableName}
                                            handleGridTabNavigation={props.handleGridTabNavigation}
                                            runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                                            setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                                            savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                                        />
                                    );
                                    if (showSecondaryGroup) {
                                        return (
                                            <>
                                                {secondLevelChild.children?.map((thirdLevelChild) => {
                                                    const thirdLeveGroupIsHidden = props.hideGroup[thirdLevelChild.variableName] === false;

                                                    return (
                                                        <MarginInputRow
                                                            className={styles.margin_inputs_table_level_3_background}
                                                            getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                                                            ancestors={[
                                                                ...level1Parent,
                                                                ...level2Parents,
                                                                secondLevelChild.variableName,
                                                                secondLevelChild.combinedWith?.variableName ?? '',
                                                            ]}
                                                            hideGroup={props.hideGroup}
                                                            runCalculationEngineResponse={props.runCalculationEngineResponse}
                                                            disabled={thirdLeveGroupIsHidden}
                                                            fieldName={thirdLevelChild.variableName}
                                                            handleGridTabNavigation={props.handleGridTabNavigation}
                                                            runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                                                            setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                                                            savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                                                        />
                                                    );
                                                })}
                                                {secondaryRow}
                                            </>
                                        );
                                    }

                                    return secondaryRow;
                                })}

                                {finalRow}
                            </>
                        );
                    }

                    return finalRow;
                })}

            <MarginInputRow
                className={showCostTotalPriceChildren ? level1ChildBackground : topLevelBackground}
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                hideGroup={props.hideGroup}
                disabled={showCostTotalPriceChildren}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                fieldName={firstRowModel.costTotalPrice.variableName}
                handleGridTabNavigation={props.handleGridTabNavigation}
                runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
            />
        </>
    );
};

export default MarginInputsTableCostData;
