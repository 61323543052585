import { useMemo } from 'react';
import { MarketIndicatorTemplateType } from '../../../../../Generated/Raven-Demeter';
import JoinedSelectionButtons from '../../../../Components/Form/Buttons/JoinedSelectionButtons/JoinedSelectionButtons';
import CheckboxDropdown from '../../../../Components/Form/Inputs/CheckboxDropdown';
import TextInput from '../../../../Components/Form/Inputs/TextInput';
import ComponentHeader from '../../../../Components/Headers/ComponentHeader';
import useSearchParameters from '../../../../Components/Navigation/Hooks/useSearchParametersHook';
import RegexValidators from '../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { EventDataTargetsEnum } from '../../../../Services/Logging/DataLayerDefinitions';
import {
    ForwardCurveContractInputType,
    ForwardCurveParameters,
    HistoricalParameters,
    ManualEntryParameters,
    monthsOptions,
    SlidingWindowSeasonalParameters,
    UpsertMarketIndicatorFactorRequest,
} from '../MarketIndicatorsManagementDefinitions';
import styles from './MarketIndicatorFactorPage.module.scss';
import {
    defaultLongTermContractMonth,
    defaultLongTermContractNumber,
    defaultMonthsFlatOutlook,
    defaultMonthsInverseCorrelation,
    numberOfThresholds,
} from './MarketIndicatorFactorPageDefault';
import MarketFactorCalculationTypeDropdown from './MarketIndicatorsFactorFields/Common/MarketFactorCalculationTypeDropdown';
import MarketFactorCorrelationDropdown from './MarketIndicatorsFactorFields/Common/MarketFactorCorrelationDropdown';
import MarketFactorMultiplierTextInput from './MarketIndicatorsFactorFields/Common/MarketFactorMultiplierTextInput';
import MarketFactorParameterTextInput from './MarketIndicatorsFactorFields/Common/MarketFactorParameterTextInput';
import MarketFactorTemplateTypeDropdown from './MarketIndicatorsFactorFields/Common/MarketFactorTemplateTypeDropdown';
import MarketFactorYearsOfDataDropdown from './MarketIndicatorsFactorFields/Common/MarketFactorYearsOfDataDropdown';
import MarketFactorForecastDropdown from './MarketIndicatorsFactorFields/Forecast/MarketFactorForecastDropdown';
import MarketFactorLongTermContractMonthDropdown from './MarketIndicatorsFactorFields/ForwardCurve/MarketFactorLongTermContractMonthDropdown';
import MarketFactorLongTermContractNumberDropdown from './MarketIndicatorsFactorFields/ForwardCurve/MarketFactorLongTermContractNumberDropdown';
import MarketFactorMonthsOfDataDropdown from './MarketIndicatorsFactorFields/ForwardCurve/MarketFactorMonthsOfDataDropdown';
import MarketFactorShortTermContractNumberDropdown from './MarketIndicatorsFactorFields/ForwardCurve/MarketFactorShortTermContractNumberDropdown';
import MarketFactorManualEntryArea from './MarketIndicatorsFactorFields/Manual/MarketFactorManualEntryArea';
import MarketFactorEndMonthDropdown from './MarketIndicatorsFactorFields/Seasonal/MarketFactorEndMonthDropdown';
import MarketFactorStartMonthDropdown from './MarketIndicatorsFactorFields/Seasonal/MarketFactorStartMonthDropdown';
import MarketFactorWindowEndDayDropdown from './MarketIndicatorsFactorFields/SlidingWindowSeasonal/MarketFactorWindowEndDayDropdown';
import MarketFactorWindowStartDayDropdown from './MarketIndicatorsFactorFields/SlidingWindowSeasonal/MarketFactorWindowStartDayDropdown';

interface IMarketIndicatorFactorTemplateAreaProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
    forwardCurveContractInput: ForwardCurveContractInputType;
    setForwardCurveContractInput: (forwardCurveContractInputType: ForwardCurveContractInputType) => void;
    resetSelectionsByTemplateType: (value: MarketIndicatorTemplateType) => void;
    multiplierColumns?: JSX.Element[] | null;
}

const MarketIndicatorFactorTemplateArea: React.FC<IMarketIndicatorFactorTemplateAreaProps> = (props: IMarketIndicatorFactorTemplateAreaProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const [searchParameters] = useSearchParameters();
    const isCombinationParent = searchParameters.tab === 'parent';

    const longTermContractOptions = useMemo(
        () => [
            {
                label: translations.marketIndicatorsManagement.fields.contractNumber,
                value: 'contractNumber',
            },
            {
                label: translations.marketIndicatorsManagement.fields.contractMonths,
                value: 'contractMonths',
            },
        ],
        [translations],
    );

    const marketFactorThresholds = useMemo(() => {
        if (
            (props.upsertMarketIndicatorFactorRequest?.templateType !== MarketIndicatorTemplateType.Historical &&
                props.upsertMarketIndicatorFactorRequest?.templateType !== MarketIndicatorTemplateType.Seasonal) ||
            !(props.upsertMarketIndicatorFactorRequest.parameters as HistoricalParameters).subFactorCalculationTypes
        ) {
            return null;
        }

        const thresholds = [];
        for (let index = 0; index < numberOfThresholds; index += 1) {
            thresholds.push(
                <MarketFactorCalculationTypeDropdown
                    key={`calculationType${index}`}
                    calculationTypeIndex={index}
                    upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                    setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                />,
            );
        }

        return thresholds;
    }, [props.upsertMarketIndicatorFactorRequest?.templateType, props.upsertMarketIndicatorFactorRequest?.parameters]);

    const shouldIncludeYearsOfDataDropdown = useMemo(() => {
        if (!props.upsertMarketIndicatorFactorRequest?.templateType) {
            return false;
        }

        return (
            [
                MarketIndicatorTemplateType.AverageSeasonal,
                MarketIndicatorTemplateType.Seasonal,
                MarketIndicatorTemplateType.Historical,
                MarketIndicatorTemplateType.SlidingWindowSeasonal,
                MarketIndicatorTemplateType.Decile,
            ] as MarketIndicatorTemplateType[]
        ).includes(props.upsertMarketIndicatorFactorRequest?.templateType);
    }, [props.upsertMarketIndicatorFactorRequest?.templateType]);

    return (
        <div className={styles.indicator_add_and_edit_template}>
            <ComponentHeader title={translations.marketIndicatorsManagement.headers.template} />
            <div className={styles.indicator_add_and_edit_row}>
                <MarketFactorTemplateTypeDropdown
                    value={props.upsertMarketIndicatorFactorRequest.templateType}
                    handleOptionChange={props.resetSelectionsByTemplateType}
                />
                {props.upsertMarketIndicatorFactorRequest.templateType !== MarketIndicatorTemplateType.Combination && (
                    <MarketFactorCorrelationDropdown
                        upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                        setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                    />
                )}
            </div>

            {isCombinationParent && <div className={styles.indicator_add_and_edit_multipliers}>{props.multiplierColumns}</div>}
            {props.upsertMarketIndicatorFactorRequest.templateType !== MarketIndicatorTemplateType.Combination && (
                <div className={styles.indicator_add_and_edit_row}>
                    {!searchParameters.tab && (
                        <MarketFactorMultiplierTextInput
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                        />
                    )}
                </div>
            )}

            {shouldIncludeYearsOfDataDropdown && (
                <div className={styles.indicator_add_and_edit_row}>
                    <MarketFactorYearsOfDataDropdown
                        upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                        setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                    />
                </div>
            )}

            {props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Forecast && (
                <div className={styles.indicator_add_and_edit_row}>
                    <MarketFactorForecastDropdown
                        upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                        setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                    />
                </div>
            )}

            {(props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Historical ||
                props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Seasonal) && (
                <div className={styles.indicator_add_and_edit_row}>{marketFactorThresholds}</div>
            )}

            {(props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.AverageSeasonal ||
                props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Seasonal) && (
                <div className={styles.indicator_add_and_edit_row}>
                    <MarketFactorStartMonthDropdown
                        upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                        setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                    />
                    <MarketFactorEndMonthDropdown
                        upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                        setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                    />
                </div>
            )}
            {props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.SlidingWindowSeasonal && (
                <>
                    <div className={styles.indicator_add_and_edit_row}>
                        <MarketFactorWindowStartDayDropdown
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                        />
                        <MarketFactorWindowEndDayDropdown
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                        />
                    </div>
                    <div className={styles.indicator_add_and_edit_row}>
                        <div className={styles.indicator_add_and_edit_dropdown}>
                            <CheckboxDropdown
                                options={monthsOptions}
                                handleOptionSelect={(value) =>
                                    props.setUpsertMarketIndicatorFactorRequest({
                                        ...props.upsertMarketIndicatorFactorRequest,
                                        parameters: {
                                            ...props.upsertMarketIndicatorFactorRequest.parameters,
                                            monthsFlatOutlook: value.sort().join(),
                                        },
                                    })
                                }
                                values={(
                                    (props.upsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).monthsFlatOutlook ??
                                    defaultMonthsFlatOutlook
                                ).split(',')}
                                label={translations.marketIndicatorsManagement.fields.monthsFlatOutlook}
                                placeholder={
                                    (props.upsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).monthsFlatOutlook ??
                                    defaultMonthsFlatOutlook
                                }
                            />
                        </div>
                        <div className={styles.indicator_add_and_edit_dropdown}>
                            <CheckboxDropdown
                                options={monthsOptions}
                                handleOptionSelect={(value) =>
                                    props.setUpsertMarketIndicatorFactorRequest({
                                        ...props.upsertMarketIndicatorFactorRequest,
                                        parameters: {
                                            ...props.upsertMarketIndicatorFactorRequest.parameters,
                                            monthsInverseCorrelation: value.sort().join(),
                                        },
                                    })
                                }
                                values={(
                                    (props.upsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).monthsInverseCorrelation ??
                                    defaultMonthsInverseCorrelation
                                ).split(',')}
                                label={translations.marketIndicatorsManagement.fields.monthsInverseCorrelation}
                                placeholder={
                                    (props.upsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).monthsInverseCorrelation ??
                                    defaultMonthsInverseCorrelation
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.indicator_add_and_edit_row}>
                        <TextInput
                            title={translations.marketIndicatorsManagement.fields.imageLink}
                            value={(props.upsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).imageUrl ?? ''}
                            handleTextChange={(value: string) =>
                                props.setUpsertMarketIndicatorFactorRequest({
                                    ...props.upsertMarketIndicatorFactorRequest,
                                    parameters: {
                                        ...props.upsertMarketIndicatorFactorRequest.parameters,
                                        imageUrl: value,
                                    },
                                })
                            }
                        />
                    </div>
                </>
            )}
            {props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.ForwardCurve && (
                <>
                    <div className={styles.indicator_add_and_edit_row}>
                        <MarketFactorShortTermContractNumberDropdown
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                        />
                    </div>
                    <div className={styles.indicator_add_and_edit_row}>
                        <p>{translations.marketIndicatorsManagement.fields.longTerm}</p>
                    </div>
                    <div className={styles.indicator_add_and_edit_long_term}>
                        <JoinedSelectionButtons
                            name={EventDataTargetsEnum.MarketIndicatorFactor}
                            options={longTermContractOptions}
                            handleSelectionChange={(value) => {
                                const newUpsertMarketIndicatorFactorRequest = { ...props.upsertMarketIndicatorFactorRequest };
                                const newForwardCurveParameters = newUpsertMarketIndicatorFactorRequest.parameters as ForwardCurveParameters;

                                if (value === 'contractNumber') {
                                    newForwardCurveParameters.longTermContractNumber = `${defaultLongTermContractNumber}`;
                                    delete newForwardCurveParameters.longTermContractMonth;
                                } else {
                                    newForwardCurveParameters.longTermContractMonth = `${defaultLongTermContractMonth}`;
                                    delete newForwardCurveParameters.longTermContractNumber;
                                }

                                props.setUpsertMarketIndicatorFactorRequest(newUpsertMarketIndicatorFactorRequest);
                                props.setForwardCurveContractInput(value as ForwardCurveContractInputType);
                            }}
                            selection={props.forwardCurveContractInput!}
                        />
                    </div>
                    <div className={styles.indicator_add_and_edit_row}>
                        {props.forwardCurveContractInput === 'contractNumber' ? (
                            <MarketFactorLongTermContractNumberDropdown
                                upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                                setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                            />
                        ) : (
                            <MarketFactorLongTermContractMonthDropdown
                                upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                                setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                            />
                        )}
                    </div>
                    <div className={styles.indicator_add_and_edit_row}>
                        <MarketFactorMonthsOfDataDropdown
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                        />
                    </div>
                </>
            )}
            {props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.ManualEntry && (
                <>
                    <div className={styles.indicator_add_and_edit_row}>
                        <TextInput
                            title={translations.marketIndicatorsManagement.fields.imageLink}
                            value={(props.upsertMarketIndicatorFactorRequest.parameters as ManualEntryParameters).imageUrl ?? ''}
                            handleTextChange={(value: string) =>
                                props.setUpsertMarketIndicatorFactorRequest({
                                    ...props.upsertMarketIndicatorFactorRequest,
                                    parameters: {
                                        ...props.upsertMarketIndicatorFactorRequest.parameters,
                                        imageUrl: value,
                                    },
                                })
                            }
                        />
                    </div>
                    <div className={styles.indicator_add_and_edit_row}>
                        <MarketFactorManualEntryArea
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                        />
                    </div>
                </>
            )}
            {props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Technical && (
                <>
                    <div className={styles.indicator_add_and_edit_row_spacer} />
                    <div className={styles.indicator_add_and_edit_row_technical_input}>
                        <MarketFactorParameterTextInput
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                            title={translations.marketIndicatorsManagement.fields.daysToAverage1}
                            field="daysToAverage1"
                        />
                        <MarketFactorParameterTextInput
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                            title={translations.marketIndicatorsManagement.fields.daysToAverage2}
                            field="daysToAverage2"
                        />
                        <MarketFactorParameterTextInput
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                            title={translations.marketIndicatorsManagement.fields.daysToAverage3}
                            field="daysToAverage3"
                        />
                    </div>
                    <div className={styles.indicator_add_and_edit_row_technical_input}>
                        <MarketFactorParameterTextInput
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                            title={translations.marketIndicatorsManagement.fields.shortTermDays1}
                            field="shortTermDays1"
                        />
                        <MarketFactorParameterTextInput
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                            title={translations.marketIndicatorsManagement.fields.shortTermDays2}
                            field="shortTermDays2"
                        />
                        <MarketFactorParameterTextInput
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                            title={translations.marketIndicatorsManagement.fields.shortTermDays3}
                            field="shortTermDays3"
                        />
                    </div>
                    <div className={styles.indicator_add_and_edit_row_technical_input}>
                        <MarketFactorParameterTextInput
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                            title={translations.marketIndicatorsManagement.fields.longTermDays1}
                            field="longTermDays1"
                        />
                        <MarketFactorParameterTextInput
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                            title={translations.marketIndicatorsManagement.fields.longTermDays2}
                            field="longTermDays2"
                        />
                        <MarketFactorParameterTextInput
                            upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                            title={translations.marketIndicatorsManagement.fields.longTermDays3}
                            field="longTermDays3"
                        />
                    </div>
                </>
            )}
            <div className={styles.indicator_add_and_edit_row}>
                {props.upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Decile && (
                    <MarketFactorParameterTextInput
                        upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                        setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                        title={translations.marketIndicatorsManagement.fields.oneYearWeightedPercent}
                        field="oneYearWeightedPercent"
                        validation={RegexValidators.PositiveNumberZeroThroughOneHundred}
                        errorMessage={translations.marketIndicatorsManagement.messages.positiveNumberZeroThroughOneHundred}
                    />
                )}
            </div>
        </div>
    );
};

export default MarketIndicatorFactorTemplateArea;
