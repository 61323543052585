/* eslint-disable class-methods-use-this */
import {
    DemeterDataFrequency,
    DemeterRegion,
    DemeterSubRegion,
    DemeterTableDefinitionCommodityModel,
    DemeterTableDefinitionCommodityRegionModel,
    DemeterTableDefinitionGroupModel,
    DemeterTableDefinitionModel,
    DemeterTableDefinitionRegionModel,
    DemeterTableDefinitionSubCommodityModel,
} from '../../../Generated/Raven-Demeter';
import languageService from '../../Services/Language/LanguageService';
import { IMenuItem, IRegionCommodityValues } from './MenuSelectorItem';

class RegionCommodityGroupingService {
    getMenuItemsByCommodities = (
        commodities: DemeterTableDefinitionCommodityModel[],
        selection: IRegionCommodityValues,
        showMainRegionsAndCommoditiesGroupsOnly: boolean,
    ): IMenuItem[] => {
        if (!commodities.length) {
            return [];
        }
        return commodities.map((item, index) => ({
            id: `${item.order + index}`,
            regionCommodityValues: {
                region: null,
                subRegion: null,
                commodity: item.commodity ?? null,
                subCommodity: '',
                topLevelGroup: item.topLevelGroup,
                topLevelRegion: selection.region,
                extraParameters: '',
                dataFrequency: '',
                isSubSelection: false,
            },
            label: languageService.translate(item.displayName),
            hasSubGroupBreakBefore: false,
            isSubGroupHeader: false,
            isSelectedItem: selection.topLevelGroup === item.topLevelGroup,
            dropdownOptions:
                item.demeterTableDefinitionSubCommodities.map((dropdownItem, i) => ({
                    id: `${item.order + index + dropdownItem.order + i}`,
                    regionCommodityValues: {
                        region: null,
                        subRegion: null,
                        commodity: item.commodity ?? null,
                        subCommodity: dropdownItem.commodity ?? '',
                        topLevelGroup: item.topLevelGroup,
                        topLevelRegion: selection.region,
                        extraParameters: '',
                        dataFrequency: '',
                        isSubSelection: true,
                    },
                    label: languageService.translate(dropdownItem.displayName),
                    hasSubGroupBreakBefore: dropdownItem.hasSubGroupBreakBefore,
                    isSubGroupHeader: dropdownItem.isSubGroupHeader,
                    isSelectedItem: !showMainRegionsAndCommoditiesGroupsOnly && !!dropdownItem.commodity && dropdownItem.commodity === selection.subCommodity,
                    dropdownOptions: [],
                })) ?? [],
        }));
    };

    getMenuItemsForCommodityRegions = (
        regions: DemeterTableDefinitionCommodityRegionModel[],
        selection: IRegionCommodityValues,
        showMainRegionsAndCommoditiesGroupsOnly: boolean,
    ): IMenuItem[] => {
        const shownRegions = regions.filter((x) => !x.showInOtherList);
        const othersRegions = regions
            .filter((x) => x.showInOtherList)
            .sort((region1, region2) => {
                const displayName1: string = region1?.displayName || '';
                const displayName2: string = region2?.displayName || '';
                return displayName1?.localeCompare(displayName2);
            });

        const regionsResult: IMenuItem[] =
            shownRegions.map((item, index) => ({
                id: `${item.order + index}`,
                regionCommodityValues: {
                    region: item.region ?? null,
                    subRegion: '',
                    commodity: null,
                    subCommodity: null,
                    topLevelGroup: selection.topLevelGroup,
                    topLevelRegion: item.region ?? null,
                    extraParameters: '',
                    dataFrequency: '',
                    isSubSelection: false,
                },
                label: languageService.translate(item.displayName),
                hasSubGroupBreakBefore: false,
                isSubGroupHeader: false,
                isSelectedItem: item.region === selection.region,
                isDropdownSelection: false,
                dropdownOptions:
                    item.subRegions?.map((dropdownItem, i) => ({
                        id: `${item.order + index + i}`,
                        regionCommodityValues: {
                            region: item.region ?? null,
                            subRegion: dropdownItem ?? null,
                            commodity: null,
                            subCommodity: null,
                            topLevelGroup: selection.topLevelGroup,
                            topLevelRegion: item.region ?? null,
                            extraParameters: '',
                            dataFrequency: '',
                            isSubSelection: true,
                        },
                        label: languageService.translate(dropdownItem),
                        hasSubGroupBreakBefore: false,
                        isSubGroupHeader: false,
                        isSelectedItem: !showMainRegionsAndCommoditiesGroupsOnly && dropdownItem === selection.subRegion,
                        isDropdownSelection: true,
                        dropdownOptions: [],
                    })) ?? [],
            })) ?? [];

        if (othersRegions.length) {
            const othersTab: IMenuItem[] = [
                {
                    id: `${othersRegions.length}`,
                    regionCommodityValues: {
                        region: null,
                        subRegion: '',
                        commodity: null,
                        subCommodity: null,
                        topLevelGroup: selection.topLevelGroup,
                        topLevelRegion: DemeterRegion.Other,
                        extraParameters: '',
                        dataFrequency: '',
                        isSubSelection: false,
                    },
                    label: languageService.translate('Others'),
                    hasSubGroupBreakBefore: false,
                    isSubGroupHeader: false,
                    isSelectedItem: othersRegions.some((x) => x.region === selection.region),
                    dropdownOptions: othersRegions.map((subItem) => ({
                        id: `${othersRegions.length + subItem.order}`,
                        regionCommodityValues: {
                            region: subItem.region ?? null,
                            subRegion: '',
                            commodity: null,
                            subCommodity: null,
                            topLevelGroup: selection.topLevelGroup,
                            topLevelRegion: DemeterRegion.Other,
                            extraParameters: '',
                            dataFrequency: '',
                            isSubSelection: true,
                        },
                        label: languageService.translate(subItem.displayName),
                        hasSubGroupBreakBefore: false,
                        isSubGroupHeader: false,
                        isSelectedItem: subItem.region === selection.region,
                        dropdownOptions: [],
                    })),
                },
            ];

            return [...regionsResult, ...othersTab];
        }
        return regionsResult;
    };

    getMenuItemsByRegions = (
        regions: DemeterTableDefinitionRegionModel[],
        selection: IRegionCommodityValues,
        showMainRegionsAndCommoditiesGroupsOnly: boolean,
    ): IMenuItem[] => {
        const shownRegions = regions.filter((x) => !x.showInOtherList);
        const othersRegions = regions
            .filter((x) => x.showInOtherList)
            .sort((region1, region2) => {
                const displayName1: string = region1?.displayName || '';
                const displayName2: string = region2?.displayName || '';
                return displayName1?.localeCompare(displayName2);
            });

        const regionsResult: IMenuItem[] =
            shownRegions.map((item, index) => ({
                id: `${item.order + index}`,
                regionCommodityValues: {
                    region: item.region ?? null,
                    subRegion: '',
                    commodity: null,
                    subCommodity: null,
                    topLevelGroup: selection.topLevelGroup,
                    topLevelRegion: item.region ?? null,
                    extraParameters: '',
                    dataFrequency: '',
                    isSubSelection: false,
                },
                label: languageService.translate(item.displayName),
                hasSubGroupBreakBefore: false,
                isSubGroupHeader: false,
                isSelectedItem: item.region === selection.region,
                isDropdownSelection: false,
                dropdownOptions:
                    item.subRegions?.map((dropdownItem, i) => ({
                        id: `${item.order + index + i}`,
                        regionCommodityValues: {
                            region: item.region ?? null,
                            subRegion: dropdownItem ?? null,
                            commodity: null,
                            subCommodity: null,
                            topLevelGroup: selection.topLevelGroup,
                            topLevelRegion: item.region ?? null,
                            extraParameters: '',
                            dataFrequency: '',
                            isSubSelection: true,
                        },
                        label: languageService.translate(dropdownItem),
                        hasSubGroupBreakBefore: false,
                        isSubGroupHeader: false,
                        isSelectedItem: !showMainRegionsAndCommoditiesGroupsOnly && dropdownItem === selection.subRegion,
                        isDropdownSelection: true,
                        dropdownOptions: [],
                    })) ?? [],
            })) ?? [];

        if (othersRegions.length) {
            const othersTab: IMenuItem[] = [
                {
                    id: `${othersRegions.length}`,
                    regionCommodityValues: {
                        region: null,
                        subRegion: '',
                        commodity: null,
                        subCommodity: null,
                        topLevelGroup: selection.topLevelGroup,
                        topLevelRegion: DemeterRegion.Other,
                        extraParameters: '',
                        dataFrequency: '',
                        isSubSelection: false,
                    },
                    label: languageService.translate('Others'),
                    hasSubGroupBreakBefore: false,
                    isSubGroupHeader: false,
                    isSelectedItem: othersRegions.some((x) => x.region === selection.region),
                    dropdownOptions: othersRegions.map((subItem) => ({
                        id: `${othersRegions.length + subItem.order}`,
                        regionCommodityValues: {
                            region: subItem.region ?? null,
                            subRegion: '',
                            commodity: null,
                            subCommodity: null,
                            topLevelGroup: selection.topLevelGroup,
                            topLevelRegion: DemeterRegion.Other,
                            extraParameters: '',
                            dataFrequency: '',
                            isSubSelection: true,
                        },
                        label: languageService.translate(subItem.displayName),
                        hasSubGroupBreakBefore: false,
                        isSubGroupHeader: false,
                        isSelectedItem: subItem.region === selection.region,
                        dropdownOptions: [],
                    })),
                },
            ];

            return [...regionsResult, ...othersTab];
        }
        return regionsResult;
    };

    getMenuItemsByRegionCommodities = (
        commodities: DemeterTableDefinitionGroupModel[],
        selection: IRegionCommodityValues,
        showMainRegionsAndCommoditiesGroupsOnly: boolean,
    ): IMenuItem[] => {
        if (!commodities.length) {
            return [];
        }

        return commodities.map((item, index) => ({
            id: `${item.order + index}`,
            regionCommodityValues: {
                region: null,
                subRegion: null,
                commodity: item.commodity ?? null,
                subCommodity: '',
                topLevelGroup: item.topLevelGroup ?? null,
                topLevelRegion: selection.region ?? null,
                extraParameters: item.extraParameters ?? '',
                dataFrequency: '',
                isSubSelection: false,
            },
            label: languageService.translate(item.displayName),
            hasSubGroupBreakBefore: false,
            isSubGroupHeader: false,
            isSelectedItem: selection.topLevelGroup === item.topLevelGroup,
            dropdownOptions:
                item.demeterTableDefinitions
                    .filter((x) => !selection.subRegion || x.subRegions?.includes(selection.subRegion as DemeterSubRegion))
                    .map((dropdownItem, i) => ({
                        id: `${item.order + index + dropdownItem.order + i}`,
                        regionCommodityValues: {
                            region: null,
                            subRegion: null,
                            commodity: item.commodity ?? null,
                            subCommodity: dropdownItem.commodity ?? '',
                            topLevelGroup: item.topLevelGroup ?? null,
                            topLevelRegion: selection.region ?? null,
                            extraParameters: dropdownItem.extraParameters ?? '',
                            dataFrequency: '',
                            isSubSelection: true,
                        },
                        label: languageService.translate(dropdownItem.displayName),
                        hasSubGroupBreakBefore: dropdownItem.hasSubGroupBreakBefore,
                        isSubGroupHeader: dropdownItem.isSubGroupHeader,
                        isSelectedItem:
                            !showMainRegionsAndCommoditiesGroupsOnly &&
                            dropdownItem.commodity === selection.subCommodity &&
                            (!dropdownItem.extraParameters || dropdownItem.extraParameters === selection.extraParameters),
                        dropdownOptions: [],
                    })) ?? [],
        }));
    };

    buildSelectionObjectForCommodity = (
        selection: IRegionCommodityValues,
        regions: DemeterTableDefinitionCommodityRegionModel[],
        commodity: DemeterTableDefinitionCommodityModel,
        subCommodity?: DemeterTableDefinitionSubCommodityModel,
    ): IRegionCommodityValues => {
        if (!regions.length) {
            return selection;
        }

        const selectedRegion =
            regions.find((x) => x.region === selection.topLevelRegion || (selection.topLevelRegion === DemeterRegion.Other && x.region === selection.region)) ??
            regions[0];
        const selectedSubRegion = selectedRegion?.subRegions?.find((x) => x === selection.subRegion);

        return {
            region: selectedRegion?.region ?? null,
            subRegion: selectedSubRegion ?? null,
            commodity: commodity.commodity ? selectedRegion?.commodity ?? commodity.commodity : null,
            subCommodity: subCommodity?.commodity ?? null,
            topLevelGroup: commodity.topLevelGroup,
            topLevelRegion: !selectedRegion.showInOtherList ? selectedRegion?.region ?? null : DemeterRegion.Other,
            extraParameters: selectedRegion?.extraParameters ?? '',
            dataFrequency: selectedRegion?.dataFrequencies?.length ? selectedRegion?.dataFrequencies[0] : DemeterDataFrequency.Monthly,
            isSubSelection: !!subCommodity || !!selectedSubRegion,
        };
    };

    getMenuSelectionByCommodity = (commodities: DemeterTableDefinitionCommodityModel[], selection: IRegionCommodityValues) => {
        if (!commodities.length) {
            return selection;
        }

        const selectedCommodity = commodities.find((x) => x.topLevelGroup === selection.topLevelGroup);
        if (!selectedCommodity) {
            const firstAvailableCommodity = commodities[0];
            if (!firstAvailableCommodity.commodity) {
                return this.buildSelectionObjectForCommodity(
                    selection,
                    firstAvailableCommodity.demeterTableDefinitionSubCommodities[0].demeterTableDefinitionRegions,
                    firstAvailableCommodity,
                    firstAvailableCommodity.demeterTableDefinitionSubCommodities[0],
                );
            }

            return this.buildSelectionObjectForCommodity(selection, firstAvailableCommodity.demeterTableDefinitionRegions, firstAvailableCommodity);
        }

        const selectedCommoditySubCommodity = selectedCommodity.demeterTableDefinitionSubCommodities.find(
            (x) => selection.subCommodity && x.commodity === selection.subCommodity && x.topLevelGroup === selection.topLevelGroup,
        );

        if (!selectedCommoditySubCommodity) {
            return this.buildSelectionObjectForCommodity(selection, selectedCommodity.demeterTableDefinitionRegions, selectedCommodity);
        }

        return this.buildSelectionObjectForCommodity(
            selection,
            selectedCommoditySubCommodity.demeterTableDefinitionRegions,
            selectedCommodity,
            selectedCommoditySubCommodity,
        );
    };

    buildSelectionObjectForRegion = (
        selection: IRegionCommodityValues,
        region: DemeterTableDefinitionRegionModel,
        subRegion: string | undefined,
        commodity: DemeterTableDefinitionGroupModel,
        subCommodity?: DemeterTableDefinitionModel,
    ): IRegionCommodityValues => ({
        region: region?.region ?? null,
        subRegion: subRegion || (selection.subRegion ?? null),
        commodity: commodity.commodity ?? null,
        subCommodity: subCommodity?.commodity ?? null,
        topLevelGroup: commodity.topLevelGroup ?? null,
        topLevelRegion: region?.region ?? null,
        extraParameters: subCommodity ? subCommodity.extraParameters ?? '' : commodity.extraParameters ?? '',
        dataFrequency: commodity?.dataFrequencies?.length ? commodity?.dataFrequencies[0] : DemeterDataFrequency.Monthly,
        isSubSelection: !!subCommodity || !!subRegion,
    });

    getMenuSelectionByRegion = (regions: DemeterTableDefinitionRegionModel[], selection: IRegionCommodityValues) => {
        const selectedRegion = regions.find(
            (x) => x.region === selection.topLevelRegion || (selection.topLevelRegion === DemeterRegion.Other && x.region === selection.region),
        );
        const selectedSubRegion = selectedRegion?.subRegions?.find((x) => x === selection.subRegion);

        if (!selectedRegion) {
            const firstAvailableRegion = regions[0];
            const firstAvailableCommodity = firstAvailableRegion.demeterTableDefinitionGroups[0];
            if (!firstAvailableCommodity.commodity) {
                const firstAvailableSubCommodity = firstAvailableCommodity.demeterTableDefinitions[0];

                return this.buildSelectionObjectForRegion(selection, firstAvailableRegion, '', firstAvailableCommodity, firstAvailableSubCommodity);
            }

            return this.buildSelectionObjectForRegion(selection, firstAvailableRegion, '', firstAvailableCommodity);
        }

        const availableCommodities = selectedRegion.demeterTableDefinitionGroups.filter((x) => !selectedSubRegion || x.subRegions?.includes(selectedSubRegion));
        const selectedCommodity = availableCommodities.find((x) => x.topLevelGroup === selection.topLevelGroup);
        if (!selectedCommodity) {
            const firstAvailableCommodity = availableCommodities[0];
            if (!firstAvailableCommodity.commodity) {
                const firstAvailableSubCommodity = firstAvailableCommodity.demeterTableDefinitions[0];

                return this.buildSelectionObjectForRegion(selection, selectedRegion, selectedSubRegion, firstAvailableCommodity, firstAvailableSubCommodity);
            }

            return this.buildSelectionObjectForRegion(selection, selectedRegion, selectedSubRegion, firstAvailableCommodity);
        }

        const availableSubCommodities = selectedCommodity.demeterTableDefinitions.filter(
            (x) => !selectedSubRegion || x.subRegions?.includes(selectedSubRegion),
        );
        const selectedSubCommodity = availableSubCommodities.find(
            (x) =>
                x.topLevelGroup === selection.topLevelGroup &&
                x.commodity === selection.subCommodity &&
                (!x.extraParameters || x.extraParameters === selection.extraParameters),
        );
        if (!selectedCommodity.commodity) {
            if (!selectedSubCommodity && !availableSubCommodities.length) {
                return this.buildSelectionObjectForRegion(selection, selectedRegion, '', selectedCommodity, selectedCommodity.demeterTableDefinitions[0]);
            }

            if (!selectedSubCommodity) {
                return this.buildSelectionObjectForRegion(selection, selectedRegion, selectedSubRegion, selectedCommodity, availableSubCommodities[0]);
            }
        }

        return this.buildSelectionObjectForRegion(selection, selectedRegion, selectedSubRegion, selectedCommodity, selectedSubCommodity);
    };

    getSelectedBreadCrumbLabel = (menuItems: IMenuItem[], isCommodity: boolean): string => {
        const breadCrumb = menuItems.reduce(
            (breadCrumbObject: IRegionCommodityValues, menuItem) => {
                if (menuItem.isSelectedItem) {
                    const subItem = menuItem.dropdownOptions.find((x) => x.isSelectedItem);
                    const checkIfRegionInOthers = !menuItem.regionCommodityValues.region && subItem?.regionCommodityValues.region ? subItem : menuItem;
                    return {
                        region: checkIfRegionInOthers.regionCommodityValues.region ? checkIfRegionInOthers.label : breadCrumbObject.region,
                        commodity:
                            menuItem.regionCommodityValues.commodity ||
                            (!menuItem.regionCommodityValues.commodity && menuItem.label === menuItem.regionCommodityValues.topLevelGroup)
                                ? menuItem.label
                                : breadCrumbObject.commodity,
                        topLevelGroup: menuItem.regionCommodityValues.topLevelGroup ? menuItem.label : breadCrumbObject.topLevelGroup,
                        subRegion: subItem?.regionCommodityValues.subRegion ? subItem.label : breadCrumbObject.subRegion,
                        subCommodity: subItem?.regionCommodityValues.subCommodity ? subItem.label : breadCrumbObject.subCommodity,
                        topLevelRegion: menuItem.regionCommodityValues.topLevelRegion ? menuItem.label : breadCrumbObject.topLevelRegion,
                        extraParameters: '',
                        dataFrequency: '',
                        isSubSelection: false,
                    };
                }

                return breadCrumbObject;
            },
            {
                region: null,
                subRegion: null,
                commodity: null,
                subCommodity: null,
                topLevelGroup: null,
                topLevelRegion: null,
                extraParameters: '',
                dataFrequency: '',
                isSubSelection: false,
            },
        );

        const commodityString = `${breadCrumb.commodity}${breadCrumb.subCommodity ? ` / ${breadCrumb.subCommodity}` : ''}`;
        const regionString = `${breadCrumb.region} ${breadCrumb.subRegion ? `/ ${breadCrumb.subRegion}` : ''}`;
        return !isCommodity ? `${regionString} / ${commodityString}` : `${commodityString} / ${regionString}`;
    };
}

const regionCommodityGroupingService = new RegionCommodityGroupingService();

export default regionCommodityGroupingService;
