import { useState } from 'react';
import { demeterUsersApi } from '../../../../../Apis/Apis';
import { DemeterUserModel } from '../../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../../Apis/Hooks/useApiWithoutAutoExecute';
import ConfirmModal from '../../../../Components/Modals/ConfirmModal/ConfirmModal';
import ActionsCellButton from '../../../../Components/Tables/ActionsCellButton/ActionsCellButton';
import useLanguage from '../../../../Services/Language/useLanguageHook';

interface IApproveUserProps {
    show: boolean;
    user: DemeterUserModel;
    handleConfirmed: () => any;
}

const ApproveUser: React.FC<IApproveUserProps> = (props: IApproveUserProps) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [translations] = useLanguage();

    const [, approveDemeterUser] = useApiWithoutAutoExecute(() => demeterUsersApi.approveDemeterUser(props.user.demeterUserTrialGuid));

    if (!props.show) {
        return null;
    }

    return (
        <>
            <ActionsCellButton text={translations.users.actions.approve} handleClick={() => setShowConfirmModal(true)} />
            <ConfirmModal
                header={translations.users.text.confirmApproveHeader}
                message={translations.users.text.confirmApproveMessage}
                showModal={showConfirmModal}
                handleConfirm={async () => {
                    approveDemeterUser();
                    props.handleConfirmed();
                    setShowConfirmModal(false);
                }}
                handleCancel={() => {
                    setShowConfirmModal(false);
                }}
            />
        </>
    );
};

export default ApproveUser;
