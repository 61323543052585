import {
    Correlation,
    DemeterDataFrequency,
    DemeterTableDefinitionType,
    LeadingIndicatorType,
    MarketIndicatorOutlook,
    MarketIndicatorTemplateType,
} from '../../../../../Generated/Raven-Demeter';

// Defaults, parameters, and constants.
export const defaultTemplateType = MarketIndicatorTemplateType.AverageSeasonal;
export const defaultCorrelation = Correlation.Positive;
export const defaultPeriodocity = DemeterDataFrequency.Daily;
export const defaultLeadingIndicatorType = LeadingIndicatorType.CommodityFuturesPrice;
export const defaultTableDefinition = DemeterTableDefinitionType.CommodityFuturesTable;
export const defaultYearsOfData = 4;
export const defaultOneYearWeightedPercent = 20;
export const defaultSeasonalStartMonth = 1;
export const defaultSeasonaEndMonth = 12;
export const minimumNumberOfSubFactors = 2;

// Sliding window seasonal defaults.
export const defaultMonthsFlatOutlook = '5,9';
export const defaultMonthsInverseCorrelation = '6,7,8';
export const defaultWindowStartDay = 1;
export const defaultWindowEndDay = 2;

// Forecast template defaults.
export const defaultMonthsForward = 6;

// Technical template defaults.
export const defaultDaysToAverage1 = 10;
export const defaultDaysToAverage2 = 40;
export const defaultDaysToAverage3 = 200;
export const defaultShortTermDays1 = 8;
export const defaultShortTermDays2 = 34;
export const defaultShortTermDays3 = 13;
export const defaultLongTermDays1 = 16;
export const defaultLongTermDays2 = 68;
export const defaultLongTermDays3 = 26;

// Forward Curve defaults.
export const defaultMonthsOfData = 1;
export const defaultShortTermContractMonth = 1;
export const defaultLongTermContractMonth = 12;
export const defaultLongTermContractNumber = 12;

// Manual entry defaults.
export const defaultOutlook = MarketIndicatorOutlook.Flat;

// Combination defaults
export const defaultMultipliers1 = '1,1';
export const defaultMultipliers2 = '1,1';
export const defaultMultipliers3 = '1,1';
export const defaultMultipliers4 = '1,1';
export const defaultMultipliers5 = '1,1';
export const defaultMultipliers6 = '1,1';
export const defaultMultipliers7 = '1,1';
export const defaultMultipliers8 = '1,1';
export const defaultMultipliers9 = '1,1';
export const defaultMultipliers10 = '1,1';
export const defaultMultipliers11 = '1,1';
export const defaultMultipliers12 = '1,1';

export const minimumLengthOfGuid = 32;
export const subFactorOrderBaseline = 100;

export const defaultNumberOfDataSourceSections = 1;
export const maximumNumberOfDataSourceSections = 2;
export const maximumNumberOfFactorsInRow = 4;

export const numberOfThresholds = 3;
export const defaultSeasonalSubFactorCalculationTypes = 'Average,LastYear,VersesAverageChange';
export const defaultHistoricalSubFactorCalculationTypes = 'Average,LastYear,Previous';
