/* eslint-disable import/no-cycle */
import { IndicatorStudy } from '../../../Components/Charts/BaseCharts/CommodityPriceChart/CommodityPriceChartDefinitions';
import {
    CommodityMonthlyMarginModel,
    Correlation,
    Currency,
    DemeterCommodity,
    DemeterDataFrequency,
    DemeterDataSource,
    DemeterFeatureType,
    DemeterFilterTimeSpan,
    DemeterMarket,
    DemeterPaymentType,
    DemeterRegion,
    DemeterSubscriptionFrequency,
    DemeterTableDefinitionType,
    DemeterUserStatus,
    DemeterUserType,
    ExchangeType,
    LeadingIndicatorType,
    MarketIndicatorDataCombinationType,
    MarketIndicatorOutlook,
    MarketIndicatorTemplateType,
    MarketIndicatorTransformationType,
    MarketPrecision,
    SubExchangeType,
    UnitOfMeasure,
} from '../../../Generated/Raven-Demeter';
import { ChartDisplayType } from '../../Components/Charts/ChartDefinitions';
import { ChartStudyType } from '../../Components/Charts/Studies/StudiesDefinitions';
import { BasisAdjustment, BasisLagPeriod, BasisPeriod } from '../../Pages/Calculators/BasisCalculator/BasisCalculatorDefinitions';
import { OutlookAndReportsTitleEnum } from '../../Pages/OutlookAndReports/OutlookAndReportsDefinitions';

const TranslationKeyDefinition = {
    // Common translations.
    text: {
        asOfDate: '',
        averageChange: '',
        averageShort: '',
        brokerSupport: '',
        copyEmail: '',
        forwardCurve: '',
        dayMovingAverage: '',
        endDate: '',
        helpAndPolicies: '',
        lastChange: '',
        lastUpdated: '',
        lastSavedOn: '',
        lastYear: '',
        leaveWithoutSaving: '',
        netPosition: '',
        monthOverMonth: '',
        percentChange: '',
        quarterlyAndYearly: '',
        saveAs: '',
        savedBy: '',
        shortYearOverYearChange: '',
        sessionExpired: '',
        signalFrom: '',
        spotPrice: '',
        startDate: '',
        thisYear: '',
        twelveMonths: '',
        versesAverageChange: '',
        versesThreshold: '',
        yearToDateShort: '',
        yearlyAverage: '',
        yearOverYear: '',
        yearRange: '',
    },
    words: {
        actions: '',
        actual: '',
        active: '',
        and: '',
        approved: '',
        average: '',
        basis: '',
        bids: '',
        by: '',
        calculated: '',
        change: '',
        chart: '',
        charts: '',
        commodity: '',
        contract: '',
        contraction: '',
        cost: '',
        country: '',
        currency: '',
        date: '',
        decile: '',
        default: '',
        direction: '',
        edit: '',
        exchange: '',
        expansion: '',
        fat: '',
        forecast: '',
        friday: '',
        fundamentals: '',
        generated: '',
        here: '',
        high: '',
        historical: '',
        history: '',
        implied: '',
        inactive: '',
        indicators: '',
        inputs: '',
        licenses: '',
        low: '',
        lower: '',
        management: '',
        market: '',
        mathOperation: '',
        maximum: '',
        mean: '',
        median: '',
        minimum: '',
        model: '',
        monday: '',
        month: '',
        monthShort: '',
        multiplier: '',
        name: '',
        none: '',
        note: '',
        of: '',
        offers: '',
        other: '',
        over: '',
        overview: '',
        parent: '',
        percentChange: '',
        percentile: '',
        permissions: '',
        previousShort: '',
        previous: '',
        price: '',
        prices: '',
        probabilty: '',
        product: '',
        products: '',
        protein: '',
        quarter: '',
        quarterly: '',
        range: '',
        region: '',
        rejected: '',
        results: '',
        rolling: '',
        saved: '',
        sector: '',
        settlement: '',
        shortYearOverYear: '',
        spot: '',
        spotPrice: '',
        spread: '',
        standard: '',
        subtotal: '',
        system: '',
        summary: '',
        table: '',
        termsAndConditions: '',
        thursday: '',
        to: '',
        tools: '',
        total: '',
        trades: '',
        tuesday: '',
        unit: '',
        upper: '',
        values: '',
        volatility: '',
        volume: '',
        vs: '',
        wednesday: '',
        year: '',
        yearly: '',
        yield: '',
    },
    actions: {
        accept: '',
        add: '',
        addContracts: '',
        addFiveYears: '',
        apply: '',
        back: '',
        backToTop: '',
        browseFiles: '',
        cancel: '',
        clickToDownload: '',
        close: '',
        confirm: '',
        delete: '',
        deleteColumn: '',
        details: '',
        done: '',
        download: '',
        edit: '',
        export: '',
        flipRegionsAndCommodities: '',
        gainInstantAccess: '',
        go: '',
        import: '',
        less: '',
        loadLastSave: '',
        logout: '',
        more: '',
        removeFiveYears: '',
        renderChart: '',
        requestMoreInformation: '',
        requestPricingInformation: '',
        reset: '',
        save: '',
        saveAndReturn: '',
        search: '',
        set: '',
        select: '',
        submit: '',
        test: '',
        update: '',
    },
    errors: {
        apiError: '',
        componentError: '',
        dateFormatError: '',
        dateFormatWithOldestDateError: '',
        downloadSubscriptionError: '',
        pleaseContactYourBroker: '',
    },

    // Components.
    charts: {
        solidLine: {
            expansion: '',
            contraction: '',
        } as { [key in keyof CommodityMonthlyMarginModel | string]: string },
        legend: {
            history: '',
            lastMonth: '',
            lastSettlement: '',
            period: '',
        },
        limitMessage: {
            yearLimitMessage: '',
        },
        text: {
            forecastData: '',
            monthly: '',
            noChartSelected: '',
            pastTwelveMonths: '',
            projectionChartNote: '',
            seasonal: '',
            seasonalChartNote: '',
            yearOverYearChange: '',
            yearToDate: '',
        },
        displayType: {
            Area: '',
            Column: '',
            Line: '',
            Spline: '',
        } as { [key in keyof typeof ChartDisplayType]: string },
        buttons: {
            changeType: '',
            download: '',
            showModal: '',
        },
    },
    dropdown: {
        commodityChart: '',
        nativeCurrency: '',
        nativeUnits: '',
        seasonalChart: '',
        studies: '',
        studiesLimitMessage: '',
        downloads: {
            csv: '',
            jpeg: '',
            pdf: '',
            svg: '',
            png: '',
            xlsx: '',
        },
    },
    fields: {
        chartControl: '',
        percentChange: '',
    },
    permissions: {
        text: {
            advancedValueMatrix: '',
            farmerMarginAdministrator: '',
            hedgeMonitorProAdministrator: '',
            marketIndicatorAdministrator: '',
            marketIndicatorCompliance: '',
            marketIndicatorDeveloper: '',
            marketIndicatorSubjectMatterExpert: '',
            permissionsSuccessfullyUpdated: '',
            usersExport: '',
            users: '',
            valorization: '',
        },
        errors: {
            updatePermissionsError: '',
        },
    },
    // Major enumerations.
    commodity: {
        All: 'commodity.All',
        AnhydrousMilkFat: 'commodity.AnhydrousMilkFat',
        Butter: 'commodity.Butter',
        Butterfat: 'commodity.Butterfat',
        ButterMilkPowder: 'commodity.ButterMilkPowder',
        Cheddar: 'commodity.Cheddar',
        CheeseBlock: 'commodity.CheeseBlock',
        CheeseBarrel: 'commodity.CheeseBarrel',
        CheddarCurd: 'commodity.CheddarCurd',
        CheddarMild: 'commodity.CheddarMild',
        CrudeOil: 'commodity.CrudeOil',
        DryWhey: 'commodity.DryWhey',
        Gasoline: 'commodity.Gasoline',
        Gouda: 'commodity.Gouda',
        Milk: 'commodity.Milk',
        MilkClass1: 'commodity.MilkClass1',
        MilkClass2: 'commodity.MilkClass2',
        MilkClass3: 'commodity.MilkClass3',
        MilkClass4: 'commodity.MilkClass4',
        Mozzarella: 'commodity.Mozzarella',
        MilkEquivalent: 'commodity.MilkEquivalent',
        NaturalGas: 'commodity.NatrualGas',
        NonFatDryMilk: 'commodity.NonFatDryMilk',
        NonFatSolids: 'commodity.NonFatSolids',
        OtherSolids: 'commodity.OtherSolids',
        Protein: 'commodity.Protein',
        SkimMilkClass1: 'commodity.SkimMilkClass1',
        SkimMilkClass2: 'commodity.SkimMilkClass2',
        SkimMilkClass3: 'commodity.SkimMilkClass3',
        SkimMilkClass4: 'commodity.SkimMilkClass4',
        SkimMilkPowder: 'commodity.SkimMilkPowder',
        SkimMilkPowderLow: 'commodity.SkimMilkPowderLow',
        SkimMilkPowderMedium: 'commodity.SkimMilkPowderMedium',
        Soybean: 'commodity.Soybean',
        SoybeanMeal: 'commodity.SoybeanMeal',
        SoybeanOil: 'commodity.SoybeanOil',
        SourCream: 'commodity.SourCream',
        Weather: 'commodity.Weather',
        WholeMilkPowder: 'commodity.WholeMilkPowder',
    } as { [key in keyof typeof DemeterCommodity]: string },
    dataSource: {
        StoneXCalculations: '',
    } as { [key in keyof typeof DemeterDataSource]: string },
    correlation: {
        Negative: '',
        None: '',
        Positive: '',
    } as { [key in keyof typeof Correlation]: string },
    currency: {
        AUD: '',
        CAD: '',
        CNY: '',
        EUR: '',
        GBP: '',
        NZD: '',
        USC: '',
        USD: '',
    } as { [key in keyof typeof Currency | string]: string },
    exchange: {
        Cme: 'exchange.CME',
        CME: 'exchange.CME',
        Eex: 'exchange.EEX',
        EEX: 'exchange.EEX',
        Euronext: 'exchange.Euronext',
        Dce: 'exchange.DCE',
        DCE: 'exchange.DCE',
        Ice: 'exchange.ICE',
        ICE: 'exchange.ICE',
        Otc: 'exchange.OTC',
        OTC: 'exchange.OTC',
        Sgx: 'exchange.SGX',
        SGX: 'exchange.SGX',
    } as { [key in keyof typeof ExchangeType | string]: string },
    subExchange: {
        Cbot: 'subExchange.CBOT',
        Comex: 'subExchange.COMEX',
        Nymex: 'subExchange.NYMEX',
        Ifeu: 'subExchange.IFEU',
        Ifsg: 'subExchange.IFSG',
        Ifus: 'subExchange.IFUS',
        Ndex: 'subExchange.NDEX',
    } as { [key in keyof typeof SubExchangeType | string]: string },
    filterTimeSpan: {
        LastTwoActuals: '',
        OneDay: '',
        ThirtyDays: '',
        OneMonth: '',
        ThreeMonths: '',
        SixMonths: '',
        NineMonths: '',
        OneYear: '',
        TwoYears: '',
        ThreeYears: '',
        FiveYears: '',
        TenYears: '',
        TwentyYears: '',
        All: '',
        YearToDate: '',
    } as { [key in keyof typeof DemeterFilterTimeSpan]: string },
    market: {
        All: '',
        Currency: '',
        Dairy: '',
        Energy: '',
        Grains: '',
        Livestock: '',
        Other: '',
        Softs: '',
    } as { [key in keyof typeof DemeterMarket | string]: string },
    marketIndicatorOutlook: {
        Unavailable: '',
        VeryBearish: '',
        Bearish: '',
        Flat: '',
        Bullish: '',
        VeryBullish: '',
    } as { [key in keyof typeof MarketIndicatorOutlook]: string },
    marketIndicatorTemplateType: {
        AverageSeasonal: '',
        Combination: '',
        Decile: '',
        Forecast: '',
        ForwardCurve: '',
        Historical: '',
        ManualEntry: '',
        Seasonal: '',
        SlidingWindowSeasonal: '',
        Speculative: '',
        Technical: '',
    } as { [key in keyof typeof MarketIndicatorTemplateType]: string },
    marketIndicatorDataCombinationType: {
        Aggregate: '',
        Average: '',
        Index: '',
        Multiply: '',
        Ratio: '',
        Spread: '',
    } as { [key in keyof typeof MarketIndicatorDataCombinationType]: string },
    marketIndicatorTransformationType: {
        DayOverDayChange: '',
        MonthOverMonthChange: '',
        Multiply: '',
        RelativeStrengthIndex: '',
        RollingAverage: '',
        RollingAverageForwardContracts: '',
        WeekOverWeekChange: '',
        YearOverYearChange: '',
    } as { [key in keyof typeof MarketIndicatorTransformationType]: string },
    leadingIndicatorType: {
        CommodityDailyOther: '',
        CommodityFuturesIndex: '',
        CommodityFuturesPrice: '',
        CommodityMonthlyDomestics: '',
        CommodityMonthlyExports: '',
        CommodityMonthlyImports: '',
        CommodityMonthlyPrices: '',
        CommodityMonthlyProduction: '',
        CommodityMonthlyStocks: '',
        CommodityProgress: '',
        CommoditySpotPrices: '',
        CommodityWeeklyPrices: '',
        GlobalDairyTradeWeeklyPrices: '',
    } as { [key in keyof typeof LeadingIndicatorType]: string },
    parentRegion: {
        AsiaPacific: 'parentRegion.AsiaPacific',
        America: 'parentRegion.America',
        EuropeMiddleEastAndAfrica: 'parentRegion.EuropeMiddleEastAndAfrica',
    } as { [key in keyof typeof DemeterRegion]: string },
    paymentType: {
        WireTransfer: '',
        DeductionFromFuturesAccount: '',
    } as { [key in keyof typeof DemeterPaymentType]: string },
    region: {
        All: 'region.All',
        Algeria: 'region.Algeria',
        Aggregated: 'region.Aggregated',
        America: 'region.America',
        Argentina: 'region.Argentina',
        Australia: 'region.Australia',
        AsiaPacific: 'region.AsiaPacific',
        Brazil: 'region.Brazil',
        Canada: 'region.Canada',
        Chile: 'region.Chile',
        China: 'region.China',
        Colombia: 'region.Colombia',
        DominicanRepublic: 'region.DominicanRepublic',
        EuropeanUnion: 'region.EuropeanUnion',
        EuropeMiddleEastAndAfrica: 'region.EuropeMiddleEastAndAfrica',
        GlobalDairyTrade: 'region.GlobalDairyTrade',
        Indonesia: 'region.Indonesia',
        MajorExporters: 'region.MajorExporters',
        Malaysia: 'region.Malaysia',
        Mexico: 'region.Mexico',
        Oceania: 'region.Oceania',
        NewZealand: 'region.NewZealand',
        Philippines: 'region.Philippines',
        UnitedStates: 'region.UnitedStates',
        Uruguay: 'region.Uruguay',
    } as { [key in keyof typeof DemeterRegion]: string },
    tableDefinitionType: {
        CommodityFuturesTable: '',
        CommodityFuturesIndexTable: '',
        CommodityGlobalDairyTradeWeeklyPricesTable: '',
        CommodityMonthlyDomesticTable: '',
        CommodityMonthlyExportsTable: '',
        CommodityMonthlyImportsTable: '',
        CommodityMonthlyMarginTable: '',
        CommodityMonthlyPricesTable: '',
        CommodityMonthlyProductionTable: '',
        CommodityMonthlySalesTable: '',
        CommodityMonthlyStockTable: '',
        CommodityOtcPricesTable: '',
        CommodityOtherTable: '',
        CommodityPricesTable: '',
        CommodityProgressTable: '',
        CommoditySpotPricesTable: '',
        CommoditySummaryTable: '',
        CommodityWeeklyPricesTable: '',
        CommodityYearlyBalanceTable: '',
        MarketNews: '',
        UsersTable: '',
    } as { [key in keyof typeof DemeterTableDefinitionType]: string },
    unitOfMeasure: {
        Bag: '',
        Barrel: '',
        Bushel: '',
        Currency: '',
        EuropeanUnionAllowance: '',
        Gallon: '',
        HundredKilogram: '',
        HundredWeight: '',
        Imperial: '',
        Kilogram: '',
        KilogramMilkSolids: '',
        Liter: '',
        MegaWattHour: '',
        Metric: '',
        MetricTon: '',
        MillionBritishThermalUnits: '',
        MillionPounds: '',
        MillionLiters: '',
        Percent: '',
        Pound: '',
        ShortBarrel: '',
        ShortBushel: '',
        ShortContracts: '',
        ShortCurrency: '',
        ShortGallon: '',
        ShortHead: '',
        ShortHundredKilogram: '',
        ShortHundredWeight: '',
        ShortKilogram: '',
        ShortMegaWattHour: '',
        ShortMetricTon: '',
        ShortMillionBritishThermalUnits: '',
        ShortMillionGallons: '',
        ShortMillionPounds: '',
        ShortMillionLiters: '',
        ShortPercent: '',
        ShortPound: '',
        ShortPoundSingle: '',
        ShortThousandBarrels: '',
        ShortThousandBarrelsPerDay: '',
        ShortThousandHeads: '',
        ShortThousandMetricTons: '',
        ShortThousandPounds: '',
        ShortShortTon: '',
        ShortTon: '',
        ThousandBarrels: '',
        ThousandBarrelsPerDay: '',
        ThousandMetricTons: '',
        ThousandPounds: '',
    } as { [key in keyof typeof UnitOfMeasure | string]: string },
    subscriptionFrequency: {
        Monthly: '',
        Quarterly: '',
        Yearly: '',
    } as { [key in keyof typeof DemeterSubscriptionFrequency]: string },

    // Other enumerations.
    featureType: {
        [DemeterFeatureType.Administration]: '',
        [DemeterFeatureType.BasisCalculator]: '',
        [DemeterFeatureType.BudgetCalculator]: '',
        [DemeterFeatureType.Companies]: '',
        [DemeterFeatureType.DownloadsExports]: '',
        [DemeterFeatureType.DownloadsGeneral]: '',
        [DemeterFeatureType.DownloadsImports]: '',
        [DemeterFeatureType.FarmerMarginCalculator]: '',
        [DemeterFeatureType.HedgeMonitorPro]: '',
        [DemeterFeatureType.MarketIndicators]: '',
        [DemeterFeatureType.Markets]: '',
        [DemeterFeatureType.PositionsCalculator]: '',
        [DemeterFeatureType.PresentationTemplates]: '',
        [DemeterFeatureType.Refactors]: '',
        [DemeterFeatureType.UserData]: '',
        [DemeterFeatureType.Users]: '',
        [DemeterFeatureType.Valorization]: '',
        [DemeterFeatureType.ValueMatrix]: '',
    } as { [key in keyof typeof IndicatorStudy | string]: string },
    indicatorStudy: {
        [IndicatorStudy.absolutePrice]: '',
        [IndicatorStudy.accelerationBands]: '',
        [IndicatorStudy.annualPercentageChange]: '',
        [IndicatorStudy.aroon]: '',
        [IndicatorStudy.bollingerBands]: '',
        [IndicatorStudy.detrendedPrice]: '',
        [IndicatorStudy.doubleExponentialMovingAverage]: '',
        [IndicatorStudy.exponentialMovingAverage]: '',
        [IndicatorStudy.linearRegression]: '',
        [IndicatorStudy.linearRegressionIntercept]: '',
        [IndicatorStudy.linearRegressionSlope]: '',
        [IndicatorStudy.movingAverage]: '',
        [IndicatorStudy.percentagePrice]: '',
        [IndicatorStudy.rateOfChange]: '',
        [IndicatorStudy.relativeStrengthIndex]: '',
        [IndicatorStudy.tema]: '',
        [IndicatorStudy.trix]: '',
        [IndicatorStudy.weightedMovingAverage]: '',
        [IndicatorStudy.aroonOscillator]: '',
        [IndicatorStudy.chandeMomentumOscillator]: '',
        [IndicatorStudy.disparityindex]: '',
    } as { [key in keyof typeof IndicatorStudy | string]: string },
    chartStudy: {
        [ChartStudyType.AbsolutePrice]: '',
        [ChartStudyType.BollingerBands]: '',
        [ChartStudyType.ExponentialMovingAverage]: '',
        [ChartStudyType.LinearRegression]: '',
        [ChartStudyType.MovingAverage]: '',
        [ChartStudyType.RelativeStrengthIndex]: '',
    } as { [key in keyof typeof ChartStudyType | string]: string },

    // Feature translations.
    globalDairyTrade: {},
    footer: {
        text: {
            allRightsReserved: '',
            disclaimer: '',
            privacyPolicy: '',
        },
    },
    // Page translations.
    page: {
        administration: '',
        balanceTable: '',
        calculators: '',
        cancelTrial: '',
        companies: '',
        disclaimer: '',
        domestics: '',
        exports: '',
        featureFlags: '',
        frequentlyAskedQuestions: '',
        hedgeMonitorPro: '',
        privacyPolicy: '',
        imports: '',
        marketIndicators: '',
        presentationTemplates: '',
        production: '',
        risk: '',
        userData: '',
        stocks: '',
        users: '',
    },
    accessPending: {
        title: '',
        text: {
            toRequestALicense: '',
        },
    },
    administration: {
        title: '',
    },
    balanceTable: {
        title: '',
    },
    calculators: {
        title: '',
        text: {
            averageCheesePrice: '',
            basis: '',
            cheesePricing: '',
            containsUserEnteredValue: '',
            federalMilkMarketingOrder: '',
            federalMilkMarketingOrderDescription: '',
            forwardSpreadCalculator: '',
            historicalSpread: '',
            milkPriceCalculator: '',
            noPriceData: '',
            quarterly: '',
            result: '',
            spread: '',
            valueMatrix: '',
            valorization: '',
        },
        actions: {
            resetPrices: '',
        },
        fields: {
            converted: '',
            foreignExchangeRate: '',
            period: '',
            spread: '',
        },
        valorization: {
            fields: {
                costOfProduction: '',
                energy: '',
                fat: '',
                labor: '',
                protein: '',
                maintenance: '',
                depreciation: '',
                streamName: '',
            },
            messages: {
                addCalculationEnginesInputsSuccessful: '',
                addCalculationEnginesInputsFailed: '',
                pleaseEnterAValueBetween: '',
                updateCalculationEnginesInputsSuccessful: '',
                updateCalculationEnginesInputsFailed: '',
            },
            text: {
                addStream: '',
                costOfProductionTooltip: '',
                editStream: '',
                enterName: '',
                enterNote: '',
                howToUseModal: '',
                netReturns: '',
                milkSolids: '',
                modelName: '',
                resetCustomValues: '',
                resetSpotPrices: '',
                settingsApplyToAllStreams: '',
                setValorizationModelAs: '',
                stream: '',
                valorizationModels: '',
                yourCustomValues: '',
            },
        },
        valueMatrix: {
            fields: {
                aboveMedian: '',
                applyProducerPriceIndex: '',
                deciles: '',
                innerRange: '',
                innerWeight: '',
                outerRange: '',
                outerWeight: '',
                producerPriceIndex: '',
                sector: '',
                stripType: '',
                termClassification: '',
                terms: '',
                weighted: '',
                yearsToLookBack: '',
            },
            messages: {
                failedCalculatorSave: '',
                successfulCalculatorSave: '',
            },
            text: {
                annual: '',
                chartControls: '',
                chartSettings: '',
                chartSettingsMessage: '',
                contract1: '',
                contract2: '',
                forwardContract: '',
                innerRangeTooltipInformation: '',
                limitMessage: '',
                numberOfSelectedDecileslimitMessage: '',
                numberOfSelectedYearslimitMessage: '',
                outerRangeTooltipInformation: '',
                producerPriceIndexTooltipInformation: '',
                quarter1: '',
                quarter2: '',
                quarter3: '',
                quarter4: '',
                savedVersionLoaded: '',
                stripTypeTooltipInformationFirstContract: '',
                stripTypeTooltipInformationSecondContract: '',
                stripTypeTooltipInformationForwardContract: '',
                stripTypeTooltipInformationTwelveMonth: '',
                summerMonths: '',
                twelveMonthStrip: '',
                winterMonths: '',
            },
        },
        basis: {
            chooseProductsHeader: '',
            chooseProducts: '',
            futures: '',
            physicalPrices: '',
            userData: '',
            productSelector: {
                header: '',
                tabs: {
                    productsTab: {
                        name: '',
                        product1: '',
                        product2: '',
                        useMyData: '',
                        search: '',
                        noData: '',
                    },
                    savedProductPairsTab: {
                        name: '',
                        product1: '',
                        product2: '',
                        dateSaved: '',
                        deleteProductPair: '',
                        confirmDeleteProductPair: '',
                    },
                },
                actions: {
                    uploadMyData: '',
                    set: '',
                    cancel: '',
                },
            },
            form: {
                product1: '',
                product2: '',
                historyStartDate: '',
                correlation: '',
                optimalLag: '',
                month: '',
                months: '',
                lag: '',
                lagPeriod: '',
                noLag: '',
                statisticalRelationship: '',
                slope: '',
                intercept: '',
                useRegression: '',
                basisSettings: '',
                basisPeriod: '',
                basisAdjustment: '',
                basisAdjustmentInformation: '',
                openAdvancedSettings: '',
                closeAdvancedSettings: '',
                selectIntervals: '',
                advancedCorrelation: '',
                lagCorrelation: '',
            },
            charts: {
                basisAnalysis: '',
                rollingBasisHistory: '',
                twelveMonthRollingCorrelation: '',
                correlation: '',
                basisHistory: '',
                regression: '',
            },
            actions: {
                selectSavedPair: '',
                saveProductPair: '',
                setProductPair: '',
            },
            basisLagPeriod: {
                MinusSixMonthLag: '',
                MinusFiveMonthLag: '',
                MinusFourMonthLag: '',
                MinusThreeMonthLag: '',
                MinusTwoMonthLag: '',
                MinusOneMonthLag: '',
                NoLag: '',
                OneMonthLag: '',
                TwoMonthLag: '',
                ThreeMonthLag: '',
                FourMonthLag: '',
                FiveMonthLag: '',
                SixMonthLag: '',
            } as { [key in keyof typeof BasisLagPeriod | string]: string },
            basisPeriod: {
                Basis: '',
                ThreeMonthBasis: '',
                SixMonthBasis: '',
                NineMonthBasis: '',
                TwelveMonthBasis: '',
            } as { [key in keyof typeof BasisPeriod | string]: string },
            basisAdjustment: {
                Average: '',
                Minimum: '',
                Maximum: '',
                Top10Percent: '',
                Top20Percent: '',
                Top30Percent: '',
                Top40Percent: '',
                Top50Percent: '',
                Bottom40Percent: '',
                Bottom30Percent: '',
                Bottom20Percent: '',
                Bottom10Percent: '',
            } as { [key in keyof typeof BasisAdjustment | string]: string },
        },
    },
    userData: {
        startup: {
            header: '',
            info: '',
            note: '',
            upload: '',
        },
        upload: {
            header: '',
            description: '',
            chooseFile: '',
            import: '',
            invalidFile: '',
            errorIncorrectHeader: '',
            errorIncorrectData: '',
            error: '',
        },
        management: {
            header: '',
            upload: '',
            description: '',
            subheader: '',
            table: {
                nameHeader: '',
                fileNameHeader: '',
                unitHeader: '',
                currencyHeader: '',
                actionsHeader: '',
                itemsPerPage: '',
                pageOf: '',
            },
            confirmDelete: {
                header: '',
                message: '',
            },
        },
        form: {
            header: '',
            name: '',
            unit: '',
            currency: '',
            invalidName: '',
        },
    },
    cancelTrial: {
        errors: {
            failureCancelSubscription: '',
        },
        text: {
            cancelTrialMessage: '',
            cancelTrialHeader: '',
            userSuccessfullyCancelledSubscription: '',
        },
    },
    companies: {
        title: {
            add: '',
            list: '',
            companyUsers: '',
        },
        tabs: {
            billing: '',
            details: '',
            licenses: '',
            permissions: '',
        },
        text: {
            companySuccessfullyAdded: '',
            companySuccessfullyUpdated: '',
            successExport: '',
        },
        fields: {
            address1: '',
            address2: '',
            billingAddress1: '',
            billingAddress2: '',
            billingCity: '',
            billingCompanyName: '',
            billingContactName: '',
            billingCountry: '',
            billingCurrency: '',
            billingEmail: '',
            billingFax: '',
            billingPhone: '',
            billingPostalCode: '',
            billingRegion: '',
            billingSameAsCompanyInformation: '',
            billingState: '',
            billingVatNumber: '',
            city: '',
            company: '',
            contactPerson: '',
            country: '',
            dateAdded: '',
            futuresAccountCustomerName: '',
            futuresAccountNumber: '',
            futuresAccountTitle: '',
            futuresAccountTransferConsent: '',
            invoiceDate: '',
            numberOfLicenses: '',
            numberOfLicensesUsed: '',
            numberOfUsers: '',
            paymentType: '',
            policyNumbers: '',
            phone: '',
            postalCode: '',
            subscriptionFrequency: '',
            subscriptionPrice: '',
            state: '',
            companyAdministrator: '',
            termsAndConditionsAccepted: '',
            termsAndConditionsAcceptedAt: '',
            totalLicenses: '',
        },
        errors: {
            addCompanyError: '',
            failureExport: '',
            nameRequired: '',
            updateCompanyError: '',
            requiredField: '',
        },
    },
    companyRegistration: {
        description: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        phone: '',
        contactPerson: '',
        agreeTo: '',
        termsAndConditionsHeader: '',
        billingAddress1: '',
        billingAddress2: '',
        billingCity: '',
        billingContactName: '',
        billingCompanyName: '',
        billingCountry: '',
        billingPhone: '',
        billingFax: '',
        billingEmail: '',
        billingPostalCode: '',
        billingVatNumber: '',
        billingSameAsSubscriberInformation: '',
        billingState: '',
        paymentWireTransfer: '',
        paymentWireTransferUS: '',
        paymentWireTransferEU: '',
        paymentAutomaticDeduction: '',
        futuresAccountNumber: '',
        futuresAccountCustomerName: '',
        futuresAccountTitle: '',
        futuresAccountTransferConsent: '',
        subscriberBillingDetailsHeader: '',
        subscriberPaymentOptionsHeader: '',
    },
    dashboard: {
        title: '',
        chartName: {
            production: '',
            exports: '',
            margin: '',
        },
        headings: {
            articleHeader: '',
            cmeSummaryTableHeading: '',
            commoditySummaryTable: '',
            globalDairyTradeResultTableHeading: '',
            globalDairyTradeSummaryTableHeading: '',
            globalDairyTradeResultTableSubHeading: '',
            marketIndicatorsHeader: '',
            hedgeMonitorProHeader: '',
            priceValueMatrix: '',
            event: '',
        },
        indicators: {
            title: '',
            manageModalSubHeading: '',
            group: '',
            manage: '',
            single: '',
            availableIndicators: '',
            availableProducts: '',
            selectedIndicators: '',
            selectedProducts: '',
            searchIndicatorPlaceholder: '',
            addOrRemoveLeadingIndicators: '',
            addOrRemoveCommodityIndicators: '',
            addOrRemoveHedgeMonitorProIndicators: '',
            indicatorFrequency: '',
        },
        fields: {
            averageWinningPrice: '',
            contract: '',
            allContracts: '',
            changeInOverallPriceIndex: '',
            percentChangeFromPrevious: '',
            percentChangeFromPreviousEvent: '',
        },
        text: {
            commoditySummaryTableFootnote: '',
            customizeView: '',
            dairyTrader: '',
            dairyBuyerOrEndUser: '',
            fiftiethDecile: '',
            producerOrFarmer: '',
            processorOrCoop: '',
            results: '',
            summary: '',
        },
        latestNews: {
            latest: '',
            marketIntelligenceReports: '',
            readFullArticle: '',
        },
        priceSummary: {
            cme: {
                summaryHeader: '',
                headers: {
                    daily: '',
                    weekly: '',
                    historical: '',
                },
                fields: {
                    currentWeekAverage: '',
                    priorWeekAverage: '',
                    weekVolume: '',
                    currentPrice: '',
                    yearAgo: '',
                    monthAgo: '',
                    weekAgo: '',
                    twoDaysAgo: '',
                    oneDayAgo: '',
                },
            },
            dropdownPrefix: '',
            europeanUnion: {
                summaryHeader: '',
                headers: {
                    weeklyCheeseIndices: '',
                    averageIndex: '',
                    quotationsSummary: '',
                },
            },
        },
    },
    destinationsOrOrigins: {
        subHeader: {
            topOrigin: '',
            topDestination: '',
        },
        fields: {
            country: '',
            volume: '',
            yearOverYearChange: '',
            pastTwelveMonths: '',
            yearToDateChange: '',
            yearToDate: '',
        },
        text: {
            total: '',
            monthly: '',
        },
    },
    disclaimer: {
        title: '',
        paragraph1: '',
        paragraph2: '',
        paragraph3: '',
        paragraph4: '',
        paragraph5: '',
        paragraph6: '',
    },
    domestic: {
        title: '',
    },
    // This is the issue where some usages are plural.
    domestics: {
        title: '',
    },
    exports: {
        title: '',
    },
    featureFlags: {
        title: '',
        text: {
            successUpdated: '',
        },
    },
    futures: {
        title: '',
        titleWithoutOtc: '',
        headers: {
            forwardCurve: '',
            priceCurve: '',
        },
        text: {
            delayedFooter: '',
            realtimeFooter: '',
            priceStudiesPlaceholder: '',
            priceLimitMessage: '',
            searchProductPlaceholder: '',
        },
        fields: {
            askPrice: '',
            bidPrice: '',
            contractMonth: '',
            last: '',
            symbol: '',
            bidSize: '',
            askSize: '',
            volumeTradedToday: '',
            settle: '',
            openInterest: '',
            currency: '',
            open: '',
            openInterestNetChange: '',
            expirationDate: '',
            lastSessionVolume: '',
            previousSettlementPrice: '',
            priorSessionHigh: '',
            priorSessionLow: '',
            settleNetChange: '',
            name: '',
            high: '',
            low: '',
            percentChange: '',
            netChange: '',
            chartControl: '',
            tradeRegistrationVolume: '',
            tradeRegistrationPrice: '',
            lastUpdate: '',
            todaysMidpoint: '',
            priorDayMidpoint: '',
        },
        symbols: {
            'Anhydrous Milk Fat': '',
            'Block Cheese': '',
            Butter: '',
            Cheese: '',
            'Class III Milk': '',
            'Class IV Milk': '',
            'Dry Whey': '',
            'Nonfat Dry Milk': '',
            'NZ Milk Price': '',
            'Skim Milk Powder': '',
            'Skimmed Milk Powder': '',
            'Whey Powder': '',
            'Whole Milk Powder': '',
        },
    },
    hedgeMonitorPro: {
        title: '',
        brandingTitleString: '',
        text: {
            marketIndicator: '',
            precipiceMomentum: '',
        },
        tooltipMessage: '',
    },
    imports: {
        title: '',
    },
    licenses: {
        title: '',
        text: {
            licenseTableFooter: '',
        },
        error: {
            noUsersMessage: '',
        },
    },
    marketIndicators: {
        brandingTitleString: '',
        hedgeMontiorPro: '',
        marketIndicatorErrorMessage: '',
        multipleFactorsErrorMessage: '',
        singleFactorErrorMessage: '',
        title: '',
        tooltipMessage: '',
    },
    marketIndicatorsManagement: {
        actions: {
            activate: '',
            addIndicators: '',
            clone: '',
            deactivate: '',
            editDetails: '',
            history: '',
        },
        headers: {
            actions: '',
            addFactor: '',
            addIndicators: '',
            cloneIndicators: '',
            commodityName: '',
            dataSource: '',
            dateCreated: '',
            deleteFactor: '',
            deleteFactorGroup: '',
            details: '',
            editFactorGroup: '',
            exchangeAndCommodity: '',
            factorName: '',
            indicatorName: '',
            lastUpdated: '',
            marketName: '',
            numberOfYears: '',
            reviewDate: '',
            reviewExpert: '',
            reviewStatus: '',
            reviewedBy: '',
            comments: '',
            status: '',
            template: '',
        },
        fields: {
            basisLocation: '',
            calculationType: '',
            commodity: '',
            contractMonths: '',
            contractNumber: '',
            correlation: '',
            dataTransformation: '',
            daysToAverage1: '',
            daysToAverage2: '',
            daysToAverage3: '',
            endMonth: '',
            factorGroupName: '',
            factorName: '',
            fundamentalCategory: '',
            imageLink: '',
            longTerm: '',
            longTermDays1: '',
            longTermDays2: '',
            longTermDays3: '',
            manualEntry: '',
            monthsFlatOutlook: '',
            monthsForward: '',
            monthsInverseCorrelation: '',
            monthsOfData: '',
            numberOfYears: '',
            oneYearWeightedPercent: '',
            outlook: '',
            periodicity: '',
            product: '',
            region: '',
            shortTermContractNumber: '',
            shortTermDays1: '',
            shortTermDays2: '',
            shortTermDays3: '',
            startMonth: '',
            subRegion: '',
            templateType: '',
            thresholdValue: '',
            transformationValue: '',
            windowEndDay: '',
            windowStartDay: '',
            yearsOfData: '',
        },
        messages: {
            displayNameInvalid: '',
            factorGroupNameUpdateFailed: '',
            factorGroupNameUpdateSuccessful: '',
            factorUpdateFailed: '',
            factorUpdateSuccessful: '',
            indicatorAddSuccessful: '',
            indicatorAddFailed: '',
            indicatorAddConflict: '',
            indicatorCloneConflict: '',
            indicatorCloneSuccessful: '',
            indicatorCloneFailed: '',
            indicatorUpdateSuccessful: '',
            indicatorUpdateFailed: '',
            marketIndicatorFactorAddFailed: '',
            marketIndicatorFactorAddSuccessful: '',
            marketIndicatorFactorDeleteSuccessful: '',
            marketIndicatorFactorDeleteFailed: '',
            marketIndicatorFactorUpdateFailed: '',
            marketIndicatorFactorUpdateSuccessful: '',
            marketIndicatorReviewAddFailed: '',
            marketIndicatorReviewAddSuccessful: '',
            positiveOrNegativeNumber: '',
            numberOfYearsInvalid: '',
            positiveNumber: '',
            positiveNumberZeroThroughOneHundred: '',
        },
        reviewForm: {
            approve: '',
            approvedBy: '',
            comment: '',
            commentError: '',
            contentReview: '',
            description: '',
            reject: '',
            rejectedBy: '',
            title: '',
        },
        reviewHistory: {
            approvalHistory: '',
            commodity: '',
            noData: '',
            reviewers: '',
        },
        reviewStatus: {
            approved: '',
            expired: '',
            expireSoon: '',
            rejected: '',
        },
        text: {
            addDataSourceSection: '',
            addFactorGroup: '',
            areYouSureDeleteFactor: '',
            areYouSureDeleteFactorGroup: '',
            currentForecast: '',
            currentForwardCurve: '',
            newFactorGroup: '',
        },
        title: '',
    },
    outlookAndReports: {
        title: '',
        [OutlookAndReportsTitleEnum.AustraliaExports]: '',
        [OutlookAndReportsTitleEnum.AustraliaProduction]: '',
        [OutlookAndReportsTitleEnum.ChineseImports]: '',
        [OutlookAndReportsTitleEnum.ColdStorageReport]: '',
        [OutlookAndReportsTitleEnum.CommitmentOfTradersReport]: '',
        [OutlookAndReportsTitleEnum.DailyReports]: '',
        [OutlookAndReportsTitleEnum.DairyProductsReport]: '',
        [OutlookAndReportsTitleEnum.EarlyMorniningUpdateUnitedStates]: '',
        [OutlookAndReportsTitleEnum.EuropeanUnionAndUnitedKingdomExports]: '',
        [OutlookAndReportsTitleEnum.EuropeanUnionAndUnitedKingdomProduction]: '',
        [OutlookAndReportsTitleEnum.EuropeanUnionCheeseIndices]: '',
        [OutlookAndReportsTitleEnum.EuropeanUnionQuotations]: '',
        [OutlookAndReportsTitleEnum.EuropeanUnionOceaniaDaily]: '',
        [OutlookAndReportsTitleEnum.EuropeanUnionWeeklySummary]: '',
        [OutlookAndReportsTitleEnum.EuropeanUnionWeeklySummaryFr]: '',
        [OutlookAndReportsTitleEnum.EuropeanUnionWeeklySummaryDe]: '',
        [OutlookAndReportsTitleEnum.EuropeanUnionWeeklySummaryPl]: '',
        [OutlookAndReportsTitleEnum.Exports]: '',
        [OutlookAndReportsTitleEnum.GlobalDairyTradeReport]: '',
        [OutlookAndReportsTitleEnum.GlobalOutlook]: '',
        [OutlookAndReportsTitleEnum.GlobalSlideDeck]: '',
        [OutlookAndReportsTitleEnum.Imports]: '',
        [OutlookAndReportsTitleEnum.InternationalButterSpreads]: '',
        [OutlookAndReportsTitleEnum.InternationalWMPSpreads]: '',
        [OutlookAndReportsTitleEnum.InternationalSMPSpreads]: '',
        [OutlookAndReportsTitleEnum.InternationalWheySpreads]: '',
        [OutlookAndReportsTitleEnum.MilkPriceEquivalent]: '',
        [OutlookAndReportsTitleEnum.MilkPriceEquivalentFr]: '',
        [OutlookAndReportsTitleEnum.MilkProduction]: '',
        [OutlookAndReportsTitleEnum.MonthlyOutlooks]: '',
        [OutlookAndReportsTitleEnum.MonthlyWebinarReplay]: '',
        [OutlookAndReportsTitleEnum.NewZealandExports]: '',
        [OutlookAndReportsTitleEnum.NewZealandProduction]: '',
        [OutlookAndReportsTitleEnum.PostGlobalDairyTradeReport]: '',
        [OutlookAndReportsTitleEnum.PreGlobalDairyTradeReport]: '',
        [OutlookAndReportsTitleEnum.ProfitViewFarmerReport]: '',
        [OutlookAndReportsTitleEnum.SeasonalPriceReport]: '',
        [OutlookAndReportsTitleEnum.UdderIntelligence]: '',
        [OutlookAndReportsTitleEnum.UdderIntelligenceChina]: '',
        [OutlookAndReportsTitleEnum.UnitedStatesExports]: '',
        [OutlookAndReportsTitleEnum.UnitedStatesOutlook]: '',
        [OutlookAndReportsTitleEnum.UnitedStatesProduction]: '',
        [OutlookAndReportsTitleEnum.Video]: '',
        [OutlookAndReportsTitleEnum.WeeklyReports]: '',
        [OutlookAndReportsTitleEnum.WeeklyMarketRecapChina]: '',
        [OutlookAndReportsTitleEnum.WeeklyPrices]: '',
        [OutlookAndReportsTitleEnum.WeeklyVideo]: '',
    },
    physicalPrices: {
        title: '',
        chartTypes: {
            lineChart: '',
            areaChart: '',
            column: '',
            spline: '',
        },
        fields: {
            name: '',
            date: '',
            price: '',
            percentChange: '',
            netChange: '',
            previousPrice: '',
            currency: '',
            unit: '',
            frequencyOrForcast: '',
            chartControl: '',
        },
        tooltip: {
            daily: '',
            dailyForecast: '',
            weekly: '',
            weeklyForecast: '',
            monthly: '',
            monthlyForecast: '',
        },
        text: {
            forecastData: '',
            futuresForwardCurve: '',
            limitMessage: '',
            studies: '',
        },
    },
    dataFrequency: {
        Daily: '',
        Monthly: '',
        Streaming: '',
        Weekly: '',
        Yearly: '',
    } as { [key in keyof typeof DemeterDataFrequency]: string },
    presentationTemplates: {
        title: {
            add: '',
            edit: '',
            list: '',
        },
        text: {
            description: '',
            confirmDeleteHeader: '',
            confirmDeleteMessage: '',
            confirmDuplicateHeader: '',
            includeDescription: '',
            includeInExport: '',
            noImage: '',
        },
        actions: {
            add: '',
            edit: '',
            duplicate: '',
            delete: '',
            download: '',
        },
        fields: {
            search: '',
            templateFormat: '',
            templateName: '',
        },
        errors: {
            addPresentationTemplateError: '',
            getPresentationTemplateError: '',
            getDemeterPageElementImageError: '',
            listPresentationTemplatesError: '',
            updatePresentationTemplateError: '',
        },
        success: {
            addPresentationTemplateSuccess: '',
            updatePresentationTemplateSuccess: '',
        },
    },
    positions: {
        title: '',
        text: {
            successExport: '',
        },
        fields: {
            commodity: '',
            companyName: '',
            coveragePeriod: '',
            endorsementDate: '',
            netCoveredVolume: '',
            policyNumber: '',
            premiumPrice: '',
            triggerPrice: '',
            state: '',
        },
        errors: {
            failureExport: '',
        },
    },
    production: {
        title: '',
        marginChart: {
            subtitle: '',
            title: '',
        },
    },
    registration: {
        actions: {
            completeRegistration: '',
            contactUsForMore: '',
            startNow: '',
        },
        errors: {
            missingParameters: '',
            passwordInvalid: '',
            confirmPasswordInvalid: '',
        },
        fields: {
            password: '',
            confirmPassword: '',
            choosePasswordPlaceholder: '',
            confirmPasswordPlaceholder: '',
        },
        success: {
            clickTheButton: '',
            copyright: '',
            header: '',
            paragraph1: '',
            paragraph2: '',
            paragraph3: '',
            paragraph4: '',
            paragraph5: '',
            paragraph6: '',
            subheader: '',
            watchTheTutorial: '',
            whatWillHappenNext: '',
            youWillRecieveEmail: '',
        },
        text: {
            accessLeadingMarketIntelligence: '',
            accessToTheTrial: '',
            accessTradingAccount: '',
            allForecastingIs: '',
            analyzeAllRelevent: '',
            asianUserSuccessfullyRegistered: '',
            bySubmittingThisForm: '',
            callTheNumber: '',
            canIUseStoneX: '',
            chicagoTelephone: '',
            currentlyStonex: '',
            customizedDashboard: '',
            customizeThePlatform: '',
            dairyMarketsInsights: '',
            derivativesContractsTrading: '',
            discoverHowPowerful: '',
            doesPlusHave: '',
            dublinTelephone: '',
            eachExchangeHas: '',
            exportData: '',
            fatAndProtein: '',
            fmmoCalculator: '', // TODO - change this. What is FMMO?
            forecastForTheTop: '',
            forwardSpreadCalculator: '',
            fourTrillionCombined: '',
            frequentlyAskedQuestions: '',
            futuresPrices: '',
            globalDairyTradePrices: '',
            howAreTheForecasts: '',
            howArePricesDetermined: '',
            howMuchDoesStoneX: '',
            howToRequestMore: '',
            importsData: '',
            isTheFuturesInformation: '',
            lengthMessage: '',
            matchingPasswords: '',
            milkAndMajor: '',
            monthlyProduction: '',
            moreThanOneHundred: '',
            moreThanTwenty: '',
            ourDairyGroup: '',
            ourDiverseGlobal: '',
            passwordRequirements: '',
            positionAnalysis: '',
            powerfulAnalysis: '',
            prices: '',
            privacyPolicy: '',
            production: '',
            proprietaryOverTheCounter: '',
            provideYourBusiness: '',
            recieveLiveAndDelayed: '',
            registrationFailed: '',
            registrationSucceeded: '',
            reportsWereViewed: '',
            selectCountry: '',
            singaporeTelephone: '',
            specialCharacterMessage: '',
            startYourFreeTrial: '',
            statisticallyConsistent: '',
            stonexIsAlso: '',
            stoneXIsAnInstitution: '',
            stonexPlusData: '',
            stonexPlusIsAvailable: '',
            theOtcPrices: '',
            toLearnMore: '',
            toDiscoverHowStonex: '',
            toRequestMoreInformation: '',
            tradeData: '',
            userSuccessfullyRegistered: '',
            weCreateBespoke: '',
            weCurrentlyHaveDairy: '',
            welcomeMessage: '',
            whatInformationDoes: '',
            whatLanguageIs: '',
            whereAreWeBased: '',
            whyChooseStonex: '',
            yesStoneXPlusCan: '',
        },
        title: '',
    },
    risk: {
        title: '',
        headers: {
            budget: '',
            budgetAtRisk: '',
            budgetVersesMarket: '',
            combinedNetPosition: '',
            cumulativeValueAtRisk: '',
            forwardCurveAndBasis: '',
            futures: '',
            margin: '',
            marginHeatMap: '',
            marginTrackerChart: '',
            marketPrices: '',
            markToBudgetOfNetCovered: '',
            markToMarket: '',
            markToMarketNetOpenPositions: '',
            markToMarketOfNetCovered: '',
            netPosition: '',
            physical: '',
            physicalPositionNetLong: '',
            physicalPositionNetSales: '',
            physicalPositionSecured: '',
            positions: '',
            productionVersusHedgedVolume: '',
            purchases: '',
            monthlyNetPositionValueAtRisk: '',
            netPositionCumulativeRisk: '',
            netPositionMonthlyRisk: '',
            netUnhedgedPosition: '',
            sales: '',
            stocks: '',
            valueAtRisk: '',
            valueAtRiskAssumptions: '',
        },
        margin: {
            title: '',
            fields: {
                calculator: '',
                company: '',
                endingQuarter: '',
                location: '',
                startingQuarter: '',
                state: '',
                volatility: '',
            },
            text: {
                bias: '',
                breakEven: '',
                breakEvenShortText: '',
                calculatorAddedSuccessfully: '',
                calculatorUpdatedSuccessfully: '',
                costTotalPrice: '',
                futuresBlendPrice: '',
                hedgedMargin: '',
                hedgedVolume: '',
                marketPrice: '',
                marketPriceShortText: '',
                production: '',
                productionInformation: '',
                severityOfCoverage: '',
                unhedgedMargin: '',
            },
        },
        text: {
            basis: '',
            budgetMessage: '',
            combinedNetPosition: '',
            disclaimer: '',
            futuresPosition: '',
            futuresPositionMessage: '',
            futuresPrice: '',
            historicVolatility: '',
            marginAtRisk: '',
            marketPrices: '',
            markToMarket: '',
            markToMarketAdjusted: '',
            markToMarketPriceDifference: '',
            markToMarketReference: '',
            markToMarketTotal: '',
            monthlyBudgetAtRisk: '',
            netFuturesSwapPosition: '',
            physicalPositionMessage: '',
            physicalPositionNetSales: '',
            physicalPositionNetLong: '',
            price: '',
            netPhysicalPosition: '',
            netPosition: '',
            nettedVolume: '',
            netUnhedgedPosition: '',
            netUnhedgedPrice: '',
            openingPrice: '',
            openingWeightedAveragePrice: '',
            openingVolume: '',
            perUnit: '',
            physicalPositionNetLongPosition: '',
            physicalPositionNetLongPrice: '',
            physicalPositionNetSalesPosition: '',
            physicalPositionNetSalesPrice: '',
            physicalPositionSecuredPosition: '',
            physicalPositionSecuredPrice: '',
            physicalPositionSecuredTotal: '',
            physicalPosition: '',
            physicalPrice: '',
            purchasesPosition: '',
            purchasesPrice: '',
            reference: '',
            riskCumulative: '',
            riskDays: '',
            riskMonthly: '',
            salesPosition: '',
            salesPrice: '',
            physicalPositionSecured: '',
            stocksPosition: '',
            stocksPrice: '',
            stocksUnpricedDrawdown: '',
            stocksUnpricedIncrease: '',
            unpricedDrawdown: '',
            unpricedIncrease: '',
            valueAtRisk: '',
            volatilityPercent: '',
            weightedAveragePrice: '',
        },
    },
    sessionExpiration: {
        clickClosePreviousSession: '',
        forQuestionsContactUs: '',
        loggedInOnAnotherDevice: '',
        reloadSession: '',
    },
    stocks: {
        title: '',
        text: {
            shortCommericalStocks: '',
            shortGovernmentStocks: '',
        },
    },
    stocksCommercial: {
        title: '',
    },
    stocksGovernment: {
        title: '',
    },
    trialExpiration: {
        benefitsList: {
            analyzeMarketData: '',
            buildCompletePicture: '',
            developUnderstanding: '',
            fullyCustomize: '',
            gainAccessInsights: '',
            keepTrack: '',
            stayUpToDate: '',
        },
        header: {
            benefitsOfSubscribing: '',
            hopeYouEnjoyed: '',
            apologizeForInconvenience: '',
        },
        text: {
            email: '',
            stayOneStepAhead: '',
            weTrustThat: '',
            weWillTakeCare: '',
            copyText: '',
            trialExpirationNotice: '',
            errorWhileAccessingContactTeam: '',
            contactTeam: '',
        },
    },
    users: {
        title: {
            add: '',
            list: '',
        },
        text: {
            batchImportWarning: '',
            confirmApproveHeader: '',
            confirmApproveMessage: '',
            confirmDeactivateHeader: '',
            confirmDeactivateMessage: '',
            confirmReactivateHeader: '',
            confirmReactivateMessage: '',
            confirmRejectHeader: '',
            confirmRejectMessage: '',
            downloadTemplateLink: '',
            removeMarketIntelligenceMessage: '',
            successExport: '',
            userSuccessfullyCreated: '',
            userSuccessfullyUpdated: '',
            assignLicenses: '',
        },
        actions: {
            approve: '',
            deactivate: '',
            reactivate: '',
            reject: '',
            export: '',
            search: '',
        },
        fields: {
            company: '',
            companyAdministrator: '',
            email: '',
            expiration: '',
            firstName: '',
            importStatus: '',
            lastName: '',
            lastUseDate: '',
            marketPrecision: '',
            markets: '',
            name: '',
            notes: '',
            phone: '',
            role: '',
            status: '',
            subscribedDate: '',
            termsAndConditionsAcceptedAt: '',
        },
        import: {
            failed: '',
            skipped: '',
            success: '',
        },
        errors: {
            addUserError: '',
            companyNameRequired: '',
            emailAlreadyExistsError: '',
            emailInvalid: '',
            failureExport: '',
            firstNameRequired: '',
            lastNameRequired: '',
            notCsv: '',
            phoneInvalid: '',
            updateUserError: '',
        },
        trialLength: {
            custom: '',
            fourteenDays: '',
            sixtyDays: '',
            thirtyDays: '',
        },
        roles: {
            All: '',
            [DemeterUserType.Administrator]: '',
            [DemeterUserType.BusinessOwner]: '',
            [DemeterUserType.Premium]: '',
            [DemeterUserType.Regular]: '',
        } as { [key in keyof typeof DemeterUserType | 'All']: string },
        status: {
            All: '',
            [DemeterUserStatus.Active]: '',
            [DemeterUserStatus.Deactivated]: '',
            [DemeterUserStatus.Expired]: '',
            [DemeterUserStatus.Expiring]: '',
            [DemeterUserStatus.Pending]: '',
            [DemeterUserStatus.PendingApproval]: '',
            [DemeterUserStatus.Rejected]: '',
        } as { [key in keyof typeof DemeterUserStatus | 'All']: string },
        marketPrecision: {
            [MarketPrecision.Delayed]: '',
            [MarketPrecision.EndOfDay]: '',
            [MarketPrecision.Realtime]: '',
        } as { [key in keyof typeof MarketPrecision]: string },
    },
    workers: {
        title: '',
        fields: {
            name: '',
            lastRun: '',
            lastStatus: '',
            lastSuccessDate: '',
            lastFailureDate: '',
            lastFailureMessage: '',
        },
    },
    modalChartFooterDisclaimer: '',
    pageFooterDisclaimer: '',
    privacyPolicyHeader: '',
    privacyPolicyContents: '',
};

export type TranslationType = typeof TranslationKeyDefinition;

const translationKeysInternal = {} as unknown as TranslationType;
const convertObjectToTranslation = (namespace: string[], source: any, destination: any): void => {
    Object.keys(source).forEach((key) => {
        const newNamespace = [...namespace, key];
        const value = source[key];

        if (typeof value === 'string') {
            destination[key] = newNamespace.join('.');
            return;
        }

        destination[key] = {};
        convertObjectToTranslation(newNamespace, value, destination[key]);
    });
};

convertObjectToTranslation([], TranslationKeyDefinition, translationKeysInternal);

export const translationKeys = translationKeysInternal;

export default TranslationKeyDefinition;
