import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useEffect, useRef, useState } from 'react';
import styles from './MenuFileSelectors.module.scss';

export enum MenuTypeForSearchParameter {
    region = 'region',
    subRegion = 'subRegion',
    commodity = 'commodity',
}

export interface IRegionCommodityValues {
    region: string | null;
    subRegion: string | null;
    commodity: string | null;
    subCommodity: string | null;
    topLevelGroup: string | null;
    topLevelRegion: string | null;
    extraParameters: string;
    dataFrequency: string;
    isSubSelection: boolean;
}

export interface IMenuItem {
    id: string;
    regionCommodityValues: IRegionCommodityValues;
    label: string;
    hasSubGroupBreakBefore: boolean;
    isSubGroupHeader: boolean;
    isSelectedItem: boolean;
    dropdownOptions: IMenuItem[];
}

interface IMenuSelectorItemProps {
    menuItem: IMenuItem;
    menuType: string;
    dividerClassName: string;
    classname: string;
    buttonClassname: string;
    labelClassname: string;
    simpleClassname: string;
    selectorClassname: string;
    dropdownClassname: string;
    handleMenuSelection: (selection: IMenuItem) => void;
    isSelected: boolean;
    showMainRegionsAndGroupsOnly?: boolean;
}

const MenuSelectorItem: React.FC<IMenuSelectorItemProps> = (props: IMenuSelectorItemProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const anchorReference = useRef<HTMLDivElement>(null);

    const [displayName, setDisplayName] = useState<string>(props.menuItem.label);

    const handleClick = (menuItem?: IMenuItem) => {
        if (
            !menuItem &&
            !props.menuItem.regionCommodityValues.region &&
            !props.menuItem.regionCommodityValues.commodity &&
            !props.showMainRegionsAndGroupsOnly
        ) {
            setDropdownOpen((previousOpen) => !previousOpen);
            return;
        }

        if (menuItem) {
            setDropdownOpen(false);
            props.handleMenuSelection(menuItem);
        } else {
            setDisplayName(props.menuItem.label);
            setDropdownOpen(false);
            props.handleMenuSelection(props.menuItem);
        }
    };

    const handleToggle = () => {
        setDropdownOpen((previousOpen) => !previousOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorReference.current && anchorReference.current.contains(event.target as HTMLElement)) {
            return;
        }

        setDropdownOpen(false);
    };

    useEffect(() => {
        const subItemSelected = props.menuItem.dropdownOptions.find((x) => x.isSelectedItem);
        if (props.isSelected && subItemSelected && props.menuType === 'topItem') {
            setDisplayName(`${props.menuItem.label} / ${subItemSelected.label}`);
        } else {
            setDisplayName(props.menuItem.label);
        }
    }, [props]);

    return (
        <>
            <ButtonGroup sx={{ height: '100%' }} variant="text" ref={anchorReference}>
                <button
                    className={`${props.classname} ${props.buttonClassname} ${props.labelClassname} ${
                        !props.menuItem.dropdownOptions.length || props.showMainRegionsAndGroupsOnly ? props.simpleClassname : ''
                    } ${!props.menuItem.regionCommodityValues.region && !props.menuItem.regionCommodityValues.commodity ? props.selectorClassname : ''} `}
                    type="button"
                    onClick={() => handleClick()}
                >
                    {displayName}
                </button>
                {!!props.menuItem.dropdownOptions?.length &&
                    !props.showMainRegionsAndGroupsOnly &&
                    (!!props.menuItem.regionCommodityValues.region || !!props.menuItem.regionCommodityValues.commodity) && (
                        <div className={props.dividerClassName} />
                    )}

                {!!props.menuItem.dropdownOptions?.length && !props.showMainRegionsAndGroupsOnly && (
                    <button
                        className={`${props.buttonClassname} ${props.dropdownClassname} ${props.classname} ${
                            !props.menuItem.regionCommodityValues.region && !props.menuItem.regionCommodityValues.commodity ? props.selectorClassname : ''
                        } `}
                        type="button"
                        onClick={handleToggle}
                    >
                        <KeyboardArrowDownIcon fontSize="small" />
                    </button>
                )}
            </ButtonGroup>
            <Popper sx={{ zIndex: 1 }} open={dropdownOpen} anchorEl={anchorReference.current} placement="bottom-start" role={undefined} disablePortal>
                <Paper sx={{ marginTop: '5px' }}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                            dense
                            id="split-button-menu"
                            autoFocusItem
                            sx={{ padding: '8px 0', maxHeight: '400px', minWidth: '150px', overflowY: 'auto' }}
                        >
                            {props.menuItem.dropdownOptions.map((option) => (
                                <div key={option.id}>
                                    {option.hasSubGroupBreakBefore && <Divider sx={{ margin: '5px 0' }} />}
                                    <MenuItem key={option.id} sx={{ minHeight: '0', padding: '0' }} dense onClick={() => handleClick(option)}>
                                        <div
                                            className={
                                                option.isSubGroupHeader ? styles.menu_selector_item_dropdown_header : styles.menu_selector_item_dropdown_label
                                            }
                                        >
                                            {option.label}
                                        </div>
                                    </MenuItem>
                                </div>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
};

export default MenuSelectorItem;
