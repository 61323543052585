import SearchIcon from '@mui/icons-material/Search';
import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { demeterUsersApi } from '../../../../Apis/Apis';
import AgGridBuilder from '../../../../Components/AgGridBuilder/AgGridBuilder';
import downloadSvg from '../../../../Components/Assets/download.svg';
import scssVariables from '../../../../Config.module.scss';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import {
    DemeterPermissionType,
    DemeterUserModel,
    DemeterUserStatus,
    DemeterUserType,
    ListDemeterUsersResponse,
    SortDirection,
} from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { SearchParameters } from '../../../../Redux/Slices/SystemSlice';
import { selectCompanyGuid, selectUserType } from '../../../../Redux/Slices/UserSlice';
import useApi from '../../../Apis/Hooks/useApiHook';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import Dropdown from '../../../Components/Form/Inputs/Dropdown';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import useNotificationHook from '../../../Components/Notifications/useNotificationHook';
import { ApiListResponse } from '../../../Components/Tables/TableWrapper/ApiListResponse';
import TableWrapper from '../../../Components/Tables/TableWrapper/TableWrapper';
import browserService from '../../../Services/BrowserService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import usePermission from '../../../Services/Permissions/usePermissionHook';
import UserActions from './UserActions';
import styles from './UsersPage.module.scss';
import { RendererParameters, usersColumnDefinitions, usersColumnOptions } from './UsersPageDefinitions';
import useUserTypeOptions from './useUserTypeOptionsHook';

const sortColumns = ['companyName', 'fullName', 'email'];

type SortColumn = (typeof sortColumns)[number];

interface ListDemeterUsersRequest {
    searchTerm?: string;
    userType?: DemeterUserType;
    userStatus?: DemeterUserStatus;
    demeterCompanyGuid?: string;
    sortColumn?: SortColumn;
    sortDirection?: SortDirection;
    take: number;
    skip: number;
}

const UsersPage: React.FC = () => {
    const [translations] = useLanguage();
    const routeParameters = useParams();
    const companyGuidFromRoute = routeParameters.demeterCompanyGuid;
    const companyGuidFromRedux = useApplicationSelector(selectCompanyGuid);
    const currentUserType = useApplicationSelector(selectUserType);
    const isPremium = currentUserType === DemeterUserType.Premium;
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const [displayError, displaySuccess] = useNotificationHook();
    const usersExportPermission = usePermission(DemeterPermissionType.UsersExport);

    const [listDemeterUsersRequest, setListDemeterUsersRequest] = useState<ListDemeterUsersRequest>(() => ({
        searchTerm: searchParameters.searchTerm ?? '',
        userType: searchParameters.userType !== 'All' ? (searchParameters.userType as DemeterUserType) : undefined,
        userStatus: searchParameters.userStatus !== 'All' ? (searchParameters.userStatus as DemeterUserStatus) : undefined,
        demeterCompanyGuid: companyGuidFromRoute ?? undefined,
        sortColumn: searchParameters.sortColumn ?? 'fullName',
        sortDirection: (searchParameters.sortDirection as SortDirection) ?? SortDirection.Asc,
        take: applicationConstants.ItemsPerPage,
        skip: (searchParameters.skip as unknown as number) ?? 0,
    }));
    const [searchTermInput, setSearchTermInput] = useState<string>('');
    const [listDemeterUsersResponse, setListDemeterUsersResponse] = useState<ListDemeterUsersResponse>();
    const [onGridReady, setOnGridReady] = useState<GridReadyEvent>();
    const gridReference = useRef<AgGridReact>(null);

    const selectedCompanyGuid = useMemo(() => {
        if (isPremium && companyGuidFromRedux) {
            return companyGuidFromRedux;
        }

        if (companyGuidFromRoute) {
            return companyGuidFromRoute;
        }

        return null;
    }, [companyGuidFromRedux, companyGuidFromRoute]);

    useEffect(() => {
        setSearchTermInput(searchParameters?.searchTerm);
        setListDemeterUsersRequest({ ...listDemeterUsersRequest, searchTerm: searchParameters?.searchTerm, skip: 0 });
        refreshApi();
    }, [searchParameters]);

    const filteredUsersColumnDefinitions = useMemo(() => {
        const newUsersColumnDefinitions = [...usersColumnDefinitions];
        if (selectedCompanyGuid) {
            return newUsersColumnDefinitions.filter(
                (usersColumnDefinition) =>
                    usersColumnDefinition.field === 'fullName' ||
                    usersColumnDefinition.field === 'email' ||
                    usersColumnDefinition.field === 'userType' ||
                    usersColumnDefinition.field === 'markets' ||
                    usersColumnDefinition.field === 'userStatus' ||
                    usersColumnDefinition.field === 'actions',
            );
        }
        return newUsersColumnDefinitions;
    }, [selectedCompanyGuid, listDemeterUsersResponse]);

    const [, refreshApi, apiResponse] = useApi(() => {
        if (!listDemeterUsersRequest) {
            return null;
        }
        return demeterUsersApi.listDemeterUsers(
            listDemeterUsersRequest.searchTerm,
            listDemeterUsersRequest.userType,
            listDemeterUsersRequest.userStatus,
            listDemeterUsersRequest.demeterCompanyGuid,
            listDemeterUsersRequest.sortColumn,
            listDemeterUsersRequest.sortDirection,
            listDemeterUsersRequest.take,
            listDemeterUsersRequest.skip,
        );
    });

    useEffect(() => {
        const actionIndex = filteredUsersColumnDefinitions.findIndex((element) => element.field === 'actions');
        filteredUsersColumnDefinitions[actionIndex].cellRenderer = (parameters: RendererParameters) => (
            <UserActions user={parameters.data} handleChange={refreshApi} />
        );
        const marketsIndex = filteredUsersColumnDefinitions.findIndex((element) => element.field === 'markets');
        filteredUsersColumnDefinitions[marketsIndex].cellRenderer = (parameters: RendererParameters) => {
            if (parameters.data.markets.length) {
                return <div>{parameters.data.markets.map((x) => translations.market[x]).join(', ')}</div>;
            }

            if (!companyGuidFromRoute && !isPremium) {
                return <div>{applicationConstants.TablePlaceholderZeroOrEmpty}</div>;
            }

            if (parameters.data.userStatus === DemeterUserStatus.Deactivated) {
                return <div>{applicationConstants.TablePlaceholderZeroOrEmpty}</div>;
            }

            return (
                <button
                    type="button"
                    className={styles.users_licenses_link_button}
                    style={{ color: scssVariables.agGridLinkLolor }}
                    onClick={() => {
                        setSearchParameters({ tab: 'Licenses' });
                    }}
                >
                    {translations.users.text.assignLicenses}
                </button>
            );
        };
    }, [filteredUsersColumnDefinitions]);

    useEffect(() => {
        if (apiResponse?.rows) {
            apiResponse.rows = apiResponse?.rows.map((row: DemeterUserModel) => ({ ...row, id: row.demeterUserTrialGuid }));
            setListDemeterUsersResponse(apiResponse);
        }
    }, [apiResponse]);

    useEffect(() => {
        if (gridReference?.current) {
            gridReference.current.api.addEventListener('sortChanged', handleSort);
        }
    }, [gridReference?.current, onGridReady]);

    const handleSort = () => {
        const columnState = gridReference?.current?.columnApi.getColumnState();
        const columnsWithActiveSort = columnState?.filter((x) => x.sort !== null && x.sortIndex !== null);
        if (!columnsWithActiveSort || !columnsWithActiveSort.length) {
            return;
        }

        const newRequest = { ...listDemeterUsersRequest };
        newRequest.sortColumn = columnsWithActiveSort[0].colId;
        newRequest.sortDirection = columnsWithActiveSort[0].sort === SortDirection.Asc.toLowerCase() ? SortDirection.Asc : SortDirection.Desc;
        setListDemeterUsersRequest(newRequest);
        refreshApi();
    };

    const handleTakeCountUpdate = (apiListResponse: ApiListResponse) => {
        const newRequest = { ...listDemeterUsersRequest };
        newRequest.take = apiListResponse.take ?? applicationConstants.ItemsPerPage;
        setListDemeterUsersRequest(newRequest);
        refreshApi();
    };

    const handleSearch = (event: KeyboardEvent) => {
        const { value } = event.target as HTMLInputElement;
        if (event.key === 'Enter') {
            const newSearchParameters: SearchParameters = {
                searchTerm: value as string,
                userStatus: listDemeterUsersRequest.userStatus ?? 'All',
                userType: listDemeterUsersRequest.userType ?? 'All',
                sortColumn: listDemeterUsersRequest.sortColumn as string,
                sortDirection: listDemeterUsersRequest.sortDirection as string,
                skip: listDemeterUsersRequest.skip as unknown as string,
                tab: 'Users',
            };

            setSearchParameters(newSearchParameters);
            setListDemeterUsersRequest({ ...listDemeterUsersRequest, searchTerm: value, skip: 0 });
        }
    };

    const handlePagination = (apiListResponse: ApiListResponse) => {
        const newRequest = { ...listDemeterUsersRequest };
        newRequest.skip = apiListResponse.skip ?? 0;
        setListDemeterUsersRequest(newRequest);
        refreshApi();
    };

    const handleExportUsers = async () => {
        try {
            const listUsersDownload = await demeterUsersApi.listDemeterUsers(
                listDemeterUsersRequest.searchTerm,
                listDemeterUsersRequest.userType,
                listDemeterUsersRequest.userStatus,
                undefined,
                listDemeterUsersRequest.sortColumn,
                listDemeterUsersRequest.sortDirection,
                -1,
                0,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'text/csv',
                        accept: 'text/csv',
                    },
                },
            );

            browserService.downloadCsv(listUsersDownload.data as string, 'Users');
            displaySuccess(translations.users.text.successExport);
        } catch {
            displayError(translations.users.errors.failureExport);
        }
    };

    const filterRoleDropdownOptions = useUserTypeOptions(true);
    const userStatusOptions: { value: DemeterUserStatus | 'All'; label: string }[] = (Object.keys(DemeterUserStatus) as Array<keyof typeof DemeterUserStatus>)
        .filter((el) => el !== DemeterUserStatus.Synced && el !== DemeterUserStatus.Locked)
        .map((key) => ({
            value: key,
            label: translations.users.status[key],
        }));

    userStatusOptions.unshift({ value: 'All', label: translations.users.status.All });

    return !listDemeterUsersResponse ? (
        <PageLoadingSpinner />
    ) : (
        <div className={styles.users_container}>
            <div className={selectedCompanyGuid ? styles.users_buttons_row_details : styles.users_actions_row}>
                {!selectedCompanyGuid && (
                    <div className={styles.users_actions_search}>
                        <div className={styles.users_search_area}>
                            <SearchIcon className={styles.users_search_area_icon} />
                            <input
                                onKeyDown={handleSearch as any}
                                className={styles.users_actions_row_input}
                                placeholder={translations.actions.search}
                                value={searchTermInput}
                                onChange={(event) => setSearchTermInput((event.target as HTMLInputElement).value)}
                            />
                        </div>
                        <div className={styles.users_dropdown_container}>
                            <Dropdown
                                value={listDemeterUsersRequest.userType ?? 'All'}
                                options={filterRoleDropdownOptions}
                                handleOptionChange={(value: DemeterUserType | 'All') => {
                                    if (value === 'All') {
                                        setListDemeterUsersRequest({ ...listDemeterUsersRequest, userType: undefined, skip: 0 });
                                        refreshApi();
                                    } else {
                                        setListDemeterUsersRequest({ ...listDemeterUsersRequest, userType: value as DemeterUserType, skip: 0 });
                                        refreshApi();
                                    }
                                }}
                            />
                        </div>
                        <div className={styles.users_dropdown_container}>
                            <Dropdown
                                value={listDemeterUsersRequest.userStatus ?? 'All'}
                                options={userStatusOptions}
                                handleOptionChange={(value: DemeterUserStatus | 'All') => {
                                    if (value === 'All') {
                                        setListDemeterUsersRequest({ ...listDemeterUsersRequest, userStatus: undefined, skip: 0 });
                                        refreshApi();
                                    } else {
                                        setListDemeterUsersRequest({ ...listDemeterUsersRequest, userStatus: value as DemeterUserStatus, skip: 0 });
                                        refreshApi();
                                    }
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.users_buttons_row}>
                    {!selectedCompanyGuid && (
                        <LinkButton title={translations.actions.import} navigateTo={`${NavigationRoutes.UsersImport}`} type={LinkButtonType.White} />
                    )}
                    {usersExportPermission && (
                        <LinkButton
                            title={translations.users.actions.export}
                            type={LinkButtonType.White}
                            icon={<img style={{ marginRight: '8px' }} src={downloadSvg} alt="download" />}
                            onClick={handleExportUsers}
                        />
                    )}
                    <LinkButton title={translations.actions.add} navigateTo={`${NavigationRoutes.UsersAdd}`} type={LinkButtonType.Blue} />
                </div>
            </div>
            <div className={styles.users_table_container}>
                <TableWrapper
                    apiListResponse={listDemeterUsersResponse}
                    showPagination
                    handlePagination={handlePagination}
                    handleTakeCountUpdate={handleTakeCountUpdate}
                >
                    <AgGridBuilder
                        gridRef={gridReference}
                        rowData={listDemeterUsersResponse.rows!}
                        onGridReady={setOnGridReady}
                        hasSaveColumnsState
                        columnDefinitions={filteredUsersColumnDefinitions}
                        defaultColumnDefinition={usersColumnOptions}
                        gridHeightFull
                        domLayout="autoHeight"
                        testId="UsersTable"
                    />
                </TableWrapper>
            </div>
        </div>
    );
};

export default UsersPage;
