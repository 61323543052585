import { useEffect, useMemo, useState } from 'react';
import {
    DemeterCommodity,
    DemeterDataFrequency,
    DemeterMarket,
    DemeterRegion,
    DemeterSubRegion,
    DemeterTableDefinitionType,
    ExchangeType,
    LeadingIndicatorType,
    MarketIndicatorTemplateType,
    MarketIndicatorTransformationType,
} from '../../../../../Generated/Raven-Demeter';
import useSymbolsApi from '../../../../Apis/Hooks/useSymbolsApiHook';
import ComponentHeader from '../../../../Components/Headers/ComponentHeader';
import useEffectAfterFirstRender from '../../../../Core/Hooks/useEffectAfterFirstRenderHook';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { leadingIndicatorTypeDefinitions } from '../MarketIndicatorsManagementDefinitions';
import styles from './MarketIndicatorFactorPage.module.scss';
import DataSelectorCommodityDropdown from './MarketIndicatorsFactorFields/Common/DataSelectorCommodityDropdown';
import DataSelectorContractNumberDropdown from './MarketIndicatorsFactorFields/Common/DataSelectorContractNumberDropdown';
import DataSelectorDataTransformation from './MarketIndicatorsFactorFields/Common/DataSelectorDataTransformation';
import DataSelectorExchangeDropdown from './MarketIndicatorsFactorFields/Common/DataSelectorExchangeDropdown';
import DataSelectorFrequencyDropdown from './MarketIndicatorsFactorFields/Common/DataSelectorFrequencyDropdown';
import DataSelectorLeadingIndicatorTypeDropdown from './MarketIndicatorsFactorFields/Common/DataSelectorLeadingIndicatorTypeDropdown';
import DataSelectorMarketDropdown from './MarketIndicatorsFactorFields/Common/DataSelectorMarketDropdown';
import DataSelectorRegionDropdown from './MarketIndicatorsFactorFields/Common/DataSelectorRegionDropdown';
import DataSelectorSubRegionDropdown from './MarketIndicatorsFactorFields/Common/DataSelectorSubRegionDropdown';
import DataSelectorTransformationValueTextInput from './MarketIndicatorsFactorFields/Common/DataSelectorTransformationValueTextInput';

interface IDataSelectorMarketDropdownProps {
    title: string;
    market: DemeterMarket;
    leadingIndicatorType: LeadingIndicatorType;
    region?: DemeterRegion;
    subRegion?: DemeterSubRegion;
    commodity?: DemeterCommodity;
    templateType: MarketIndicatorTemplateType;
    extraParameters: string | null | undefined;
    dataFrequency?: DemeterDataFrequency;
    transformationType: MarketIndicatorTransformationType;
    transformationValue: number | undefined;
    handleChange: (
        market: DemeterMarket,
        fundamentalCategory: DemeterTableDefinitionType | null,
        leadingIndicatorType: LeadingIndicatorType,
        region: DemeterRegion,
        subRegion: DemeterSubRegion | undefined,
        commodity: DemeterCommodity,
        extraParameters: string | null | undefined,
        transformationType: MarketIndicatorTransformationType,
        transformationValue: number | undefined,
        dataFrequency?: DemeterDataFrequency,
    ) => void;
}

type DataSourceRequest = {
    market: DemeterMarket;
    fundamentalCategory: DemeterTableDefinitionType | null;
    leadingIndicatorType: LeadingIndicatorType;
    region?: DemeterRegion;
    subRegion?: DemeterSubRegion;
    commodity?: DemeterCommodity;
    extraParameters: string | null | undefined;
    dataFrequency?: DemeterDataFrequency;
    transformationType: MarketIndicatorTransformationType;
    transformationValue: number | undefined;
};

const defaultMarket = DemeterMarket.Dairy;
const transformationTypesRequiringValues: MarketIndicatorTransformationType[] = [
    MarketIndicatorTransformationType.RollingAverageForwardContracts,
    MarketIndicatorTransformationType.RollingAverage,
    MarketIndicatorTransformationType.Multiply,
    MarketIndicatorTransformationType.RelativeStrengthIndex,
];

const MarketIndicatorFactorDataSource: React.FC<IDataSelectorMarketDropdownProps> = (props: IDataSelectorMarketDropdownProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const symbols = useSymbolsApi();

    const showTransformationValueTextInput = props.transformationType && transformationTypesRequiringValues.includes(props.transformationType);

    const leadingIndicatorTypeOptions = useMemo(
        () =>
            leadingIndicatorTypeDefinitions.map((x) => ({
                ...x,
                label: translations.tableDefinitionType[x.tableDefinitionType],
            })),
        [translations],
    );

    const [exchangeType, setExchangeType] = useState<ExchangeType>();

    // Constants.
    const fundamentalCategory = leadingIndicatorTypeOptions.find((x) => x.value === props.leadingIndicatorType)?.tableDefinitionType!;
    const isFuturesCategory = fundamentalCategory === DemeterTableDefinitionType.CommodityFuturesTable;
    const isPricesCategory = fundamentalCategory === DemeterTableDefinitionType.CommodityPricesTable;

    const [dataSourceRequestData, setDataSourceRequestData] = useState<DataSourceRequest>({
        market: props.market,
        fundamentalCategory,
        leadingIndicatorType: props.leadingIndicatorType,
        region: props.region,
        subRegion: props.subRegion,
        commodity: props.commodity,
        extraParameters: props.extraParameters,
        dataFrequency: props.dataFrequency,
        transformationType: props.transformationType,
        transformationValue: props.transformationValue,
    });

    // Whenever changing subfactors, need to resync everything.
    useEffect(() => {
        if (
            (!props.extraParameters && isFuturesCategory) ||
            (props.market === dataSourceRequestData.market &&
                props.region === dataSourceRequestData.region &&
                props.subRegion === dataSourceRequestData.subRegion &&
                props.extraParameters === dataSourceRequestData.extraParameters &&
                props.dataFrequency === dataSourceRequestData.dataFrequency)
        ) {
            return;
        }

        setDataSourceRequestData({
            market: props.market,
            fundamentalCategory,
            leadingIndicatorType: props.leadingIndicatorType,
            region: props.region,
            subRegion: props.subRegion,
            commodity: props.commodity,
            extraParameters: props.extraParameters,
            dataFrequency: props.dataFrequency,
            transformationType: props.transformationType,
            transformationValue: props.transformationValue,
        });

        const lastSelectedSymbol = symbols?.find((x) => x.region === props.region && x.commodity === props.commodity);

        if (lastSelectedSymbol && lastSelectedSymbol.exchange !== exchangeType) {
            setExchangeType(lastSelectedSymbol.exchange);
        }
    }, [props.market, props.region, props.subRegion, props.commodity, props.extraParameters, props.dataFrequency]);

    useEffectAfterFirstRender(() => {
        props.handleChange(
            dataSourceRequestData.market,
            dataSourceRequestData.fundamentalCategory,
            dataSourceRequestData.leadingIndicatorType!,
            dataSourceRequestData.region!,
            dataSourceRequestData.subRegion,
            dataSourceRequestData.commodity!,
            dataSourceRequestData.extraParameters,
            dataSourceRequestData.transformationType,
            dataSourceRequestData.transformationValue,
            dataSourceRequestData.dataFrequency,
        );
    }, [dataSourceRequestData]);

    const handleRegionUpdate = (currentRegion: DemeterRegion) => {
        setDataSourceRequestData({
            ...dataSourceRequestData,
            region: currentRegion,
            subRegion: undefined,
        });
    };

    return (
        <div className={styles.indicator_add_and_edit_data_source}>
            <ComponentHeader title={props.title} />
            <div className={styles.indicator_add_and_edit_row}>
                <DataSelectorMarketDropdown
                    market={dataSourceRequestData.market}
                    handleChange={(selectedMarket) => {
                        const newDataSourceRequest = { ...dataSourceRequestData, market: selectedMarket! };

                        if (selectedMarket === DemeterMarket.Other) {
                            newDataSourceRequest.fundamentalCategory = DemeterTableDefinitionType.CommodityOtherTable;
                            newDataSourceRequest.leadingIndicatorType = LeadingIndicatorType.CommodityDailyOther;
                        }
                        setDataSourceRequestData(newDataSourceRequest);
                    }}
                />
            </div>
            <div className={styles.indicator_add_and_edit_row}>
                <DataSelectorLeadingIndicatorTypeDropdown
                    handleChange={(value) => {
                        const newUpsertMarketIndicatorFactorRequest = { ...dataSourceRequestData, subRegion: undefined };

                        if (props.market === DemeterMarket.Other && value === LeadingIndicatorType.CommodityFuturesPrice) {
                            newUpsertMarketIndicatorFactorRequest.market = defaultMarket;
                        }

                        if (value !== LeadingIndicatorType.CommodityMonthlyPrices && value !== LeadingIndicatorType.CommodityFuturesPrice) {
                            newUpsertMarketIndicatorFactorRequest.extraParameters = '';
                        }

                        if (value !== LeadingIndicatorType.CommodityFuturesPrice) {
                            newUpsertMarketIndicatorFactorRequest.leadingIndicatorType = value!;
                            newUpsertMarketIndicatorFactorRequest.fundamentalCategory = leadingIndicatorTypeOptions.find(
                                (x) => x.value === value,
                            )?.tableDefinitionType!;
                        } else {
                            newUpsertMarketIndicatorFactorRequest.dataFrequency = DemeterDataFrequency.Daily;
                            newUpsertMarketIndicatorFactorRequest.leadingIndicatorType = value!;
                            newUpsertMarketIndicatorFactorRequest.fundamentalCategory = leadingIndicatorTypeOptions.find(
                                (x) => x.value === value,
                            )?.tableDefinitionType!;
                        }

                        setDataSourceRequestData(newUpsertMarketIndicatorFactorRequest);
                    }}
                    leadingIndicatorTypeOptions={leadingIndicatorTypeOptions}
                    leadingIndicatorType={leadingIndicatorTypeOptions.find((x) => x.tableDefinitionType === fundamentalCategory)?.value}
                />
                {isFuturesCategory && (
                    <DataSelectorContractNumberDropdown
                        extraParameters={+(props.extraParameters ?? '')}
                        handleChange={(value) => {
                            if (!value) {
                                return;
                            }

                            setDataSourceRequestData({ ...dataSourceRequestData, extraParameters: `${value}` });
                        }}
                    />
                )}
            </div>
            <div className={styles.indicator_add_and_edit_row}>
                {isFuturesCategory ? (
                    <DataSelectorExchangeDropdown
                        market={props.market}
                        region={props.region!}
                        commodity={props.commodity!}
                        exchangeType={exchangeType}
                        handleChange={(exchange: ExchangeType, region?: DemeterRegion) => {
                            if (!exchange) {
                                return;
                            }

                            if (region) {
                                handleRegionUpdate(region);
                            }

                            setExchangeType(exchange);
                        }}
                    />
                ) : (
                    <DataSelectorRegionDropdown
                        market={props.market}
                        fundamentalCategory={fundamentalCategory}
                        region={props.region}
                        handleChange={(value) => {
                            handleRegionUpdate(value!);
                        }}
                    />
                )}
                {!isPricesCategory && (
                    <DataSelectorSubRegionDropdown
                        market={props.market}
                        fundamentalCategory={fundamentalCategory}
                        region={props.region}
                        subRegion={props.subRegion}
                        handleChange={(value) => setDataSourceRequestData({ ...dataSourceRequestData, subRegion: value })}
                    />
                )}
            </div>
            <div className={styles.indicator_add_and_edit_row}>
                <DataSelectorCommodityDropdown
                    market={props.market}
                    fundamentalCategory={fundamentalCategory}
                    region={props.region}
                    subRegion={props.subRegion}
                    commodity={props.commodity}
                    extraParameters={props.extraParameters}
                    exchange={exchangeType}
                    handleChange={(currentCommodity, currentExtraParameters, subRegion) => {
                        const newDataSourceRequstData = { ...dataSourceRequestData };

                        // These 2 items are always linked to each other.
                        newDataSourceRequstData.commodity = currentCommodity;
                        newDataSourceRequstData.extraParameters = currentExtraParameters;

                        if (isPricesCategory) {
                            newDataSourceRequstData.subRegion = subRegion;
                        }

                        setDataSourceRequestData(newDataSourceRequstData);
                    }}
                />
            </div>
            <div className={styles.indicator_add_and_edit_row}>
                <DataSelectorFrequencyDropdown
                    templateType={props.templateType}
                    dataFrequency={dataSourceRequestData.dataFrequency!}
                    handleChange={(value) => {
                        const newDataSourceRequstData = { ...dataSourceRequestData };

                        newDataSourceRequstData.dataFrequency = value!;
                        setDataSourceRequestData(newDataSourceRequstData);
                    }}
                    excludedFrequencyOptions={props.templateType !== MarketIndicatorTemplateType.AverageSeasonal ? [DemeterDataFrequency.Monthly] : []}
                    disabled={fundamentalCategory === DemeterTableDefinitionType.CommodityFuturesTable}
                />
            </div>
            <div className={styles.indicator_add_and_edit_row}>
                <DataSelectorDataTransformation
                    transformationType={props.transformationType}
                    handleChange={(value) => {
                        const newDataSourceRequestData = { ...dataSourceRequestData };

                        newDataSourceRequestData.transformationType = value!;
                        setDataSourceRequestData(newDataSourceRequestData);
                    }}
                />
                {showTransformationValueTextInput && (
                    <DataSelectorTransformationValueTextInput
                        transformationValue={props.transformationValue!}
                        handleChange={(value) => {
                            const newDataSourceRequestData = { ...dataSourceRequestData };

                            newDataSourceRequestData.transformationValue = value;
                            setDataSourceRequestData(newDataSourceRequestData);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default MarketIndicatorFactorDataSource;
