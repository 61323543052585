import { useEffect, useState } from 'react';
import useLanguage from '../../../Services/Language/useLanguageHook';
import TextInput from './TextInput';

interface NumberInputProps {
    className?: string;
    title?: string | JSX.Element;
    placeholder?: string;
    value?: number | null;
    required?: boolean;
    skipHandleChangeWhenInvalid?: boolean;
    disabled?: boolean;
    handleTextChange: (value: number) => void;
    handleBlur?: (value: string) => void;
    validation?: RegExp | ((value: string) => boolean);
    errorMessage?: string;
    handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    columnNumber?: number;
    testId?: string;
}

const NumberInput: React.FC<NumberInputProps> = (props: NumberInputProps) => {
    const [translations] = useLanguage();
    const [textValue, setTextValue] = useState('');

    useEffect(() => {
        if (textValue || !props.value) {
            return;
        }

        setTextValue(`${props.value}`);
    }, [props.value]);

    return (
        <TextInput
            className={props.className}
            type="number"
            title={props.title}
            placeholder={props.placeholder}
            value={textValue}
            required={props.required}
            skipHandleChangeWhenInvalid={props.skipHandleChangeWhenInvalid}
            disabled={props.disabled}
            handleTextChange={(value) => {
                setTextValue(value);

                if (!value || Number.isNaN(+value)) {
                    return;
                }

                props.handleTextChange(+value);
            }}
            handleBlur={props.handleBlur}
            validation={props.validation}
            errorMessage={props.errorMessage}
            handleKeyDown={props.handleKeyDown}
            columnNumber={props.columnNumber}
            testId={props.testId}
        />
    );
};

export default NumberInput;
