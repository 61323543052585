import React, { useEffect, useMemo, useState } from 'react';
import { demeterApi } from '../../../../Apis/Apis';
import { Currency, DemeterDataFrequency, DemeterFilterTimeSpan, MarketPricesTimeSpan, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import useMultipleApis from '../../../Apis/Hooks/useMultipleApisHook';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import { ChartDisplayType, IChartDataSeries } from '../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../Components/Charts/ChartWrapper/ChartWrapper';
import FilterTimeSpans from '../../../Components/Charts/FilterTimeSpans/FilterTimeSpans';
import PriceChartRaw from '../../../Components/Charts/Price/PriceChartRaw';
import { ChartStudyType } from '../../../Components/Charts/Studies/StudiesDefinitions';
import StudiesDropdown from '../../../Components/Charts/Studies/StudiesDropdown';
import JoinedSelectionButtons from '../../../Components/Form/Buttons/JoinedSelectionButtons/JoinedSelectionButtons';
import { IExchangeCommoditySelection } from '../../../Components/Navigation/Hooks/useExchangeCommodityNavigationHook';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import styles from './FuturesPricesTable.module.scss';
import { SymbolContractCompositeModel } from './FuturesPricesTableDefinitions';

export type ActualOrRollingType = 'actual' | 'rolling';

export interface FuturesPricesChartProps {
    exchangeCommoditySelection: IExchangeCommoditySelection;
    symbolContracts: SymbolContractCompositeModel[];
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
    hideRollingOption?: boolean;
    showPopout?: boolean;
    showOnlyAsPopout?: boolean;
    setShowPopout?: (showPopout: boolean) => void;
    testId?: string;
}

const availableFilterTimeSpansActual: DemeterFilterTimeSpan[] = [
    DemeterFilterTimeSpan.OneMonth,
    DemeterFilterTimeSpan.SixMonths,
    DemeterFilterTimeSpan.OneYear,
    DemeterFilterTimeSpan.TwoYears,
];

const availableFilterTimeSpansRolling: DemeterFilterTimeSpan[] = [
    DemeterFilterTimeSpan.OneMonth,
    DemeterFilterTimeSpan.SixMonths,
    DemeterFilterTimeSpan.OneYear,
    DemeterFilterTimeSpan.FiveYears,
    DemeterFilterTimeSpan.TenYears,
    DemeterFilterTimeSpan.TwentyYears,
];

const defaultFilterTimeSpan = DemeterFilterTimeSpan.OneYear;

const maximumRollingContractNumber = 10;

const defaultDisplayTypes = [ChartDisplayType.Line];
const singleLineDisplayTypes = [...defaultDisplayTypes, ChartDisplayType.Area, ChartDisplayType.Column, ChartDisplayType.Spline];

const FuturesPricesChart: React.FC<FuturesPricesChartProps> = (props: FuturesPricesChartProps) => {
    // Text hooks.
    const [translations] = useLanguage();
    const [actualOrRolling, setActualOrRolling] = useState<ActualOrRollingType>('actual');

    // Api hooks.
    const [filterTimeSpan, setFilterTimeSpan] = useState<DemeterFilterTimeSpan>(defaultFilterTimeSpan);
    const [futuresPricesLoading, refreshApi, futuresPricesDatas] = useMultipleApis(
        () => {
            if (props.symbolContracts.length === 0 || !props.symbolContracts[0].reutersInstrumentCode) {
                return null;
            }

            if (isRollingSelected) {
                return props.symbolContracts
                    .filter((x) => x.rowIndex < maximumRollingContractNumber)
                    .map((x) =>
                        demeterApi.listMarketPricesRollingWithConversions(
                            x.reutersInstrumentCodePrefix,
                            x.rowIndex + 1,
                            MarketPricesTimeSpan.TwentyYears,
                            props.currency,
                            props.unitOfMeasure,
                        ),
                    );
            }

            return props.symbolContracts.map((x) =>
                demeterApi.listMarketPricesWithConversions(x.reutersInstrumentCode, MarketPricesTimeSpan.TwoYears, props.currency, props.unitOfMeasure),
            );
        },
        { stopAutoExecute: true },
    );

    // Data hooks.
    const [linesSeries, setLinesSeries] = useState<IChartDataSeries[]>([]);
    const [displayType, setDisplayType] = useState<ChartDisplayType>(ChartDisplayType.Line);
    const [studies, setStudies] = useState<ChartStudyType[]>([]);
    const symbols = useSymbolsApi();
    const symbolModel = useMemo(() => {
        if (!symbols) {
            return undefined;
        }

        const selectedSymbol = symbols.find(
            (x) => x.exchange === props.exchangeCommoditySelection.exchange && x.commodity === props.exchangeCommoditySelection.commodity,
        );

        return selectedSymbol;
    }, [symbols, props.symbolContracts]);

    const actualOrRollingOptions: { label: string; value: ActualOrRollingType }[] = useMemo(
        () => [
            {
                label: translations.words.actual,
                value: 'actual',
            },
            {
                label: translations.words.rolling,
                value: 'rolling',
            },
        ],
        [],
    );

    useEffect(() => {
        if (props.hideRollingOption) {
            setActualOrRolling('actual');
        }
    }, [props.hideRollingOption]);

    const isRollingSelected = actualOrRolling === 'rolling';
    const titleString = formattingService.toDisplayName(symbolModel);

    useEffect(() => {
        if (props.symbolContracts.length > 1) {
            setStudies([]);
        }
        if (props.symbolContracts.length > 0 && props.symbolContracts[0].reutersInstrumentCode) {
            refreshApi();
        }
    }, [props.symbolContracts, props.currency, props.unitOfMeasure, actualOrRolling]);

    useEffect(() => {
        if (!futuresPricesDatas || futuresPricesDatas.length === 0 || !props.symbolContracts.length) {
            setLinesSeries([]);
            return;
        }

        const newLinesSeries = futuresPricesDatas
            .filter((x) => x.rows?.length !== 0)
            .flatMap((data, index) => {
                const selection = props.symbolContracts[index];
                if (isRollingSelected && selection && selection.rowIndex >= maximumRollingContractNumber) {
                    return [];
                }

                let label = titleString;
                if (isRollingSelected && selection) {
                    label = `${label} C${selection.rowIndex + 1}`;
                } else {
                    label = `${label} ${formattingService.toMonthYear(new Date(selection.year, selection.month - 1, 1))}`;
                }

                return {
                    label,
                    forecastLabel: `${label} ${translations.words.forecast}`,
                    isPrimaryLine: false,
                    data: data!.rows!.map((row) => ({
                        value: row.settlementPrice,
                        asOfDate: new Date(row.asOfDate),
                        isActualValue: true,
                    })),
                } as IChartDataSeries;
            }) as IChartDataSeries[];

        setLinesSeries(newLinesSeries);
    }, [futuresPricesDatas]);

    const title = useMemo<string>(() => {
        if (isRollingSelected) {
            if (props.symbolContracts && props.symbolContracts.length > 0 && futuresPricesDatas) {
                const contractNumbers = futuresPricesDatas
                    .filter((x) => x.rows?.length !== 0)
                    .flatMap((_, index) => {
                        const symbolContracts = props.symbolContracts.filter((x) => x.rowIndex < maximumRollingContractNumber);
                        const selection = symbolContracts[index];
                        const contractNumber =
                            isRollingSelected && selection && selection.rowIndex < maximumRollingContractNumber ? `C${selection.rowIndex + 1}` : null;
                        return contractNumber ? [contractNumber] : [];
                    });

                return `${titleString} ${contractNumbers.join(', ')}`;
            }
        }
        if (props.symbolContracts && props.symbolContracts.length !== 1) {
            return titleString;
        }

        const symbolContract = props.symbolContracts[0];
        return `${titleString} ${formattingService.toMonthYear(new Date(symbolContract.year, symbolContract.month - 1, 1))}`;
    }, [symbolModel, props.symbolContracts, props.currency, props.unitOfMeasure, futuresPricesDatas]);

    const dataSourceTag = useMemo(
        (): string => (futuresPricesDatas && futuresPricesDatas[0] && futuresPricesDatas[0].dataSourceTag) ?? '',
        [futuresPricesDatas],
    );
    const currency = useMemo(
        (): Currency => props.currency ?? (futuresPricesDatas && futuresPricesDatas[0] && futuresPricesDatas[0].currency)!,
        [futuresPricesDatas, props.currency],
    );
    const unitOfMeasure = useMemo(
        (): UnitOfMeasure => props.unitOfMeasure ?? (futuresPricesDatas && futuresPricesDatas[0] && futuresPricesDatas[0].unitOfMeasure)!,
        [futuresPricesDatas, props.unitOfMeasure],
    );
    const oldestAsOfDate = useMemo((): Date | undefined => {
        if (!futuresPricesDatas || !futuresPricesDatas[0]) {
            return undefined;
        }

        const date = futuresPricesDatas
            .map((x) => {
                const rows = x.rows ?? [];
                return Math.min(...rows.map((row) => new Date(row.asOfDate).getTime()));
            })
            .sort()
            .reverse()[0];

        return new Date(date);
    }, [futuresPricesDatas]);

    const handleRollingActualButtonSelection = (selection: ActualOrRollingType) => {
        setFilterTimeSpan(defaultFilterTimeSpan);
        setActualOrRolling(selection);
    };

    return (
        <ChartWrapper
            name="FuturesPricesChart"
            title={title}
            dataSourceTag={dataSourceTag}
            isLoading={futuresPricesLoading || linesSeries.length === 0}
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
            headerOptions={{
                chartDisplayTypes: linesSeries.length <= 1 ? singleLineDisplayTypes : defaultDisplayTypes,
                showOnlyAsPopout: props.showOnlyAsPopout,
                handleChartDisplayTypeChange: (newDisplayType: ChartDisplayType) => {
                    setDisplayType(newDisplayType);
                },
            }}
            header={
                <div className={styles.futures_prices_multiple_header_components_container}>
                    {props.symbolContracts.length > 0 &&
                        props.symbolContracts[0].rowIndex < maximumRollingContractNumber &&
                        props.hideRollingOption !== true && (
                            <JoinedSelectionButtons
                                name={EventDataTargetsEnum.PriceGraph}
                                options={actualOrRollingOptions}
                                handleSelectionChange={handleRollingActualButtonSelection}
                                selection={actualOrRolling}
                            />
                        )}
                    <StudiesDropdown
                        disabled={linesSeries.length > 1}
                        studies={studies}
                        handleStudiesSelected={(selectedStudies) => setStudies(selectedStudies)}
                    />
                </div>
            }
            footer={
                <FilterTimeSpans
                    name="FuturesPricesChart"
                    filterTimeSpanOptions={isRollingSelected ? availableFilterTimeSpansRolling : availableFilterTimeSpansActual}
                    filterTimeSpan={filterTimeSpan}
                    oldestAsOfDate={oldestAsOfDate}
                    handleTimeSpanSelected={(timeSpan) => setFilterTimeSpan(timeSpan)}
                />
            }
            testId={props.testId}
        >
            <PriceChartRaw
                displayType={displayType}
                linesSeries={linesSeries}
                dataFrequency={DemeterDataFrequency.Daily}
                currency={currency}
                unitOfMeasure={unitOfMeasure}
                filterTimeSpan={filterTimeSpan}
                navigatorHasFullData
                displayDecimalPlacesMinimum={0}
                // TODO... Try to find the range then count the number
                // of whole digits, if the number of digits = 3, then use displayDecimalPlacesMinimum=0,
                // if the number of digits < 3, then use 2, for example
                // 100, then this would be 0, if the range is 10, then use 2, if the range is 1, then use 2.
                displayDecimalPlacesMaximum={4}
                studies={studies}
            />
        </ChartWrapper>
    );
};

export default FuturesPricesChart;
