import {
    CalculationEngineLineResultModel,
    CalculationEngineMonthlyValueOverrides,
    FarmerMarginCalculatorModel,
    RunCalculationEngineResponse,
} from '../../../../../../Generated/Raven-Demeter';
import documentObjectModelService from '../../../../../Services/DocumentObjectModelService';
import { HideGroup, MarginCompositeModel, MarginQuarterOptions, TabEventKeyType } from '../../../RiskDefinitions';
import MarginDataRow from '../MarginDataRow';
import styles from '../MarginInputsTable.module.scss';
import MarginInputsTableCostData from './MarginInputsTableCostData';
import MarginInputsTableFuturesData from './MarginInputsTableFuturesData';
import MarginInputsTableOutputHedgedData from './MarginInputsTableOutputHedgedData';
import MarginInputsTableProductionData from './MarketInputsTableProductionData';

interface IMarginInputProps {
    getFarmerMarginCalculatorCompositeRowsModel: MarginCompositeModel[];
    runCalculationEngineResponse: RunCalculationEngineResponse;
    savedFarmerMarginCalculator?: FarmerMarginCalculatorModel;
    hideGroup: HideGroup;
    quarterOptions: MarginQuarterOptions[];
    runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null;
    setRunMonthlyValueOverrides: (runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null) => void;
}

const MarginInputTableData: React.FC<IMarginInputProps> = (props: IMarginInputProps) => {
    const handleGridTabNavigation = (columnIndex: number, rowIndex: number, direction: TabEventKeyType) => {
        const currentParentDivElement = documentObjectModelService.getActiveElementParent();
        const allInputElementsOfColumn = documentObjectModelService.selectElementsWithAttributeStatus('data-column-number', columnIndex, true);

        if (!direction || direction === 'ArrowUp') {
            allInputElementsOfColumn[rowIndex - 1]?.focus();
        } else if (direction === 'ArrowDown' || direction === 'Enter') {
            allInputElementsOfColumn[rowIndex + 1]?.focus();
        } else if (direction === 'ArrowLeft') {
            (currentParentDivElement?.previousElementSibling?.firstChild?.firstChild as HTMLElement)?.focus();
        } else {
            (currentParentDivElement?.nextElementSibling?.firstChild?.firstChild as HTMLElement)?.focus();
        }
    };

    const firstRowModel = props.getFarmerMarginCalculatorCompositeRowsModel[0];

    return (
        <div className={styles.margin_inputs_table_data}>
            <div className={styles.margin_inputs_table_quarter_headers}>
                {props.quarterOptions.map((x) => (
                    <div key={x.label} className={styles.margin_inputs_table_quarter_header}>
                        {x.label}
                    </div>
                ))}
            </div>
            <MarginDataRow
                className={styles.margin_inputs_table_top_level_background}
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                fieldName="asOfDate"
            />

            <MarginInputsTableFuturesData
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                hideGroup={props.hideGroup}
                runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                handleGridTabNavigation={handleGridTabNavigation}
            />
            <MarginInputsTableProductionData
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                hideGroup={props.hideGroup}
                runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                handleGridTabNavigation={handleGridTabNavigation}
            />

            <MarginInputsTableOutputHedgedData
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                hideGroup={props.hideGroup}
                runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                handleGridTabNavigation={handleGridTabNavigation}
            />

            <MarginInputsTableCostData
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                hideGroup={props.hideGroup}
                runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                handleGridTabNavigation={handleGridTabNavigation}
            />

            <MarginDataRow
                className={styles.margin_inputs_table_data_group_bold}
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                fieldName={firstRowModel.hedgedMargin.variableName as keyof CalculationEngineLineResultModel}
            />
            <MarginDataRow
                className={styles.margin_inputs_table_data_group_bold}
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                fieldName={firstRowModel.outputHedgedPercent.variableName as keyof CalculationEngineLineResultModel}
            />
            <MarginDataRow
                className={styles.margin_inputs_table_data_group_bold}
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                fieldName={firstRowModel.unhedgedMargin.variableName as keyof CalculationEngineLineResultModel}
            />
            <MarginDataRow
                className={styles.margin_inputs_table_data_group_bold}
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                fieldName={firstRowModel.netMargin.variableName as keyof CalculationEngineLineResultModel}
            />
        </div>
    );
};

export default MarginInputTableData;
