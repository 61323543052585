import CheckboxDropdown from './CheckboxDropdown';
import InputContainer from './InputContainer';
import { SelectInputOption } from './SelectInput';

interface ICheckboxDropdownInputProps<T> {
    title: string;
    values?: T[];
    options: SelectInputOption<T>[];
    handleOptionSelect: (values: T[]) => void;
    disabled?: boolean;
    minimumSelectionLimit?: number;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const CheckboxDropdownInput = <T,>(props: ICheckboxDropdownInputProps<T>): JSX.Element => (
    <InputContainer title={props.title}>
        <CheckboxDropdown
            values={props.values ?? []}
            options={props.options}
            handleOptionSelect={props.handleOptionSelect}
            displayValues
            disabled={props.disabled}
            minimumSelectionLimit={props.minimumSelectionLimit}
        />
    </InputContainer>
);

export default CheckboxDropdownInput;
